import {getRequest, IApiResponse, deleteRequest, putRequest, postRequest} from "../../../common/helpers/RequestHelper";
import {kpiStore} from "./KpiStore";

class KpiService{

    public getKPI(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/kpis/year/${kpiStore.searchYear}`)
    }

    public editKpi(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/kpis`, data)
    }


}

export const kpiService = new KpiService()