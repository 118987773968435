import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {accountStore} from "./AccountStore";

class AccountService {

    public getAccount(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff?page=${accountStore.page}&size=100${accountStore.keyword ? `&keyword=${accountStore.keyword}` : ''}`)
    }
    public getCheckerPhone(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff/check_phone${accountStore.dataRequest.phone ? `?phone=${accountStore.dataRequest.phone}` : ''}`)
    }
    public getDepartments(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/departments?page=0&size=100`)
    }
    public getListRestaurant(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/establishment?page=0&size=100`)
    }
    public getListRole(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/role?page=0&size=100`)
    }
    public getListManager(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff?rank=MANAGERS&page=0&size=100`)
    }

    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/staff`, data)
    }
    public getAccountById(id: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff/${id}`)
    }
    public changeStatus(data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/staff/change_status`, data)
    }

}
export const accountService = new AccountService()