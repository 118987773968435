import React, {useEffect} from 'react';
import './RndResearchPage.scss'
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {sttPage} from "../../../common/utils/Utils";
import {Link} from "react-router-dom";
import {convertStateResearch} from "../marketResearch/MarketResearchPage";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";
import {registerStore} from "../../../modules/auth/register/RegisterStore";
import {Tooltip} from "antd";
import {rndResearchStore} from "./RndResearchStore";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {dateUtils} from "../../../common/utils/DateUtils";

const RndResearchPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value: rndResearchStore.searchStatus,
            placeholder: 'Tất cả',
            listValue: [{value: "PENDING", label: "Đang chờ"},{value: "PROCESSING", label: "Đang xử lý"},{value: "APPROVED", label: "Đã duyệt"}],
            callback: async (e: any) => {
                rndResearchStore.searchStatus = e
                await rndResearchStore.getListRndResearch()
            }
        },
    ]
    useEffect(() => {
        rndResearchStore.getListRndResearch().then()
    }, [])
    return (
        <>
            <div className='market_research__container'>
                <div className='d-flex justify-content-between'>
                    <TabRedirect tab='Báo cáo R&D' />
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={<Link onClick={() => {
                            rndResearchStore.typeForm = 'add'
                            rndResearchStore.statusResearch = ''
                            rndResearchStore.clearForm()
                            rndResearchStore.dataRequestRndResearch.reportUser = registerStore.username
                        }} className="button_add_research" to={"/nghien-cuu-thi-truong/bao-cao-rnd/tao-bao-cao"}>
                            <img src="/assets/ico/action/add_icon.svg" alt=""/>
                            Thêm
                        </Link>}
                    />
                </div>
                {rndResearchStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tên</th>
                            <th className="text-center">Thời gian</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listRnDResearch.length < 1 ?
                                <NoContent colSpan={5}/> : rndResearchStore.listRnDResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(0, i)}</td>
                                    <td className="text-center">
                                        <div className='d-flex align-items-center'>
                                            <img className='tab_header__device' src="/assets/ico/file-bar-chart-2.svg" alt="" />
                                            <span className='mx-2'>{item?.name}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{dateUtils.formatDate(item?.reportingTime, "HH:mm:ss, DD/MM/YYYY")}</td>
                                    <td className="text-center">{convertStateResearch("PENDING")}</td>
                                    <td className="text-center">
                                        <Tooltip title='Chỉnh sửa'>
                                            <Link onClick={() => {
                                                rndResearchStore.typeForm = 'edit'
                                                rndResearchStore.id = item.id
                                            }} className='p-0 mx-1' to={`/nghien-cuu-thi-truong/bao-cao-rnd/chi-tiet/${item.id}`}>
                                                <img src="/assets/ico/action/edit_icon.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Chi tiết"/>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title='Xóa'>
                                            <button className="btn p-0 mx-1"
                                                    data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                    onClick={() => rndResearchStore.id = item.id}>
                                                <img src="/assets/ico/action/delete_icon.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Xóa"/>
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <PopupConfirm isLoading={rndResearchStore.isLoadingBt} action={rndResearchStore.deleteMRndResearch} />
        </>
    );
}

export default observer(RndResearchPage);