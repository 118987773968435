import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {staffSettingStore} from "./StaffStore";

class StaffService{
    public getStaff(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff?page=${staffSettingStore.page}&size=100${staffSettingStore.keyword ? `&keyword=${staffSettingStore.keyword}` : ''}`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff/${id}`)
    }

    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/staff`, data)
    }

    public edit(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/staff/${id}`, data)
    }



}
export const staffService = new StaffService()