import {makeAutoObservable} from "mobx";
import $ from 'jquery'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {restaurantStore} from "../restaurants/RestaurantStore";
import {roomService} from "./RoomService";
import {restaurantService} from "../restaurants/RestaurantService";


class RoomStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    status: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isDisableEditBtn: boolean = false
    isLoadingBt: boolean = false
    listRestaurant: any[] = []
    establishmentId: any = null
    establishmentName: string = ''
    listRoom: any[] = []
    dataRequest: any = {
        name: "",
        establishmentId: null,
        status: null,
    }
    dataRequestTmp: any = {
        name: "",
        establishmentId: null,
        status: null,
    }
    errors: any = {
        name: "",
        establishmentId: "",
        status: "",
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            name: "",
            establishmentId: null,
            status: null,
        }
        this.errors = {
            name: "",
            establishmentId: "",
            status: "",
        }
    }

    async getListRoom() {
        this.isLoading = true
        const result = await roomService.getListRoom()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRoom = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getListRestaurant() {
        const result = await restaurantService.getListRestaurant(10000)
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getDetailRoom(id: number) {
        this.typeModal = 'edit'
        const result = await roomService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        }
    }

    async addEditRoom() {
        this.dataRequest.establishmentId = this.establishmentId

        if(!this.dataRequest.name) {
            roomStore.errors.name = "Tên phòng không được để trống"
            return false
        }
        if(!this.dataRequest.status) {
            roomStore.errors.status = "Vui lòng chọn tình trạng của phòng"
            return false
        }
        if(!this.dataRequest.establishmentId) {
            roomStore.errors.establishmentId = "Bạn chưa chọn địa điểm bán để tạo phòng"
            return false
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await roomService.addRoom(this.dataRequest) : await roomService.editRoom(roomStore.id, this.dataRequest)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#add_edit_room').trigger('click')
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} phòng thành công!`)
            await roomStore.getListRoom()
            roomStore.isDisableEditBtn = true
        } else toastUtil.error(result.body.message)
    }
    async deleteRoom() {
        restaurantStore.isLoadingBt = true
        const result = await roomService.deleteRoom(roomStore.id)
        restaurantStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa phòng thành công!')
            await roomStore.getListRoom()
        } else toastUtil.error(result.body.message)
    }

}

export const roomStore = new RoomStore()