import React, {useEffect} from 'react';
import SuppliesPage from "./SuppliesPage";
import {observer} from "mobx-react";
import {ITypeSupplies, suppliesStore} from "./SuppliesStore";

const MaterialsPage = () => {

    useEffect(() => {
        suppliesStore.type = ITypeSupplies.materials
        suppliesStore.getSupplies().then()
    },[])

    return (
        <div>
            <SuppliesPage/>
        </div>
    );
}

export default observer(MaterialsPage);