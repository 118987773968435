import React from 'react';
import {observer} from "mobx-react";
import './FreshFoodCheckSheet.scss'
import {Input} from "antd";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { ConfigProvider} from 'antd';
import viVI from 'antd/lib/locale/vi_VN';
import {checkStore} from "../../CheckStore";

const ModalFreshFoodCheckSheet= () => {
    return (
        <div className="modal fade" id="fresh_food_check_sheet" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content px-5">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title">LẬP PHIẾU KIỂM TRA</h5>
                            <span style={{color: '#000', fontSize: '14px'}}>Phiếu kiểm nguyên liệu thực phẩm tươi sống, đông lạnh: thịt cá, rau, củ, quả, ....</span>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='d-flex w-100 justify-content-between my-3'>
                        <div style={{width: '33%'}} className='form-group'>
                            <label className='form-label'>Nhà cung cấp</label>
                            <Input placeholder="Nhập văn bản..." />
                        </div>
                        <div style={{width: '33%'}} className='form-group'>
                            <label className='form-label'>Thời gian kiểm tra</label>
                            <ConfigProvider locale={viVI}>
                                <DatePicker placeholder='Chọn ngày' defaultValue={dayjs('01/01/2015', 'DD/MM/YYYY')} format='DD/MM/YY' />
                            </ConfigProvider>
                        </div>
                        <div style={{width: '33%'}} className='form-group'>
                            <label className='form-label'>Địa điểm kiểm tra</label>
                            <Input placeholder="Nhập văn bản..." />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-center mb-2'>
                        <button className="button_add_form_table"
                                onClick={() => {
                                    checkStore.listRequestCheckAfterProcessing.push({
                                        name: "",
                                        code: "",
                                        unit: "",
                                        qty: "",
                                        note: "",
                                    },)
                                }}>
                            <img src="/assets/ico/action/add_green_icon.svg"
                                 alt=""/>
                            Thêm
                        </button>
                    </div>
                    <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                        <table className='table align-middle border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th rowSpan={2} className="text-center">TT</th>
                                <th rowSpan={2}  className="text-center">Tên thực phẩm</th>
                                <th rowSpan={2} className="text-center">Thời gian nhập (Ngày, giờ)</th>
                                <th rowSpan={2} className="text-center">Khối lượng</th>
                                <th colSpan={3} className="text-center">Nơi cung cấp</th>
                                <th rowSpan={2} className="text-center">Chứng từ, hóa đơn</th>
                                <th rowSpan={2} className="text-center">Giấy ĐKVS thú y</th>
                                <th rowSpan={2} className="text-center">Giấy kiểm dịch</th>
                                <th colSpan={2} className="text-center">Kiểm tra cảm quan</th>
                                <th colSpan={2} className="text-center">Xét nghiệm nhanh</th>
                                <th rowSpan={2} className="text-center">Biện pháp xử lý/ Ghi chú</th>
                            </tr>
                            <tr>
                                <th className="text-center">Tên cơ sở</th>
                                <th className="text-center">Địa chỉ, SĐT</th>
                                <th className="text-center">Tên người giao hàng</th>
                                <th className="text-center">Đạt</th>
                                <th className="text-center">Không đạt</th>
                                <th className="text-center">Đạt</th>
                                <th className="text-center">Không đạt</th>
                            </tr>
                            </thead>
                            <tbody>
                            { checkStore.listRequestCheckAfterProcessing.map((item: any, i: number) => {
                                return (
                                    <tr key={i} className='position-relative'>
                                        <td  style={{width: '32px'}}  className="text-center">{i + 1}</td>
                                        <td style={{minWidth: '200px'}}  className="text-center">
                                            <Input type="text" value={item?.name} onChange={(e: any) => item.name = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td style={{minWidth: '150px'}} className="text-center">
                                            <Input type="text" value={item?.name} onChange={(e: any) => item.name = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.name} onChange={(e: any) => item.name = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.name} onChange={(e: any) => item.name = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.name} onChange={(e: any) => item.name = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.code}  onChange={(e: any) => item.code = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.unit}  onChange={(e: any) => item.unit = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td  className="text-center">
                                            <Input type="text" value={item?.qty}  onChange={(e: any) => item.qty = e.currentTarget.value} placeholder='Nhập...'/>
                                        </td>
                                        <td className="text-center">
                                            <Input type="text" value={item?.note} onChange={(e: any) => item.note = e.currentTarget.value} placeholder='Nhập...'/>
                                            { i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                data-toggle="modal" data-target="#"
                                                                onClick={() => {
                                                                    checkStore.listRequestCheckAfterProcessing.splice(i, 1)
                                                                }}>
                                                <img src="/assets/ico/action/minus-circle.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Xóa"/>
                                            </button>}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className='w-100 d-flex justify-content-end align-items-center'>
                        <button className="button_save">
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ModalFreshFoodCheckSheet);