import React, {useEffect} from 'react';
import './RevenueReportPage.scss'
import {observer} from "mobx-react";
import { Line } from "react-chartjs-2";
import {Doughnut} from 'react-chartjs-2';
import 'chart.js/auto'
import {costStore} from "../../finance/costPage/CostStore";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {orderStore} from "../../order/OrderStore";
import {DatePicker, Select, Tooltip} from "antd";
import dayjs from "dayjs";
import Loading from "../../../modules/components/Loading";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {revenueReportStore} from "./RevenueReportStore";
import {urlImage} from "../../../common/utils/Utils";

const RevenueReportPage = () => {
    useEffect(() => {
        costStore.searchYear = new Date().getFullYear().toString()
        costStore.getListRestaurant().then(() => {
            if(!costStore.searchEstablishmentId) {
                costStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
            }
            if(!costStore.establishmentName) {
                costStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
            }
            costStore.getListCost().then(() => {
                revenueReportStore.getRevenueStructure().then()
                revenueReportStore.getProductStructure().then()
                revenueReportStore.getBestSellerProduct().then()
            })
            revenueReportStore.getListProduct().then(() => {
                revenueReportStore.searchProduct = revenueReportStore.listProduct?.[0]?.value
                revenueReportStore.getPromoteProduct().then()
            })
        })
    }, [])
    const data = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                data: costStore.dataRevenueLineChart,
                fill: true,
                backgroundColor: "rgba(0, 171, 86, 0.2)",
                borderColor: "#00AB56",
                pointBorderRadius: 10, // Điểm dữ liệu
                borderRadius: 20, // Đường dữ liệu
                tension: 0.3,
                options: {
                    responsive: true, // Instruct chart js to respond nicely.
                    maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
                }
            }
        ]
    };

    const dataLineProduct = {
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        datasets: [
            {
                label: "Doanh thu",
                data:  revenueReportStore.dataPromoteProduct,
                fill: true,
                backgroundColor: "rgba(239, 119, 82, 0.2)",
                borderColor: "rgba(239, 119, 82, 1)",
                pointBorderRadius: 10, // Điểm dữ liệu
                borderRadius: 20, // Đường dữ liệu
                tension: 0.3,
                options: {
                    responsive: true, // Instruct chart js to respond nicely.
                    maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height
                }
            }
        ]
    };
    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true, // Đặt trục y bắt đầu từ 0
            }
        },
        plugins: {
            legend: {
                display: false // Ẩn label
            }
        },
    };
    const chartLineProductOptions = {
        scales: {
            y: {
                beginAtZero: true, // Đặt trục y bắt đầu từ 0
            }
        },
        plugins: {
            legend: {
                display: false // Ẩn label
            }
        },
        cutout: '70%',
        aspectRatio: 1
    };
    const dataDoughnut = {
        labels: revenueReportStore.dataRevenueStructure?.map((item: any) => {
            return item.name
        }),
        datasets: [
            {
                label: 'Doanh thu',
                data: revenueReportStore.dataRevenueStructure?.map((item: any) => {
                    return item.revenue
                }),
                borderColor: ['white'],
                borderWidth: 8,
                backgroundColor: revenueReportStore.dataColor,
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
            }

        ]
    }
    const dataDoughnutProduct = {
        labels: revenueReportStore.dataProductStructure?.map((item: any) => {
            return item.name
        }),
        datasets: [
            {
                label: 'Doanh thu',
                data: revenueReportStore.dataProductStructure?.map((item: any) => {
                    return item.revenue
                }),
                borderColor: ['white'],
                borderWidth: 0,
                backgroundColor: revenueReportStore.dataColorProduct,
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
            }

        ]
    }
    const optionDoughnut = {
        plugins: {
            legend: {
                display: false // Ẩn label
            }
        },
        cutout: '70%', // Đặt chiều rộng tại đây
        aspectRatio: 1
    }
    const dataFilter : IFilter[] = [
        {
            type: 'select',
            value: costStore.searchEstablishmentId,
            placeholder: 'Tất cả cửa hàng',
            listValue: costStore.listRestaurant,
            callback: async (e: any, value: any) => {
                costStore.searchMonth = null
                costStore.searchEstablishmentId = e
                costStore.establishmentName = value?.label ?? ""
                orderStore.dataRequest.establishmentId = e
                orderStore.dataRequest.establishmentName = value?.label ?? ""
                localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                await costStore.getListCost()
            }
        },
    ]
    const handleSearchYearChange = async (date: any, dateString: any) => {
        costStore.searchYear = dateString || new Date().getFullYear().toString()
        await costStore.getListCost()
        await revenueReportStore.getRevenueStructure()
    };
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <div className='dashboard_container'>
                {
                    costStore.isLoading ? <Loading /> :
                        <div className='d-flex flex-column justify-content-between align-items-start w-100'>
                            <FilterComponent
                                data={dataFilter}
                                permissionCode
                                isSearchYear
                                searchYear={<DatePicker
                                    picker="year"
                                    placeholder="Năm"
                                    value={costStore.searchYear ? dayjs(costStore.searchYear, 'YYYY') as any : null}
                                    onChange={handleSearchYearChange}
                                    bordered={false}
                                />}
                            />
                            <div className='w-100 d-flex justify-content-between'>
                                <div style={{width: '66%', height: 'auto'}} className="dashboard_container__revenue-line d-flex justify-content-start flex-column align-items-start">
                                    <div className='d-flex w-100 dashboard_container__revenue-header'>
                                        <div className='dashboard_container__revenue-title'>
                                            Tăng trường doanh thu <br/> theo tháng
                                        </div>
                                        <div className='dashboard_container__revenue-title'>
                                            <h2>Doanh thu cao nhất</h2>
                                            <h1>{numberUtil.number_format(revenueReportStore.dataRevenueLineChartMax)}<span className='text-decoration-underline ms-1'>đ</span></h1>
                                        </div>
                                    </div>
                                    <Line className='p-5' data={data} options={chartOptions} />
                                </div>
                                <div style={{width: '32%', height: 'auto'}} className="dashboard_container__revenue-line d-flex justify-content-start flex-column align-items-start">
                                    <div className='d-flex w-100 align-items-center dashboard_container__revenue-header mb-3'>
                                        <div className='dashboard_container__revenue-title'>
                                            Cơ cấu doanh thu
                                        </div>
                                        <div className='w-50 p-3 d-flex justify-content-end align-items-center'>
                                            <Select
                                                className='select_month__filter'
                                                value={revenueReportStore.monthRevenueStructure}
                                                onChange={async (e: any) => {
                                                    revenueReportStore.monthRevenueStructure = e ?? new Date().getMonth()  + 1
                                                    await revenueReportStore.getRevenueStructure()
                                                }}
                                                placeholder="Tất cả các tháng" options={revenueReportStore.dataMonth}
                                            />
                                        </div>
                                    </div>
                                    {revenueReportStore.dataRevenueStructure?.map((item: any) => {
                                        return item.revenue
                                    }).some((revenue : any) => (revenue !== null && revenue !== 0)) ? <Doughnut className='p-2 mb-3' style={{maxHeight: '350px'}} data={dataDoughnut} options={optionDoughnut}  />
                                        : <div style={{height: '350px'}} className='text-no-data'>Chưa có dữ liệu</div>
                                    }
                                    <div className='w-100 d-flex flex-column pb-3'>
                                        <div className='dashboard_container__revenue-table'>
                                            <div className='dashboard_container__revenue-head mb-2'>
                                                <div className='dashboard_container__revenue-head--item'>
                                                    CƠ SỞ
                                                </div>
                                                <div className='dashboard_container__revenue-head--item'>
                                                    DOANH THU
                                                </div>
                                                <div className='dashboard_container__revenue-head--item'>
                                                    TỶ LỆ
                                                </div>
                                            </div>
                                            <div style={{height: '150px'}} className='custom-scrollbar overflow-auto w-100 d-flex flex-column'>
                                                {
                                                    revenueReportStore.dataRevenueStructure?.map((item: any, index: number) => {
                                                        return(
                                                            <div key={index} className='dashboard_container__revenue-row'>
                                                                <div className='dashboard_container__revenue-row--item item-1'>
                                                                    {item?.name}
                                                                </div>
                                                                <div className='dashboard_container__revenue-row--item item-2'>
                                                                    {numberUtil.number_format(item?.revenue)}
                                                                </div>
                                                                <div className='dashboard_container__revenue-row--item item-3'>
                                                                    {item?.revenue && item?.total ? (item?.revenue * 100 / item?.total).toFixed(2) : 0}%
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-4'>
                                <div style={{width: '32%', height: 'auto'}} className="dashboard_container__revenue-line d-flex justify-content-between flex-column align-items-start">
                                    <div className='d-flex w-100 dashboard_container__revenue-header mb-3'>
                                        <div className='dashboard_container__revenue-title d-flex justify-content-center flex-column w-100'>
                                            Tăng trưởng từng sản phẩm
                                            <span className='title-1'>
                                                Doanh thu lớn nhất
                                            </span>
                                            <span className='title-2'>{numberUtil.number_format(revenueReportStore.dataPromoteProductMax)}<span className='text-decoration-underline ms-1'>đ</span></span>
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-center my-3'>
                                        <Select
                                            style={{width: '200px'}}
                                            showSearch
                                            onSearch={(e: any) => {
                                                revenueReportStore.searchProduct = e
                                            }}
                                            filterOption={filterOption}
                                            value={revenueReportStore.searchProduct}
                                            onChange={async (e: any) => {
                                                revenueReportStore.searchProduct = e
                                                await revenueReportStore.getPromoteProduct().then()
                                            }}
                                            placeholder="Tất cả sản phẩm" options={revenueReportStore.listProduct}
                                        />
                                    </div>
                                    <Line className='p-5' style={{maxHeight: '450px'}} data={dataLineProduct} options={chartLineProductOptions} />
                                </div>
                                <div style={{width: '32%', height: 'auto'}} className="dashboard_container__revenue-line d-flex justify-content-between flex-column align-items-start">
                                    <div className='d-flex w-100 dashboard_container__revenue-header mb-3'>
                                        <div className='dashboard_container__revenue-title d-flex flex-column w-100'>
                                            Cơ cấu sản phẩm
                                            <span className='title-2'>{numberUtil.number_format(revenueReportStore.dataProductStructure.reduce((accumulator: any, currentValue: any) => {
                                                return accumulator + currentValue.revenue;
                                            }, 0))}<span className='text-decoration-underline ms-1'>đ</span></span>
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-center my-3'>
                                        <Select
                                            className='select_month__filter'
                                            value={revenueReportStore.monthProductStructure}
                                            onChange={async (e: any) => {
                                                revenueReportStore.monthProductStructure = e ?? new Date().getMonth()  + 1
                                                await revenueReportStore.getProductStructure().then()
                                            }}
                                            placeholder="Tất cả các tháng" options={revenueReportStore.dataMonth}
                                        />
                                    </div>
                                    {revenueReportStore.dataProductStructure?.map((item: any) => {
                                        return item.revenue
                                    }).some((revenue : any) => (revenue !== null && revenue !== 0)) ? <Doughnut className='p-2 mb-3' style={{maxHeight: '350px'}} data={dataDoughnutProduct} options={optionDoughnut}  />
                                        : <div style={{height: '350px'}} className='text-no-data'>Chưa có dữ liệu</div>
                                    }
                                    <div className='w-100 d-flex flex-wrap justify-content-between px-5 pb-5'>
                                        {revenueReportStore.dataProductStructure.map((item: any, index: number) =>{
                                            return(
                                                <Tooltip title={item?.name}>
                                                    <div key={index} style={{width: '30%'}} className='note_structure__product d-flex justify-content-start align-items-center mb-2'>
                                                        <div style={{width: '14px'}}>
                                                            <div style={{backgroundColor: `${item.color}`}} className='note-color-chart'></div>
                                                        </div>
                                                        <span className='ms-2 title-color-chart'>{item?.name}</span>
                                                    </div>
                                                </Tooltip>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div style={{width: '32%', height: 'auto'}} className="dashboard_container__revenue-line d-flex justify-content-start flex-column align-items-start">
                                    <div className='top-product__header'>
                                        Top sản phẩm bán chạy
                                    </div>

                                    <div className="top-product__container">
                                        {
                                            revenueReportStore.dataBestSellerProduct?.length > 0 ? revenueReportStore.dataBestSellerProduct?.map((item: any, index: number) => {
                                                if(index === 0) return (
                                                    <div key={index} className='first_product'>
                                                        <div className='mx-3 py-1 medal_icon'>
                                                            <img src="/assets/ico/medal_1.svg" alt=""/>
                                                        </div>
                                                        <div className='first_product__content d-flex flex-fill justify-content-between align-items-center'>
                                                            <div className='d-flex flex-column'>
                                                                <h2>
                                                                    {item?.name}
                                                                </h2>
                                                                <span>
                                                        {numberUtil.number_format(item?.sold)}
                                                    </span>
                                                            </div>
                                                            <img className='img_top__product' src={urlImage(item?.image)} alt=""/>
                                                        </div>
                                                    </div>
                                                )
                                                else return (
                                                    <div key={index} className='last_product'>
                                                        <div className='mx-3 py-1 medal_icon'>
                                                            {index === 1 ? <img src="/assets/ico/medal-2.svg" alt=""/>
                                                                : index === 2 ? <img src="/assets/ico/medal-3.svg" alt=""/> : index }
                                                        </div>
                                                        <div className={`${index !== 9 ? 'last_product__content--border' : ''} last_product__content d-flex flex-fill justify-content-between align-items-center`}>
                                                            <h2>
                                                                {item?.name}
                                                            </h2>
                                                            <span>
                                                        {numberUtil.number_format(item?.sold)}
                                                    </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                                :  <div style={{height: '350px'}} className='text-no-data'>Chưa có dữ liệu</div>
                                        }
                                    </div>
                                </div>
                            </div>
                    </div>
                }
            </div>
        </>
    );
}

export default observer(RevenueReportPage);