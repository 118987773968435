import {getRequest, IApiResponse, postRequest} from "../../../common/helpers/RequestHelper";

class RegisterService {
    public registerRestaurant(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/restaurant`, data);
    }
    public getListFnB(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/restaurant/member`)
    }
    public getToken(params: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/gettoken?service=fnb`, params)
    }
}

export const registerService = new RegisterService();