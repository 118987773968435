import React from 'react';
import {observer} from "mobx-react";
import '../../purchase/components/addRequestPurchase/AddRequestPurchase.scss'
import {importExportStore} from "../ImportExportStore";
import SelectForm from "../../../../modules/components/form/SelectAnt";
import {DatePicker, Input, Spin} from 'antd';
import {suppliesStore} from "../../../setting/supplies/SuppliesStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import NoContent from "../../../../modules/components/NoContent";
import {personalManagerStore} from "../../../personalManager/PersonalManagerStore";
import {DatePickerProps, RangePickerProps} from "antd/es/date-picker";
import {Moment} from "moment/moment";

const {TextArea} = Input;

interface IProps {
    text: string
}

let options = [
    {
        id: 'materials',
        name: 'Nguyên vật liệu'
    },
    {
        id: 'additive',
        name: 'Phụ gia'
    },
    {
        id: 'packaging',
        name: 'Bao bì'
    },
    {
        id: 'devices',
        name: 'Thiết bị'
    }
]
function convertTime(date: Moment): any {
    if(date) return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    else return ""
}

const AddImpExpRequest = ({text}: IProps) => {

    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
    ) => {
        // @ts-ignore
        importExportStore.dataRequest.timeRequest = convertTime(value);
    };
    return (
        <div className="modal fade" id="add_import_export_request" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 1450}}>
                <div className="modal-content px-5">
                    <div className="modal-header">
                        <h5 className="modal-title">PHIẾU ĐỀ NGHỊ {text} KHO</h5>
                        <button type="button" id="close_add_imp_exp_request" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="w-100 d-flex justify-content-between mt-4">
                        <div style={{width: '33%'}} className="form-group pe-2">
                            <label className='form-label'>Kính gửi</label>
                            <Input type='text' value={personalManagerStore.dataCampaignRequest?.name}
                                   status={(personalManagerStore.errorCampaignRequest?.name && !personalManagerStore.dataCampaignRequest?.name) ? "error" : ""}
                                   onChange={(e: any) => {
                                       personalManagerStore.dataCampaignRequest.name = e.target.value
                                   }}
                                   disabled
                                   placeholder='Nhập tên ...' />
                        </div>
                        <div style={{width: '33%'}} className="form-group ">
                            <label className='form-label'>Mã yêu cầu<span className='text-danger'>*</span></label>
                            <Input type='text' value={importExportStore.dataRequest?.code}
                                   status={(importExportStore.errors?.code && !importExportStore.dataRequest?.code) ? "error" : ""}
                                   onChange={(e: any) => {
                                       importExportStore.dataRequest.code = e.target.value
                                   }}
                                   placeholder='Nhập...' />
                            {importExportStore.errors?.code && !importExportStore.dataRequest?.code && <small className="mb-2 text-error">{importExportStore.errors?.code}</small>}

                        </div>
                        <div style={{width: '33%'}}  className="ps-2 form-group">
                            <label className='form-label'>Ngày gửi<span className='text-danger'>*</span></label>
                            <DatePicker
                                placeholder='Chọn'
                                status={(importExportStore.errors?.timeRequest && !importExportStore.dataRequest?.timeRequest) ? "error" : ""}
                                showTime
                                onChange={onChange}/>
                            {importExportStore.errors?.timeRequest && !importExportStore.dataRequest?.timeRequest && <small className="mb-2 text-error">{importExportStore.errors?.timeRequest}</small>}
                        </div>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="w-75 d-flex align-items-center">
                            <div style={{width: '33%'}} className="pe-2">
                                <div className="form-group">
                                    <label>Kho</label>
                                    <SelectForm value={importExportStore.warehouseId} isError={importExportStore.errors.warehouseId}
                                                options={importExportStore.listWarehouseByUser}
                                                onChange={(e: any, value: any) => {
                                                    importExportStore.warehouseId = e
                                                    importExportStore.warehouseName = value.label
                                                }}
                                                placeholder="Chọn kho"/>
                                </div>
                            </div>
                            <div style={{width: '33%'}} className="">
                                <div className="form-group">
                                    <label>Sử dụng vật tư <span className="text-danger">*</span></label>
                                    <SelectForm value={importExportStore.use_material}
                                                options={options}
                                                onChange={(e: any) => importExportStore.changeUseMaterial(e)}
                                                placeholder="Chọn vật tư"/>
                                </div>
                            </div>
                            <div style={{width: '33%'}} className="ps-2 me-4">
                                <div className="form-group">
                                    <label>Tên vật tư <span className="text-danger">*</span></label>
                                    <SelectForm value={importExportStore.materialId}
                                                onChange={(e: any) => importExportStore.materialId = e}
                                                options={suppliesStore.listSupplies}/>
                                </div>
                            </div>
                        </div>
                        <button className="button_add_form_table mt-3" onClick={() => importExportStore.addSupplies()}>
                            <img src="/assets/ico/action/add_green_icon.svg" style={{width: 14}} alt=""/>
                            Thêm
                        </button>
                    </div>
                    <div className="table-form-responsive mt-2 mb-3 d-flex justify-content-between">
                        <table className='table align-middle border gy-7 gs-7 mb-0'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Tên vật tư</th>
                                <th className="text-center">Mã vật tư</th>
                                <th className="text-center">ĐVT</th>
                                <th className="text-center">Số lượng</th>
                                <th className="text-center">Tên kho</th>
                                <th>Ghi chú</th>
                            </tr>
                            </thead>
                            <tbody>
                            {importExportStore.dataRequest.items && importExportStore.dataRequest.items.length < 1 ? <NoContent colSpan={9} height={150} /> : importExportStore.dataRequest.items.map((item: any, i: number) => {
                                return (
                                    <tr key={i} className='position-relative'>
                                        <td className="text-center">{i + 1}</td>
                                        <td  width="15%">{item.name}</td>
                                        <td width="10%" className="text-center">{item.code}</td>
                                        <td className="text-center">{item.unit}</td>
                                        <td width="15%" className="text-center">
                                            <Input
                                                type="text"
                                                onKeyDown={(evt: {
                                                    key: string
                                                    preventDefault: () => any
                                                }) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        e === '' ||
                                                        Number(numberUtil.regexNumber(e)) >= 0
                                                    ) {
                                                        item.qty = numberUtil.regexNumber(e)
                                                    }
                                                }}
                                                value={item.qty === "" ? "" : numberUtil.number_format(item.qty)}
                                                placeholder='Nhập...'
                                            />
                                        </td>
                                        <td width="15%" className="text-center">
                                            {item.warehouseName}
                                        </td>
                                        <td width="20%" className="text-center">
                                            <Input
                                                type="text"
                                                onChange={(e: any) => item.note = e.currentTarget.value}
                                                value={item.note}
                                                placeholder='Nhập...'
                                            />
                                            <button className="btn p-0 mx-1 btn_delete_form_table"
                                                    data-toggle="modal" data-target="#"
                                                    onClick={() => {
                                                        importExportStore.dataRequest.items.splice(i, 1)
                                                    }}>
                                                <img src="/assets/ico/action/minus-circle.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Xóa"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            {(importExportStore.dataRequest.items && importExportStore.dataRequest.items.length < 1 && importExportStore.errors.materialId) && <tr>
                                <td colSpan={9}>
                                    <p className="error">{importExportStore.errors.materialId}</p>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className='form-group mb-4'>
                        <label className='form-label'>Mục đích sử dụng</label>
                        <TextArea placeholder="Nhập văn bản..." value={importExportStore.dataRequest.purpose}
                                  onChange={(e: any) => importExportStore.dataRequest.purpose = e.currentTarget.value}
                                  className='w-100' rows={2}/>
                    </div>
                    <div className='w-100 d-flex justify-content-end align-items-center'>
                        <button className="button_save" onClick={() => importExportStore.sendImportExport()}>
                            {
                                importExportStore.isLoadingBt ? <Spin size="small" /> : "Gửi"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddImpExpRequest);