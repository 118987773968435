import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {customerFeedbackStore} from "./CustomerFeedbackStore";

class CustomerFeedbackService{

    public getReviewStar(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/review_stats/detail${customerFeedbackStore.establishmentId ? `?establishmentId=${customerFeedbackStore.establishmentId}` : ''}`)
    }

    public getFeedback(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reviews?page=${customerFeedbackStore.page}&size=100${customerFeedbackStore.establishmentId ? `&establishmentId=${customerFeedbackStore.establishmentId}` : ''}${customerFeedbackStore.keyword ? `&keyword=${customerFeedbackStore.keyword}` : ''}`)
    }
    public getFeedbackById(id: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reviews/${id}`)
    }
    public edit(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/reviews/${id}`, data)
    }
    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/reviews`, data)
    }
    public checkPhoneUser(phone: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user/check_phone?phone=${phone}`)
    }
}
export const customerFeedbackService = new CustomerFeedbackService()