import {Empty, SpinProps, Table} from "antd";
import "./CustomTable.scss";
import {
    ColumnsType,
    TableRowSelection,
} from "antd/lib/table/interface";
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import * as React from "react";
import {
    ColumnType,
    GetComponentProps,
    TableComponents,
} from "rc-table/lib/interface";
import Loading from "../Loading";

interface CustomTableProps {
    rowSelection?: TableRowSelection<any> | false;
    isRowSelection?: boolean
    className?: string;
    data: RcTableProps<any>["data"];
    columns: ColumnsType<any>;
    rowKey?: string | false;
    isLoading?: boolean | SpinProps;
    tableIndex?: string;
    onRow?: any;
    scrollX?: number;
    subtractHeight?: number;
    onHeaderRow?: GetComponentProps<readonly ColumnType<any>[]>;
    components?: TableComponents<any>;
    summary?: (data: any) => React.ReactNode;
}
function CustomTable(props: CustomTableProps): JSX.Element {
    const {
        isLoading = false,
        rowKey = "id",
        columns,
        data,
        className,
        isRowSelection = true,
        rowSelection,
        tableIndex = null,
        onRow,
        scrollX = 1000,
        onHeaderRow,
        components,
    } = props;
    return (
        <Table
            components={components}
            onRow={onRow}
            id={`custom-table${tableIndex}`}
            bordered
            loading={{
                spinning: isLoading as any,
                indicator: <Loading />,
            }}
            rowKey={rowKey === false ? undefined : rowKey}
            columns={columns}
            dataSource={data}
            className={`custom-table ${className}`}
            pagination={false}
            scroll={{
                scrollToFirstRowOnChange: true,
                x: scrollX,
            }}
            locale={{emptyText: <Empty description="Chưa có dữ liệu" />}}
            rowSelection={isRowSelection ? {...rowSelection, fixed: "left"} : isRowSelection as any}
            onHeaderRow={onHeaderRow}
        />
    );
}

export default CustomTable;
