import React from 'react';
import {observer} from "mobx-react";
import './ModalUpdateContent.scss'
import {Spin, Tooltip} from 'antd';
import {urlImage} from "../../../../../common/utils/Utils";
import {productStore} from "../../../../setting/product/ProductStore";
import {businessPlanStore} from "../../BusinessPlanStore";
import QuillTextEditor from "./QuillTextEditor";
import {Link} from "react-router-dom";
import uploadFileHelper from "../../../../../common/helpers/UploadFileHelper";
const ModalUpdateContent = () => {
    const uploadImgContent = (e: any) => {
        businessPlanStore.dataRequestUpdateContentForJob.images.push(e)
    }
    const uploadVideoContent = (e: any) => {
        businessPlanStore.dataRequestUpdateContentForJob.videos.push(e)
    }
    const uploadFileContent = (e: any) => {
        businessPlanStore.dataRequestUpdateContentForJob.docs.push(e)
    }
    return (
        <div className='modal fade' id='update_content_job_plan' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div
                            className='value_voucher__title'>CẬP NHẬT NỘI DUNG CÔNG VIỆC</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex flex-column justify-content-between'>
                            <div style={{height: '300px'}} className='w-100 mb-4'>
                                <QuillTextEditor
                                    valueQuill={
                                        businessPlanStore.dataRequestUpdateContentForJob.description
                                    }
                                />
                            </div>
                            <div style={{width: '100%'}} className='d-flex flex-column justify-content-between'>
                                <div className="w-100 flex flex-wrap justify-between">
                                    <label className='form-label'>Hình ảnh mô tả<span className='text-danger'>*</span></label>
                                    <div className='list_image'>
                                        <div className='list_'>
                                            <div
                                                className='upload'
                                            >
                                                <img src="/assets/ico/action/upload.svg" alt=""/>
                                                <input
                                                    accept="image/jpeg, image/jpg, image/png"
                                                    type="file"
                                                    onChange={async (e: any) =>
                                                        await uploadFileHelper(
                                                            e,
                                                            uploadImgContent
                                                        )
                                                    }
                                                />
                                            </div>
                                            {businessPlanStore.dataRequestUpdateContentForJob.images &&
                                                businessPlanStore.dataRequestUpdateContentForJob.images.map(
                                                    (item: any, i: number) => {
                                                        return (
                                                            <div className='item' key={i}>
                                                                <img className='img' src={urlImage(item)} alt=""/>
                                                                <div
                                                                    className='close_item'
                                                                    onClick={() =>
                                                                        businessPlanStore.dataRequestUpdateContentForJob.images.splice(
                                                                            i,
                                                                            1
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="/assets/ico/action/button_clear_image.svg"
                                                                         className="icon-action"
                                                                         alt=""
                                                                         title="Chỉnh sửa"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 flex flex-wrap justify-between">
                                    <label className='form-label'>Video mô tả<span className='text-danger'>*</span></label>
                                    <div className='list_image'>
                                        <div className='list_'>
                                            <div
                                                className='upload'
                                            >
                                                <img src="/assets/ico/action/upload.svg" alt=""/>
                                                <input
                                                    accept="video/mp4"
                                                    type="file"
                                                    onChange={async (e: any) =>
                                                        await uploadFileHelper(
                                                            e,
                                                            uploadVideoContent
                                                        )
                                                    }
                                                />
                                            </div>
                                            {businessPlanStore.dataRequestUpdateContentForJob.videos &&
                                                businessPlanStore.dataRequestUpdateContentForJob.videos.map(
                                                    (item: any, i: number) => {
                                                        return (
                                                            <div className='item' key={i}>
                                                                <video autoPlay controls className='img' src={urlImage(item)}/>
                                                                <div
                                                                    className='close_item'
                                                                    onClick={() =>
                                                                        businessPlanStore.dataRequestUpdateContentForJob.videos.splice(
                                                                            i,
                                                                            1
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="/assets/ico/action/button_clear_image.svg"
                                                                         className="icon-action"
                                                                         alt=""
                                                                         title="Chỉnh sửa"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 flex flex-wrap justify-between mb-4">
                                    <label className='form-label'>Danh sách tài liệu<span className='text-danger'>*</span></label>
                                    <div className='list_image'>
                                        <div className='list_'>
                                            <div
                                                className='upload'
                                            >
                                                <img src="/assets/ico/action/upload.svg" alt=""/>
                                                <input
                                                    accept="application/pdf, .doc, .docx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    type="file"
                                                    onChange={async (e: any) =>
                                                        {
                                                            await uploadFileHelper(
                                                                e,
                                                                uploadFileContent
                                                            )
                                                        }
                                                    }
                                                />
                                            </div>
                                            {businessPlanStore.dataRequestUpdateContentForJob.docs &&
                                                businessPlanStore.dataRequestUpdateContentForJob.docs.map(
                                                    (item: any, i: number) => {
                                                        return (
                                                            <div className='item' key={i}>
                                                                <Tooltip title={item.substring(item.indexOf("_") + 1)}>
                                                                    <Link
                                                                        to={urlImage(item)}
                                                                        className='img d-flex flex-column justify-content-center align-items-center'
                                                                        target='_blank'
                                                                    >
                                                                        <img src="/assets/ico/paperclip.svg" className='mb-1' alt=""/>
                                                                        <span className='text-truncate w-100 overflow-hidden'>{item.substring(item.indexOf("_") + 1)}</span>
                                                                    </Link>
                                                                </Tooltip>
                                                                <div
                                                                    className='close_item'
                                                                    onClick={() =>
                                                                        businessPlanStore.dataRequestUpdateContentForJob.docs.splice(
                                                                            i,
                                                                            1
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="/assets/ico/action/button_clear_image.svg"
                                                                         className="icon-action"
                                                                         alt=""
                                                                         title="Chỉnh sửa"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-end'>
                                    <button type='submit' className='btn btn__voucher'
                                            onClick={async () => {
                                                await businessPlanStore.updateContentJob()
                                            }}>{productStore.isLoadingBt ? <Spin size="small" /> : productStore.typeModal === 'add' ? 'Thêm' : 'Cập nhật'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default observer(ModalUpdateContent);

