import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './PickProductPage.scss'
import {orderStore} from "../../OrderStore";
import ProductCardOrder from "./components/productCard/ProductCardOrder";
import {getIDParameter, urlImage} from "../../../../common/utils/Utils";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {Link} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/vi';
import TabHeaderProduct from "./components/tabHeader/TabHeaderProductPick";
import Loading from "../../../../modules/components/Loading";
import {Spin} from "antd";
import ModalShip from "../modalShip/ModalShip";

const PickProductPageOrder = () => {
    useEffect(() => {
        orderStore.dataRequest = {
            ...JSON.parse(localStorage.getItem('dataOrder') as any),
            bookingInfo : JSON.parse(localStorage.getItem('bookingInfo') as any),
            checkinTime: JSON.parse(localStorage.getItem('checkinTime') as any),
            items : []
        }
        if(getIDParameter(3) && getIDParameter(2)) {
            orderStore.codeOrder = getIDParameter(2)
            orderStore.idOrder = getIDParameter(3)
        }
    }, [])
    const updateItemQuantity = (array: any, productId: any, incrementBy: any) => {
        const indexToUpdate = array?.findIndex((item: any) => item.productId === productId);
        if (indexToUpdate !== -1 && indexToUpdate >= 0) {
            array[indexToUpdate] = {
                ...array[indexToUpdate],
                quantity: array[indexToUpdate]?.quantity + incrementBy
            };
        }
        return array;
    }
    const onKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
            await orderStore.getListProduct()
        }
    }
    return (
        <>
            <div className='back_order__page'>
                <Link to='/phuc-vu'>
                    <img src="/assets/ico/action/move-left.svg" alt=""/>
                </Link>
                <div className='ms-3'>
                    <h2>
                        {orderStore.dataRequest.establishmentName}
                    </h2>
                    <span className="text-capitalize">{moment().format('dddd, DD MMMM, YYYY')}</span>
                </div>
            </div>
            <div className='pick_product__order'>
                <div className='pick_product__list'>
                    <div className='name_restaurant__order'>
                        <div className='mb-3'>
                            <TabHeaderProduct />
                        </div>
                        <div className="input-search-container">
                            <img src="/assets/ico/action/icon_search.svg" onClick={async () => await orderStore.getListProduct()} alt=""/>
                            <input onChange={(e: any) => {
                                orderStore.keyword = e.target.value
                            }} onKeyDown={(e: any) => onKeyDown(e)} type="text" placeholder="Tìm kiếm món ăn..." className="search-input" />
                        </div>
                    </div>
                    {orderStore.isLoading ? <Loading />: <div className='list_product__item custom-scrollbar'>
                        {
                            orderStore.listProduct.length > 0 && orderStore.listProduct.map((item: any, index: number) => {
                                let qtyPickProduct = orderStore.dataRequest.items.filter((i: any) => i.productId === item.id)
                                return(
                                    <ProductCardOrder qty={qtyPickProduct?.[0]?.quantity} key={index} onClick={() => {
                                        let tmpProduct = []
                                        tmpProduct = orderStore.dataRequest.items.filter((itemList: any) => itemList.productId === item.id)
                                        if(tmpProduct?.length <= 0) {
                                            orderStore.dataRequest.items.push({
                                                image: urlImage(item?.images?.[0]),
                                                name: item?.name,
                                                price: item?.price,
                                                note: '',
                                                quantity: 1,
                                                productId: item?.id
                                            })
                                        }
                                        else {
                                            updateItemQuantity(orderStore.dataRequest.items, tmpProduct?.[0].productId, 1)
                                        }
                                    }} image={urlImage(item?.images?.[0])} description={item?.description} name={item?.name} price={item?.price} />
                                )
                            })
                        }
                    </div>}
                </div>
                    <div className="pick_product__total">
                        <div className='header_total'>
                            <h2>
                                Đơn hàng
                                <span style={{color: '#515158'}} className='ms-2 fst-italic'>#{orderStore.codeOrder}</span>
                            </h2>
                            {orderStore.dataRequest?.items?.length > 0 && <div className='header_total__title'>
                                <div className="w-75">
                                    Sản phẩm
                                </div>
                                <div  className="w-25">
                                    Số lượng
                                </div>
                            </div>}
                        </div>
                        {orderStore.dataRequest?.items?.length > 0 ? <div className='w-100'>
                            <div className='list_product w-100 custom-scrollbar pe-3'>
                                {
                                    orderStore.dataRequest.items.map((item: any, index: number) => {
                                        return(
                                            <div key={index} className='w-100 mb-3 list_product__container'>
                                                <div className='d-flex align-items-center w-100 mb-2'>
                                                    <img src={item.image} className='image_product__item' alt=""/>
                                                    <div className='d-flex flex-column w-100'>
                                                        <div className='name_product__item'>
                                                            <h2 className='name_product__item'>{item.name}</h2>
                                                            <button
                                                                onClick={() => {
                                                                    orderStore.dataRequest.items.splice(index, 1)
                                                                }}
                                                            >
                                                                <img
                                                                    className="btn_delete__product-order"
                                                                    src="/assets/ico/action/eraser.svg" alt=""/>
                                                            </button>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <span className='price_product__item'>{numberUtil.number_format(item.price)}</span>
                                                            <span className='totalPrice_product__item'>{numberUtil.number_format(item.price * item.quantity)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-100 d-flex justify-content-between align-items-center'>
                                                    <input onChange={(e: any) => item.note = e.target.value} className='input_note__order' placeholder='Ghi chú...' type="text"/>
                                                    <div className='w-30 select_qty__product'>
                                                        <button onClick={() => {
                                                            if(item.quantity > 1) item.quantity--
                                                        }}>
                                                            <img src="/assets/ico/action/minus.svg" alt=""/>
                                                        </button>
                                                        <div className='qty_product__order'>
                                                            {item.quantity}
                                                        </div>
                                                        <button onClick={() => {
                                                            item.quantity++
                                                        }}>
                                                            <img src="/assets/ico/action/plus.svg" alt=""/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='w-100 button_finish__order'>
                                <div className='d-flex justify-content-between align-items-center pt-3'>
                                    <h2>
                                        Tổng :
                                    </h2>
                                    <h5>
                                        {numberUtil.number_format(orderStore.dataRequest.items.reduce((accumulator: any, currentItem: any) => {
                                            return accumulator + currentItem.price * currentItem.quantity;
                                        }, 0))} <span>đ</span>
                                    </h5>
                                </div>
                                <button onClick={async () => {
                                    (!orderStore.idOrder) ? await orderStore.addOrder() : await orderStore.addOrderItem(orderStore.isBackToDetailPage)}}>
                                    {orderStore.isLoadingBt ? <Spin size="small" /> : "Xác nhận"}
                                </button>
                            </div>
                        </div> :  <div className='no_product w-100 custom-scrollbar'>
                            <img src="/assets/ico/no_product.svg" alt=""/>
                            <span className='no_product__title'>Bạn chưa chọn sản phẩm nào!</span>
                        </div> }
                    </div>
            </div>
            <ModalShip/>
        </>
    );
}

export default observer(PickProductPageOrder);