import React, {useEffect, useRef} from 'react';
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {observer} from "mobx-react";
import {inventoryReportStore} from "./InventoryReportStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import ModalSelectRestaurant from "../../order/components/selectRestaurant/SelectRestaurant";
import {kitchenStore} from "../../kitchenManager/KitchenStore";
import {orderStore} from "../../order/OrderStore";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";

const InventoryReportPage = () => {
    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: inventoryReportStore.keyword,
            onChange: (e: any) => inventoryReportStore.keyword = e,
            callback: async () => await inventoryReportStore.getInventoryReport()
        },
    ]
    useEffect(() => {
        if(!inventoryReportStore.searchEstablishmentId) {
            inventoryReportStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
        }
        if(!inventoryReportStore.establishmentName) {
            inventoryReportStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
        }
        inventoryReportStore.getInventoryReport().then()
        orderStore.getListRestaurant().then(() => {
            if (modalRef.current && !JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId && orderStore.listRestaurant.length > 0) {
                (modalRef.current as HTMLButtonElement).click();
            }
        })
    }, [])

    const modalRef = useRef(null);

    return (
        <div className='inventory_report_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Báo cáo tồn kho ( {inventoryReportStore?.establishmentName ? inventoryReportStore?.establishmentName : 'Tất cả điểm bán'} )</h4>
                <FilterComponent permissionCode={true} data={dataFilter}/>
            </div>
            <button ref={modalRef}  data-bs-toggle="modal" data-bs-target='#select_restaurant_order' className='btn_select_restaurant mb-3'>Chuyển điểm bán</button>
            {inventoryReportStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th rowSpan={2} className="text-center">STT</th>
                        <th rowSpan={2} className="text-center">Mã vật tư</th>
                        <th rowSpan={2} className="text-center">Tên vật tư</th>
                        <th rowSpan={2} className="text-center">ĐVT</th>
                        <th colSpan={2} className="text-center">Tồn đầu kỳ</th>
                        <th colSpan={2} className="text-center">Nhập trong kỳ</th>
                        <th colSpan={2} className="text-center">Xuất trong kỳ</th>
                        <th colSpan={2} className="text-center">Tồn cuối kỳ</th>
                    </tr>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thành tiền</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thành tiền</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thành tiền</th>
                        <th className="text-center">Số lượng</th>
                        <th className="text-center">Thành tiền</th>
                    </tr>
                    </thead>
                    <tbody>
                    {inventoryReportStore.listInventoryReport && inventoryReportStore.listInventoryReport.length > 0 ? inventoryReportStore.listInventoryReport.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item?.supplies?.code}</td>
                                <td>{item?.supplies?.name}</td>
                                <td className="text-center">{item?.supplies?.unit}</td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                                <td className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                <td className="text-center"></td>
                            </tr>
                        )
                    }) : <NoContent colSpan={12}/>}
                    {inventoryReportStore.listInventoryReport && inventoryReportStore.listInventoryReport.length > 0 &&
                        <tr>
                            <td colSpan={4} className="text-center fw-500">Tổng cộng</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500">-</td>
                            <td className="text-center fw-500"> {numberUtil.number_format(inventoryReportStore.listInventoryReport.reduce((accumulator: any, currentItem: any) => {
                                return accumulator + (currentItem.qty ? currentItem.qty : 0);
                            }, 0))}</td>
                            <td className="text-center"></td>
                        </tr>
                    }
                    </tbody>
                </table>

            </div>}
            <ModalSelectRestaurant />
        </div>
    );
}

export default observer(InventoryReportPage);