import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {importExportStore} from "./ImportExportStore";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import {warehouseStore} from "../../setting/warehouse/WarehouseStore";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddImpExpRequest from "./components/AddImpExpRequest";
import {suppliesStore} from "../../setting/supplies/SuppliesStore";
import PopupImpExpDetail from "./components/PopupImpExpDetail";
import ModalSelectTypeImport from "./components/modalSelectTypeImport/ModalSelectTypeImport";
import ConfirmSuppliesImport from "./components/ConfirmSuppliesImport";
export const convertStatusImportRequest= (value: string) => {
    switch (value.toUpperCase()) {
        case "APPROVED":
            return <span className="color-green fw-500">Hoàn thành</span>
        case "PROCESSING":
            return <span className="color-purple fw-500">Đang xử lý</span>
        case "EXPORTED":
            return <span style={{fontWeight: '500', color: '#EF7752'}}>Đã xuất kho</span>
        case "REJECT":
            return <span className="color-red fw-500">Hủy</span>
        case "PENDING":
            return <span style={{fontWeight: '500', color: '#808089'}}>Đang chờ</span>
        default:
            return null
    }
}
const ReceiptPage = () => {

    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: importExportStore.keyword,
            onChange: (e: any) => importExportStore.keyword = e,
            callback: async () => await importExportStore.getImpExpRequest()
        },
    ]

    useEffect(() => {
        importExportStore.typeImportExport = window.location.pathname.split('/')[1]
        importExportStore.getImpExpRequest().then()
        warehouseStore.getWarehouse().then()
        return () => {
            suppliesStore.type = null
            importExportStore.keyword = ''
            importExportStore.searchWarehouse = null
            suppliesStore.listSupplies = []
            warehouseStore.listWarehouse = []
        }
    }, [])

    return (
        <div className="receipt_page">
            <div className='tab_filter_header'>
                <h4 className='title'>Đề nghị nhập kho</h4>
                <FilterComponent permissionCode={true} data={dataFilter}
                                 buttonAdd={<ButtonGlobal onClick={() => {importExportStore.clearForm()}} dataTarget="select_type_import" text='Thêm' ico='add_icon' className='btn_add'/>}/>
            </div>
            {importExportStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Mã phiếu đề nghị <br/> nhập kho</th>
                        <th className="text-center">Người đề nghị</th>
                        <th className="text-center">Mục đích sử dụng</th>
                        <th className="text-center">Kho</th>
                        <th className="text-center">Trạng thái</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {importExportStore.listImportExportRequest && importExportStore.listImportExportRequest.length > 0 ? importExportStore.listImportExportRequest.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{dateUtils.formatDate(item.timeRequest, 'hh:mm , DD/MM/YYYY')}</td>
                                <td className="text-center">{item.code}</td>
                                <td className="text-center">{item.requestUser?.name}</td>
                                <td>{item.purpose}</td>
                                <td className="text-center">{item?.mainWarehouse?.name}</td>
                                <td className="text-center">{convertStatusImportRequest(item.status)}</td>
                                <td className="text-center">
                                    <button className="btn p-0 mx-1"
                                        onClick={() => importExportStore.getDetailRequest(item.id)} data-bs-toggle="modal" data-bs-target='#popup_imp_exp_request'>
                                        <img src="/assets/ico/action/detail_icon.svg" alt=""/>
                                    </button>
                                    {item?.status === "EXPORTED"
                                        && <button className="btn p-0 mx-1"
                                                   data-bs-toggle="modal" data-bs-target='#confirm_supplies_import'
                                                   onClick={async () => {
                                                       importExportStore.id = item.id
                                                       await importExportStore.getDetailRequest(item.id)
                                                   }
                                                   }
                                        >
                                            <img src="/assets/ico/action/check-circle-2.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xác nhận nhập kho"/>
                                        </button>}
                                </td>
                            </tr>
                        )
                    }) : <NoContent colSpan={8}/>}
                    </tbody>
                </table>
            </div>}

            <AddImpExpRequest text="NHẬP"/>
            <ModalSelectTypeImport />
            <PopupImpExpDetail/>
            <ConfirmSuppliesImport/>
        </div>
    );

}

export default observer(ReceiptPage);