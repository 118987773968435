import {orderStore} from "./OrderStore";
import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
class OrderService{
    public getListProduct(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product?page=0${orderStore.keyword ? `&keyword=${orderStore.keyword}` : ''}${orderStore.tabActiveProduct && orderStore.tabActiveProduct !== 'ALL' ? `&type=${orderStore.tabActiveProduct}` : ''}&size=500`)
    }
    public getListOrder(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/order/fnb?page=${orderStore.page}${orderStore.dataRequest.establishmentId ? `&establishmentId=${orderStore.dataRequest.establishmentId}` : ''}${orderStore.searchStatusOrder ? `&status=${orderStore.searchStatusOrder}` : ''}${orderStore.searchTypeOrder ? `&type=${orderStore.searchTypeOrder}` : ''}${orderStore.searchKeywordOrder ? `&keyword=${orderStore.searchKeywordOrder}` : ''}${orderStore.fromTime ? `&fromTime=${orderStore.fromTime}` : ''}${orderStore.toTime ? `&toTime=${orderStore.toTime}` : ''}&size=${size ?? 15}`)
    }
    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product/${id}`)
    }
    public addOrder(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/order`, data)
    }
    public editOrder(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/order/${id}`, data)
    }
    public getDetailOrder(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/order/${id}`)
    }
    public addOrderItem(data: any, id: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/order/${id}/item`, data)
    }
    public getListRoom(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/room?page=${orderStore.page}${orderStore.dataRequest.establishmentId ? `&establishmentId=${orderStore.dataRequest.establishmentId }` : ''}&size=${size ?? 50}`)
    }
    public getListRestaurant(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/establishment`)
    }
    public getListTable(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/table?page=${orderStore.page}${orderStore.dataRequest.establishmentId ? `&establishmentId=${orderStore.dataRequest.establishmentId}` : ''}${orderStore.dataRequest.roomId ? `&roomId=${orderStore.dataRequest.roomId}` : ''}&size=100`)
    }
}
export const orderService = new OrderService()