import React from 'react';
import {observer} from "mobx-react";
import './ChainStore.scss'

const ChainStorePage = () => {
    return (
        <div className='chain_container'>
            Chức năng đang phát triển
        </div>
    );
}

export default observer(ChainStorePage);