import React, {useEffect} from 'react';
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import Loading from "../../../modules/components/Loading";
import {sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../modules/components/NoContent";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {suppliesStore} from "./SuppliesStore";
import {observer} from "mobx-react";

const SuppliesPage = () => {

    const handlePageClick = async (data: any) => {
        suppliesStore.page = data.selected;
        await suppliesStore.getSupplies();
    };


    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo tên, mã...',
            value: suppliesStore.keyword,
            onChange: (e: any) => suppliesStore.keyword = e,
            callback: async () => await suppliesStore.getSupplies()
        }
    ]

    useEffect(() => {

        return () => {
            suppliesStore.typeModal = '';
            suppliesStore.clearForm()
        }
    }, [])

    return (
        <div className='supplies_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Kho {suppliesStore.returnName()}</h4>
                <FilterComponent permissionCode={true} data={dataFilter} buttonAdd={<ButtonGlobal onClick={() => {
                    suppliesStore.typeModal = 'add';
                    suppliesStore.clearForm()
                }} text='Thêm' ico='add_icon' className='btn_add'/>}/>
            </div>
            {suppliesStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Tên {suppliesStore.returnName()}</th>
                        <th className="text-center">Mã {suppliesStore.returnName()}</th>
                        <th className="text-center">ĐVT</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {suppliesStore.listSupplies && suppliesStore.listSupplies.length > 0 ? suppliesStore.listSupplies.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width="5%" className="text-center">{sttPage(suppliesStore.page, i)}</td>
                                <td width="40%">{item.name}</td>
                                <td width="25%" className="text-center">{item.code}</td>
                                <td width="15%" className="text-center">{item.unit}</td>
                                <td className="text-center">
                                    <button className="btn p-0 mr-1"
                                            onClick={async () => await suppliesStore.getDetail(item.id)}>
                                        <img src="/assets/ico/action/edit_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Chỉnh sửa"/>
                                    </button>

                                    <button className="btn p-0" data-bs-toggle="modal"
                                            data-bs-target="#popup_confirm"
                                            onClick={() => suppliesStore.id = item.id}>
                                        <img src="/assets/ico/action/delete_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Xóa"/>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) : <NoContent colSpan={5}/>}
                    {(suppliesStore.typeModal == "add" || suppliesStore.typeModal == "edit") && <tr>
                        <td width="5%"/>
                        <td width="40%"><input type="text"
                                               className={`form-control ${!suppliesStore.dataRequest.name && suppliesStore.errors.name && 'border_error'}`}
                                               value={suppliesStore.dataRequest.name}
                                               onChange={(e: any) => suppliesStore.dataRequest.name = e.currentTarget.value}
                                               placeholder={`Nhập tên ${suppliesStore.returnName()}`}/></td>
                        <td width="25%" className="text-center">
                            <input type="text"
                                   className={`form-control w-75 d-inline-block text-center ${!suppliesStore.dataRequest.code && suppliesStore.errors.code && 'border_error'}`}
                                   value={suppliesStore.dataRequest.code}
                                   onChange={(e: any) => suppliesStore.dataRequest.code = e.currentTarget.value}
                                   placeholder={`Mã ${suppliesStore.returnName()}`}/>
                        </td>
                        <td width="15%" className="text-center">
                            <input type="text" className={`form-control w-75 d-inline-block text-center ${!suppliesStore.dataRequest.unit && suppliesStore.errors.unit && 'border_error'}`}
                                   value={suppliesStore.dataRequest.unit}
                                   onChange={(e: any) => suppliesStore.dataRequest.unit = e.currentTarget.value}
                                   placeholder="Đơn vị"/>
                        </td>
                        <td/>
                    </tr>}

                    </tbody>
                </table>
                {(suppliesStore.typeModal == "add" || suppliesStore.typeModal == "edit") &&
                <div className="d-flex w-100 mb-4 justify-content-end">
                    <button onClick={() => suppliesStore.typeModal = ""} className='button_cancel__formtable'>Hủy
                    </button>
                    <button className='button_save__formtable' disabled={suppliesStore.isLoadingBt}
                            onClick={() => suppliesStore.addEdit()}>Lưu
                    </button>
                </div>}
            </div>}
            <PopupConfirm action={suppliesStore.delete} isLoading={suppliesStore.isLoadingBt}/>
        </div>
    );
}


export default observer(SuppliesPage);
