import React from 'react';


const Loading = (height?: any) =>{

    return (
        <div className="d-flex align-items-center justify-content-center" style={{height: height ?? 350}}>
            <div className="dot-opacity-loader my-5">
                <span/>
                <span/>
                <span/>
            </div>
        </div>
    )
}

export default Loading;
