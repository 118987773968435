import React, {useEffect} from 'react';
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import {dateUtils} from "../../../common/utils/DateUtils";
import NoContent from "../../../modules/components/NoContent";
import {importExportStore} from "./ImportExportStore";
import {observer} from "mobx-react";
import {numberUtil} from "../../../common/utils/NumberUtil";


const ImportExportPage = () => {

    useEffect(() => {
        importExportStore.typeImportExport = window.location.pathname.split('/')[1]
        importExportStore.typeSupplies = window.location.pathname.split('/')[2]
        importExportStore.getImpExpSupplies().then()
    }, [])

    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: importExportStore.keyword,
            onChange: (e: any) => importExportStore.keyword = e,
            callback: async () => await importExportStore.getImpExpSupplies()
        },
    ]

    return (
        <div className='warehouse_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>{importExportStore.typeImportExport == 'import' ? 'Nhập' : 'Xuất'} kho {importExportStore.returnName()}</h4>
                <FilterComponent permissionCode={true} data={dataFilter}/>
            </div>
            {importExportStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th rowSpan={2} className="text-center">STT</th>
                        <th rowSpan={2} className="text-center">Thời gian</th>
                        <th rowSpan={2} className="text-center">Mã nhập kho</th>
                        <th rowSpan={2} className="text-center">Tên vật tư</th>
                        <th rowSpan={2} className="text-center">Mã vật tư</th>
                        <th rowSpan={2} className="text-center">ĐVT</th>
                        <th rowSpan={2} className="text-center">Hạn sử dụng</th>
                        <th rowSpan={2} className="text-center">Đơn giá (VNĐ)</th>
                        <th colSpan={2} className="text-center">Số lượng</th>
                        <th rowSpan={2} className="text-center">Thành tiền (VNĐ)</th>
                    </tr>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                        <th className="text-center">Theo chứng từ</th>
                        <th className="text-center">Thực {importExportStore.typeImportExport == 'import' ? 'nhập' : 'xuất'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {importExportStore.listSupplies && importExportStore.listSupplies.length > 0 ? importExportStore.listSupplies.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td width="3%" className="text-center">{i + 1}</td>
                                <td width="9%" className="text-center">{dateUtils.formatDate(item?.requestWarehouse?.timeRequest, 'hh:mm , DD/MM/YYYY')}</td>
                                <td width="8%" className="text-center">{item?.requestWarehouse?.code}</td>
                                <td width="12%">{item?.supplies?.name}</td>
                                <td width="8%" className="text-center">{item?.supplies?.code}</td>
                                <td width="6%" className="text-center">{item?.supplies?.unit}</td>
                                <td width="10%" className="text-center">{dateUtils.formatDate(item?.expiryDate)}</td>
                                <td width="8%" className="text-center">{item?.price ? numberUtil.number_format(item?.price) : '-'}</td>
                                <td width="8%" className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                <td width="6%" className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                <td width="8%" className="text-center">{(item?.price && item?.qty) ? numberUtil.number_format(item?.qty * item?.price) : '-'}</td>
                            </tr>
                        )
                    }) : <NoContent colSpan={11}/>}
                    {importExportStore.listSupplies && importExportStore.listSupplies.length > 0 && <tr>
                        <td colSpan={8} className="text-center fw-500">Tổng cộng</td>
                        <td className="text-center fw-500"> {numberUtil.number_format( importExportStore.listSupplies.reduce((accumulator: any, currentItem: any) => {
                            return accumulator + ((currentItem.qty) ? currentItem.qty : 0);
                        }, 0))}</td>
                        <td className="text-center fw-500"> {numberUtil.number_format( importExportStore.listSupplies.reduce((accumulator: any, currentItem: any) => {
                            return accumulator + ((currentItem.qty) ? currentItem.qty : 0);
                        }, 0))}</td>
                        <td className="text-center fw-500"> {numberUtil.number_format( importExportStore.listSupplies.reduce((accumulator: any, currentItem: any) => {
                            return accumulator + ((currentItem.price && currentItem.qty) ? currentItem.price * currentItem.qty : 0);
                        }, 0))}<span className='text-decoration-underline ms-1'>đ</span></td>
                    </tr>}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}


export default observer(ImportExportPage);