import React from 'react';
import {observer} from "mobx-react";
import {kitchenStore} from "../KitchenStore";
import { dateUtils } from '../../../common/utils/DateUtils';
import {numberUtil} from "../../../common/utils/NumberUtil";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";


const DetailKitchen = () => {

    const convertStatus = (value: string) => {
        switch (value) {
            case "SUCCESS":
                return <span className="color-green" style={{fontWeight: '500'}}>Hoàn thành</span>
            case "IN_PROGRESS":
                return <span className="color-purple fw-medium" style={{fontWeight: '500'}}>Đang làm</span>
            case "CANCEL":
                return <span className="color-red fw-medium" style={{fontWeight: '500'}}>Hủy</span>
            case "PENDING":
                return <span className="color-pending fw-medium" style={{fontWeight: '500'}}>Chưa làm</span>
            default:
                return null
        }
    }
    return (
        <div className="modal fade" id="detail_kitchen" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 1350}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"/>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    {kitchenStore.isLoadingDt ? <Loading/> : <div className='table-responsive custom_scroll mt-4'>
                        <table className='table align-middle'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Giờ gọi</th>
                                <th className="text-center">Tình trạng</th>
                                <th>Tên sản phẩm</th>
                                <th>Mã sản phẩm</th>
                                <th className="text-center">Đơn vị</th>
                                <th className="text-center">Số lượng</th>
                                <th>Ghi chú</th>
                            </tr>
                            </thead>
                            <tbody>
                            {kitchenStore.dataDetail && kitchenStore.dataDetail.length > 0 ? kitchenStore.dataDetail.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt, 'HH:MM')}</td>
                                        <td className="text-center">{convertStatus(item.status)}</td>
                                        <td>{item.product?.name}</td>
                                        <td>{item.product?.batch}</td>
                                        <td className="text-center">{item.product?.unit}</td>
                                        <td className="text-center">{item.quantity}</td>
                                        <td>{item.note}</td>
                                    </tr>
                                )
                            }) : <NoContent colSpan={8}/>}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default observer(DetailKitchen);