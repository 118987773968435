import React from 'react';
import "./FilterGlobal.scss"
import {Input, Select} from "antd";
import {observer} from "mobx-react";

export interface IFilter {
    type: string,
    label?: string,
    value: any,
    callback?: any,
    width?: any,
    onChange?: any,
    placeholder?: any,
    listValue?: any[],
}

interface IProps {
    buttonAdd?: any,
    searchYear?: any,
    isSearchYear?: boolean,
    permissionCode?: boolean,
    data?: IFilter[]
}

export enum IType {
    date = 'date',
    select = "select",
    search = 'search'
}

const FilterComponent = ({buttonAdd, data, permissionCode, isSearchYear, searchYear}: IProps) => {

    const onKeyDown = async (e: any, item: any) => {
        if (e.key === 'Enter') {
            await item.callback()
        }
    }

    const switchCase = (item: IFilter) => {
        switch (item.type) {
            case IType.date:
                return <div className="search_date">
                    <input type="date" value={item.value} onChange={(e: any) => {
                        item.callback(e.currentTarget.value);
                        item.value = e.currentTarget.value
                    }} placeholder={item.placeholder ?? ''}/>
                </div>
            case IType.select:
                return <div className='d-flex align-items-center'>
                    {/*{item.label && <div style={{fontSize: '16px', fontWeight: '400', lineHeight: '140%', width: 'max-content'}} className='me-2'>*/}
                    {/*    {item.label} :*/}
                    {/*</div>}*/}
                        <div className="filter_select">
                            <Select allowClear onChange={(e: any, value: any) => {
                                item.callback(e, value);
                                item.value = e
                            }}  placeholder={item.placeholder} value={item.value} options={item.listValue}>
                            </Select>
                        </div>
                </div>

            case IType.search:
                return <div className="filter_search" style={{width: `${item.width ?? '189px'}`}}>
                    <Input prefix={<img className='img_prefix' src='/assets/ico/action/search.svg' alt='' onClick={() => item.callback()}/>} type="text" onChange={(e: any) => {
                        item.onChange(e.currentTarget.value);
                        item.value = e.currentTarget.value
                    }} onKeyDown={(e: any) => onKeyDown(e, item)} placeholder={item.placeholder ?? ''}/>

                </div>
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-end filter_header">
            <div className="d-flex align-items-center">
                {data && data.map((item, i) => {
                    return <div className="d-flex form_search align-items-center" key={i}>
                        {switchCase(item)}
                    </div>
                })}
                {isSearchYear && <div className="d-flex form_search align-items-center">
                    {searchYear}
                </div>}
                {permissionCode && buttonAdd}
            </div>
            <img className='ms-2' src="/assets/ico/list-filter.svg" alt=""/>
        </div>
    );
}

export default observer(FilterComponent);