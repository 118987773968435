import {makeAutoObservable} from 'mobx'
class SignUpStore {

    isLogin: boolean = true

    constructor() {
        makeAutoObservable(this)
    }

}

export const signUpStore = new SignUpStore()