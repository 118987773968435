import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {warehouseStore} from "./WarehouseStore";

class WarehouseService {

    public getWarehouse(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse?page=${warehouseStore.page}&size=100${warehouseStore.type ? `&type=${warehouseStore.type}` : ''}${warehouseStore.keyword ? `&keyword=${warehouseStore.keyword}` : ''}`)
    }

    public getDetail(id: number): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/${id}`)
    }

    public getEstablishment(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/establishment?page=0&size=500`)
    }

    public getUser(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/staff?page=0&size=500`)
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/warehouse`, data)
    }

    public edit(id: number, data: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/warehouse/${id}`, data)
    }

    public delete(id: number): Promise<IApiResponse> {
        return deleteRequest(`/iss_365_fnb/warehouse/${id}`, {})
    }

    public getProvinces(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/provinces`)
    }

    public getDistricts(provinceId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/districts?provinceId=${provinceId}`)
    }

    public getWards(districtId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/wards?districtId=${districtId}`)
    }



}

export const warehouseService = new WarehouseService()