import React from 'react';
import {observer} from "mobx-react";
import './ChartFeedback.scss'
import { Tooltip } from 'antd';
interface IProps {
    title: string
    type: string
    data: {
        value: number,
        label: string
    }[]
}
const convertType = (type: any) => {
    switch (type) {
        case "1":
            return 'chart_feedback__item'
        case "2":
            return 'chart_feedback__item--2'
        case "3":
            return 'chart_feedback__item--3'
        case "4":
            return 'chart_feedback__item--4'
        default:
            return ''
    }
}
const ChartFeedback = ({title, type, data} : IProps) => {
    let sumChart = data.reduce((accumulator: any, currentItem: any) => {
        return accumulator + currentItem.value;
    }, 0)


    return (
            <div className='chart_feedback__container'>
                <div className='chart_feedback__container--title'>
                    {title}
                </div>
                {
                    data.map((item: {
                        value: number,
                        label: string
                    }, index: number) => {
                        let tooltipContent = `Phản hồi : ${title} <br />${item.label} : ${item.value}<br />Tổng số phản hồi : ${sumChart}<br />Tỷ lệ : ${(item.value * 100 / sumChart).toFixed(2)}%`;
                        return(
                            <Tooltip key={index} title={<span dangerouslySetInnerHTML={{ __html: tooltipContent }} />}>
                                <div className={convertType(type)} key={index} style={{width: `${(item.value * 100 / sumChart)}%`}}>
                                </div>
                            </Tooltip>
                        )
                    })
                }
            </div>
    )

}

export default observer(ChartFeedback);

