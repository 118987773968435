import {makeAutoObservable} from "mobx";
import {HttpStatusCode} from "axios";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery'
import {customerBenefitService} from "./CustomerBenefitService";


class CustomerBenefitStore{
    id: any = ''
    page: number = 0
    totalPage: number = 0
    typeModal: any = ''
    isLoading: boolean = false
    isLoadingBt: boolean = false
    dataRequest: any = {
        rankName: "",
        revenueFrom: "",
        discountValue: "",
        discountType: "VALUE",
        note: ""
    }
    errors : any = {
        rankName: "",
        revenueFrom: "",
        discountValue: "",
        discountType: "",
    }
    listCustomerBenefit: any[] = []

    clearForm(){
        this.typeModal = 'add'
        this.dataRequest = {
            rankName: "",
            revenueFrom: "",
            discountValue: "",
            discountType: "VALUE",
            note: ""
        }
        this.errors = {
            rankName: "",
            revenueFrom: "",
            discountValue: "",
            discountType: "",
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
    async getCustomerBenefit(){
        this.isLoading = true
        const result = await customerBenefitService.getBenefit()
        this.isLoading = false
        if(result.status === HttpStatusCode.Ok){
            this.listCustomerBenefit = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: number){
        this.typeModal = 'edit'
        const result = await customerBenefitService.getDetail(id)
        if(result.status === HttpStatusCode.Ok){
            this.dataRequest = {
                rankName: result.body?.rankName,
                revenueFrom: result.body?.revenueFrom,
                discountValue: result.body?.discountValue,
                discountType: result.body?.discountType,
                note: result.body?.interest?.note
            }
        }
    }
    async addEdit(){
        if(!this.dataRequest.rankName) {
            this.errors.rankName = 'Tên phân loại khách hàng không được để trống'
            return
        }
        else this.errors.rankName = ''

        if(!this.dataRequest.revenueFrom) {
            this.errors.revenueFrom = 'Mức doanh thu khách hàng không được để trống'
            return
        }
        else this.errors.revenueFrom = ''

        if(!this.dataRequest.discountValue) {
            this.errors.discountValue = 'Mức ưu đãi không được để trống'
            return
        }
        else this.errors.discountValue = ''

        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await customerBenefitService.add(this.dataRequest) : await customerBenefitService.edit(this.id, this.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.Ok){
            toastUtil.success(`${this.typeModal === 'add' ? 'Tạo' : 'Cập nhật'} quyền lợi thành viên thành công!`)
            $('#close_customer_benefit').trigger('click')
            this.clearForm()
           await this.getCustomerBenefit()
        }else toastUtil.error(result.body.message, 2)
    }

    async delete() {
        customerBenefitStore.isLoadingBt = true
        const result = await customerBenefitService.delete(customerBenefitStore.id)
        customerBenefitStore.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa quyền lợi thành công!')
            await  customerBenefitStore.getCustomerBenefit()
        } else toastUtil.error(result.body.message)
    }

}

export const customerBenefitStore = new CustomerBenefitStore()