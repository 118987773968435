import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import $ from "jquery";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {warehouseService} from "./WarehouseService";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {importExportStore} from "../../warehouses/importExportSupplies/ImportExportStore";
import {validatePhoneNumber} from "../../../common/utils/Utils";

class WarehouseStore {
    id: any = ''
    typeModal: string = ''
    type: any = null
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listWarehouse: any[] = []
    listEstablishment: any[] = []
    listStaff: any[] = []
    listProvince: any[] = []
    listDistrict: any[] = []
    listWard: any[] = []
    error: any = {
        name: '',
        code: '',
        phone: '',
        type: '',
        user: '',
        province: '',
        district: '',
        ward: '',
        address: '',
        establishment: ''
    }
    dataRequest: any = {
        id: '',
        name: '',
        code: '',
        phone: '',
        type: null,
        user: {
            id: null
        },
        establishmentId: null,
        address: {
            province: {
                id: null
            },
            district: {
                id: null
            },
            ward: {
                id: null
            },
            detail: ''
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearError() {
        this.error = {
            name: '',
            code: '',
            phone: '',
            type: '',
            user: '',
            province: '',
            district: '',
            ward: '',
            address: '',
            establishment: ''
        }
    }

    clearForm() {
        this.clearError()
        this.typeModal = 'add'
        this.dataRequest = {
            id: '',
            name: '',
            code: '',
            phone: '',
            type: null,
            user: {
                id: null
            },
            establishmentId: null,
            address: {
                province: {
                    id: null
                },
                district: {
                    id: null
                },
                ward: {
                    id: null
                },
                detail: ''
            }
        }
    }

    async changeProvince(provinceId: any) {
        this.dataRequest.address.province.id = provinceId
        this.dataRequest.address.district.id = null
        this.dataRequest.address.ward.id = null
        await this.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        this.dataRequest.address.district.id = districtId
        this.dataRequest.address.ward.id = null
        await this.getWards(districtId)
    }


    async getProvinces() {
        const result = await warehouseService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            this.listProvince = result.body
        }
    }

    async getDistricts(provinceId: any) {
        const result = await warehouseService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            this.listDistrict = result.body
        }
    }

    async getWards(districtId: any) {
        const result = await warehouseService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            this.listWard = result.body
        }
    }

    async getWarehouse() {
        this.isLoading = true
        const result = await warehouseService.getWarehouse()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listWarehouse = result.body.data
            importExportStore.listWarehouseByUser = result.body.data.filter((item: any) => item.user?.id !== Number(localStorage.getItem('fnbUserId')))
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getEstablishment() {
        const result = await warehouseService.getEstablishment()
        if (result.status === HttpStatusCode.OK) {
            this.listEstablishment = result.body.data
        }
    }

    async getUser() {
        const result = await warehouseService.getUser()
        if (result.status === HttpStatusCode.OK) {
            this.listStaff = result.body.data
        }
    }


    async getDetail(id: number) {
        this.typeModal = 'edit'
        const result = await warehouseService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
            if(!result.body.address){
                this.dataRequest.address = {
                    province: {
                        id: null
                    },
                    district: {
                        id: null
                    },
                    ward: {
                        id: null
                    },
                    detail: ''
                }
            }else {
                this.dataRequest.address?.province?.id && await this.getDistricts(this.dataRequest.address?.province?.id)
                this.dataRequest.address?.district?.id && await this.getWards(this.dataRequest.address?.district?.id)
            }
        }
    }

    async addEdit() {
        this.clearError()
        let {id, name, code, user, establishmentId, phone, address, type} = this.dataRequest
        let data = {
            establishmentId: establishmentId,
            name: name,
            code: code,
            phone: phone,
            type: type,
            userId: user.id,
            provinceId: address.province.id,
            districtId: address.district.id,
            wardId: address.ward.id,
            address: address.detail
        }
        if (!establishmentId) {
            this.error.establishment = "Cửa hàng không được để trống!"
        }

        if (!name) {
            this.error.name = "Tên kho không được để trống!"
        }
        if (!code) {
            this.error.code = "Mã kho không được để trống!"
        }
        if (!phone) {
            this.error.phone = "SĐT không được để trống!"
        }
        if (!validatePhoneNumber(phone)) {
            warehouseStore.dataRequest.phone = ''
            this.error.phone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            return
        }
        if (!user.id) {
            this.error.user = "Trưởng kho được để trống!"
        }
        if (!type) {
            this.error.type = "Loại kho không được để trống!"
        }
        if (!address.province.id) {
            this.error.province = "Tỉnh/Thành phố không được để trống!"
        }
        if (!address.district.id) {
            this.error.district = "Quận/Huyện không được để trống!"
        }
        if (!address.ward.id) {
            this.error.ward = "Phường/Xã không được để trống!"
        }
        if (!address.detail) {
            this.error.address = "Địa chỉ cụ thể không được để trống!"
        }

        if (numberUtil.checkPhone(phone) || !user.id || !establishmentId || !name || !phone || !type || !address.detail || !code || !address.province.id || !address.district.id || !address.ward.id) {
            return
        }

        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await warehouseService.add(data) : await warehouseService.edit(id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_warehouse').trigger('click')
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} kho thành công!`)
            this.clearForm()
            await warehouseStore.getWarehouse()
        } else toastUtil.error(result.body.message, 1)
    }

    async delete() {
        warehouseStore.isLoadingBt = true
        const result = await warehouseService.delete(warehouseStore.id)
        warehouseStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_confirm').trigger('click')
            toastUtil.success('Xóa kho thành công!')
            await warehouseStore.getWarehouse()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const warehouseStore = new WarehouseStore()