import React from 'react';
import {observer} from "mobx-react";
import './AddInfoReceiverTransport.scss'
import {Input, Spin} from 'antd';
import {checkOrderStore} from "../../CheckOrderStore";
import {customerDataStore} from "../../../customerCare/customerData/CustomerDataStore";
import SelectForm from "../../../../modules/components/form/SelectAnt";
import TextArea from "antd/es/input/TextArea";
const ModalAddInfoReceiver = () => {

    return (
        <div className='modal fade' id='add_info_receiver_transport' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_info__receiver--title'>
                            CẬP NHẬT THÔNG TIN NGƯỜI NHẬN
                        </div>
                        <button type="button" id="add_info_receiver_transport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên người nhận <span className='text-danger'>*</span></label>
                                <Input type='text' value={checkOrderStore.dataReceiverTransport?.nameReceiver}
                                       status={(checkOrderStore.errorDataReceiverTransport?.nameReceiver && !checkOrderStore.dataReceiverTransport?.nameReceiver) ? "error" : ""}
                                       onChange={(e: any) => {
                                           checkOrderStore.dataReceiverTransport.nameReceiver = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {checkOrderStore.errorDataReceiverTransport?.nameReceiver && !checkOrderStore.dataReceiverTransport.nameReceiver && <small className="text-error">{checkOrderStore.errorDataReceiverTransport?.nameReceiver}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số điện thoại <span className='text-danger'>*</span></label>
                                <Input type='number' value={checkOrderStore.dataReceiverTransport?.phoneReceiver}
                                       status={(checkOrderStore.errorDataReceiverTransport?.phoneReceiver && !checkOrderStore.dataReceiverTransport?.phoneReceiver) ? "error" : ""}
                                       onChange={(e: any) => {
                                           checkOrderStore.dataReceiverTransport.phoneReceiver = e.target.value
                                       }}
                                       placeholder='Nhập số điện thoại ...' />
                                {checkOrderStore.errorDataReceiverTransport?.phoneReceiver && !checkOrderStore.dataReceiverTransport.phoneReceiver && <small className="text-error">{checkOrderStore.errorDataReceiverTransport?.phoneReceiver}</small>}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-4">
                                <label>Tỉnh/Thành phố <span className="text-danger">*</span></label>
                                <SelectForm value={checkOrderStore.dataReceiverTransport.receivingAddress.provinceId} isError={checkOrderStore.errorDataReceiverTransport.receivingAddress.provinceId}
                                            onChange={(e: any, value: any) => checkOrderStore.changeProvince(e, value)}
                                            options={checkOrderStore.listProvince}/>
                            </div>
                            <div className="col-4">
                                <label>Quận/Huyện <span className="text-danger">*</span></label>
                                <SelectForm value={checkOrderStore.dataReceiverTransport.receivingAddress.districtId} isError={checkOrderStore.errorDataReceiverTransport.receivingAddress.districtId}
                                            onChange={(e: any, value: any) => checkOrderStore.changeDistrict(e, value)}
                                            options={checkOrderStore.listDistrict}/>
                            </div>
                            <div className="col-4">
                                <label>Phường/Xã <span className="text-danger">*</span></label>
                                <SelectForm value={checkOrderStore.dataReceiverTransport.receivingAddress.wardId} isError={checkOrderStore.errorDataReceiverTransport.receivingAddress.wardId}
                                            onChange={(e: any, value: any) => {
                                                checkOrderStore.dataReceiverTransport.receivingAddress.wardId = e
                                                checkOrderStore.dataReceiverTransportWardName = value.label
                                            }}
                                            options={checkOrderStore.listWard}/>
                            </div>
                        </div>
                        <div className='w-100 form-group'>
                            <label className='form-label'>Địa chỉ cụ thể <span className='text-danger'>*</span></label>
                            <Input type='text' value={checkOrderStore.dataReceiverTransport?.receivingAddress?.addressDetail}
                                   status={(checkOrderStore.errorDataReceiverTransport?.receivingAddress.addressDetail && !checkOrderStore.dataReceiverTransport?.receivingAddress?.addressDetail) ? "error" : ""}
                                   onChange={(e: any) => {
                                       checkOrderStore.dataReceiverTransport.receivingAddress.addressDetail = e.target.value
                                   }}
                                   placeholder='Nhập địa chỉ ...' />
                            {checkOrderStore.errorDataReceiverTransport?.receivingAddress.addressDetail && !checkOrderStore.dataReceiverTransport?.receivingAddress?.addressDetail && <small className="text-error">{checkOrderStore.errorDataReceiverTransport?.receivingAddress.addressDetail}</small>}
                        </div>
                        <div className='w-100 form-group'>
                            <label className='form-label'>Ghi chú</label>
                                <TextArea rows={4} placeholder='Nhập ghi chú...' onChange={(e: any) => {checkOrderStore.dataReceiverTransport.note = e.currentTarget.value}} />
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn_submit__info--receiver'
                                        onClick={async () => {
                                            await checkOrderStore.validateInfoReceiver()}}>
                                    {
                                        checkOrderStore.isLoadingBt ? <Spin size="small" /> : customerDataStore.typeModal === 'edit' ? 'Cập nhật' :'Thêm'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddInfoReceiver);

