import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {tableStore} from "./TableStore";

class TableService{

    public getListTable(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/table?page=${tableStore.page}${tableStore.establishmentId ? `&establishmentId=${tableStore.establishmentId}` : ''}${tableStore.roomId ? `&roomId=${tableStore.roomId}` : ''}${tableStore.status ? `&status=${tableStore.status}` : ''}&size=20`)
    }
    public getListRoom(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/room?page=${tableStore.page}${tableStore.establishmentId ? `&establishmentId=${tableStore.establishmentId}` : ''}${tableStore.status ? `&status=${tableStore.status}` : ''}&size=${size ?? 20}`)
    }
    public getListRoomEdit(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/room?page=${tableStore.page}${tableStore.dataRequest.establishmentId ? `&establishmentId=${tableStore.dataRequest.establishmentId}` : ''}&size=${size ?? 20}`)
    }
    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/table/${id}`)
    }
    public addTable(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/table`, data)
    }
    public editTable(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/table/${id}`, data)
    }
    public deleteTable(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/table/${id}`, {})
    }
}

export const tableService = new TableService()