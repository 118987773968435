import {makeAutoObservable} from "mobx";
import $ from 'jquery'
import {restaurantService} from "./RestaurantService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {validateEmail, validatePhoneNumber} from "../../../common/utils/Utils";


class RestaurantStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    status: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isDisableEditBtn: boolean = false
    isLoadingBt: boolean = false
    listRestaurant: any[] = []
    listProvince: any[] = []
    listDistrict: any[] = []
    listWard: any[] = []
    dataRequest: any = {
        countStaff: "",
        name: "",
        provinceId: null,
        districtId: null,
        wardId: null,
        addressDetail: "",
        status: null,
        code: "",
        email: "",
        phone: ""
    }
    dataRequestTmp: any = {
        countStaff: "",
        name: "",
        provinceId: null,
        districtId: null,
        wardId: null,
        addressDetail: "",
        status: null,
        code: "",
        email: "",
        phone: ""
    }
    errors: any = {
        countStaff: "",
        name: "",
        provinceId: "",
        districtId: "",
        wardId: "",
        addressDetail: "",
        status: "",
        code: "",
        email: "",
        phone: ""
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            countStaff: "",
            name: "",
            provinceId: null,
            districtId: null,
            wardId: null,
            addressDetail: "",
            status: null,
            code: "",
            email: "",
            phone: ""
        }
        this.errors = {
            countStaff: "",
            name: "",
            provinceId: "",
            districtId: "",
            wardId: "",
            addressDetail: "",
            status: "",
            code: "",
            email: "",
            phone: ""
        }
    }

    async getListRestaurant() {
        this.isLoading = true
        const result = await restaurantService.getListRestaurant()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetailRestaurant(id: number) {
        this.typeModal = 'edit'
        const result = await restaurantService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = {
                countStaff: result.body?.countStaff,
                name: result.body?.name,
                provinceId: result.body?.address?.province?.id,
                districtId: result.body?.address?.district?.id,
                wardId: result.body?.address?.ward?.id,
                addressDetail: result.body?.address?.detail,
                status: result.body?.status,
                code: result.body?.code,
                email: result.body?.email,
                phone: result.body?.phone
            }
        }
    }

    async addEditRestaurant() {
        if(!this.dataRequest.name) {
            restaurantStore.errors.name = "Tên điểm bán không được để trống"
            return false
        }
        if(!this.dataRequest.countStaff) {
            restaurantStore.errors.countStaff = "Số nhân viên không được để trống"
            return false
        }
        if(!this.dataRequest.code) {
            restaurantStore.errors.code = "Mã điểm bán không được để trống"
            return false
        }
        if(!this.dataRequest.phone) {
            restaurantStore.errors.phone = "Số điện thoại không được để trống"
            return false
        }
        if (!validatePhoneNumber(this.dataRequest.phone)) {
            this.dataRequest.phone = ''
            this.errors.phone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            return
        }
        if(!this.dataRequest.email) {
            restaurantStore.errors.email = "Email không được để trống"
            return false
        }
        if (!validateEmail(this.dataRequest.email)) {
            this.dataRequest.email = ''
            this.errors.email = 'Vui lòng nhập đúng định dạng email!'
            return
        }
        if(!this.dataRequest.status) {
            restaurantStore.errors.status = "Vui lòng chọn tình trạng điểm bán"
            return false
        }
        if(!this.dataRequest.provinceId) {
            restaurantStore.errors.provinceId = "Tỉnh/ TP không được để trống"
            return false
        }
        if(!this.dataRequest.districtId) {
            restaurantStore.errors.districtId = "Quận/ Huyện không được để trống"
            return false
        }
        if(!this.dataRequest.wardId) {
            restaurantStore.errors.wardId = "Xã/ Phường không được để trống"
            return false
        }
        if(!this.dataRequest.addressDetail) {
            restaurantStore.errors.addressDetail = "Địa chỉ cụ thể không được để trống"
            return false
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await restaurantService.addRestaurant(this.dataRequest) : await restaurantService.editRestaurant(restaurantStore.id, this.dataRequest)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#add_edit_restaurant').trigger('click')
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} điểm bán thành công!`)
            await restaurantStore.getListRestaurant()
            restaurantStore.isDisableEditBtn = true
        } else toastUtil.error(result.body.message)
    }

    async deleteRestaurant() {
        restaurantStore.isLoadingBt = true
        const result = await restaurantService.deleteRestaurant(restaurantStore.id)
        restaurantStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa điểm bán thành công!')
            await  restaurantStore.getListRestaurant()
        } else toastUtil.error(result.body.message)
    }

    async getProvinces() {
        const result = await restaurantService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            restaurantStore.listProvince = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }
    async getDistricts(provinceId: any) {
        const result = await restaurantService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            restaurantStore.listDistrict = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getWards(districtId: any) {
        const result = await restaurantService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            restaurantStore.listWard = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async changeProvince(provinceId: any) {
        restaurantStore.dataRequest.provinceId = provinceId
        if (restaurantStore.dataRequest.provinceId) {
            restaurantStore.dataRequest.districtId = null
            restaurantStore.errors.provinceId = ''
            restaurantStore.dataRequest.wardId = null

        }
        await restaurantStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        restaurantStore.dataRequest.districtId = districtId
        if (restaurantStore.dataRequest.districtId) {
            restaurantStore.dataRequest.wardId = null
            restaurantStore.errors.districtId = ''
        }
        await restaurantStore.getWards(districtId)
    }

}

export const restaurantStore = new RestaurantStore()