import React from 'react';
import {observer} from "mobx-react";
import ImportExportPage from "../ImportExportPage";

const ImportDevicesPage = () => {
    return (
        <div className="import_page">
            <ImportExportPage/>
        </div>
    );
}

export default observer(ImportDevicesPage);