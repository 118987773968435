import {makeAutoObservable} from 'mobx'
import {toastUtil} from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {changePointService} from "./ChangePointService";
class SettingChangePointStore {
    isLoadingBt: boolean = false
    isLoadingBtPoint: boolean = false
    isErrorRefundOrder: boolean = false
    listCondition: any = [{
        from: 0,
        refund: ""
    }]
    dataRequest: any = {
        amount: "",
        point: "",
    }
    errors : any = {
        amount: "",
        point: "",
    }
    async getToken(){
    }
    async getDetailChangePoint() {
        const result = await changePointService.getSettingChangePoint()
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getDetailSettingRefundOrder() {
        const result = await changePointService.getSettingRefundOrder()
        if (result.status === HttpStatusCode.OK) {
            this.listCondition = result.body?.condition
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async updateChangePoint() {
        if(!this.dataRequest?.point) {
            this.errors.point = "Số điểm thiết lập không được để trống"
            return
        }
        if(!this.dataRequest?.amount) {
            this.errors.amount = "Số tiền chuyển đổi không được để trống"
            return
        }
        this.isLoadingBtPoint = true
        const data = {
            amount: Number(this.dataRequest?.amount),
            point: Number(this.dataRequest?.point)
        }
        const result = await changePointService.updateSettingChangePoint(data)
        this.isLoadingBtPoint = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success("Cập nhật thiết lập đổi điểm thành công", 1)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async updateChangeRefundOrder() {
        this.isLoadingBt = true
        for (let i = this.listCondition.length - 1; i >= 0; i--) {
            if (this.listCondition[i].from === "") {
                this.listCondition.splice(i, 1); // Xóa phần tử thỏa mãn điều kiện
            }
        }
        this.isErrorRefundOrder = this.listCondition.some((item: any) => item.refund === "");
        if(this.isErrorRefundOrder){
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin thiết lập')
            return
        }
        const data = {
            condition : this.listCondition
        }
        const result = await changePointService.updateSettingRefundOrder(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success("Cập nhật thiết lập hoàn tiền cho đơn hàng thành công", 1)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    constructor() {
        makeAutoObservable(this)
    }

}

export const settingChangePointStore = new SettingChangePointStore()