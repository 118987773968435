import React from 'react';
import {observer} from "mobx-react";
import './AddUserModal.scss'
import {ConfigProvider, DatePicker, Input, Select, Spin} from 'antd';
import {checkOrderStore} from "../../CheckOrderStore";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {DatePickerProps} from "antd/es/date-picker";
import {customerDataStore} from "../../../customerCare/customerData/CustomerDataStore";
const ModalAddUser = () => {
    function convertTime(date: any): any {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChangeDateFinish = (
        value: DatePickerProps['value'],
    ) => {
        checkOrderStore.dataUserRequest.birthDay = convertTime(value)
    };
    return (
        <div className='modal fade' id='add_user_customer' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>
                            {customerDataStore.typeModal === 'edit' ? 'SỬA THÔNG TIN KHÁCH HÀNG' :'THÊM THÔNG TIN KHÁCH HÀNG'}
                        </div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên khách hàng<span className='text-danger'>*</span></label>
                                <Input type='text' value={checkOrderStore.dataUserRequest?.name}
                                       status={(checkOrderStore.errorUserRequest?.name && !checkOrderStore.dataUserRequest?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           checkOrderStore.dataUserRequest.name = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {checkOrderStore.errorUserRequest?.name && !checkOrderStore.dataUserRequest.name && <small className="text-error">{checkOrderStore.errorUserRequest?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                <Input type='number' value={checkOrderStore.dataUserRequest?.phone}
                                       status={(checkOrderStore.errorUserRequest?.phone && !checkOrderStore.dataUserRequest?.phone) ? "error" : ""}
                                       onChange={(e: any) => {
                                           checkOrderStore.dataUserRequest.phone = e.target.value
                                       }}
                                       placeholder='Nhập số điện thoại ...' />
                                {checkOrderStore.errorUserRequest?.phone && !checkOrderStore.dataUserRequest.phone && <small className="text-error">{checkOrderStore.errorUserRequest?.phone}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Giới tính<span className='text-danger'>*</span></label>
                                <Select
                                    value={checkOrderStore.dataUserRequest.gender}
                                    status={(checkOrderStore.errorUserRequest?.gender && !checkOrderStore.dataUserRequest.gender) ? "error" : ""}
                                    onChange={(e: any) => {
                                        checkOrderStore.dataUserRequest.gender = e
                                    }}
                                    placeholder="Chọn" options={[{value: "MALE", label: "Nam"}, {value: "FEMALE", label: "Nữ"}]}
                                />
                                {checkOrderStore.errorUserRequest?.gender  && !checkOrderStore.dataUserRequest.gender && <small className="text-error">{checkOrderStore.errorUserRequest?.gender}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Ngày sinh<span className='text-danger'>*</span></label>
                                <ConfigProvider locale={viVI}>
                                    <DatePicker
                                        value={!checkOrderStore.dataUserRequest.birthDay ? null : dayjs(checkOrderStore.dataUserRequest.birthDay)}
                                        placeholder='Chọn'
                                        format={"DD/MM/YYYY"} onChange={onChangeDateFinish}/>
                                </ConfigProvider>
                                {checkOrderStore.errorUserRequest?.birthDay  && !checkOrderStore.dataUserRequest.birthDay && <small className="text-error">{checkOrderStore.errorUserRequest?.birthDay}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn__voucher'
                                        onClick={async () => {
                                            await checkOrderStore.addUser()}}>
                                    {
                                        checkOrderStore.isLoadingBt ? <Spin size="small" /> : customerDataStore.typeModal === 'edit' ? 'Cập nhật' :'Thêm'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddUser);

