import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {customerDataService} from "./CustomerDataService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {checkOrderStore} from "../../checkOrder/CheckOrderStore";


class CustomerDataStore {
    id: any = ''
    keyword: any = ''
    typeModal: any = 'add'
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listUser: any[] = []
    listOrderUser: any[] = []
    isFetching = false; // Thêm biến kiểm tra
    hasMore: boolean = true
    dataDetail: any = {}
    userId: any = ""
    constructor() {
        makeAutoObservable(this)
    }

    async getListUser(size?: any) {
        if (customerDataStore.isFetching) {
            return;
        }
        customerDataStore.isLoading = true
        customerDataStore.isFetching = true;
        try {
            const result = await customerDataService.getListUser(size);
            if (result.status === HttpStatusCode.OK) {
                if (result.body.data.length === 0) {
                    customerDataStore.hasMore = false;
                } else {
                    customerDataStore.listUser = [...customerDataStore.listUser, ...result.body.data];
                    if(customerDataStore.listUser.length < 15) {
                        customerDataStore.hasMore = false;
                    }
                    customerDataStore.page = customerDataStore.page + 1;
                }
            }
        } catch (error) {
            toastUtil.error(error as any)
        } finally {
            customerDataStore.isLoading = false;
            customerDataStore.isFetching = false;
        }
    }

    async getDetailUser(id: number) {
        const result = await customerDataService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataDetail = result.body
            checkOrderStore.dataUserRequest = result.body
        }
    }
    async getOrderUser() {
        const result = await customerDataService.getListOrderUser()
        if (result.status === HttpStatusCode.OK) {
            this.listOrderUser = result.body.data
        }
    }

}

export const customerDataStore = new CustomerDataStore()