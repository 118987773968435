import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {rndResearchStore} from "./RndResearchStore";


class RndResearchService {
    public getListRnDResearch(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/market_research?type=R_A_D${rndResearchStore.searchStatus ? `&status=${rndResearchStore.searchStatus}` : ''}`);
    }
    public getDetailMarketResearch(id: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/market_research/${id}`);
    }
    public addMarketResearch(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/market_research`, data);
    }
    public editMarketResearch(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/market_research/${id}`, data)
    }
    public deleteMarketResearch(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/market_research/${id}`, {})
    }
}

export const rndResearchService = new RndResearchService();