import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {customerFeedbackService} from "./CustomerFeedbackService";
import {accountService} from "../../setting/account/AccountService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {validatePhoneNumber} from "../../../common/utils/Utils";

class CustomerFeedbackStore {
    id: any = ''
    keyword: string = ''
    establishmentId: any = null
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    dataCheckPhone: any = {}
    listFeedback: any[] = []
    listRestaurant: any[] = []
    dataRequest: any = {
        phone: "",
        name: "",
        gender: null,
        birthday: null,
        address: "",
        establishmentId: null,
        space: "",
        hygiene: "",
        serviceAttitude: "",
        serviceTime: "",
        diverseMenu: true,
        pricing: "",
        palate: "",
        referralSource: "",
        recommendToFriends: true,
        returnReview: false
    }
    dataDetailFeedback: any = {}
    errors: any = {
        name: "",
        phone: "",
        validatePhone: "",
        establishmentId: ""
    }
    listFeedbackSpace: any[] = [
        {
            title: "2.1. Không gian",
            keyRole: "space",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
        {
            title: "2.2. Vệ sinh",
            keyRole: "hygiene",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
        {
            title: "2.3. Thái độ phục vụ",
            keyRole: "serviceAttitude",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
        {
            title: "2.4. Thời gian phục vụ",
            keyRole: "serviceTime",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
    ]
    listFeedbackFood: any[] = [
        {
            title: "3.1. Đa dạng menu",
            keyRole: "diverseMenu",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
        {
            title: "3.2. Giá cả",
            keyRole: "pricing",
            value: "APPROPRIATE",
            listRole: [{value: "APPROPRIATE", label: 'Phù hợp'}, {value: "LOW", label: 'Thấp'}, {
                value: "HIGH",
                label: 'Cao'
            }]
        },
        {
            title: "3.3. Khẩu vị",
            keyRole: "palate",
            value: "VERY_SATISFIED",
            listRole: [{value: "VERY_SATISFIED", label: 'Rất hài lòng'}, {
                value: "AVERAGE",
                label: 'Bình thường'
            }, {value: "UNSATISFIED", label: 'Không hài lòng'}]
        },
    ]
    listFeedbackOtherInfo: any[] = [
        {
            title: "4.1. Biết cửa hàng qua",
            keyRole: "referralSource",
            value: "FACEBOOK",
            listRole: [{value: "FACEBOOK", label: 'Facebook'}, {value: "FRIENDS", label: 'Bạn bè'}, {
                value: "OTHER",
                label: 'Khác'
            }]
        },
        {
            title: "3.2. Giới thiệu bạn bè",
            keyRole: "recommendToFriends",
            value: true,
            listRole: [{value: true, label: 'Có'}, {value: false, label: 'Không'}]
        },
        {
            title: "3.3. Quay lại",
            keyRole: "returnReview",
            value: true,
            listRole: [{value: true, label: 'Có'}, {value: false, label: 'Không'}]
        },
    ]
    dataSumaryFeedback: any[] = []

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        customerFeedbackStore.dataRequest = {
            phone: "",
            name: "",
            gender: null,
            birthday: null,
            address: "",
            establishmentId: null,
            space: "VERY_SATISFIED",
            hygiene: "VERY_SATISFIED",
            serviceAttitude: "VERY_SATISFIED",
            serviceTime: "VERY_SATISFIED",
            diverseMenu: "VERY_SATISFIED",
            pricing: "APPROPRIATE",
            palate: "VERY_SATISFIED",
            referralSource: "FACEBOOK",
            recommendToFriends: true,
            returnReview: true
        }
        customerFeedbackStore.errors = {
            name: "",
            phone: "",
            validatePhone: "",
            establishmentId: ""
        }
    }


    async getReviewsStats() {
        this.isLoading = true
        const result = await customerFeedbackService.getReviewStar()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataSumaryFeedback = []
            let {space, serviceTime, serviceAttitude, pricing, hygiene, diverseMenu, returnReview, recommendToFriends, referralSource, palate} = result.body
            this.dataSumaryFeedback.push({
                title: 'Không gian',
                type: "1",
                data: [
                    {
                        value: space?.data?.vERYSATISFIED?.count ?? 0,
                        label: "Rất hài lòng"
                    },
                    {
                        value: space?.data?.aVERAGE?.count ?? 0,
                        label: "Bình thường"
                    },
                    {
                        value: space?.data?.uNSATISFIED?.count ?? 0,
                        label: "Không hài lòng"
                    }
                ]
            })
            this.dataSumaryFeedback.push(
                {
                    title: 'Vệ sinh',
                    type: "1",
                    data: [
                        {
                            value: hygiene?.data?.vERYSATISFIED?.count ?? 0,
                            label: "Rất hài lòng"
                        },
                        {
                            value: hygiene?.data?.aVERAGE?.count ?? 0,
                            label: "Bình thường"
                        },
                        {
                            value: hygiene?.data?.uNSATISFIED?.count ?? 0,
                            label: "Không hài lòng"
                        }
                    ]
                }
            )
            this.dataSumaryFeedback.push({
                title: 'Thái độ phục vụ',
                type: "1",
                data: [
                    {
                        value: serviceAttitude?.data?.vERYSATISFIED?.count ?? 0,
                        label: "Rất hài lòng"
                    },
                    {
                        value: serviceAttitude?.data?.aVERAGE?.count ?? 0,
                        label: "Bình thường"
                    },
                    {
                        value: serviceAttitude?.data?.uNSATISFIED?.count ?? 0,
                        label: "Không hài lòng"
                    }
                ]
            })
            this.dataSumaryFeedback.push(
                {
                    title: 'Thời gian phục vụ',
                    type: "1",
                    data: [
                        {
                            value: serviceTime?.data?.vERYSATISFIED?.count ?? 0,
                            label: "Rất hài lòng"
                        },
                        {
                            value: serviceTime?.data?.aVERAGE?.count ?? 0,
                            label: "Bình thường"
                        },
                        {
                            value: serviceTime?.data?.uNSATISFIED?.count ?? 0,
                            label: "Không hài lòng"
                        }
                    ]
                }
            )
            this.dataSumaryFeedback.push({
                title: 'Đa dạng menu',
                type: "1",
                data: [
                    {
                        value: diverseMenu?.data?.vERYSATISFIED?.count ?? 0,
                        label: "Rất hài lòng"
                    },
                    {
                        value: diverseMenu?.data?.aVERAGE?.count ?? 0,
                        label: "Bình thường"
                    },
                    {
                        value: diverseMenu?.data?.uNSATISFIED?.count ?? 0,
                        label: "Không hài lòng"
                    }
                ]
            })
            this.dataSumaryFeedback.push(
                {
                    title: 'Khẩu vị',
                    type: "1",
                    data: [
                        {
                            value: palate?.data?.vERYSATISFIED?.count ?? 0,
                            label: "Rất hài lòng"
                        },
                        {
                            value: palate?.data?.aVERAGE?.count ?? 0,
                            label: "Bình thường"
                        },
                        {
                            value: palate?.data?.uNSATISFIED?.count ?? 0,
                            label: "Không hài lòng"
                        }
                    ]
                }
            )
            this.dataSumaryFeedback.push({
                title: 'Giá cả',
                type: "2",
                data: [
                    {
                        value: pricing?.data?.aPPROPRIATE?.count ?? 0,
                        label: "Phù hợp"
                    },
                    {
                        value: pricing?.data?.hIGH?.count ?? 0,
                        label: "Cao"
                    },
                    {
                        value: pricing?.data?.lOW?.count ?? 0,
                        label: "Thấp"
                    }
                ]
            })
            this.dataSumaryFeedback.push(
                {
                    title: 'Biết cửa hàng qua',
                    type: "3",
                    data: [
                        {
                            value: referralSource?.data?.fACEBOOK?.count ?? 0,
                            label: "Facebook"
                        },
                        {
                            value: referralSource?.data?.fRIENDS?.count ?? 0,
                            label: "Bạn bè"
                        },
                        {
                            value: referralSource?.data?.oTHER?.count ?? 0,
                            label: "Khác"
                        }
                    ]
                }
            )
            this.dataSumaryFeedback.push({
                title: 'Giới thiệu bạn bè',
                type: "4",
                data: [
                    {
                        value: recommendToFriends?.data?.tRUE?.count ?? 0,
                        label: "Có"
                    },
                    {
                        value: recommendToFriends?.data?.fALSE?.count ?? 0,
                        label: "Không"
                    },
                ]
            })
            this.dataSumaryFeedback.push(
                {
                    title: 'Quay lại',
                    type: "4",
                    data: [
                        {
                            value: returnReview?.data?.tRUE?.count ?? 0,
                            label: "Có"
                        },
                        {
                            value: returnReview?.data?.fALSE?.count ?? 0,
                            label: "Không"
                        }
                    ]
                }
            )
        }
    }

    async getListRestaurant() {
        const result = await accountService.getListRestaurant()
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
    }

    async getCustomerFeedback() {
        this.isLoading = true
        const result = await customerFeedbackService.getFeedback()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listFeedback = result.body.data
        }
    }
    async checkPhone(phone: any) {
        this.isLoading = true
        const result = await customerFeedbackService.checkPhoneUser(phone)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            customerFeedbackStore.dataCheckPhone = {}
            if(result.body.phone) {
                customerFeedbackStore.dataCheckPhone = result.body
                customerFeedbackStore.dataRequest.name = result.body.name
                customerFeedbackStore.dataRequest.birthday = result.body.birthday
                customerFeedbackStore.dataRequest.gender = result.body.gender
            }
            else {
                customerFeedbackStore.dataRequest.name =  ""
            }
        }
    }

    async getDetailFeedback(id: any) {
        const result = await customerFeedbackService.getFeedbackById(id)
        if (result.status === HttpStatusCode.OK) {
            customerFeedbackStore.dataDetailFeedback = result.body
        }
    }

    getValueFeedbackItem(list: any[], keyRole: any) {
        return list.find((item: any) => item.keyRole === keyRole)?.value
    }

    removeErrors(keyRemove: string) {
        for (const key in this.errors) {
            if (key !== keyRemove) {
                this.errors[key] = '';
            }
        }
    }

    async addEditFeedback() {
        if(customerFeedbackStore.dataRequest.phone && !customerFeedbackStore.dataRequest.name){
            await customerFeedbackStore.checkPhone(customerFeedbackStore.dataRequest.phone).then(() => {
                if(!customerFeedbackStore.dataCheckPhone.phone) {
                    this.dataRequest.name = ''
                }
            })
        }
        if (!this.dataRequest.phone) {
            this.errors.phone = `Vui lòng nhập số điện thoại`
            this.removeErrors('phone')
            return
        }
        if (!validatePhoneNumber(this.dataRequest.phone)) {
            this.errors.validatePhone = `Vui lòng nhập đúng định dạng số điện thoại`
            this.removeErrors('validatePhone')
            return
        }
        if (!this.dataRequest.name) {
            this.errors.name = `Khách hàng mới , vui lòng nhập tên khách hàng`
            this.removeErrors('name')
            return
        }

        this.isLoadingBt = true
        let data = {
            phone: this.dataRequest.phone,
            name: this.dataRequest.name,
            gender: this.dataRequest.gender,
            birthday: this.dataRequest.birthday,
            address: this.dataRequest.address,
            establishmentId: customerFeedbackStore.establishmentId,
            space: this.getValueFeedbackItem(this.listFeedbackSpace, "space"),
            hygiene: this.getValueFeedbackItem(this.listFeedbackSpace, "hygiene"),
            serviceAttitude: this.getValueFeedbackItem(this.listFeedbackSpace, "serviceAttitude"),
            serviceTime: this.getValueFeedbackItem(this.listFeedbackSpace, "serviceTime"),
            diverseMenu: this.getValueFeedbackItem(this.listFeedbackFood, "diverseMenu"),
            pricing: this.getValueFeedbackItem(this.listFeedbackFood, "pricing"),
            palate: this.getValueFeedbackItem(this.listFeedbackFood, "palate"),
            referralSource: this.getValueFeedbackItem(this.listFeedbackOtherInfo, "referralSource"),
            recommendToFriends: this.getValueFeedbackItem(this.listFeedbackOtherInfo, "recommendToFriends"),
            returnReview: this.getValueFeedbackItem(this.listFeedbackOtherInfo, "returnReview")
        }
        const result = await customerFeedbackService.add(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success("Thêm đánh giá thành công", 1)
            $('#add_feedback').trigger('click')
            this.clearForm()
            await customerFeedbackStore.getReviewsStats()
            await customerFeedbackStore.getCustomerFeedback()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const customerFeedbackStore = new CustomerFeedbackStore();