import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './OrderPage.scss'
import {orderStore} from "./OrderStore";
import {Link} from "react-router-dom";
import NoContent from "../../modules/components/NoContent";
import {checkOrderStore} from "../checkOrder/CheckOrderStore";
import {Tooltip} from "antd";
import {kitchenStore} from "../kitchenManager/KitchenStore";
import {dateUtils} from "../../common/utils/DateUtils";
import {convertTypeOrder} from "../checkOrder/CheckOrderPage";
import DetailCheckoutOrderModal from "../checkOrder/components/detailOrder/detailOrder";
import ViewDetailOrderModal from "../checkOrder/components/viewHistoryOrder/ViewHistoryOrder";
import MenuAction from "../../modules/components/menuAction/MenuAction";
import ModalSelectRoom from "./components/modalSelectRoom/ModalSelectRoom";

const OrderDetailPage = () => {
    useEffect(() => {
        orderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
    }, [])
    const convertStatusOrder = (value: string) => {
        switch (value) {
            case "SUCCESS":
                return <div className="px-1 text-center"
                            style={{
                                width: '130px',
                                backgroundColor: '#F3FFF9',
                                color: '#00AB56',
                                borderRadius: '3px'
                            }}>Hoàn
                    thành</div>
            case "IN_PROGRESS":
                return <div className="px-1 text-center"
                            style={{
                                width: '130px',
                                backgroundColor: '#FAF9FF',
                                color: '#7B61FF',
                                borderRadius: '3px'
                            }}>Đang
                    phục vụ</div>
            case "PENDING":
                return <div className="px-1 text-center"
                            style={{
                                width: '130px',
                                backgroundColor: '#EBEBF0',
                                color: '#626C70',
                                borderRadius: '3px'
                            }}>Chưa
                    phục vụ</div>
            case "CANCEL":
                return <div className="px-1 text-center" style={{
                    width: '130px',
                    backgroundColor: '#FFEDEE',
                    color: '#FF424F',
                    borderRadius: '3px'
                }}>Hủy</div>
            default:
                return null
        }
    }

    function checkIsShowAction(arr: any) {
        return arr && arr.some((item: any) => item.status === 'PENDING' || item.status === 'IN_PROGRESS')
    }

    const convertStatusItemOrder = (value: string) => {
        switch (value) {
            case "SUCCESS":
                return <div className="color-green fw-500">Hoàn thành</div>
            case "IN_PROGRESS":
                return <div style={{fontWeight: '500', color: '#7B61FF'}}>Đang chuẩn bị</div>
            case "CANCEL":
                return <div className="color-red fw-500">Hủy</div>
            case "PENDING":
                return <div style={{fontWeight: '500', color: '#808089'}}>Chưa làm</div>
            default:
                return null
        }
    }
    return (
        <>
            <div className='w-100 d-flex flex-column align-items-center'>
                <div className='w-100 d-flex justify-content-start'>
                    <Link to='/phuc-vu' className='back_order__detail'>
                        <img src="/assets/ico/action/move-left.svg" alt=""/>
                    </Link>
                </div>

                <div className='service_detail__container'>
                    <div className='service_detail__container--header'>
                        <div className='service_detail__header--item'>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Mã đơn</span>
                                <span
                                    className='service_detail__header--item-right'>{orderStore.dataDetailOrder?.code ?? "-"}</span>
                            </div>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Loại</span>
                                <span
                                    className='service_detail__header--item-right'>{convertTypeOrder(orderStore.dataDetailOrder?.type)}</span>
                            </div>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Phòng/Bàn</span>
                                <span
                                    className='service_detail__header--item-right'>{`${orderStore.dataDetailOrder?.room?.name ? `${orderStore.dataDetailOrder?.room?.name}` : ""}${orderStore.dataDetailOrder?.table?.name ? ` - ${orderStore.dataDetailOrder?.table?.name}` : ""}`}</span>
                            </div>
                        </div>
                        <div className='service_detail__header--item'>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Họ và tên</span>
                                <span
                                    className='service_detail__header--item-right'>{orderStore?.dataDetailOrder?.user?.name ?? '-'}</span>
                            </div>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Số điện thoại</span>
                                <span
                                    className='service_detail__header--item-right'>{orderStore?.dataDetailOrder?.user?.phone ?? '-'}</span>
                            </div>
                            <div className='service_detail__header--item-container'>
                                <span className='service_detail__header--item-left'>Khách hàng</span>
                                <span
                                    className='service_detail__header--item-right'>{orderStore?.dataDetailOrder?.user?.member?.[0]?.rank?.rankName ?? '-'}</span>
                            </div>
                        </div>
                        <div className='service_detail__header--item'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                {convertStatusOrder(orderStore.dataDetailOrder?.status)}
                                <span className='service_detail__header--item-timeTitle my-1'>Thời gian phục vụ</span>
                                <span className='service_detail__header--item-timeValue'>45'</span>
                            </div>
                        </div>
                        <div className='service_detail__header--item'>
                            <button
                                className='check_out__order--btn btn'
                                onClick={() => {
                                    if (orderStore.dataDetailOrder.status !== "SUCCESS") {
                                        checkOrderStore.clearForm()
                                        checkOrderStore.idOrder = Number(localStorage.getItem('orderId'))
                                        checkOrderStore.getDetailOrder(orderStore.dataDetailOrder.id).then()
                                        checkOrderStore.dataCheckCodeVoucher = {}
                                        checkOrderStore.detailInfoUser = {}
                                        checkOrderStore.keyword = ""
                                    } else {
                                        checkOrderStore.dataCheckCodeVoucher = {}
                                        checkOrderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                    }
                                }}
                                data-bs-toggle="modal"
                                data-bs-target={orderStore.dataDetailOrder?.status !== "SUCCESS" ? '#detail_checkout_order' : '#view_detail_order'}
                            >
                                <img src="/assets/ico/action/banknote_white.svg" alt=""/>
                                <span
                                    className='single-line'>{orderStore.dataDetailOrder?.status !== 'SUCCESS' ? 'Check đơn' : 'Chi tiết'}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='w-75 detail_order__action my-3'>
                    <MenuAction isRight={false} children={<>
                        <button className='d-flex justify-content-start btn'
                                onClick={async () => {
                                    await kitchenStore.cancelKitchen(orderStore.dataDetailOrder.items?.map((item: any) => item.id))
                                    await orderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                }}>
                            <img src="/assets/ico/action/x_red.svg"
                                 className="icon-action"
                                 alt=""/>
                            <span style={{color: '#FF424F'}}>
                                Hủy tất cả món
                            </span>
                        </button>
                        <div className={'divice_menu__action'}></div>
                        <button className='d-flex justify-content-start btn'
                                onClick={async () => {
                                    await kitchenStore.changeStatus({
                                        status: 'IN_PROGRESS',
                                        ids: orderStore.dataDetailOrder.items?.map((item: any) => item.id)
                                    })
                                    await orderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                }}>
                            <img src="/assets/ico/action/check-circle-green.svg"
                                 className="icon-action"
                                 alt=""/>
                            <span style={{color: '#00AB56'}}>
                                Đã phục vụ tất cả
                            </span>
                        </button>
                    </>}/>
                    <div className='d-flex'>
                        <button className='btn_switch__table'
                                data-bs-toggle="modal"
                                data-bs-target='#select_room_order'
                                onClick={async () => {
                                    orderStore.idOrder = orderStore.dataDetailOrder?.id
                                    orderStore.typeModal = 'edit'
                                    orderStore.codeOrder = orderStore.dataDetailOrder?.code
                                    orderStore.page = 0
                                    orderStore.dataRequest.establishmentId = orderStore.dataDetailOrder?.establishmentId
                                    await orderStore.getListRoom().then()
                                    orderStore.dataRequest.roomId = orderStore.dataDetailOrder?.roomId
                                    orderStore.dataRequest.tableId = orderStore.dataDetailOrder?.tableId
                                }}>
                            <img src="/assets/ico/action/arrow-left-right.svg"
                                 className="icon-action"
                                 alt=""/>
                            <span style={{color: '#626C70'}}>
                                Đổi bàn
                            </span>
                        </button>
                        {orderStore.dataDetailOrder.code && orderStore.dataDetailOrder.id &&
                            <Link to={`/phuc-vu/${orderStore.dataDetailOrder.code}/${orderStore.dataDetailOrder.id}`}
                                  className='btn_add__item ms-3'
                                  onClick={() => {
                                      orderStore.idOrder = orderStore.dataDetailOrder?.id
                                      orderStore.codeOrder = orderStore.dataDetailOrder.code
                                      orderStore.isBackToDetailPage = true
                                  }}>
                                <img src="/assets/ico/action/plus-square-white.svg" alt="btn_add"/>
                                Thêm món
                            </Link>}
                    </div>
                </div>
                <div className="w-75 table-responsive d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Thời gian</th>
                            <th className="text-center">Tên sản phẩm</th>
                            <th className="text-center">Mã sản phẩm</th>
                            <th className="text-center">Số lượng</th>
                            <th className="text-center">Đơn vị</th>
                            <th className="text-center">Ghi chú</th>
                            <th className="text-center">Tình trạng</th>
                            {checkIsShowAction(orderStore.dataDetailOrder?.items) &&
                                <th className="text-center">Hành động</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {orderStore.dataDetailOrder?.items?.length <= 0 ? <NoContent height={100}
                                                                                     colSpan={8}/> : orderStore.dataDetailOrder?.items?.map((itemProduct: any, indexProduct: number) => {
                            return (
                                <tr key={indexProduct}>
                                    <td className="text-center">{indexProduct + 1}</td>
                                    <td className="text-center">{dateUtils.formatDate(itemProduct.createdAt, 'hh:mm, DD/MM/YYYY')}</td>
                                    <td className="text-center">{itemProduct?.product?.name}</td>
                                    <td className="text-center">{itemProduct?.product?.batch}</td>

                                    <td className="text-center">
                                        <div style={{width: '60%'}} className='d-flex justify-content-end'>
                                            {itemProduct.quantity > 1 && itemProduct.status !== "SUCCESS" && itemProduct.status !== "CANCEL" &&
                                                <button className='button_qty__item-order' onClick={async () => {
                                                    checkOrderStore.idOrder = Number(localStorage.getItem('orderId'))
                                                    if (itemProduct.quantity > 0) itemProduct.quantity--
                                                    checkOrderStore.itemUpdate = {
                                                        productId: itemProduct.productId,
                                                        quantity: itemProduct.quantity
                                                    }
                                                    checkOrderStore.idItemUpdate = itemProduct.id
                                                    await checkOrderStore.updateItemOrder()
                                                }}>
                                                    <Tooltip title='Trả hàng'>
                                                        <img src="/assets/ico/action/minus.svg" alt=""/>
                                                    </Tooltip>
                                                </button>
                                            }
                                            <span style={{width: '32px'}}>
                                                                                        {itemProduct?.quantity}
                                                                                    </span>
                                        </div>
                                    </td>
                                    <td className="text-center">{itemProduct?.product?.unit}</td>
                                    <td className="text-center">{itemProduct?.note}</td>
                                    <td className="text-center">{convertStatusItemOrder(itemProduct?.status)}</td>
                                    {checkIsShowAction(orderStore.dataDetailOrder?.items) &&
                                        <td className="text-center">
                                            {(itemProduct.status === 'PENDING' || itemProduct.status === 'IN_PROGRESS') &&
                                                <button className="btn p-0 mx-1" disabled={kitchenStore.isLoadingBt}
                                                        onClick={async () => {
                                                            await kitchenStore.cancelKitchen([itemProduct.id])
                                                            await orderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                                        }}>
                                                    <Tooltip title='Hủy món'>
                                                        <img src="/assets/ico/action/x-circle (1).svg"
                                                             className="icon-action"
                                                             alt=""/>
                                                    </Tooltip>
                                                </button>
                                            }
                                            {(itemProduct.status === 'PENDING' || itemProduct.status === 'IN_PROGRESS') &&
                                                <button className="btn p-0 mx-1" disabled={kitchenStore.isLoadingBt}
                                                        onClick={async () => {
                                                            await kitchenStore.changeStatus({
                                                                status: 'IN_PROGRESS',
                                                                ids: [itemProduct.id]
                                                            })
                                                            await orderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                                        }
                                                        }
                                                >
                                                    <Tooltip title="Hoàn thành">
                                                        <img src="/assets/ico/action/check-circle.svg"
                                                             className="icon-action"
                                                             alt=""/>
                                                    </Tooltip>
                                                </button>
                                            }
                                        </td>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <DetailCheckoutOrderModal/>
            <ViewDetailOrderModal/>
            <ModalSelectRoom/>
        </>

    );
}

export default observer(OrderDetailPage);
