import React from 'react';
import './CheckStyle.scss'
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../modules/components/buttonGlobal/ButtonGlobal";
import {voucherStore} from "../saleMarketing/voucher/VoucherStore";
import {purchaseStore} from "../warehouses/purchase/PurchaseStore";
import ModalSelectTypeFood from "./components/selectTypeFood/SelectTypeFood";
import ModalDetailDryFoodCheckSheet from "./components/detailDryFoodCheckSheet/DetailDryFoodCheckSheet";
import NoContent from "../../modules/components/NoContent";
interface IProps {
    title: string
}

const CheckTheDish = ({title}:IProps) => {

    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm theo mã...',
            value: '',
            onChange: (e: any) => {
            },
            callback: async () => {
            }
        },
    ]



    return (
        <>
            <div className='purchase_container'>
                <div className='tab_filter_header'>
                    <h4 className='title'>{title}</h4>
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={ <ButtonGlobal onClick={() => {
                            voucherStore.type = 'add'
                            voucherStore.resetError()
                        }} dataTarget='select_type_food' text='Thêm' ico='add_icon' className='btn_add' />}
                    />
                </div>
                <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-center">STT</th>
                            <th scope="col" className="text-center">Mã phiếu</th>
                            <th scope="col">{title === "Kiểm trước khi ăn" ? 'Sản phẩm' : 'Nguyên liệu'}</th>
                            <th scope="col" className="text-center">Mã nguyên liệu</th>
                            <th scope="col" className="text-center">Nhà cung cấp</th>
                            <th scope="col" className="text-center">Người kiểm tra</th>
                            <th scope="col" className="text-center">Thời gian</th>
                            <th scope="col" className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {[1,2,3] && [1,2,3].length > 0 ? [1,2,3].map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i+1}</td>
                                    <td className="text-center">-</td>
                                    <td>-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">
                                        <button
                                            type='button'
                                            className="view_detail__btn"
                                            data-bs-toggle="modal"
                                            title="Xem chi tiết"
                                            data-bs-target='#detail_dry_food_check'
                                        >
                                            Chi tiết
                                        </button>
                                    </td>
                                </tr>
                            )
                        }): <NoContent colSpan={8}/>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalSelectTypeFood />
            <ModalDetailDryFoodCheckSheet />
        </>
    );
}

export default observer(CheckTheDish);