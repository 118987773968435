import {makeAutoObservable} from "mobx";
import {inventoryReportService} from "../inventoryReport/InventoryReportService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";

class StipulationsStore {
    keyword: any = ''
    id: any = ''
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listStipulation: any[] = []
    dataRequest: any = {
        suppliesId: "",
        maxQty: "",
        minQty: "",
    }
    async getInventoryReport(){
        this.isLoading = true
        const result = await inventoryReportService.getInvetoryReportRequest()
        if (result.status === HttpStatusCode.OK) {
            this.isLoading = false
            stipulationStore.listStipulation = result.body.data.map((item: any) => {
                return {
                    ...item,
                    isEdit: false
                }
            })
        }
    }
    async updateInventory() {
        this.isLoadingBt = true
        const result =  await inventoryReportService.updateInfoInventory(stipulationStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`Cập nhật quy định định mức của vật tư thành công!`, 1)
            await stipulationStore.getInventoryReport()
        } else toastUtil.error(result.body.message, 2)
    }
    constructor() {
        makeAutoObservable(this)
    }

}

export const stipulationStore = new StipulationsStore()