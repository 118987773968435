import React from 'react';
import {observer} from "mobx-react";
import './SelectRoom.scss'
import {Select } from 'antd';
import $ from "jquery";
import {roomStore} from "../../../room/RoomStore";
import {tableStore} from "../../TableStore";
import {orderStore} from "../../../../order/OrderStore";
const ModalSelectRoom = () => {
    return (
        <div className='modal fade' id='select_room' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header'>
                        <div className='value_voucher__title'>CHỌN ĐIỂM BÁN</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className='w-100 d-flex mb-3'>
                                <Select
                                    value={tableStore.establishmentId}
                                    allowClear
                                    onChange={async (e: any, value: any) => {
                                        tableStore.establishmentId = e
                                        tableStore.establishmentName = value?.label ?? ""
                                        orderStore.dataRequest.establishmentId = e
                                        orderStore.dataRequest.establishmentName = value?.label ?? ""
                                        localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                                        tableStore.roomId = null
                                        tableStore.roomName = ''
                                        await tableStore.getListTable().then(async () => {
                                            await tableStore.getListRoom().then()
                                        })
                                        $('#select_room').trigger('click')
                                    }}
                                    placeholder="Chọn địa điểm bán" options={roomStore.listRestaurant}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalSelectRoom);

