import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import {sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../modules/components/NoContent";
import {settingRoleStore} from "./SettingRoleStore";
import ModalSettingRole from "./components/modalSettingRole/ModalSettingRole";
import {positionStore} from "../position/PositionStore";


const SettingRolePage = () => {
    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm chức vụ...',
            value: '',
            onChange: (e: any) => {
                positionStore.keyword  = e
            },
            callback: async () => {
                settingRoleStore.getPosition().then()
            }
        },
    ]
    useEffect(() => {
        settingRoleStore.getPosition().then()
    }, [])

    return (
        <div className='account_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Phân quyền</h4>
                <FilterComponent
                    data={dataFilter}
                />
            </div>

            {settingRoleStore.isLoading ? <Loading/> :
                <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" style={{width : '80px'}} className="text-center">STT</th>
                            <th scope="col" className="text-center">Chức vụ</th>
                            <th scope="col" style={{width : '150px'}} className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settingRoleStore.listPosition.length < 1 ?
                            <NoContent colSpan={4}/> : settingRoleStore.listPosition.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(settingRoleStore.page, i)}</td>
                                        <td className="text-start">{item?.name}</td>
                                        <td className="text-center">
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target={"#setting_role"}
                                                    onClick={async () => {
                                                        settingRoleStore.namePosition = item?.name
                                                    }}>
                                                <img src="/assets/ico/action/edit_green.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Chỉnh sửa"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
            <ModalSettingRole />
        </div>
    );
}

export default observer(SettingRolePage);
