import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {importExportStore, ITypeSupplies} from "../ImportExportStore";
import ImportExportPage from "../ImportExportPage";

const ExportDevicesPage = () => {

    useEffect(() => {
        importExportStore.typeSupplies = ITypeSupplies.devices
    },[])

    return (
        <div className="export_page">
            <ImportExportPage/>
        </div>
    );
}

export default observer(ExportDevicesPage);