import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {restaurantStore} from "./RestaurantStore";

class RestaurantService{

    public getListRestaurant(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/establishment?page=${restaurantStore.page}${restaurantStore.status ? `&status=${restaurantStore.status}` : ''}&size=${size ?? 20}`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/establishment/${id}`)
    }

    public addRestaurant(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/establishment`, data)
    }

    public editRestaurant(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/establishment/${id}`, data)
    }

    public deleteRestaurant(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/establishment/${id}`, {})
    }

    public getProvinces(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/provinces`)
    }

    public getDistricts(provinceId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/districts?provinceId=${provinceId}`)
    }

    public getWards(districtId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/wards?districtId=${districtId}`)
    }

}

export const restaurantService = new RestaurantService()