import React from 'react';
import {observer} from "mobx-react";
import './AddEditProduct.scss'
import {Select, Input, Spin} from 'antd';
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {productStore} from "../../ProductStore";
import {urlImage} from "../../../../../common/utils/Utils";
import {toastUtil} from "../../../../../common/utils/ToastUtil";
import uploadFileHelper from "../../../../../common/helpers/UploadFileHelper";
const ModalAddEditProduct = () => {
    const uploadImgCertificate = (e: any) => {
        productStore.dataRequest.images.push(e)
    }
    const handleAddSupplies = () => {
        if(productStore.supplyItem.id) {
            const isIdInArraySupplies = productStore.dataRequest.supplies.some(
                (supply: { id: any; }) => supply.id === productStore.supplyItem.id
            );
            if(isIdInArraySupplies) {
                toastUtil.warning('Vật tư đã được thêm', 5)
                return;
            }
            else {
                productStore.dataRequest.supplies.push(productStore.supplyItem)
                productStore.supplyItem = {
                    id: null,
                    name: "",
                    qty: "",
                    unit: ""
                }
            }
        }
        else return
    }
    return (
        <div className='modal fade' id='add_edit_product' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div
                            className='value_voucher__title'>{productStore.typeModal === 'add' ? 'THÊM SẢN PHẨM' : 'CẬP NHẬT THÔNG TIN SẢN PHẨM'}</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex justify-content-between'>
                            <div style={{width: '65%'}}>
                                <div className='w-100 d-flex'>
                                    <div className='w-50 form-group pe-2'>
                                        <label className='form-label'>Tên sản phẩm<span className='text-danger'>*</span></label>
                                        <Input type='text' value={productStore.dataRequest?.name}
                                               status={(productStore.errors?.name && !productStore.dataRequest?.name) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   productStore.dataRequest.name = e.target.value
                                               }}
                                               placeholder='Nhập tên ...'/>
                                        {productStore.errors?.name && !productStore.dataRequest.name &&
                                            <small className="text-error">{productStore.errors?.name}</small>}
                                    </div>
                                    <div className='w-50 form-group ps-2'>
                                        <label className='form-label'>Mã sản phẩm<span className='text-danger'>*</span></label>
                                        <Input type='text' value={productStore.dataRequest?.batch}
                                               status={(productStore.errors?.batch && !productStore.dataRequest?.batch) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   productStore.dataRequest.batch = e.target.value
                                               }}
                                               placeholder='HAUS | Nhập vào ...'/>
                                        {productStore.errors?.batch && !productStore.dataRequest.batch &&
                                            <small className="text-error">{productStore.errors?.batch}</small>}
                                    </div>
                                </div>
                                <div className='w-100 d-flex'>
                                    <div className='w-50 form-group pe-2'>
                                        <label className='form-label'>Mô tả</label>
                                        <Input type='text' value={productStore.dataRequest?.description}
                                               status={(productStore.errors?.description && !productStore.dataRequest?.description) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   productStore.dataRequest.description = e.target.value
                                               }}
                                               placeholder='Nhập vào ...'/>
                                        {productStore.errors?.description && !productStore.dataRequest.description &&
                                            <small className="text-error">{productStore.errors?.description}</small>}

                                    </div>
                                    <div className='w-50 form-group ps-2'>
                                        <label className='form-label'>Đơn vị<span className='text-danger'>*</span></label>
                                        <Input type='text' value={productStore.dataRequest?.unit}
                                               status={(productStore.errors?.unit && !productStore.dataRequest?.unit) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   productStore.dataRequest.unit = e.target.value
                                               }}
                                               placeholder='Nhập vào ...'/>
                                        {productStore.errors?.unit && !productStore.dataRequest.unit &&
                                            <small className="text-error">{productStore.errors?.unit}</small>}
                                    </div>
                                </div>
                                <div className='w-100 d-flex'>
                                    <div className='w-50 form-group pe-2'>
                                        <label className='form-label'>Loại<span className='text-danger'>*</span></label>
                                        <Select
                                            value={productStore.dataRequest.type}
                                            status={(productStore.errors?.type && !productStore.dataRequest?.type) ? "error" : ""}
                                            onChange={(e: any) => {
                                                productStore.dataRequest.type = e
                                            }}
                                            placeholder="Chọn" options={[{value: 'APPETIZER', label: 'Món khai vị'}, {
                                            value: 'MAIN_COURSE',
                                            label: 'Món chính'
                                        }, {value: 'DESSERT', label: 'Món tráng miệng'}, {value: 'BEVERAGE', label: 'Đồ uống'}]}
                                        />
                                        {productStore.errors?.type && !productStore.dataRequest.type &&
                                            <small className="text-error">{productStore.errors?.type}</small>}
                                    </div>
                                    <div className='w-50 form-group ps-2'>
                                        <label className='form-label'>Hệ số sản phẩm<span className='text-danger'>*</span>
                                        </label>
                                        <Input type='text'
                                               value={productStore.dataRequest?.coefficient ? numberUtil.number_format(productStore.dataRequest?.coefficient) : ""}
                                               onKeyDown={(evt: {
                                                   key: string
                                                   preventDefault: () => any
                                               }) =>
                                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                                   evt.preventDefault()
                                               }
                                               status={(productStore.errors?.coefficient && !productStore.dataRequest?.coefficient) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   if (
                                                       e === '' ||
                                                       Number(numberUtil.regexNumber(e)) >= 0
                                                   ) {
                                                       productStore.dataRequest.coefficient = numberUtil.regexNumber(e)
                                                   }
                                               }}
                                               placeholder='Nhập số ...'/>
                                        {productStore.errors?.coefficient && !productStore.dataRequest.coefficient &&
                                            <small className="text-error">{productStore.errors?.coefficient}</small>}
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-end'>
                                    <div className='w-50 form-group pe-2'>
                                        <label className='form-label'>Chi phí sản xuất (VNĐ)<span
                                            className='text-danger'>*</span>
                                        </label>
                                        <Input type='text'
                                               value={productStore.dataRequest?.cogs ? numberUtil.number_format(productStore.dataRequest?.cogs) : ""}
                                               onKeyDown={(evt: {
                                                   key: string
                                                   preventDefault: () => any
                                               }) =>
                                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                                   evt.preventDefault()
                                               }
                                               status={(productStore.errors?.cogs && !productStore.dataRequest?.cogs) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   if (
                                                       e === '' ||
                                                       Number(numberUtil.regexNumber(e)) >= 0
                                                   ) {
                                                       productStore.dataRequest.cogs = numberUtil.regexNumber(e)
                                                   }
                                               }}
                                               placeholder='Nhập số ...'/>
                                        {productStore.errors?.cogs && !productStore.dataRequest.cogs &&
                                            <small className="text-error">{productStore.errors?.cogs}</small>}
                                    </div>

                                    <div className='w-50 form-group ps-2'>
                                        <label className='form-label'>Giá bán (VNĐ)<span className='text-danger'>*</span>
                                        </label>
                                        <Input type='text'
                                               value={productStore.dataRequest?.price ? numberUtil.number_format(productStore.dataRequest?.price) : ""}
                                               onKeyDown={(evt: {
                                                   key: string
                                                   preventDefault: () => any
                                               }) =>
                                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                                   evt.preventDefault()
                                               }
                                               status={(productStore.errors?.price && !productStore.dataRequest?.price) ? "error" : ""}
                                               onChange={(e: any) => {
                                                   if (
                                                       e === '' ||
                                                       Number(numberUtil.regexNumber(e)) >= 0
                                                   ) {
                                                       productStore.dataRequest.price = numberUtil.regexNumber(e)
                                                   }
                                               }}
                                               placeholder='Nhập số ...'/>
                                        {productStore.errors?.price && !productStore.dataRequest.price &&
                                            <small className="text-error">{productStore.errors?.price}</small>}

                                    </div>
                                </div>
                                <div className="w-100 flex flex-wrap justify-between mb-4">
                                    <label className='form-label'>Hình ảnh sản phẩm</label>
                                    <div className='list_image'>
                                        <div className='list_'>
                                            <div
                                                className='upload'
                                                title="Thêm ảnh nông trường & sản phẩm"
                                            >
                                                <img src="/assets/ico/action/upload.svg" alt=""/>
                                                <input
                                                    accept="image/jpeg, image/jpg, image/png"
                                                    type="file"
                                                    onChange={async (e: any) =>
                                                        await uploadFileHelper(
                                                            e,
                                                            uploadImgCertificate
                                                        )
                                                    }
                                                />
                                            </div>
                                            {productStore.dataRequest.images &&
                                                productStore.dataRequest.images.map(
                                                    (item: any, i: number) => {
                                                        return (
                                                            <div className='item' key={i}>
                                                                <img className='img' src={urlImage(item)} alt=""/>
                                                                <div
                                                                    className='close_item'
                                                                    onClick={() =>
                                                                        productStore.dataRequest.images.splice(
                                                                            i,
                                                                            1
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="/assets/ico/action/button_clear_image.svg"
                                                                         className="icon-action"
                                                                         alt=""
                                                                         title="Chỉnh sửa"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '33%'}} className='setting_supplies__product d-flex flex-column justify-content-between'>
                                <div>
                                    <div className='setting_supplies__product_title'>
                                        Thiết lập vật tư
                                    </div>
                                    <div className='w-100 form-group'>
                                        <label className='form-label'>Loại vật tư</label>
                                        <Select
                                            allowClear
                                            value={productStore.typeSupplies}
                                            onChange={async (e: any) => {
                                                productStore.typeSupplies = e
                                                productStore.supplyItem = {
                                                    id: null,
                                                    name: "",
                                                    qty: ""
                                                }
                                                await productStore.getListSupplies()
                                            }}
                                            placeholder="Chọn" options={[
                                            {value: 'MATERIALS', label: 'Nguyên vật liệu'},
                                            {value: 'PACKAGING', label: 'Bao bì'},
                                            {value: 'ADDITIVE', label: 'Phụ gia'},
                                            {value: 'DEVICES', label: 'Thiết bị'}
                                        ]}
                                        />
                                    </div>
                                    <div className='w-100 form-group'>
                                        <label className='form-label'>Chọn vật tư
                                        </label>
                                        <Select
                                            allowClear
                                            value={productStore.supplyItem.id}
                                            status={(productStore.errors?.type && !productStore.dataRequest?.type) ? "error" : ""}
                                            onChange={(e: any, value: any) => {
                                                productStore.supplyItem.id = e
                                                productStore.supplyItem.name = value.label
                                                productStore.supplyItem.unit = value.unit
                                            }}
                                            placeholder="Chọn" options={productStore.listSupplies}
                                        />
                                        {productStore.errors?.coefficient && !productStore.dataRequest.coefficient &&
                                            <small className="text-error">{productStore.errors?.coefficient}</small>}
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button onClick={handleAddSupplies} className='button_add_supplies'>
                                            <img src="/assets/ico/action/plus_green.svg" alt="button_add_product"/>
                                            Thêm vật tư
                                        </button>
                                    </div>
                                    <div className='supplies_product__container'>
                                        {
                                            productStore.dataRequest?.supplies.length > 0 ? productStore.dataRequest?.supplies?.map((itemSupplies: any, indexSupplies: number) =>{
                                                return(
                                                    <div className='supplies_item' key={indexSupplies}>
                                                        <div className='supplies_item__name' style={{width: '60%'}}>
                                                            {indexSupplies + 1} . {itemSupplies?.name} ({itemSupplies?.unit})
                                                        </div>
                                                        <div style={{width: '40%'}} className='d-flex justify-content-between'>
                                                            <input
                                                                type="number"
                                                                onKeyDown={(evt: {
                                                                    key: string
                                                                    preventDefault: () => any
                                                                }) =>
                                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (e === '' || Number(numberUtil.regexNumber(e)) >= 0) {
                                                                        itemSupplies.qty = numberUtil.regexNumber(e)
                                                                    }
                                                                }}
                                                                className={productStore.isErrorsQtySupplies && !itemSupplies.qty ? 'border_error' : ''}
                                                                value={itemSupplies.qty ? numberUtil.number_format(itemSupplies.qty) : ""}
                                                                required={true}
                                                                placeholder="Nhập số..."
                                                            />
                                                            <img onClick={() => {
                                                                productStore.dataRequest.supplies.splice(indexSupplies, 1)
                                                            }} src="/assets/ico/action/delete_icon.svg" style={{width: '20px'}} className='cursor_pointer button_delete__supplies' alt=""/>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className='d-flex flex-column justify-content-center align-items-center no_supplies__container' style={{height: '100px'}}>
                                                <img src="/assets/ico/no_supplies.svg" style={{width: '50px'}} alt=""/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-end'>
                                    <button type='submit' className='btn btn__voucher'
                                            onClick={async () => {
                                                await productStore.addEditProduct()
                                            }}>{productStore.isLoadingBt ? <Spin size="small" /> : productStore.typeModal === 'add' ? 'Thêm' : 'Cập nhật'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default observer(ModalAddEditProduct);

