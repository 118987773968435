import React, {useEffect} from 'react';
import './DepartmentPage.scss'
import {observer} from "mobx-react";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {departmentStore} from "./DepartmentStore";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import ModalAddEditDepartment from "./components/modalAddEditDepartment/ModalAddEditDepartment";
import PopupConfirm from "../../../modules/components/PopUpConfirm";

const DepartmentPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm tên , mã nhân viên...',
            value: '',
            onChange: (e: any) => {
                departmentStore.keyword  = e
            },
            callback: async () => {
                departmentStore.getDepartment().then()
            }
        },
    ]
    useEffect(() => {
        departmentStore.getDepartment().then()
    }, [])
    return (
        <>
            <div className='department_container'>
                <div className='department_container__header'>
                    <div  className='d-flex w-100 justify-content-end align-items-center'>
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            buttonAdd={<ButtonGlobal onClick={() => {
                                departmentStore.typeModal = 'add'
                                departmentStore.clearForm()
                            }} dataTarget='add_edit_department' text='Thêm' ico='add_icon' className='btn_add' />}
                        />
                    </div>
                </div>
                {departmentStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" style={{width : '80px'}} className="text-center">STT</th>
                            <th scope="col" className="text-center">Phòng ban</th>
                            <th scope="col" className="text-center">Mã</th>
                            <th scope="col" style={{width : '150px'}} className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {departmentStore.listDepartment?.length <= 0 ? <NoContent colSpan={4}/> : departmentStore.listDepartment.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-start">{item?.name ?? "-"}</td>
                                    <td className="text-center">{item?.code ?? "-"}</td>
                                    <td className="text-center">
                                        <button className="btn p-0"
                                                data-bs-toggle="modal" data-bs-target={"#add_edit_department"}
                                                onClick={async () => {
                                                    departmentStore.typeModal = 'edit'
                                                    departmentStore.id = item?.id
                                                    await departmentStore.getDetailAccount(item?.id)
                                                }}>
                                            <img src="/assets/ico/action/edit_green.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Chỉnh sửa"/>
                                        </button>

                                        <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                onClick={() => departmentStore.id = item.id}>
                                            <img src="/assets/ico/action/delete_icon.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <ModalAddEditDepartment />
            <PopupConfirm isLoading={departmentStore.isLoadingBt} action={departmentStore.deleteDepartment} />
        </>
    );
}

export default observer(DepartmentPage);