import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {departmentService} from "./DepartmentService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";


export interface IDataRequestDepartment{
    name: string
    code: string
}

class DepartmentStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listDepartment: any[] = []

    dataRequest : IDataRequestDepartment = {
        name: "",
        code: ""
    }
    errors: any = {
        name: "",
        code: ""
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.typeModal = 'add'
        this.dataRequest = {
            name: "",
            code: ""
        }
        this.errors = {
            name: "",
            code: ""
        }
    }

    async getDepartment() {
        this.isLoading = true
        const result = await departmentService.getDepartment()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listDepartment = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetailAccount(id: any) {
        const result = await departmentService.getDepartmentById(id)
        if (result.status === HttpStatusCode.OK) {
            departmentStore.dataRequest = {
                name: result.body?.name,
                code: result.body?.code
            }
        }
    }
    async deleteDepartment() {
        departmentStore.isLoadingBt = true
        const result = await departmentService.deleteDepartment(departmentStore.id)
        departmentStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa phòng ban thành công!')
            await  departmentStore.getDepartment()
        } else toastUtil.error(result.body.message)
    }
    async addEditDepartment() {
        if(!this.dataRequest.name) {
            this.errors.name = "Vui lòng nhập tên phòng ban !"
            return
        }
        if(!this.dataRequest.code) {
            this.errors.code = "Vui lòng nhập mã phòng ban !"
            return
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await departmentService.add(departmentStore.dataRequest) : await departmentService.editDepartment(departmentStore.id, departmentStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${departmentStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} phòng ban thành công!`, 1)
            $('#add_edit_department').trigger('click')
            this.clearForm()
            await departmentStore.getDepartment()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const departmentStore = new DepartmentStore()