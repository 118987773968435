import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {importExportStore} from "./ImportExportStore";

class ImportExportService {

    public getImpExpSupplies(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/request_item?page=0size=100${importExportStore.typeImportExport? `&type=${String(importExportStore.typeImportExport).toUpperCase()}` : ''}${importExportStore.typeSupplies? `&suppliesType=${String(importExportStore.typeSupplies).toUpperCase()}` : ''}${importExportStore.keyword? `&code=${importExportStore.keyword}` : ''}`)
    }

    public getDetailSupplies(id: number): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/supplies/${id}`)
    }

    public getImpExpRequest(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/request?page=0&type=${importExportStore.typeImportExport === 'export' ? "EXPORT" : "IMPORT"}${importExportStore.keyword ? `&code=${importExportStore.keyword}` : ''}${importExportStore.searchWarehouse ? `&warehouseId=${importExportStore.searchWarehouse}` : ''}&size=500`)
    }

    public getDetailRequest(id: number): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/request/${id}`)
    }

    public sendImpExpRequest(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/warehouse/request`, data)
    }
    public confirmApprovedRequest(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/warehouse/request/${id}/approved`, data)
    }

    public updateImpExpRequest(id: any, data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/warehouse/request/${id}`, data)
    }
    public changeStatusRequest(data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/warehouse/request/change_status`, data)
    }
}

export const importExportService = new ImportExportService()