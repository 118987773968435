import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './ProductPage.scss'
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {sttPage, urlImage} from "../../../common/utils/Utils";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {productStore} from "./ProductStore";
import ModalAddEditProduct from "./components/addEditProduct/AddEditProduct";
import InfiniteScroll from 'react-infinite-scroll-component';

export const convertTypeProduct = (value: string) => {
    switch (value.toUpperCase()) {
        case "APPETIZER":
            return <span style={{color: '#515158'}}>Món khai vị</span>
        case "MAIN_COURSE":
            return <span style={{color: '#515158'}}>Món chính</span>
        case "DESSERT":
            return <span style={{color: '#515158'}}>Món tráng miệng</span>
        case "BEVERAGE":
            return <span style={{color: '#515158'}}>Đồ uống</span>
        default:
            return null
    }
}
const ProductPage = () => {
    useEffect(() => {
        productStore.searchType = null
        productStore.getListProduct().then()
    }, [])
    const dataFilter : IFilter[] = [

        {
            label: 'Loại sản phẩm',
            type: 'select',
            value: productStore.searchType,
            placeholder: 'Chọn',
            listValue: [{value: 'APPETIZER', label: 'Món khai vị'}, {value: 'MAIN_COURSE', label: 'Món chính'},  {value: 'DESSERT', label: 'Món tráng miệng'}, {value: 'BEVERAGE', label: 'Đồ uống'}],
            callback: async (e: any) => {
                productStore.searchType = e
                productStore.page = 0
                productStore.listProduct = []
                productStore.hasMore = !productStore.searchType
                if(!e) await productStore.getListProduct().then()
                else await productStore.getListProduct(100).then()
            }
        },
    ]
    return (
        <div className='kpi_page'>
            <div className='d-flex justify-content-between'>
                <TabRedirect tab='Thiết lập sản phẩm' />
                <FilterComponent
                    data={dataFilter}
                    permissionCode
                    buttonAdd={<ButtonGlobal onClick={() => {
                        productStore.typeModal = 'add'
                        productStore.clearForm()
                    }} dataTarget='add_edit_product' text='Thêm' ico='add_icon' className='btn_add' />}
                />
            </div>
            <div className='w-100'>
                <InfiniteScroll
                    dataLength={productStore.listProduct.length}
                    next={productStore.getListProduct}
                    hasMore={productStore.hasMore}
                    loader={<Loading/>}
                >
                    <div className="table-responsive d-flex flex-column mb-4 justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Hình ảnh</th>
                            <th className="text-center">Tên sản phẩm</th>
                            <th className="text-center">Mã sản phẩm</th>
                            <th className="text-center">Loại</th>
                            <th className="text-center">Chi phí sản xuất</th>
                            <th className="text-center">Giá (VNĐ)</th>
                            <th className="text-center">Hệ số sản phẩm</th>
                            <th className="text-center">Mô tả</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productStore.listProduct?.length <= 0 && !productStore.isLoading ? <NoContent colSpan={10}/> : productStore.listProduct.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td style={{width: '38px'}} className="text-center">{sttPage(0, i)}</td>
                                    <td className="text-center fw-500">
                                        {item?.images?.[0] ? <img src={urlImage(item?.images?.[0])} className='image_product' alt=""/> : ""}
                                    </td>
                                    <td className="text-start fw-500">{item?.name}</td>
                                    <td className="text-center fw-500">{item?.batch}</td>
                                    <td className="text-center fw-500">{convertTypeProduct(item?.type)}</td>
                                    <td className="text-end">{numberUtil.number_format(item?.cogs)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.price)}</td>
                                    <td className="text-center">{numberUtil.number_format(item?.coefficient)}</td>
                                    <td className="text-start">{item?.description}</td>
                                    <td className="text-center">
                                        <button className="btn p-0"
                                                data-bs-toggle="modal" data-bs-target={"#add_edit_product"}
                                                onClick={async () => {
                                                    productStore.id = item?.id
                                                    productStore.typeSupplies = null
                                                    productStore.isErrorsQtySupplies = false
                                                    await productStore.getDetailProduct(item.id)}
                                                }>
                                            <img src="/assets/ico/action/edit_icon.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Chỉnh sửa"/>
                                        </button>

                                        <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                onClick={() => productStore.id = item.id}>
                                            <img src="/assets/ico/action/delete_icon.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                </InfiniteScroll>
            </div>
            <ModalAddEditProduct />
            <PopupConfirm isLoading={productStore.isLoadingBt} action={productStore.deleteProduct} />
        </div>
    );
}

export default observer(ProductPage);