import React from 'react';
import {observer} from "mobx-react";
import './ModalProductVoucher.scss'
// @ts-ignore
import {DatePicker, Radio, Select, Input, Space, Spin} from 'antd';
import {voucherStore} from "../../VoucherStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {Moment} from "moment";
import ModalAddProductVoucher from "./modalAddProductVoucher/modalAddProductVoucher";
dayjs.extend(customParseFormat);

function convertTime(date?: Moment, isEndDate?: boolean): number {
    if (!date) return 0;
    if (isEndDate) {
        return date.set({hour: 23, minute: 59, second: 59}).unix() * 1000;
    }
    return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
}
const handleRangePickerChange = (dates: any) => {
    if (dates && dates.length > 0) {
        voucherStore.dataRequestValueVoucher.startTime = convertTime(dates[0]);
        voucherStore.dataRequestValueVoucher.endTime = convertTime(dates[1]);
    }
}
const ModalProductVoucher = () => {
    const {RangePicker} = DatePicker;
    return (
        <div className='modal fade' id='product_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>VOUCHER TẶNG SẢN PHẨM</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên chương trình giảm giá<span className='text-danger'>*</span></label>
                                <Input type='text' value={voucherStore.dataRequestValueVoucher?.name}
                                       status={(voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           voucherStore.dataRequestValueVoucher.name = e.currentTarget.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher.name && <small className="text-error">{voucherStore.errors?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Đối tượng áp dụng<span className='text-danger'>*</span></label>
                                <Select
                                    disabled
                                    onChange={(e: any) => {
                                        alert(e.currentTarget.value)}}
                                    placeholder="Chọn" options={[{value: '11', label: '1'}, {value: '22', label: '2'}]}
                                />
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Mã Voucher<span className='text-danger'>*</span></label>
                                <Input type='text'  value={voucherStore.dataRequestValueVoucher?.code}
                                       status={(voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher?.code) ? "error" : ""}
                                       onChange={(e: any) => {
                                           voucherStore.dataRequestValueVoucher.code = e.currentTarget.value
                                       }}
                                       placeholder='HUAS | Nhập vào ...' />
                                {voucherStore.errors?.code  && !voucherStore.dataRequestValueVoucher.code && <small className="text-error">{voucherStore.errors?.code}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số voucher phát hành<span className='text-danger'>*</span></label>
                                <Input type='number'  value={voucherStore.dataRequestValueVoucher?.amount}
                                       status={(voucherStore.errors?.amount && !voucherStore.dataRequestValueVoucher?.amount) ? "error" : ""}
                                       onChange={(e: any) => {
                                           voucherStore.dataRequestValueVoucher.amount = e.currentTarget.value
                                       }}
                                       placeholder='Nhập số ...' />
                                {voucherStore.errors?.amount  && !voucherStore.dataRequestValueVoucher?.amount && <small className="text-error">{voucherStore.errors?.amount}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                    <div className='w-100 mb-3 d-flex justify-content-between align-items-center'>
                                        <label className='form-label'>Sản phẩm Combo</label>
                                        <button className='button_add_product__voucher'  data-bs-toggle="modal" data-bs-target="#add_product_voucher__product">
                                            <img src="/assets/ico/action/plus_green.svg" alt="button_add_product"/>
                                            Thêm sản phẩm
                                        </button>
                                    </div>
                                    <div className='list_product__voucher'>
                                        {voucherStore.dataRequestValueVoucher?.proviso?.products?.length <= 0 ? <div className='list_product__placeholder'>
                                                Chọn 1 sản phẩm trở lên
                                            </div> :
                                            <>{
                                                voucherStore.dataRequestValueVoucher?.proviso?.products?.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index} className='product_item__voucher'>
                                                            {item.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                            </>
                                        }

                                    </div>
                                    {voucherStore.errors.proviso?.products &&  voucherStore.dataRequestValueVoucher?.proviso?.products?.length <= 0 &&  <small className="text-error">{voucherStore.errors.proviso?.products}</small>}
                            </div>
                            <div className='w-50 d-flex justify-content-between flex-column ps-2'>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Thời gian sử dụng mã<span className='text-danger'>*</span></label>
                                    <RangePicker
                                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                        onChange={handleRangePickerChange}
                                        format={'DD/MM/YYYY'}
                                        status={(voucherStore.errors?.time && !voucherStore.dataRequestValueVoucher?.startTime &&  !voucherStore.dataRequestValueVoucher?.endTime) ? "error" : ""}
                                        value={[
                                            voucherStore.dataRequestValueVoucher.startTime === "" ? null : dayjs(voucherStore.dataRequestValueVoucher.startTime),
                                            voucherStore.dataRequestValueVoucher.endTime === "" ? null : dayjs( voucherStore.dataRequestValueVoucher.endTime)
                                        ] as any}
                                    />
                                    {voucherStore.errors?.time  && !voucherStore.dataRequestValueVoucher.startTime &&  !voucherStore.dataRequestValueVoucher.endTime && <small className="text-error">{voucherStore.errors?.time}</small>}
                                </div>
                                <div className='w-100 d-flex justify-content-end'>
                                        <button type='submit' className='btn btn__voucher'
                                                onClick={async () => {
                                                    await voucherStore.addEditPromotion()}}>
                                            {voucherStore.isLoadingBt ? <Spin size="small" /> : voucherStore.type === 'add' ?  'Tạo Voucher' : voucherStore.type === 'copy' ?  "Sao chép Voucher" : 'Cập nhật Voucher'}
                                        </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <ModalAddProductVoucher />
        </div>
    )
}

export default observer(ModalProductVoucher);

