import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {warehouseStore} from "../WarehouseStore";
import InputForm from "../../../../modules/components/form/Input";
import SelectForm from "../../../../modules/components/form/SelectAnt";
import {Input} from "antd";


const AddEditWarehouse = () => {

    useEffect(() => {
        warehouseStore.getProvinces().then()
    }, [])

    return (
        <div className="modal fade" id="add_edit_warehouse" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 800}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{warehouseStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} kho</h5>
                        <button type="button" id="close_warehouse" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className='modal-body p-0 mt-4'>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Cửa hàng <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.establishmentId} isError={warehouseStore.error.establishment} onChange={(e: any) => warehouseStore.dataRequest.establishmentId = e}
                                        options={warehouseStore.listEstablishment}/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Loại <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.type} isError={warehouseStore.error.type} onChange={(e: any) => warehouseStore.dataRequest.type = e}
                                        options={[
                                    {
                                        name: 'Kho tổng',
                                        id: 'MAIN'
                                    },
                                    {
                                        name: 'Chi nhánh',
                                        id: 'BRANCH'
                                    }
                                ]}/>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Tên kho <span className="text-danger">*</span></label>
                                <InputForm value={warehouseStore.dataRequest.name} isError={warehouseStore.error.name}
                                       onChange={(e: any) => warehouseStore.dataRequest.name = e.currentTarget.value}
                                       placeholder="Nhập tên kho"/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Mã kho <span className="text-danger">*</span></label>
                                <InputForm value={warehouseStore.dataRequest.code} isError={warehouseStore.error.code}
                                       onChange={(e: any) => warehouseStore.dataRequest.code = e.currentTarget.value}
                                       placeholder="Nhập mã kho"/>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Trưởng kho <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.user.id} isError={warehouseStore.error.user}
                                    onChange={(e: any) => warehouseStore.dataRequest.user.id = e}
                                    options={warehouseStore.listStaff}/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <Input type='number' value={warehouseStore.dataRequest.phone}
                                       status={(warehouseStore.error.phone && !warehouseStore.dataRequest.phone) ? "error" : ""}
                                       onChange={(e: any) => {
                                           warehouseStore.dataRequest.phone = e.target.value
                                       }}
                                       placeholder='Nhập số điện thoại ...' />
                                {warehouseStore.error.phone && !warehouseStore.dataRequest.phone && <small className="text-error">{warehouseStore.error.phone}</small>}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-4">
                                <label>Tỉnh/Thành phố <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.address.province.id} isError={warehouseStore.error.province}
                                        onChange={(e: any) => warehouseStore.changeProvince(e)}
                                        options={warehouseStore.listProvince}/>
                            </div>
                            <div className="col-4">
                                <label>Quận/Huyện <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.address.district.id} isError={warehouseStore.error.district}
                                        onChange={(e: any) => warehouseStore.changeDistrict(e)}
                                        options={warehouseStore.listDistrict}/>
                            </div>
                            <div className="col-4">
                                <label>Phường/Xã <span className="text-danger">*</span></label>
                                <SelectForm value={warehouseStore.dataRequest.address.ward.id} isError={warehouseStore.error.ward}
                                        onChange={(e: any) => warehouseStore.dataRequest.address.ward.id = e}
                                        options={warehouseStore.listWard}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Địa chỉ cụ thể <span className="text-danger">*</span></label>
                            <InputForm value={warehouseStore.dataRequest.address.detail} isError={warehouseStore.error.address}
                                   onChange={(e: any) => warehouseStore.dataRequest.address.detail = e.currentTarget.value}
                                   placeholder="Nhập địa chỉ cụ thể"/>
                        </div>
                        <div className="mt-4 d-flex justify-content-end">
                            <button className="btn send_request" type="submit" disabled={warehouseStore.isLoadingBt}
                                    onClick={() => warehouseStore.addEdit()}>Hoàn thành
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(AddEditWarehouse);