import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {importExportStore} from "./ImportExportStore";
import {convertDataSelectAnt} from "../../../common/utils/Utils";
import {warehouseStore} from "../../setting/warehouse/WarehouseStore";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import Loading from "../../../modules/components/Loading";
import {dateUtils} from "../../../common/utils/DateUtils";
import NoContent from "../../../modules/components/NoContent";
import {suppliesStore} from "../../setting/supplies/SuppliesStore";
import AddImpExpRequest from "./components/AddImpExpRequest";
import PopupImpExpDetail from "./components/PopupImpExpDetail";
import {convertStatusImportRequest} from "./ReceiptPage";
import ConfirmSuppliesImport from "./components/ConfirmSuppliesImport";


const DeliveryBillPage = () => {

    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: importExportStore.keyword,
            onChange: (e: any) => importExportStore.keyword = e,
            callback: async () => await importExportStore.getImpExpRequest()
        },
        {
            label: 'Kho',
            type: 'select',
            value: importExportStore.searchWarehouse,
            placeholder: 'Chọn kho',
            listValue: convertDataSelectAnt(warehouseStore.listWarehouse),
            callback: async (e: any) => {
                importExportStore.searchWarehouse = e
                await importExportStore.getImpExpRequest()
            }
        },
    ]

    useEffect(() => {
        importExportStore.typeImportExport = window.location.pathname.split('/')[1]
        importExportStore.getImpExpRequest().then()
        warehouseStore.getWarehouse().then()
        return () => {
            suppliesStore.type = null
            importExportStore.keyword = ''
            importExportStore.searchWarehouse = null
            suppliesStore.listSupplies = []
            warehouseStore.listWarehouse = []
        }
    }, [])

    return (
        <div className="delivery_bill_page">
            <div className='tab_filter_header'>
                <h4 className='title'>Đề nghị xuất kho</h4>
                <FilterComponent permissionCode={true} data={dataFilter}
                                 buttonAdd={<ButtonGlobal onClick={() => {
                                     importExportStore.clearForm()
                                 }} dataTarget="add_import_export_request" text='Thêm' ico='add_icon' className='btn_add'/>}/>
            </div>
            {importExportStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Mã phiếu đề nghị <br/> xuất kho</th>
                        <th className="text-center">Người đề nghị</th>
                        <th>Mục đích sử dụng</th>
                        <th className="text-center">Kho</th>
                        <th className="text-center">Trạng thái</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {importExportStore.listImportExportRequest && importExportStore.listImportExportRequest.length > 0 ? importExportStore.listImportExportRequest.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{dateUtils.formatDate(item.timeRequest, 'hh:mm , DD/MM/YYYY')}</td>
                                <td className="text-center">{item.code}</td>
                                <td className="text-center">{item.requestUser?.name}</td>
                                <td>{item.purpose}</td>
                                <td className="text-center">{item?.mainWarehouse?.name}</td>
                                <td className="text-center">{convertStatusImportRequest(item.status)}</td>
                                <td className="text-center">
                                    <button className="btn"
                                            onClick={() => importExportStore.getDetailRequest(item.id)} data-bs-toggle="modal" data-bs-target='#popup_imp_exp_request'>
                                        <img src="/assets/ico/action/detail_icon.svg" alt=""/>
                                    </button>
                                    {item?.status === "PROCESSING" && (Number(localStorage.getItem('fnbUserId')) !== item.requestUser.id)
                                        && <button className="btn p-0 mx-1"
                                                   onClick={async () => {
                                                       importExportStore.dataRequestChangeStatus = {
                                                           ids: [item.id],
                                                           status: "EXPORTED"
                                                       }
                                                       await importExportStore.changeStatusRequest()
                                                   }}
                                        >
                                        <img src="/assets/ico/action/check-circle-2.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Duyệt xuất kho"/>
                                    </button>}
                                </td>
                            </tr>
                        )
                    }) : <NoContent colSpan={8}/>}
                    </tbody>
                </table>
            </div>}
            <AddImpExpRequest text="XUẤT" />
            <PopupImpExpDetail/>
            <ConfirmSuppliesImport/>
        </div>

    );

}

export default observer(DeliveryBillPage);