import {makeAutoObservable} from 'mobx'
import {toastUtil} from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {settingInformationService} from "./SettingInformationService";
import {validatePhoneNumber} from "../../../common/utils/Utils";
class SettingInformationStore {
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listProvince: any[] = []
    listDistrict: any[] = []
    listWard: any[] = []
    dataRequest: any = {
        name: "",
        districtId: null,
        provinceId: null,
        wardId: null,
        address: "",
        businessNumber: "",
        numBranch: "",
        ownerName: "",
        ownerPhone: "",
        logo: ""
    }
    errors : any = {
        name: "",
        districtId: "",
        provinceId: "",
        wardId: "",
        address: "",
        businessNumber: "",
        numBranch: "",
        ownerName: "",
        ownerPhone: "",
        logo : ""
    }
    async getToken(){
    }
    async getDetailRestaurant(id: any) {
        this.isLoading = true
        const result = await settingInformationService.getDetailRestaurant(id)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = {
                ...result.body,
                address: result.body?.address?.detail,
                provinceId : result.body?.address?.province?.id,
                districtId : result.body?.address?.district?.id,
                wardId : result.body?.address?.ward?.id,
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async updateInfo() {
        if(!this.dataRequest?.name) {
            this.errors.name = "Tên thương hiệu không được bỏ trống"
            return
        }
        if(!this.dataRequest?.businessNumber) {
            this.errors.businessNumber = "Quy mô cửa hàng không được bỏ trống"
            return
        }
        if(!this.dataRequest?.numBranch) {
            this.errors.numBranch = "Số chi nhánh không được bỏ trống"
            return
        }
        if(!this.dataRequest?.ownerName) {
            this.errors.ownerName = "Họ và tên không được bỏ trống"
            return
        }
        if(!this.dataRequest?.ownerPhone) {
            this.errors.ownerPhone = "Số điện thoại không được bỏ trống"
            return
        }
        if (!validatePhoneNumber(this.dataRequest.ownerPhone)) {
            this.dataRequest.ownerPhone = ''
            this.errors.ownerPhone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            return
        }
        if(!this.dataRequest?.provinceId) {
            this.errors.provinceId = "Tỉnh/TP không được bỏ trống"
            return
        }
        if(!this.dataRequest?.districtId) {
            this.errors.districtId = "Quận/Huyện không được bỏ trống"
            return
        }
        if(!this.dataRequest?.wardId) {
            this.errors.wardId = "Xã/Phường không được bỏ trống"
            return
        }
        if(!this.dataRequest?.address) {
            this.errors.address = "Địa chỉ cụ thể không được bỏ trống"
            return
        }
        if(!this.dataRequest?.logo) {
            this.errors.logo = "Vui lòng thêm logo cho cửa hàng"
            return
        }
        this.isLoadingBt = true
        const result = await settingInformationService.updateInfoRestaurant(this.dataRequest, localStorage.getItem('fnbId'))
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            if(result.body.name) localStorage.setItem('name_restaurant', result.body.name)
            toastUtil.success("Cập nhật thông tin cửa hàng thành công", 1)
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getProvinces() {
        const result = await settingInformationService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            settingInformationStore.listProvince = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }
    async getDistricts(provinceId: any) {
        const result = await settingInformationService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            settingInformationStore.listDistrict = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getWards(districtId: any) {
        const result = await settingInformationService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            settingInformationStore.listWard = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async changeProvince(provinceId: any) {
        settingInformationStore.dataRequest.provinceId = provinceId
        if (settingInformationStore.dataRequest.provinceId) {
            settingInformationStore.dataRequest.districtId = null
            settingInformationStore.errors.provinceId = ''
            settingInformationStore.dataRequest.wardId = null

        }
        await settingInformationStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        settingInformationStore.dataRequest.districtId = districtId
        if (settingInformationStore.dataRequest.districtId) {
            settingInformationStore.dataRequest.wardId = null
            settingInformationStore.errors.districtId = ''
        }
        await settingInformationStore.getWards(districtId)
    }

    constructor() {
        makeAutoObservable(this)
    }

}

export const settingInformationStore = new SettingInformationStore()