import {getRequest, IApiResponse, putRequest} from "../../../common/helpers/RequestHelper";
import {orderShipStore} from "./OrderShipStore";

class OrderShipService{
    public getOrderShip(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/transport_requests?page=${orderShipStore.page}${orderShipStore.searchStatusOrder ? `&state=${orderShipStore.searchStatusOrder}` : ''}${orderShipStore.searchKeywordOrder ? `&keyword=${orderShipStore.searchKeywordOrder}` : ''}`)
    }
    public detailOrderById(id: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/transport_requests/${id}`);
    }
    public changeStatusOrder(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/transport_requests/${id}/change_state`, data);
    }
}
export const orderShipService = new OrderShipService()