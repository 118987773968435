import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import './DetailOrderShipById.scss'
import {dateUtils} from "../../../common/utils/DateUtils";
import {Select, Spin} from "antd";
import {orderShipStore} from "./OrderShipStore";
import ActiveTrackingCard from "./orderTrackingCard/OrderTrackingCard";
import {getIDParameter} from "../../../common/utils/Utils";
import {numberUtil} from "../../../common/utils/NumberUtil";
import ViewDetailOrderModal from "../../checkOrder/components/viewHistoryOrder/ViewHistoryOrder";
import {checkOrderStore} from "../../checkOrder/CheckOrderStore";
import Loading from "../../../modules/components/Loading";
export const convertTagStatusOrder = (value: string) => {
    switch (value) {
        case 'PENDING':
            return <span className="tag-status tag-pending">Chờ xác nhận</span>
        case 'DELIVERY_CONFIRMATION':
            return <span className="tag-status tag-purple">Đang giao</span>
        case 'DELIVERED':
            return <span className="tag-status tag-green">Đã giao</span>
        case 'CANCEL':
            return <span className="tag-status tag-red">Đã hủy</span>
        default:
            return null
    }
}
const OrderShipBuyId = () => {
    useEffect(() => {
        orderShipStore.getDetailOrder(Number(getIDParameter(2))).then(() => {
            orderShipStore.idOrder = Number(getIDParameter(2))
        })
    }, [])

    const convertChangeStatus = (status: any) => {
        switch (status) {
            case "PENDING":
                return (
                    <div className='convert_status__transport--container'>
                        <button
                            onClick={async () => {
                                await orderShipStore.updateOrder(orderShipStore.idOrder, "CANCEL").then(() => {
                                    orderShipStore.getDetailOrder(orderShipStore.idOrder)
                                })
                            }}
                            className='btn btn_cancel__transport'>
                            {orderShipStore.isLoadingBt ? <Spin size="small" /> : 'Hủy'}
                        </button>
                        <button
                            onClick={async () => {
                                await orderShipStore.updateOrder(orderShipStore.idOrder, "DELIVERY_CONFIRMATION").then(() => {
                                    orderShipStore.getDetailOrder(orderShipStore.idOrder)
                                })
                            }}
                            className='btn btn_transport'>
                            {orderShipStore.isLoadingBt ? <Spin size="small" /> : 'Đang giao'}
                        </button>
                    </div>
                )
            case "DELIVERY_CONFIRMATION":
                return (
                    <div
                        onClick={async () => {
                            await orderShipStore.updateOrder(orderShipStore.idOrder, "DELIVERED").then(() => {
                                orderShipStore.getDetailOrder(orderShipStore.idOrder)
                            })
                        }}
                        className='convert_status__transport--container'>
                        <button className='btn btn_transport'>
                            {orderShipStore.isLoadingBt ? <Spin size="small" /> : 'Đã giao'}
                        </button>
                    </div>
                )
            default:
                return <div className='convert_status__transport--container'>
                </div>
        }
    }

    return (
        <div id={'order_ship__container'} className="order_ship__container w-100 h-auto d-flex justify-content-center pt-5">
            {!orderShipStore.isLoading ? <div className="w-100 flex justify-between px-6 py-4">
                <div className="header_order__ship w-100 d-flex justify-content-between align-items-center mb-3">
                    <div className='d-flex align-items-center'>
                        <Link
                            to={`/van-chuyen`}
                            className="btn_back d-flex justify-content-between align-items-center"
                        >
                            <img
                                src='/assets/ico/action/back_left.svg'
                                width={24}
                                height={24}
                                className="me-2 text-sm font-normal"
                                alt="icon-action"
                            />
                            Thông tin đơn hàng
                        </Link>
                        <span className='order_code'>
                        #{orderShipStore.dataOrderById?.code}
                        </span>
                        {convertTagStatusOrder(orderShipStore.dataOrderById?.state)}
                        <span className="order_time">
                            {dateUtils.formatDate(orderShipStore.dataOrderById?.createdAt, 'hh:mm DD/MM/YYYY')}
                        </span>
                    </div>
                    <div>
                        {
                            convertChangeStatus(orderShipStore.dataOrderById?.state)
                        }

                    </div>
                </div>
                <div className='w-100 tracking_order__status mb-3'>
                    <ActiveTrackingCard
                        state={orderShipStore.dataOrderById?.state}
                        logs={orderShipStore.dataOrderById?.logs}
                    />
                </div>
                <div className='des_info__order w-100 d-flex justify-content-start align-items-start mb-3'>
                        <div className="des_info__order--left">Địa chỉ lấy hàng</div>
                        <div className="des_info__order--right">
                            {orderShipStore.dataOrderById?.establishment?.phone && <div className="mb-1">
                                {orderShipStore.dataOrderById?.establishment?.name} |{' '}
                                {orderShipStore.dataOrderById?.establishment?.phone}
                            </div>
                            }
                            <div>
                                {orderShipStore.dataOrderById?.pickupAddress?.detail ? `${orderShipStore.dataOrderById?.pickupAddress?.detail}, ` : ''}
                                {orderShipStore.dataOrderById?.pickupAddress?.ward?.name ? `${orderShipStore.dataOrderById?.pickupAddress?.ward?.name}, ` : ''}
                                {orderShipStore.dataOrderById?.pickupAddress?.province?.name ? `${orderShipStore.dataOrderById?.pickupAddress?.province?.name}, ` : ''}
                                {orderShipStore.dataOrderById?.pickupAddress?.district?.name ? `${orderShipStore.dataOrderById?.pickupAddress?.district?.name}` : ''}
                            </div>
                        </div>
                </div>
                <div className='des_info__order w-100 d-flex justify-content-start align-items-start mb-3'>
                    <div className="des_info__order--left">Địa chỉ giao hàng</div>
                    <div className="des_info__order--right">
                        {orderShipStore.dataOrderById?.receiver?.phone && <div className="mb-1">
                            {orderShipStore.dataOrderById?.receiver?.name} |{' '}
                            {orderShipStore.dataOrderById?.receiver?.phone}
                        </div>
                        }
                        <div>
                            {orderShipStore.dataOrderById?.receivingAddress?.detail ? `${orderShipStore.dataOrderById?.receivingAddress?.detail}, ` : ''}
                            {orderShipStore.dataOrderById?.receivingAddress?.ward?.name ? `${orderShipStore.dataOrderById?.receivingAddress?.ward?.name}, ` : ''}
                            {orderShipStore.dataOrderById?.receivingAddress?.district?.name ? `${orderShipStore.dataOrderById?.receivingAddress?.district?.name}, ` : ''}
                            {orderShipStore.dataOrderById?.receivingAddress?.province?.name ? `${orderShipStore.dataOrderById?.receivingAddress?.province?.name}` : ''}
                        </div>
                    </div>
                </div>
                <div className='des_info__order w-100 d-flex justify-content-start align-items-start mb-3'>
                    <div className="des_info__order--left">Thanh toán</div>
                    <div className="des_info__order--right">
                        {orderShipStore.dataOrderById?.order?.paymentStatus === "PAID" ? <span className='color-green'>
                            Đã thanh toán
                        </span> : <span className='color-red'>
                            Chưa thanh toán
                        </span>
                        }
                    </div>
                </div>
                <div className='des_info__order w-100 d-flex justify-content-start align-items-start mb-3'>
                    <div className="des_info__order--left">Ghi chú</div>
                    <div className="des_info__order--right">
                        {orderShipStore.dataOrderById?.note?.customer}
                    </div>
                </div>
                <div className='des_info__order w-100 d-flex flex-column mb-3'>
                    <div className='detail_order__customer d-flex justify-content-between align-items-center'>
                        <span className='detail_order__customer--name'>
                            {orderShipStore.dataOrderById?.customer?.name}
                        </span>
                        <button
                            onClick={() => {
                                if(orderShipStore.dataOrderById?.order?.id) {
                                    checkOrderStore.clearForm()
                                    checkOrderStore.idOrder = orderShipStore.dataOrderById?.order?.id
                                    checkOrderStore.getDetailOrder(orderShipStore.dataOrderById?.order?.id).then()
                                    checkOrderStore.dataCheckCodeVoucher = {}
                                    checkOrderStore.detailInfoUser = {}
                                    checkOrderStore.keyword = ""
                                }
                            }}
                            data-bs-toggle="modal"
                            title="Xem chi tiết"
                            data-bs-target='#view_detail_order'
                            className='detail_order__customer--btn'>
                            Chi tiết đơn
                        </button>
                    </div>
                    <div className='w-100 p-3'>
                        {
                            orderShipStore.dataOrderById?.order?.items.map((item: any, index: number) => {
                                return (
                                    <div key={index} className='w-100 order_item__container'>
                                        <div className='order_item__container--product'>
                                            <p>{item?.product?.name}</p>
                                            <span>{item?.product?.description}</span>
                                        </div>
                                        <div className='order_item__container--price'>
                                            <p>{numberUtil.number_format(item?.price)} đ</p>
                                        </div>
                                        <div className='order_item__container--qty'>
                                            <p>x{item?.quantity}</p>
                                        </div>
                                        {
                                            item?.quantity && <div className='order_item__container--total'>
                                            <p>{numberUtil?.number_format(item?.price * item?.quantity)}</p> <span>đ</span>
                                        </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        <div className='sumary__order'>
                            <div className='title_sumary__order'>
                                Tổng tiền hàng
                            </div>
                            <p>
                                {numberUtil.number_format(orderShipStore?.dataOrderById?.order?.totalAmount)} <span>đ</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='des_info__order w-100 mb-3'>
                    <div className='w-100 d-flex justify-content-end py-2'>
                        <div className='sumary__order'>
                            <div className='title_sumary__order'>
                                Giảm giá
                            </div>
                            <p>
                                - {numberUtil.number_format(orderShipStore?.dataOrderById?.order?.totalDiscountAmount)} <span>đ</span>
                            </p>
                        </div>
                    </div>
                    <div className='line-dashed'></div>
                    <div className='w-100 d-flex justify-content-end py-2'>
                        <div className='sumary__order'>
                            <div className='title_sumary__order'>
                                Phí vận chuyển
                            </div>
                            <p>
                                {numberUtil.number_format(orderShipStore?.dataOrderById?.shippingFee)} <span>đ</span>
                            </p>
                        </div>
                    </div>
                    <div className='line-dashed'></div>
                    <div className='w-100 d-flex justify-content-end py-2'>
                        <div className='sumary__order'>
                            <div className='title_sumary__order'>
                                Tổng thanh toán
                            </div>
                            <p className='sum_price'>
                                {numberUtil.number_format(orderShipStore?.dataOrderById?.order?.totalAmount - orderShipStore?.dataOrderById?.order?.totalDiscountAmount + orderShipStore?.dataOrderById?.shippingFee)} <span>đ</span>
                            </p>
                        </div>
                    </div>
                    <div className='line-dashed'></div>
                    <div className='w-100 d-flex justify-content-end py-2'>
                        <div className='sumary__order'>
                            <div className='title_sumary__order'>
                                Thu hộ
                            </div>
                            <p className='sum_price'>
                                {orderShipStore.dataOrderById?.order?.paymentStatus === "PAID" ? <div>
                                        0 <span>đ</span>
                                    </div> : <div>{numberUtil.number_format(orderShipStore?.dataOrderById?.order?.totalAmount - orderShipStore?.dataOrderById?.order?.totalDiscountAmount + orderShipStore?.dataOrderById?.shippingFee)} <span>đ</span></div>
                                }
                            </p>
                        </div>
                    </div>
                </div>
                <div className='btn_print w-100'>
                    <button
                        onClick={() => {
                            window.print();
                        }}
                        className='print_order_bill--btn'>
                        <img src="/assets/ico/statusOrder/printer.svg" alt=""/>
                        In phiếu giao hàng
                    </button>
                </div>
            </div> : <Loading />}
            <ViewDetailOrderModal />
        </div>
    )
}
export default observer(OrderShipBuyId)
