import React, {useEffect} from 'react';
import {kpiStore} from "./KpiStore";
import {observer} from "mobx-react";
import './KPIPage.scss'
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {numberUtil} from "../../../common/utils/NumberUtil";
import LoadingTable from "../../../modules/components/LoadingTable";
import {restaurantStore} from "../../setting/restaurants/RestaurantStore";

const KpiPage = () => {

    const returnStatus = (status: string) => {
        switch (status) {
            case 'ACTIVE':
                return <span style={{color: '#00AB56'}} className="fw-500">Thành công</span>
            case 'REJECT':
                return <span style={{color: '#FF424F'}} className="fw-500">Từ chối</span>
            case 'PENDING':
                return <span style={{color: '#7B61FF'}} className="fw-500">Chờ duyệt</span>
            case 'DRAFT':
                return <span style={{color: '#38383D'}} className="fw-500">Nháp</span>
        }
    }

    const handleSearchYear = async (date: any, dateString: any) => {
        kpiStore.searchYear = dateString || new Date().getFullYear().toString()
        await kpiStore.getKpi()
    };

    const callApi = async () => {
        kpiStore.isLoading = true
        await restaurantStore.getListRestaurant()
        await kpiStore.getKpi()
    }

    useEffect(() => {
        callApi().then()
    }, [])

    let {revenue, averageMonthlyRevenue, beverageRevenue, foodRevenue} = kpiStore.dataKpi

    let resultRevenue: any = ((revenue.result - revenue.target) / revenue.target) * 100
    let resultAverageMonthlyRevenue: any = ((averageMonthlyRevenue.result - averageMonthlyRevenue.target) / averageMonthlyRevenue.target) * 100
    let resultBeverageRevenue: any = ((beverageRevenue.result - beverageRevenue.target) / beverageRevenue.target) * 100
    let resultFoodRevenue: any = ((foodRevenue.result - foodRevenue.target) / foodRevenue.target) * 100

    return (
        <div className='kpi_page position-relative'>
            <div className='d-flex justify-content-between'>
                <TabRedirect tab='KPI'/>
                <div className="d-flex align-items-center">
                    <div className="d-flex form_search align-items-center">
                        <DatePicker
                            picker="year"
                            placeholder="Năm"
                            value={kpiStore.searchYear ? dayjs(kpiStore.searchYear, 'YYYY') as any : null}
                            onChange={handleSearchYear}
                            bordered={false}
                        />
                    </div>
                    <button style={{width: 80, fontSize: 14}} className="ms-3 btn btn_add"
                            onClick={() => kpiStore.typeModal = "edit"}>Sửa
                    </button>
                </div>
            </div>
            {kpiStore.isLoading ? <LoadingTable
            colSpan={7}/> : <div className="data_kpi mt-2">
                <div className="table-responsive">
                    <table className='table align-middle border mb-0 gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th style={{width: '30%'}}>Chỉ số đánh giá hiệu quả KPI</th>
                            <th style={{width: '10%'}} className="text-center">Mục tiêu</th>
                            <th style={{width: '10%'}} className="text-center">Tỉ lệ Tăng/Giảm</th>
                            <th style={{width: '10%'}} className="text-center">Kết quả</th>
                            <th style={{width: '15%'}} className="text-center">Gửi</th>
                            <th style={{width: '10%'}} className="text-center">Trạng thái</th>
                            <th style={{width: '15%'}} className="text-center">Nhận từ lãnh đạo</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div className="w-100"><strong>Doanh thu (*)</strong></div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Doanh thu tổng</div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.revenue.target ? numberUtil.number_format(kpiStore.dataKpi.revenue.target) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.revenue.target = numberUtil.regexNumber(e)}/> : numberUtil.number_format(kpiStore.dataKpi.revenue.target)}
                    </div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal !== "edit" && revenue.result && revenue.target &&
                        <span className={revenue.result < revenue.target ? 'text-danger' : 'color-green'}
                              style={{fontWeight: '500'}}>{parseFloat(resultRevenue.toFixed(1))}%</span>}
                    </div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.revenue.result ? numberUtil.number_format(kpiStore.dataKpi.revenue.result) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.revenue.result = numberUtil.regexNumber(e)}/> : numberUtil.number_format(kpiStore.dataKpi.revenue.result)}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">

                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Doanh thu trung bình tháng</div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.averageMonthlyRevenue.target ? numberUtil.number_format(kpiStore.dataKpi.averageMonthlyRevenue.target) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.averageMonthlyRevenue.target = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.averageMonthlyRevenue.target)}
                    </div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal !== "edit" && averageMonthlyRevenue.result && averageMonthlyRevenue.target &&
                        <span className={averageMonthlyRevenue.result < averageMonthlyRevenue.target ? 'text-danger' : 'color-green'}
                              style={{fontWeight: '500'}}>{parseFloat(resultAverageMonthlyRevenue.toFixed(1))}%</span>}
                    </div>
                    <div style={{width: '10%'}}
                         className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.averageMonthlyRevenue.result ? numberUtil.number_format(kpiStore.dataKpi.averageMonthlyRevenue.result) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.averageMonthlyRevenue.result = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.averageMonthlyRevenue.result)}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                {kpiStore.listRestaurant && kpiStore.listRestaurant.map((item: any, i: any) => {
                    let result: any = ((item.result - item.target) / item.target) * 100
                    return (
                        <div className="col_table d-flex align-items-center" key={i}>
                            <div style={{width: '30%'}}>Doanh thu {item.name}</div>
                            <div style={{width: '10%'}} className="text-center">
                                {kpiStore.typeModal === "edit" ?
                                    <input type="text" value={item.target ? numberUtil.number_format(item.target) : ''}
                                           className="text-center form-control"
                                           onChange={(e: any) => item.target = numberUtil.regexNumber(e)}/> :
                                    numberUtil.number_format(item.target)}</div>
                            <div style={{width: '10%'}} className="text-center">
                                {kpiStore.typeModal !== "edit" && item.result && item.target &&
                                <span className={item.result < item.target ? 'text-danger' : 'color-green'}
                                      style={{fontWeight: '500'}}>{parseFloat(result.toFixed(1))}%</span>}
                            </div>
                            <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                                <input type="text" value={item.result ? numberUtil.number_format(item.result) : ''}
                                       className="text-center form-control"
                                       onChange={(e: any) => item.result = numberUtil.regexNumber(e)}/> :
                                numberUtil.number_format(item.result)}</div>
                            <div style={{width: '15%'}} className="text-center">-</div>
                            <div style={{width: '10%'}} className="text-center">
                                {/*{returnStatus("ACTIVE")}*/}
                            </div>
                            <div style={{width: '15%'}} className="text-center">-</div>
                        </div>
                    )
                })}
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Doanh thu đồ ăn</div>
                    <div style={{width: '10%'}}
                         className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.foodRevenue.target ? numberUtil.number_format(kpiStore.dataKpi.foodRevenue.target) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.foodRevenue.target = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.foodRevenue.target)}
                    </div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal !== "edit" && foodRevenue.result && foodRevenue.target &&
                        <span className={foodRevenue.result < foodRevenue.target ? 'text-danger' : 'color-green'}
                              style={{fontWeight: '500'}}>{parseFloat(resultFoodRevenue.toFixed(1))}%</span>}
                    </div>
                    <div style={{width: '10%'}}
                         className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.foodRevenue.result ? numberUtil.number_format(kpiStore.dataKpi.foodRevenue.result) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.foodRevenue.result = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.foodRevenue.result)}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Doanh thu đồ uống</div>
                    <div style={{width: '10%'}}
                         className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.beverageRevenue.target ? numberUtil.number_format(kpiStore.dataKpi.beverageRevenue.target) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.beverageRevenue.target = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.beverageRevenue.target)}
                    </div>
                    <div style={{width: '10%'}} className="text-center">
                        {kpiStore.typeModal !== "edit" && beverageRevenue.result && beverageRevenue.target &&
                        <span className={beverageRevenue.result < beverageRevenue.target ? 'text-danger' : 'color-green'}
                              style={{fontWeight: '500'}}>{parseFloat(resultBeverageRevenue.toFixed(1))}%</span>}
                    </div>
                    <div style={{width: '10%'}}
                         className="text-center">
                        {kpiStore.typeModal === "edit" ?
                            <input type="text" value={kpiStore.dataKpi.beverageRevenue.result ? numberUtil.number_format(kpiStore.dataKpi.beverageRevenue.result) : ''}
                                   className="text-center form-control"
                                   onChange={(e: any) => kpiStore.dataKpi.beverageRevenue.result = numberUtil.regexNumber(e)}/> :
                            numberUtil.number_format(kpiStore.dataKpi.beverageRevenue.result)}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div className="w-100"><strong>Tài chính (*)</strong></div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Tổng chi phí Marketing</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Tỉ lệ % Chi phí/Doanh thu (*)</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                {kpiStore.listMarRestaurant && kpiStore.listMarRestaurant.map((item: any, i: any) => {
                    let result: any = ((item.result - item.target) / item.target) * 100
                    return (
                        <div className="col_table d-flex align-items-center" key={i}>
                            <div style={{width: '30%'}}>Chi phí Marketing cho {item.name}</div>
                            <div style={{width: '10%'}} className="text-center">
                                {kpiStore.typeModal === "edit" ?
                                    <input type="text" value={item.target ? numberUtil.number_format(item.target) : ''}
                                           className="text-center form-control"
                                           onChange={(e: any) => item.target = numberUtil.regexNumber(e)}/> :
                                    numberUtil.number_format(item.target)}</div>
                            <div style={{width: '10%'}} className="text-center">
                                {kpiStore.typeModal !== "edit" && item.result && item.target &&
                                <span className={item.result < item.target ? 'text-danger' : 'color-green'}
                                      style={{fontWeight: '500'}}>{parseFloat(result.toFixed(1))}%</span>}
                            </div>
                            <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                                <input type="text" value={item.result ? numberUtil.number_format(item.result) : ''}
                                       className="text-center form-control"
                                       onChange={(e: any) => item.result = numberUtil.regexNumber(e)}/> :
                                numberUtil.number_format(item.result)}</div>
                            <div style={{width: '15%'}} className="text-center">-</div>
                            <div style={{width: '10%'}} className="text-center">
                                {/*{returnStatus("ACTIVE")}*/}
                            </div>
                            <div style={{width: '15%'}} className="text-center">-</div>
                        </div>
                    )
                })}
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Chi phí thương hiệu</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Chi phí tổ chức sự kiện</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Chi phí chạy quảng cáo</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="col_table d-flex align-items-center">
                    <div style={{width: '30%'}}>Chi phí chiết khấu Mar-Sale</div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '10%'}} className="text-center"></div>
                    <div style={{width: '10%'}} className="text-center">{kpiStore.typeModal === "edit" ?
                        <input type="text" value=""
                               className="text-center form-control"
                               onChange={(e: any) => {}}/> :
                        numberUtil.number_format(0)}</div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                    <div style={{width: '10%'}} className="text-center">
                        {/*{returnStatus("ACTIVE")}*/}
                    </div>
                    <div style={{width: '15%'}} className="text-center">-</div>
                </div>
                <div className="table-responsive">
                    <table className='table align-middle border gy-7 gs-7'>
                        <tbody>
                        {kpiStore.listKpi && kpiStore.listKpi.map((item, i) => {
                            let result: any = ((item.result - item.target) / item.target) * 100
                            if (item.type) {
                                return <tr key={i}>
                                    <td width="30%" colSpan={7}><strong>{kpiStore.returnKey(item.type)}</strong></td>
                                </tr>
                            } else return (
                                <tr key={i}>
                                    <td width="30%">{kpiStore.returnKey(item.key)}</td>
                                    <td width="10%" className="text-center">
                                        {kpiStore.typeModal === "edit" ?
                                            <input type="text" value={item.target ? numberUtil.number_format(item.target) : ''} className="text-center form-control"
                                                   onChange={(e: any) => item.target = numberUtil.regexNumber(e)}/> : numberUtil.number_format(item.target)}</td>
                                    <td width="10%" className="text-center">
                                        {kpiStore.typeModal !== "edit" && item.result && item.target &&
                                        <span className={item.result < item.target ? 'text-danger' : 'color-green'}
                                              style={{fontWeight: '500'}}>{parseFloat(result.toFixed(1))}%</span>}
                                    </td>
                                    <td width="10%" className="text-center">{
                                        kpiStore.typeModal === "edit" ?
                                            <input type="text" className="text-center form-control" value={item.result ? numberUtil.number_format(item.result) : ''}
                                                   onChange={(e: any) => item.result = numberUtil.regexNumber(e)}/> : numberUtil.number_format(item.result)}</td>
                                    <td width="15%" className="text-center">-</td>
                                    <td width="10%" className="text-center">
                                        {/*{returnStatus("ACTIVE")}*/}
                                    </td>
                                    <td width="15%" className="text-center">-</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>}

            {kpiStore.typeModal == "edit" &&
            <div className="d-flex action_edit w-100 justify-content-end">
                <button onClick={async () => {
                    kpiStore.typeModal = ""
                    await kpiStore.getKpi()
                }} className='button_cancel__formtable'>Hủy</button>
                <button className='button_save__formtable' disabled={kpiStore.isLoadingBt}
                        onClick={() => kpiStore.editKpi()}>Lưu
                </button>
            </div>}
        </div>
    );

}

export default observer(KpiPage)