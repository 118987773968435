import React, {useEffect, useState} from 'react';
import './MenuAction.scss'
interface IProps {
    children?: JSX.Element
    isRight?: any
}

const MenuAction = ({children, isRight = true}: IProps) => {
    const [activeMenu, setActiveMenu] = useState(false)
    const ref = React.useRef<any>(null)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
            setActiveMenu(false)
        }
    }
    return (
        <div className='d-flex justify-content-center'>
            <div ref={ref} className='menu_action__container'>
                <button
                    onClick={() => {setActiveMenu(!activeMenu)}}
                    className='btn_toggle__action'>
                    Hành động
                    <img src="/assets/ico/action/icon-park-solid_down-one.svg" alt=""/>
                </button>
                <div style={isRight ? {right : 0} : {left: 0}} className={`menu-action_dropdown ${activeMenu ? "active" : ""}`}>
                    <div
                        onClick={() => {
                            setActiveMenu(!activeMenu)
                        }}
                        className="dropdown-action__item">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MenuAction;
