import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import {sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../modules/components/NoContent";
import {staffSettingStore} from "./StaffStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import AddEditStaff from "./components/AddEditStaff";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";


const StaffSettingPage = () => {

    const dataFilter: IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm tên nhân viên...',
            value: '',
            onChange: (e: any) => {
                staffSettingStore.keyword = e
            },
            callback: async () => {
                staffSettingStore.getStaff().then()
            }
        },
    ]

    useEffect(() => {
        staffSettingStore.getStaff().then()
    }, [])

    const wageType = (type: string) => {
        switch (type) {
            case "WAGE_PER_DAY":
                return "Lương theo ngày";
            case "WAGE_PER_HOUR":
                return "Lương theo giờ";
            case "WAGE_PER_WEEK":
                return "Lương theo tuần";
            case "WAGE_PER_SHIFT":
                return "Lương theo ca";
        }
    }


    return (
        <div className='staff_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Nhân viên</h4>
                <FilterComponent permissionCode={true} data={dataFilter} buttonAdd={<ButtonGlobal onClick={() => {
                    staffSettingStore.clearForm()
                }} dataTarget='add_edit_staff' text='Thêm' ico='add_icon' className='btn_add'/>}/>
            </div>
            {staffSettingStore.isLoading ? <Loading/> :
                <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-center">STT</th>
                            <th scope="col" className="text-center">Tên nhân viên</th>
                            <th scope="col" className="text-center">Mã nhân viên</th>
                            <th scope="col" className="text-center">Hình thức<br/> làm việc</th>
                            <th scope="col" className="text-center">Lương</th>
                            <th scope="col" className="text-center">Loại lương</th>
                            <th scope="col" className="text-center">Thưởng</th>
                            <th scope="col" className="text-center">Trợ cấp</th>
                            <th scope="col" className="text-center">BHXH</th>
                            <th scope="col" className="text-center">Phí công đoàn</th>
                            <th scope="col" className="text-center">Chi phí khác</th>
                            <th scope="col" className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {staffSettingStore.listStaff.length < 1 ?
                            <NoContent colSpan={12}/> : staffSettingStore.listStaff.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(staffSettingStore.page, i)}</td>
                                        <td className="text-center">{item.name}</td>
                                        <td className="text-center">{item.code}</td>
                                        <td className="text-center">{item.jobType === "FULL_TIME" ? 'FullTime' : 'PartTime'}</td>
                                        <td className="text-center">{numberUtil.number_format(item.wage)} đ</td>
                                        <td className="text-center">{wageType(item.wageType)}</td>
                                        <td className="text-center">{numberUtil.number_format(item.bonus)} đ</td>
                                        <td className="text-center">{numberUtil.number_format(item.allowance)} đ</td>
                                        <td className="text-center">{numberUtil.number_format(item.socialInsurance)} đ</td>
                                        <td className="text-center">{numberUtil.number_format(item.unionDues)} đ</td>
                                        <td className="text-center">{numberUtil.number_format(item.otherCosts)} đ</td>
                                        <td className="text-center">
                                            <button className="btn p-0 mr-1" data-bs-toggle="modal" data-bs-target={"#add_edit_staff"}
                                                    onClick={async () => await staffSettingStore.getDetail(item.id)}>
                                                <img src="/assets/ico/action/edit_icon.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Chỉnh sửa"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </div>}
            <AddEditStaff/>
        </div>
    )
}

export default observer(StaffSettingPage);