import React, {useState} from 'react';
import {observer} from "mobx-react";
import './ModalDetailRequestForWork.scss'
import CustomTable from "../../../../../modules/components/tableGlobal/CustomTable";
import {costStore} from "../../../../finance/costPage/CostStore";
import {businessPlanStore} from "../../BusinessPlanStore";
import ButtonGlobal from "../../../../../modules/components/buttonGlobal/ButtonGlobal";
import {Input, Tooltip} from "antd";
import {dateUtils} from "../../../../../common/utils/DateUtils";
const ModalDetailRequestForWork= () => {
    const [nameRequest, setNameRequest] = useState("")
    const columns: any = [
        {
            title: "STT",
            align: "center",
            width: 80,
            render: (_: any, __ : any, index: any) => index + 1,
        },
        {
            title: "Thời gian tạo",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 150,
            render: (value: any): JSX.Element => <span>{dateUtils.formatDate(value)}</span>
        },
        {
            title: "Nội dung yêu cầu",
            dataIndex: "content",
            key: "content",
            align: "left",
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{value}</span>
                }
                else return <div>
                    <Input type='text' value={nameRequest}
                           onChange={(e: any) => {
                               setNameRequest(e.target.value)
                           }}
                           placeholder='Nhập ...' />
                </div>
            }
        },
        {
            title: "Thao tác",
            width: 100,
            align: "center",
            render: (_: any, record: any, index: number): JSX.Element => {
                if (!record.isEdit) {
                    return (
                        <div>
                                <button className="btn p-0"
                                        onClick={async () => {
                                            businessPlanStore.id = record.id
                                            businessPlanStore.keyRequirement = record.key
                                            businessPlanStore.typeModal = 'edit'
                                            setNameRequest(record.content)
                                            handleAddRequest()
                                        }}>
                                    <Tooltip title='Chỉnh sửa yêu cầu'>
                                        <img src="/assets/ico/action/edit_icon.svg"
                                             className="icon-action"
                                             alt=""/>
                                    </Tooltip>
                                </button>
                                <button className="btn p-0"
                                        onClick={async () => {
                                            businessPlanStore.typeModal = 'delete'
                                            businessPlanStore.dataDetailRequestForWork.splice(index, 1)
                                            await businessPlanStore.addRequirement()
                                        }}
                                >
                                    <Tooltip title='Xóa yêu cầu'>
                                        <img src="/assets/ico/action/delete_icon.svg"
                                             className="icon-action"
                                             alt=""/>
                                    </Tooltip>
                                </button>
                        </div>
                    );
                } else
                {
                    return <div className='d-flex justify-content-center align-items-center'>
                        <button onClick={() => {
                            const dataSplice = businessPlanStore.dataDetailRequestForWork.slice(); //Tạo bản ghi sâu
                            dataSplice.splice(index, 1)
                            businessPlanStore.dataDetailRequestForWork = dataSplice
                        }} className='btn-edit__row--cancel mx-1'>
                            Hủy
                        </button>
                        <button onClick={async () => {
                            businessPlanStore.dataRequestAddRequestForWork.content = nameRequest
                            await businessPlanStore.addRequirement()
                        }} className='btn-edit__row--save mx-1'>
                            Lưu
                        </button>
                    </div>
                }
            },
        },
    ];
    const handleAddRequest = () => {
        if(businessPlanStore.typeModal === 'add') {
            setNameRequest("")
        }
        if(!businessPlanStore.dataDetailRequestForWork.some((item: any) => item.isEdit)) {
            const newData = [...businessPlanStore.dataDetailRequestForWork];
            newData.push({
                content: "",
                createdAt: "",
                isEdit: true
            })
            businessPlanStore.dataDetailRequestForWork = newData
        }
    }
    return (
        <div className='modal fade' id='detail_request_for_work' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='detail_request_for_work__title'>
                            CHI TIẾT YÊU CẦU CÔNG VIỆC
                        </div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='px-4 mt-4 d-flex justify-content-end align-items-center'>
                        <ButtonGlobal onClick={() => {
                            businessPlanStore.typeModal = 'add'
                            handleAddRequest()
                        }} text='Thêm' ico='add_icon' className='btn_add'/>
                    </div>
                    <div className="modal-body px-4">
                        <CustomTable
                            className='mt-0'
                            subtractHeight={400}
                            columns={columns}
                            isLoading={costStore.isLoading}
                            isRowSelection={false}
                            data={businessPlanStore.dataDetailRequestForWork}
                            scrollX={500}
                        />
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalDetailRequestForWork);

