import React, {useEffect} from 'react';
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {warehouseStore} from "./WarehouseStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import {sttPage} from "../../../common/utils/Utils";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import AddEditWarehouse from "./components/AddEditWarehouse";
import { observer } from 'mobx-react';


const WarehousePage = () => {

    useEffect(() => {
        warehouseStore.getWarehouse().then()
        warehouseStore.getEstablishment().then()
        warehouseStore.getUser().then()
    }, [])


    const dataFilter: IFilter[] = [
        {
            label: 'Loại',
            type: 'select',
            value: warehouseStore.type,
            placeholder: 'Tất cả',
            listValue: [
                {label: "Kho tổng", value: "MAIN"},
                {label: "Chi nhánh", value: "BRANCH"},
            ],
            callback: async (e: any) => {
                warehouseStore.type = e
                await warehouseStore.getWarehouse()
            }
        },
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo tên kho...',
            value: warehouseStore.keyword,
            onChange: (e: any) => warehouseStore.keyword = e,
            callback: async () => await warehouseStore.getWarehouse()
        },
    ]


    return (
        <div className='warehouse_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Danh sách kho</h4>
                <FilterComponent permissionCode={true}
                                 data={dataFilter} buttonAdd={<ButtonGlobal onClick={() => {warehouseStore.clearForm()}} dataTarget='add_edit_warehouse' text='Thêm' ico='add_icon' className='btn_add'/>}
                />
            </div>
            {warehouseStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Thời gian tạo</th>
                        <th className="text-center">Tên kho</th>
                        <th className="text-center">Loại</th>
                        <th className="text-center">Địa chỉ</th>
                        <th className="text-center">Số điện thoại</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {warehouseStore.listWarehouse && warehouseStore.listWarehouse.length > 0 ? warehouseStore.listWarehouse.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(warehouseStore.page, i)}</td>
                                <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item.type == "BRANCH" ? 'Chi nhánh' : 'Kho tổng'}</td>
                                <td className="text-center">{item.address?.detail}</td>
                                <td className="text-center">{item.phone}</td>
                                <td className="text-center">
                                    <button className="btn p-0 mr-1"
                                            data-bs-toggle="modal" data-bs-target={"#add_edit_warehouse"}
                                            onClick={async () => await warehouseStore.getDetail(item.id)}>
                                        <img src="/assets/ico/action/edit_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Chỉnh sửa"/>
                                    </button>

                                    <button className="btn p-0" data-bs-toggle="modal"
                                            data-bs-target="#popup_confirm"
                                            onClick={() => warehouseStore.id = item.id}>
                                        <img src="/assets/ico/action/delete_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Xóa"/>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) : <NoContent colSpan={7}/>}
                    </tbody>
                </table>
            </div>}

            <AddEditWarehouse/>
            <PopupConfirm action={warehouseStore.delete} isLoading={warehouseStore.isLoadingBt}/>
        </div>
    );
}

export default observer(WarehousePage);