import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {marketResearchStore} from "./MarketResearchStore";


class MarketResearchService {
    public getListMarketResearch(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/market_research?type=RESEARCH${marketResearchStore.searchStatus ? `&status=${marketResearchStore.searchStatus}` : ''}`);
    }
    public getDetailMarketResearch(id: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/market_research/${id}`);
    }
    public addMarketResearch(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/market_research`, data);
    }
    public editMarketResearch(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/market_research/${id}`, data)
    }
    public deleteMarketResearch(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/market_research/${id}`, {})
    }
}

export const marketResearchService = new MarketResearchService();