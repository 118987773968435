import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {orderShipService} from "./OrderShipService";
import {toastUtil} from "../../../common/utils/ToastUtil";

class OrderShipStore {
    id: any = ''
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listOrderShip: any[] = []
    dataOrderById: any = {}
    page: any = 0
    idOrder: any = ''
    searchKeywordOrder: any = ''
    searchStatusOrder: any = null
    async getOrderShip() {
        this.isLoading = true
        const result = await orderShipService.getOrderShip()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listOrderShip = result.body.data
        }
    }
    async getDetailOrder(id: any) {
        this.isLoading = true
        const result = await orderShipService.detailOrderById(id)
        if (result.status === HttpStatusCode.OK) {
            this.isLoading = false
            this.dataOrderById = result.body
        }
        else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async updateOrder(id: any, state: any) {
        let data = {
            state: state
        }
        this.isLoadingBt = true
        const result = await orderShipService.changeStatusOrder(id, data)
        if (result.status === 200) {
            this.isLoadingBt = false
            toastUtil.success('Cập nhật trạng thái đơn hàng thành công !', 1)
        }
        else {
            toastUtil.error(result.body.message, 2)
        }
    }
    constructor() {
        makeAutoObservable(this)
    }

}

export const orderShipStore = new OrderShipStore()