import {deleteRequest, getRequest, postRequest, IApiResponse, putRequest} from "../../../common/helpers/RequestHelper";

class CustomerBenefitService {

    public getBenefit(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/ranks`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/ranks/${id}`)
    }

    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/ranks`, data)
    }

    public edit(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/ranks/${id}`, data)
    }

    public delete(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/ranks/${id}`, {id: id})
    }

}

export const customerBenefitService = new CustomerBenefitService()