import moment from "moment";
import {addDays, format, subDays} from "date-fns";


class DateUtils {

    public formatTSDateTimeAtTZ(timeSecond: number, localeFormat: string = "en-US") {
        return new Date(timeSecond * 1000).toLocaleString(localeFormat);
    }


    public formatISODateTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if (lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleString(localeFormat);
    }


    public formatISODateAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if (lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleDateString(localeFormat);
    }


    public formatISOTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if (lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleTimeString(localeFormat);
    }

    public formatDate(timeSecond: number, format?: any) {
        return timeSecond ? moment(timeSecond).format(format ?? 'DD/MM/YYYY') : '-';
    }
    public formatDateInUTC(timeSecond: number, format?: any) {
        if (timeSecond) {
            // Chuyển đổi thời gian thành múi giờ UTC
            const utcTime = moment.utc(timeSecond);
            return utcTime.format(format ?? 'DD/MM/YYYY');
        } else {
            return '-';
        }
    }

    public getLocalDateTime(_date: number | string, format: "dd_mm_yyyy" | "dd/mm/yyyy" | "dd-mm-yyyy" | "dd-mm-yyyy, hh:m_m:ss" | "dd/mm/yyyy, hh:m_m" | "yyyy-mm-dd hh:m_m:ss" | "yyyy-mm-dd" | "yyyy/mm/dd") {
        const date = new Date(_date);
        const D = date.getDate();
        const hh = date.getHours();
        const m_m = date.getMinutes();
        const ss = date.getSeconds();

        const M = date.getMonth() + 1;
        const dd = D < 10 ? "0" + D : D;
        const mm = M < 10 ? "0" + M : M;
        const min = m_m === 0 ? "00" : m_m;
        const yyyy = date.getFullYear();

        let result: string = format;
        result = result.replace("D", D.toString());
        result = result.replace("M", M.toString());
        result = result.replace("dd", dd.toString());
        result = result.replace("mm", mm.toString());
        result = result.replace("yyyy", yyyy.toString());
        result = result.replace("hh", hh.toString());
        result = result.replace("m_m", min.toString());
        result = result.replace("ss", ss.toString());

        return _date ? result : ''
    }


}

export const dateUtils = new DateUtils();