import {makeAutoObservable} from "mobx";
import $ from 'jquery'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {productService} from "./ProductService";


class ProductStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    searchType: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listProduct: any[] = []
    listSupplies: any[] = []
    isFetching = false; // Thêm biến kiểm tra
    hasMore: boolean = true
    isErrorsQtySupplies : boolean = false
    supplyItem: any = {
        id: null,
        name: "",
        qty: "",
        unit: ""
    }
    typeSupplies : any = null
    dataRequest: any = {
        name: "",
        description: null,
        type: null,
        unit: "",
        batch: "",
        price: "",
        cogs: "",
        coefficient: "",
        images: [],
        supplies: []
    }
    errors: any = {
        name: "",
        description: "",
        type: "",
        unit: "",
        batch: "",
        price: "",
        cogs: "",
        coefficient: "",
        images: ""
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            name: "",
            description: null,
            type: null,
            unit: "",
            batch: "",
            price: "",
            cogs: "",
            coefficient: "",
            images: [],
            supplies: []
        }
        this.errors = {
            name: "",
            description: "",
            type: "",
            unit: "",
            batch: "",
            price: "",
            cogs: "",
            coefficient: "",
            images: ""
        }
        productStore.typeSupplies = null
        productStore.isErrorsQtySupplies = false
    }
    async getListProduct(size?: any) {
        if (productStore.isFetching) {
            return;
        }
        productStore.isLoading = true
        productStore.isFetching = true;
        try {
            const result = await productService.getListProduct(size)
            if (result.status === HttpStatusCode.OK) {
                if (result.body.data.length === 0) {
                    productStore.hasMore = false;
                } else {
                    productStore.listProduct = [...productStore.listProduct, ...result.body.data];
                    if(productStore.listProduct.length < 15) {
                        productStore.hasMore = false;
                    }
                    productStore.page = productStore.page + 1;
                }
            }
        } catch (error) {
            toastUtil.error(error as any)
        } finally {
            productStore.isLoading = false;
            productStore.isFetching = false;
        }
    }
    async getDetailProduct(id: number) {
        this.typeModal = 'edit'
        const result = await productService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = {
                ...result.body,
                supplies : result.body?.supplies.map((item: any) => {
                    return {
                        id: item?.supplies?.id,
                        name: item?.supplies?.name,
                        qty: item?.qty,
                        unit: item?.supplies?.unit
                    }
                })
            }
        }
    }

    async addEditProduct() {
        if(!this.dataRequest.name) {
            productStore.errors.name = "Tên sản phẩm không được để trống"
            return false
        }
        if(!this.dataRequest.batch) {
            productStore.errors.batch = "Mã sản phẩm không được để trống"
            return false
        }
        if(!this.dataRequest.unit) {
            productStore.errors.unit = "Đơn vị sản phẩm không được để trống"
            return false
        }
        if(!this.dataRequest.type) {
            productStore.errors.type = "Vui lòng chọn loại sản phẩm"
            return false
        }
        if(!this.dataRequest.cogs) {
            productStore.errors.cogs = "Chi phí sản xuất không được để trống"
            return false
        }
        if(!this.dataRequest.coefficient) {
            productStore.errors.coefficient = "Hệ số sản phẩm không được để trống"
            return false
        }
        if(!this.dataRequest.price) {
            productStore.errors.price = "Giá bán không được để trống"
            return false
        }
        const isIdInArraySupplies = productStore.dataRequest.supplies.some(
            (supply: { qty: any; }) => !supply.qty
        );
        if(isIdInArraySupplies) {
            this.isErrorsQtySupplies = true
            toastUtil.warning('Vui lòng nhập đầy đủ số lượng vật tư sử dụng', 2)
            return false
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await productService.addProduct(this.dataRequest) : await productService.editProduct(productStore.id, this.dataRequest)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#add_edit_product').trigger('click')
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} sản phẩm thành công!`)
            productStore.page = 0
            productStore.listProduct = []
            await productStore.getListProduct()
        } else toastUtil.error(result.body.message)
    }

    async deleteProduct() {
        productStore.isLoadingBt = true
        const result = await productService.deleteProduct(productStore.id)
        productStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa sản phẩm thành công!')
            productStore.page = 0
            productStore.listProduct = []
            await productStore.getListProduct()
        } else toastUtil.error(result.body.message)
    }
    async getListSupplies() {
        this.isLoading = true
        const result = await productService.getListSupplies()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listSupplies = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                    unit: item?.unit
                }
            })
        }
    }
}

export const productStore = new ProductStore()