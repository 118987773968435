import { observer } from 'mobx-react'
import React, {useEffect} from 'react'
import {ITabTypeProduct, orderStore} from "../../../../OrderStore";
import './TabHeaderProductPick.scss'

const TabHeaderProduct = () => {
    useEffect(() => {
        orderStore.getListProduct().then()
    }, [orderStore.tabActiveProduct])
    return (
        <div id='tab_header__product'>
            <div
                className={`tab_header w-100 d-flex text-uppercase`}
            >
                <button
                    className={`tab ${
                        orderStore.tabActiveProduct === ITabTypeProduct.ALL && 'active'
                    }`}
                    onClick={() => {
                        orderStore.tabActiveProduct = 'ALL'
                    }}
                >
                    <div className='text_tab'>Tất cả</div>
                </button>
                <button
                    className={`tab ${
                        orderStore.tabActiveProduct === ITabTypeProduct.APPETIZER && 'active'
                    }`}
                    onClick={() => {
                        orderStore.tabActiveProduct = 'APPETIZER'
                    }}
                >
                    <div className='text_tab'>Món khai vị</div>
                </button>
                <button
                    className={`tab ${
                        orderStore.tabActiveProduct === ITabTypeProduct.MAIN_COURSE && 'active'
                    }`}
                    onClick={() => {
                        orderStore.tabActiveProduct = 'MAIN_COURSE'
                    }}
                >
                    <div className='text_tab'>Món chính</div>
                </button>
                <button
                    className={`tab ${
                        orderStore.tabActiveProduct === ITabTypeProduct.DESSERT && 'active'
                    }`}
                    onClick={() => {
                        orderStore.tabActiveProduct = 'DESSERT'
                    }}
                >
                    <div className='text_tab'>Món tráng miệng</div>
                </button>
                <button
                    className={`tab ${
                        orderStore.tabActiveProduct === ITabTypeProduct.BEVERAGE && 'active'
                    }`}
                    onClick={() => {
                        orderStore.tabActiveProduct = 'BEVERAGE'
                    }}
                >
                    <div className='text_tab'>Đồ uống</div>
                </button>
            </div>
        </div>
    )
}

export default observer(TabHeaderProduct)
