import React, {useEffect} from 'react';
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {observer} from "mobx-react";
import {stipulationStore} from "./StipulationsStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {Input, Spin} from "antd";

const StipulationsPage = () => {
    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: stipulationStore.keyword,
            onChange: (e: any) => stipulationStore.keyword = e,
            callback: async () => await stipulationStore.getInventoryReport()
        },
    ]
    useEffect(() => {
         stipulationStore.getInventoryReport().then()
    }, [])
    return (
        <div className='stipulations_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Quy định định mức</h4>
                <FilterComponent permissionCode={true} data={dataFilter}/>
            </div>
            {stipulationStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th  className="text-center">STT</th>
                        <th  className="text-center">Tên vật tư</th>
                        <th  className="text-center">Mã vật tư</th>
                        <th  className="text-center">Số lượng</th>
                        <th  className="text-center">ĐVT</th>
                        <th  className="text-center">Tối thiểu</th>
                        <th  className="text-center">Tối đa</th>
                        <th scope="col" style={{width: '150px'}} className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stipulationStore.listStipulation && stipulationStore.listStipulation.length > 0 ? stipulationStore.listStipulation.map((item, i) => {
                        if(!item.isEdit)
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-start">{item?.supplies?.name}</td>
                                <td className="text-center">{item?.supplies?.code}</td>
                                <td className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                <td className="text-center">{item?.supplies?.unit}</td>
                                <td style={{width: '250px'}} className="text-center">{numberUtil.number_format(item?.minQty)}</td>
                                <td style={{width: '250px'}} className="text-center">{numberUtil.number_format(item?.maxQty)}</td>
                                <td className="text-center">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button onClick={async () => {
                                            item.isEdit = true
                                            stipulationStore.id = item.id
                                            stipulationStore.listStipulation.filter((i: any) => i.id !== item.id).forEach((itemList: any) => {
                                                itemList.isEdit = false
                                            })
                                            stipulationStore.dataRequest = {
                                                suppliesId: item?.supplies?.id,
                                                maxQty: item?.maxQty,
                                                minQty: item?.minQty,
                                            }
                                        }} className='btn-edit__row mx-1'>
                                            Sửa
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                        else
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-start">{item?.supplies?.name}</td>
                                    <td className="text-center">{item?.supplies?.code}</td>
                                    <td className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                    <td className="text-center">{item?.supplies?.unit}</td>
                                    <td style={{width: '250px'}} className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    stipulationStore.dataRequest.minQty =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (stipulationStore.dataRequest.minQty ? numberUtil.number_format(
                                                    stipulationStore.dataRequest.minQty
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td style={{width: '250px'}} className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    stipulationStore.dataRequest.maxQty =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (stipulationStore.dataRequest.maxQty ? numberUtil.number_format(
                                                    stipulationStore.dataRequest.maxQty
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td style={{width: '80px'}} className="text-center">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button onClick={() => {
                                                item.isEdit = false
                                            }} className='btn-edit__row--cancel mx-1'>
                                                Hủy
                                            </button>
                                            <button onClick={async () => {
                                                stipulationStore.id = item.id
                                                await stipulationStore.updateInventory()
                                            }} className='btn-edit__row--save mx-1'>
                                                {stipulationStore.isLoadingBt ? <Spin size="small" /> : 'Lưu'}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                    }) : <NoContent colSpan={8}/>}
                    </tbody>
                </table>

            </div>}
        </div>
    );
}

export default observer(StipulationsPage);