import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './AddEditRestaurant.scss'
import {Select, Input, Spin} from 'antd';
import {restaurantStore} from "../../RestaurantStore";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
const ModalAddEditRestaurant = () => {
    useEffect(() => {
        if(restaurantStore.dataRequest.provinceId && restaurantStore.typeModal === 'edit') {
            restaurantStore.getDistricts(restaurantStore.dataRequest.provinceId).then(() => {
                if(restaurantStore.dataRequest.districtId) {
                    restaurantStore.getWards(restaurantStore.dataRequest.districtId).then()
                }
            })
        }
    }, [restaurantStore.dataRequest.provinceId, restaurantStore.dataRequest.districtId])
    return (
        <div className='modal fade' id='add_edit_restaurant' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>{restaurantStore.typeModal === 'add' ? 'THÊM ĐIỂM BÁN' : 'CẬP NHẬT THÔNG TIN ĐIỂM BÁN'}</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên điểm bán<span className='text-danger'>*</span></label>
                                <Input type='text' value={restaurantStore.dataRequest?.name}
                                       status={(restaurantStore.errors?.name && !restaurantStore.dataRequest?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           restaurantStore.dataRequest.name = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {restaurantStore.errors?.name && !restaurantStore.dataRequest.name && <small className="text-error">{restaurantStore.errors?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số nhân viên<span className='text-danger'>*</span>
                                </label>
                                <Input type='text' value={restaurantStore.dataRequest?.countStaff ? numberUtil.number_format(restaurantStore.dataRequest?.countStaff) : ""}
                                       onKeyDown={(evt: {
                                           key: string
                                           preventDefault: () => any
                                       }) =>
                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                           evt.preventDefault()
                                       }
                                       status={(restaurantStore.errors?.countStaff && !restaurantStore.dataRequest?.countStaff) ? "error" : ""}
                                       onChange={(e: any) => {
                                           if (
                                               e === '' ||
                                               Number(numberUtil.regexNumber(e)) >= 0
                                           ) {
                                               restaurantStore.dataRequest.countStaff = numberUtil.regexNumber(e)
                                           }
                                       }}
                                       placeholder='Nhập số ...' />
                                {restaurantStore.errors?.countStaff && !restaurantStore.dataRequest.countStaff && <small className="text-error">{restaurantStore.errors?.countStaff}</small>}

                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Mã điểm bán<span className='text-danger'>*</span></label>
                                <Input type='text'  value={restaurantStore.dataRequest?.code}
                                       status={(restaurantStore.errors?.code && !restaurantStore.dataRequest?.code) ? "error" : ""}
                                       onChange={(e: any) => {
                                           restaurantStore.dataRequest.code = e.target.value
                                       }}
                                       placeholder='HUAS | Nhập vào ...' />
                                {restaurantStore.errors?.code  && !restaurantStore.dataRequest.code && <small className="text-error">{restaurantStore.errors?.code}</small>}

                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                <Input type='number'  value={restaurantStore.dataRequest?.phone}
                                       status={(restaurantStore.errors?.phone && !restaurantStore.dataRequest?.phone) ? "error" : ""}
                                       onChange={(e: any) => {
                                           restaurantStore.dataRequest.phone = e.target.value
                                       }}
                                       placeholder='Nhập số ...' />
                                {restaurantStore.errors?.phone  && !restaurantStore.dataRequest.phone && <small className="text-error">{restaurantStore.errors?.phone}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Email<span className='text-danger'>*</span></label>
                                <Input type='text'  value={restaurantStore.dataRequest?.email}
                                       status={(restaurantStore.errors?.email && !restaurantStore.dataRequest?.email) ? "error" : ""}
                                       onChange={(e: any) => {
                                           restaurantStore.dataRequest.email = e.target.value
                                       }}
                                       placeholder='Nhập vào ...' />
                                {restaurantStore.errors?.email  && !restaurantStore.dataRequest.email && <small className="text-error">{restaurantStore.errors?.email}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Tình trạng<span className='text-danger'>*</span></label>
                                <Select
                                    value={restaurantStore.dataRequest.status}
                                    status={(restaurantStore.errors?.status && !restaurantStore.dataRequest?.status) ? "error" : ""}
                                    onChange={(e: any) => {
                                        restaurantStore.dataRequest.status = e
                                    }}
                                    placeholder="Chọn" options={[{value: 'ACTIVE', label: 'Hoạt động'}, {value: 'INACTIVE', label: 'Chưa hoạt động'}]}
                                />
                                {restaurantStore.errors?.status  && !restaurantStore.dataRequest.status && <small className="text-error">{restaurantStore.errors?.status}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tỉnh/ Thành phố<span className='text-danger'>*</span></label>
                                <Select
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    value={restaurantStore.dataRequest.provinceId}
                                    status={(restaurantStore.errors?.provinceId && !restaurantStore.dataRequest?.provinceId) ? "error" : ""}
                                    onChange={async (e: any) => {
                                        await restaurantStore.changeProvince(e)
                                    }}
                                    placeholder="Chọn" options={restaurantStore.listProvince}
                                />
                                {restaurantStore.errors?.provinceId  && !restaurantStore.dataRequest.provinceId && <small className="text-error">{restaurantStore.errors?.provinceId}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Quận/ Huyện<span className='text-danger'>*</span></label>
                                <Select
                                    showSearch
                                    status={(restaurantStore.errors?.districtId && !restaurantStore.dataRequest?.districtId) ? "error" : ""}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    value={restaurantStore.dataRequest.districtId}
                                    onChange={async (e: any) => {
                                        await restaurantStore.changeDistrict(e)
                                    }}
                                    placeholder="Chọn" options={restaurantStore.listDistrict}
                                />
                                {restaurantStore.errors?.districtId  && !restaurantStore.dataRequest.districtId && <small className="text-error">{restaurantStore.errors?.districtId}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Xã/ Phường<span className='text-danger'>*</span></label>
                                <Select
                                    showSearch
                                    status={(restaurantStore.errors?.wardId && !restaurantStore.dataRequest?.wardId) ? "error" : ""}

                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    value={restaurantStore.dataRequest.wardId}
                                    onChange={(e: any) => {
                                        restaurantStore.dataRequest.wardId = e
                                    }}
                                    placeholder="Chọn" options={restaurantStore.listWard}
                                />
                                {restaurantStore.errors?.wardId  && !restaurantStore.dataRequest.wardId && <small className="text-error">{restaurantStore.errors?.wardId}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Địa chỉ cụ thể<span className='text-danger'>*</span></label>
                                <Input type='text'  value={restaurantStore.dataRequest?.addressDetail}
                                       status={(restaurantStore.errors?.addressDetail && !restaurantStore.dataRequest?.addressDetail) ? "error" : ""}
                                       onChange={(e: any) => {
                                           restaurantStore.dataRequest.addressDetail = e.target.value
                                       }}
                                       placeholder='Nhập vào ...' />
                                {restaurantStore.errors?.addressDetail  && !restaurantStore.dataRequest.addressDetail && <small className="text-error">{restaurantStore.errors?.addressDetail}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>

                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn__voucher'
                                        onClick={async () => {
                                            await restaurantStore.addEditRestaurant()}}>
                                    {restaurantStore.isLoadingBt ? <Spin size="small" /> : restaurantStore.typeModal === 'add' ?  'Thêm' : 'Cập nhật'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddEditRestaurant);

