import React from 'react';
import {observer} from "mobx-react";
import './TableSelected.scss'

interface ITableSelected {
    type?: string,
    value?: any,
    name?: string
    onSelected?: any
}

const TableSelected= (props: ITableSelected) => {
    const {type, name, onSelected, value} = props
    return (
        <>
            {
                type === 'INACTIVE' ? <div className='table_container'>
                            <img src="/assets/ico/table/TableFull.svg" alt=""/>
                            <div className='name_table_white'>
                                {name}
                            </div>
                    </div>
            :
            <button onClick={() => onSelected(value)} className='table_container'>
                {
                    type === "ACTIVE" ? <img src="/assets/ico/table/TableEmpty.svg" alt=""/>
                    : <img src="/assets/ico/table/TableSelected.svg" alt=""/>
                }
                <div className={`${type === 'SELECTED' ? 'name_table_white' : 'name_table'}`}>
                    {name}
                </div>
            </button> }
        </>
    )

}

export default observer(TableSelected);
