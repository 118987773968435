import React, {useEffect} from 'react';
import './CostPage.scss'
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import CustomTable from "../../../modules/components/tableGlobal/CustomTable";
import {numberUtil} from "../../../common/utils/NumberUtil";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import ModalFilter from "./components/modalFilter/ModalFilter";
import {costStore} from "./CostStore";
import {DatePicker} from "antd";
import {orderStore} from "../../order/OrderStore";
import dayjs from "dayjs";

const CostPage = () => {
    const dataFilter : IFilter[] = [
        {
            type: 'select',
            value: costStore.searchEstablishmentId,
            placeholder: 'Tất cả cửa hàng',
            listValue: costStore.listRestaurant,
            callback: async (e: any, value: any) => {
                costStore.searchMonth = null

                costStore.searchEstablishmentId = e
                costStore.establishmentName = value?.label ?? ""

                orderStore.dataRequest.establishmentId = e
                orderStore.dataRequest.establishmentName = value?.label ?? ""
                localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                await costStore.getListCost()
            }
        },
    ]

    useEffect(() => {
        costStore.searchYear = new Date().getFullYear().toString()
        costStore.getListRestaurant().then(() => {
            if(!costStore.searchEstablishmentId) {
                costStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
            }
            if(!costStore.establishmentName) {
                costStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
            }
            costStore.getListCost().then()
        })
    }, [])

    const handleSearchYearChange = async (date: any, dateString: any) => {
        costStore.searchYear = dateString || new Date().getFullYear().toString()
        await costStore.getListCost()
    };
    const averageCost = (record: any) => {
        let result = 0
        for(let i = 0; i <= 11; i++) {
            if(record[`cost_${i + 1}`])
            result += record[`cost_${i + 1}`]
        }
        return result / 12
    }
    const columnCosts: any = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            fixed: "left",
            width: 80,
        },
        {
            title: "Các loại chi phí",
            dataIndex: "type",
            key: "type",
            align: "left",
            fixed: "left",
            width: 200,
            render: (value: any) => value ? <span className='fw-500'>{value}</span> : "-"
        },
        {
            title: 'Chi phí tháng (VNĐ)',
            children: [
                {
                    title: "Tháng 1",
                    dataIndex: "cost_1",
                    key: "cost_1",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 2",
                    dataIndex: "cost_2",
                    key: "cost_2",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 3",
                    dataIndex: "cost_3",
                    key: "cost_3",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 4",
                    dataIndex: "cost_4",
                    key: "cost_4",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 5",
                    dataIndex: "cost_5",
                    key: "cost_5",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 6",
                    dataIndex: "cost_6",
                    key: "cost_6",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 7",
                    dataIndex: "cost_7",
                    key: "cost_7",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 8",
                    dataIndex: "cost_8",
                    key: "cost_8",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 9",
                    dataIndex: "cost_9",
                    key: "cost_9",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 10",
                    dataIndex: "cost_10",
                    key: "cost_10",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>

                },
                {
                    title: "Tháng 11",
                    dataIndex: "cost_11",
                    key: "cost_11",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
                {
                    title: "Tháng 12",
                    dataIndex: "cost_12",
                    key: "cost_12",
                    align: "right",
                    width: 150,
                    render: (value: any, record: any) => <span className={`${(record.type === 'COS' || record.type === 'COL' || record.type === 'SEMI') ? 'fw-500' : ''}`}>{ value ? numberUtil.number_format(value) : "-"}</span>
                },
            ],
        },
        {
            title: "Trung bình",
            dataIndex: "cost",
            key: "cost",
            align: "right",
            fixed: "right",
            width: 150,
            render: (value: any, record: any) => <span className='fw-500'>{numberUtil.number_format(averageCost(record)) ?? "-"}</span>
        },
    ];


    return (
        <>
            <div className='finance_container'>
                <div className='finance_container__header'>
                    <div className='d-flex w-100 justify-content-between align-items-center'>
                        <TabRedirect tab='Chi phí' />
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            isSearchYear
                            searchYear={<DatePicker
                                picker="year"
                                placeholder="Năm"
                                value={costStore.searchYear ? dayjs(costStore.searchYear, 'YYYY') as any : null}
                                onChange={handleSearchYearChange}
                                bordered={false}
                            />}
                            buttonAdd={<button data-bs-toggle="modal" data-bs-target={"#filter_finance"} className="btn_add"
                                               onClick={() => {costStore.clearForm()}}>
                                Cập nhật chi phí
                            </button>}
                        />
                    </div>
                </div>
                <CustomTable
                    subtractHeight={800}
                    isRowSelection={false}
                    columns={columnCosts}
                    isLoading={costStore.isLoading}
                    data={costStore.dataColumns}
                    scrollX={2000}
                />
            </div>
            <ModalFilter />
        </>
    );
}

export default observer(CostPage);