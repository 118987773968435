import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery'
import {businessPlanService} from "./BusinessPlanService";

class BusinessPlanStore {
    id: any = ''
    idJob: any = ''
    keyRequirement: any = ''
    searchTypePlan: any = null
    searchTypeJob: any = null
    typeModal: any = 'add'
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listStaff: any = []
    dataRequestAddPlan: any = {
        name: '',
    }
    dataRequestAddPJobForPlan: any = {}
    dataRequestAddRequestForWork: any = {
        content: '',
    }
    dataRequestUpdateContentForJob: any = {
        images: [],
        videos: [],
        docs: [],
        description: ""
    }
    dataDetailPlan: any = {}
    dataDetailJob: any = {}
    dataColumn : any = []
    dataColumnWork : any = []
    dataDetailRequestForWork : any = []

    clearForm() {
    }

    async getBusinessPlan() {
        this.isLoading = true
        const result = await businessPlanService.getBusinessPlan()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataColumn =  result.body.data?.map((item: any) => {
                return {
                    id: item?.id,
                    name: item?.name,
                    createdAt: item?.createdAt,
                    creator: item?.createUser?.name,
                    state: item?.state,
                    isEdit: false
                }
            })
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async deleteBusinessPlan() {
        businessPlanStore.isLoadingBt = true
        const result = await businessPlanService.delete(businessPlanStore.id)
        businessPlanStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa chiến dịch thành công!')
            await  businessPlanStore.getBusinessPlan()
        } else toastUtil.error(result.body.message)
    }
    async getDetail(id: number) {
        const result = await businessPlanService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            businessPlanStore.dataDetailPlan = result.body
        }
    }

    async addEditBusinessPlan() {
        this.isLoadingBt = true
        const result = businessPlanStore.typeModal === 'add' ?  await businessPlanService.add(businessPlanStore.dataRequestAddPlan)
            :  await businessPlanService.edit(businessPlanStore.id, businessPlanStore.dataRequestAddPlan)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_businessPlan').trigger('click')
            toastUtil.success(`${businessPlanStore.typeModal === 'add' ? 'Thêm' : 'Cập nhật'} kế hoạch kinh doanh thành công!`)
            await businessPlanStore.getBusinessPlan()
        } else toastUtil.error(result.body.message)
    }
    async changeStatusPlan(id: any, state: any) {
        this.isLoadingBt = true
        const result = await businessPlanService.changeStatusPlan(id, state, {})
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật trạng thái chiến dịch kinh doanh thành công !')
            await businessPlanStore.getBusinessPlan()
        } else toastUtil.error(result.body.message)
    }

    //------------------------------------JOB-----------------------------------
    async getJobPlan() {
        this.isLoading = true
        const result = await businessPlanService.getJobPlan()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataColumnWork =  result.body.data?.map((item: any) => {
                return {
                    id: item?.id,
                    contentType: item?.contentType,
                    deadline: item?.deadline,
                    createUser: item?.createUser?.name,
                    createdAt: item?.createdAt,
                    inChargeUser: item?.inChargeUser?.name,
                    supportUsers: item?.supportUsers,
                    requirement: item?.requirement,
                    state: item?.state,
                    isEdit: false
                }
            })
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetailJob(id: number) {
        const result = await businessPlanService.getDetailJob(id)
        if (result.status === HttpStatusCode.OK) {
            businessPlanStore.dataDetailJob = result.body
            businessPlanStore.dataRequestUpdateContentForJob = result.body?.result ?? {
                images: [],
                videos: [],
                docs: [],
                description: ""
            }
            businessPlanStore.dataDetailRequestForWork = result.body?.requirement?.map((item: any) => {
                return {
                    ...item,
                    isEdit: false
                }
            })
        }
    }
    async addEditJobPlan() {
        if(!businessPlanStore.dataRequestAddPJobForPlan.contentType){
            toastUtil.warning('Bạn chưa chọn mã công việc',1)
            return
        }
        if(!businessPlanStore.dataRequestAddPJobForPlan.deadline){
            toastUtil.warning('Bạn chưa chọn ngày yêu cầu hoàn thành',2)
            return
        }
        if(!businessPlanStore.dataRequestAddPJobForPlan.inChargeUserId){
            toastUtil.warning('Bạn chưa chọn người phụ trách công việc',3)
            return
        }
        this.isLoadingBt = true
        const result = businessPlanStore.typeModal === 'add' ?  await businessPlanService.addJob(businessPlanStore.dataRequestAddPJobForPlan)
            :  await businessPlanService.editJob(businessPlanStore.id, businessPlanStore.dataRequestAddPJobForPlan)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_businessPlan').trigger('click')
            toastUtil.success(`${businessPlanStore.typeModal === 'add' ? 'Thêm' : 'Cập nhật'} công việc thành công!`)
            await businessPlanStore.getJobPlan()
        } else toastUtil.error(result.body.message)
    }

    async deleteJobPlan() {
        businessPlanStore.isLoadingBt = true
        const result = await businessPlanService.deleteJobPlan(businessPlanStore.id)
        businessPlanStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_confirm').trigger('click')
            toastUtil.success('Xóa công việc thành công!')
            await businessPlanStore.getJobPlan()
        } else toastUtil.error(result.body.message)
    }

    // -----------------REQUEST---------------------
    async addRequirement() {
        this.isLoadingBt = true
        const result = (businessPlanStore.typeModal === 'edit') ?
            await businessPlanService.addRequirement(businessPlanStore.idJob, {
                requirements :
                    businessPlanStore.dataDetailRequestForWork.filter((item: any) => !item.isEdit).map((i: any) => {
                        if(i.key == businessPlanStore.keyRequirement){
                            return {
                                key: i.key,
                                content: businessPlanStore.dataRequestAddRequestForWork.content
                            }
                        }
                        else return {
                            key: i.key,
                            content: i.content
                        }
                    }),
            }) :
            (businessPlanStore.typeModal === 'delete') ?
            await businessPlanService.addRequirement(businessPlanStore.idJob, {
                requirements : [
                    ...businessPlanStore.dataDetailRequestForWork.map((i: any) => {
                        return {
                            key: i.key,
                            content: i.content
                        }
                    }),
                ]
            })
            :
            await businessPlanService.addRequirement(businessPlanStore.idJob, {
            requirements : [
                ...businessPlanStore.dataDetailRequestForWork.filter((item: any) => !item.isEdit).map((i: any) => {
                    return {
                        key: i.key,
                        content: i.content
                    }
                }),
                {
                    key: businessPlanStore.dataDetailRequestForWork.length,
                    content: businessPlanStore.dataRequestAddRequestForWork.content
                }
            ]
        })
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_businessPlan').trigger('click')
            toastUtil.success(`${businessPlanStore.typeModal === 'add' ? 'Thêm' : 'Cập nhật'} yêu cầu thành công!`)
            await businessPlanStore.getDetailJob(businessPlanStore.idJob)
            await businessPlanStore.getJobPlan()
        } else toastUtil.error(result.body.message)
    }

    async updateContentJob() {
        this.isLoadingBt = true
        const result = await businessPlanService.updateContentJob(businessPlanStore.idJob, businessPlanStore.dataRequestUpdateContentForJob)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#update_content_job_plan').trigger('click')
            toastUtil.success(`Cập nhật nội dung công việc thành công!`)
            await businessPlanStore.getJobPlan()
        } else toastUtil.error(result.body.message)
    }
    async changeStatusJob(id: any, state: any) {
        this.isLoadingBt = true
        const result = await businessPlanService.changeStatusJob(id, state, {})
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật trạng thái công việc thành công !')
            await businessPlanStore.getJobPlan()
        } else toastUtil.error(result.body.message)
    }
    constructor() {
        makeAutoObservable(this)
    }
}

export const businessPlanStore = new BusinessPlanStore()