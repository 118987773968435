import {getRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {revenueReportStore} from "./RevenueReportStore";
import {costStore} from "../../finance/costPage/CostStore";

class RevenueReportService{
    public getListProduct(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product`)
    }
    public getRevenueStructure(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reports/revenue_structure${costStore.searchYear ? `?&year=${costStore.searchYear}` : ''}${revenueReportStore.monthRevenueStructure ? `&month=${revenueReportStore.monthRevenueStructure}` : ''}`)
    }
    public getPromoteProduct(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reports/product/development${costStore.searchYear ? `?&year=${costStore.searchYear}` : ''}${revenueReportStore.searchProduct ? `&productId=${revenueReportStore.searchProduct}` : ''}`)
    }
    public getProductStructure(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reports/product/structure${costStore.searchYear ? `?&year=${costStore.searchYear}` : ''}${revenueReportStore.monthProductStructure ? `&month=${revenueReportStore.monthProductStructure}` : ''}`)
    }
    public getBestSellerProduct(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/reports/product/best_sell?limit=8`)
    }
}
export const revenueReportService = new RevenueReportService()