import React from 'react';
import {observer} from "mobx-react";
import CheckTheDish from "./CheckTheDish";


const CheckDuringProcessingPage = () => {

    return (
        <div>
            <CheckTheDish title="Kiểm khi chế biến"/>
        </div>
    )

}

export default observer(CheckDuringProcessingPage);

