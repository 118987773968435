import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery'
import {trademarkService} from "./TrademarkService";


class TrademarkStore {
    id: any = ''
    typeForm: string = 'add'
    statusResearch: any = ''

    page: number = 0
    totalPage: number = 0
    searchStatus: any = null
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listTrademark: any[] = []
    dataRequest: any = {
        name: "KẾ HOẠCH THƯƠNG HIỆU",
        data: {},
    }
    reportingTime: any = ""
    summaryCurrentBrandStatus: any = ""
    goalBrandDevelop: any = ""
    propagandaBrandValue: any = ""
    summaryStrategy: any = ""
    listStrategyTrademark: any = [{
        targetDevelopment: "",
        action: "",
        chanel: "",
        target: "",
        object: "",
        qty: "",
        unit: "",
        performer: "",
        time: "",
    }]
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.statusResearch = ''
        this.typeForm = 'add'
        this.reportingTime = ""
        this.summaryCurrentBrandStatus = ""
        this.propagandaBrandValue = ""
        this.goalBrandDevelop = ""
        this.summaryStrategy = ""
        this.listStrategyTrademark = [{
            targetDevelopment: "",
            action: "",
            chanel: "",
            target: "",
            object: "",
            qty: "",
            unit: "",
            performer: "",
            time: "",
        }]
    }

    async getTrademark() {
        this.isLoading = true
        const result = await trademarkService.getTrademark()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listTrademark = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: number) {
        const result = await trademarkService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            trademarkStore.dataRequest.name = result.body.name
            trademarkStore.reportingTime = result.body?.data?.reportingTime
            trademarkStore.summaryCurrentBrandStatus = result.body?.data?.summaryCurrentBrandStatus
            trademarkStore.goalBrandDevelop = result.body?.data?.goalBrandDevelop
            trademarkStore.propagandaBrandValue = result.body?.data?.propagandaBrandValue
            trademarkStore.summaryStrategy = result.body?.data?.summaryStrategy
            trademarkStore.listStrategyTrademark = result.body?.data?.listStrategyTrademark
            trademarkStore.statusResearch = result.body?.status
        }
    }

    async addEditTradeMark() {
        let data = {
            name: trademarkStore.dataRequest.name,
            data: {
                reportingTime: trademarkStore.reportingTime,
                summaryCurrentBrandStatus: trademarkStore.summaryCurrentBrandStatus,
                goalBrandDevelop: trademarkStore.goalBrandDevelop,
                propagandaBrandValue: trademarkStore.propagandaBrandValue,
                summaryStrategy: trademarkStore.summaryStrategy,
                listStrategyTrademark: trademarkStore.listStrategyTrademark,
            }
        }
        this.isLoadingBt = true
        const result = this.typeForm === 'add' ? await trademarkService.add(data) : await trademarkService.edit(trademarkStore.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeForm === 'add' ? 'Thêm' : 'Cập nhật'} kế hoạch thương hiệu thành công!`)
            await trademarkStore.getTrademark()
            window.location.href = '/thuong-hieu'
        } else toastUtil.error(result.body.message)
    }

    async delete() {
        trademarkStore.isLoadingBt = true
        const result = await trademarkService.delete(trademarkStore.id)
        trademarkStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_confirm').trigger('click')
            toastUtil.success('Xóa kế hoạch thương hiệu thành công!')
            await trademarkStore.getTrademark()
        } else toastUtil.error(result.body.message)
    }

}

export const trademarkStore = new TrademarkStore()