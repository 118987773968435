import React from 'react';
import {observer} from "mobx-react";
import './ModalComboVoucher.scss'
import {DatePicker, Radio, Input, Spin} from 'antd';
import {voucherStore} from "../../VoucherStore";
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import {Moment} from "moment/moment";
import {RangePickerProps} from "antd/es/date-picker";
import ModalAddProductVoucher from "./modalAddProductVoucher/modalAddProductVoucher";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
dayjs.extend(customParseFormat);
function convertTime(date?: Moment, isEndDate?: boolean): number {
    if (!date) return 0;
    if (isEndDate) {
        return date.set({hour: 23, minute: 59, second: 59}).unix() * 1000;
    }
    return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
}
const handleRangePickerChange = (dates: any) => {
    if (dates && dates.length > 0) {
        voucherStore.dataRequestValueVoucher.startTime = convertTime(dates[0]);
        voucherStore.dataRequestValueVoucher.endTime = convertTime(dates[1]);
    }
}

const ModalComboVoucher = () => {
    const {RangePicker} = DatePicker;

    return (
        <div className='modal fade' id='combo_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>COMBO</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='d-flex w-100'>
                            <div className='w-50 d-flex flex-column pe-2'>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Tên combo khuyến mãi<span className='text-danger'>*</span></label>
                                    <Input type='text' value={voucherStore.dataRequestValueVoucher?.name}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.name = e.currentTarget.value
                                           }}
                                           status={(voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher?.name) ? "error" : ""}
                                           placeholder='Nhập tên ...' />
                                    {voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher.name && <small className="text-error">{voucherStore.errors?.name}</small>}
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Mã Voucher<span className='text-danger'>*</span></label>
                                    <Input type='text'  value={voucherStore.dataRequestValueVoucher?.code}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.code = e.currentTarget.value
                                           }}
                                           status={(voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher?.code) ? "error" : ""}
                                           placeholder='HUAS | Nhập vào ...' />
                                    {voucherStore.errors?.code  && !voucherStore.dataRequestValueVoucher.code && <small className="text-error">{voucherStore.errors?.code}</small>}
                                </div>
                                <div className='w-100 form-group'>
                                        <label className='form-label'>Thời gian chạy combo<span className='text-danger'>*</span></label>
                                            <RangePicker
                                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                                onChange={handleRangePickerChange}
                                                format={"DD/MM/YYYY"}
                                                status={(voucherStore.errors?.time && !voucherStore.dataRequestValueVoucher?.startTime &&  !voucherStore.dataRequestValueVoucher?.endTime) ? "error" : ""}
                                                value={[
                                                    voucherStore.dataRequestValueVoucher.startTime === "" ? null : dayjs(voucherStore.dataRequestValueVoucher.startTime),
                                                    voucherStore.dataRequestValueVoucher.endTime === "" ? null : dayjs( voucherStore.dataRequestValueVoucher.endTime)
                                                ] as any}
                                            />
                                    {voucherStore.errors?.time  && !voucherStore.dataRequestValueVoucher.startTime &&  !voucherStore.dataRequestValueVoucher.endTime && <small className="text-error">{voucherStore.errors?.time}</small>}

                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Số voucher phát hành<span className='text-danger'>*</span></label>
                                    <Input type='number'  value={voucherStore.dataRequestValueVoucher?.amount}
                                           status={(voucherStore.errors?.amount && !voucherStore.dataRequestValueVoucher?.amount) ? "error" : ""}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.amount = e.currentTarget.value
                                           }}
                                           placeholder='Nhập số ...' />
                                    {voucherStore.errors?.amount  && !voucherStore.dataRequestValueVoucher?.amount && <small className="text-error">{voucherStore.errors?.amount}</small>}
                                </div>
                                <div className='w-100'>
                                    <div className='w-100 mb-3 d-flex justify-content-between align-items-center'>
                                        <label className='form-label'>Sản phẩm Combo</label>
                                        <button className='button_add_product__voucher'  data-bs-toggle="modal" data-bs-target="#add_product_voucher">
                                            <img src="/assets/ico/action/plus_green.svg" alt="button_add_product"/>
                                            Thêm sản phẩm
                                        </button>
                                    </div>
                                    <div className='list_product__voucher'>
                                        {voucherStore.dataRequestValueVoucher?.proviso?.products?.length <= 0 ? <div className='list_product__placeholder'>
                                                Tất cả sản phẩm
                                            </div> :
                                            <>{
                                                voucherStore.dataRequestValueVoucher?.proviso?.products?.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index} className='product_item__voucher'>
                                                            {item.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                            </>
                                        }

                                    </div>
                                    {voucherStore.errors.proviso?.products &&  voucherStore.dataRequestValueVoucher?.proviso?.products?.length <= 0 &&  <small className="text-error">{voucherStore.errors.proviso?.products}</small>}
                                </div>
                            </div>
                            <div className='w-50 d-flex flex-column ps-2'>
                                <div className='w-100 pe-2'>
                                    <label className='form-label'>Thiết lập mã giảm giá</label>
                                    <div className='mt-2'>
                                        <label className='form-label'>Loại Voucher</label>
                                        <Radio.Group name="radiogroup" className='d-flex flex-column ps-2' value={voucherStore.dataRequestValueVoucher.proviso.type} onChange={(e: any) => {
                                            voucherStore.dataRequestValueVoucher.proviso.type = e.target.value
                                            if (voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIAL") {
                                                delete voucherStore.dataRequestValueVoucher.proviso.percentage;
                                                delete voucherStore.dataRequestValueVoucher.proviso.specificAmount;
                                            } else if (voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
                                                delete voucherStore.dataRequestValueVoucher.proviso.percentage;
                                                voucherStore.dataRequestValueVoucher.proviso.specificAmount = {
                                                    value: ""
                                                }
                                            } else if (voucherStore.dataRequestValueVoucher?.proviso?.type === "PERCENTAGE") {
                                                delete voucherStore.dataRequestValueVoucher.proviso.specificAmount;
                                                voucherStore.dataRequestValueVoucher.proviso.percentage = {
                                                    maxDiscountAmount: "",
                                                    value: ""
                                                }
                                            }
                                        }}>
                                            <Radio value={'PERCENTAGE'}>Giảm giá theo %</Radio>
                                            <Radio value={'SPECIFIC_AMOUNT'}>Giảm giá theo số tiền</Radio>
                                            <Radio value={'SPECIAL'}>Giảm giá đặc biệt</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div className='ps-2 w-100'>
                                    <label className='form-label mt-2'>Mức ưu đãi</label>
                                    {
                                        voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' && (
                                            <div className='w-100 d-flex align-items-center rate-voucher__container'>
                                                <div className='w-40 overflow-hidden me-2'>Số % giảm</div>
                                                <Input type='text' value={voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value) : ""}
                                                       className='w-60 ms-2'
                                                       onChange={(e: any) => {
                                                           if (
                                                               e === '' ||
                                                               Number(numberUtil.regexNumber(e)) >= 0
                                                           ) {
                                                               voucherStore.dataRequestValueVoucher.proviso.percentage.value = numberUtil.regexPercentage(e)
                                                           }
                                                       }}
                                                       onKeyDown={(evt: {
                                                           key: string
                                                           preventDefault: () => any
                                                       }) =>
                                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                                           evt.preventDefault()
                                                       }
                                                       placeholder='-' />
                                            </div>
                                        )
                                    }
                                    {
                                        voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIFIC_AMOUNT' && (
                                            <div className='w-100 d-flex align-items-center rate-voucher__container'>
                                                <div className='w-40 overflow-hidden me-2'>Số tiền giảm</div>
                                                <Input type='text' value={voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value) : ""}
                                                       className='w-60 ms-2'
                                                       onChange={(e: any) => {
                                                           if (
                                                               e === '' ||
                                                               Number(numberUtil.regexNumber(e)) >= 0
                                                           ) {
                                                               console.log(voucherStore.dataRequestValueVoucher.proviso)
                                                               voucherStore.dataRequestValueVoucher.proviso.specificAmount.value = numberUtil.regexNumber(e)
                                                           }
                                                       }}
                                                       onKeyDown={(evt: {
                                                           key: string
                                                           preventDefault: () => any
                                                       }) =>
                                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                                           evt.preventDefault()
                                                       }
                                                       placeholder='-' />
                                            </div>
                                        )
                                    }
                                    {voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIAL' && voucherStore.dataRequestValueVoucher?.proviso?.products?.length > 0 && voucherStore.dataRequestValueVoucher?.proviso?.products?.map((item: any, index: number) => {
                                        return (
                                            <div className='w-100 d-flex align-items-center rate-voucher__container'>
                                                <div className='w-40 overflow-hidden me-2'>{`${index}.  ${item.label}`}</div>
                                                <Input type='text' value={item.price ? numberUtil.number_format(item.price) : ""}
                                                       className='w-60 ms-2'
                                                       onChange={(e: any) => {
                                                           if (
                                                               e === '' ||
                                                               Number(numberUtil.regexNumber(e)) >= 0
                                                           ) {
                                                               item.price = numberUtil.regexNumber(e)
                                                           }
                                                       }}
                                                       onKeyDown={(evt: {
                                                           key: string
                                                           preventDefault: () => any
                                                       }) =>
                                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                                           evt.preventDefault()
                                                       }
                                                       placeholder='-' />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='w-100 mt-4'>
                                        <button type='submit' className='btn btn__voucher'
                                                onClick={async () => {
                                            await voucherStore.addEditPromotion()}}
                                        >{voucherStore.isLoadingBt ? <Spin size="small" /> : voucherStore.type === 'add' ?  'Tạo Voucher' : voucherStore.type === 'copy' ?  "Sao chép Voucher" : 'Cập nhật Voucher'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddProductVoucher />
        </div>

    )

}

export default observer(ModalComboVoucher);

