import React from 'react';
import {observer} from "mobx-react";
import {DatePicker, Input, Spin} from 'antd';
import {DatePickerProps, RangePickerProps} from "antd/es/date-picker";
import {Moment} from "moment/moment";
import {purchaseStore} from "../../PurchaseStore";
import SelectForm from "../../../../../modules/components/form/SelectAnt";
import {suppliesStore} from "../../../../setting/supplies/SuppliesStore";
import NoContent from "../../../../../modules/components/NoContent";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import './AddRequestPurchase.scss'
import dayjs from "dayjs";
const {TextArea} = Input;

let options = [
    {
        id: 'materials',
        name: 'Nguyên vật liệu'
    },
    {
        id: 'additive',
        name: 'Phụ gia'
    },
    {
        id: 'packaging',
        name: 'Bao bì'
    },
    {
        id: 'devices',
        name: 'Thiết bị'
    }
]
function convertTime(date: Moment): any {
    if(date) return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    else return ""
}

const ModalAddRequestPurchase = () => {

    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
    ) => {
        // @ts-ignore
        purchaseStore.dataRequest.timeRequest = convertTime(value);
    };
    return (
        <div className="modal fade" id="add_request_purchase" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 1450}}>
                <div className="modal-content px-5">
                    <div className="modal-header">
                        <h5 className="modal-title">PHIẾU ĐỀ NGHỊ MUA HÀNG</h5>
                        <button type="button" id="close_add_imp_exp_request" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="w-100 d-flex justify-content-between mt-4">
                        <div style={{width: '33%'}} className="form-group pe-2">
                            <label className='form-label'>Kính gửi</label>
                            <Input type='text' value=""
                                   disabled
                                   placeholder='Nhập tên ...' />
                        </div>
                        <div style={{width: '33%'}} className="form-group ">
                            <label className='form-label'>Mã yêu cầu<span className='text-danger'>*</span></label>
                            <Input type='text' value={purchaseStore.dataRequest?.code}
                                   status={(purchaseStore.errors?.code && !purchaseStore.dataRequest?.code) ? "error" : ""}
                                   onChange={(e: any) => {
                                       purchaseStore.dataRequest.code = e.target.value
                                   }}
                                   placeholder='Nhập...' />
                            {purchaseStore.errors?.code && !purchaseStore.dataRequest?.code && <small className="mb-2 text-error">{purchaseStore.errors?.code}</small>}

                        </div>
                        <div style={{width: '33%'}}  className="ps-2 form-group">
                            <label className='form-label'>Ngày gửi<span className='text-danger'>*</span></label>
                            <DatePicker
                                placeholder='Chọn'
                                status={(purchaseStore.errors?.timeRequest && !purchaseStore.dataRequest?.timeRequest) ? "error" : ""}
                                showTime
                                value={purchaseStore.dataRequest.timeRequest ? dayjs(purchaseStore.dataRequest.timeRequest) : null}
                                onChange={onChange}/>
                            {purchaseStore.errors?.timeRequest && !purchaseStore.dataRequest?.timeRequest && <small className="mb-2 text-error">{purchaseStore.errors?.timeRequest}</small>}
                        </div>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                            <div style={{width: '33%'}} className="pe-2">
                                <div className="form-group">
                                    <label>Sử dụng vật tư <span className="text-danger">*</span></label>
                                    <SelectForm value={purchaseStore.use_material}
                                                options={options}
                                                onChange={(e: any) => purchaseStore.changeUseMaterial(e)}
                                                placeholder="Chọn vật tư"/>
                                </div>
                            </div>
                            <div style={{width: '33%'}} className="">
                                <div className="form-group">
                                    <label>Tên vật tư <span className="text-danger">*</span></label>
                                    <SelectForm value={purchaseStore.materialId}
                                                onChange={(e: any) => purchaseStore.materialId = e}
                                                options={suppliesStore.listSupplies}/>
                                </div>
                            </div>
                        <div  style={{width: '33%'}} className='d-flex justify-content-end'>
                            <button className="button_add_form_table mt-3" onClick={() => purchaseStore.addSupplies()}>
                                <img src="/assets/ico/action/add_green_icon.svg" style={{width: 14}} alt=""/>
                                Thêm
                            </button>
                        </div>
                    </div>

                    <div className="table-form-responsive mt-2 mb-3 d-flex justify-content-between">
                        <table className='table align-middle border gy-7 gs-7 mb-0'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Tên vật tư</th>
                                <th className="text-center">Mã vật tư</th>
                                <th className="text-center">ĐVT</th>
                                <th className="text-center">Số lượng <span className='text-danger'>*</span></th>
                                <th className="text-center">Ngày dự kiến hàng về</th>
                                <th>Ghi chú</th>
                            </tr>
                            </thead>
                            <tbody>
                            {purchaseStore.dataRequest.items && purchaseStore.dataRequest.items.length < 1 ? <NoContent colSpan={9} height={150} /> : purchaseStore.dataRequest.items.map((item: any, i: number) => {
                                return (
                                    <tr key={i} className='position-relative'>
                                        <td className="text-center">{i + 1}</td>
                                        <td  width="15%">{item.name}</td>
                                        <td width="10%" className="text-center">{item.code}</td>
                                        <td className="text-center">{item.unit}</td>
                                        <td width="15%" className="text-center">
                                            <Input
                                                type="text"
                                                onKeyDown={(evt: {
                                                    key: string
                                                    preventDefault: () => any
                                                }) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        e === '' ||
                                                        Number(numberUtil.regexNumber(e)) >= 0
                                                    ) {
                                                        item.qty = numberUtil.regexNumber(e)
                                                    }
                                                }}
                                                status={(purchaseStore.isErrorsQtySupplies && !item.qty) ? 'error' : ''}
                                                value={item.qty === "" ? "" : numberUtil.number_format(item.qty)}
                                                placeholder='Nhập...'
                                            />
                                        </td>
                                        <td  width="20%" className="text-center">
                                            <DatePicker placeholder='Chọn'
                                                        value={item.expectedDate ? dayjs(item.expectedDate) : null}
                                                        onChange={(
                                                value: DatePickerProps['value'] | RangePickerProps['value'],
                                            ) => {
                                                // @ts-ignore
                                                item.expectedDate = convertTime(value);
                                            }}/>
                                        </td>
                                        <td width="20%" className="text-center">
                                            <Input
                                                type="text"
                                                onChange={(e: any) => item.note = e.currentTarget.value}
                                                value={item.note}
                                                placeholder='Nhập...'
                                            />
                                            <button className="btn p-0 mx-1 btn_delete_form_table"
                                                    data-toggle="modal" data-target="#"
                                                    onClick={() => {
                                                        purchaseStore.dataRequest.items.splice(i, 1)
                                                    }}>
                                                <img src="/assets/ico/action/minus-circle.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Xóa"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            {(purchaseStore.dataRequest.items && purchaseStore.dataRequest.items.length < 1 && purchaseStore.errors.materialId) && <tr>
                                <td colSpan={9}>
                                    <p className="error">{purchaseStore.errors.materialId}</p>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className='form-group mb-4'>
                        <label className='form-label'>Mục đích sử dụng</label>
                        <TextArea placeholder="Nhập văn bản..." value={purchaseStore.dataRequest.purpose}
                                  onChange={(e: any) => purchaseStore.dataRequest.purpose = e.currentTarget.value}
                                  className='w-100' rows={2}/>
                    </div>
                    <div className='w-100 d-flex justify-content-between align-items-center'>
                        <div style={{width: '33%'}} className="pe-2">
                            <div className="form-group">
                                <label>Người duyệt</label>
                                <SelectForm value={purchaseStore.dataRequest.approvedId}
                                            options={purchaseStore.listUser}
                                            onChange={(e: any) => {
                                                purchaseStore.dataRequest.approvedId = e
                                            }}
                                            placeholder="Chọn"/>
                            </div>
                        </div>
                        <button className="button_save" onClick={() => purchaseStore.sendImportExport()}>
                            {
                                purchaseStore.isLoadingBt ? <Spin size="small" /> : "Gửi"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ModalAddRequestPurchase);
