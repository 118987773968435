import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import './AddFeedbackModal.scss'
import CardFeedback from "./CardFeedback";
import type { RadioChangeEvent } from 'antd';
import {customerFeedbackStore} from "../../CustomerFeedbackStore";
import {ConfigProvider, DatePicker, Input, Select, Spin} from "antd";
import viVI from "antd/lib/locale/vi_VN";
import {DatePickerProps, RangePickerProps} from "antd/es/date-picker";
import {personalManagerStore} from "../../../../personalManager/PersonalManagerStore";
import {Moment} from "moment/moment";
import dayjs from "dayjs";
import {validatePhoneNumber} from "../../../../../common/utils/Utils";
const { TextArea } = Input;
const AddFeedbackModal = () => {
    const [disableName, setDisableName] = useState(false)
    const [shouldClick, setShouldClick] = useState(false);

    function convertTime(date: Moment): number | null {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
    ) => {
        // @ts-ignore
        customerFeedbackStore.dataRequest.birthday = convertTime(value);
    };
    const modalRef = useRef(null);
    const inputRefs = {
        input1: useRef(null),
        input2: useRef(null),
        // Thêm tham chiếu cho tất cả các ô input trong modal
    };


    useEffect(() => {
        if (shouldClick) {
            // Thực hiện thao tác click vào tham chiếu khi shouldClick là true
            (modalRef.current as unknown as HTMLButtonElement).click();
            // Sau khi thực hiện, đặt shouldClick lại thành false
            setShouldClick(false);
        }
    }, [shouldClick]);
    useEffect(() => {
        const handleKeyPress = (e: any) => {
            if (e.key === "Enter") {
                // Đặt shouldClick thành true khi nhấn phím Enter
                setShouldClick(true);
            }
        };

        // Lắng nghe sự kiện nhấn phím Enter ở bất kỳ đâu trong modal
        window.addEventListener("keydown", handleKeyPress);

        // Làm sạch sự kiện khi component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    const findNextInput = (currentInputName: any) => {
        const inputNames = Object.keys(inputRefs);
        const currentIndex = inputNames.indexOf(currentInputName);

        if (currentIndex !== -1 && currentIndex < inputNames.length - 1) {
            const nextInputName = inputNames[currentIndex + 1];
            // @ts-ignore
            return inputRefs[nextInputName];
        }

        return null; // Không tìm thấy ô input kế tiếp
    };


    const handleKeyPress = async (e: any, currentInputName: any) => {
        if (e.key === "Enter") {
            if(customerFeedbackStore.dataRequest.phone){
                await customerFeedbackStore.checkPhone(customerFeedbackStore.dataRequest.phone).then(() => {
                    if(customerFeedbackStore.dataCheckPhone.phone) {
                        setDisableName(true)
                    }
                    else setDisableName(false)
                })
            }
            const nextInputRef = findNextInput(currentInputName);
            if (nextInputRef) {
                nextInputRef.current.focus();
            }
        }
    };
    const handleBlur = async () => {
        if(customerFeedbackStore.dataRequest.phone){
            await customerFeedbackStore.checkPhone(customerFeedbackStore.dataRequest.phone).then(() => {
                if(customerFeedbackStore.dataCheckPhone.phone) {
                    setDisableName(true)
                }
                else setDisableName(false)
            })
        }
    };

    return (
        <div className='modal modal_full fade' id='add_feedback' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='d-flex w-100 flex-column modal-header__container'>
                            <div className='header__title'>
                                PHIẾU THÔNG TIN
                            </div>
                            <button type="button"
                                    className="close d-flex align-items-center"
                                    id="close_setting_role"
                                    data-bs-dismiss="modal"
                                    aria-label="Close">
                                <img src="/assets/ico/action/close.svg" alt=""/>
                                <p className="m-0">Đóng</p>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body custom-scrollbar">
                        <div className='w-100 modal-header__content_container'>
                            <h2 className="modal_header__subcontent">1. Thông tin khách hàng</h2>
                            <div className='w-100 modal-header__info'>
                                <div className='form-group'>
                                    <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                    <Input type='text' value={customerFeedbackStore.dataRequest?.phone}
                                           status={((customerFeedbackStore.errors?.phone && !customerFeedbackStore.dataRequest?.phone) || customerFeedbackStore.errors?.validatePhone) ? "error" : ""}
                                           onChange={(e: any) => {
                                               customerFeedbackStore.dataRequest.name = ''
                                               setDisableName(false)
                                               customerFeedbackStore.dataRequest.phone = e.target.value
                                               if(validatePhoneNumber(customerFeedbackStore.dataRequest.phone)) {
                                                   customerFeedbackStore.errors.validatePhone = ''
                                               }
                                           }}
                                           onBlur={handleBlur}
                                           ref={inputRefs.input1}
                                           onKeyPress={(e) => handleKeyPress(e, "input1")}
                                           placeholder='Nhập...' />
                                    {customerFeedbackStore.errors?.phone && !customerFeedbackStore.dataRequest.phone && <small className="text-error">{customerFeedbackStore.errors?.phone}</small>}
                                    {customerFeedbackStore.errors?.validatePhone && <small className="text-error">{customerFeedbackStore.errors?.validatePhone}</small>}
                                </div>
                                <div className='form-group'>
                                    <label className='form-label'>Họ và tên<span className='text-danger'>*</span></label>
                                    <Input type='text' value={customerFeedbackStore.dataRequest?.name}
                                           status={(customerFeedbackStore.errors?.name && !customerFeedbackStore.dataRequest?.name) ? "error" : ""}
                                           onChange={(e: any) => {
                                               customerFeedbackStore.dataRequest.name = e.target.value
                                           }}
                                           disabled={customerFeedbackStore.dataRequest.name && disableName}
                                           ref={inputRefs.input2}
                                           onKeyPress={(e) => handleKeyPress(e, "input2")}
                                           placeholder='Nhập tên...' />
                                    {customerFeedbackStore.errors?.name && !customerFeedbackStore.dataRequest.name && <small className="text-error">{customerFeedbackStore.errors?.name}</small>}
                                </div>

                                <div className='form-group'>
                                    <label className='form-label'>Giới tính</label>
                                    <Select
                                        value={customerFeedbackStore.dataRequest.gender}
                                        disabled={disableName}
                                        onChange={(e: any) => {
                                            customerFeedbackStore.dataRequest.gender = e
                                        }}
                                        placeholder="Chọn" options={[{value: "MALE", label: "Nam"}, {value: "FEMALE", label: "Nữ"}]}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className='form-label'>Ngày sinh</label>
                                    <ConfigProvider locale={viVI}>
                                        <DatePicker
                                            disabled={disableName}
                                            value={!customerFeedbackStore.dataRequest.birthday ? null : dayjs(customerFeedbackStore.dataRequest.birthday)}
                                            placeholder='Chọn'
                                            format={"DD/MM/YYYY"} onChange={onChange}/>
                                    </ConfigProvider>
                                </div>
                                <div className='form-group'>
                                    <label className='form-label'>Địa chỉ</label>
                                    <Input type='text' value={customerFeedbackStore.dataRequest?.address}
                                           disabled={disableName}
                                           onChange={(e: any) => {
                                               customerFeedbackStore.dataRequest.address = e.target.value
                                           }}
                                           placeholder='Nhập chữ...' />
                                </div>
                                <div className='form-group'>
                                    <label className='form-label'>Chọn điểm bán<span className='text-danger'>*</span></label>
                                    <Select value={customerFeedbackStore.establishmentId}
                                            onChange={(e: any) => {
                                                customerFeedbackStore.establishmentId = e
                                            }}
                                        placeholder="Chọn" options={customerFeedbackStore.listRestaurant}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-100 modal-header__content_container'>
                            <h2 className="modal_header__subcontent">2. Không gian & Thái độ phục vụ</h2>
                            <div className='w-100 modal-header__content'>
                                {
                                    customerFeedbackStore.listFeedbackSpace.map((item: any, index: number) => {
                                        return (
                                            <CardFeedback key={index} value={item.value} keyRole={item.keyRole} title={item.title}
                                                          onChange={(key: any, e: RadioChangeEvent) => {
                                                              item.value = e.target.value
                                                          }}
                                                          listFeedbackItem={item.listRole}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='w-100 modal-header__content_container'>
                            <h2 className="modal_header__subcontent">3. Món ăn & Đồ uống</h2>
                            <div className='w-100 modal-header__content'>
                                {
                                    customerFeedbackStore.listFeedbackFood.map((item: any, index: number) => {
                                        return (
                                            <CardFeedback key={index} value={item.value} keyRole={item.keyRole} title={item.title}
                                                          onChange={(key: any, e: RadioChangeEvent) => {
                                                              item.value = e.target.value
                                                          }}
                                                          listFeedbackItem={item.listRole}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='w-100 modal-header__content_container'>
                            <h2 className="modal_header__subcontent">4. Thông tin khác</h2>
                            <div className='w-100 modal-header__content'>
                                {
                                    customerFeedbackStore.listFeedbackOtherInfo.map((item: any, index: number) => {
                                        return (
                                            <CardFeedback key={index} value={item.value} keyRole={item.keyRole} title={item.title}
                                                          onChange={(key: any, e: RadioChangeEvent) => {
                                                              item.value = e.target.value
                                                          }}
                                                          listFeedbackItem={item.listRole}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='w-100 modal-header__content_container'>
                            <h2 className="modal_header__subcontent">5. Ý kiến đóng góp</h2>
                            <div className='w-100'>
                                <div className='form-group'>
                                    <TextArea rows={4} placeholder='Nhập ý kiến đóng góp...' onChange={(e: any) => {personalManagerStore.dataCampaignRequest.content = e.currentTarget.value}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-4">
                        <button ref={modalRef} type='submit' className='btn btn__save'
                                onClick={async () => await customerFeedbackStore.addEditFeedback()}>
                            {
                                customerFeedbackStore.isLoadingBt ?  <Spin size="small" /> : "Hoàn thành"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(AddFeedbackModal);

