import {makeAutoObservable} from 'mobx'
import {toastUtil} from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {registerService} from "./RegisterService";
import StorageService from "../../../common/service/StorageService";
import {parseJwt, validatePhoneNumber} from "../../../common/utils/Utils";
import {settingInformationService} from "../../../pages/setting/information/SettingInformationService";
class RegisterStore {
    isLoading: boolean = false
    isLoadingBt: boolean = false
    idFnb : any = ""
    username: string = ""
    phone: string = ""
    avatar: string = ""
    listFnB : any = []
    listProvince: any[] = []
    listDistrict: any[] = []
    listWard: any[] = []
    idRestaurant : any = null
    dataRequest: any = {
        name: "",
        districtId: null,
        provinceId: null,
        wardId: null,
        address: "",
        businessNumber: "",
        numBranch: "",
        ownerName: "",
        ownerPhone: "",
        logo: ""
    }
    errors : any = {
        name: "",
        districtId: "",
        provinceId: "",
        wardId: "",
        address: "",
        businessNumber: "",
        numBranch: "",
        ownerName: "",
        ownerPhone: "",
        logo : ""
    }
    async getToken(){
        this.isLoadingBt = true
        const result = await registerService.getToken({
            fnbId: registerStore.idRestaurant
        })
        this.isLoadingBt = false
        if (result.status === 200) {
            let token: string = result.body.token;
            let refreshToken: string = result.body.refreshToken;
            StorageService.setToken(token)
            StorageService.setRefreshToken(refreshToken)
            await parseJwt(token)
            if(localStorage.getItem('fnbId')) {
                window.location.reload();
            }
        }
    }
    async reloadBlockPage(){
        this.isLoadingBt = true
        const result = await registerService.getToken({
            fnbId: localStorage.getItem('idRestaurant')
        })
        this.isLoadingBt = false
        if (result.status === 200) {
            let token: string = result.body.token;
            StorageService.setToken(token)
            await parseJwt(token)
            if(localStorage.getItem('statusAccount') !== null && localStorage.getItem('statusAccount') !== "BLOCK") {
                window.location.reload();
            }
        }
    }
    async getListFnB(){
        const result = await registerService.getListFnB()
        if (result.status === 200) {
            this.listFnB = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                    logo: item?.logo,
                    address: item?.address
                }
            })
        }
    }
    async register() {
        if(!this.dataRequest?.name) {
            this.errors.name = "Tên thương hiệu không được bỏ trống"
            return
        }
        if(!this.dataRequest?.businessNumber) {
            this.errors.businessNumber = "Quy mô cửa hàng không được bỏ trống"
            return
        }
        if(!this.dataRequest?.numBranch) {
            this.errors.numBranch = "Số chi nhánh không được bỏ trống"
            return
        }
        if(!this.dataRequest?.ownerName) {
            this.errors.ownerName = "Họ và tên không được bỏ trống"
            return
        }
        if(!this.dataRequest?.ownerPhone) {
            this.errors.ownerPhone = "Số điện thoại không được bỏ trống"
            return
        }
        if (!validatePhoneNumber(this.dataRequest.ownerPhone)) {
            this.dataRequest.ownerPhone = ''
            this.errors.ownerPhone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            return
        }
        if(!this.dataRequest?.provinceId) {
            this.errors.provinceId = "Tỉnh/TP không được bỏ trống"
            return
        }
        if(!this.dataRequest?.districtId) {
            this.errors.districtId = "Quận/Huyện không được bỏ trống"
            return
        }
        if(!this.dataRequest?.wardId) {
            this.errors.wardId = "Xã/Phường không được bỏ trống"
            return
        }
        if(!this.dataRequest?.address) {
            this.errors.address = "Địa chỉ cụ thể không được bỏ trống"
            return
        }
        if(!this.dataRequest?.logo) {
            this.errors.logo = "Vui lòng thêm logo cho cửa hàng"
            return
        }
        else  this.errors.name = ""
        this.isLoadingBt = true
        const result = await registerService.registerRestaurant(this.dataRequest)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            registerStore.idRestaurant = result.body.id
            toastUtil.success("Đăng ký cửa hàng thành công", 1)
            await this.getToken()
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async getProvinces() {
        const result = await settingInformationService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            registerStore.listProvince = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }
    async getDistricts(provinceId: any) {
        const result = await settingInformationService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            registerStore.listDistrict = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getWards(districtId: any) {
        const result = await settingInformationService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            registerStore.listWard = result.body.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async changeProvince(provinceId: any) {
        registerStore.dataRequest.provinceId = provinceId
        if (registerStore.dataRequest.provinceId) {
            registerStore.dataRequest.districtId = null
            registerStore.errors.provinceId = ''
            registerStore.dataRequest.wardId = null

        }
        await registerStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any) {
        registerStore.dataRequest.districtId = districtId
        if (registerStore.dataRequest.districtId) {
            registerStore.dataRequest.wardId = null
            registerStore.errors.districtId = ''
        }
        await registerStore.getWards(districtId)
    }
    constructor() {
        makeAutoObservable(this)
    }

}

export const registerStore = new RegisterStore()