import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {inventoryReportService} from "./InventoryReportService";

class InventoryReportStore {
    keyword: any = ''
    isLoading: boolean = false
    listInventoryReport: any[] = []
    searchEstablishmentId: any = ''
    establishmentName: any = ''
    constructor() {
        makeAutoObservable(this)
    }

    async getInventoryReport(){
        this.isLoading = true
        const result = await inventoryReportService.getInvetoryReportRequest()
        if (result.status === HttpStatusCode.OK) {
            this.isLoading = false
            inventoryReportStore.listInventoryReport = result.body.data
        }
    }

}

export const inventoryReportStore = new InventoryReportStore()