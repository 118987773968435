import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {positionStore} from "./PositionStore";

class PositionService{
    public getPosition(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/role?page=${positionStore.page}&size=100${positionStore.keyword ? `&keyword=${positionStore.keyword}` : ''}`)
    }
    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/role`, data)
    }
    public getPositionById(id: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/role/${id}`)
    }
    public editPosition(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/role/${id}`, data)
    }
    public deletePosition(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/role/${id}`, {})
    }
}
export const positionService = new PositionService()