import {makeAutoObservable} from "mobx";
import {staffService} from "./StaffService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery'

interface IDataRequest  {
    id: any
    code: string,
    jobType: any,
    name: string,
    wage: any,
    wageType: any,
    allowance: any,
    bonus: any,
    image: string,
    otherCosts: any,
    socialInsurance: any,
    unionDues: any
}

class StaffStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listStaff: any[] = []
    errors: any = {
        name: '',
        code: '',
        jobType: '',
        wageType: '',
        wage: ''
    }
    dataRequest: IDataRequest = {
        id: '',
        code: "",
        jobType: null,
        name: "",
        wage: "",
        wageType: null,
        allowance: "",
        bonus: "",
        image: "",
        otherCosts: "",
        socialInsurance: "",
        unionDues: ""
    }
    wageType: any [] = [
        {
            name: 'Lương theo ngày',
            id: 'WAGE_PER_DAY'
        },
        {
            name: 'Lương theo giờ',
            id: 'WAGE_PER_HOUR'
        },
        {
            name: 'Lương theo ca',
            id: 'WAGE_PER_SHIFT'
        },
        {
            name: 'Lương theo tuần',
            id: 'WAGE_PER_WEEK '
        },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    clearError(){
        this.errors = {
            name: '',
            code: '',
            jobType: '',
            wageType: '',
            wage: ''
        }
    }


    clearForm() {
        this.clearError()
        this.typeModal = 'add'
        this.dataRequest = {
            id: '',
            code: "",
            jobType: null,
            name: "",
            wage: "",
            wageType: null,
            allowance: "",
            bonus: "",
            image: "",
            otherCosts: "",
            socialInsurance: "",
            unionDues: ""
        }
    }

    async getStaff() {
        this.isLoading = true
        const result = await staffService.getStaff()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listStaff = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: number) {
        this.typeModal = 'edit'
        const result = await staffService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        }
    }

    async addEdit() {
        this.clearError()

        let {id, code, jobType, name, wage, wageType, allowance,bonus,image, otherCosts, socialInsurance, unionDues} = this.dataRequest

        let data = {
            code: code,
            jobType: jobType,
            name: name,
            wage: wage ?? 0,
            wageType: wageType,
            allowance: allowance ?? 0,
            bonus: bonus ?? 0,
            image: image,
            otherCosts: otherCosts ?? 0,
            socialInsurance: socialInsurance ?? 0,
            unionDues: unionDues ?? 0
        }
        
        if(!name){
            this.errors.name = "Tên nhân viên không được để trống!"
        }

        if(!code){
            this.errors.code = "Mã nhân viên không được để trống!"
        }

        if(!jobType){
            this.errors.jobType = "Hình thức làm việc không được để trống!"
        }

        if(!wageType){
            this.errors.wageType = "Loại lương không được để trống!"
        }

        if(!wage){
            this.errors.wage = "Lương không được để trống!"
        }

        if(!name || !code || !jobType || !wage || !wageType){
            return
        }

        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await staffService.add(data) : await staffService.edit(id, data)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${this.typeModal === "add" ? "Thêm" : 'Cập nhật'} nhân viên thành công!`, 1)
            $('#close_staff').trigger('click')
            this.clearForm()
            await this.getStaff()
        }else toastUtil.error(result.body.message, 2)

    }


}

export const staffSettingStore = new StaffStore()