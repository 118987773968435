import React, {useEffect} from "react";
import "./App.scss";
import NavHeader from "./modules/layout/NavHeader";
import Sidebar from "./modules/layout/Sidebar";
import Redirect from "./router/Router";
import SignUpPage from "./modules/auth/signUp/SignUpPage";
import {observer} from "mobx-react";
import {getUrlParameter, parseJwt, setCookie} from "./common/utils/Utils";
import StorageService from "./common/service/StorageService";
import RegisterPage from "./modules/auth/register/RegisterPage";
import {useNavigate} from "react-router-dom";
import {registerStore} from "./modules/auth/register/RegisterStore";
import {sidebarStore} from "./modules/layout/SidebarStore";
import ChangeStatusRequestInFooter from "./pages/browser/components/changeStatusRequestInFooter/ChangeStatusRequests";
import {listMenu} from "./common/constants/Constants";

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let token: any = getUrlParameter('token')
        let refreshToken: any = getUrlParameter('refreshToken')
        if (token) {
            parseJwt(token)
            setCookie('x-token', token, 7)
            StorageService.setToken(token);
            navigate({pathname: window.location.pathname});
        }
        if (refreshToken) {
            StorageService.setRefreshToken(refreshToken);
        }
        if (StorageService.getToken()) {
            parseJwt()
            registerStore.idFnb = localStorage.getItem('fnbId')
        }
        if(window.location.pathname === '/'){
            localStorage.setItem("module", 'Báo cáo')
        }
        sidebarStore.showSidebar = JSON.parse(localStorage.getItem('activeSidebar') as any);
    }, [])

    if(window.location.pathname === '/'){
        localStorage.setItem("module", 'Báo cáo')
    }
    else {
        const currentPath = window.location.pathname;
        let selectedTitle;
        listMenu.forEach(menuItem => {
            if (menuItem.link !== '/' && currentPath.startsWith(menuItem.link)) {
                selectedTitle = menuItem.title;
                return
            }
        });
        if(selectedTitle) localStorage.setItem("module", selectedTitle)
    }

    sidebarStore.showSidebar = JSON.parse(localStorage.getItem('activeSidebar') as any);

    let width:any
    if(!localStorage.getItem("activeSidebar")) localStorage.setItem("activeSidebar", JSON.stringify(true))
    if(!localStorage.getItem("module")) localStorage.setItem("module", 'Báo cáo')
    if(window.location.pathname.startsWith("/phuc-vu")|| window.location.pathname === "/check-out" || window.location.pathname === "/duyet") {
        width = 0
    }
    else width =  sidebarStore.showSidebar ? 268 : 69

    if (!StorageService.getToken() && !getUrlParameter('token'))
        return (
            <SignUpPage/>
        );
    else {
        return (localStorage.getItem('fnbId') && localStorage.getItem('statusAccount') !== "BLOCK") ? <div className="d-flex flex-column justify-content-between w-100">
            <NavHeader/>
            <div className="d-flex w-100 h-auto">
                {!(window.location.pathname.startsWith("/phuc-vu") || window.location.pathname === "/check-out" || window.location.pathname === "/duyet") && <Sidebar/>}
                <div className='body-wrapper' style={{paddingBottom: '55px',  paddingLeft: `${width}px`}}>
                    <div className='main-content flex-grow-1'>
                        <Redirect/>
                    </div>
                    {(window.location.pathname !== '/duyet') ? <div className="footer" style={{width: `calc(100% - ${width}px)`}}>
                        © 2023 Ommani. All rights reserved.
                    </div> : <ChangeStatusRequestInFooter width={width} />}
                </div>
            </div>
        </div> :  <RegisterPage />
    }

}

export default observer(App);
