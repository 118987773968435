
class NumberUtil {

    public regexNumber(e: any) {
        let value = (e.currentTarget.value + '').trim()
        value = value.replace(/[^0-9]/g, '')
        return value ? Number(value) : null
    }

    public regexPercentage(e: any) {
        let value = (e.currentTarget.value + '').trim();
        // Loại bỏ bất kỳ ký tự nào không phải là số hoặc ký tự `%`
        value = value.replace(/[^0-9%]/g, '');

        // Loại bỏ tất cả các ký tự `%` sau ký tự `%` đầu tiên (giữ lại chỉ một ký tự `%`)
        const percentIndex = value.indexOf('%');
        if (percentIndex !== -1) {
            value = value.substring(0, percentIndex + 1);
        }

        // Đảm bảo giá trị không vượt quá 100%
        if (parseInt(value) > 100) {
            value = '100';
        }

        return value;
    }

    public regexNumberFormat(e: any) {
        let value = (e + '').trim()
        value = value.replace(/[^0-9]/g, '')
        return value
    }
    public regexVote(e: any) {
        let value = (e.currentTarget.value + '').trim();
        // Loại bỏ bất kỳ ký tự nào không phải là số hoặc ký tự `%`
        value = value.replace(/[^0-9%]/g, '');
        // Đảm bảo giá trị không vượt quá 5
        if (parseInt(value) > 5) {
            value = '5';
        }
        return value;
    }
    public regexNumberDecimal(e: any) {
        let value = (e.currentTarget.value + '').trim()
        value = value.replace(/[^0-9.,]/g, '')
        return value
    }

    public regexPhone(e: any) {
        let value = (e.currentTarget.value + '').trim()
        value = value.replace(/[^0-9]/g, '')
        return value
    }

    public checkPhone(value: any){
        let check = false
        check = value && value.length > 1 && !(value.startsWith('03') || value.startsWith('08') || value.startsWith('09') || value.startsWith('05') || value.startsWith('07'));
        return check
    }

    public formatCurrency(number: number) {
        try{
            return number.toFixed(2);
        }catch (e) {
            return number;
        }
    }

    public number_format(number: any, decimals?: any, dec_point?: any, thousands_sep?: any) {
        var n = number,
            c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
        var d = dec_point == undefined ? "," : dec_point;
        var t = thousands_sep == undefined ? "." : thousands_sep,
            s = n < 0 ? "-" : "";
        var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j: number = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
    }


}

export const numberUtil = new NumberUtil();
