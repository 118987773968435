import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../../modules/components/Loading";
import {sttPage} from "../../../common/utils/Utils";
import NoContent from "../../../modules/components/NoContent";
import {accountStore} from "./AccountStore";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import ModalAddUsername from "./components/addUsernameModal/AddUsernameModal";
import ModalAddEditAccount from "./components/addAccountModal/AddAccountModal";


const AccountPage = () => {

    const dataFilter: IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm tên tài khoản...',
            value: '',
            onChange: (e: any) => {
                accountStore.keyword = e
            },
            callback: async () => {
                accountStore.getAccount().then()
            }
        },
    ]
    const rankType = (type: string) => {
        switch (type) {
            case "DIRECTORS":
                return <span className="color-red fw-500">Lãnh đạo</span>;
            case "MANAGERS":
                return <span className="fw-500" style={{color: '#38383D'}}>Quản lý</span>;
            case "EMPLOYEES":
                return <span className="color-purple fw-500">Nhân viên</span>;
            default:
                return null
        }
    }
    const workType = (type: string) => {
        switch (type) {
            case "WORKING":
                return <span className="color-green fw-500">Đang làm việc</span>;
            case "WAITING":
                return <span className="color-red fw-500">Khóa</span>;
            default:
                return null
        }
    }
    useEffect(() => {
        accountStore.getAccount().then()
    }, [])

    return (
        <div className='account_page'>
            <div className='tab_filter_header'>
                <h4 className='title'>Tài khoản</h4>
                <FilterComponent
                    data={dataFilter}
                    permissionCode
                    buttonAdd={<ButtonGlobal onClick={() => {
                        accountStore.typeModal = 'add'
                        accountStore.clearForm()
                    }} dataTarget='add_username_account' text='Thêm' ico='add_icon' className='btn_add' />}
                />
            </div>
            {accountStore.isLoading ? <Loading/> :
                <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-center">STT</th>
                            <th scope="col" className="text-center">Họ và tên</th>
                            <th scope="col" className="text-center">Mã nhân viên</th>
                            <th scope="col" className="text-center">Số điện thoại</th>
                            <th scope="col" className="text-center">Chức vụ</th>
                            <th scope="col" className="text-center">Phòng ban</th>
                            <th scope="col" className="text-center">Cấp bậc</th>
                            <th scope="col" className="text-center">Cơ sở</th>
                            <th scope="col" className="text-center">Trạng thái</th>
                            <th scope="col" className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accountStore.listAccount.length < 1 ?
                            <NoContent colSpan={10}/> : accountStore.listAccount.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{sttPage(accountStore.page, i)}</td>
                                        <td className="text-center">{item?.name}</td>
                                        <td className="text-center">{item?.code}</td>
                                        <td className="text-center">{item?.phone}</td>
                                        <td className="text-center">{item?.roles?.map((role: any, index: number) => <div>{index !== 0 ? <span key={index}>{`${role.name}`}</span> : <span key={index}>{`${role.name}, `}</span>}</div>)}</td>
                                        <td className="text-center">{item?.departments?.map((department: any, index: number) => <div>{index !== 0 ? <span key={index}>{`${department.name}`}</span> : <span key={index}>{`${department.name}, `}</span>}</div>)}</td>
                                        <td className="text-center">{rankType(item?.rank)}</td>
                                        <td className="text-center">{item?.establishment?.name}</td>
                                        <td className="text-center">{workType(item?.status)}</td>
                                        <td className="text-center">
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target={"#add_edit_account"}
                                                    onClick={async () => {
                                                        accountStore.typeModal = 'edit'
                                                        accountStore.id = item?.id
                                                        await accountStore.getDetailAccount(item?.id)
                                                    }}>
                                                <img src="/assets/ico/action/edit_green.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Chỉnh sửa"/>
                                            </button>
                                            {item?.status === "WORKING" ? <button className="btn p-0"
                                                    onClick={async () => {
                                                        await accountStore.changeStatusRequest(
                                                            item?.id,
                                                            "BLOCK"
                                                        )
                                                    }}>
                                                <img src="/assets/ico/action/lock.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Khóa"/>
                                            </button> :
                                                <button className="btn p-0"
                                                        onClick={async () => {
                                                            await accountStore.changeStatusRequest(
                                                                item?.id,
                                                                "ACTIVE"
                                                            )
                                                        }}>
                                                    <img src="/assets/ico/action/unlock.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Khóa"/>
                                                </button>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
            <ModalAddUsername />
            <ModalAddEditAccount />
        </div>
    );
}

export default observer(AccountPage);
