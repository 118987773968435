import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {voucherService} from "./VoucherService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";


class VoucherStore {
    id: any = ''
    type: string = 'add'
    typeModal: string = ''
    searchNameCode: string = ''
    searchTypeVoucher: any = null
    searchTypePromotion: any = null
    searchStatusPromotion: any = null
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listPromotion: any[] = []
    listVoucherByPromotionId: any[] = []
    dataRequestValueVoucher: any = {
        name: "",
        code: "",
        startTime: "",
        endTime: "",
        type: "",
        discountType: "DISCOUNT",
        proviso: {
            type: "",
            minOrderValue: "",
            percentage: {
                maxDiscountAmount: "",
                value: ""
            },
            products: [],
            buyMore: [],
            specificAmount: {value: ""},
        },
        amount: ""
    }

    errors: any = {
        name: "",
        code: "",
        time: "",
        type: "",
        discountType: "",
        proviso: {
            type: "",
            minOrderValue: "",
            maxDiscountAmount: "",
            discountValue: "",
            products: "",
        },
        amount: ""
    }
    resetError() {
        this.errors = {
            name: "",
            code: "",
            time: "",
            type: "",
            discountType: "",
            proviso: {
                type: "",
                minOrderValue: "",
                maxDiscountAmount: "",
                discountValue: "",
                products: "",
            },
            amount: ""
        }
    }
    resetFormValue() {
        this.dataRequestValueVoucher = {
            name: "",
            code: "",
            startTime: "",
            endTime: "",
            type: "VALUE",
            discountType: "DISCOUNT",
            proviso: {
                type: "SPECIFIC_AMOUNT",
                minOrderValue: "",
                percentage: {
                    maxDiscountAmount: "",
                    value: ""
                },
                specificAmount: {value: ""},
            },
            amount: ""
        }
    }
    resetFormProduct() {
        this.dataRequestValueVoucher = {
            name: "",
            code: "",
            startTime: "",
            endTime: "",
            type: "GIVE_PRODUCT",
            discountType: "DISCOUNT",
            proviso: {
                products: [],
            },
            amount: ""
        }
    }
    resetFormCombo() {
        this.dataRequestValueVoucher = {
            name: "",
            code: "",
            startTime: "",
            endTime: "",
            type: "COMBO",
            discountType: "DISCOUNT",
            proviso: {
                type: "PERCENTAGE",
                products: [],
                percentage: {
                    maxDiscountAmount: "",
                    value: ""
                },
                specificAmount: {value: ""},
            },
            amount: ""
        }
    }
    resetFormProducts() {
        this.dataRequestValueVoucher = {
            name: "",
            code: "",
            startTime: "",
            endTime: "",
            type: "PRODUCT",
            discountType: "DISCOUNT",
            proviso: {
                type: "SPECIFIC_AMOUNT",
                products: [],
                buyMore: [{
                    from: "",
                    to: "",
                    discount: "",
                }]
            },
            amount: ""
        }
    }
    resetFormBirthday() {
        this.dataRequestValueVoucher = {
            type: "BIRTHDAY",
            discountType: 'DISCOUNT',
            name: 'Voucher tặng sinh nhật',
            startTime: new Date().getTime(),
            endTime: 4127509558000, //2100
            proviso: {
                type: "SPECIFIC_AMOUNT",
                birthdayDiscount: "FOR_MONTH",
                percentage: {value: ""},
                specificAmount: {value: ""},
            },
            amount: '1'
        }
    }
    validateFormCombo(){
        if (voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
            delete voucherStore.dataRequestValueVoucher.proviso.percentage;
        } else {
            delete voucherStore.dataRequestValueVoucher.proviso.specificAmount;
        }
        if(!voucherStore.dataRequestValueVoucher.name) {
            voucherStore.errors.name = "Tên chương trình không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.code) {
            voucherStore.errors.code = "Mã voucher (4 kí tự) không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.startTime || !voucherStore.dataRequestValueVoucher.endTime) {
            voucherStore.errors.time = "Thời gian chạy combo không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.amount) {
            voucherStore.errors.amount = "Số voucher phát hành không được để trống"
            return false
        }
        else if(voucherStore.dataRequestValueVoucher.proviso?.products?.length <= 1) {
            voucherStore.errors.proviso.products = "Vui lòng chọn từ 2 sản phẩm"
            return false
        }
        else return true
    }
    validateFormValue(){
        if (voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
            delete voucherStore.dataRequestValueVoucher.proviso.percentage;
        } else {
            delete voucherStore.dataRequestValueVoucher.proviso.specificAmount;
        }
        if(!voucherStore.dataRequestValueVoucher.name) {
            voucherStore.errors.name = "Tên chương trình không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.code) {
            voucherStore.errors.code = "Mã voucher (4 kí tự) không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.startTime || !voucherStore.dataRequestValueVoucher.endTime) {
            voucherStore.errors.time = "Thời gian chạy combo không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher.proviso?.percentage?.value) {
            voucherStore.errors.discountValue = "Mức giảm không được để trống"
            return false
        }
        else if(voucherStore.dataRequestValueVoucher?.proviso?.type !== "SPECIFIC_AMOUNT" && !voucherStore.dataRequestValueVoucher.proviso.percentage.maxDiscountAmount) {
            voucherStore.errors.proviso.maxDiscountAmount = "Giá trị tối đa của voucher không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.proviso.minOrderValue) {
            voucherStore.errors.proviso.minOrderValue = "Giá trị đơn hàng tối thiểu không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.amount) {
            voucherStore.errors.amount = "Số voucher phát hành không được để trống"
            return false
        }
        else return true
    }
    validateFormProduct(){
        if(!voucherStore.dataRequestValueVoucher.name) {
            voucherStore.errors.name = "Tên chương trình không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.code) {
            voucherStore.errors.code = "Mã voucher (4 kí tự) không được để trống"
            return false
        }

        else if(!voucherStore.dataRequestValueVoucher.amount) {
            voucherStore.errors.amount = "Số voucher phát hành không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.startTime || !voucherStore.dataRequestValueVoucher.endTime) {
            voucherStore.errors.time = "Thời gian chạy combo không được để trống"
            return false
        }
        else if(voucherStore.dataRequestValueVoucher.proviso?.products?.length <= 0) {
            voucherStore.errors.proviso.products = "Vui lòng chọn sản phẩm"
            return false
        }
        else return true
    }
    validateFormProducts(){
        if(!voucherStore.dataRequestValueVoucher.name) {
            voucherStore.errors.name = "Tên chương trình không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.code) {
            voucherStore.errors.code = "Mã voucher (4 kí tự) không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.startTime || !voucherStore.dataRequestValueVoucher.endTime) {
            voucherStore.errors.time = "Thời gian chạy combo không được để trống"
            return false
        }
        else if(!voucherStore.dataRequestValueVoucher.amount) {
            voucherStore.errors.amount = "Số voucher phát hành không được để trống"
            return false
        }
        else return true
    }
    validateFormBirthday(){
        if (voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
            delete voucherStore.dataRequestValueVoucher.proviso.percentage;
        } else {
            delete voucherStore.dataRequestValueVoucher.proviso.specificAmount;
        }
        if(!voucherStore.dataRequestValueVoucher.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher.proviso?.percentage?.value) {
            voucherStore.errors.discountValue = "Mức giảm không được để trống"
            return false
        }
        else return true
    }

    constructor() {
        makeAutoObservable(this)
    }
    async getPromotions(size?: any) {
        this.isLoading = true
        const result = await voucherService.getPromotions(size)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listPromotion = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getVoucherByPromotionId(id: any, size?: any) {
        this.isLoading = true
        const result = await voucherService.getVoucherByPromotionId(id , size)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listVoucherByPromotionId = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetail(id: any) {
        const result = await voucherService.getDetailPromotion(id)
        if (result.status === HttpStatusCode.OK) {
            voucherStore.dataRequestValueVoucher = {
                ...result.body,
                proviso: {
                    ...result.body.proviso,
                    products : result.body.proviso?.products?.map((item: any) => {
                        return {
                            value: item?.id,
                            label: item?.name,
                            price: item?.price
                        }
                    })
                }
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }

    async delete() {
        voucherStore.isLoadingBt = true
        const result = await voucherService.delete(voucherStore.id)
        voucherStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa chương trình khuyến mãi thành công`, 3)
            $('#popup_confirm').trigger('click')
            await voucherStore.getPromotions()
        } else {
            toastUtil.error(result.body.message, 6)
        }
    }

    async addEditPromotion() {
        if(this.dataRequestValueVoucher.type === "COMBO" && !this.validateFormCombo()) {
            return
        }
        else if(this.dataRequestValueVoucher.type === "VALUE" && !this.validateFormValue()){
            return
        }
        else if(this.dataRequestValueVoucher.type === "GIVE_PRODUCT" && !this.validateFormProduct()){
            return
        }
        else if(this.dataRequestValueVoucher.type === "PRODUCT" && !this.validateFormProducts()){
            return
        }
        else if(this.dataRequestValueVoucher.type === "BIRTHDAY" && !this.validateFormBirthday()){
            return
        }
        if(this.dataRequestValueVoucher.type !== "BIRTHDAY" && this.dataRequestValueVoucher.code.length !== 4) {
            toastUtil.warning('Mã Voucher phải có 4 kí tự')
            return
        }
        const newData = voucherStore.dataRequestValueVoucher
        const data =  JSON.parse(JSON.stringify(newData))
        if(data?.proviso?.products?.length  > 0 && this.dataRequestValueVoucher.type === "COMBO") {
            data.proviso.products =  data?.proviso?.products?.map((item : any) => {
                return {
                    id: item?.value,
                    name: item?.label,
                    price: item?.price
                }
            })
        }
        else if(data?.proviso?.products?.length  > 0 && (this.dataRequestValueVoucher.type === "GIVE_PRODUCT" || this.dataRequestValueVoucher.type === "PRODUCT")) {
            data.proviso.products =  data?.proviso?.products?.map((item : any) => {
                return {
                    id: item?.value,
                    name: item?.label,
                }
            })
        }
        this.isLoadingBt = true
        const result = ( this.type === 'add' ||  this.type === 'copy') ? await voucherService.addPromotion(data) : await voucherService.updatePromotion(this.id , data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.type === 'add' ? 'Thêm chương trình khuyến mãi thành công' : 'Cập nhật chương trình khuyến mãi thành công'}`, 1)
            $('#value_voucher').trigger('click')
            $('#product_voucher').trigger('click')
            $('#combo_voucher').trigger('click')
            $('#discount_voucher').trigger('click')
            $('#birthday_voucher').trigger('click')
            await this.getPromotions()
        } else {
            if(typeof result.body.message === 'object' && Object.keys(result.body.message).length > 0) toastUtil.error(result.body.message[Object.keys(result.body.message)?.[0]], 2)
            else toastUtil.error(result.body.message, 4)
        }
    }



}

export const voucherStore = new VoucherStore()