import React, {useMemo, useRef, useState} from 'react';
import {observer} from "mobx-react";
import './modalAddProductVoucher.scss'
import {Select , Spin } from 'antd';
import debounce from 'lodash/debounce';
import type { SelectProps } from 'antd';
import {voucherService} from "../../../VoucherService";
import HttpStatusCode from "../../../../../../common/constants/HttpErrorCode";
import {voucherStore} from "../../../VoucherStore";
export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
}
function DebounceSelect<
    ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
    >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<ValueType[]>([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            listItemHeight={10} listHeight={250}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}
interface UserValue {
    label: string;
    value: string;
}
// @ts-ignore
async function fetchUserList(username: string): Promise<UserValue[]> {
    const result = await voucherService.getListProduct(username)
    if (result.status === HttpStatusCode.OK) {
        return result.body.data.map((item: any) => {
            return {
                value: String(item?.id) ,
                label: String(item?.name),
            }
        })
    }
}
const ModalAddProductVoucher= () => {
    return (
        <div className='modal fade' id='add_product_voucher__discount' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header'>
                        <div className='value_voucher__title'>CHỌN SẢN PHẨM</div>
                    </div>
                    <div className="modal-body">
                        <div className='w-100 d-flex'>
                            <DebounceSelect
                                mode="multiple"
                                value={voucherStore.dataRequestValueVoucher?.proviso?.products}
                                placeholder="Chọn"
                                fetchOptions={fetchUserList}
                                onChange={newValue => {
                                    voucherStore.dataRequestValueVoucher.proviso.products = newValue as UserValue[];
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(ModalAddProductVoucher);

