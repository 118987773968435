import {getRequest, IApiResponse, putRequest} from "../../common/helpers/RequestHelper";
import {staffStore} from "./StaffStore";

class StaffService{
    public getStaff(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff${staffStore.keyword ? `?keyword=${staffStore.keyword}` : ''}`)
    }
    public getStaffById(id: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/staff/${id}`)
    }
    public updateInfoStaff(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/staff/${id}`, data)
    }
}
export const staffService = new StaffService()