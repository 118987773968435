import React from 'react';
import {observer} from "mobx-react";
import CheckTheDish from "./CheckTheDish";


const CheckBeforeEatingPage = () => {

    return (
        <div>
            <CheckTheDish title="Kiểm trước khi ăn"/>
        </div>
    )

}

export default observer(CheckBeforeEatingPage);

