import React from 'react';
import './TabRedirect.scss'
import {NavLink} from "react-router-dom";
interface IProps {
    tab?: string
    redirect?: string
    subTab?: string
}

const TabRedirect = ({tab = '', redirect , subTab}: IProps) => {
    return (
        <div className='tab_redirect__header'>
            {redirect ? <NavLink to={redirect}  className='tab_redirect__subtitle'>{tab}</NavLink> : <span className='tab_redirect__subtitle'>{tab}</span>}
            {
                subTab && <img src="/assets/ico/move-right.svg" alt=""/>
            }
        </div>
    )

}

export default TabRedirect;
