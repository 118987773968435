import React from 'react';

interface IProps {
    id?: string,
    isLoading?: any
    action: any
    titlePopup?: string
    titleConfirm?: string

}

const PopupConfirm = ({
                          action,
                          id,
                          isLoading,
                          titlePopup = "Xác nhận xóa",
                          titleConfirm = "Bạn có chắc chắn muốn xóa?"
                      }: IProps) => {

    return (
        <div className="modal fade" id={id ?? 'popup_confirm'} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="d-flex justify-content-center align-items-center icon">
                        <img src="/assets/ico/icon-remove.svg" alt=""/>
                    </div>
                    <h2 className="text-center">{titlePopup}</h2>
                    <p className="text-center">{titleConfirm}</p>
                    <div className="d-flex action align-items-center justify-content-center">
                        <button className="btn btn-cancel" id="close_confirm" data-bs-dismiss="modal">Hủy</button>
                        <button className="btn btn-confirm" onClick={() => action()} disabled={isLoading}>Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PopupConfirm;