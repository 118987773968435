import {makeAutoObservable} from "mobx";
import {costService} from "./CostService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {revenueReportStore} from "../../summaryReport/revenueReport/RevenueReportStore";



class CostStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    searchEstablishmentId: any = null
    searchMonth: any = null
    establishmentName: any = ''
    searchYear: any = null
    dataCostLineChart: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    dataRevenueLineChart: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    dataMonth = [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
        {value: 4, label: '4'},
        {value: 5, label: '5'},
        {value: 6, label: '6'},
        {value: 7, label: '7'},
        {value: 8, label: '8'},
        {value: 9, label: '9'},
        {value: 10, label: '10'},
        {value: 11, label: '11'},
        {value: 12, label: '12'},
    ]
    listCost: any[] = []
    listRevenue: any[] = []
    listRestaurant: any[] = []
    dataRequest: any = {
        establishmentId: null,
        month: null,
        year: null,
        foodCost: "",
        drinkCost: "",
        otherGoodsCost: "",
        destructionCost: "",
        instrumentAllotmentCost: "",

        salaryFee: "",
        socialInsuranceFee: "",
        bscbonusFee: "",
        extraSalaryFee: "",
        staffmealFee: "",

        rentalCost: "",
        securityCost: "",
        electricityCost: "",
        phoneBill: "",
        internetCost: "",
        gasBill: "",
        coalCost: "",
        waterCost: "",
        ccdcFee: "",
        ccdcPeriodFee: "",
        repairCost: "",
        maintenanceCost: "",
        serviceFee: "",
        marketingCost: "",
        transportationCost: "",
        otherCost: "",
    }
    errors : any = {
        establishmentId: "",
        month: "",
        year: "",
    }
    dataColumns: any = []
    dataColumnRevenue: any = []
    constructor() {
        makeAutoObservable(this)
    }
    async resetDataColumn(){
        costStore.dataColumns = [{
            index: '',
            type: "COS",
            key: 'cos'
        },{
            index: '1',
            type: "- Giá vốn đồ ăn",
            key: "foodCost",
        },{
            index: '2',
            key: 'drinkCost',
            type: "- Giá vốn đồ uống",
        },{
            index: '3',
            key: 'otherGoodsCost',
            type: "- Giá vốn hàng bán khác",
        },{
            index: '4',
            key: 'destructionCost',
            type: "- Chi phí hỏng, huỷ NVL",
        },{
            index: '5',
            key: 'instrumentAllotmentCost',
            type: "- Chi phí DC phân bổ",
        },
            {
                index: '',
                type: "COL",
                key: 'col'
            },{
                index: '1',
                key: 'salaryFee',
                type: "- Chi phí lương nhân viên",
            },{
                index: '2',
                key: 'socialInsuranceFee',
                type: "- Chi phí BHXH, BHYT, BHTN",
            },{
                index: '3',
                key: 'bscbonusFee',
                type: "- Chi tiền thưởng BSC",
            },{
                index: '4',
                key: 'extraSalaryFee',
                type: "- Chi tháng lương thứ 13",
            },{
                index: '5',
                key: 'staffmealFee',
                type: "- Chi phí cơm nhân viên (ăn ca)",
            },
            {
                index: '',
                type: "SEMI",
                key: 'semi'
            },{
                index: '1',
                key: 'rentalCost',
                type: "- Chi phí thuê mặt bằng",
            },{
                index: '2',
                key: 'securityCost',
                type: "- Chi phí dịch vụ bảo vệ",
            },{
                index: '3',
                key: 'electricityCost',
                type: "- Chi phí tiền điện",
            },{
                index: '4',
                key: 'phoneBill',
                type: "- Chi phí điện thoại",
            },{
                index: '5',
                key: 'internetCost',
                type: "- Chi phí Internet",
            },
            {
                index: '6',
                key: 'gasBill',
                type: "- Chi phí tiền gas",
            },{
                index: '7',
                key: 'internetCost',
                type: "- Chi phí tiền than 1.3",
            },{
                index: '8',
                key: 'coalCost',
                type: "- Chi phí tiền nước máy",
            },{
                index: '9',
                key: 'ccdcFee',
                type: "- Chi phí CCDC, VL (phân bổ) ngắn hạn = 12 tháng",
            },{
                index: '10',
                key: 'ccdcPeriodFee',
                type: "- Chi phí CCDC , VL dùng ngay trong kỳ",
            },{
                index: '11',
                key: 'repairCost',
                type: "- Chi phí sửa chữa nhà hàng",
            },{
                index: '12',
                key: 'maintenanceCost',
                type: "- Chi phí bảo hành , bảo trì hệ thống",
            },{
                index: '13',
                key: 'serviceFee',
                type: "- Chi phí dịch vụ",
            },{
                index: '14',
                key: 'marketingCost',
                type: "- Chi phí Marketing",
            },{
                index: '15',
                key: 'transportationCost',
                type: "- Chi phí vận chuyển",
            },{
                index: '16',
                key: 'otherCost',
                type: "- Chi phí khác",
            }
        ]
    }
    async resetDataColumnRevenue(){
        costStore.dataColumnRevenue = []
    }

    clearForm() {
        this.dataRequest = {
            establishmentId: JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear().toString(),

            foodCost: "",
            drinkCost: "",
            otherGoodsCost: "",
            destructionCost: "",
            instrumentAllotmentCost: "",

            salaryFee: "",
            socialInsuranceFee: "",
            bscbonusFee: "",
            extraSalaryFee: "",
            staffmealFee: "",

            rentalCost: "",
            securityCost: "",
            electricityCost: "",
            phoneBill: "",
            internetCost: "",
            gasBill: "",
            coalCost: "",
            waterCost: "",
            ccdcFee: "",
            ccdcPeriodFee: "",
            repairCost: "",
            maintenanceCost: "",
            serviceFee: "",
            marketingCost: "",
            transportationCost: "",
            otherCost: "",
        }
        this.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
    }
    async checkValidateModalFilter() {
        if(!costStore.dataRequest.establishmentId || !costStore.dataRequest.year || !costStore.dataRequest.month) {
            toastUtil.warning('Vui lòng điền đầy đủ thông tin của form !')
            return false
        }
        else {
            costStore.searchEstablishmentId = costStore.dataRequest.establishmentId
            costStore.searchYear = costStore.dataRequest.year
            costStore.searchMonth = costStore.dataRequest.month
            await costStore.getCostByMonth()
            return true
        }
    }
    async getListRestaurant() {
        this.isLoading = true
        const result = await costService.getListRestaurant()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getListRevenue(){
        costStore.isLoading = true
        await costStore.resetDataColumnRevenue()
        const result = await costService.getListRevenue()
        costStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
                costStore.listRevenue = result.body.map((item: any) => {
                    return {
                        address: item.businessEstablishment?.name,
                        code: item.businessEstablishment?.code,
                        data: item.data.map((i: any) => {
                            return {
                                month: i.month,
                                revenue: i.revenue
                            }
                        })
                    }
                })

            costStore.dataColumnRevenue = costStore.listRevenue.map((itemRe: any) => {
                let dataRevenue = {}
                itemRe.data.forEach((item: any) => {
                    // @ts-ignore
                    dataRevenue[`cost_${item.month}`] = item.revenue
                })
                return {
                    code: itemRe.code,
                    resource: "-",
                    address: itemRe.address,
                    ...dataRevenue
                }
            })
        }
    }
    findMax(arr: any) {
        if (arr.length === 0) {
            return undefined; // Trả về undefined nếu mảng trống
        }
        return Math.max(...arr);
    }
    async getListCost() {
        costStore.isLoading = true
        await costStore.resetDataColumn()
        const result = await costService.getListCost()
        costStore.isLoading = false
        let dataTmpCostLineChart = []
        let dataTmpRevenueLineChart = []
        if (result.status === HttpStatusCode.OK) {
            if(costStore.searchEstablishmentId) {
                costStore.listCost = result.body?.data.map((item: any) => {
                    return {
                        month: item.month,
                        children: {
                            ...item
                        }
                    }
                })
                dataTmpCostLineChart = result.body?.data.map((item: any) => {
                    return {
                        month: item.month,
                        sum: item.totalCost
                    }
                })
                dataTmpRevenueLineChart = result.body?.data.map((item: any) => {
                    return {
                        month: item.month,
                        sum: item.revenue
                    }
                })
            }
            else {
                costStore.listCost = result.body?.map((item: any) => {
                    return {
                        month: item.month,
                        children: {
                            ...item
                        }
                    }
                })
                dataTmpCostLineChart = result.body?.map((item: any) => {
                    return {
                        month: item.month,
                        sum: item.totalCost
                    }
                })
                dataTmpRevenueLineChart = result.body?.map((item: any) => {
                    return {
                        month: item.month,
                        sum: item.revenue
                    }
                })
            }

            costStore.dataCostLineChart =  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            costStore.dataRevenueLineChart =  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

            dataTmpCostLineChart.forEach((item: any) => {
                const monthIndex = parseInt(item.month) - 1; // Chuyển đổi tháng thành chỉ số mảng (0-11)
                costStore.dataCostLineChart[monthIndex] = parseInt(item.sum);
            });
            dataTmpRevenueLineChart.forEach((item: any) => {
                const monthIndex = parseInt(item.month) - 1; // Chuyển đổi tháng thành chỉ số mảng (0-11)
                costStore.dataRevenueLineChart[monthIndex] = parseInt(item.sum);
            });
            revenueReportStore.dataRevenueLineChartMax = this.findMax(costStore.dataRevenueLineChart)

            costStore.dataColumns.forEach((item: any) => {
                costStore.listCost.forEach((i: any) => {
                    if(i?.children?.[item.key]) {
                        item[`cost_${i.month}`] = i.children[item.key]
                    }
                    if(item.type === "COS") {
                        item[`cost_${i.month}`] = i.children.foodCost + i.children.drinkCost + i.children.otherGoodsCost
                        + i.children.destructionCost + i.children.instrumentAllotmentCost
                    }
                    else if(item.type === "COL") {
                        item[`cost_${i.month}`] = i.children.salaryFee + i.children.socialInsuranceFee + i.children.extraSalaryFee
                            + i.children.staffmealFee + i.children.bscbonusFee
                    }
                    else if(item.type === "SEMI") {
                        item[`cost_${i.month}`] = i.children.rentalCost + i.children.securityCost + i.children.electricityCost
                            + i.children.phoneBill + i.children.internetCost + i.children.gasBill + i.children.coalCost + i.children.waterCost
                            + i.children.ccdcFee + i.children.ccdcPeriodFee  + i.children.repairCost + i.children.maintenanceCost + i.children.serviceFee
                            + i.children.marketingCost + i.children.transportationCost + i.children.otherCost
                    }
                } )
            })
        }
    }
    async getCostByMonth() {
        costStore.isLoading = true
        const result = await costService.getListCost()
        costStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            if(result.body.data?.length > 0){
                costStore.dataRequest = {
                    ...result.body.data?.[0],
                    establishmentId: result.body.data?.[0]?.establishment?.id
                }
            }
            else costStore.dataRequest = {
                establishmentId:  costStore.dataRequest?.establishmentId,
                month: costStore.dataRequest?.month,
                year: costStore.dataRequest?.year,
                foodCost: "",
                drinkCost: "",
                otherGoodsCost: "",
                destructionCost: "",
                instrumentAllotmentCost: "",

                salaryFee: "",
                socialInsuranceFee: "",
                bscbonusFee: "",
                extraSalaryFee: "",
                staffmealFee: "",

                rentalCost: "",
                securityCost: "",
                electricityCost: "",
                phoneBill: "",
                internetCost: "",
                gasBill: "",
                coalCost: "",
                waterCost: "",
                ccdcFee: "",
                ccdcPeriodFee: "",
                repairCost: "",
                maintenanceCost: "",
                serviceFee: "",
                marketingCost: "",
                transportationCost: "",
                otherCost: "",
            }
        }
    }

    async updateCost() {
        this.isLoadingBt = true
        const result =  await costService.updateCost(costStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`Cập nhật thông tin chi phí thành công`, 1)
            $('#add_edit_finance').trigger('click')
            this.clearForm()
            costStore.searchMonth = null
            await costStore.getListCost()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const costStore = new CostStore()