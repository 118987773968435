import React from 'react';
import {observer} from "mobx-react";
import './ModalSelectRoom.scss'
import ModalSelectTable from "../modalSelectTable/ModalSelectTable";
import {orderStore} from "../../OrderStore";
import Loading from "../../../../modules/components/Loading";
import $ from "jquery";
const ModalSelectRoom = () => {
    const closeModal = () => {
        $('#select_room_order').trigger('click')
        localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
        window.location.href = '/phuc-vu/chon-mon';
    }
    return (
        <>
            <div className='modal fade' id='select_room_order' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_room__container'>
                            {orderStore.typeModal !== 'edit' && <div className='w-100'>
                                <button data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target={orderStore.dataRequest.type !== "ORDER" ? '#add_service' : '#add_user_infor_order'} className='button_move_left'>
                                    <img src="/assets/ico/action/move-left.svg" alt=""/>
                                </button>
                            </div>}

                            <h1 className='modal_select_room__title'>
                                CHỌN PHÒNG {(orderStore.typeModal !== 'edit' && orderStore.dataRequest.establishmentName) && <span>{`(${orderStore.dataRequest.establishmentName})`}</span>}
                            </h1>
                            {orderStore.isLoading ? <Loading /> : <div className='modal_select_room__cardContainer'>
                                {
                                    orderStore.listRoom.map((item: any, index: number) => {
                                        if(item.status === "ACTIVE") return (
                                             <button
                                                 onClick={async () => {
                                                     orderStore.dataRequest.roomId = item?.value
                                                     if(orderStore.dataRequest.establishmentId && orderStore.dataRequest.roomId) {
                                                         await orderStore.getListTable().then()
                                                     }
                                                 }}
                                                 key={index} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#select_table' className='modal_select_room__card'>
                                                 <div className='modal_select_room__cardTitle'>
                                                     {item.label}
                                                 </div>
                                             </button>
                                         )
                                        else return (
                                            <button key={index} disabled className='modal_room_full__card'>
                                                <div className='modal_select_room__cardTitle'>
                                                    {item.label}
                                                </div>
                                            </button>
                                        )
                                    })
                                }
                        </div>}
                            <div className='w-100 mt-5 d-flex justify-content-end align-items-center'>
                                <button onClick={() => closeModal()} className='btn_add_service px-5 py-3'>Tiếp theo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSelectTable />
        </>
    )

}

export default observer(ModalSelectRoom);
