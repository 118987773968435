import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {importExportService} from "./ImportExportService";
import {suppliesStore} from "../../setting/supplies/SuppliesStore";
import $ from 'jquery'
import {purchaseStore} from "../purchase/PurchaseStore";
import {purchaseService} from "../purchase/PurchaseService";

export enum ITypeSupplies {
    packaging = 'packaging',
    additive = "additive",
    materials = "materials",
    devices = "devices"
}


class ImportExportStore {
    id: any = ''
    typeSupplies: any = ''
    materialId: any = null
    typeImportExport: any = ''
    typeImport: any = ''
    keyword: string = ''
    searchWarehouse: any = null
    page: number = 0
    use_material: any = null
    warehouseId: any = null
    isToMainWarehouse: boolean = false
    isChangeStatusWarehouse: boolean = false
    isErrorsConfirmSupplies: boolean = false
    warehouseName: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listSupplies: any[] = []
    listImportExportRequest: any[] = []
    listWarehouseByUser: any[] = []
    errors: any = {
        code: '',
        warehouseId: '',
        materialId: '',
    }
    dataRequest: any = {
        code: '',
        timeRequest : "",
        type: "",
        purpose: '',
        processUserId: "",
        items: [],
        toMainWarehouse: false
    }
    itemsApprovedRequest: any = []
    dataRequestChangeStatus: any = {
        ids: [],
        status: ""
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearError(){
        this.errors = {
            code: '',
            timeRequest: '',
            warehouseId: '',
            materialId: '',
        }
    }
    clearForm() {
        this.clearError()
        this.dataRequest = {
            code: '',
            timeRequest: "",
            type: "",
            purpose: '',
            processUserId: "",
            items: [],
            toMainWarehouse: false
        }
    }

    returnName(){
        switch (importExportStore.typeSupplies) {
            case ITypeSupplies.materials:
                return "nguyên liệu"
            case ITypeSupplies.additive:
                return "phụ gia"
            case ITypeSupplies.packaging:
                return "bao bì"
            case ITypeSupplies.devices:
                return "thiết bị"
        }
    }


    async getImpExpSupplies() {
        this.isLoading = true
        const result = await importExportService.getImpExpSupplies()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listSupplies = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }


    async getImpExpRequest() {
        this.isLoading = true
        const result = await importExportService.getImpExpRequest()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listImportExportRequest = result.body.data
        }
    }


    async getDetailRequest(id: number) {
        const result = await importExportService.getDetailRequest(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
            this.itemsApprovedRequest = result.body.items?.map((item: any) => {
                 return {
                     supplies: item?.supplies,
                     qty: item?.qty,
                     note:  item?.note,
                     price: "",
                     expiryDate: "",
                 }
            })
            this.isToMainWarehouse = result.body.mainWarehouse
        }
    }

    async changeUseMaterial(value: any){
        suppliesStore.type = value
        importExportStore.use_material = value
        await suppliesStore.getSupplies()
    }

    async addSupplies(){

        let value: any = {}

        let isError: boolean = false
        suppliesStore.listSupplies && suppliesStore.listSupplies.map((item: any) => {
            importExportStore.dataRequest.items && importExportStore.dataRequest.items.map((val: any) => {
                if (val.id == this.materialId) {
                    isError = true
                }
            })
            if (item.id == this.materialId) {
                value = item
            }
        })
        if (this.dataRequest.toMainWarehouse && !this.warehouseId) {
            toastUtil.warning('Vui lòng chọn kho!', 1)
            return
        }
        if (!this.materialId) {
            toastUtil.warning('Vui lòng chọn vật tư!', 1)
            return
        }
        if (isError) {
            toastUtil.warning('Vật tư đã tồn tại!', 2)
            return
        }
        let data = {
            id: value.id,
            name: value.name,
            code: value.code,
            unit: value.unit,
            qty: "",
            price: "",
            expiryDate: "",
            warehouseId: this.warehouseId,
            warehouseName: this.warehouseName,
            note: ""
        }
        importExportStore.dataRequest.items.unshift(data)
        suppliesStore.listSupplies = []
        this.use_material = null
        this.materialId = null
        this.warehouseId = null
        this.warehouseName = ""
    }

    async sendImportExport() {
        let {code, timeRequest, items, purpose, toMainWarehouse} = this.dataRequest
        let arrSupplies: any = []
        if(!toMainWarehouse && this.typeImportExport === "IMPORT") {
            if(items.some(
                (item: any) => !item.price ||  !item.expiryDate
            )) {
                this.isErrorsConfirmSupplies = true
                toastUtil.warning('Vui lòng nhập đầy đủ đơn giá và hạn sử dụng vật tư !', 1)
                return
            }
            else  this.isErrorsConfirmSupplies = false
        }
        items && items.map((item: any) => {
            arrSupplies.push({
                id: item.id,
                qty: item.qty ? Number(item.qty) : null,
                note: item.note,
                price: item.price ? Number(item.price) : null,
                expiryDate: item.expiryDate,
                warehouseId: item.warehouseId
            })
        })

        if (!code) {
            this.errors.code = `Mã yêu cầu ${this.typeImportExport === 'export' ? 'xuất' : 'nhập'} kho không được để trống!`
            return
        }
        if (!timeRequest) {
            this.errors.timeRequest = `Ngày gửi yêu cầu không được để trống!`
            return
        }

        if (arrSupplies.length < 1) {
            this.errors.materialId = 'Vật tư không được để trống!'
            return
        }

        let data = {
            code: code,
            timeRequest: timeRequest,
            type: this.typeImportExport === 'export' ? "EXPORT" : "IMPORT",
            items: arrSupplies,
            purpose: purpose,
            toMainWarehouse: toMainWarehouse
        }
        this.isLoadingBt = true
        const result = await importExportService.sendImpExpRequest(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            if(importExportStore.isChangeStatusWarehouse) {
                importExportStore.dataRequestChangeStatus = {
                    ids: [purchaseStore.id],
                    status: "WAREHOUSED"
                }
                const result = await purchaseService.changeStatusRequest(importExportStore.dataRequestChangeStatus)
                if (result.status === HttpStatusCode.OK) {
                    importExportStore.dataRequestChangeStatus = {
                        ids: [],
                        status: ""
                    }
                    importExportStore.isChangeStatusWarehouse = false
                    $('#add_import_buy_yourself').trigger('click')
                    toastUtil.success(`${this.typeImportExport === 'export' ? 'Xuất' : 'Nhập'} kho vật tư thành công!`)
                    await purchaseStore.getBuyRequest()
                    this.clearForm()
                } else toastUtil.error(result.body.message)
            }
            else {
                toastUtil.success(`Tạo đề nghị ${this.typeImportExport === 'export' ? 'xuất' : 'nhập'} kho vật tư thành công!`)
                $('#add_import_export_request').trigger('click')
                $('#add_import_buy_yourself').trigger('click')
                this.clearForm()
                await importExportStore.getImpExpRequest()
            }
        } else toastUtil.error(result.body.message)
    }
    async comfirmApprovedRequest() {
        if(this.itemsApprovedRequest.some(
            (item: any) => !item.price ||  !item.expiryDate
        )) {
            this.isErrorsConfirmSupplies = true
            toastUtil.warning('Vui lòng nhập đầy đủ đơn giá và hạn sử dụng vật tư !', 1)
            return
        }
        else  this.isErrorsConfirmSupplies = false
        let data = this.itemsApprovedRequest.map((item: any) => {
            return {
                id: item?.supplies?.id,
                expiryDate:item.expiryDate,
                price: item.price,
            }
        })
        this.isLoadingBt = true
        const result = await importExportService.confirmApprovedRequest(this.id, {
            items: data
        })
        if (result.status === HttpStatusCode.OK) {
            this.isLoadingBt = false
            toastUtil.success('Xác nhận nhập kho thành công !')
            $('#confirm_supplies_import').trigger('click')
            await importExportStore.getImpExpRequest()
        } else {
            this.isLoadingBt = false
            toastUtil.error(result.body.message)
        }
        this.itemsApprovedRequest = []
    }
    async changeStatusRequest() {
        this.isLoadingBt = true
        const result = await importExportService.changeStatusRequest(this.dataRequestChangeStatus)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật trạng thái yêu cầu thành công !')
            await importExportStore.getImpExpRequest()
            importExportStore.dataRequestChangeStatus = {
                ids: [],
                status: ""
            }
        } else {
            importExportStore.dataRequestChangeStatus = {
                ids: [],
                status: ""
            }
            toastUtil.error(result.body.message)
        }
    }


}

export const importExportStore = new ImportExportStore();
