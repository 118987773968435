import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {checkOrderService} from "./CheckOrderService";
import {orderService} from "../order/OrderService";
import {getRequest} from "../../common/helpers/RequestHelper";
import $ from "jquery";
import {toastUtil} from "../../common/utils/ToastUtil";
import {orderStore} from "../order/OrderStore";
import {validatePhoneNumber} from "../../common/utils/Utils";
import {customerDataStore} from "../customerCare/customerData/CustomerDataStore";
import {numberUtil} from "../../common/utils/NumberUtil";
import {warehouseService} from "../setting/warehouse/WarehouseService";
import moment from "moment";

class CheckOrderStore {
    id: any = ''
    typeModal: string = ''
    establishmentName: string = ''
    page: number = 0
    totalPage: number = 0
    searchKeywordOrder: any = null
    searchStatusOrder: any = null
    searchTypeOrder: any = null
    isLoading: boolean = false
    isLoadingBt: boolean = false
    isErrorShippingFee: boolean = false
    isErrorInfoReceiver: boolean = true
    listProvince: any[] = []
    listDistrict: any[] = []
    listWard: any[] = []
    listOrders: any[] = []
    listRestaurant: any[] = []
    listUser: any[] = []
    detailInfoUser: any = {}
    keyword: string = ''
    idOrder: any = ''
    dataDetail: any = {}
    dataDetailTransport: any = {}
    dataCheckCodeVoucher : any = {}
    dataRequest: any = {
    }
    listItems : any = []
    idItemUpdate: any = ""
    itemUpdate : any = {}
    dataUserRequest: any = {
        name: "",
        phone: "",
        gender: null,
        birthDay: null
    }
    errorUserRequest: any = {
        name: "",
        phone: "",
        gender: "",
        birthDay: "",
    }
    dataCheckVoucherRequest: any = {
        voucher: "",
        useCoins: false,
        userId: ""
    }
    dataCheckOutRequest: any = {
        voucherCode: "",
        useCoins: false,
        userId: null,
        transport: null
    }

    dataReceiverTransport : any = {
        phoneReceiver: "",
        nameReceiver: "",
        shippingFee: 0,
        receivingAddress: {
            provinceId: null,
            districtId: null,
            wardId: null,
            addressDetail: ""
        },
        paymentStatus: "UNPAID",
        note: ""
    }
    errorDataReceiverTransport : any = {
        phoneReceiver: "",
        nameReceiver: "",
        shippingFee: "",
        receivingAddress: {
            provinceId: "",
            districtId: "",
            wardId: "",
            addressDetail: ""
        },
    }
    dataReceiverTransportProvinceName: string = ''
    dataReceiverTransportDistrictName: string = ''
    dataReceiverTransportWardName: string = ''

    clearForm() {
        this.isErrorShippingFee = false
        this.isErrorInfoReceiver = true
        this.dataUserRequest = {
            name: "",
            phone: "",
            gender: null,
        }
        this.errorUserRequest = {
            name: "",
            phone: "",
            gender: "",
        }
        this.dataCheckVoucherRequest = {
            voucher: "",
            useCoins: false,
            userId: null,
            transport: null
        }
        this.dataReceiverTransport = {
            phoneReceiver: "",
            nameReceiver: "",
            shippingFee: 0,
            receivingAddress: {
                provinceId: null,
                districtId: null,
                wardId: null,
                addressDetail: ""
            },
            paymentStatus: "UNPAID",
            note: ""
        }
        this.errorDataReceiverTransport = {
            phoneReceiver: "",
            nameReceiver: "",
            shippingFee: "",
            receivingAddress: {
                provinceId: "",
                districtId: "",
                wardId: "",
                addressDetail: ""
            },
        }
        this.dataReceiverTransportProvinceName = ''
        this.dataReceiverTransportDistrictName = ''
        this.dataReceiverTransportWardName = ''
    }

    constructor() {
        makeAutoObservable(this)
    }
    async getListOrder() {
        this.isLoading = true
        checkOrderStore.page = 0
        const result = await checkOrderService.getListOrder()
        if (result.status === HttpStatusCode.OK) {
            checkOrderStore.isLoading = false
            checkOrderStore.listOrders = result.body.data
        }
    }
    async getDetailOrder(id: number){
        const result = await getRequest(`/iss_365_fnb/order/${id}/checkout`)
        if(result.status === HttpStatusCode.OK){
            checkOrderStore.dataDetail = result.body?.order
            checkOrderStore.dataDetailTransport = result.body?.transportRequest
            checkOrderStore.listItems  = result.body?.order?.items
        }
    }

    async getListRestaurant() {
        orderStore.page = 0
        const result = await orderService.getListRestaurant()
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }
    async getDetailUser(id: number) {
        this.isLoading = true
        const result = await checkOrderService.getDetailUser(id)
        if (result.status === HttpStatusCode.OK) {
            this.isLoading = false
            this.detailInfoUser = result.body
            this.dataReceiverTransport.nameReceiver = result.body.name
            this.dataReceiverTransport.phoneReceiver = result.body.phone
            this.dataCheckVoucherRequest.userId = result.body?.id
            this.dataCheckOutRequest.userId = result.body?.id
            await checkOrderStore.checkAddVoucher()
        }
    }
    async getListUser() {
        const result = await checkOrderService.getListUser()
        if (result.status === HttpStatusCode.OK) {
            this.listUser = result.body.data
        }
    }
    validateInfoReceiver() {
        this.isLoadingBt = true
        if(!this.dataReceiverTransport.nameReceiver) {
            checkOrderStore.errorDataReceiverTransport.nameReceiver = "Tên người nhận không được để trống"
            this.isLoadingBt = false
            return false
        }
        if(!this.dataReceiverTransport.phoneReceiver) {
            checkOrderStore.errorDataReceiverTransport.phoneReceiver = "Số điện thoại người nhận không được để trống"
            this.isLoadingBt = false
            return false
        }
        if (!validatePhoneNumber(this.dataReceiverTransport.phoneReceiver)) {
            this.dataReceiverTransport.phoneReceiver = ''
            checkOrderStore.errorDataReceiverTransport.phoneReceiver = 'Vui lòng nhập đúng định dạng số điện thoại!'
            this.isLoadingBt = false
            return
        }
        if(!this.dataReceiverTransport.receivingAddress.provinceId) {
            checkOrderStore.errorDataReceiverTransport.receivingAddress.provinceId = "Vui lòng chọn tỉnh / thành phố"
            this.isLoadingBt = false
            return false
        }
        if(!this.dataReceiverTransport.receivingAddress.districtId) {
            checkOrderStore.errorDataReceiverTransport.receivingAddress.districtId = "Vui lòng chọn quận / huyện"
            this.isLoadingBt = false
            return false
        }
        if(!this.dataReceiverTransport.receivingAddress.wardId) {
            checkOrderStore.errorDataReceiverTransport.receivingAddress.wardId = "Vui lòng chọn phường / xã"
            this.isLoadingBt = false
            return false
        }
        if(!this.dataReceiverTransport.receivingAddress.addressDetail) {
            checkOrderStore.errorDataReceiverTransport.receivingAddress.addressDetail = "Vui lòng nhập địa chỉ cụ thể"
            this.isLoadingBt = false
            return false
        }
        this.isLoadingBt = false
        this.dataCheckOutRequest.transport = this.dataReceiverTransport
        this.isErrorInfoReceiver = false
        $('#add_info_receiver_transport').trigger('click')
    }
    async addUser() {
        if(!this.dataUserRequest.name) {
            checkOrderStore.errorUserRequest.name = "Tên khách hàng không được để trống"
            return false
        }
        if(!this.dataUserRequest.phone) {
            checkOrderStore.errorUserRequest.phone = "Số điện thoại không được để trống"
            return false
        }
        if (!validatePhoneNumber(this.dataUserRequest.phone)) {
            this.dataUserRequest.phone = ''
            this.errorUserRequest.phone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            return
        }
        if(!this.dataUserRequest.gender) {
            checkOrderStore.errorUserRequest.gender = "Vui lòng chọn giới tính khách hàng"
            return false
        }
        if(!this.dataUserRequest.birthDay) {
            checkOrderStore.errorUserRequest.birthDay = "Vui lòng chọn ngày sinh của khách hàng"
            return false
        }

        this.isLoadingBt = true
        let birthdayUser =  moment(this.dataUserRequest.birthDay)
        let data = {
            ...this.dataUserRequest,
            birthDay: birthdayUser
        }
        const result = customerDataStore.typeModal !== 'edit' ?  await checkOrderService.addUser(data) : await checkOrderService.updateUser(customerDataStore.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#add_user_customer').trigger('click')
            toastUtil.success(`${customerDataStore.typeModal !== 'edit' ? 'Thêm' : 'Cập nhật thông tin'}  khách hàng thành công !`)
            customerDataStore.page = 0
            customerDataStore.listUser = []
            customerDataStore.hasMore = true
            await customerDataStore.getListUser().then()
        } else toastUtil.error(result.body.message)
    }
    async checkAddVoucher() {
        this.isLoadingBt = true
        const result =  await checkOrderService.checkAddVoucher(this.dataCheckVoucherRequest, this.idOrder)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            checkOrderStore.dataCheckCodeVoucher = result.body
            if(checkOrderStore.dataCheckCodeVoucher.discountBirthday) {
                toastUtil.success(`Chúc mừng khách hàng được giảm giá voucher sinh nhật trị giá ${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher.discountBirthday)}đ`)
            }
        } else toastUtil.error(result.body.message)
    }
    async updateItemOrder() {
        const result =  await checkOrderService.updateItemOrder(this.itemUpdate, this.idOrder , this.idItemUpdate)
        if (result.status === HttpStatusCode.OK) {
            this.idItemUpdate = ""
            this.idItemUpdate = {}
        } else toastUtil.error(result.body.message)
    }

    async checkOutOrder() {
        this.isLoadingBt = true
        if(checkOrderStore.dataDetail?.type === 'ONLINE' && !(checkOrderStore.dataReceiverTransport.shippingFee >= 0)){
            checkOrderStore.isErrorShippingFee = true
            toastUtil.warning('Vui lòng nhập phí vận chuyển cho đơn hàng')
            this.isLoadingBt = false
            return
        }
        else if(checkOrderStore.dataDetail?.type === 'ONLINE' && this.isErrorInfoReceiver) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin người nhận')
            this.isLoadingBt = false
            return
        }
        const result =  await checkOrderService.checkOutOrder(this.dataCheckOutRequest, this.idOrder)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Đơn hàng thành công !')
            await checkOrderStore.getListOrder()
            if(window.location.pathname.startsWith('/phuc-vu')) {
                await orderStore.getDetailOrder(Number(localStorage.getItem('orderId')))
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = true
                orderStore.getListOrder().then()
            }
            $('#detail_checkout_order').trigger('click')
        } else toastUtil.error(result.body.message)
    }
    async changeProvince(provinceId: any, value: any) {
        checkOrderStore.dataReceiverTransport.receivingAddress.provinceId = provinceId
        checkOrderStore.dataReceiverTransportProvinceName = value.label
        checkOrderStore.dataReceiverTransport.receivingAddress.districtId = null
        checkOrderStore.dataReceiverTransport.receivingAddress.wardId = null
        await checkOrderStore.getDistricts(provinceId)
    }

    async changeDistrict(districtId: any, value: any) {
        checkOrderStore.dataReceiverTransport.receivingAddress.districtId = districtId
        checkOrderStore.dataReceiverTransportDistrictName = value.label
        checkOrderStore.dataReceiverTransport.receivingAddress.wardId = null
        await checkOrderStore.getWards(districtId)
    }

    async getProvinces() {
        const result = await warehouseService.getProvinces()
        if (result.status === HttpStatusCode.OK) {
            checkOrderStore.listProvince = result.body
        }
    }

    async getDistricts(provinceId: any) {
        const result = await warehouseService.getDistricts(provinceId)
        if (result.status === HttpStatusCode.OK) {
            checkOrderStore.listDistrict = result.body
        }
    }

    async getWards(districtId: any) {
        const result = await warehouseService.getWards(districtId)
        if (result.status === HttpStatusCode.OK) {
            checkOrderStore.listWard = result.body
        }
    }
}

export const checkOrderStore = new CheckOrderStore()