import React from 'react';
import {orderStore} from "../../OrderStore";
import {observer} from "mobx-react";

const ModalShip = () => {

    return (
        <div className='modal fade modal_full' id='modal_shipping' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header'>
                        <h1 className='value_voucher__title'>
                            {localStorage.getItem('name_restaurant')} {orderStore.dataRequest?.establishmentName}
                        </h1>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">

                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(ModalShip);