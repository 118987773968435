import React from 'react';
import {observer} from "mobx-react";
import {importExportStore} from "../ImportExportStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {dateUtils} from "../../../../common/utils/DateUtils";
import '../../purchase/components/detailRequestPurchase/DetailRequestPurchase.scss'


const PopupImpExpDetail = () => {

    return (
        <div className="modal fade" id="popup_imp_exp_request" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: `1316px !important`}}>
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center justify-content-end mb-3">
                       <div className="d-flex align-items-center">
                           <button className="bt-action-print me-2"
                                   title="In biên bản"
                                   onClick={() => window.print()}>
                               <img src="/assets/ico/action/printer.svg" alt=""/>
                           </button>
                           <div className="d-flex align-items-center">
                               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                           </div>
                       </div>
                    </div>
                    <div className='px-3'>
                        <div className='d-flex justify-content-between align-items-center mb-2' style={{paddingRight: 60}}>
                            <div>
                                <div className="d-flex">
                                    <span style={{color: '#64646D', fontSize: '14px'}}>Công ty: </span>
                                    <div className="ms-1" style={{color: '#000', fontSize: '14px', fontWeight: '500'}}>{localStorage.getItem('name_restaurant')}</div>
                                </div>
                                <div className="d-flex">
                                    <span style={{color: '#64646D', fontSize: '14px'}}>Địa chỉ: </span>
                                    <div className="ms-1" style={{color: '#000', fontSize: '14px', fontWeight: '500'}}>{localStorage.getItem('address_restaurant')}</div>
                                </div>
                            </div>
                            <div
                                className='detail_request_purchase__title d-flex flex-column align-items-center justify-content-center'>
                                <span className='text-uppercase'>Cộng hoà xã hội chủ nghĩa Việt Nam</span>
                                <span>Độc lập - Tự do - Hạnh phúc</span>
                            </div>
                        </div>
                        <div className='detail_request_purchase__content mb-3'>
                            PHIẾU ĐỀ NGHỊ {importExportStore.dataRequest.type === "IMPORT" ? 'NHẬP' : "XUẤT"} KHO
                        </div>
                        <div className='modal-order--content'>
                            <div className='modal-order--head'>
                                <div className='modal-order--headFirst'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Kính gửi: </span>
                                            <span>Người đề nghị: </span>
                                            <span>Chức danh: </span>
                                        </div>
                                        <div className='modal-order--value d-flex flex-column'>
                                            <span>{importExportStore.dataRequest?.mainWarehouse?.name ?? importExportStore.dataRequest?.warehouse?.name}</span>
                                            <span>{importExportStore.dataRequest?.requestUser?.name}</span>
                                            <span>-</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive mb-2 d-flex justify-content-between">
                            <table className='table align-middle border mb-0'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th>Tên vật tư</th>
                                    <th className="text-center">Mã vật tư</th>
                                    <th className="text-center">ĐVT</th>
                                    <th className="text-center">Số lượng</th>
                                    {!importExportStore.isToMainWarehouse && <th className="text-center">Đơn giá</th>}
                                    {!importExportStore.isToMainWarehouse &&<th className="text-center">Hạn sử dụng</th>}
                                    <th className="text-center">Ghi chú</th>
                                </tr>
                                </thead>
                                <tbody>
                                {importExportStore.dataRequest.items.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td className="text-center">{i + 1}</td>
                                            <td>{item?.supplies?.name}</td>
                                            <td className="text-center">{item?.supplies?.code}</td>
                                            <td className="text-center">{item?.supplies?.unit}</td>
                                            <td className="text-center">{numberUtil.number_format(item?.qty)}</td>
                                            {!importExportStore.isToMainWarehouse && <td className="text-center">{item?.price ? numberUtil.number_format(item?.price) : ''}</td>}
                                            {!importExportStore.isToMainWarehouse &&<td className="text-center">{dateUtils.formatDate(item?.expiryDate)}</td>}
                                            <td className="text-center">{item?.note}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex">
                            <span style={{color: '#64646D', fontSize: '14px'}}>Lý do: </span>
                            <div className="ms-2" style={{color: '#000', fontSize: '14px', fontWeight: '500'}}>{importExportStore.dataRequest.purpose}</div>
                        </div>
                        <div className="d-flex justify-content-between mt-4 mb-5 ps-5 ps-5">
                            <div className="requestUser text-center" style={{width: 250}}>
                                <p style={{color: '#64646D', fontSize: '14px'}} >Người duyệt</p>
                                <p className="mb-0 fw-bold" style={{color: '#000000', fontSize: '14px', marginTop: 65}}>{importExportStore.dataRequest?.mainWarehouse?.user?.name ?? importExportStore.dataRequest?.warehouse?.user?.name}</p>
                            </div>
                            <div className="requestUser text-center" style={{width: 250}}>
                                <p style={{color: '#64646D', fontSize: '14px'}} >Người đề nghị</p>

                                <p className="mb-0 fw-bold" style={{color: '#000000', fontSize: '14px', marginTop: 65}}>{importExportStore.dataRequest?.requestUser?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupImpExpDetail);