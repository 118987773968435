import React, {useEffect, useRef} from 'react';
import './Dashboard.scss'
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {getUrlParameter, sttPage} from "../../../common/utils/Utils";
import StorageService from "../../../common/service/StorageService";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {dateUtils} from "../../../common/utils/DateUtils";
import ModalSelectRestaurant from "../../order/components/selectRestaurant/SelectRestaurant";
import DetailCheckoutOrderModal from "../../checkOrder/components/detailOrder/detailOrder";
import ViewDetailOrderModal from "../../checkOrder/components/viewHistoryOrder/ViewHistoryOrder";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {businessPlanStore} from "../businessPlan/BusinessPlanStore";
import {Select, Tooltip} from "antd";
import ModalDetailRequestForWork from "../businessPlan/components/modalDetailRequest/ModalDetailRequestForWork";
import InfiniteScroll from "react-infinite-scroll-component";
import {orderStore} from "../../order/OrderStore";
import {checkOrderStore} from "../../checkOrder/CheckOrderStore";
import {Bar} from "react-chartjs-2";

const convertTypeOrder = (value: string) => {
    switch (value.toUpperCase()) {
        case "ONLINE":
            return <div className="" style={{background: '#FFF6D3', borderRadius: '3px', width: '80px'}}>Online</div>
        case "OFFLINE":
            return <div className="" style={{background: '#EEFFFE', borderRadius: '3px',width: '80px'}}>Tại chỗ</div>
        default:
            return null
    }
}
const convertStatusOrder = (value: string) => {

    switch (value.toUpperCase()) {
        case "SUCCESS":
            return <span className="color-green fw-500" style={{width: '100px'}}>Thành công</span>
        case "IN_PROGRESS":
            return <span style={{fontWeight: '500', color: '#7B61FF', width: '120px'}}>Đang phục vụ</span>
        case "PENDING":
            return <span style={{fontWeight: '500', color: '#808089', width: '120px'}}>Chưa phục vụ</span>
        case "CANCEL":
            return <span className="color-red fw-500" style={{width: '100px'}}>Hủy</span>
        default:
            return null
    }
}

const convertStatusJob = (value: string) => {
    switch (value?.toUpperCase()) {
        case "COMPLETE":
            return <span className="color-green fw-500">Hoàn thành</span>
        case "PROCESSING":
            return <span className="color-purple fw-500">Đang xử lý</span>
        case "DONE":
            return <span className="color-green fw-500">Xong</span>
        default:
            return null
    }
}
const Dashboard = () => {

    const dataCodeWork = [
        {value: 'CONTENT_FACEBOOK', label: 'Nội dung Facebook'},
        {value: 'CONTENT_WEBSITE', label: 'Nội dung Website'},
        {value: 'CONTENT_VIDEO', label: 'Nội dung Video'},
        {value: 'ADVERTISING_PLAN', label: 'Kế hoạch chạy Ads'},
        {value: 'EXCEL', label: 'Excel'},
        {value: 'WORD', label: 'Word'},
    ]
    if(getUrlParameter('token')) {
        StorageService.setToken(getUrlParameter('token') as any);
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
    }

    const modalRef = useRef(null);

    useEffect(() => {
        orderStore.searchKeywordOrder = ''
        orderStore.searchStatusOrder = null
        orderStore.page = 0
        orderStore.listOrders = []
        orderStore.hasMore = true
        if(!orderStore.dataRequest?.establishmentId) {
            orderStore.dataRequest.establishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
        }
        if(!orderStore.dataRequest?.establishmentName) {
            orderStore.dataRequest.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
        }
        orderStore.getListOrder().then()
        orderStore.getListRestaurant().then(() => {
            if (modalRef.current && !JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId && orderStore.listRestaurant.length > 0) {
                (modalRef.current as HTMLButtonElement).click();
            }
        })
    }, [])
    useEffect(() => {
        localStorage.removeItem('idPlan')
        businessPlanStore.getJobPlan().then()
    }, [])

    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  orderStore.searchStatusOrder,
            placeholder: 'Tất cả',
            listValue: [{
                value: "CANCEL",
                label : "Đã hủy"
            },{
                value: "IN_PROGRESS",
                label : "Đang phục vụ"
            },{
                value: "PENDING",
                label : "Chưa phục vụ"
            },{
                value: "SUCCESS",
                label : "Thành công"
            }],
            callback: async (e: any) => {
                orderStore.searchStatusOrder = e
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = !orderStore.searchStatusOrder
                if(!e) await orderStore.getListOrder().then()
                else await orderStore.getListOrder(500).then()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Nhập từ khóa tìm kiếm...',
            value: orderStore.searchKeywordOrder,
            onChange: (e: any) => {
                orderStore.searchKeywordOrder = e
            },
            callback: async () => {
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = !orderStore.searchKeywordOrder
                if(!orderStore.searchKeywordOrder) await orderStore.getListOrder().then()
                else await orderStore.getListOrder(500).then()
            }
        },
    ]
    const dataLineProduct = {
        labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Mục tiêu",
                data:  [2,2,3,4,5,4,10,23,2,5,4,5],
                fill: true,
                backgroundColor: "rgba(249, 65, 68, 1)",
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                }
            },
            {
                label: "Doanh thu",
                data:  [5,12,13,14,15,11,10,31,14,15,11,2],
                fill: true,
                backgroundColor: "rgba(144, 190, 109, 1)",
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                }
            }
        ]
    };
    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
    };
    return (
        <>
        <div className='dashboard_container'>
            <div className='dashboard_container__header'>
                <div className='d-flex justify-content-between align-items-center'>
                    <TabRedirect tab={`Quản lý đơn hàng ${orderStore.dataRequest.establishmentName ? `(${orderStore.dataRequest.establishmentName})` : '(Tất cả điểm bán)'}`}  />
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={<button ref={modalRef} data-bs-toggle="modal" data-bs-target='#select_restaurant_order' className='btn_select_restaurant'>Chuyển điểm bán</button>}
                    />
                </div>

            </div>
            <div className='d-flex justify-content-between w-100'>
                <div style={{width: '70%', height: 'fit-content'}}>
                    <div  id="table_data_order" className="table-responsive table-part-responsive custom-scrollbar d-flex flex-column justify-content-between">
                                            <InfiniteScroll
                                                dataLength={orderStore.listOrders.length}
                                                next={orderStore.getListOrder}
                                                hasMore={orderStore.hasMore}
                                                loader={<Loading/>}
                                                scrollableTarget="table_data_order"
                                            >
                                            <table className='table align-middle border gy-7 gs-7'>
                                                <thead>
                                                <tr>
                                                    <th className="text-center">STT</th>
                                                    <th className="text-center">Mã đơn</th>
                                                    <th className="text-center">Thời gian</th>
                                                    <th className="text-center">Số điện thoại</th>
                                                    <th style={{width: '150px'}} className="text-center">Họ và tên</th>
                                                    <th className="text-center">Loại</th>
                                                    {/*<th className="text-center">Phòng - bàn</th>*/}
                                                    <th className="text-center">Tình trạng</th>
                                                    <th className="text-center">Thao tác</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(orderStore.listOrders.length <= 0 &&  !orderStore.isLoading) ? <NoContent colSpan={10}/> : orderStore.listOrders.map((item, i) => {
                                                    if(!item.isShow){
                                                        return (
                                                            <tr key={i}>
                                                                <td className="text-center">{i + 1}</td>
                                                                <td className="text-center">
                                                                    #{item?.code}
                                                                </td>
                                                                <td className="text-center">{dateUtils.formatDate(item?.checkinTime, 'hh:mm , DD/MM/YYYY')}</td>
                                                                <td className="text-center">{item?.user?.phone ?? "-"}</td>
                                                                <td className="text-center">{item?.user?.name ?? "-"}</td>
                                                                <td className="text-center">{convertTypeOrder(item?.type)}</td>
                                                                {/*<td className="text-center">{`${item?.room?.name ? `${item?.room?.name}`: ""}${item?.table?.name ? `- ${item?.table?.name}` : ""}`}</td>*/}
                                                                <td className="text-center" style={{width: '120px'}}>{convertStatusOrder(item?.status)}</td>
                                                                <td style={{width: '100px'}} className="text-center fw-500">
                                                                    <div className='w-100 d-flex justify-content-center'>
                                                                            <button className="btn p-0 ms-2"
                                                                                    onClick={() => {
                                                                                        if(orderStore.listOrders[i + 1]?.isShow === true) {
                                                                                            orderStore.listOrders.splice(i + 1, 1)
                                                                                        }
                                                                                        else {
                                                                                            let indexShowOrder = orderStore.listOrders.findIndex(order => order.isShow === true);
                                                                                            if(indexShowOrder >= 0) {
                                                                                                orderStore.listOrders.splice(indexShowOrder, 1)
                                                                                                if(indexShowOrder > i){
                                                                                                    orderStore.listOrders.splice(i + 1, 0, {
                                                                                                        ...item,
                                                                                                        isShow: true
                                                                                                    });
                                                                                                }
                                                                                                else {
                                                                                                    orderStore.listOrders.splice(i, 0, {
                                                                                                        ...item,
                                                                                                        isShow: true
                                                                                                    });
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                orderStore.listOrders.splice(i + 1, 0, {
                                                                                                    ...item,
                                                                                                    isShow: true
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}>
                                                                                <img src={(orderStore.listOrders[i + 1]?.isShow) ? "/assets/ico/action/minus_expanded.svg" : "/assets/ico/action/expanded.svg"}
                                                                                     className="icon-action"
                                                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                                                     title="Chi tiết"/>
                                                                            </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    else return (
                                                        <tr className='service_item_container'>
                                                            <td colSpan={11}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div>
                                                                        Loại KH : <span className='fw-500'>Thường</span>
                                                                        <span className='mx-3'>|</span>
                                                                        {
                                                                            item?.type === "OFFLINE" && <>
                                                                                Phòng : <span className='fw-500'>{item?.room?.name ?? "-"}</span>
                                                                                <span className='mx-3'>|</span>
                                                                                Bàn : <span className='fw-500'>{item?.table?.name ?? "-"}</span>
                                                                                <span className='mx-3'>|</span>
                                                                            </>
                                                                        }
                                                                        Loại phục vụ : <span className='fw-500'>{item?.type === "OFFLINE" ? "Tại chỗ" : "Online"}</span>
                                                                        <span className='mx-3'>|</span>
                                                                        Thời gian đặt : <span className='fw-500'>{dateUtils.formatDate(item?.checkinTime, 'hh:mm DD/MM/YYYY')}</span>
                                                                    </div>
                                                                    <button className={'detail_order_checkout'}
                                                                            onClick={() => {
                                                                                if(item.status !== "SUCCESS"){
                                                                                    checkOrderStore.clearForm()
                                                                                    checkOrderStore.idOrder = item.id
                                                                                    checkOrderStore.getDetailOrder(item.id).then()
                                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                                    checkOrderStore.detailInfoUser = {}
                                                                                    checkOrderStore.keyword = ""
                                                                                }
                                                                                else {
                                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                                    checkOrderStore.getDetailOrder(item.id).then()
                                                                                }
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            title="Xem chi tiết"
                                                                            data-bs-target={item?.status !== "SUCCESS" ? '#detail_checkout_order' : '#view_detail_order'}
                                                                    >
                                                                        Chi tiết đơn
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                            </InfiniteScroll>
                                        </div>
                </div>
                <div style={{width: '28%', height: 'auto'}} className="dashboard_container_revenue d-flex justify-content-between flex-column align-items-start">
                    <div className='d-flex w-100 dashboard_container__revenue-header'>
                        <div className='dashboard_container__revenue-title d-flex justify-content-center flex-column w-100'>
                            Doanh thu
                        </div>
                    </div>
                    <Bar className='px-4 py-2' style={{maxHeight: '550px'}} data={dataLineProduct} options={chartOptions} />
                    <div className='w-100 d-flex px-5 py-3'>
                        {[{color: '#F94144', name: 'Mục tiêu'},{color: '#90BE6D', name: 'Doanh thu'}].map((item: any, index: number) =>{
                            return(
                                <div key={index} style={{width: '30%'}} className='note_structure__product me-2 d-flex justify-content-start align-items-center mb-2'>
                                    <span style={{backgroundColor: `${item.color}`}} className='note-color-chart me-2'></span>
                                    <span className='title-color-chart'>{item?.name}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='dashboard_container__header'>
                <div className='dashboard_container__title mt-3'>Quản lý công việc</div>
            </div>
            <div className="table-responsive table-part-responsive custom-scrollbar d-flex justify-content-between">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Mã công việc</th>
                        <th className="text-center">Ngày yêu cầu hoàn thành</th>
                        <th className="text-center">Người tạo</th>
                        <th className="text-center">Phụ trách chính</th>
                        <th className="text-center">Tình trạng</th>
                        <th className="text-center">Thời gian giao việc</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {businessPlanStore.dataColumnWork.length <= 0 ? <NoContent colSpan={8}/> : businessPlanStore.dataColumnWork.map((item: any, i: number) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{sttPage(0, i)}</td>
                                <td className="text-center">{dataCodeWork.find((i: any) => i.value === item?.contentType)?.label}</td>
                                <td className="text-center">{dateUtils.formatDate(item?.deadline)}</td>
                                <td className="text-center">{item?.createUser}</td>
                                <td className="text-center">{item?.inChargeUser}</td>
                                <td className="text-center">{convertStatusJob(item?.state)}</td>
                                <td className="text-center">{dateUtils.formatDate(item?.createdAt)}</td>
                                <td className="text-center">
                                    <Tooltip title='Chi tiết yêu cầu công việc'>
                                        <button className="view_detail__btn"
                                                data-bs-toggle="modal" data-bs-target={'#detail_request_for_work'}
                                                onClick={async () => {
                                                    businessPlanStore.idJob = item.id
                                                    await businessPlanStore.getDetailJob(item.id).then()
                                                }}
                                        >
                                            Chi tiết
                                        </button>
                                    </Tooltip>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

        </div>
            <DetailCheckoutOrderModal />
            <ViewDetailOrderModal />
            <ModalSelectRestaurant />
            <ModalDetailRequestForWork />
        </>
    );
}

export default observer(Dashboard);