import React, {useEffect} from 'react';
import './CustomerData.scss'
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {numberUtil} from "../../../common/utils/NumberUtil";
import DetailCustomerDataModal from "./components/detailCustomerData/DetailCustomerData";
import {customerDataStore} from "./CustomerDataStore";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import InfiniteScroll from 'react-infinite-scroll-component';
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {checkOrderStore} from "../../checkOrder/CheckOrderStore";
import ModalAddUser from "../../checkOrder/components/addUser/AddUserModal";
import {dateUtils} from "../../../common/utils/DateUtils";
import {Tooltip} from "antd";
const CustomerDataPage = () => {
    useEffect(() => {
        customerDataStore.keyword = ''
        customerDataStore.page = 0
        customerDataStore.listUser = []
        customerDataStore.hasMore = true
        customerDataStore.getListUser().then(() => {
        })
    }, [])
    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm theo tên , số điện thoại...',
            value: '',
            onChange: (e: any) => {
                customerDataStore.keyword = e
            },
            callback: async () => {
                customerDataStore.page = 0
                customerDataStore.listUser = []
                customerDataStore.hasMore = !customerDataStore.keyword
                if(!customerDataStore.keyword) await customerDataStore.getListUser().then()
                else await customerDataStore.getListUser(100).then()
            }
        },
    ]
    return (
        <>
            <div className='customerData_container'>
                <div className='customerData_container__header'>
                    <TabRedirect tab='Dữ liệu khách hàng' />
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={<button onClick={() => {
                            customerDataStore.typeModal = 'add'
                            checkOrderStore.clearForm()
                        }} data-bs-toggle="modal" data-bs-target='#add_user_customer' className='btn_select_restaurant ms-3'>
                            <img src="/assets/ico/action/add_icon.svg" alt=""/>
                            Thêm khách hàng
                        </button>}
                    />
                </div>

                <InfiniteScroll
                    dataLength={customerDataStore.listUser.length}
                    next={customerDataStore.getListUser}
                    hasMore={customerDataStore.hasMore}
                    loader={<Loading/>}
                >
                    <div className="table-responsive d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">ID</th>
                            <th className="text-center">Số điện thoại</th>
                            <th className="text-center">Họ và tên</th>
                            <th className="text-center">Ngày sinh</th>
                            <th className="text-center">Loại</th>
                            <th className="text-end">Số điểm</th>
                            <th className="text-end">Tổng chi (VNĐ)</th>
                            <th className="text-center" style={{width: '200px'}}>Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {customerDataStore.listUser?.length <= 0  && !customerDataStore.isLoading ? <NoContent colSpan={8}/> : customerDataStore.listUser.map((item: any, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-center">{item?.id}</td>
                                    <td className="text-center fw-500">{item?.phone}</td>
                                    <td className="text-left">{item?.name}</td>
                                    <td className="text-center">{dateUtils.formatDate(item?.birthDay)}</td>
                                    <td className="text-center">Thường</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.customerPoints?.[0]?.totalPoint)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.member?.[0]?.totalRevenue)}</td>
                                    <td className="text-center">
                                        <div className='d-flex justify-content-center'>
                                                <button
                                                    type='button'
                                                    className="view_detail__btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target='#detail_customer_data'
                                                    onClick={async () => {
                                                        customerDataStore.id = item?.id
                                                        customerDataStore.userId = item?.id
                                                        await customerDataStore.getDetailUser(item.id)
                                                        await customerDataStore.getOrderUser()
                                                    }
                                                    }
                                                >
                                                    <Tooltip title='Xem chi tiết đơn hàng'>
                                                        Chi tiết
                                                    </Tooltip>
                                                </button>
                                                <button
                                                    type='button'
                                                    className="view_detail__btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target='#add_user_customer'
                                                    onClick={async () => {
                                                        customerDataStore.id = item?.id
                                                        customerDataStore.typeModal = 'edit'
                                                        customerDataStore.userId = item?.id
                                                        await customerDataStore.getDetailUser(item.id)
                                                    }
                                                    }
                                                >
                                                    <Tooltip title='Chỉnh sửa thông tin'>
                                                        Sửa
                                                    </Tooltip>
                                                </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                </InfiniteScroll>
            </div>
            <DetailCustomerDataModal />
            <ModalAddUser />
        </>
    );
}

export default observer(CustomerDataPage);