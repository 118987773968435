import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import CustomTable from "../../../modules/components/tableGlobal/CustomTable";
import {businessPlanStore} from "./BusinessPlanStore";
import {ConfigProvider, DatePicker, Input, Select, Tooltip} from "antd";
import './BusinessPlanPage.scss'
import ModalDetailRequestForWork from "./components/modalDetailRequest/ModalDetailRequestForWork";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {DatePickerProps} from "antd/es/date-picker";
import {staffStore} from "../../staff/StaffStore";
import ModalUpdateContent from "./components/modalUpdateContent/ModalUpdateContent";
import {dateUtils} from "../../../common/utils/DateUtils";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
const DetailBusinessPlanPage = () => {
    const [isEdit, setIsEdit] = useState(false)
    const [dataWork, setDataWork] = useState({
        businessPlanId: Number(localStorage.getItem('idPlan')),
        contentType: null,
        deadline: null,
        inChargeUserId: null,
        supportUserId: [],
        requirement: ""
    })
    const convertStatus = (value: string) => {
        switch (value?.toUpperCase()) {
            case "COMPLETE":
                return <span className="color-green fw-500">Hoàn thành</span>
            case "PROCESSING":
                return <span className="color-purple fw-500">Đang xử lý</span>
            case "DONE":
                return <span className="color-green fw-500">Xong</span>
            default:
                return null
        }
    }
    const dataCodeWork = [
        {value: 'CONTENT_FACEBOOK', label: 'Nội dung Facebook'},
        {value: 'CONTENT_WEBSITE', label: 'Nội dung Website'},
        {value: 'CONTENT_VIDEO', label: 'Nội dung Video'},
        {value: 'ADVERTISING_PLAN', label: 'Kế hoạch chạy Ads'},
        {value: 'EXCEL', label: 'Excel'},
        {value: 'WORD', label: 'Word'},
    ]
    function convertTime(date: any): any {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChangeDateFinish = (
        value: DatePickerProps['value'],
    ) => {
        setDataWork({
            ...dataWork,
            deadline : convertTime(value)
        })
    };
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        if(businessPlanStore.dataDetailJob?.businessPlanId) {
            setDataWork({
                businessPlanId: businessPlanStore.dataDetailJob?.businessPlanId,
                contentType: businessPlanStore.dataDetailJob?.contentType,
                deadline: businessPlanStore.dataDetailJob?.deadline,
                inChargeUserId: businessPlanStore.dataDetailJob?.inChargeUser?.id,
                supportUserId: businessPlanStore.dataDetailJob?.supportUsers?.map((i: any) => i.id),
                requirement: businessPlanStore.dataDetailJob?.requirement?.content
            })
        }
    }, [businessPlanStore.dataDetailJob])

    useEffect(() => {
        businessPlanStore.getJobPlan().then()
    }, [])
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  businessPlanStore.searchTypeJob,
            placeholder: 'Tất cả',
            listValue: [{
                value: "PROCESSING",
                label : "Đang thực hiện"
            },{
                value: "COMPLETE",
                label : "Hoàn thành"
            },{
                value: "DONE",
                label : "Xác nhận hoàn thành"
            }],
            callback: async (e: any) => {
                businessPlanStore.searchTypeJob = e
                await businessPlanStore.getJobPlan().then()
            }
        },
    ]
    const columns: any = [
        {
            title: "STT",
            align: "center",
            fixed: "left",
            width: 80,
            render: (_: any, __ : any, index: any) => index + 1,
        },
        {
            title: "Nội dung công việc",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            align: "center",
            width: 300,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <button type='submit'
                                   onClick={async () => {
                                       await businessPlanStore.getDetailJob(record.id)
                                       businessPlanStore.idJob = record.id
                                   }}
                                   className='btn btn-update__content-work'
                            data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#update_content_job_plan'
                            >
                        Cập nhật nội dung
                    </button>
                }
                else return <div>
                </div>
            }
        },
        {
            title: "Mã công việc",
            dataIndex: "contentType",
            key: "contentType",
            align: "center",
            width: 200,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{
                        dataCodeWork.find((item: any) => item.value === value)?.label
                    }</span>
                }
                else return <div>
                    <Select
                        allowClear
                        value={dataWork.contentType}
                        onChange={(e: any) => {
                            setDataWork({
                                ...dataWork,
                                contentType : e
                            })
                        }}
                        placeholder="Chọn" options={dataCodeWork}
                    />
                </div>
            }
        },
        {
            title: "Ngày yêu cầu hoàn thành",
            dataIndex: "deadline",
            key: "deadline",
            align: "center",
            width: 200,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{dateUtils.formatDate(value)}</span>
                }
                else return <div>
                    <ConfigProvider locale={viVI}>
                        <DatePicker
                            value={!dataWork.deadline ? null : dayjs(dataWork.deadline)}
                            placeholder='Chọn'
                            format={"DD/MM/YYYY"} onChange={onChangeDateFinish}/>
                    </ConfigProvider>
                </div>
            }
        },
        {
            title: "Người phụ trách",
            dataIndex: "inChargeUser",
            key: "inChargeUser",
            align: "center",
            width: 200,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{value}</span>
                }
                else return <div>
                    <Select
                        allowClear
                        showSearch
                        onSearch={(e: any) => {
                            setDataWork({
                                ...dataWork,
                                inChargeUserId : e
                            })
                        }}
                        filterOption={filterOption}
                        value={dataWork.inChargeUserId}
                        onChange={(e: any) => {
                            setDataWork({
                                ...dataWork,
                                inChargeUserId : e
                            })
                        }}
                        placeholder="Chọn" options={businessPlanStore.listStaff}
                    />
                </div>
            }
        },
        {
            title: "Người hỗ trợ",
            dataIndex: "supportUsers",
            key: "supportUsers",
            align: "center",
            width: 300,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{value?.map((item: any, index: number) => index === 0 ?  `${item.name}` : `, ${item.name}`)}</span>
                }
                else return <div>
                    <Select
                        allowClear
                        showSearch
                        mode="multiple"
                        onSearch={(e: any) => {
                            setDataWork({
                                ...dataWork,
                                supportUserId : e
                            })
                        }}
                        filterOption={filterOption}
                        value={dataWork.supportUserId}
                        onChange={(e: any) => {
                            setDataWork({
                                ...dataWork,
                                supportUserId : e
                            })
                        }}
                        placeholder="Chọn" options={businessPlanStore.listStaff}
                    />
                </div>
            }
        },
        {
            title: "Yêu cầu",
            dataIndex: "requirement",
            key: "requirement",
            align: "left",
            width: 200,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{value?.[value.length - 1]?.content} <span className='text-danger fst-italic'>({value?.length} yêu cầu)</span></span>
                }
                else if(record.isEdit && !isEdit) return <div>
                    <Input type='text' value={dataWork.requirement}
                           onChange={(e: any) => {
                               setDataWork({
                                   ...dataWork,
                                   requirement: e.target.value
                               })
                           }}
                           placeholder='Nhập...' />
                </div>
                else return <div></div>
            }
        },
        {
            title: "Người tạo",
            dataIndex: "createUser",
            key: "createUser",
            align: "center",
            width: 150,
        },
        {
            title: "Thời gian giao",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 200,
            render: (value: any): JSX.Element => <span>{dateUtils.formatDate(value)}</span>
        },
        {
            title: "Tình trạng",
            dataIndex: "state",
            key: "state",
            align: "center",
            fixed: "right",
            width: 150,
            render: (value: any) => <span>{convertStatus(value)}</span>
        },
        {
            title: "Thao tác",
            dataIndex: "resource",
            key: "resource",
            width: 200,
            fixed: "right",
            align: "center",
            render: (_: any, record: any, index: number): JSX.Element => {
                if (!record.isEdit) {
                    return (
                        <div>
                                <button className="btn p-0"
                                        data-bs-toggle="modal" data-bs-target={'#detail_request_for_work'}
                                        onClick={async () => {
                                            businessPlanStore.idJob = record.id
                                            await businessPlanStore.getDetailJob(record.id).then()
                                        }}
                                        >
                                    <Tooltip title='Chi tiết yêu cầu công việc'>
                                    <img src="/assets/ico/action/detail_icon.svg"
                                         className="icon-action"
                                         alt=""/>
                                    </Tooltip>
                                </button>
                                <button className="btn p-0"
                                        onClick={async () => {
                                            businessPlanStore.id = record.id
                                            businessPlanStore.typeModal = 'edit'
                                            setIsEdit(true)
                                            await businessPlanStore.getDetailJob(record.id).then(() => {
                                                handleAddJob()
                                            })
                                        }}>
                                    <Tooltip title='Chỉnh sửa công việc'>
                                    <img src="/assets/ico/action/edit_icon.svg"
                                         className="icon-action"
                                         alt=""/>
                                    </Tooltip>
                                </button>
                            {
                                record.state === "PROCESSING" ?
                                    <button className="btn p-0"
                                            onClick={async () => {
                                                await businessPlanStore.changeStatusJob(record.id, 'COMPLETE')
                                            }}
                                    >
                                        <Tooltip title='Hoàn thành công việc'>
                                            <img src="/assets/ico/action/check-circle-2.svg"
                                                 className="icon-action"
                                                 alt=""/>
                                        </Tooltip>
                                    </button>
                                 : record.state === "COMPLETE" ?
                                    <button className="btn p-0"
                                            onClick={async () => {
                                                await businessPlanStore.changeStatusJob(record.id, 'DONE')
                                            }}
                                    >
                                        <Tooltip title='Xác nhận hoàn thành'>
                                            <img src="/assets/ico/action/check-circle-2.svg"
                                                 className="icon-action"
                                                 alt=""/>
                                        </Tooltip>
                                    </button>
                                 :
                                    <button className="btn p-0"
                                            onClick={async () => {
                                                await businessPlanStore.changeStatusJob(record.id, 'PROCESSING')
                                            }}
                                    >
                                        <Tooltip title='Chưa hoàn thành công việc'>
                                            <img src="/assets/ico/action/x-circle.svg"
                                                 className="icon-action"
                                                 alt=""/>
                                        </Tooltip>
                                    </button>
                            }
                                <button className="btn p-0"
                                        data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                        onClick={() => businessPlanStore.id = record.id}
                                >
                                    <Tooltip title='Xóa công việc'>
                                        <img src="/assets/ico/action/delete_icon.svg"
                                             className="icon-action"
                                             alt=""/>
                                    </Tooltip>
                                </button>
                        </div>
                    );
                } else
                {
                    return <div className='d-flex justify-content-center align-items-center'>
                        <button onClick={() => {
                            const dataSplice = businessPlanStore.dataColumnWork.slice(); //Tạo bản ghi sâu
                            dataSplice.splice(index, 1)
                            businessPlanStore.dataColumnWork = dataSplice
                        }} className='btn-edit__row--cancel mx-1'>
                            Hủy
                        </button>
                        <button onClick={async () => {
                            businessPlanStore.dataRequestAddPJobForPlan = dataWork
                            await businessPlanStore.addEditJobPlan()
                        }} className='btn-edit__row--save mx-1'>
                            Lưu
                        </button>
                    </div>
                }
            },
        },
    ];

    const handleAddJob = async () => {
        if(businessPlanStore.typeModal === 'add') {
            setDataWork({
                businessPlanId: Number(localStorage.getItem('idPlan')),
                contentType: null,
                deadline: null,
                inChargeUserId: null,
                supportUserId: [],
                requirement: ""
            })
        }
        await staffStore.getStaff()
        if(!businessPlanStore.dataColumnWork.some((item: any) => item.isEdit)) {
            const newData = [...businessPlanStore.dataColumnWork];
            newData.push({
                name: "",
                campaign: "",
                finishDay: "",
                creator: "",
                status: "",
                isEdit: true
            })
            businessPlanStore.dataColumnWork = newData
        }
    }

    return (
        <div className='trademark_page'>
            <div className='d-flex justify-content-between'>
                <TabRedirect tab='Kế hoạch kinh doanh' redirect={'/ke-hoach-kinh-doanh'} subTab={'Chiến dịch 1'} />

                <FilterComponent
                    data={dataFilter}
                    permissionCode
                    buttonAdd={<ButtonGlobal onClick={async () => {
                        businessPlanStore.typeModal = 'add'
                        setIsEdit(false)
                        await handleAddJob()
                    }} text='Thêm' ico='add_icon' className='btn_add' />}
                />
            </div>
            <CustomTable
                subtractHeight={400}
                columns={columns}
                isLoading={businessPlanStore.isLoading}
                isRowSelection={false}
                data={businessPlanStore.dataColumnWork}
                scrollX={2000}
            />
            <ModalDetailRequestForWork />
            <ModalUpdateContent />
            <PopupConfirm isLoading={businessPlanStore.isLoadingBt} action={businessPlanStore.deleteJobPlan} />
        </div>
    );
}

export default observer(DetailBusinessPlanPage);