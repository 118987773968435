import React, {useEffect} from 'react';
import './DashboardFinance.scss'
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {costStore} from "../costPage/CostStore";
import {orderStore} from "../../order/OrderStore";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import 'chart.js/auto'



const DashboardFinancePage = () => {
    const dataFilter : IFilter[] = [
        {
            type: 'select',
            value: costStore.searchEstablishmentId,
            placeholder: 'Tất cả cửa hàng',
            listValue: costStore.listRestaurant,
            callback: async (e: any, value: any) => {
                costStore.searchMonth = null
                costStore.searchEstablishmentId = e
                costStore.establishmentName = value?.label ?? ""
                orderStore.dataRequest.establishmentId = e
                orderStore.dataRequest.establishmentName = value?.label ?? ""
                localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                await costStore.getListCost()
            }
        },
    ]
    useEffect(() => {
        costStore.searchYear = new Date().getFullYear().toString()
        costStore.getListRestaurant().then(() => {
            if(!costStore.searchEstablishmentId) {
                costStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
            }
            if(!costStore.establishmentName) {
                costStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
            }
            costStore.getListCost().then()
        })
    }, [])
    const handleSearchYearChange = async (date: any, dateString: any) => {
        costStore.searchYear = dateString || new Date().getFullYear().toString()
        await costStore.getListCost()
    };
    const data = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Chi phí",
                data: costStore.dataCostLineChart,
                fill: true,
                backgroundColor: "rgba(249, 65, 68, 0.2)",
                borderColor: "rgba(249, 65, 68, 1)",
                pointBorderRadius: 10, // Điểm dữ liệu
                borderRadius: 20, // Đường dữ liệu
                tension: 0.3,
                options: {
                    responsive: true, // Instruct chart js to respond nicely.
                    maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
                }
            },
            {
                label: "Doanh thu",
                data:  costStore.dataRevenueLineChart,
                fill: true,
                backgroundColor: "rgba(0, 171, 86, 0.2)",
                borderColor: "#00AB56",
                pointBorderRadius: 10, // Điểm dữ liệu
                borderRadius: 20, // Đường dữ liệu
                tension: 0.3,
                options: {
                    responsive: true, // Instruct chart js to respond nicely.
                    maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
                }
            }
        ]
    };
    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true, // Đặt trục y bắt đầu từ 0
            }
        }
    };
    return (
        <>
            <div className='finance_container'>
                <div className='finance_container__header'>
                    <div className='d-flex w-100 justify-content-between align-items-center'>
                        <TabRedirect tab='Tổng quan tài chính' />
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            isSearchYear
                            searchYear={<DatePicker
                                picker="year"
                                placeholder="Năm"
                                value={costStore.searchYear ? dayjs(costStore.searchYear, 'YYYY') as any : null}
                                onChange={handleSearchYearChange}
                                bordered={false}
                            />}
                        />
                    </div>
                </div>
                <Line style={{maxHeight: '650px'}} data={data} options={chartOptions} />
            </div>
        </>
    );
}

export default observer(DashboardFinancePage);