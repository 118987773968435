import React from 'react';
import {observer} from "mobx-react";
import './ChangeStatusRequest.scss'
import {browserStore} from "../../BrowserStore";
import {Spin} from "antd";
const ChangeStatusRequestInFooter = ({width}: any) => {
    return (
            <div className="footer approve_footer__container" style={{width: `calc(100% - ${width}px)`}}>
                <div className='w-100 d-flex justify-content-end me-3'>
                    <button
                        onClick={async () => {
                            browserStore.dataRequestChangeStatus = {
                                ids: browserStore.listSelectRowRequest,
                                status: "REJECT"
                            }
                            await browserStore.changeStatusRequest()
                        }}
                        className='reject_request__btn'>
                        {browserStore.isLoadingBt ? <Spin size="small" /> : "Từ chối"}
                    </button>
                    <button
                        className='approve_request__btn'
                        onClick={async () => {
                            browserStore.dataRequestChangeStatus = {
                                ids: browserStore.listSelectRowRequest,
                                status: "APPROVED"
                            }
                            await browserStore.changeStatusRequest()
                        }}
                    >
                        {browserStore.isLoadingBt ? <Spin size="small" /> : "Duyệt"}
                    </button>
                </div>
            </div>
    );
}

export default observer(ChangeStatusRequestInFooter);