import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {voucherStore} from "./VoucherStore";

class VoucherService {
    public getPromotions(size: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/promotion?page=${voucherStore.page}&size=${size ?? 10}${voucherStore.searchTypePromotion ? `&type=${voucherStore.searchTypePromotion}` : ''}${voucherStore.searchStatusPromotion ? `&state=${voucherStore.searchStatusPromotion}` : ''}`);
    }
    public getVoucherByPromotionId(id: any , size?: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/voucher?promotionId=${id}${voucherStore.searchNameCode ? `&code=${voucherStore.searchNameCode}` : ''}${voucherStore.searchTypeVoucher ? `&state=${voucherStore.searchTypeVoucher}` : ''}&page=${voucherStore.page}&size=${size ?? 50}`);
    }
    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/iss_365_fnb/promotion/${id}`, {id});
    }
    public getDetailPromotion(id: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/promotion/${id}`);
    }
    public addPromotion(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/promotion`, data);
    }

    public updatePromotion(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/promotion/${id}`, data);
    }
    public getListProduct(keyword : string): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product?page=0&${keyword ? `keyword=${keyword}` : ""}&size=${1000}`)
    }
}

export const voucherService = new VoucherService();