import React from 'react';
import {observer} from "mobx-react";
import './ModalSettingRole.scss'
import {Checkbox, Col, Row} from 'antd';
import {CheckboxValueType} from "antd/es/checkbox/Group";
interface IProps {
    listRole: any[],
    onChange: (key: string, selectedValues: CheckboxValueType[]) => void,
    onChangeAll: any
    title: string,
    keyRole: string,
    value: any[]
}

const CardRole = ({listRole, onChange, title, keyRole,value, onChangeAll} : IProps) => {

    return (
        <div className='card-role__container'>
            <div className='card-role__header'>
                <span className='card-role__header_name'>
                    {title}
                </span>
                <Checkbox value="ALL"
                          checked={value.length === listRole.map((item: any) => {
                              return item.value
                          }).length}
                    onChange={onChangeAll}
                >Chọn tất cả</Checkbox>
            </div>
            <div  className='card-role__header_line'>
            </div>
            <div className='card-role__content w-100'>
                <Checkbox.Group style={{ width: '100%' }} value={value} onChange={(selectedValues: CheckboxValueType[]) => onChange(keyRole, selectedValues)}>
                    <Row>
                        {listRole.map((item: any, i: number) => {
                            return (
                                <Col key={i} span={8} className='mb-2'>
                                    <Checkbox checked={item.checked} value={item.value}>{item.label}</Checkbox>
                                </Col>
                            )
                        })}
                    </Row>
                </Checkbox.Group>
            </div>
        </div>
    )

}

export default observer(CardRole);

