import React from 'react';
import {observer} from "mobx-react";
import './AddFeedbackModal.scss'
import {Col, Radio, Row} from 'antd';
import type { RadioChangeEvent } from 'antd';
interface IProps {
    listFeedbackItem: any[],
    onChange: (key: string, e: RadioChangeEvent) => void,
    title: string,
    keyRole: string,
    value: any
}

const CardFeedback = ({listFeedbackItem, onChange, title, keyRole,value} : IProps) => {
    return (
        <div className='card-feedback__container'>
            <div className='card-feedback__header'>
                <span className='card-feedback__header_name'>
                    {title}
                </span>
            </div>
            <div  className='card-feedback__header_line'>
            </div>
            <div className='card-feedback__content w-100'>
                <Radio.Group style={{ width: '100%' }} value={value} onChange={(e: RadioChangeEvent) => onChange(keyRole, e)}>
                    <Row>
                        {listFeedbackItem.map((item: any, i: number) => {
                            return (
                                <Col key={i} span={24} className='mb-2'>
                                    <Radio value={item.value}>{item.label}</Radio>
                                </Col>
                            )
                        })}
                    </Row>
                </Radio.Group>
            </div>
        </div>
    )

}

export default observer(CardFeedback);

