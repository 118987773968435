import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import $ from "jquery";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {suppliesService} from "./SuppliesService";

export enum ITypeSupplies {
    packaging = 'packaging',
    additive = "additive",
    materials = "materials",
    devices = "devices"
}


class SuppliesStore {
    id: any = ''
    typeModal: string = ''
    type: any = null
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listSupplies: any[] = []
    dataRequest: any = {
        id: '',
        name: '',
        code: '',
        unit: ''
    }
    errors: any = {
        name: '',
        code: '',
        unit: '',
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearError() {
        this.errors = {
            name: '',
            code: '',
            unit: '',
        }
    }

    clearForm() {
        this.clearError()
        suppliesStore.dataRequest = {
            id: '',
            name: '',
            code: '',
            unit: ''
        }
    }


    async getSupplies() {
        this.isLoading = true
        const result = await suppliesService.getSupplies()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listSupplies = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetail(id: number) {
        this.typeModal = 'edit'
        const result = await suppliesService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        }
    }

    returnName(){
        switch (this.type) {
            case ITypeSupplies.materials:
                return "nguyên liệu"
            case ITypeSupplies.additive:
                return "phụ gia"
            case ITypeSupplies.packaging:
                return "bao bì"
            case ITypeSupplies.devices:
                return "thiết bị"
        }
    }

    async addEdit() {
        let {id, name, code, unit} = this.dataRequest
        this.clearError()

        let data = {
            name: name,
            code: code,
            unit: unit,
            type: this.type.toUpperCase()
        }
        if(!name){
            this.errors.name = `Tên ${this.returnName()} không được để trống!`
        }

        if(!code){
            this.errors.code = `Mã ${this.returnName()} không được để trống!`
        }

        if(!unit){
            this.errors.unit = "Đơn vị không được để trống!"
        }

        if(!name || !code || !unit){
            return
        }

        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await suppliesService.add(data) : await suppliesService.edit(id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} ${this.returnName()} thành công!`)
            if(this.typeModal == 'edit') this.typeModal = ''
            this.clearForm()
            await suppliesStore.getSupplies()
        } else toastUtil.error(result.body.message, 1)
    }

    async delete() {
        suppliesStore.isLoadingBt = true
        const result = await suppliesService.delete(suppliesStore.id)
        suppliesStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#close_confirm').trigger('click')
            toastUtil.success(`Xóa ${suppliesStore.returnName()} thành công!`)
            await suppliesStore.getSupplies()
        } else toastUtil.error(result.body.message, 2)
    }

}

export const suppliesStore = new SuppliesStore()