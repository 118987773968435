import React from 'react';
import {observer} from "mobx-react";
import './ModalSelectTable.scss'
import TableSelected from "../tableSelected/TableSelected";
import {orderStore} from "../../OrderStore";
import $ from "jquery";
import Loading from "../../../../modules/components/Loading";
const ModalSelectTable = () => {
    const handleSelectedTable = (value: any) => {
        const tableSelected  = orderStore.listTable.filter((item: any) => item.value === value)[0]
        if(tableSelected.status === "ACTIVE") {
            orderStore.listTable.filter((item: any) => item.value === value)[0].status = "SELECTED"
            orderStore.dataRequest.tableId = orderStore.listTable.filter((item: any) => item.value === value)[0]?.value
            orderStore.listTable.forEach(item => {
                    if (item.value !== value &&  item.status === "SELECTED") {
                        item.status = "ACTIVE";
                    }
                });
        }
    }
    const closeModal = () => {
        $('#select_table').trigger('click')
        localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
        localStorage.setItem('bookingInfo' , JSON.stringify(orderStore.bookingInfo))
        localStorage.setItem('checkinTime' , JSON.stringify(orderStore.checkinTimeOrder))
        window.location.href = '/phuc-vu/chon-mon';
    }
    const closeModalOrder = async () => {
        $('#select_table').trigger('click')
        await orderStore.addOrder()
    }
    return (
        <>
            <div className='modal fade' id='select_table' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_table__container'>
                             <div className='w-100'>
                                <button data-bs-dismiss="modal" data-bs-toggle="modal"
                                        data-bs-target='#select_room_order' className='button_move_left'>
                                    <img src="/assets/ico/action/move-left.svg" alt=""/>
                                </button>
                            </div>


                            <h1 className='modal_select_table__title'>
                                CHỌN BÀN
                            </h1>
                            {orderStore.isLoading ? <Loading /> : <div className='modal_select_table__cardContainer'>
                                {
                                    orderStore.listTable.map((item: any, index: number) =>
                                            <TableSelected key={index} onSelected={handleSelectedTable} value={item.value} type={item.status} name={item.label} />
                                    )
                                }
                            </div>}
                            {
                                orderStore.typeModal !== 'edit' ?
                                orderStore.dataRequest.type === 'ORDER' ? <div className='w-100 mt-5 d-flex justify-content-end align-items-center'>
                                    <button onClick={() => closeModalOrder()} className='btn_add_service px-5 py-3'>Hoàn thành</button>
                                </div> : <div className='w-100 mt-5 d-flex justify-content-end align-items-center'>
                                    <button onClick={() => closeModal()} className='btn_add_service px-5 py-3'>Tiếp theo</button>
                                </div> : <div className='w-100 mt-5 d-flex justify-content-end align-items-center'>
                                        <button onClick={async () => {
                                            await orderStore.addOrder()
                                        }
                                        } className='btn_add_service px-5 py-3'>Lưu</button>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default observer(ModalSelectTable);
