import { observer } from 'mobx-react'
import { useMemo } from 'react'
import 'react-quill/dist/quill.snow.css'
// @ts-ignore
import ReactQuill from 'react-quill';
import {businessPlanStore} from "../../BusinessPlanStore";
function QuillTextEditor({ valueQuill }: any) {
    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ size: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ direction: 'rtl' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    [{ direction: 'rtl' }],
                    [{ color: [] }, { background: [] }],
                    ['link'],
                    ['clean'],
                ],
            },

            clipboard: {
                matchVisual: false,
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const formats = [
        'header',
        'font',
        'align',
        'direction',
        'script',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'color',
        'background',
    ]

    return (
        <ReactQuill
            modules={modules}
            formats={formats}
            theme="snow"
            placeholder="Nhập mô tả ..."
            value={valueQuill}
            onChange={(e: any) => {
                businessPlanStore.dataRequestUpdateContentForJob.description = e
            }}
        />
    )
}

export default observer(QuillTextEditor)
