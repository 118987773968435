import {makeAutoObservable} from "mobx";
import $ from 'jquery'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {restaurantStore} from "../restaurants/RestaurantStore";
import {tableService} from "./TableService";


class TableStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    status: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isDisableEditBtn: boolean = false
    isLoadingBt: boolean = false
    establishmentId: any = null
    establishmentName: string = ''
    roomId : any = null
    roomName : string = ''
    listRoom: any[] = []
    listTable: any[] = []
    dataRequest: any = {
        name: "",
        roomId: null,
        status: null,
    }

    errors: any = {
        name: "",
        establishmentId: "",
        status: "",
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            name: "",
            status: null,
        }
        this.errors = {
            name: "",
            establishmentId: "",
            status: "",
        }
    }

    async getListTable() {
        this.isLoading = true
        const result = await tableService.getListTable()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listTable = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getListRoom() {
        const result = tableStore.establishmentId ?  await tableService.getListRoom(10000) : await tableService.getListRoomEdit(10000)
        if (result.status === HttpStatusCode.OK) {
            this.listRoom = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }

    async getDetailTable(id: number) {
        this.typeModal = 'edit'
        const result = await tableService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body
        }
    }

    async addEditTable() {
         let data = {
            name: tableStore.dataRequest.name,
            status: tableStore.dataRequest.status,
            roomId: tableStore.dataRequest.roomId,
            establishmentId: tableStore.establishmentId
        }
        if(!this.dataRequest.name) {
            tableStore.errors.name = "Tên bàn không được để trống"
            return false
        }
        if(!this.dataRequest.status) {
            tableStore.errors.status = "Tình trạng của bàn không được để trống"
            return false
        }
        if(!this.establishmentId) {
            tableStore.errors.establishmentId = "Vui lòng chọn địa điểm bán"
            return false
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await tableService.addTable(data) : await tableService.editTable(tableStore.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#add_edit_room').trigger('click')
            toastUtil.success(`${this.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} bàn thành công!`)
            await tableStore.getListTable()
            tableStore.isDisableEditBtn = true
        } else toastUtil.error(result.body.message)
    }
    async deleteTable() {
        restaurantStore.isLoadingBt = true
        const result = await tableService.deleteTable(tableStore.id)
        restaurantStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa bàn thành công!')
            await tableStore.getListTable()
        } else toastUtil.error(result.body.message)
    }

}

export const tableStore = new TableStore()