import {getRequest, IApiResponse, deleteRequest, putRequest, postRequest} from "../../../common/helpers/RequestHelper";
import {trademarkStore} from "./TrademarkStore";

class TrademarkService{

    public getTrademark(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/brand_research${trademarkStore.searchStatus ? `?status=${trademarkStore.searchStatus}` : ''}`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/brand_research/${id}`)
    }

    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/brand_research`, data)
    }

    public edit(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/brand_research/${id}`, data)
    }

    public delete(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/brand_research/${id}`, {})
    }


}

export const trademarkService = new TrademarkService()