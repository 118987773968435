import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import './DetailListVoucher.scss'
import FilterComponent, {IFilter} from "../../../../../modules/components/filterGlobal/FilterGlobal";
import {voucherStore} from "../../VoucherStore";
import TabRedirect from "../../../../../modules/components/tabRedirect/TabRedirect";
import Loading from "../../../../../modules/components/Loading";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import {getIDParameter, sttPage} from "../../../../../common/utils/Utils";
import {convertStatusVoucher} from "../../VoucherPage";
import {Spin, Tooltip} from "antd";


export const convertTypePromotion = (value: string) => {
    switch (value.toUpperCase()) {
        case "VALUE":
            return "Voucher giá trị"
        case "GIVE_PRODUCT":
            return "Voucher tặng sản phẩm"
        case "COMBO":
            return "Voucher Combo"
        case "PRODUCT":
            return "Voucher mua nhiều giảm giá"
        case "BIRTHDAY":
            return "Voucher tặng sinh nhật"
        default:
            return null
    }
}

const DetailVoucherPage = () => {
    const [isCopy, setIsCopy] = useState(false)
    const [code, setCode] = useState("")
    const handleCopyClick = (code: any) => {
        setCode(code)
        setIsCopy(true)
        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.value = code;
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setTimeout(() => {
            setIsCopy(false)
        }, 200);
    }

    const dataFilter : IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm mã...',
            value: voucherStore.searchNameCode,
            onChange: (e: any) => {
                voucherStore.searchNameCode = e
            },
            callback: async () => {
                await voucherStore.getVoucherByPromotionId(getIDParameter(3)).then()
            }
        },
        {
            label: 'Tình trạng',
            type: 'select',
            value: voucherStore.searchTypeVoucher,
            placeholder: 'Tất cả',
            listValue: [{
                value: "UNUSED",
                label: "Chưa sử dụng"
            }, {
                value: "USED",
                label: "Đã sử dụng"
            }],
            callback: async (e: any) => {
                voucherStore.searchTypeVoucher = e
                await voucherStore.getVoucherByPromotionId(getIDParameter(3)).then()
            }
        }
    ]

    useEffect(() => {
        voucherStore.getDetail(getIDParameter(3)).then()
        voucherStore.getVoucherByPromotionId(getIDParameter(3)).then()
    }, [])
    return (
        <>
            <div className='voucher_detail_container'>
                <div className='modal-voucher--content'>
                    <div className='modal-voucher--head'>
                        <div className='modal-voucher--headFirst'>
                            <div className='d-flex'>
                                <div className='modal-voucher--name d-flex flex-column pe-4'>
                                    <span>Voucher: </span>
                                    <span>Thời gian: </span>
                                    <span>Loại: </span>
                                    {(voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' || voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIFIC_AMOUNT')
                                        && <span>Mức giảm: </span>
                                    }
                                </div>
                                <div className='modal-voucher--value d-flex flex-column'>
                                    <span>{voucherStore.dataRequestValueVoucher?.name}</span>
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" ? <span>{`${dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.startTime)} - ${dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.endTime)}`}</span>
                                        :<span>{`${dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.startTime)} - Vô thời hạn`}</span> }
                                    <span>{convertTypePromotion(voucherStore.dataRequestValueVoucher?.type)}</span>
                                    {
                                        voucherStore?.dataRequestValueVoucher?.type === "PRODUCT" ? <div>
                                            <span>Theo số sản phẩm</span>
                                            {
                                                voucherStore?.dataRequestValueVoucher?.proviso?.buyMore?.map((item: any, index: number) => {
                                                    return <div key={index}>
                                                        ( <span>{item.from
                                                        }</span>
                                                        <span className='mx-1'>-</span>
                                                        <span>{item.to
                                                        }</span> )
                                                        <span className='mx-1'>:</span>
                                                        <span className='mx-1'>Giảm {numberUtil.number_format(item?.discount)}</span>
                                                        <span className='text-decoration-underline'>{voucherStore?.dataRequestValueVoucher?.proviso?.type === "PERCENTAGE" ? "%" : "đ"}</span>
                                                    </div>
                                                })
                                            }
                                        </div> : ((voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' || voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIFIC_AMOUNT')
                                            &&
                                            <span>{voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' ? `${numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value)} %` : `${numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value)} đ`}</span>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='modal-voucher--headSecond'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex w-100'>
                                    <div className='modal-voucher--name d-flex flex-column pe-4'>
                                        <span>Giá trị đơn tối thiểu:</span>
                                        {voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' && <span>Giá trị Voucher tối đa:</span>}
                                        <span>Địa điểm áp dụng: </span>
                                        {voucherStore.dataRequestValueVoucher.type === "COMBO" && <span>Combo áp dụng: </span>}
                                        {voucherStore.dataRequestValueVoucher.type === "BIRTHDAY" && <span>Theo: </span>}
                                    </div>
                                    <div className='modal-voucher--value d-flex flex-column align-items-start'>
                                        <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.minOrderValue)} đ</span>
                                        {voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' && <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount)} đ</span>}
                                        <span>Tất cả cửa hàng </span>
                                        {voucherStore.dataRequestValueVoucher.type === "COMBO" && <span>{voucherStore.dataRequestValueVoucher?.proviso?.products?.map((item: any, i: number) => {
                                            return <span key={i}>{(i+1 === voucherStore.dataRequestValueVoucher?.proviso?.products?.length) ?`${item?.label}` : `${item?.label} ,`}</span>
                                        })}</span>}
                                        {voucherStore.dataRequestValueVoucher.type === "BIRTHDAY" && <span>{voucherStore.dataRequestValueVoucher.proviso?.birthdayDiscount === "FOR_MONTH" ? "Tháng" : "Ngày"}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-voucher--headFirst'>
                            <div className='d-flex'>
                                <div className='modal-voucher--name d-flex flex-column pe-4'>
                                    <span>Tổng số phát hành: </span>
                                    <span>Voucher đã sử dụng: </span>
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" &&  <span>Chưa sử dụng: </span>}
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" && <span>Hết hạn: </span>}
                                </div>
                                <div className='modal-voucher--value d-flex flex-column'>
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" ? <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.amount)} mã</span> : <span>Không giới hạn</span>}
                                    <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.amountUsed)} mã</span>
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" && <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.amount - voucherStore.dataRequestValueVoucher?.amountUsed)} mã</span>}
                                    {voucherStore.dataRequestValueVoucher?.type !== "BIRTHDAY" && <span>{dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.endTime)}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='voucher_detail_container__header'>
                    <TabRedirect tab='Danh sách mã' />
                    <div>
                        <FilterComponent
                            data={dataFilter}
                        />
                    </div>
                </div>
                {voucherStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex  mb-4 justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Mã Voucher</th>
                            <th className="text-center">Điểm sử dụng</th>
                            <th className="text-center">Ngày sử dụng</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {voucherStore.listVoucherByPromotionId.length <= 0 ? <tr>
                                <td style={{height: '200px'}} colSpan={8} className='text-center fw-500'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        Chưa có dữ liệu
                                    </div>
                                </td>
                            </tr> :
                            voucherStore.listVoucherByPromotionId.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">
                                            {sttPage(voucherStore.page, i)}
                                        </td>
                                        <td className="text-center">{item?.code}</td>
                                        <td className="text-center">-</td>
                                        <td className="text-center">{item?.applyTime ? dateUtils.formatDate(item?.applyTime) : "-"}</td>
                                        <td className="text-center">{convertStatusVoucher(item?.state)}</td>
                                        <td className="text-center fw-500">
                                            <div className='d-flex justify-content-center'>
                                                    <button className="button_copy"
                                                            onClick={ () => {
                                                                handleCopyClick(item.code)
                                                            }}>
                                                        <Tooltip title={`Copy mã #${item.code}`}>
                                                            {
                                                                (isCopy && code === item?.code) ? <Spin size="small" /> : 'Sao chép'
                                                            }
                                                        </Tooltip>
                                                    </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
            </div>
        </>
    );
}

export default observer(DetailVoucherPage);