import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {productStore} from "./ProductStore";

class ProductService{

    public getListProduct(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product?page=${productStore.page}${productStore.searchType ? `&type=${productStore.searchType}` : ''}&size=${size ?? 50}`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/product/${id}`)
    }

    public addProduct(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/product`, data)
    }

    public editProduct(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/product/${id}`, data)
    }

    public deleteProduct(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/product/${id}`, {})
    }
    public getListSupplies(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/supplies?page=0${productStore.typeSupplies ? `&type=${productStore.typeSupplies}` : ''}&size=${size ?? 1000}`)
    }

}

export const productService = new ProductService()