import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {importExportStore} from "../importExportSupplies/ImportExportStore";
import ModalAddRequestPurchase from "./components/addRequestPurchase/AddRequestPurchase";
import {purchaseStore} from "./PurchaseStore";
import './PurchasePage.scss'
import {numberUtil} from "../../../common/utils/NumberUtil";
import {Link} from "react-router-dom";

const SummaryPurchasePage = () => {
    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: importExportStore.keyword,
            onChange: (e: any) => importExportStore.keyword = e,
            callback: async () => await importExportStore.getImpExpRequest()
        },
    ]
    useEffect(() => {
        purchaseStore.getListSummaryPurchase().then()
        purchaseStore.getListUser().then()
    }, [])

    const handleAddSummaryPurchase = () => {
        purchaseStore.dataRequest.items = purchaseStore.listSummaryPurchase.filter((item: any) => ((item.qtyRequest - item.qtyInventory) > 0)).map((item: any) => {
            return {
                id: item.suppliesId,
                name: item.suppliesName,
                code: item.suppliesCode,
                unit: item.unit,
                qty: (item.qtyRequest - item.qtyInventory),
                expectedDate: "",
                note: ""
            }
        })
    }

    return (
        <div className="summary_purchase_page">
            <div className='tab_filter_header'>
                <Link to='/purchase'>
                    <img src="/assets/ico/action/move-left.svg" alt="back" className='me-2'/>
                    <h4 className='title'>
                        Tổng hợp đơn
                    </h4>
                </Link>
            </div>
            <FilterComponent permissionCode={true}
                             data={dataFilter}
                             buttonAdd={<ButtonGlobal onClick={handleAddSummaryPurchase} dataTarget="add_request_purchase" text='Thêm' ico='add_icon' className='btn_add'/>}/>
            {purchaseStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Vật tư</th>
                        <th className="text-center">Mã vật tư</th>
                        <th className="text-center">ĐVT</th>
                        <th className="text-center">Số lượng cần</th>
                        <th className="text-center">Kho tồn</th>
                        <th className="text-center">Cần mua</th>
                    </tr>
                    </thead>
                    <tbody>
                    {purchaseStore.listSummaryPurchase && purchaseStore.listSummaryPurchase.length > 0 ? purchaseStore.listSummaryPurchase.map((item: any, i: number) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item?.suppliesName}</td>
                                <td className="text-center">{item?.suppliesCode}</td>
                                <td className="text-center">{item.unit}</td>
                                <td className="text-center">{numberUtil.number_format(item.qtyRequest)}</td>
                                <td className="text-center">{numberUtil.number_format(item.qtyInventory)}</td>
                                <td className="text-center">{(item.qtyRequest - item.qtyInventory) > 0 ? numberUtil.number_format((item.qtyRequest - item.qtyInventory)) : 0}</td>
                            </tr>
                        )
                    }) : <NoContent colSpan={8}/>}
                    </tbody>
                </table>
            </div>}
            <ModalAddRequestPurchase />
        </div>
    );

}

export default observer(SummaryPurchasePage);