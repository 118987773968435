import {makeAutoObservable} from "mobx";
import {accountService} from "./AccountService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {validatePhoneNumber} from "../../../common/utils/Utils";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";

export interface IDataRequestAddAccount {
    id: any
    phone: string
    name: string
    establishmentId: any
    managerId : any
    departmentId : []
    roleId: any[]
    rank : any
    type: string
}


class AccountStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listDepartment: any[] = []
    listRestaurant: any[] = []
    listRole: any[] = []
    listManager: any[] = []
    listAccount: any[] = []
    isValidateUsername : boolean = false
    dataCheckPhone: any = {}
    dataRequest: IDataRequestAddAccount = {
        id: "",
        phone: "",
        name: "",
        establishmentId: null,
        managerId : null,
        departmentId : [],
        roleId: [],
        rank: null,
        type: 'STAFF'
    }
    errors: any = {
        phone: "",
        name: "",
        usernameNotExits: "",
        validateUsername: "",
        establishmentId: "",
        managerId: "",
        departmentId: "",
        roleId: "",
        rank: ""
    }
    dataRequestChangeStatus: any = {
        ids: [],
        status: ""
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            id: "",
            phone: "",
            name: "",
            establishmentId: null,
            managerId : null,
            departmentId : [],
            roleId: [],
            rank: null,
            type: 'STAFF'
        }
        this.errors = {
            phone: "",
            name: "",
            usernameNotExits: "",
            validateUsername: "",
            establishmentId: "",
            managerId: "",
            departmentId: "",
            roleId: "",
            rank: ""
        }
        this.isValidateUsername = false
    }

    async checkUsername() {
        if(!this.dataRequest.phone) {
            this.errors.username = "Vui lòng nhập số điện thoại !"
            return false
        }
        else this.errors.username = ""
        if(!validatePhoneNumber(this.dataRequest.phone)) {
            this.errors.validateUsername = "Vui lòng nhập đúng định dạng số điện thoại !"
            return false
        }
        else this.errors.validateUsername = ""
        await accountStore.getCheckerPhone()
        await accountStore.getDepartments()
        await accountStore.getListRestaurant()
        await accountStore.getListRole()
        await accountStore.getListManager()
        return true
    }
    validateUsername() {
        return !(!this.dataRequest.phone || !validatePhoneNumber(this.dataRequest.phone));
    }

    async getAccount() {
        this.isLoading = true
        const result = await accountService.getAccount()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listAccount = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDepartments() {
        const result = await accountService.getDepartments()
        if (result.status === HttpStatusCode.OK) {
            accountStore.listDepartment = result.body.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
    }
    async getListRestaurant() {
        const result = await accountService.getListRestaurant()
        if (result.status === HttpStatusCode.OK) {
            accountStore.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
    }
    async getListRole() {
        const result = await accountService.getListRole()
        if (result.status === HttpStatusCode.OK) {
            accountStore.listRole = result.body?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
    }
    async getListManager() {
        const result = await accountService.getListManager()
        if (result.status === HttpStatusCode.OK) {
            accountStore.listManager = result.body.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
    }
    async getDetailAccount(id: any) {
        await accountStore.getDepartments()
        await accountStore.getListRestaurant()
        await accountStore.getListRole()
        await accountStore.getListManager()
        const result = await accountService.getAccountById(id)
        if (result.status === HttpStatusCode.OK) {
            accountStore.dataRequest = {
                id: result.body?.id,
                phone: result.body?.phone,
                name: result.body?.name,
                establishmentId: result.body?.establishment?.id,
                managerId : result.body?.manager?.id,
                departmentId : result.body?.departments?.map((item: any) => {
                    return item.id
                }),
                roleId: result.body?.roles?.map((item: any) => {
                    return item.id
                }),
                rank: result.body?.rank,
                type: result.body.type
            }
        }
    }
    async getCheckerPhone() {
        this.isLoadingBt = true
        const result = await accountService.getCheckerPhone()
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            accountStore.dataCheckPhone = result.body
            accountStore.dataRequest = {
                ...accountStore.dataRequest,
                id: result.body?.id,
                name: result.body?.name
            }
            if(!accountStore.dataCheckPhone.phone) {
                accountStore.errors.usernameNotExits = "*Số điện thoại chưa được đăng ký Ommani (Mật khẩu mặc định: 123456)"
            }
            else accountStore.errors.usernameNotExits = ""
        }
    }
    async addAccount() {
        if(!this.dataRequest.roleId) {
            this.errors.roleId = "Chức vụ không được để trống !"
            return
        }
        if(!this.dataRequest.name) {
            this.errors.name = "Vui lòng nhập tên !"
            return
        }
        if(!this.dataRequest.rank) {
            this.errors.rank = "Cấp bậc không được để trống !"
            return
        }
        this.isLoadingBt = true
        const result =  await accountService.add(accountStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${accountStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} tài khoản thành công!`, 1)
            $('#add_edit_account').trigger('click')
            this.clearForm()
            await accountStore.getAccount()
        } else toastUtil.error(result.body.message, 2)
    }
    async changeStatusRequest(id: any, status: any) {
        this.dataRequestChangeStatus = {
            ids: [id],
            status : status
        }
        this.isLoadingBt = true
        const result = await accountService.changeStatus(this.dataRequestChangeStatus)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${status === "BLOCK" ? "Khóa" : "Mở khóa"} tài khoản nhân viên thành công !`)
            await accountStore.getAccount()
            accountStore.dataRequestChangeStatus = {
                ids: [],
                status: ""
            }
        } else toastUtil.error(result.body.message)
    }
}

export const accountStore = new AccountStore();