import React from 'react';
import {observer} from "mobx-react";
import './SelectTypeFood.scss'
import ModalDryFoodCheckSheet from "../dryFoodCheckSheet/DryFoodCheckSheet";
import ModalFreshFoodCheckSheet from "../freshFoodCheckSheet/FreshFoodCheckSheet";

const ModalSelectTypeFood = () => {
    return (
        <>
            <div className='modal fade' id='select_type_food' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_voucher__container'>
                            <h1 className='modal_select_voucher__title'>
                                Chọn loại
                            </h1>
                            <div className='modal_select_voucher__cardContainer'>
                                <button onClick={() => {}} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#dry_food_check_sheet' className='modal_select_voucher__card'>
                                    <div className='modal_select_voucher__cardTitle'>
                                        ĐỒ KHÔ
                                    </div>
                                </button>
                                <button onClick={() => {}} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#fresh_food_check_sheet' className='modal_select_voucher__card'>
                                    <div className='modal_select_voucher__cardTitle'>
                                        ĐỒ SỐNG
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDryFoodCheckSheet />
            <ModalFreshFoodCheckSheet />
        </>

    )

}

export default observer(ModalSelectTypeFood);
