import {makeAutoObservable} from "mobx";
import {getRequest} from "../../../common/helpers/RequestHelper";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";

class DataStore {
    keyword: any = ''
    state: any = null
    isLoading: boolean = false
    totalPage: number = 0
    listData: any[] = []
    dataDetail: any = {

    }

    constructor() {
        makeAutoObservable(this)
    }


    async getData() {
        this.isLoading = true
        const result = await getRequest(`/iss_365_fnb/order/fnb?page=0&size=100${dataStore.keyword ? `&keyword=${this.keyword.trim()}` : ''}${dataStore.state ? `&status=${this.state}` : ''}`)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listData = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: number){

        const result = await getRequest(`/iss_365_fnb/order/${id}`)
        if(result.status === HttpStatusCode.OK){
            this.dataDetail = result.body
        }
    }


}

export const dataStore = new DataStore()