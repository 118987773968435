import React from 'react';
import {observer} from "mobx-react";
import './DetailFeedbackModal.scss'
import {customerFeedbackStore} from "../../CustomerFeedbackStore";
const DetailFeedbackModal = () => {
    return (
        <>
            <div className='modal fade' id='detail_feedback' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal-header'>
                            <div className='detail_feedback__title'>CHI TIẾT PHIẾU THÔNG TIN</div>
                            <button type="button" id="detail_feedback" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className='stock_container__content'>
                                <div className='stock_wrap'>
                                    <span className='stock_wrap__title'>1. Thông tin khách hàng</span>
                                    <div className='d-flex flex-column align-items-start g-1'>
                                        <div className='stock_wrap__subtitle'>1.1. Họ & Tên : <span className='fw-500'>{customerFeedbackStore.dataDetailFeedback?.user?.name}</span></div>
                                        <div className='stock_wrap__subtitle'>1.2. Số điện thoại: <span className='fw-500'>{customerFeedbackStore.dataDetailFeedback?.user?.phone}</span></div>
                                        <div className='stock_wrap__subtitle'>1.3. Giới tính: <span className='fw-500'>{customerFeedbackStore.dataDetailFeedback?.user?.gender === "MALE" ? "Nam": 'Nữ'}</span></div>
                                        <div className='stock_wrap__subtitle'>1.4. Ngày sinh: <span className='fw-500'>{customerFeedbackStore.dataDetailFeedback?.user?.birthday ?? "-"}</span></div>
                                        <div className='stock_wrap__subtitle'>1.5. Địa chỉ: <span className='fw-500'>{customerFeedbackStore.dataDetailFeedback?.user?.address ?? "-"}</span></div>
                                    </div>
                                </div>
                                <div className='stock_wrap'>
                                    <span className='stock_wrap__title'>2. Không gian & thái độ phục vụ</span>
                                    <div className='d-flex flex-column align-items-start g-1'>
                                        <div className='stock_wrap__subtitle'>2.1. Không gian: <span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.space === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.space === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.space === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></div>
                                        <div className='stock_wrap__subtitle'>2.2. Vệ sinh: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.hygiene === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.hygiene === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.hygiene === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></span></div>
                                        <div className='stock_wrap__subtitle'>2.3. Thái độ phục vụ: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.serviceAttitude === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.serviceAttitude === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.serviceAttitude === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></span></div>
                                        <div className='stock_wrap__subtitle'>2.3. Thời gian phục vụ: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.serviceTime === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.serviceTime === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.serviceTime === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></span></div>
                                    </div>
                                </div>
                                <div className='stock_wrap'>
                                    <span className='stock_wrap__title'>3. Món ăn, đồ uống</span>
                                    <div className='d-flex flex-column align-items-start g-1'>
                                        <div className='stock_wrap__subtitle'>3.1. Đa dạng menu: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.diverseMenu === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.diverseMenu === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.diverseMenu === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></span></div>
                                        <div className='stock_wrap__subtitle'>3.2. Giá cả: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.pricing === "APPROPRIATE" ? 'stock_wrap__key--active' : ''}>Phù hợp</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.pricing === "LOW" ? 'stock_wrap__key--active' : ''}>Thấp</span> |  <span className={customerFeedbackStore.dataDetailFeedback.pricing === "HIGH" ? 'stock_wrap__key--active' : ''}>Cao</span></span></span></span></div>
                                        <div className='stock_wrap__subtitle'>3.3. Khẩu vị:  <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.palate === "VERY_SATISFIED" ? 'stock_wrap__key--active' : ''}>Rất hài lòng</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.palate === "AVERAGE" ? 'stock_wrap__key--active' : ''}>Bình thường</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.palate === "UNSATISFIED" ? 'stock_wrap__key--active' : ''}>Không hài lòng</span></span></span></div>
                                    </div>
                                </div>
                                <div className='stock_wrap'>
                                    <span className='stock_wrap__title'>4. Thông tin khác</span>
                                    <div className='d-flex flex-column align-items-start g-1'>
                                        <div className='stock_wrap__subtitle'>4.1. Biết cửa hàng qua:  <span className='stock_wrap__key'>: <span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.referralSource === "FACEBOOK" ? 'stock_wrap__key--active' : ''}>Facebook</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.referralSource === "FRIENDS" ? 'stock_wrap__key--active' : ''}>Bạn bè</span>  |  <span className={customerFeedbackStore.dataDetailFeedback.referralSource === "OTHER" ? 'stock_wrap__key--active' : ''}>Khác</span></span></span></div>
                                        <div className='stock_wrap__subtitle'>4.2. Giới thiệu với bạn bè: <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.recommendToFriends ? 'stock_wrap__key--active' : ''}>Có</span>  |  <span className={!customerFeedbackStore.dataDetailFeedback.recommendToFriends ? 'stock_wrap__key--active' : ''}>Không</span></span></span></div>
                                        <div className='stock_wrap__subtitle'>4.3. Quay lại:  <span className='stock_wrap__key'><span className='stock_wrap__key'><span className={customerFeedbackStore.dataDetailFeedback.returnReview ? 'stock_wrap__key--active' : ''}>Có</span>  |  <span className={!customerFeedbackStore.dataDetailFeedback.returnReview ? 'stock_wrap__key--active' : ''}>Không</span></span></span></div>
                                    </div>
                                </div>
                                <div className='stock_wrap'>
                                    <span className='stock_wrap__title'>5. Ý kiến đóng góp</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default observer(DetailFeedbackModal);

