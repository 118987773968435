import {getRequest, IApiResponse, postRequest} from "../../../common/helpers/RequestHelper";
import {stipulationStore} from "../stipulations/StipulationsStore";
import {inventoryReportStore} from "./InventoryReportStore";

class InventoryReportService{
    public getInvetoryReportRequest(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/inventory${inventoryReportStore.searchEstablishmentId ? `?establishmentId=${inventoryReportStore.searchEstablishmentId}` : ''}${stipulationStore.keyword ? `&keyword=${stipulationStore.keyword}` : ''}`)
    }
    public updateInfoInventory(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/inventory/set_norms`, data)
    }
}

export const inventoryReportService = new InventoryReportService()