import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import {warehouseStore} from "../../setting/warehouse/WarehouseStore";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {dateUtils} from "../../../common/utils/DateUtils";
import {suppliesStore} from "../../setting/supplies/SuppliesStore";
import {importExportStore} from "../importExportSupplies/ImportExportStore";
import ModalDetailRequestPurchase from "./components/detailRequestPurchase/DetailRequestPurchase";
import ModalAddRequestPurchase from "./components/addRequestPurchase/AddRequestPurchase";
import {purchaseStore} from "./PurchaseStore";
import './PurchasePage.scss'
import {Link} from "react-router-dom";
import AddImportBuy from "../importExportSupplies/components/AddImportBuy";
const convertStatusImportRequest= (value: string) => {
    switch (value.toUpperCase()) {
        case "NEW":
            return <span style={{fontWeight: '500', color: '#808089'}}>Đang chờ</span>
        case "PROCESSING":
            return <span className="color-green fw-500">Đã duyệt</span>
        case "WAREHOUSED":
            return <span className="color-purple fw-500" >Đã nhập kho</span>
        case "REJECT":
            return <span className="color-red fw-500" >Hủy</span>
        default:
            return null
    }
}
const PurchasePage = () => {
    const dataFilter: IFilter[] = [
        {
            type: 'search',
            placeholder: 'Tìm kiếm theo mã...',
            value: importExportStore.keyword,
            onChange: (e: any) => importExportStore.keyword = e,
            callback: async () => await importExportStore.getImpExpRequest()
        },
    ]

    useEffect(() => {
        purchaseStore.getBuyRequest().then()
        warehouseStore.getWarehouse().then()
        purchaseStore.getListUser().then()
        return () => {
            suppliesStore.type = null
            importExportStore.keyword = ''
            importExportStore.searchWarehouse = null
            suppliesStore.listSupplies = []
            warehouseStore.listWarehouse = []
        }
    }, [])


    return (
        <div className="receipt_page">
            <div className='tab_filter_header d-flex justify-content-between align-items-center'>
                <h4 className='title'>Báo cáo mua hàng</h4>
                <Link to='/purchase/summary' onClick={() => {}} className='btn_sumary__request'>
                    Tổng hợp yêu cầu
                </Link>
            </div>
            <div>
                <FilterComponent permissionCode={true} data={dataFilter}
                                 buttonAdd={<ButtonGlobal onClick={() => {purchaseStore.clearForm()}} dataTarget="add_request_purchase" text='Thêm' ico='add_icon' className='btn_add'/>}/>
            </div>
            {importExportStore.isLoading ? <Loading/> : <div className="table-responsive">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Mã phiếu đề nghị</th>
                        <th className="text-center">Ngày đề nghị</th>
                        <th className="text-center">Ngày duyệt</th>
                        <th className="text-center">Ngày nhập kho</th>
                        <th className="text-center">Tổng tiền</th>
                        <th className="text-center">Trạng thái</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {purchaseStore.listBuyRequest && purchaseStore.listBuyRequest.length > 0 ?purchaseStore.listBuyRequest.map((item: any, i: number) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item?.code}</td>
                                <td className="text-center">{dateUtils.formatDate(item.dataForCreated?.date ,'hh:mm , DD/MM/YYYY')}</td>
                                <td className="text-center">{dateUtils.formatDate(item.dataForApproved?.date, 'hh:mm , DD/MM/YYYY')}</td>
                                <td className="text-center">{dateUtils.formatDate(item.dataForWarehoused?.date, 'hh:mm , DD/MM/YYYY')}</td>
                                <td className="text-center">{item?.mainWarehouse?.name}</td>
                                <td className="text-center">{convertStatusImportRequest(item.status)}</td>
                                <td style={{width: '100px'}} className="text-center">
                                    <button className="btn p-0 mx-1"
                                            onClick={() => purchaseStore.getDetailBuyRequest(item.id)} data-bs-toggle="modal" data-bs-target='#detail_request_purchase'>
                                        <img src="/assets/ico/action/detail_icon.svg" alt=""/>
                                    </button>
                                    {item.status === "PROCESSING" && <button className="btn p-0 mx-1"
                                            onClick={async () => {
                                                importExportStore.dataRequest.toMainWarehouse = false
                                                importExportStore.isChangeStatusWarehouse = true
                                                purchaseStore.id = item.id
                                                importExportStore.typeImportExport = 'import'
                                                importExportStore.clearForm()
                                                await purchaseStore.getDetailBuyRequest(item.id)
                                            }} data-bs-toggle="modal" data-bs-target='#add_import_buy_yourself'>
                                        <img src="/assets/ico/action/folder-input.svg" alt=""/>
                                    </button>}
                                </td>
                            </tr>
                        )
                    }) : <NoContent colSpan={9}/>}
                    </tbody>
                </table>
            </div>}
            <ModalDetailRequestPurchase/>
            <ModalAddRequestPurchase />
            <AddImportBuy text="nhập" />
        </div>
    );

}

export default observer(PurchasePage);