import React from 'react';
import {observer} from "mobx-react";
import '../../purchase/components/addRequestPurchase/AddRequestPurchase.scss'
import {importExportStore} from "../ImportExportStore";
import {DatePicker, Input} from 'antd';
import {numberUtil} from "../../../../common/utils/NumberUtil";
import NoContent from "../../../../modules/components/NoContent";
import {DatePickerProps, RangePickerProps} from "antd/es/date-picker";
import {Moment} from "moment/moment";
import {dateUtils} from "../../../../common/utils/DateUtils";


function convertTime(date: Moment): any {
    if(date) return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    else return ""
}

const ConfirmSuppliesImport = () => {

    return (
        <div className="modal fade" id="confirm_supplies_import" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 1450}}>
                <div className="modal-content px-5">
                    <div className="modal-header">
                        <h5 className="modal-title">PHIẾU XÁC NHẬN NHẬP KHO</h5>
                        <button type="button" id="close_add_imp_exp_request" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='modal-order--content mt-3'>
                        <div className='modal-order--head'>
                            <div className='modal-order--headFirst'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='modal-order--name d-flex flex-column'>
                                        <span>Người đề nghị: </span>
                                        <span>Thời gian đề nghị: </span>
                                        <span>Mục đích: </span>
                                        <span>Kho yêu cầu: </span>
                                        <span>Người duyệt: </span>
                                    </div>
                                    <div className='modal-order--value d-flex flex-column'>
                                        <span>{importExportStore.dataRequest?.requestUser?.name}</span>
                                        <span>{dateUtils.formatDate(importExportStore.dataRequest?.timeRequest, 'hh:mm , DD/MM/YYYY')}</span>
                                        <span>{importExportStore.dataRequest?.purpose}</span>
                                        <span>{importExportStore.dataRequest?.mainWarehouse?.name}</span>
                                        <span>{importExportStore.dataRequest?.mainWarehouse?.user?.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-form-responsive mt-2 mb-3 d-flex justify-content-between">
                        <table className='table align-middle border gy-7 gs-7 mb-0'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Tên vật tư</th>
                                <th className="text-center">Mã vật tư</th>
                                <th className="text-center">ĐVT</th>
                                <th className="text-center">Số lượng</th>
                                <th className="text-center">Đơn giá <span className='text-danger'>*</span></th>
                                <th className="text-center">Thành tiền</th>
                                <th className="text-center">Hạn sử dụng <span className='text-danger'>*</span></th>
                                <th>Ghi chú</th>
                            </tr>
                            </thead>
                            <tbody>
                            {importExportStore.itemsApprovedRequest && importExportStore.itemsApprovedRequest.length < 1 ? <NoContent colSpan={9} height={150} /> : importExportStore.itemsApprovedRequest.map((item: any, i: number) => {
                                return (
                                    <tr key={i} className='position-relative'>
                                        <td className="text-center">{i + 1}</td>
                                        <td  width="15%">{item?.supplies?.name}</td>
                                        <td width="10%" className="text-center">{item?.supplies?.code}</td>
                                        <td className="text-center">{item?.supplies?.unit}</td>
                                        <td width="10%" className="text-center form-group">
                                            <Input
                                                type="text"
                                                disabled
                                                value={item.qty === "" ? "" : numberUtil.number_format(item.qty)}
                                                placeholder='Nhập...'
                                            />
                                        </td>
                                        <td width="15%" className="text-center form-group">
                                            <Input
                                                type="text"
                                                onKeyDown={(evt: {
                                                    key: string
                                                    preventDefault: () => any
                                                }) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        e === '' ||
                                                        Number(numberUtil.regexNumber(e)) >= 0
                                                    ) {
                                                        item.price = numberUtil.regexNumber(e)
                                                    }
                                                }}
                                                status={(importExportStore.isErrorsConfirmSupplies && !item.price) ? 'error' : ''}
                                                value={item.price === "" ? "" : numberUtil.number_format(item.price)}
                                                placeholder='Nhập...'
                                            />
                                        </td>
                                        <td width="14%" className="text-center">{(item.qty && item.price) ? numberUtil.number_format(item.qty * item.price) : ""}</td>
                                        <td  width="20%" className="text-center">
                                            <DatePicker placeholder='Chọn'
                                                        status={(importExportStore.isErrorsConfirmSupplies && !item.expiryDate) ? 'error' : ''}
                                                        showTime
                                                        onChange={(
                                                value: DatePickerProps['value'] | RangePickerProps['value'],
                                            ) => {
                                                // @ts-ignore
                                                item.expiryDate = convertTime(value);
                                            }}/>
                                        </td>
                                        <td width="20%" className="text-center">
                                            <Input
                                                type="text"
                                                disabled
                                                value={item.note}
                                                placeholder='Nhập...'
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            {(importExportStore.dataRequest.items && importExportStore.dataRequest.items.length < 1 && importExportStore.errors.materialId) && <tr>
                                <td colSpan={9}>
                                    <p className="error">{importExportStore.errors.materialId}</p>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className='w-100 d-flex justify-content-end align-items-center'>
                        <button className="button_save" onClick={() => importExportStore.comfirmApprovedRequest()} disabled={importExportStore.isLoadingBt}>
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ConfirmSuppliesImport);