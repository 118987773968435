import React from 'react';
import {observer} from "mobx-react";
import './ModalInfoOrder.scss'
import {ConfigProvider, DatePicker, Input} from 'antd';
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {DatePickerProps} from "antd/es/date-picker";
import {orderStore} from "../../OrderStore";
import ModalSelectRoom from "../modalSelectRoom/ModalSelectRoom";
const ModalAddUserInforOrder = () => {
    function convertTime(date: any): any {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }
    const onChangeDateFinish = async (
        value: DatePickerProps['value'],
    ) => {
        orderStore.checkinTimeOrder = convertTime(value)
        await orderStore.isValidateInforOrder()
    };
    return (
        <>
            <div className='modal fade' id='add_user_infor_order' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal-header px-4'>
                            <div className='value_voucher__title'>
                                THÔNG TIN ĐẶT BÀN
                            </div>
                            <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body p-4">
                            <div className='w-100 form-group'>
                                <label className='form-label'>Tên khách hàng<span className='text-danger'>*</span></label>
                                <Input type='text' value={orderStore.bookingInfo?.name}
                                       status={(orderStore.errorBookingInfo?.name && !orderStore.bookingInfo?.name) ? "error" : ""}
                                       onChange={async (e: any) => {
                                           orderStore.bookingInfo.name = e.target.value
                                           await orderStore.isValidateInforOrder()
                                       }}
                                       placeholder='Nhập tên ...' />
                                {orderStore.errorBookingInfo?.name && !orderStore.bookingInfo.name && <small className="text-error">{orderStore.errorBookingInfo?.name}</small>}
                            </div>
                            <div className='w-100 form-group'>
                                <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                <Input type='number' value={orderStore.bookingInfo?.phone}
                                       status={(orderStore.errorBookingInfo?.phone && !orderStore.bookingInfo?.phone) ? "error" : ""}
                                       onChange={async (e: any) => {
                                           orderStore.bookingInfo.phone = e.target.value
                                           await orderStore.isValidateInforOrder()
                                       }}
                                       placeholder='Nhập số điện thoại ...' />
                                {orderStore.errorBookingInfo?.phone && !orderStore.bookingInfo.phone && <small className="text-error">{orderStore.errorBookingInfo?.phone}</small>}
                            </div>
                            <div className='w-100 form-group'>
                                <label className='form-label'>Số người<span className='text-danger'>*</span></label>
                                <Input type='number' value={orderStore.bookingInfo?.numPeople}
                                       status={(orderStore.errorBookingInfo?.numPeople && !orderStore.bookingInfo?.numPeople) ? "error" : ""}
                                       onChange={async (e: any) => {
                                           orderStore.bookingInfo.numPeople = e.target.value
                                           await orderStore.isValidateInforOrder()
                                       }}
                                       placeholder='Nhập số ...' />
                                {orderStore.errorBookingInfo?.numPeople && !orderStore.bookingInfo.numPeople && <small className="text-error">{orderStore.errorBookingInfo?.numPeople}</small>}
                            </div>
                            <div className='w-100 form-group'>
                                <label className='form-label'>Thời gian<span className='text-danger'>*</span></label>
                                <ConfigProvider locale={viVI}>
                                    <DatePicker
                                        value={!orderStore.checkinTimeOrder ? null : dayjs(orderStore.checkinTimeOrder)}
                                        status={!orderStore.checkinTimeOrder && orderStore.errorBookingInfo.checkinTimeOrder ? 'error' : ''}
                                        placeholder='Chọn'
                                        showTime
                                        format={"hh:mm , DD/MM/YYYY"} onChange={onChangeDateFinish}/>
                                </ConfigProvider>
                                {!orderStore.checkinTimeOrder && orderStore.errorBookingInfo.checkinTimeOrder && <small className="text-error">{orderStore.errorBookingInfo.checkinTimeOrder}</small>}
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                            <button onClick={() => {
                                                orderStore.validateInforOrder()
                                            }} type='submit' className='btn btn__voucher' data-bs-dismiss={!orderStore.isErrorInfoOrder ? "modal": ''} data-bs-toggle={!orderStore.isErrorInfoOrder ? "modal": ''} data-bs-target={!orderStore.isErrorInfoOrder ? "#select_room_order": ''}>
                                                Tiếp theo
                                            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSelectRoom />
        </>
    )

}

export default observer(ModalAddUserInforOrder);

