import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {suppliesStore} from "./SuppliesStore";

class SuppliesService {

    public getSupplies(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/supplies${suppliesStore.type ? `?type=${suppliesStore.type.toUpperCase()}` : ''}&page=${suppliesStore.page}&size=100${suppliesStore.keyword ? `&keyword=${suppliesStore.keyword}` : ''}`)
    }

    public getDetail(id: number): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/supplies/${id}`)
    }

    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/supplies`, data)
    }

    public edit(id: number, data: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/supplies/${id}`, data)
    }

    public delete(id: number): Promise<IApiResponse> {
        return deleteRequest(`/iss_365_fnb/supplies/${id}`, {})
    }


}

export const suppliesService = new SuppliesService()