import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import CustomTable from "../../../modules/components/tableGlobal/CustomTable";
import {businessPlanStore} from "./BusinessPlanStore";
import {Input} from "antd";
import {Link} from "react-router-dom";
import {slug} from "../../../common/utils/Utils";
import {dateUtils} from "../../../common/utils/DateUtils";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import MenuAction from "../../../modules/components/menuAction/MenuAction";

const BusinessPlanPage = () => {
    const modalRef = useRef(null);
    const [namePlan, setNamePlan] = useState("")
    const convertStatus = (value: string) => {
        switch (value?.toUpperCase()) {
            case "COMPLETE":
                return <span className="color-green fw-500">Hoàn thành</span>
            case "PROCESSING":
                return <span className="color-purple fw-500">Đang xử lý</span>
            default:
                return null
        }
    }
    useEffect(() => {
        businessPlanStore.getBusinessPlan().then()
    }, [])
    useEffect(() => {
        if(businessPlanStore.dataDetailPlan?.name) {
            setNamePlan(businessPlanStore.dataDetailPlan.name)
        }
    }, [businessPlanStore.dataDetailPlan])
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  businessPlanStore.searchTypePlan,
            placeholder: 'Tất cả',
            listValue: [{
                value: "PROCESSING",
                label : "Đang thực hiện"
            },{
                value: "COMPLETE",
                label : "Thành công"
            }],
            callback: async (e: any) => {
                businessPlanStore.searchTypePlan = e
                await businessPlanStore.getBusinessPlan().then()
            }
        },
    ]
    const columns: any = [
        {
            title: "STT",
            align: "center",
            width: 100,
            render: (_: any, __ : any, index: any) => index + 1,
        },
        {
            title: "Chiến dịch",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 400,
            render: (value: any, record: any): JSX.Element => {
                if (!record.isEdit) {
                    return <span>{value}</span>
                }
                else return <div>
                    <Input type='text' value={namePlan}
                           onKeyPress={async (e) => {
                               if (e.key === "Enter") {
                                   (modalRef.current as unknown as HTMLButtonElement).click();
                               }
                           }}
                           onChange={(e: any) => {
                               setNamePlan(e.target.value)
                           }}
                           placeholder='Nhập tên ...' />
                </div>
            }
        },
        {
            title: "Thời gian tạo",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 200,
            render: (value: any) => <span>{dateUtils.formatDate(value)}</span>
        },
        {
            title: "Người tạo",
            dataIndex: "creator",
            key: "creator",
            align: "center",
            width: 200,
        },
        {
            title: "Tình trạng",
            dataIndex: "state",
            key: "state",
            align: "center",
            width: 200,
            render: (value: any) => <span>{convertStatus(value)}</span>
        },
        {
            title: "",
            dataIndex: "resource",
            key: "resource",
            width: 300,
            align: "center",
            render: (_: any, record: any, index: number): JSX.Element => {
                if (!record.isEdit) {
                    return (
                        <div>
                            <MenuAction children={<>
                                <Link
                                    to={`/ke-hoach-kinh-doanh/${slug(record.name)}`}
                                    className="d-flex justify-content-start btn"
                                    onClick={() => {
                                        localStorage.setItem('idPlan', record.id)
                                    }}
                                >
                                        <img
                                            src="/assets/ico/menuAction/clipboard-list.svg"
                                            className="icon-action"
                                            alt="detail"
                                        />
                                        <span>
                                            Chi tiết
                                        </span>
                                </Link>
                                <button className='d-flex justify-content-start btn'
                                        onClick={async () => {
                                            businessPlanStore.id = record.id
                                            businessPlanStore.typeModal = 'edit'
                                            await businessPlanStore.getDetail(record.id).then(() => {
                                                handleAddCampaign()
                                            })
                                        }}>
                                        <img src="/assets/ico/menuAction/app.svg"
                                             className="icon-action"
                                             alt=""/>
                                        <span>
                                            Chỉnh sửa
                                        </span>
                                </button>
                                <div className={'divice_menu__action'}></div>
                                <button className="d-flex justify-content-start btn"
                                        data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                        onClick={() => businessPlanStore.id = record.id}
                                >
                                    <img src="/assets/ico/action/delete_icon.svg"
                                         className="icon-action"
                                         alt=""/>
                                    <span className='menu_action__error'>
                                            Xóa
                                        </span>
                                </button>
                                {
                                    record.state === "PROCESSING" ?
                                        <button className="d-flex justify-content-start btn"
                                                onClick={async () => {
                                                    await businessPlanStore.changeStatusPlan(record.id, 'COMPLETE')
                                                }}
                                        >
                                                <img src="/assets/ico/action/check-circle-2.svg"
                                                     className="icon-action"
                                                     alt=""/>
                                            <span className='menu_action__success'>
                                                Hoàn thành
                                            </span>
                                        </button>
                                        :
                                        <button className="d-flex justify-content-start btn"
                                                onClick={async () => {
                                                    await businessPlanStore.changeStatusPlan(record.id, 'PROCESSING')
                                                }}
                                        >
                                                <img src="/assets/ico/action/x-circle.svg"
                                                     className="icon-action"
                                                     alt=""/>
                                                <span className='menu_action__error'>
                                                    Chưa hoàn thành
                                                </span>
                                        </button>
                                }
                            </>} />
                        </div>
                    );
                } else
                {
                    return <div className='d-flex justify-content-center align-items-center'>
                        <button onClick={() => {
                            const dataSplice = businessPlanStore.dataColumn.slice(); //Tạo bản ghi sâu
                            dataSplice.splice(index, 1)
                            businessPlanStore.dataColumn = dataSplice
                        }} className='btn-edit__row--cancel mx-1'>
                            Hủy
                        </button>
                        <button  ref={modalRef} onClick={async () => {
                            businessPlanStore.dataRequestAddPlan.name = namePlan
                            await businessPlanStore.addEditBusinessPlan()
                        }} className='btn-edit__row--save mx-1'>
                            Lưu
                        </button>
                    </div>
                }
            },
        },
    ];

    const handleAddCampaign = () => {
        if(businessPlanStore.typeModal === 'add') {
            setNamePlan("")
        }
        if(!businessPlanStore.dataColumn.some((item: any) => item.isEdit)) {
            const newData = [...businessPlanStore.dataColumn];
            newData.push({
                name: "",
                campaign: "",
                finishDay: "",
                creator: "",
                status: "",
                isEdit: true
            })
            businessPlanStore.dataColumn = newData
        }
    }
    return (
        <div className='business_plan'>
            <div className='d-flex justify-content-between'>
                <TabRedirect tab='Kế hoạch kinh doanh' />
                <FilterComponent
                    data={dataFilter}
                    permissionCode
                    buttonAdd={<ButtonGlobal onClick={() => {
                        businessPlanStore.typeModal = 'add'
                        handleAddCampaign()
                    }} text='Thêm' ico='add_icon' className='btn_add' />}
                />
            </div>
            <CustomTable
                subtractHeight={400}
                columns={columns}
                isLoading={businessPlanStore.isLoading}
                isRowSelection={false}
                data={businessPlanStore.dataColumn}
                scrollX={1000}
            />
            <PopupConfirm isLoading={businessPlanStore.isLoadingBt} action={businessPlanStore.deleteBusinessPlan} />
        </div>
    );
}

export default observer(BusinessPlanPage);