import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import TabRedirect from "../../modules/components/tabRedirect/TabRedirect";
import './CheckOrderPage.scss'
import FilterComponent, {IFilter} from "../../modules/components/filterGlobal/FilterGlobal";
import Loading from "../../modules/components/Loading";
import {dateUtils} from "../../common/utils/DateUtils";
import {checkOrderStore} from "./CheckOrderStore";
import ModalSelectRestaurant from "./components/selectRestaurant/SelectRestaurant";
import NoContent from "../../modules/components/NoContent";
import {numberUtil} from "../../common/utils/NumberUtil";
import DetailCheckoutOrderModal from "./components/detailOrder/detailOrder";
import ModalAddUser from "./components/addUser/AddUserModal";
import ViewDetailOrderModal from "./components/viewHistoryOrder/ViewHistoryOrder";
import {customerDataStore} from "../customerCare/customerData/CustomerDataStore";
const convertStatusOrder = (value: string) => {
    switch (value.toUpperCase()) {
        case "SUCCESS":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#F3FFF9', color: '#00AB56', borderRadius: '3px'}}>Hoàn
                thành</div>
        case "IN_PROGRESS":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#FAF9FF', color: '#7B61FF', borderRadius: '3px'}}>Đang
                phục vụ</div>
        case "PENDING":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#EBEBF0', color: '#626C70', borderRadius: '3px'}}>Chưa
                phục vụ</div>
        case "CANCEL":
            return <div className="px-1" style={{
                width: '100px',
                backgroundColor: '#FFEDEE',
                color: '#FF424F',
                borderRadius: '3px'
            }}>Hủy</div>
        default:
            return null
    }
}
export const convertTypeOrder = (value: string) => {
    switch (value) {
        case "ONLINE":
            return <div className="px-1 text-center" style={{width: '80px', background: '#FFF6D3', borderRadius: '3px'}}>Online</div>
        case "OFFLINE":
            return <div className="px-1 text-center" style={{width: '80px',background: '#EEFFFE', borderRadius: '3px'}}>Offline</div>
        case "ORDER":
            return <div className="px-1 text-center" style={{width: '80px',background: '#EEFFFE', borderRadius: '3px'}}>Đặt bàn</div>
        default:
            return null
    }
}
const CheckOrderPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  checkOrderStore.searchStatusOrder,
            placeholder: 'Tình trạng',
            listValue: [{
                value: "CANCEL",
                label : "Đã hủy"
            },{
                value: "IN_PROGRESS",
                label : "Đang phục vụ"
            },{
                value: "PENDING",
                label : "Chưa phục vụ"
            },{
                value: "SUCCESS",
                label : "Thành công"
            }],
            callback: async (e: any) => {
                checkOrderStore.searchStatusOrder = e
                await checkOrderStore.getListOrder().then()
            }
        },
        {
            label: 'Loại',
            type: 'select',
            value:  checkOrderStore.searchTypeOrder,
            placeholder: 'Loại',
            listValue: [{
                value: "OFFLINE",
                label : "Tại chỗ / Mang đi"
            },{
                value: "ONLINE",
                label : "Gọi ship"
            },{
                value: "ORDER",
                label : "Đặt chỗ"
            }],
            callback: async (e: any) => {
                checkOrderStore.searchTypeOrder = e
                await checkOrderStore.getListOrder().then()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm theo mã đơn , số điện thoại...',
            value: checkOrderStore.searchKeywordOrder,
            onChange: (e: any) => {
                checkOrderStore.searchKeywordOrder = e
            },
            callback: async () => {
                await checkOrderStore.getListOrder().then()
            }
        },
    ]
    useEffect(() => {
        checkOrderStore.searchStatusOrder = null
        checkOrderStore.searchKeywordOrder = ''
        checkOrderStore.getListOrder().then()
        checkOrderStore.getListRestaurant().then()
    }, [])
    const modalRef = useRef(null);
    useEffect(() => {
        if (modalRef.current && !JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId) {
            (modalRef.current as HTMLButtonElement).click();
        }
    }, []);
    useEffect(() => {
        if(!checkOrderStore.dataRequest?.establishmentId) {
            checkOrderStore.dataRequest.establishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
        }
        if(!checkOrderStore.dataRequest?.establishmentName) {
            checkOrderStore.dataRequest.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
        }
    }, [])
    return (
        <>
            <div className='service_container'>
                <div  className='w-100 service_container__header'>
                    <TabRedirect tab={`${checkOrderStore.dataRequest?.establishmentName ? checkOrderStore.dataRequest?.establishmentName : 'Tất cả điểm bán'}`}  />
                    <FilterComponent
                        data={dataFilter}
                    />
                </div>
                <div className='mb-3 d-flex justify-content-between'>
                    <button ref={modalRef} data-bs-toggle="modal" data-bs-target='#select_restaurant_order' className='btn_select_restaurant'>Chuyển điểm bán</button>
                    <button onClick={() => {
                        customerDataStore.typeModal = 'add'
                        checkOrderStore.clearForm()
                    }} data-bs-toggle="modal" data-bs-target='#add_user_customer' className='btn_select_restaurant ms-3'>
                        <img src="/assets/ico/action/add_icon.svg" alt=""/>
                        Thêm khách hàng
                    </button>
                </div>
                <div className='w-100'>
                    {checkOrderStore.isLoading ? <Loading/> :
                        <>
                            <div className="table-responsive d-flex flex-column mb-4 justify-content-between">
                                <table className='table align-middle border gy-7 gs-7'>
                                    <thead>
                                    <tr>
                                        <th className="text-center">Mã đơn</th>
                                        <th className="text-center">Thời gian</th>
                                        <th className="text-center">Số điện thoại</th>
                                        <th className="text-center">Họ và tên</th>
                                        <th className="text-center">Loại</th>
                                        <th className="text-center">Phòng - bàn</th>
                                        <th className="text-center">Tình trạng</th>
                                        <th className="text-center">Tổng tiền (VNĐ)</th>
                                        <th className="text-center">Thao tác</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {checkOrderStore.listOrders.length <= 0 ? <NoContent colSpan={9}/> : checkOrderStore.listOrders.map((item: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-start">
                                                        #{item?.code}
                                                    </td>
                                                    <td className="text-center">{dateUtils.formatDate(item?.checkinTime, 'hh:mm , DD/MM/YYYY')}</td>
                                                    <td className="text-center">{item?.user?.phone ?? "-"}</td>
                                                    <td className="text-center">{item?.user?.name ?? "-"}</td>
                                                    <td className="text-center">{convertTypeOrder(item?.type)}</td>
                                                    <td className="text-center">{`${item?.room?.name ? `${item?.room?.name} - `: ""}${item?.table?.name ? item?.table?.name : "-"}`}</td>
                                                    <td className="text-center"><div className='d-flex justify-content-center'>{convertStatusOrder(item?.status)}</div></td>
                                                    <td className="text-end fw-500">{numberUtil.number_format(item?.totalPayment)}</td>
                                                    <td className="text-center fw-500">
                                                        <button
                                                            type='button'
                                                            className="view_detail__btn"
                                                            onClick={() => {
                                                                if(item.status !== "SUCCESS"){
                                                                    checkOrderStore.clearForm()
                                                                    checkOrderStore.idOrder = item.id
                                                                    checkOrderStore.getDetailOrder(item.id).then()
                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                    checkOrderStore.detailInfoUser = {}
                                                                    checkOrderStore.keyword = ""
                                                                }
                                                                else {
                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                    checkOrderStore.getDetailOrder(item.id).then()
                                                                }
                                                            }}
                                                            data-bs-toggle="modal"
                                                            title="Xem chi tiết"
                                                            data-bs-target={item?.status !== "SUCCESS" ? '#detail_checkout_order' : '#view_detail_order'}>
                                                            Chi tiết
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                </div>
            </div>
            <ModalSelectRestaurant />
            <DetailCheckoutOrderModal />
            <ViewDetailOrderModal />
            <ModalAddUser />
        </>
    );
}

export default observer(CheckOrderPage);