import StorageService from "../service/StorageService";
import {registerStore} from "../../modules/auth/register/RegisterStore";
import React from "react";

export function sttPage(page: number, index: any) {
    let start = 1
    if (page > 0) {
        start = page * 10 + index + 1
    } else {
        start = start + index
    }
    return start
}

export function urlImage(url: any) {
    let image: any = process.env.REACT_APP_API_VIEWIMG + url
    return image
}

export function urlImageSSO(url: any) {
    let image: any = process.env.REACT_APP_VIEWIMG_SSO + url
    return image
}

export function uniqueArrObject(arr: any[]) {
    const uniqueArr = arr.reduce((acc, curr) => {
        if (!acc.some((item: any) => item.id === curr.id)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    return uniqueArr
}


export function convertDataSelectAnt(arr: any[]) {
    let arrNew: any = []
    arr && arr.map((val) => {
        arrNew.push({label: val.name, value: val.id})
    });
    return arrNew
}
export function selectModule() {
    if(window.location.pathname.startsWith("/")) localStorage.setItem("module", 'Báo cáo')

    return null
}


export function getPathName(index?: any) {
    let path: any = window.location.pathname.split('/')[index ?? 1]
    return path;
}

export function getUrlParameter(name: any) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ''));
}

export function getIDParameter(index?: any) {
    let id: any = window.location.pathname.split('/')[index ?? 3]
    return id;
}

export function unique_arr(arr: any) {
    return Array.from(new Set(arr))
}

export function setCookie(name: string, value: any, days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function eraseCookie(name: string) {
    setCookie(name, "", -1);
}


export function goBack() {
    window.history.back();
}

export function slug(value: string) {
    var str = value ?? 'detail'
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
    str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
    str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
    str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
    str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
    str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
    str = str.replace(/(đ)/g, 'd');

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, '');

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, '-');

    // xóa phần dự - ở đầu
    str = str.replace(/^-+/g, '');

    // xóa phần dư - ở cuối
    str = str.replace(/-+$/g, '');

    // return
    return str;
}


export function formatNumberPhone(number_phone: string) {
    return number_phone.replace(
        /(^(?:\d{2}))?(\d{3})(?=(?:\d{5})+$)/g,
        '$1.$2.'
    );
}

export function validateEmail(email: any){
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return pattern.test(email)
}

export function validatePhoneNumber(phoneNumber : any) {
    const phoneRegex = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/
    return phoneRegex.test(phoneNumber)
}

export async function parseJwt(token_?: any) {
    var token: any = token_ ?? StorageService.getToken()
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    if (JSON.parse(jsonPayload)['fnb-id']) {
        localStorage.setItem('fnbId', JSON.parse(jsonPayload)['fnb-id'])
        registerStore.idFnb =  JSON.parse(jsonPayload)['fnb-id']
    }
    if (JSON.parse(jsonPayload)['fnb-user-id']) {
        localStorage.setItem('fnbUserId', JSON.parse(jsonPayload)['fnb-user-id'])
    }
    if (JSON.parse(jsonPayload)['status']) {
        localStorage.setItem('statusAccount', JSON.parse(jsonPayload)['status'])
    }
    else  localStorage.removeItem('statusAccount')

    registerStore.username = JSON.parse(jsonPayload)['name']
    registerStore.phone = JSON.parse(jsonPayload)['username']
    registerStore.avatar = JSON.parse(jsonPayload)['avatar']
}