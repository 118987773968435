import React from 'react';


interface IProps{
    colSpan?: number,
    message?: string,
    height?: number
}

const NoContent = ({message, colSpan, height}: IProps) => {

    if(colSpan){
        return (
            <tr>
                <td style={{height: height ?? 350}} colSpan={colSpan} className='text-center fw-500'>
                    <div style={{fontSize: '16px'}} className='d-flex justify-content-center align-items-center'>
                        {message ?? 'Chưa có dữ liệu'}
                    </div>
                </td>
            </tr>
        )
    }else return (
        <div style={{fontSize: 16, height: height ?? 350}} className='d-flex fw-500 justify-content-center align-items-center'>
            {message ?? 'Chưa có dữ liệu'}
        </div>
    )
}

export default NoContent;
