import {getRequest, IApiResponse, putRequest} from "../../common/helpers/RequestHelper";
import {browserStore} from "./BrowserStore";

class BrowserService{
    public getImpExpRequest(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/leader/request?page=0${browserStore.keyword ? `&code=${browserStore.keyword}` : ''}${browserStore.searchType ? `&type=${browserStore.searchType}` : ''}${browserStore.searchStatus ? `&status=${browserStore.searchStatus}` : ''}&size=100`)
    }
    public changeStatusRequest(data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/leader/request/change_status`, data)
    }

}

export const browserService = new BrowserService()