import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {browserService} from "./BrowserService";
import {toastUtil} from "../../common/utils/ToastUtil";

class BrowserStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    searchType: any = null
    searchStatus: any = null
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listImportExportRequest: any = []
    listSelectRowRequest: any = []
    errors: any = {
    }
    dataRequestChangeStatus: any = {
        ids: [],
        status: ""
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
    }

    async getImpExpRequest() {
        this.isLoading = true
        const result = await browserService.getImpExpRequest()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listImportExportRequest = result.body.data.map((item: any) => {
                return {
                    id: item.id,
                    idRequest: item?.data?.requestWarehouse?.id,
                    idBuyRequest: item?.data?.buyRequest?.id,
                    purpose: item.purpose,
                    type: item.type,
                    imEx: item?.data?.requestWarehouse?.type,
                    senderName: item?.sender?.name,
                    code: item.code,
                    timeRequest: item?.sender?.date,
                    timeApproved: item?.receiver?.date,
                    updatedAt: item.updatedAt,
                    status: item.status
                }
            })
        }
    }
    async changeStatusRequest() {
        this.isLoadingBt = true
        const result = await browserService.changeStatusRequest(this.dataRequestChangeStatus)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật trạng thái yêu cầu thành công !')
            await browserStore.getImpExpRequest()
            browserStore.dataRequestChangeStatus = {
                ids: [],
                status: ""
            }
            browserStore.listSelectRowRequest = []
        } else toastUtil.error(result.body.message)
    }
}

export const browserStore = new BrowserStore()