import React from 'react';
import {observer} from "mobx-react";
import InputForm from "../../../../modules/components/form/Input";
import SelectForm from "../../../../modules/components/form/SelectAnt";
import {staffSettingStore} from "../StaffStore";
import {numberUtil} from "../../../../common/utils/NumberUtil";
const AddEditStaff = () => {
    return (
        <div className="modal fade" id="add_edit_staff" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 800}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{staffSettingStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} nhân viên</h5>
                        <button type="button" id="close_staff" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className='modal-body p-0 mt-4'>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Tên nhân viên <span className="text-danger">*</span></label>
                                <InputForm value={staffSettingStore.dataRequest.name} isError={staffSettingStore.errors.name}
                                       onChange={(e: any) => staffSettingStore.dataRequest.name = e.currentTarget.value}
                                       placeholder="Nhập tên nhân viên"/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Mã nhân viên <span className="text-danger">*</span></label>
                                <InputForm value={staffSettingStore.dataRequest.code} isError={staffSettingStore.errors.code}
                                       onChange={(e: any) => staffSettingStore.dataRequest.code = e.currentTarget.value}
                                       placeholder="Nhập mã nhân viên"/>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Hình thức làm việc <span className="text-danger">*</span></label>
                                <SelectForm value={staffSettingStore.dataRequest.jobType}
                                            isError={staffSettingStore.errors.jobType}
                                            onChange={(e: any) => {staffSettingStore.dataRequest.jobType = e}}
                                            options={[
                                                {
                                                    name: 'Full Time',
                                                    id: 'FULL_TIME'
                                                },
                                                {
                                                    name: 'Part Time',
                                                    id: 'PART_TIME'
                                                }
                                            ]}/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Loại lương <span className="text-danger">*</span></label>
                                <SelectForm value={staffSettingStore.dataRequest.wageType} isError={staffSettingStore.errors.wageType} onChange={(e: any) => staffSettingStore.dataRequest.wageType = e}
                                            options={staffSettingStore.wageType}/>
                            </div>
                        </div>

                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Lương <span className="text-danger">*</span></label>
                                <InputForm value={staffSettingStore.dataRequest.wage} isError={staffSettingStore.errors.wage}
                                           onChange={(e: any) => staffSettingStore.dataRequest.wage = numberUtil.regexNumber(e)}
                                           placeholder="Nhập lương"/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Thưởng</label>
                                <InputForm value={staffSettingStore.dataRequest.bonus}
                                           onChange={(e: any) => staffSettingStore.dataRequest.bonus = numberUtil.regexNumber(e)}
                                           placeholder="Nhập thưởng"/>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Trợ cấp</label>
                                <InputForm value={staffSettingStore.dataRequest.allowance}
                                           onChange={(e: any) => staffSettingStore.dataRequest.allowance = numberUtil.regexNumber(e)}
                                           placeholder="Nhập trợ cấp"/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Bảo hiểm xã hội</label>
                                <InputForm value={staffSettingStore.dataRequest.socialInsurance}
                                           onChange={(e: any) => staffSettingStore.dataRequest.socialInsurance = numberUtil.regexNumber(e)}
                                           placeholder="Nhập bảo hiểm xã hội"/>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <div className="w-50 pe-2">
                                <label>Phí công đoàn </label>
                                <InputForm value={staffSettingStore.dataRequest.unionDues}
                                           onChange={(e: any) => staffSettingStore.dataRequest.unionDues = numberUtil.regexNumber(e)}
                                           placeholder="Nhập phí công đoàn"/>
                            </div>
                            <div className="w-50 ps-2">
                                <label>Chi phí khác </label>
                                <InputForm value={staffSettingStore.dataRequest.otherCosts}
                                           onChange={(e: any) => staffSettingStore.dataRequest.otherCosts = numberUtil.regexNumber(e)}
                                           placeholder="Nhập chi phí khác"/>
                            </div>
                        </div>

                        <div className="mt-4 d-flex justify-content-end">
                            <button className="btn send_request" type="submit" disabled={staffSettingStore.isLoadingBt}
                                    onClick={() => staffSettingStore.addEdit()}>Hoàn thành
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(AddEditStaff);