import {getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {checkOrderStore} from "./CheckOrderStore";

class CheckOrderService{
    public getListOrder(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/order/fnb?page=${checkOrderStore.page}${checkOrderStore.dataRequest.establishmentId ? `&establishmentId=${checkOrderStore.dataRequest.establishmentId}` : ''}${checkOrderStore.searchStatusOrder ? `&status=${checkOrderStore.searchStatusOrder}` : ''}${checkOrderStore.searchTypeOrder ? `&type=${checkOrderStore.searchTypeOrder}` : ''}${checkOrderStore.searchKeywordOrder ? `&keyword=${checkOrderStore.searchKeywordOrder}` : ''}&size=${size ?? 500}`)
    }
    public getListUser(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user?page=${checkOrderStore.page}${checkOrderStore.keyword ? `&keyword=${checkOrderStore.keyword}` : ''}`)
    }
    public getDetailUser(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user/${id}`)
    }
    public addUser(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/user`, data)
    }
    public updateUser(id: any, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/user/${id}`, data)
    }
    public checkAddVoucher(data: any, id: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/voucher/add/${id}`, data)
    }
    public updateItemOrder(data: any, orderId: any, itemId: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/order/${orderId}/item/${itemId}`, data)
    }
    public checkOutOrder(data: any, id: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/order/${id}/checkout`, data)
    }
}

export const checkOrderService = new CheckOrderService()