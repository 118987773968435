import React, {useEffect} from 'react';
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {trademarkStore} from "./TrademarkStore";
import {sttPage} from "../../../common/utils/Utils";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {dateUtils} from "../../../common/utils/DateUtils";
import {convertStateResearch} from "../marketResearch/MarketResearchPage";
import {Tooltip} from "antd";
import {marketResearchStore} from "../marketResearch/MarketResearchStore";
import PopupConfirm from "../../../modules/components/PopUpConfirm";

const TrademarkPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value: trademarkStore.searchStatus,
            placeholder: 'Tất cả',
            listValue: [{value: "PENDING", label: "Đang chờ"},{value: "PROCESSING", label: "Đang xử lý"},{value: "APPROVED", label: "Đã duyệt"}],
            callback: async (e: any) => {
                trademarkStore.searchStatus = e
                await  trademarkStore.getTrademark()
            }
        },
    ]
    useEffect(() => {
        trademarkStore.getTrademark().then()
    }, [])
    return (
        <>
            <div className='trademark_page'>
                <div className='d-flex justify-content-between'>
                    <TabRedirect tab='Thương hiệu'/>
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={<Link onClick={() => {
                            trademarkStore.typeForm = 'add'
                            trademarkStore.clearForm()
                        }} className="button_add_research" to={"/thuong-hieu/tao-ke-hoach"}>
                            <img src="/assets/ico/action/add_icon.svg" alt=""/>
                            Thêm
                        </Link>}
                    />
                </div>
                {trademarkStore.isLoading ? <Loading/> : <div className="table-responsive">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tên</th>
                            <th className="text-center">Thời gian</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {trademarkStore.listTrademark && trademarkStore.listTrademark.length > 0 ? trademarkStore.listTrademark.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center"
                                        style={{width: '80px'}}>{sttPage(trademarkStore.page, i)}</td>
                                    <td className="text-center">{item?.name ?? "-"}</td>
                                    <td className="text-center">{dateUtils.formatDate(item?.data?.reportingTime, "HH:mm:ss, DD/MM/YYYY")}</td>
                                    <td className="text-center"><span className="color-green" style={{fontWeight: '500'}}>{convertStateResearch("PENDING")}</span>
                                    </td>
                                    <td className="text-center">
                                            <Link onClick={() => {
                                                trademarkStore.typeForm = 'edit'
                                                trademarkStore.id = item.id
                                            }} className='p-0 mx-1' to={`/thuong-hieu/chi-tiet/${item.id}`}>
                                                <Tooltip title='Chỉnh sửa'>
                                                <img src="/assets/ico/action/edit_icon.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Chi tiết"/>
                                                </Tooltip>
                                            </Link>
                                            <button className="btn p-0 mx-1"
                                                    data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                    onClick={() => trademarkStore.id = item.id}>
                                                <Tooltip title='Xóa'>
                                                    <img src="/assets/ico/action/delete_icon.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </Tooltip>
                                            </button>
                                    </td>
                                </tr>
                            )
                        }) : <NoContent colSpan={5}/>}
                        </tbody>
                    </table>
                </div>}
            </div>
            <PopupConfirm isLoading={trademarkStore.isLoadingBt} action={trademarkStore.delete} />
        </>
    );
}
export default observer(TrademarkPage);