import React from 'react';
import {observer} from "mobx-react";
import './ModalFilter.scss'
import {Select} from "antd";
import ModalAddEditFinance from "../modalAddEditFinance/ModalAddEditFinance";
import {costStore} from "../../CostStore";
import { DatePicker } from 'antd';

import dayjs from "dayjs";

const ModalFilter = () => {
    const handleYearChange = (date: any, dateString: any) => {
        costStore.dataRequest.year = dateString
    };
    return (
        <>
            <div className='modal fade' id='filter_finance' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal-header'>
                            <div className='value_voucher__title'>CẬP NHẬT CHI PHÍ</div>
                            <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className='w-100 form-group'>
                                <label className='form-label'>Chọn cơ sở <span className='text-danger'>*</span></label>
                                <Select
                                    value={costStore.dataRequest?.establishmentId}
                                    allowClear
                                    onChange={async (e: any, value: any) => {
                                        costStore.dataRequest.establishmentId = e
                                        costStore.establishmentName = value.label
                                    }}
                                    placeholder="Chọn địa điểm bán" options={costStore.listRestaurant}
                                />
                            </div>
                            <div className='w-100 form-group'>
                                <label className='form-label'>Năm <span className='text-danger'>*</span></label>
                                <DatePicker
                                    picker="year"
                                    placeholder={"Chọn năm"}
                                    allowClear
                                    value={!costStore.dataRequest?.year ? null : dayjs(costStore.dataRequest?.year, 'YYYY') as any}
                                    onChange={handleYearChange}
                                    bordered={false}
                                />
                            </div>
                            <div className='w-100 form-group'>
                                <label className='form-label'>Tháng <span className='text-danger'>*</span></label>
                                <Select
                                    value={costStore.dataRequest?.month}
                                    allowClear
                                    onChange={async (e: any) => {
                                        costStore.dataRequest.month = e
                                    }}
                                    placeholder="Chọn tháng" options={costStore.dataMonth}
                                />
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-end mt-4'>
                                {(costStore.dataRequest?.establishmentId && costStore.dataRequest?.year && costStore.dataRequest?.month ) ?
                                    <button type='submit' className='w-25 btn btn__continue'
                                            data-bs-dismiss='modal' data-bs-toggle='modal'
                                            data-bs-target={'#add_edit_finance'}
                                            onClick={() => costStore.checkValidateModalFilter()}
                                    >
                                        Tiếp theo
                                    </button> :
                                    <button type='submit'
                                            className='w-25 btn btn__continue_error'
                                            onClick={() => costStore.checkValidateModalFilter()}
                                    >
                                        Tiếp theo
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddEditFinance />
        </>

    )

}

export default observer(ModalFilter);

