import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './AddEditTable.scss'
import {Select, Input, Spin} from 'antd';
import {tableStore} from "../../TableStore";
import {roomStore} from "../../../room/RoomStore";
const ModalAddEditTable = () => {
    useEffect(() => {
        if(tableStore.dataRequest.establishmentId){
            tableStore.getListRoom().then()
        }
    }, [tableStore.dataRequest.establishmentId])
    useEffect(() => {
        if(tableStore.establishmentId){
            tableStore.getListRoom().then()
        }
    }, [tableStore.establishmentId])
    return (
        <div className='modal fade' id='add_edit_room' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>
                            {tableStore.typeModal === 'add' ? 'THÊM BÀN' : 'CẬP NHẬT THÔNG TIN BÀN'}
                            {tableStore.establishmentName && <span className='ms-2' style={{fontSize: '16px', fontStyle: 'italic', color: '#000'}}>{`(${tableStore.establishmentName})`}</span>}
                        </div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên bàn<span className='text-danger'>*</span></label>
                                <Input type='text' value={tableStore.dataRequest?.name}
                                       status={(tableStore.errors?.name && !tableStore.dataRequest?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           tableStore.dataRequest.name = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {tableStore.errors?.name && !tableStore.dataRequest.name && <small className="text-error">{tableStore.errors?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Tình trạng<span className='text-danger'>*</span></label>
                                <Select
                                    value={tableStore.dataRequest.status}
                                    status={(tableStore.errors?.status && !tableStore.dataRequest?.status) ? "error" : ""}
                                    onChange={(e: any) => {
                                        tableStore.dataRequest.status = e
                                    }}
                                    placeholder="Chọn" options={[{value: 'ACTIVE', label: 'Hoạt động'}, {value: 'INACTIVE', label: 'Chưa hoạt động'}]}
                                />
                                {tableStore.errors?.status  && !tableStore.dataRequest.status && <small className="text-error">{tableStore.errors?.status}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Chọn điểm bán<span className='text-danger'>*</span></label>
                                <Select
                                    value={tableStore.establishmentId ? tableStore.establishmentId : tableStore.dataRequest.establishmentId}
                                    status={(tableStore.errors?.establishmentId && !tableStore.establishmentId) ? "error" : ""}
                                    onChange={async (e: any, value: any) => {
                                        tableStore.establishmentId = e
                                        tableStore.establishmentName = value?.label ?? ""
                                        tableStore.dataRequest.roomId = null
                                        await tableStore.getListTable().then(async () => {
                                            await tableStore.getListRoom().then()
                                        })
                                    }}
                                    placeholder="Chọn" options={roomStore.listRestaurant}
                                />
                                {tableStore.errors?.establishmentId  && !tableStore.establishmentId && <small className="text-error">{tableStore.errors?.establishmentId}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Chọn phòng</label>
                                <Select
                                    value={tableStore.dataRequest.roomId}
                                    allowClear
                                    onChange={(e: any) => {
                                        tableStore.dataRequest.roomId = e
                                        console.log(e)
                                    }}
                                    placeholder="Chọn" options={tableStore.listRoom}
                                />
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn__voucher'
                                        onClick={async () => {
                                            await tableStore.addEditTable()}}>
                                    {tableStore.isLoadingBt ? <Spin size="small" /> : tableStore.typeModal === 'add' ?  'Thêm' : 'Cập nhật'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddEditTable);

