import {departmentStore} from "./DepartmentStore";
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class DepartmentService{
    public getDepartment(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/departments?page=${departmentStore.page}&size=100${departmentStore.keyword ? `&keyword=${departmentStore.keyword}` : ''}`)
    }
    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/departments`, data)
    }
    public getDepartmentById(id: any): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/departments/${id}`)
    }
    public editDepartment(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/departments/${id}`, data)
    }
    public deleteDepartment(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/departments/${id}`, {})
    }
}
export const departmentService = new DepartmentService()