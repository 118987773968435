import React, {useEffect, useState} from "react";
import "./styles/sidebar.scss";
import {Link} from "react-router-dom";
import { motion } from 'framer-motion'
import {sidebarStore} from "./SidebarStore";
const Sidebar = () => {
    // @ts-ignore
    const [isExpanded, setExpendState] = useState<any>(JSON.parse(localStorage.getItem('activeSidebar')));
    // @ts-ignore
    const [isDefault, setisDefault] = useState<any>(JSON.parse(localStorage.getItem('activeSidebar')))
    const [heading, setHeading] = useState(window.location.pathname.substring(0, window.location.pathname.indexOf('/', 1)))

    const dataMenuSidebar = [
        {
            text: "Doanh thu",
            icon: "/assets/ico/wallet",
            link: '/',
            role: 'Báo cáo'
        },
        {
            text: "Chi phí",
            icon: "/assets/ico/box",
            link: '/bao-cao-chi-phi',
            role: 'Báo cáo'
        },
        {
            text: "Khách hàng",
            icon: "/assets/ico/book-user",
            link: '/bao-cao-khach-hang',
            role: 'Báo cáo'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/tong-quan',
            role: 'Sale - Marketing'
        },
        {
            text: "KPI",
            icon: "/assets/ico/bubble_chart",
            link: '/kpi',
            role: 'Sale - Marketing'
        },
        {
            text: "Nghiên Cứu Thị Trường",
            icon: "/assets/ico/pentagon",
            link: '/nghien-cuu-thi-truong',
            role: 'Sale - Marketing',
            submenu: [
                {
                    text: 'Báo cáo NCTT ',
                    link: '/bao-cao-nghien-cuu-thi-truong',
                    orthers : '/tao-bao-cao',
                },
                {
                    text: 'Báo cáo R&D',
                    link: '/bao-cao-rnd',
                    orthers : '/tao-bao-cao'
                },
            ]
        },
        {
            text: "Kế Hoạch Kinh Doanh",
            icon: "/assets/ico/checklist",
            link: '/ke-hoach-kinh-doanh',
            role: 'Sale - Marketing'
        },
        {
            text: "Thương Hiệu",
            icon: "/assets/ico/store",
            link: '/thuong-hieu',
            role: 'Sale - Marketing'
        },
        {
            text: "Voucher",
            icon: "/assets/ico/ticket",
            link: '/voucher',
            role: 'Sale - Marketing'
        },
        {
            text: "Dữ Liệu",
            icon: "/assets/ico/bookmark_added",
            link: "/data",
            role: 'Sale - Marketing'
        },
        {
            text: "Dữ liệu khách hàng",
            icon: "/assets/ico/database",
            link: '/du-lieu-khach-hang',
            role: 'Chăm sóc khách hàng'
        },
        {
            text: "Quyền lợi thành viên",
            icon: "/assets/ico/lucide_gem",
            link: '/quyen-loi-thanh-vien',
            role: 'Chăm sóc khách hàng'
        },
        {
            text: "Phản hồi khách hàng",
            icon: "/assets/ico/lucide_framer",
            link: '/phan-hoi-khach-hang',
            role: 'Chăm sóc khách hàng'
        },
        {
            text: "Phiếu thông tin",
            icon: "/assets/ico/lucide_fan",
            link: '/phieu-thong-tin',
            role: 'Chăm sóc khách hàng'
        },
        {
            text: "Mua Hàng",
            icon: "/assets/ico/sidebar/li_package-plus",
            link: '/purchase',
            role: 'Quản lý kho'
        },
        {
            text: "Nhập kho",
            icon: "/assets/ico/sidebar/import",
            link: '/import',
            role: 'Quản lý kho',
            submenu: [
                {
                    text: 'Phiếu nhập kho',
                    link: '/receipt'
                },
                {
                    text: 'Nguyên vật liệu',
                    link: '/materials'
                },
                {
                    text: 'Phụ gia',
                    link: '/additive'
                },
                {
                    text: 'Bao bì',
                    link: '/packaging'
                },
                {
                    text: 'Thiết bị',
                    link: '/devices'
                }
            ]
        },
        {
            text: "Xuất kho",
            icon: "/assets/ico/sidebar/export",
            link: '/export',
            role: 'Quản lý kho',
            submenu: [
                {
                    text: 'Phiếu xuất kho',
                    link: '/delivery_bill'
                },
                {
                    text: 'Nguyên vật liệu',
                    link: '/materials'
                },
                {
                    text: 'Phụ gia',
                    link: '/additive'
                },
                {
                    text: 'Bao bì',
                    link: '/packaging'
                },
                {
                    text: 'Thiết bị',
                    link: '/devices'
                }
            ]
        },
        {
            text: "Báo cáo tồn kho",
            icon: "/assets/ico/sidebar/inventory_report",
            link: '/inventory_report',
            role: 'Quản lý kho'
        },
        {
            text: "Quy định định mức",
            icon: "/assets/ico/sidebar/stipulation",
            link: '/stipulations',
            role: 'Quản lý kho'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/nhan-su',
            role : 'Nhân sự'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/bep',
            role: 'Bếp'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/quan-ly-chuoi',
            role: 'Quản lý chuỗi'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/data',
            role: 'Dữ liệu'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/tai-chinh',
            role: 'Tài chính'
        },
        {
            text: "Chi phí",
            icon: "/assets/ico/bubble_chart",
            link: '/chi-phi',
            role: 'Tài chính'
        },
        {
            text: "Doanh thu",
            icon: "/assets/ico/pentagon",
            link: '/doanh-thu',
            role: 'Tài chính'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/quan-ly-ca-nhan',
            role : 'Quản lý cá nhân'
        },
        {
            text: "Tổng quan",
            icon: "/assets/ico/app",
            link: '/van-chuyen',
            role: 'Vận chuyển'
        },
        {
            text: "Thiết lập chung",
            icon: "/assets/ico/sidebar/users",
            link: '/setup',
            role : 'Thiết lập',
            submenu: [
                {
                    text: "Tài khoản",
                    link: '/account'
                },
                {
                    text: "Phân quyền",
                    link: '/role'
                },
                {
                    text: "Phòng ban",
                    link: '/department'
                },
                {
                    text: "Chức vụ",
                    link: '/position'
                },
            ]
        },
        {
            text: "Thiết lập cửa hàng",
            icon: "/assets/ico/sidebar/restaurant",
            link: '/thiet-lap',
            role : 'Thiết lập',
            submenu: [
                {
                    text: "Thông tin nhà hàng",
                    link: '/thong-tin-nha-hang'
                },
                {
                    text: "Danh sách điểm bán",
                    link: '/danh-sach-diem-ban'
                },
                {
                    text: "Phòng",
                    link: '/quan-ly-phong'
                },
                {
                    text: "Bàn",
                    link: '/quan-ly-ban'
                },
                {
                    text: "Đổi điểm",
                    link: '/doi-diem'
                },
            ]
        },
        {
            text: "Thiết lập sản phẩm",
            icon: "/assets/ico/sidebar/product",
            link: '/san-pham',
            role : 'Thiết lập',
        },
        {
            text: "Thiết lập kho",
            icon: "/assets/ico/sidebar/warehouse",
            link: '/warehouse',
            role : 'Thiết lập',
            submenu: [
                {
                    text: "Nguyên vật liệu",
                    link: '/materials'
                },
                {
                    text: "Phụ gia",
                    link: '/additives'
                },
                {
                    text: "Bao bì",
                    link: '/packaging'
                },
                {
                    text: "Thiết bị",
                    link: '/devices'
                },
                {
                    text: "Danh sách kho",
                    link: '/inventory'
                }
            ]
        },
    ]
    useEffect(() => {
        if(window.location.pathname === '/'){
            localStorage.setItem("module", 'Báo cáo')
        }
        else {
            const menuItem = dataMenuSidebar.find(item => (item.link !== '/' && window.location.pathname.startsWith(item.link)));
            if(menuItem) localStorage.setItem("module", menuItem.role)
        }
        setHeading(window.location.pathname.substring(0, window.location.pathname.indexOf('/', 1)))
    }, [window.location.pathname])

    useEffect(() => {
        const handleVisibilityChange = () => {
                if(window.location.pathname === '/'){
                    localStorage.setItem("module", 'Báo cáo')
                }
                else {
                    const menuItem = dataMenuSidebar.find(item => (item.link !== '/' && window.location.pathname.startsWith(item.link)));
                    if(menuItem) localStorage.setItem("module", menuItem.role)
                }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const checkMenuActive = (item: any) => {
        const path = window.location.pathname.split("/");
        return `/${path[1]}` === item
    }

    const checkSubmenuActive = (item: any) => {
        return window.location.pathname.startsWith(item)
    }

    const handleMouseEnter = () => {
        if(!isDefault) {
            setExpendState(true);
            sidebarStore.showSidebar = true
            localStorage.setItem('activeSidebar', JSON.stringify(true))
        }
    };

    const handleMouseLeave = () => {
        if(!isDefault) {
            setExpendState(false);
            sidebarStore.showSidebar = false
            localStorage.setItem('activeSidebar', JSON.stringify(false))
        }
    };
    if(dataMenuSidebar.filter((menu: any) => menu.role === localStorage.getItem('module')).length > 0)
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={
                isExpanded
                    ? "side-nav-container"
                    : "side-nav-container side-nav-container-NX"
            }
        >
            <div className="nav-upper">
                <div className="nav-heading">
                    <button className={isDefault ? "hamburger hamburger-in" : "hamburger hamburger-out"}
                        onClick={() => {
                            sidebarStore.showSidebar = true
                            localStorage.setItem('activeSidebar', JSON.stringify(true))
                            setExpendState(true)
                            setisDefault(!isDefault)
                        }}
                    >
                        <img src="/assets/ico/chevrons-right.svg" alt=""/>
                    </button>
                </div>
                <div className="nav-menu">
                    {dataMenuSidebar.filter((menu: any) => menu.role === localStorage.getItem('module')).map((item: any, index: number) => (
                        <div key={index}>
                            {
                                item.submenu && item.submenu.length > 0 ? <>
                                    <div key={index}
                                        onClick={() => {
                                            if(heading !== item.link) {
                                                setHeading(item.link)
                                            }
                                            else setHeading('')
                                        }}
                                        className={`${isExpanded ? "menu-item" : "menu-item menu-item-NX"} ${checkMenuActive(item.link) ? 'menu_item__active' : ""}`}>
                                        <div className='d-flex justify-content-start'>
                                            <img className="menu-item-icon" src={item.icon + `${checkMenuActive(item.link) ? '_active' : ''}` +'.svg'} alt=""/>
                                            {isExpanded && <p>{item.text}</p>}
                                        </div>
                                        {isExpanded && item?.submenu?.length > 0 && <img className={`icon_down_submenu ${heading === item.link ? 'active_submenu': 'not_active_submenu'}`} src={checkMenuActive(item.link) ? "/assets/ico/chevron-right-white.svg" : "/assets/ico/chevron-right.svg"} alt=""/>}
                                    </div>
                                    {isExpanded && (heading === item.link) && <motion.div
                                        className='submenu_container'
                                        initial={{ opacity: 0, y: -10 }} // Effect when start
                                        animate={{ opacity: 1, y: 0 }} // Effect when show
                                        exit={{ opacity: 0, y: -10 }} // Effect when hidden
                                        transition={{ duration: 0.3 }} // Effect completion time
                                    >
                                        {
                                            item?.submenu?.map((itemsub: any, i: number) => {
                                                let path: any = item.link+itemsub.link
                                                return (
                                                    <Link className={`submenu_item ${(checkSubmenuActive(path) || checkSubmenuActive(path+itemsub.orthers)) ? 'submenu_item__active' : ""}`} key={i} to={path}>
                                                        <p style={{margin: "unset"}}>
                                                            {itemsub.text}
                                                        </p>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </motion.div>}
                                </> :
                                    <div onClick={() => {
                                            if(heading !== item.link) {setHeading(item.link)}
                                            else setHeading('')}}>
                                        <Link
                                            key={index}
                                            className={`${isExpanded ? "menu-item" : "menu-item menu-item-NX"} ${checkMenuActive(item.link) ? 'menu_item__active' : ""}`}
                                            to={item.link}
                                        >
                                            <div className='d-flex justify-content-start'>
                                                <img className="menu-item-icon" src={item.icon + `${checkMenuActive(item.link) ? '_active' : ''}` +'.svg'} alt=""/>
                                                {isExpanded && <p>{item.text}</p>}
                                            </div>
                                            {isExpanded && item?.submenu?.length > 0 && <img className='icon_down_submenu' src="/assets/ico/chevron-right.svg" alt=""/>}
                                        </Link>
                                    </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
    else return null
};

export default Sidebar;