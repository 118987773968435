import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../pages/saleMarketing/dashboard/Dashboard";
import KpiPage from "../pages/saleMarketing/kpi/KPIPage";
import TrademarkPage from "../pages/saleMarketing/trademark/TrademarkPage";
import BusinessPlanPage from "../pages/saleMarketing/businessPlan/BusinessPlanPage";
import MarketResearchPage from "../pages/saleMarketing/marketResearch/MarketResearchPage";
import DataPage from "../pages/saleMarketing/data/DataPage";
import FinancePage from "../pages/finance/costPage/CostPage";
import VoucherPage from "../pages/saleMarketing/voucher/VoucherPage";
import CustomerDataPage from "../pages/customerCare/customerData/CustomerData";
import CustomerBenefitPage from "../pages/customerCare/customerBenefit/CustomerBenefit";
import StockInformationPage from "../pages/customerCare/stockInformation/StockInformationPage";
import OrderPage from "../pages/order/OrderPage";
import KitchenManagerPage from "../pages/kitchenManager/KitchenManager";
import CustomerFeedback from "../pages/customerCare/customerFeedback/CustomerFeedback";
import StorageService from "../common/service/StorageService";
import SignUpPage from "../modules/auth/signUp/SignUpPage";
import DetailVoucherPage from "../pages/saleMarketing/voucher/components/detailListVoucher/DetailListVoucher";
import StaffPage from "../pages/staff/StaffPage";
import RndResearchPage from "../pages/saleMarketing/rndResearch/RndResearchPage";
import FormMarketResearch from "../pages/saleMarketing/marketResearch/components/formMarketResearch/FormMarketResearch";
import ChainStorePage from "../pages/chainStore/ChainStore";
import FormRndResearch from "../pages/saleMarketing/rndResearch/components/formRndResearch/FormRndResearch";
import FormTradeMarkPlan from "../pages/saleMarketing/trademark/components/formTradeMarkPlan/FormTradeMarkPlan";
import PurchasePage from "../pages/warehouses/purchase/PurchasePage";
import MaterialsPage from "../pages/setting/supplies/MaterialsPage";
import AdditivePage from "../pages/setting/supplies/AdditivePage";
import PackagingPage from "../pages/setting/supplies/PackagingPage";
import DevicesPage from "../pages/setting/supplies/DevicesPage";
import SettingInformationPage from "../pages/setting/information/SettingInformationPage";
import ListRestaurantPage from "../pages/setting/restaurants/RestaurantPage";
import RoomPage from "../pages/setting/room/RoomPage";
import TablePage from "../pages/setting/table/TablePage";
import WarehousePage from "../pages/setting/warehouse/WarehousePage";
import ProductPage from "../pages/setting/product/ProductPage";
import StaffSettingPage from "../pages/setting/staff/StaffPage";
import AccountPage from "../pages/setting/account/AccountPage";
import ImportMaterialsPage from "../pages/warehouses/importExportSupplies/import/ImportMaterialsPage";
import PickProductPageOrder from "../pages/order/components/pickProductPage/PickProductPage";
import CheckOrderPage from "../pages/checkOrder/CheckOrderPage";
import ImportAdditivePage from "../pages/warehouses/importExportSupplies/import/ImportAdditivePage";
import ImportDevicesPage from "../pages/warehouses/importExportSupplies/import/ImportDevicesPage";
import ImportPackagingPage from "../pages/warehouses/importExportSupplies/import/ImportPackagingPage";
import ExportMaterialsPage from "../pages/warehouses/importExportSupplies/export/ExportMaterialsPage";
import ExportAdditivePage from "../pages/warehouses/importExportSupplies/export/ExportAdditivePage";
import ExportPackagingPage from "../pages/warehouses/importExportSupplies/export/ExportPackagingPage";
import ExportDevicesPage from "../pages/warehouses/importExportSupplies/export/ExportDevicesPage";
import InventoryReportPage from "../pages/warehouses/inventoryReport/InventoryReportPage";
import StipulationsPage from "../pages/warehouses/stipulations/StipulationsPage";
import ReceiptPage from "../pages/warehouses/importExportSupplies/ReceiptPage";
import DeliveryBillPage from "../pages/warehouses/importExportSupplies/DeliveryBillPage";
import BrowserPage from "../pages/browser/BrowserPage";
import CheckAfterProcessingPage from "../pages/check/CheckAfterProcessingPage";
import CheckDuringProcessingPage from "../pages/check/CheckDuringProcessingPage";
import CheckBeforeEatingPage from "../pages/check/CheckBeforeEatingPage";
import SettingChangePointPage from "../pages/setting/changePoint/ChangePointPage";
import PersonalManagerPage from "../pages/personalManager/PersonalManagerPage";
import SummaryPurchasePage from "../pages/warehouses/purchase/SummaryPurchasePage";
import SettingRolePage from "../pages/setting/role/SettingRole";
import DepartmentPage from "../pages/setting/department/DepartmentPage";
import PositionPage from "../pages/setting/position/PositionPage";
import RevenuePage from "../pages/finance/revennuePage/RevenuePage";
import DashboardFinancePage from "../pages/finance/dashboard/DashboardFinance";
import DetailBusinessPlanPage from "../pages/saleMarketing/businessPlan/DetailBusinessWorkPage";
import RevenueReportPage from "../pages/summaryReport/revenueReport/RevenueReportPage";
import OrderShipPage from "../pages/transport/orders_ship/OrderShipPage";
import CostReportPage from "../pages/summaryReport/costReport/CostReportPage";
import OrderShipBuyId from "../pages/transport/orders_ship/DetailOrderShipById";
import OrderDetailPage from "../pages/order/OrderDetailPage";


export default function Redirect() {
    let linkDefault = <Navigate to={'/'} replace/>
    return (
        <Routes>
            <Route path={`/login`} element={<SignUpPage/>}/>
            <Route index element={<RevenueReportPage/>}/>
                <Route element={<ProtectedRoute/>}>
            </Route>
                <Route path={'/'} element={<RevenueReportPage/>}/>
                <Route path={'/bao-cao-chi-phi'} element={<CostReportPage/>}/>
                <Route path={'/bao-cao-khach-hang'} element={<ChainStorePage/>}/>
            <Route path={'/tong-quan'} element={<Dashboard/>}/>
            <Route path={'/kpi'} element={<KpiPage/>}/>
            <Route path={'/thuong-hieu'} element={<TrademarkPage/>}/>
            <Route path={'/thuong-hieu/tao-ke-hoach'} element={<FormTradeMarkPlan/>}/>
            <Route path={'/thuong-hieu/chi-tiet/:id'} element={<FormTradeMarkPlan/>}/>
            <Route path={'/ke-hoach-kinh-doanh'} element={<BusinessPlanPage/>}/>
            <Route path={'/ke-hoach-kinh-doanh/:slug'} element={<DetailBusinessPlanPage/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong'} element={<MarketResearchPage/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong/tao-bao-cao'} element={<FormMarketResearch/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong/chi-tiet/:id'} element={<FormMarketResearch/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-rnd'} element={<RndResearchPage/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-rnd/tao-bao-cao'} element={<FormRndResearch/>}/>
            <Route path={'/nghien-cuu-thi-truong/bao-cao-rnd/chi-tiet/:id'} element={<FormRndResearch/>}/>
            <Route path={'/data'} element={<DataPage/>}/>
            <Route path={'/tai-chinh'} element={<DashboardFinancePage/>}/>
            <Route path={'/chi-phi'} element={<FinancePage/>}/>
            <Route path={'/doanh-thu'} element={<RevenuePage/>}/>
            <Route path={'/voucher'} element={<VoucherPage/>}/>
            <Route path={`/voucher/:slug/:id`} element={<DetailVoucherPage/>}/>
            <Route path={'/du-lieu-khach-hang'} element={<CustomerDataPage/>}/>
            <Route path={'/quyen-loi-thanh-vien'} element={<CustomerBenefitPage/>}/>
            <Route path={'/phan-hoi-khach-hang'} element={<CustomerFeedback/>}/>
            <Route path={'/phieu-thong-tin'} element={<StockInformationPage/>}/>
            <Route path={'/phuc-vu'} element={<OrderPage/>}/>
            <Route path={'/phuc-vu/chi-tiet/:slug'} element={<OrderDetailPage/>}/>
            <Route path={'/phuc-vu/:codeOrder/:idOrder'} element={<PickProductPageOrder/>}/>
            <Route path={'/phuc-vu/chon-mon'} element={<PickProductPageOrder/>}/>
            <Route path={'/check-out'} element={<CheckOrderPage/>}/>
            <Route path={'/bep'} element={<KitchenManagerPage/>}/>
            <Route path={'/purchase'} element={<PurchasePage/>}/>
            <Route path={'/purchase/summary'} element={<SummaryPurchasePage/>}/>
            <Route path={'/setup/staff'} element={<StaffSettingPage/>}/>
            <Route path={'/setup/account'} element={<AccountPage/>}/>
            <Route path={'/setup/role'} element={<SettingRolePage/>}/>
            <Route path={'/setup/department'} element={<DepartmentPage/>}/>
            <Route path={'/setup/position'} element={<PositionPage/>}/>
            <Route path={'/warehouse/inventory'} element={<WarehousePage/>}/>
            <Route path={'/warehouse/materials'} element={<MaterialsPage/>}/>
            <Route path={'/warehouse/additives'} element={<AdditivePage/>}/>
            <Route path={'/warehouse/packaging'} element={<PackagingPage/>}/>
            <Route path={'/warehouse/devices'} element={<DevicesPage/>}/>
            <Route path={'/kiem-tra/truoc-che-bien'} element={<CheckAfterProcessingPage/>}/>
            <Route path={'/kiem-tra/khi-che-bien'} element={<CheckDuringProcessingPage/>}/>
            <Route path={'/kiem-tra/truoc-khi-an'} element={<CheckBeforeEatingPage/>}/>
            <Route path={'/nhan-su'} element={<StaffPage/>}/>
            <Route path={'/quan-ly-chuoi'} element={<ListRestaurantPage/>}/>
            <Route path={'/quan-ly-ca-nhan'} element={<PersonalManagerPage/>}/>
            <Route path={'/thiet-lap/thong-tin-nha-hang'} element={<SettingInformationPage/>}/>
            <Route path={'/thiet-lap/danh-sach-diem-ban'} element={<ListRestaurantPage/>}/>
            <Route path={'/thiet-lap/quan-ly-phong'} element={<RoomPage/>}/>
            <Route path={'/thiet-lap/quan-ly-ban'} element={<TablePage/>}/>
            <Route path={'/thiet-lap/doi-diem'} element={<SettingChangePointPage/>}/>
            <Route path={'/san-pham'} element={<ProductPage/>}/>
            <Route path={'/van-chuyen'} element={<OrderShipPage/>}/>
            <Route path={'/van-chuyen/:id'} element={<OrderShipBuyId/>}/>
            <Route path={'/import/receipt'} element={<ReceiptPage/>}/>
            <Route path={'/import/materials'} element={<ImportMaterialsPage/>}/>
            <Route path={'/import/additive'} element={<ImportAdditivePage/>}/>
            <Route path={'/import/packaging'} element={<ImportPackagingPage/>}/>
            <Route path={'/import/devices'} element={<ImportDevicesPage/>}/>
            <Route path={'/export/delivery_bill'} element={<DeliveryBillPage/>}/>
            <Route path={'/export/materials'} element={<ExportMaterialsPage/>}/>
            <Route path={'/export/additive'} element={<ExportAdditivePage/>}/>
            <Route path={'/export/packaging'} element={<ExportPackagingPage/>}/>
            <Route path={'/export/devices'} element={<ExportDevicesPage/>}/>
            <Route path={'/inventory_report'} element={<InventoryReportPage/>}/>
            <Route path={'/stipulations'} element={<StipulationsPage/>}/>
            <Route path={'/duyet'} element={<BrowserPage/>}/>
            <Route path="*" element={linkDefault}/>
        </Routes>
    )
}


const ProtectedRoute = ({children}: any) => {
    if (!StorageService.getToken()) {
        localStorage.removeItem('fnbId')
        localStorage.removeItem('module')
        localStorage.removeItem('activeSidebar')
        return <Navigate to={`/login`} replace/>;
    }
    return children;
}
