import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import './VoucherPage.scss'
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import ModalSelectVoucher from "./components/modalSelectTypeVoucher/ModalSelectTypeVoucher";
import DetailVoucher from "./components/modalDetailVoucher/ModaDetailVoucher";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {voucherStore} from "./VoucherStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import Loading from "../../../modules/components/Loading";
import {Link} from "react-router-dom";
import {slug} from "../../../common/utils/Utils";
import {Tooltip} from "antd";
export const convertTypePromotion = (value: string) => {
    switch (value.toUpperCase()) {
        case "VALUE":
            return "Voucher giá trị"
        case "GIVE_PRODUCT":
            return "Voucher tặng sản phẩm"
        case "COMBO":
            return "Voucher Combo"
        case "PRODUCT":
            return "Voucher mua nhiều giảm giá"
        case "BIRTHDAY":
            return "Voucher tặng sinh nhật"
        default:
            return null
    }
}
export const convertStatusPromotion = (value: string, type: string) => {
    if(type === 'BIRTHDAY')
        return <span style={{color: '#00AB56'}}>Đang diễn ra</span>
    else {
        switch (value) {
            case "WAITING":
                return <span style={{color: '#515158'}}>Chưa diễn ra</span>
            case "HAPPENING":
                return <span style={{color: '#00AB56'}}>Đang diễn ra</span>
            case "EXPIRED":
                return <span style={{color: '#FF424F'}}>Quá hạn</span>
            case "OUT_CODE":
                return <span style={{color: '#FF424F'}}>Hết mã</span>
            default:
                return null
        }
    }
}
export const convertStatusVoucher = (value: string) => {
    switch (value.toUpperCase()) {
        case "UNUSED":
            return <span style={{color: '#00AB56'}}>Chưa sử dụng</span>
        case "USED":
            return <span style={{color: '#515158'}}>Đã sử dụng</span>
        case "EXPIRED":
            return <span style={{color: '#FF424F'}}>Hết hạn</span>
        default:
            return null
    }
}
const VoucherPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: 'Loại mã',
            type: 'select',
            value: voucherStore.searchTypePromotion,
            placeholder: 'Tất cả',
            listValue: [
                {
                    value: "VALUE",
                    label: "Voucher giá trị"
                },
                {
                    value: "GIVE_PRODUCT",
                    label: "Voucher tặng sản phẩm"
                },
                {
                    value: "PRODUCT",
                    label: "Voucher mua nhiều giảm giá"
                },
                {
                    value: "COMBO",
                    label: "Voucher Combo"
                },
                {
                    value: "BIRTHDAY",
                    label: "Voucher tặng sinh nhật"
                }
            ],
            callback: async (e: any) => {
                voucherStore.searchTypePromotion = e
                await voucherStore.getPromotions(50).then()
            }
        },
        {
            label: 'Tình trạng',
            type: 'select',
            value: voucherStore.searchStatusPromotion ,
            placeholder: 'Tất cả',
            listValue: [{
                value: "WAITING",
                label : "Chưa diễn ra"
            },
                {
                    value: "HAPPENING",
                    label : "Đang diễn ra"
                },
                {
                    value: "EXPIRED",
                    label : "Quá hạn"
                }],
            callback: async (e: any) => {
                voucherStore.searchStatusPromotion = e
                await voucherStore.getPromotions(50).then()
            }
        }
    ]
    const convertTypeModal = (value: string) => {
        switch (value.toUpperCase()) {
            case "VALUE":
                return "#value_voucher"
            case "GIVE_PRODUCT":
                return "#product_voucher"
            case "COMBO":
                return "#combo_voucher"
            case "PRODUCT":
                return "#discount_voucher"
            case "BIRTHDAY":
                return "#birthday_voucher"
            default:
                return null
        }
    }
    useEffect(() => {
        voucherStore.getPromotions(50).then()
    }, [])
    return (
        <>
            <div className='voucher_container'>
                <div className='voucher_container__header'>
                    <TabRedirect tab='Quản lý Voucher' />
                    <div>
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            buttonAdd={<ButtonGlobal onClick={() => {
                                voucherStore.type = 'add'
                                voucherStore.resetError()
                            }} dataTarget='add_voucher' text='Thêm' ico='add_icon' className='btn_add' />}
                        />
                    </div>
                </div>
                {voucherStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex  mb-4 justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">Tên Voucher | Mã Voucher</th>
                            <th className="text-center">Loại mã</th>
                            <th className="text-center">Sản phẩm áp dụng</th>
                            <th className="text-center">Điểm bán</th>
                            <th className="text-center">Giá trị</th>
                            <th className="text-center">Đã sử dụng /Tổng số phát hành</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {voucherStore.listPromotion.length <= 0 ? <tr>
                            <td style={{height: '200px'}} colSpan={8} className='text-center fw-500'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    Chưa có dữ liệu
                                </div>
                            </td>
                        </tr> :
                        voucherStore.listPromotion.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">
                                        <div className='d-flex align-items-center'>
                                            <img className='tab_header__device' src="assets/ico/mdi_voucher-outline.svg" alt="" />
                                            <div className='d-flex flex-column align-items-start'>
                                                <span className='mx-2 text-start'>Voucher : {item?.name}</span>
                                                <span className='mx-2 text-start'>Mã: {item?.code}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center">{convertTypePromotion(item?.type)}</td>
                                    <td className="text-center">Tất cả sản phẩm</td>
                                    <td className="text-center">Tất cả</td>
                                    <td className="text-center">
                                        {
                                            item?.type === "PRODUCT" ? 'Theo số sản phẩm' : (item?.proviso?.type === 'PERCENTAGE' || item?.proviso?.type === 'SPECIFIC_AMOUNT')
                                                ?
                                                (<span>{item?.proviso?.type === 'PERCENTAGE' ? `${numberUtil.number_format(item?.proviso?.percentage?.value)} %` : `${numberUtil.number_format(item?.proviso?.specificAmount?.value)} đ`}</span>) : "-"

                                        }

                                    </td>
                                    {item?.type !== "BIRTHDAY" ? <td className="text-center">{numberUtil.number_format(item?.amountUsed)}/{numberUtil.number_format(item?.amount)}</td> : <td className="text-center">Không giới hạn</td>}
                                    <td className="text-center">{convertStatusPromotion(item?.state, item?.type)}</td>
                                    <td className="text-center fw-500">
                                            <Link className="btn p-0"
                                                  to={`/voucher/${slug(item.name)}/${item.id}`}
                                                  onClick={() => {
                                                      voucherStore.id = item?.id
                                                  }}>
                                                <Tooltip title='Chi tiết'>
                                                    <img src="/assets/ico/action/detail_icon.svg"
                                                         className="icon-action"
                                                         alt=""
                                                    />
                                                </Tooltip>
                                            </Link>
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target={convertTypeModal(item?.type)}
                                                    onClick={async () => {
                                                        voucherStore.id = item?.id
                                                        voucherStore.type = 'edit'
                                                        await voucherStore.getDetail(item?.id)
                                                    }}>
                                                <Tooltip title='Chỉnh sửa'>
                                                    <img src="/assets/ico/action/edit_icon.svg"
                                                         className="icon-action"
                                                         alt=""/>
                                                </Tooltip>
                                            </button>
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target={convertTypeModal(item?.type)}
                                                    onClick={async () => {
                                                        voucherStore.id = item?.id
                                                        voucherStore.type = 'copy'
                                                        await voucherStore.getDetail(item?.id)
                                                    }}>
                                                <Tooltip title='Sao chép'>
                                                    <img src="/assets/ico/action/duplicate.svg"
                                                         className="icon-action"
                                                         alt=""/>
                                                </Tooltip>
                                            </button>
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                    onClick={() => {voucherStore.id = item.id}}>
                                                <Tooltip title='Xóa'>
                                                <img src="/assets/ico/action/delete_icon.svg"
                                                     className="icon-action"
                                                     alt=""/>
                                                </Tooltip>
                                            </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <ModalSelectVoucher />
            <DetailVoucher />
            <PopupConfirm isLoading={voucherStore.isLoadingBt} action={voucherStore.delete} />
        </>
    );
}

export default observer(VoucherPage);