import React, {useEffect, useState} from 'react';
import "./RegisterPage.scss"
import {observer} from "mobx-react";
import StorageService from "../../../common/service/StorageService";
import {getUrlParameter, urlImage} from "../../../common/utils/Utils";
import {Input, Select, Spin} from "antd";
import {useLocation} from "react-router-dom";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {registerStore} from "./RegisterStore";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";

const RegisterPage = () => {
    const [isRegisterPage ,setIsRegisterPage] = useState(false)
    const [currentHref, setCurrentHref] = useState(window.location.href)
    if(getUrlParameter('token')) {
        StorageService.setToken(getUrlParameter('token') as any);
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
        registerStore.getToken().then()
    }
    useEffect(() => {
        if(!localStorage.getItem('fnbId')) registerStore.getListFnB().then(() => {
            localStorage.setItem('listFnb', JSON.stringify(registerStore.listFnB))
        })
    }, [])
    useEffect(() => {
        if(!localStorage.getItem('fnbId'))
            registerStore.getProvinces().then()
    }, [])
    const location = useLocation();
    useEffect(() => {
        setCurrentHref(window.location.href)
    }, [location]);
    const uploadImgLogoInformation = (e: any) => {
        registerStore.dataRequest.logo = e
    }
        return (
            <div className="signUp_main">
                <div className="row flex-grow">
                    <div style={{width: '45%'}} className="background-signup__container">
                        <img src="/assets/ico/sign_up_banner.svg" className="w-100 vh-100"
                             style={{objectFit: 'cover'}} alt=""/>
                    </div>
                    <div style={{width: '55%'}}>
                        <div className="d-flex justify-content-end align-items-center p-3 header_register">
                            <span className="me-2">Bạn đang đăng nhập với tài khoản</span><span style={{color: '#00953D'}}>{registerStore.username}</span>
                            <a className="header_register__logout"
                                    href={`${process.env.REACT_APP_DOMAIN_SSO}/login${currentHref ? `?redirectTo=${currentHref}` : ''}&service=fnb`}
                                    onClick={() => {
                                        StorageService.removeToken();
                                        StorageService.removeRefreshToken();
                                        localStorage.removeItem('fnbId')
                                        localStorage.removeItem('activeSidebar')
                                        localStorage.removeItem('dataOrder')
                                    }}
                            >
                                <img src="/assets/ico/action/log-out.svg" alt="" className="me-2"/>
                                Đăng Xuất
                            </a>
                        </div>
                        <div className='content-signup'>
                            {!isRegisterPage ?
                                <div className='content-signup__container'>
                                <h1>Chào mừng bạn đến với</h1>
                                <h1>Không gian Quản trị số</h1>
                                <p className='py-3 content-signup__subtitle'>Chuyển đổi số cho cuộc sống đẹp tươi trong tương lai!</p>
                                    {!(localStorage.getItem('statusAccount')) ? <div className='select_account__container custom-scrollbar'>
                                        {registerStore.listFnB.length > 0 && <form onChange={async (e: any) => {
                                            registerStore.idRestaurant = e.target.id
                                            await registerStore.getToken()
                                        }} id="restaurantForm">
                                            {
                                                registerStore.listFnB.map((item: any, i: number) => {
                                                    return (
                                                        <div className="card_restaurant mb-1" key={i} onClick={() => {
                                                            localStorage.setItem('name_restaurant', item?.label)
                                                            let address_restaurant = `${item?.address?.detail}, ${item?.address?.ward?.name}, ${item?.address?.district?.name}, ${item?.address?.province?.name}`
                                                            localStorage.setItem('address_restaurant', address_restaurant)
                                                        }}>
                                                            <input type="radio" name="pricing" id={item?.value} />
                                                            <label htmlFor={item?.value}>
                                                                <div className='card_restaurant__image'>
                                                                    <img src={item.logo ? urlImage(item.logo) : 'assets/ico/avatar_tmp.png'} alt="avatar"/>
                                                                </div>
                                                                <div className='card_restaurant__text'>
                                                                    <h5>{item?.label}</h5>
                                                                    <h2>
                                                                        Chủ đơn vị
                                                                    </h2>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </form>}
                                    </div> :
                                        <div className='block_account__container'>
                                            <h1>Tài khoản đã mất quyền truy cập!</h1>
                                            <p>(Vui lòng liên hệ đến quản lý để được mở khóa và tiếp tục sử dụng)</p>
                                            <button className="btn-reload_block__container"
                                                    onClick={async () => await registerStore.reloadBlockPage()}
                                            >
                                                {registerStore.isLoadingBt ? <Spin size="small" /> :
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <img src="/assets/ico/action/timer-reset.svg" alt=""/>
                                                        Tải lại
                                                    </div>}
                                            </button>
                                    </div>}
                                <div className='content-signup__btn'>
                                    <button className={registerStore.listFnB.length > 0 ? 'btn-redirect-register' : 'btn-redirect-register__noList'}
                                            onClick={() => setIsRegisterPage(!isRegisterPage)}
                                       >
                                        Đăng ký thêm cửa hàng
                                        {registerStore.listFnB.length === 0 && <img src="/assets/ico/move-right.svg" className='pt-1' alt=""/>}
                                    </button>
                                </div>
                            </div> :
                                (<div className="register_container">
                                    <div className="mb-1">
                                        <button className="btn_back" onClick={() => setIsRegisterPage(!isRegisterPage)}>
                                            <img src="/assets/ico/action/fi_arrow-left.svg" style={{width: '34px'}} alt=""/>
                                            Quay lại
                                        </button>
                                    </div>
                                    <div className="mb-2 register_title">
                                        THÔNG TIN ĐĂNG KÝ
                                    </div>
                                    <div className='w-100 form-group'>
                                        <label className='form-label'>Tên thương hiệu<span className='text-danger'>*</span></label>
                                        <Input type='text' value={registerStore.dataRequest?.name}
                                               onChange={(e: any) => registerStore.dataRequest.name = e.currentTarget.value}
                                               status={(registerStore.errors?.name && !registerStore.dataRequest?.name) ? "error" : ""}
                                               placeholder='Nhập tên ...' />
                                        {registerStore.errors?.name && !registerStore.dataRequest?.name && <small className="mb-2 text-error">{registerStore.errors?.name}</small>}
                                    </div>
                                    <div className='w-100 d-flex'>
                                        <div className='w-50 form-group pe-2'>
                                            <label className='form-label'>Quy mô (người)<span className='text-danger'>*</span></label>
                                            <Input type='text' value={registerStore.dataRequest?.businessNumber ? numberUtil.number_format(registerStore.dataRequest?.businessNumber) : ""}
                                                   onKeyDown={(evt: {
                                                       key: string
                                                       preventDefault: () => any
                                                   }) =>
                                                       ['e', 'E', '+', '-'].includes(evt.key) &&
                                                       evt.preventDefault()
                                                   }
                                                   onChange={(e: any) => {
                                                       if (
                                                           e === '' ||
                                                           Number(numberUtil.regexNumber(e)) >= 0
                                                       ) {
                                                           registerStore.dataRequest.businessNumber = numberUtil.regexNumber(e)
                                                       }
                                                   }}
                                                   status={(registerStore.errors?.businessNumber&& !registerStore.dataRequest?.businessNumber) ? "error" : ""}
                                                   placeholder='Nhập số ...' />
                                            {registerStore.errors?.businessNumber && !registerStore.dataRequest?.businessNumber && <small className="mb-2 text-error">{registerStore.errors?.businessNumber}</small>}
                                        </div>
                                        <div className='w-50 form-group ps-2'>
                                            <label className='form-label'>Số chi nhánh<span className='text-danger'>*</span></label>
                                            <Input type='text' value={registerStore.dataRequest?.numBranch ? numberUtil.number_format(registerStore.dataRequest?.numBranch) : ""}
                                                   onKeyDown={(evt: {
                                                       key: string
                                                       preventDefault: () => any
                                                   }) =>
                                                       ['e', 'E', '+', '-'].includes(evt.key) &&
                                                       evt.preventDefault()
                                                   }
                                                   onChange={(e: any) => {
                                                       if (
                                                           e === '' ||
                                                           Number(numberUtil.regexNumber(e)) >= 0
                                                       ) {
                                                           registerStore.dataRequest.numBranch = numberUtil.regexNumber(e)
                                                       }
                                                   }}
                                                   status={(registerStore.errors?.numBranch&& !registerStore.dataRequest?.numBranch) ? "error" : ""}

                                                   placeholder='Nhập số ...'
                                            />
                                            {registerStore.errors?.numBranch && !registerStore.dataRequest?.numBranch && <small className="mb-2 text-error">{registerStore.errors?.numBranch}</small>}
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex'>
                                        <div className='w-50 form-group pe-2'>
                                            <label className='form-label'>Họ và tên<span className='text-danger'>*</span></label>
                                            <Input type='text' value={registerStore.dataRequest?.ownerName}
                                                   onChange={(e: any) => registerStore.dataRequest.ownerName = e.currentTarget.value}
                                                   status={(registerStore.errors?.ownerName && !registerStore.dataRequest?.ownerName) ? "error" : ""}
                                                   placeholder='Nhập tên...'
                                            />
                                            {registerStore.errors?.ownerName && !registerStore.dataRequest?.ownerName && <small className="mb-2 text-error">{registerStore.errors?.ownerName}</small>}
                                        </div>
                                        <div className='w-50 form-group ps-2'>
                                            <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                            <Input type='number' value={registerStore.dataRequest?.ownerPhone}
                                                   onChange={(e: any) => registerStore.dataRequest.ownerPhone = e.currentTarget.value}
                                                   status={(registerStore.errors?.ownerPhone && !registerStore.dataRequest?.ownerPhone) ? "error" : ""}
                                                   placeholder='Nhập số điện thoại ...'
                                            />
                                            {registerStore.errors?.ownerPhone && !registerStore.dataRequest?.ownerPhone && <small className="mb-2 text-error">{registerStore.errors?.ownerPhone}</small>}
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div style={{width: '33%'}} className='form-group pe-2'>
                                            <label className='form-label'>Tỉnh/ Thành phố<span className='text-danger'>*</span></label>
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                value={registerStore.dataRequest.provinceId}
                                                status={(registerStore.errors?.provinceId && !registerStore.dataRequest?.provinceId) ? "error" : ""}
                                                onChange={async (e: any) => {
                                                    await registerStore.changeProvince(e)
                                                }}
                                                placeholder="Chọn" options={registerStore.listProvince}
                                            />
                                            {registerStore.errors?.provinceId  && !registerStore.dataRequest.provinceId && <small className="text-error">{registerStore.errors?.provinceId}</small>}
                                        </div>
                                        <div style={{width: '33%'}} className='form-group '>
                                            <label className='form-label'>Quận/ Huyện<span className='text-danger'>*</span></label>
                                            <Select
                                                showSearch
                                                status={(registerStore.errors?.districtId && !registerStore.dataRequest?.districtId) ? "error" : ""}
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                value={registerStore.dataRequest.districtId}
                                                onChange={async (e: any) => {
                                                    await registerStore.changeDistrict(e)
                                                }}
                                                placeholder="Chọn" options={registerStore.listDistrict}
                                            />
                                            {registerStore.errors?.districtId  && !registerStore.dataRequest.districtId && <small className="text-error">{registerStore.errors?.districtId}</small>}
                                        </div>
                                        <div style={{width: '33%'}} className='form-group ps-2'>
                                            <label className='form-label'>Xã/ Phường<span className='text-danger'>*</span></label>
                                            <Select
                                                showSearch
                                                status={(registerStore.errors?.wardId && !registerStore.dataRequest?.wardId) ? "error" : ""}

                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                value={registerStore.dataRequest.wardId}
                                                onChange={(e: any) => {
                                                    registerStore.dataRequest.wardId = e
                                                }}
                                                placeholder="Chọn" options={registerStore.listWard}
                                            />
                                            {registerStore.errors?.wardId  && !registerStore.dataRequest.wardId && <small className="text-error">{registerStore.errors?.wardId}</small>}
                                        </div>
                                    </div>
                                    <div className='w-100 form-group'>
                                        <label className='form-label'>Địa chỉ cụ thể<span className='text-danger'>*</span></label>
                                        <Input type='text' value={registerStore.dataRequest?.address}
                                               onChange={(e: any) => registerStore.dataRequest.address = e.currentTarget.value}
                                               status={(registerStore.errors?.address && !registerStore.dataRequest?.address) ? "error" : ""}
                                               placeholder='Nhập địa chỉ ...'
                                        />
                                        {registerStore.errors?.address && !registerStore.dataRequest?.address && <small className="mb-2 text-error">{registerStore.errors?.address}</small>}
                                    </div>
                                    <div className="w-100 flex flex-wrap justify-between mb-4">
                                        <label className='form-label'>Logo thương hiệu<span className='text-danger'>*</span></label>
                                        <div className='list_image'>
                                            <div className='list_'>
                                                <div
                                                    className='upload'
                                                >
                                                    <img src="/assets/ico/action/upload.svg" alt=""/>
                                                    <input
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        type="file"
                                                        onChange={async (e: any) =>
                                                            await uploadFileHelper(
                                                                e,
                                                                uploadImgLogoInformation
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {registerStore.dataRequest.logo && <div className='item'>
                                                    <img className='img' src={urlImage(registerStore.dataRequest.logo)} alt=""/>
                                                    <div
                                                        className='close_item'
                                                        onClick={() =>
                                                            registerStore.dataRequest.logo = ""
                                                        }
                                                    >
                                                        <img src="/assets/ico/action/button_clear_image.svg"
                                                             className="icon-action"
                                                             alt=""
                                                             title="Chỉnh sửa"/>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        {registerStore.errors?.logo && !registerStore.dataRequest?.logo && <small className="mb-2 text-error">{registerStore.errors?.logo}</small>}

                                    </div>
                                    <div className='w-100 mt-4 d-flex justify-content-end'>
                                        <button onClick={async () => {await registerStore.register()}} className="w-50 button_register">
                                            Đăng ký
                                        </button>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )}

export default observer(RegisterPage);