import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {revenueReportService} from "./RevenueReportService";



class RevenueReportStore {
    id: any = ''
    typeModal: string = ''
    monthRevenueStructure: any = new Date().getMonth()  + 1
    monthProductStructure: any = new Date().getMonth()  + 1
    searchProduct: any = null
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listProduct: any = []
    dataColor = [
        'rgba(232, 70, 70, 1)',
        'rgba(123, 95, 217, 1)',
        'rgba(14, 95, 217, 1)',
        'rgba(14, 195, 217, 1)',
        'rgba(255, 149, 0, 1)',
        'rgba(255, 19, 120, 1)',
        'rgba(252, 10, 210, 1)',
        'rgba(255, 249, 30, 1)',
    ]
    dataColorProduct = [
        'rgba(249, 65, 68, 1)',
        'rgba(45, 156, 219, 1)',
        'rgba(248, 150, 30, 1)',
        'rgba(249, 199, 79, 1)',
        'rgba(144, 190, 109, 1)',
        'rgba(123, 95, 217, 1)',
    ]
    dataMonth = [
        {value: 1, label: 'Tháng 1'},
        {value: 2, label: 'Tháng 2'},
        {value: 3, label: 'Tháng 3'},
        {value: 4, label: 'Tháng 4'},
        {value: 5, label: 'Tháng 5'},
        {value: 6, label: 'Tháng 6'},
        {value: 7, label: 'Tháng 7'},
        {value: 8, label: 'Tháng 8'},
        {value: 9, label: 'Tháng 9'},
        {value: 10, label: 'Tháng 10'},
        {value: 11, label: 'Tháng 11'},
        {value: 12, label: 'Tháng 12'},
    ]
    dataPromoteProduct : any = [0,0,0,0,0,0,0,0,0,0,0,0]
    dataPromoteProductMax : any = undefined
    dataRevenueLineChartMax : any = undefined
    dataRevenueStructure : any = []
    dataProductStructure : any = []
    dataBestSellerProduct: any = []

    constructor() {
        makeAutoObservable(this)
    }
    async getListProduct() {
        this.isLoading = true
        const result = await revenueReportService.getListProduct()
        if (result.status === HttpStatusCode.OK) {
            this.listProduct = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
            this.isLoading = false
        }
    }
    findMax(arr: any) {
        if (arr.length === 0) {
            return undefined; // Trả về undefined nếu mảng trống
        }
        return Math.max(...arr);
    }
    async getPromoteProduct() {
        this.isLoading = true
        const result = await revenueReportService.getPromoteProduct()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            const arr = result.body
            this.dataPromoteProductMax = this.findMax(arr.map((i: any) => i.revenue))
            const tmpPromote = [0,0,0,0,0,0,0,0,0,0,0,0]
            for (var i = 0; i < arr.length; i++) {
                var month = arr[i].month;
                var revenue = arr[i].revenue;
                if (month >= 1 && month <= 12) {
                    tmpPromote[month - 1] = revenue;
                }
            }
            revenueReportStore.dataPromoteProduct = tmpPromote
        }
    }
    async getRevenueStructure() {
        this.isLoading = true
        const result = await revenueReportService.getRevenueStructure()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataRevenueStructure = result.body
            this.dataRevenueStructure.sort((a: any, b: any) => {
                return b.revenue - a.revenue
            })
        }
    }
    async getProductStructure() {
        this.isLoading = true
        const result = await revenueReportService.getProductStructure()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            const resultData = result.body
            this.dataProductStructure = resultData.slice(0, 5)?.map((item: any, index: number) => {
                return {
                    ...item,
                    color: this.dataColorProduct[index]
                }
            });
            if(resultData.slice(5).reduce((accumulator: any, currentValue: any) => {
                return accumulator + currentValue.revenue;
            }, 0) > 0) {
                this.dataProductStructure = [...this.dataProductStructure, {
                    name: "Khác",
                    revenue: resultData.slice(5).reduce((accumulator: any, currentValue: any) => {
                        return accumulator + currentValue.revenue;
                    }, 0),
                    color: this.dataColorProduct[5]
                }]
            }
        }
    }
    async getBestSellerProduct() {
        this.isLoading = true
        const result = await revenueReportService.getBestSellerProduct()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.dataBestSellerProduct = result.body
        }
    }

}

export const revenueReportStore = new RevenueReportStore()