import {getRequest, IApiResponse, postRequest} from "../../../common/helpers/RequestHelper";
import {costStore} from "./CostStore";

class CostService{
    public getListCost(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/finances${!costStore.searchEstablishmentId ? '/fnb' : ''}${costStore.searchYear ? `?year=${costStore.searchYear}` : ''}${costStore.searchMonth ? `&month=${costStore.searchMonth}` : ''}${costStore.searchEstablishmentId ? `&establishmentId=${costStore.searchEstablishmentId}` : ''}`)
    }
    public getListRevenue(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/finances/establishment${costStore.searchYear ? `?year=${costStore.searchYear}` : ''}`)
    }
    public getListRestaurant(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/establishment`)
    }
    public updateCost(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/finances`, data)
    }
}
export const costService = new CostService()