import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import './KitchenManager.scss'
import {dateUtils} from "../../common/utils/DateUtils";
import NoContent from "../../modules/components/NoContent";
import {kitchenStore} from "./KitchenStore";
import {numberUtil} from "../../common/utils/NumberUtil";
import Loading from "../../modules/components/Loading";
import DetailKitchen from "./components/PopupKitchen";
import TabRedirect from "../../modules/components/tabRedirect/TabRedirect";
import ModalSelectRestaurant from "../order/components/selectRestaurant/SelectRestaurant";
import {orderStore} from "../order/OrderStore";
import {Tooltip} from "antd";

const convertStatusItemOrder = (value: string) => {

    switch (value) {
        case "SUCCESS":
            return <span className="color-green fw-500">Hoàn thành</span>
        case "IN_PROGRESS":
            return <span className="color-purple fw-500">Đang làm</span>
        case "CANCEL":
            return <span className="color-red fw-500">Hủy</span>
        default:
            return <span className="color-pending fw-500">Chưa làm</span>
    }
}

const KitchenManagerPage = () => {
    const modalRef = useRef(null);

    useEffect(() => {
        if(!kitchenStore.searchEstablishmentId) {
            kitchenStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
        }
        if(!kitchenStore.establishmentName) {
            kitchenStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
        }
        kitchenStore.getKitchen().then()
        orderStore.getListRestaurant().then(() => {
            if (modalRef.current && !JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId && orderStore.listRestaurant.length > 0) {
                (modalRef.current as HTMLButtonElement).click();
            }
        })
    }, [])

    return (
        <>
            <div className='service_container'>
                <div className='tab_filter_header mb-4'>
                    <TabRedirect tab={`${kitchenStore?.establishmentName ? kitchenStore?.establishmentName : 'Tất cả điểm bán'}`}  />
                    <button ref={modalRef}  data-bs-toggle="modal" data-bs-target='#select_restaurant_order' className='btn_select_restaurant'>Chuyển điểm bán</button>
                </div>
                {kitchenStore.isLoading ? <Loading/> :
                    <div className="table-responsive mb-0">
                        <table className='table align-middle border mb-0'>
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Giờ gọi</th>
                                <th>Tên sản phẩm</th>
                                <th className="text-center">Đơn vị</th>
                                <th className="text-center">Số lượng đặt</th>
                                <th className="text-center">Tình trạng</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {kitchenStore.listKitchen && kitchenStore.listKitchen.length > 0 ? kitchenStore.listKitchen.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">{dateUtils.formatDate(item.createdAt, 'HH:MM')}</td>
                                        <td>{item.productName}</td>
                                        <td className="text-center">{item.unit}</td>
                                        <td className="text-center">{numberUtil.number_format(item.quantity)}</td>
                                        <td className="text-center">{convertStatusItemOrder(item.status)}</td>
                                        <td className="text-center">
                                            <div className="text-end d-inline-block" style={{width: 102}}>
                                                {(item.status !== "SUCCESS" && item.status !== "CANCEL") &&
                                                        <button className="btn p-0 mx-1" disabled={kitchenStore.isLoadingBt}
                                                                onClick={() => kitchenStore.changeStatus(item)}>
                                                            <Tooltip title={item.status === "PENDING" ? 'Bắt đầu nấu' : 'Nấu xong'}>
                                                                <img src="/assets/ico/action/check-circle.svg"
                                                                     className="icon-action"
                                                                     alt=""/>
                                                            </Tooltip>
                                                        </button>
                                                }
                                                {(item.status === "PENDING" || item.status === "IN_PROGRESS" ) &&
                                                        <button className="btn p-0 mx-1" disabled={kitchenStore.isLoadingBt}
                                                                onClick={() => kitchenStore.cancelKitchen(item.ids)}>
                                                            <Tooltip title={'Hủy'}>
                                                                <img src="/assets/ico/action/x-circle (1).svg"
                                                                     className="icon-action"
                                                                     alt=""/>
                                                            </Tooltip>
                                                        </button>
                                                }
                                                    <button type='button' className="btn p-0 mx-1"
                                                            onClick={() => kitchenStore.getDetail(item.ids)}
                                                            data-bs-toggle="modal" data-bs-target='#detail_kitchen'>
                                                        <Tooltip title={'Chi tiết'}>
                                                            <img src="/assets/ico/action/clipboard-list.svg"
                                                                 className="icon-action" alt="" data-toggle="tooltip"
                                                                 data-placement="top"
                                                                 title="Chi tiết"/>
                                                        </Tooltip>
                                                    </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <NoContent colSpan={7}/>}
                            </tbody>
                        </table>
                    </div>
                }

                <DetailKitchen/>

                <div>
                    {/*<div className="table-responsive d-flex flex-column mb-4 justify-content-between">*/}
                    {/*    <table className='table align-middle border gy-7 gs-7'>*/}
                    {/*        <thead>*/}
                    {/*        <tr>*/}
                    {/*            <th className="text-center">Mã đơn</th>*/}
                    {/*            <th className="text-center">Thời gian</th>*/}
                    {/*            <th className="text-center">Phân loại <br/> khách hàng</th>*/}
                    {/*            <th className="text-center">Loại</th>*/}
                    {/*            <th className="text-center">Phòng</th>*/}
                    {/*            <th className="text-center">Bàn</th>*/}
                    {/*            <th className="text-center">Thao tác</th>*/}
                    {/*        </tr>*/}
                    {/*        </thead>*/}
                    {/*        <tbody>*/}
                    {/*        {kitchenStore.listKitchen && kitchenStore.listKitchen.length > 0 ? kitchenStore.listKitchen.map((item, i) => {*/}
                    {/*            if(!item.isShow){*/}
                    {/*                return (*/}
                    {/*                    <tr>*/}
                    {/*                        <td className="text-center">#{item.code}</td>*/}
                    {/*                        <td className="text-center">{dateUtils.formatDate(item?.checkinTime, 'hh:mm DD/MM/YYYY')}</td>*/}
                    {/*                        <td className="text-center">-</td>*/}
                    {/*                        <td className="text-center">{convertTypeOrder(item.type)}</td>*/}
                    {/*                        <td className="text-center">{item.room?.name}</td>*/}
                    {/*                        <td className="text-center">{item.table?.name}</td>*/}
                    {/*                        <td className="text-center fw-500">*/}
                    {/*                            <button className="btn p-0 mx-1" onClick={() => kitchenStore.showDetail(i, item)}>*/}
                    {/*                                <img src="/assets/ico/action/clipboard-list.svg"*/}
                    {/*                                     className="icon-action"*/}
                    {/*                                     alt="" data-toggle="tooltip" data-placement="top"*/}
                    {/*                                     title="Chi tiết"/>*/}
                    {/*                            </button>*/}
                    {/*                        </td>*/}
                    {/*                    </tr>*/}
                    {/*                )*/}
                    {/*            } else return (*/}
                    {/*                <tr className='service_item_container'>*/}
                    {/*                    <td colSpan={9} className='p-3'>*/}
                    {/*                        <div className="table-item-responsive d-flex justify-content-between">*/}
                    {/*                            <table  className='table align-middle border gy-7 gs-7'>*/}
                    {/*                                <thead>*/}
                    {/*                                <tr>*/}
                    {/*                                    <th className="text-center">STT</th>*/}
                    {/*                                    <th className="text-center">Giờ gọi</th>*/}
                    {/*                                    <th>Tên sản phẩm</th>*/}
                    {/*                                    <th>Mã sản phẩm</th>*/}
                    {/*                                    <th className="text-center">Đơn vị</th>*/}
                    {/*                                    <th className="text-center">Số lượng</th>*/}
                    {/*                                    <th>Ghi chú</th>*/}
                    {/*                                    <th className="text-center">Tình trạng</th>*/}
                    {/*                                </tr>*/}
                    {/*                                </thead>*/}
                    {/*                                <tbody>*/}
                    {/*                                {item?.items.map((itemProduct: any, indexProduct: number) => {*/}
                    {/*                                    return (*/}
                    {/*                                        <tr key={indexProduct}>*/}
                    {/*                                            <td className="text-center">{indexProduct + 1}</td>*/}
                    {/*                                            <td className="text-center">{dateUtils.formatDate(item?.createdAt, 'hh:mm')}</td>*/}
                    {/*                                            <td className="text-center">{itemProduct?.product?.name}</td>*/}
                    {/*                                            <td className="text-center">{itemProduct?.product?.batch}</td>*/}
                    {/*                                            <td className="text-center">{itemProduct?.product?.unit}</td>*/}
                    {/*                                            <td className="text-center">{itemProduct?.quantity}</td>*/}
                    {/*                                            <td className="text-center">{itemProduct?.note}</td>*/}
                    {/*                                            <td className="text-center">{convertStatusItemOrder(itemProduct?.status)}</td>*/}
                    {/*                                        </tr>*/}
                    {/*                                    )*/}
                    {/*                                })}*/}
                    {/*                                </tbody>*/}
                    {/*                            </table>*/}
                    {/*                        </div>*/}

                    {/*                    </td>*/}
                    {/*                </tr>*/}

                    {/*            )*/}
                    {/*        }) : <NoContent colSpan={7}/>}*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*</div>*/}
                </div>
            </div>
            <ModalSelectRestaurant />
        </>
    );
}

export default observer(KitchenManagerPage);