/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import "./styles/nav.scss";
import StorageService from "../../common/service/StorageService";
import {Link, useLocation} from "react-router-dom";
import {registerStore} from "../auth/register/RegisterStore";
import {urlImage, urlImageSSO} from "../../common/utils/Utils";
import {observer} from "mobx-react";
import {listMenu} from "../../common/constants/Constants";

const NavHeader = () => {
  const ref = React.useRef<any>(null)
  const refAction = React.useRef<any>(null)
  const [showAction, setShowAction] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)
  const [currentHref, setCurrentHref] = useState(window.location.href)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
      setActiveMenu(false)
    }

    if (refAction.current && !refAction.current.contains(event.target as HTMLDivElement)) {
      setShowAction(false)
    }
  }
  const location = useLocation();
  useEffect(() => {
      setCurrentHref(window.location.href)
  }, [location]);

  return (
    <div className="header-top">
      <div className="content-header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={'/'} onClick={() => {
            localStorage.setItem("activeSidebar", JSON.stringify(true))
            localStorage.setItem("module", 'Báo cáo')
          }}>
            <img
                style={{ width: "30px", height: "40px" }}
                src="/assets/ico/logo_fnb.svg"
                alt=""
            />
          </Link>
          <div style={{marginLeft: '20px'}}>
            <h4>{localStorage.getItem('name_restaurant')}</h4>
            <span style={{color: '#EF7752', fontSize: '14px', fontWeight: '600'}}>{localStorage.getItem("module")}</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="notice dropdown ml-4">
            <img src="/assets/ico/li_building.svg" alt="" />
            <div className="box_restaurant">
              <div className='box_restaurant__header'>
                Chuyển đổi thương hiệu
              </div>
              {
                  JSON.parse(localStorage.getItem('listFnb') as any) && localStorage.getItem('fnbId') &&
                  JSON.parse(localStorage.getItem('listFnb') as any).filter((i: any) => i.value !== Number(localStorage.getItem('fnbId'))).map((item: any, index: number) => {
                    return(
                        <button key={index} onClick={async () => {
                          registerStore.idRestaurant = item.value
                          localStorage.removeItem('dataOrder')
                          await registerStore.getToken()
                          localStorage.setItem('name_restaurant', item?.label)
                          let address_restaurant = `${item?.address?.detail}, ${item?.address?.ward?.name}, ${item?.address?.district?.name}, ${item?.address?.province?.name}`
                          localStorage.setItem('address_restaurant', address_restaurant)
                        }}>
                          <img src={urlImage(item.logo)} className='logo' alt="logo"/>
                          {item.label}
                        </button>
                    )
                  })
              }
            </div>
          </div>
          <div className="user_profile d-flex align-items-center justify-content-between position-relative">
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="avatar">
                  <img
                    src={registerStore.avatar ? urlImageSSO(registerStore.avatar) : "/assets/ico/avatar_tmp.png"}
                    alt=""
                  />
                </div>

                <div className="info_name">
                  <p className="name">{registerStore.username}</p>
                  <p className="manage">Quản lý</p>
                </div>
              </div>
              <div ref={refAction}>
                  <div className="action_info" style={{cursor: 'pointer'}}
                       onClick={() => setShowAction(!showAction)}>
                    <img src="/assets/ico/select_dot.svg" alt=""/>
                  </div>
                  <div className={`box_user_info ${showAction ? 'show' : ''}`}>
                    <a href={`${process.env.REACT_APP_DOMAIN_SSO}/profile?redirectTo=${window.location.origin}&service=fnb&username=${registerStore.phone}`}
                       target="_blank" rel="noreferrer">
                      Tài khoản
                    </a>
                    <a href={`${window.location.origin}/login${currentHref ? `?redirectTo=${currentHref}` : ''}`}
                       onClick={() => {
                         StorageService.removeToken();
                         StorageService.removeRefreshToken();
                         localStorage.removeItem('fnbId')
                         localStorage.removeItem('activeSidebar')
                         localStorage.removeItem('dataOrder')
                       }}
                    >
                      Đăng xuất
                    </a>
                  </div>
              </div>
            </div>
          </div>
          <div ref={ref} className="menu_toggle">
            <img onClick={() => {setActiveMenu(!activeMenu)}}
                 className="ml-4 image_toggle cursor_pointer" src="/assets/ico/select_app.svg" alt=""/>
            <div className={`profile-dropdown-list ${activeMenu ? "active" : ""}`}>
              {
                listMenu.map((item: any, index: number) => {
                  return(
                      <div key={index} className="profile-dropdown-list-item">
                        <Link
                            target={item?.type === 'redirect' ? '_blank' : ''}
                            to={item.link}
                            onClick={() => {
                              if(item?.title) {
                                localStorage.setItem('module', item?.title)
                                setActiveMenu(!activeMenu)
                              }
                            }}
                            className='d-flex flex-column'>
                          <img src={item.icon} alt=""/>
                          <span>{item.text}</span>
                        </Link>
                      </div>
                  )
                })
              }
              <div className="profile-dropdown-list-item">
                <Link
                    target={'_blank'}
                    to={`https://ommani.net/redirect?token=${StorageService.getToken()}`}
                    className='d-flex flex-column'>
                  <img src={'/assets/ico/menu/web.svg'} alt=""/>
                  <span>Tạo web</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};
export default observer(NavHeader);
