import React from 'react';
import {observer} from "mobx-react";
import './AddEditRoom.scss'
import {Select, Input, Spin} from 'antd';
import {roomStore} from "../../RoomStore";
const ModalAddEditRoom = () => {
    return (
        <div className='modal fade' id='add_edit_room' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>
                            {roomStore.typeModal === 'add' ? 'THÊM PHÒNG' : 'CẬP NHẬT THÔNG TIN PHÒNG'}
                            {roomStore.establishmentName && <span className='ms-2' style={{fontSize: '16px', fontStyle: 'italic', color: '#000'}}>{`(${roomStore.establishmentName})`}</span>}
                        </div>

                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên phòng<span className='text-danger'>*</span></label>
                                <Input type='text' value={roomStore.dataRequest?.name}
                                       status={(roomStore.errors?.name && !roomStore.dataRequest?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           roomStore.dataRequest.name = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {roomStore.errors?.name && !roomStore.dataRequest.name && <small className="text-error">{roomStore.errors?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Tình trạng<span className='text-danger'>*</span></label>
                                <Select
                                    value={roomStore.dataRequest.status}
                                    status={(roomStore.errors?.status && !roomStore.dataRequest?.status) ? "error" : ""}
                                    onChange={(e: any) => {
                                        roomStore.dataRequest.status = e
                                    }}
                                    placeholder="Chọn" options={[{value: 'ACTIVE', label: 'Hoạt động'}, {value: 'INACTIVE', label: 'Chưa hoạt động'}]}
                                />
                                {roomStore.errors?.status  && !roomStore.dataRequest.status && <small className="text-error">{roomStore.errors?.status}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            {!roomStore.establishmentId ? <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Chọn điểm bán<span className='text-danger'>*</span></label>
                                <Select
                                    value={roomStore.establishmentId ? roomStore.establishmentId : roomStore.dataRequest.establishmentId}
                                    status={(roomStore.errors?.establishmentId && !roomStore.establishmentId) ? "error" : ""}
                                    onChange={(e: any, value: any) => {
                                        roomStore.establishmentId = e
                                        roomStore.establishmentName = value.label
                                    }}
                                    placeholder="Chọn" options={roomStore.listRestaurant}
                                />
                                {roomStore.errors?.establishmentId  && !roomStore.establishmentId && <small className="text-error">{roomStore.errors?.establishmentId}</small>}
                            </div> : <div className='w-50 form-group pe-2'></div>}
                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn__voucher'
                                        onClick={async () => {
                                            await roomStore.addEditRoom()}}>
                                    {roomStore.isLoadingBt ? <Spin size="small" /> : roomStore.typeModal === 'add' ?  'Thêm' : 'Cập nhật'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddEditRoom);

