import React from 'react';
import {observer} from "mobx-react";
import './detailOrder.scss'
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import {dataStore} from "../../../data/DataStore";
import {checkOrderStore} from "../../../../checkOrder/CheckOrderStore";


const DetailOrderModal = () => {
    const convertStatus= (value: string) => {
        switch (value.toUpperCase()) {
            case "SUCCESS":
                return <span className="color-green" style={{fontWeight: '500'}}>Hoàn thành</span>
            case "PROCESS":
                return <span className="color-purple fw-medium" style={{fontWeight: '500'}}>Đang làm</span>
            case "CANCEL":
                return <span className="color-red fw-medium" style={{fontWeight: '500'}}>Hủy</span>
            case "PENDING":
                return <span className="color-pending fw-medium" style={{fontWeight: '500'}}>Chưa làm</span>
            default:
                return null
        }
    }
    const convertDetailVoucher= (type: string) => {
        switch (type) {
            case "GIVE_PRODUCT":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Sản phẩm tặng ({dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.[0]?.name})</div>
                </div>
            case "VALUE":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Giảm ({dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                </div>
            case "COMBO":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Giảm ({dataStore?.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                </div>
            default:
                return <div className='d-flex justify-content-start align-items-center'>
                </div>
        }
    }
    const convertDetailPriceVoucher= () => {
        return <div className='d-flex justify-content-start align-items-center'>
            <div className='mx-2'>Đơn tối thiểu :</div>
            <div className={dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.minOrderValue <= dataStore.dataDetail.totalAmount ? 'color-green' : 'color-red'}>({numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.minOrderValue)} đ)</div>
            {dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "PERCENTAGE" &&
                <>
                    <span className='mx-1'>;</span>
                    <div className='mx-2'>Giảm tối đa :</div>
                    <div>{dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount ? `(${numberUtil.number_format(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount)} đ)` : `(Không giới hạn)`}</div>
                </>
            }
            {dataStore.dataDetail?.vouchers?.[0]?.voucher?.type === "COMBO" &&
                <>
                    <span className='mx-1'>;</span>
                    <div className='mx-2'>Combo áp dụng :</div>
                    <span>{dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.map((item: any, i: number) => {
                        return <span key={i}>{(i+1 === dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.length) ?`${item?.name}` : `${item?.name} ,`}</span>
                    })}</span>
                </>
            }
        </div>
    }
    return (
        <div className="modal fade" id="detail_order" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"/>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='modal-order--content'>
                        <div className='modal-order--head'>
                            <div className='modal-order--headFirst'>
                                <div className='modal-order--title'>
                                    Thông tin đơn hàng
                                </div>
                                <div className='d-flex'>
                                    <div className='modal-order--name d-flex flex-column'>
                                        <span>Mã đơn:</span>
                                        <span>Điểm bán: </span>
                                        <span>Thời gian đặt:</span>
                                        <span>Loại: </span>
                                        <span>Phòng - Bàn: </span>
                                        <span>Check in- out: </span>
                                    </div>
                                    <div className='modal-order--value d-flex flex-column w-50'>
                                        <span>#{dataStore.dataDetail?.code}</span>
                                        <span>{dataStore.dataDetail?.establishment?.name}</span>
                                        <span>{dateUtils.formatDate(dataStore.dataDetail.checkinTime, 'HH:MM DD/MM/YYYY')}</span>
                                        <span>{dataStore.dataDetail.type === "OFFLINE" ? 'Tại chỗ' : 'Online'}</span>
                                        <span>{dataStore.dataDetail.room?.name} - {dataStore.dataDetail.table?.name}</span>
                                        <span>{dateUtils.formatDate(dataStore.dataDetail.checkinTime, 'HH:MM')} - {dateUtils.formatDate(dataStore.dataDetail.checkoutTime, 'HH:MM')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-order--headSecond'>
                                <div className='modal-order--title'>
                                    Thông tin khách hàng
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Họ & Tên: </span>
                                            <span>Số điện thoại: </span>
                                            <span>Giới tính: </span>
                                            <span>Ngày sinh: </span>
                                            <span>Địa chỉ: </span>
                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-60'>
                                            <span>{dataStore.dataDetail?.user?.name ?? '-'}</span>
                                            <span>{dataStore.dataDetail?.user?.phone ?? '-'}</span>
                                            <span>{dataStore.dataDetail?.user?.gender == "MALE" ? 'Nam' : 'Nữ'}</span>
                                            <span>{dateUtils.formatDate(dataStore.dataDetail.user?.birthDay)}</span>
                                            <span>{dataStore.dataDetail.user?.address ?? '-'}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>ID: </span>
                                            <span>Loại khách hàng: </span>
                                            <span>Điểm: </span>
                                        </div>
                                        <div style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-30'>
                                            <span>{dataStore.dataDetail.user?.id ?? '-'}</span>
                                            <span>Thường</span>
                                            <span>{numberUtil.number_format(dataStore.dataDetail.user?.point)}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table className='table align-middle border gy-7 gs-7 mt-4'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Thời gian</th>
                                    <th>Tên sản phẩm</th>
                                    <th>Mã sản phẩm</th>
                                    <th className="text-center">Đơn vị</th>
                                    <th className="text-center">Số lượng</th>
                                    <th className="text-center">Đơn giá (VNĐ)</th>
                                    <th className="text-center">Thành tiền (VNĐ)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dataStore.dataDetail.items && dataStore.dataDetail.items.map((item: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{i+1}</td>
                                            <td className="text-center">{dateUtils.formatDate(item.createdAt, 'HH:MM')}</td>
                                            <td>{item.product?.name}</td>
                                            <td>{item.product?.batch}</td>
                                            <td className="text-center">{item.product?.unit}</td>
                                            <td className="text-center">{item?.quantity}</td>
                                            <td className="text-center fw-500">{numberUtil.number_format(item.product?.price)}</td>
                                            <td className="text-center fw-500">
                                                {numberUtil.number_format(item?.quantity * item.product?.price)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{marginRight: '48px', width: '90%'}}>Tổng tiền dịch vụ:</div>
                                            <div>{numberUtil.number_format(dataStore.dataDetail?.totalAmount)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{marginRight: '48px', width: '90%'}}>Chiết khấu:</div>
                                            <div>{numberUtil.number_format(dataStore.dataDetail?.totalDiscountAmount)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                {dataStore.dataDetail?.vouchers?.[0]?.voucher ? convertDetailVoucher(dataStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.type) :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Thuế GTGT:</div>
                                            </div>
                                            <div>0 %</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                {dataStore.dataDetail?.vouchers?.[0]?.voucher ? convertDetailPriceVoucher() :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Tổng tiền thành toán:</div>
                                            </div>
                                            <div>{numberUtil.number_format(dataStore.dataDetail.totalPayment)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(DetailOrderModal);