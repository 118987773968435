import {makeAutoObservable} from "mobx";
import {positionService} from "../position/PositionService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";

export interface IDataRequestAddAccount {
    username: string
    name: string
}


class SettingRoleStore {
    id: any = ''
    namePosition: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listPosition: any[] = [1,2,3]
    listRole : any[] = [
        {
            title: "QUYỀN QUẢN TRỊ",
            keyRole: "QQT1",
            value: [1],
            listRole : [{value: 1 , label: 'Xem DS Tài khoản'}, {value: 2 , label: 'Xem DS Tài khoản'},{value: 3 , label: 'Xem DS Tài khoản'},{value: 4 , label: 'Xem DS Tài khoản'},{value: 5 , label: 'Xem DS Tài khoản'},{value: 6 , label: 'Xem DS Tài khoản'},{value: 7 , label: 'Xem DS Tài khoản'}]
        },
        {
            title: "QUYỀN CHỦ KHO",
            keyRole: "QQT2",
            value: [2],
            listRole : [{value: 1 , label: 'Xem DS Tài khoản'},{value: 2 , label: 'Xem DS Tài khoản'},{value: 3 , label: 'Xem DS Tài khoản'},{value: 4 , label: 'Xem DS Tài khoản'},{value: 5 , label: 'Xem DS Tài khoản'},{value: 6 , label: 'Xem DS Tài khoản'},{value: 7 , label: 'Xem DS Tài khoản'}]
        },
        {
            title: "QUYỀN CHỦ CHI NHÁNH",
            keyRole: "QQT3",
            value: [3],
            listRole : [{value: 1 , label: 'Xem DS Tài khoản'},{value: 2 , label: 'Xem DS Tài khoản'},{value: 3 , label: 'Xem DS Tài khoản'},{value: 4 , label: 'Xem DS Tài khoản'},{value: 5 , label: 'Xem DS Tài khoản'},{value: 6 , label: 'Xem DS Tài khoản'},{value: 7 , label: 'Xem DS Tài khoản'}]
        },
        {
            title: "QUYỀN NHÂN VIÊN",
            keyRole: "QQT4",
            value: [3,4],
            listRole : [{value: 1 , label: 'Xem DS Tài khoản'},{value: 2 , label: 'Xem DS Tài khoản'},{value: 3 , label: 'Xem DS Tài khoản'},{value: 4 , label: 'Xem DS Tài khoản'},{value: 5 , label: 'Xem DS Tài khoản'},{value: 6 , label: 'Xem DS Tài khoản'},{value: 7 , label: 'Xem DS Tài khoản'}]
        },
        {
            title: "QUYỀN NHÂN VIÊN KHO",
            keyRole: "QQT5",
            value: [1,2,5],
            listRole : [{value: 1 , label: 'Xem DS Tài khoản'},{value: 2 , label: 'Xem DS Tài khoản'},{value: 3 , label: 'Xem DS Tài khoản'},{value: 4 , label: 'Xem DS Tài khoản'},{value: 5 , label: 'Xem DS Tài khoản'},{value: 6 , label: 'Xem DS Tài khoản'},{value: 7 , label: 'Xem DS Tài khoản'}]
        },
    ]

    isValidateUsername : boolean = false
    dataRequest: IDataRequestAddAccount = {
        username: "",
        name: ""
    }
    errors: any = {
        username: "",
        validateUsername: ""
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            username: "",
            name: ""
        }
        this.errors = {
            username: "",
            validateUsername: ""
        }
        this.isValidateUsername = false
    }

    async getPosition() {
        this.isLoading = true
        const result = await positionService.getPosition()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listPosition = result.body
        }
    }

}

export const settingRoleStore = new SettingRoleStore();