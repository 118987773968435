import React from 'react';
import {observer} from "mobx-react";
import './ModalSelectTypeService.scss'
import ModalSelectRoom from "../modalSelectRoom/ModalSelectRoom";
import {orderStore} from "../../OrderStore";
import $ from "jquery";
import {Link} from "react-router-dom";
import ModalAddUserInforOrder from "../modalInfoOrder/ModalInfoOrder";

const ModalSelectTypeSerive = () => {

    return (
        <>
            <div className='modal fade' id='add_service' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_service__container'>
                            <h1 className='modal_select_service__title'>
                                Loại hình
                            </h1>
                            <div className='modal_select_service__cardContainer'>
                                <button onClick={async () => {
                                    orderStore.dataRequest.type = "OFFLINE"
                                    await orderStore.getListRoom().then()
                                }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#select_room_order' className='modal_select_service__card'>
                                    <div className='modal_select_service__cardTitle'>
                                        TẠI CHỖ
                                    </div>
                                </button>
                                <button onClick={async () => {
                                    orderStore.dataRequest.type = "OFFLINE"
                                    $('#add_service').trigger('click')
                                    orderStore.dataRequest.roomId = null
                                    orderStore.dataRequest.tableId = null
                                    localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                                    window.location.href = '/phuc-vu/chon-mon';
                                }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#select_room_order' className='modal_select_service__card'>
                                    <div className='modal_select_service__cardTitle'>
                                        MANG ĐI
                                    </div>
                                </button>
                                <button onClick={async () => {
                                    orderStore.dataRequest.type = "ORDER"
                                    orderStore.clearFormInforOrder()
                                    await orderStore.getListRoom().then()
                                }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#add_user_infor_order' className='modal_select_service__card'>
                                    <div className='modal_select_service__cardTitle'>
                                        ĐẶT CHỖ
                                    </div>
                                </button>
                                <Link to={`/phuc-vu/chon-mon`} onClick={() => {
                                    orderStore.dataRequest.type = "ONLINE"
                                    orderStore.dataRequest.roomId = null
                                    orderStore.dataRequest.tableId = null
                                    localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                                }} data-bs-dismiss="modal">
                                    <button className='modal_select_service__card'>
                                        <div className='modal_select_service__cardTitle'>
                                            GỌI SHIP
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSelectRoom />
            <ModalAddUserInforOrder />
        </>

    )

}

export default observer(ModalSelectTypeSerive);
