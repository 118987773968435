import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {positionService} from "./PositionService";


export interface IDataRequestPosition{
    name: string
    permissionIds: any[]
}

class PositionStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listPosition: any[] = []

    dataRequest : IDataRequestPosition = {
        name: "",
        permissionIds: []
    }
    errors: any = {
        name: "",
        permissionIds: ""
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.typeModal = 'add'
        this.dataRequest = {
            name: "",
            permissionIds: []
        }
        this.errors = {
            name: "",
            permissionIds: ""
        }
    }

    async getPosition() {
        this.isLoading = true
        const result = await positionService.getPosition()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listPosition = result.body
        }
    }
    async getDetailPosition(id: any) {
        const result = await positionService.getPositionById(id)
        if (result.status === HttpStatusCode.OK) {
            positionStore.dataRequest = {
                name: result.body?.name,
                permissionIds: []
            }
        }
    }
    async deletePosition() {
        positionStore.isLoadingBt = true
        const result = await positionService.deletePosition(positionStore.id)
        positionStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa phòng ban thành công!')
            await  positionStore.getPosition()
        } else toastUtil.error(result.body.message)
    }
    async addEditPosition() {
        if(!this.dataRequest.name) {
            this.errors.name = "Vui lòng nhập tên chức vụ !"
            return
        }
        this.isLoadingBt = true
        const result = this.typeModal == 'add' ? await positionService.add(positionStore.dataRequest) : await positionService.editPosition(positionStore.id, positionStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`${positionStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} chức vụ thành công!`, 1)
            $('#add_edit_position').trigger('click')
            this.clearForm()
            await positionStore.getPosition()
        } else toastUtil.error(result.body.message, 2)
    }
}

export const positionStore = new PositionStore()