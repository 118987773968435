import React from 'react';
import {observer} from "mobx-react";
import './ModalSelectTypeImport.scss'
import {importExportStore} from "../../ImportExportStore";
import AddImpExpRequest from "../AddImpExpRequest";
import AddImportBuy from "../AddImportBuy";
const ModalSelectTypeImport = () => {
    return (
        <>
            <div className='modal fade' id='select_type_import' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_service__container'>
                            <h1 className='modal_select_service__title'>
                                Nhập kho
                            </h1>
                            <div className='modal_select_service__cardContainer'>
                                <button onClick={() => {
                                    importExportStore.typeImport = "WAREHOUSE"
                                    importExportStore.dataRequest.toMainWarehouse = true
                                    importExportStore.dataRequest.type = 'import'
                                }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#add_import_export_request' className='modal_select_service__card'>
                                    <div className='modal_select_service__cardTitle'>
                                        KHO TỔNG
                                    </div>
                                </button>
                                <button onClick={() => {
                                    importExportStore.typeImport = "BUY"
                                    importExportStore.dataRequest.toMainWarehouse = false
                                    importExportStore.dataRequest.type = 'import'
                                }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#add_import_buy_yourself' className='modal_select_service__card'>
                                    <div className='modal_select_service__cardTitle'>
                                        MUA NGOÀI
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddImpExpRequest text="Nhập"/>
            <AddImportBuy text="Nhập"/>
            </>
    )
}

export default observer(ModalSelectTypeImport);
