import React from 'react';
import {observer} from "mobx-react";
import InputForm from "../../../../modules/components/form/Input";
import {Input, Radio, Spin } from 'antd';
import { customerBenefitStore } from '../CustomerBenefitStore';
import { numberUtil } from '../../../../common/utils/NumberUtil';
import {accountStore} from "../../../setting/account/AccountStore";
const AddEditBenefit = () => {
    return (
        <div className='modal fade' id='add_edit_benefit' aria-hidden='true'>
            <div className='modal-dialog'>
                <div className='modal-content border-0'>
                    <div className='modal-header'>
                        <div className='modal_title'>
                            {customerBenefitStore.typeModal === 'add' ? 'THÊM' : 'CẬP NHẬT'} QUYỀN LỢI
                        </div>
                        <button type="button" id="close_customer_benefit" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body pe-0 ps-0">
                        <div className='form-group'>
                            <label className='form-label'>Tên phân loại <span className='text-danger'>*</span></label>
                            <InputForm value={customerBenefitStore.dataRequest.rankName}
                                       isError={customerBenefitStore.errors?.rankName}
                                       onChange={(e: any) => customerBenefitStore.dataRequest.rankName = e.target.value}
                                       placeholder='Nhập ...' />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Doanh thu khách <span className='text-danger'>*</span></label>
                          <div className="d-flex w-100">
                              <Input prefix="Từ" placeholder={'Nhập...'} value={customerBenefitStore.dataRequest.revenueFrom ? numberUtil.number_format(customerBenefitStore.dataRequest.revenueFrom) : ''}
                                     status={(customerBenefitStore.errors?.revenueFrom && !customerBenefitStore.dataRequest?.revenueFrom) ? "error" : ""}
                                     onChange={(e: any) => customerBenefitStore.dataRequest.revenueFrom = numberUtil.regexNumber(e)}
                                     suffix="đ"/>
                          </div>
                            {customerBenefitStore.errors?.revenueFrom  && !customerBenefitStore.dataRequest.revenueFrom && <small className="text-error">{customerBenefitStore.errors?.revenueFrom}</small>}

                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Loại voucher <span className='text-danger'>*</span></label>
                            <Radio.Group className="w-100 p-0" value={customerBenefitStore.dataRequest.discountType}
                                         onChange={(e: any) => {
                                             customerBenefitStore.dataRequest.discountValue = ''
                                             customerBenefitStore.dataRequest.discountType = e.target.value
                                         }}>
                                <Radio className="me-5" value="PERCENT">Giảm giá theo %</Radio>
                                <Radio value="VALUE">Giảm giá theo số tiền</Radio>
                            </Radio.Group>
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Mức ưu đãi <span className='text-danger'>*</span></label>
                            <Input
                                status={(customerBenefitStore.errors?.discountValue && !customerBenefitStore.dataRequest?.discountValue) ? "error" : ""}
                                value={customerBenefitStore.dataRequest.discountValue ? numberUtil.number_format(customerBenefitStore.dataRequest.discountValue) : ''}
                                   onChange={(e: any) => {
                                       if (e === '' || Number(numberUtil.regexNumber(e)) >= 0){
                                           if(customerBenefitStore.dataRequest.discountType === "VALUE") {
                                               customerBenefitStore.dataRequest.discountValue = numberUtil.regexNumber(e) ?? ""
                                           }
                                           else {
                                               customerBenefitStore.dataRequest.discountValue = numberUtil.regexPercentage(e) ?? ""
                                           }
                                       }
                                   }}
                                   placeholder='Nhập mức ưu đãi' suffix={customerBenefitStore.dataRequest.discountType === "VALUE" ? 'đ' : '%'}/>
                            {customerBenefitStore.errors?.discountValue  && !customerBenefitStore.dataRequest.discountValue && <small className="text-error">{customerBenefitStore.errors?.discountValue}</small>}
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Dịch vụ phục vụ</label>
                            <InputForm value={customerBenefitStore.dataRequest.note}
                                       onChange={(e: any) => customerBenefitStore.dataRequest.note = e.target.value}
                                       placeholder='Nhập dịch vụ' />
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        <button className='btn send_request' onClick={async () => await customerBenefitStore.addEdit()}>
                            {customerBenefitStore.isLoadingBt ? <Spin size="small" /> : "Xác nhận" }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default observer(AddEditBenefit);