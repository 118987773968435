import React from 'react';
import './StockInformationPage.scss'
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";

const StockInformationPage = () => {
    return (
        <>
            <div className='stock_container'>
                <div className='stock_container__header'>
                    <TabRedirect tab='Phiếu thông tin' />
                </div>
                <div className='stock_container__content'>
                    <div className='stock_wrap'>
                        <span className='stock_wrap__title'>1. Thông tin khách hàng</span>
                        <div className='d-flex flex-column align-items-start g-1'>
                            <div className='stock_wrap__subtitle'>1.1. Họ & Tên</div>
                            <div className='stock_wrap__subtitle'>1.2. Số điện thoại</div>
                            <div className='stock_wrap__subtitle'>1.3. Giới tính</div>
                            <div className='stock_wrap__subtitle'>1.4. Ngày sinh</div>
                            <div className='stock_wrap__subtitle'>1.5. Địa chỉ</div>
                        </div>
                    </div>
                    <div className='stock_wrap'>
                        <span className='stock_wrap__title'>2. Không gian & thái độ phục vụ</span>
                        <div className='d-flex flex-column align-items-start g-1'>
                            <div className='stock_wrap__subtitle'>2.1. Không gian: <span className='stock_wrap__key'>Rất hài lòng  |  Bình thường  |  Không hài lòng</span></div>
                            <div className='stock_wrap__subtitle'>2.2. Vệ sinh: <span className='stock_wrap__key'>Rất hài lòng  |  Bình thường  |  Không hài lòng</span></div>
                            <div className='stock_wrap__subtitle'>2.3. Thái độ phục vụ: <span className='stock_wrap__key'>Rất hài lòng  |  Bình thường  |  Không hài lòng</span></div>
                            <div className='stock_wrap__subtitle'>2.3. Thời gian phục vụ: <span className='stock_wrap__key'>Rất hài lòng  |  Bình thường  |  Không hài lòng</span></div>
                        </div>
                    </div>
                    <div className='stock_wrap'>
                        <span className='stock_wrap__title'>3. Món ăn, đồ uống</span>
                        <div className='d-flex flex-column align-items-start g-1'>
                            <div className='stock_wrap__subtitle'>3.1. Đa dạng menu: <span className='stock_wrap__key'>Có |  Không</span></div>
                            <div className='stock_wrap__subtitle'>3.2. Giá cả: <span className='stock_wrap__key'>Cao | Thấp | Phù hợp</span></div>
                            <div className='stock_wrap__subtitle'>3.3. Khẩu vị:  <span className='stock_wrap__key'>Cay | Chua | Mặn | Ngọt | Phù hợp</span></div>
                        </div>
                    </div>
                    <div className='stock_wrap'>
                        <span className='stock_wrap__title'>4. Thông tin khác</span>
                        <div className='d-flex flex-column align-items-start g-1'>
                            <div className='stock_wrap__subtitle'>4.1. Biết cửa hàng qua:  <span className='stock_wrap__key'>Facebook |  Người quen | Khác</span></div>
                            <div className='stock_wrap__subtitle'>4.2. Giới thiệu với bạn bè: <span className='stock_wrap__key'>Có | Không</span></div>
                            <div className='stock_wrap__subtitle'>4.3. Quay lại:  <span className='stock_wrap__key'>Có | Không</span></div>
                        </div>
                    </div>
                    <div className='stock_wrap'>
                        <span className='stock_wrap__title'>5. Ý kiến đóng góp</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default observer(StockInformationPage);