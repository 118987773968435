import {makeAutoObservable} from "mobx";

class SidebarStore {
    showSidebar = true

    constructor() {
        makeAutoObservable(this)
    }


}

export const sidebarStore = new SidebarStore()