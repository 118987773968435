import React, {useEffect} from 'react';
import "./ChangePointPage.scss"
import {observer} from "mobx-react";
import {Input, Spin} from "antd";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {settingChangePointStore} from "./ChangePointStore";

const SettingChangePointPage = () => {
    useEffect(() => {
        settingChangePointStore.getDetailChangePoint().then()
        settingChangePointStore.getDetailSettingRefundOrder().then()
    }, [])
    return (
        <div className="setting_information__container">
            <div className="register_container">
                <div className="mb-4 setting_infor__title">
                    THIẾT LẬP ĐỔI ĐIỂM
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <div className='w-50 form-group pe-5'>
                        <label className='form-label'>Số điểm<span className='text-danger'>*</span></label>
                        <Input type='text' value={ settingChangePointStore.dataRequest?.point ? numberUtil.number_format( settingChangePointStore.dataRequest?.point) : ""}
                               onKeyDown={(evt: {
                                   key: string
                                   preventDefault: () => any
                               }) =>
                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                   evt.preventDefault()
                               }
                               onChange={(e: any) => {
                                   if (
                                       e === '' ||
                                       Number(numberUtil.regexNumber(e)) >= 0
                                   ) {
                                       settingChangePointStore.dataRequest.point = numberUtil.regexNumber(e)
                                   }
                               }}
                               status={(settingChangePointStore.errors?.point && ! settingChangePointStore.dataRequest?.point) ? "error" : ""}
                               placeholder='Nhập số ...' />
                        {settingChangePointStore.errors?.point && !settingChangePointStore.dataRequest?.point && <small className="mb-2 text-error">{settingChangePointStore.errors?.point}</small>}
                    </div>
                    <div className='pt-3'>
                        <img src="/assets/ico/action/li_repeat.svg" alt=""/>
                    </div>
                    <div className='w-50 form-group ps-5'>
                        <label className='form-label'>Số tiền (VNĐ)<span className='text-danger'>*</span></label>
                        <Input type='text' value={settingChangePointStore.dataRequest?.amount ? numberUtil.number_format(settingChangePointStore.dataRequest?.amount) : ""}
                               onKeyDown={(evt: {
                                   key: string
                                   preventDefault: () => any
                               }) =>
                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                   evt.preventDefault()
                               }
                               onChange={(e: any) => {
                                   if (
                                       e === '' ||
                                       Number(numberUtil.regexNumber(e)) >= 0
                                   ) {
                                       settingChangePointStore.dataRequest.amount = numberUtil.regexNumber(e)
                                   }
                               }}
                               status={(settingChangePointStore.errors?.amount && !settingChangePointStore.dataRequest?.amount) ? "error" : ""}

                               placeholder='Nhập số ...'
                        />
                        {settingChangePointStore.errors?.amount && !settingChangePointStore.dataRequest?.amount && <small className="mb-2 text-error">{settingChangePointStore.errors?.amount}</small>}
                    </div>
                </div>
                <div className='w-100 mt-4 d-flex justify-content-end'>
                    <button onClick={async () => {
                        await settingChangePointStore.updateChangePoint()
                    }} className="button_save_change__point">
                        {settingChangePointStore.isLoadingBtPoint ? <Spin className="my-1" size="small" /> : "Lưu"}
                    </button>
                </div>
                <div className="mb-4 setting_infor__title">
                    THIẾT LẬP HOÀN TIỀN ĐƠN HÀNG
                </div>
                <div className='d-flex justify-content-end align-items-center mb-3'>
                    <button className="button_add_form_table"
                            onClick={() => {
                                settingChangePointStore.listCondition.push({
                                    from: "",
                                    refund: ""
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive mb-3 d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Giá trị đơn hàng (VNĐ)</th>
                            <th className="text-center">Hoàn tiền (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settingChangePointStore?.listCondition?.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td className="text-center">
                                        <div className='d-flex align-items-center form-group'>
                                            <span className='me-3 text_range_change__point'>Từ</span>
                                            <Input
                                                type="text"
                                                disabled={i === 0}
                                                onKeyDown={(evt: {
                                                    key: string
                                                    preventDefault: () => any
                                                }) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        e === '' ||
                                                        Number(numberUtil.regexNumber(e)) >= 0
                                                    ) {
                                                        item.from = numberUtil.regexNumber(e)
                                                    }
                                                }}
                                                value={item.from === 0 ? 0 :  item.from === "" ? "" : numberUtil.number_format(item.from)}
                                                placeholder='Nhập...'
                                                />
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className=' form-group'>
                                            <Input
                                                type="text"
                                                onKeyDown={(evt: {
                                                    key: string
                                                    preventDefault: () => any
                                                }) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={(e: any) => {
                                                    if (
                                                        e === '' ||
                                                        Number(numberUtil.regexPercentage(e)) >= 0
                                                    ) {
                                                        item.refund = numberUtil.regexPercentage(e)
                                                    }
                                                }}
                                                status={(settingChangePointStore.isErrorRefundOrder && !item.refund) ? "error" : ""}
                                                value={item.refund ? numberUtil.number_format(item.refund) : ""}
                                                placeholder='Nhập...'
                                               />
                                            {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                data-toggle="modal" data-target="#"
                                                                onClick={() => {
                                                                    settingChangePointStore.listCondition.splice(i, 1)
                                                                }}>
                                                <img src="/assets/ico/action/minus-circle.svg"
                                                     className="icon-action"
                                                     alt="" data-toggle="tooltip" data-placement="top"
                                                     title="Xóa"/>
                                            </button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='w-100 mt-4 d-flex justify-content-end'>
                    <button onClick={async () => {
                        await settingChangePointStore.updateChangeRefundOrder()
                    }} className="button_save_change__point">
                        {settingChangePointStore.isLoadingBt ? <Spin className="my-1" size="small" /> : "Lưu"}
                    </button>
                </div>
            </div>
        </div>
    )}

export default observer(SettingChangePointPage);