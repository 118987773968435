import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class PurchaseService{
    public getBuyRequest(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/buy_request`)
    }
    public getDetailBuyRequest(id: number): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/warehouse/buy_request/${id}`)
    }
    public sendBuyRequest(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/warehouse/buy_request`, data)
    }
    public getListUser(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user?page=0&size=${size ?? 500}`)
    }
    public getListSummaryPurchase(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/warehouse/request/collect`)
    }
    public changeStatusRequest(data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/warehouse/buy_request/change_status`, data)
    }
}

export const purchaseService = new PurchaseService()