import React, {useEffect} from 'react';
import './DataPage.scss'
import {observer} from "mobx-react";
import {numberUtil} from "../../../common/utils/NumberUtil";
import DetailOrderModal from "../dashboard/components/detailOrder/detailOrder";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {dataStore} from "./DataStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import NoContent from "../../../modules/components/NoContent";
import {Tooltip} from "antd";
import Loading from "../../../modules/components/Loading";

const convertTypeOrder = (value: string) => {
    switch (value.toUpperCase()) {
        case "ONLINE":
            return <span className="px-3 py-1" style={{background: '#FFF6D3', borderRadius: '3px'}}>Online</span>
        case "OFFLINE":
            return <span className="px-3 py-1" style={{background: '#EEFFFE', borderRadius: '3px'}}>Tại chỗ</span>
        default:
            return null
    }
}
const convertStatusOrder = (value: string) => {
    switch (value.toUpperCase()) {
        case "SUCCESS":
            return <span className="color-green" style={{fontWeight: '500', width: '120px'}}>Thành công</span>
        case "IN_PROGRESS":
            return <span className="color-purple fw-medium" style={{fontWeight: '500', width: '120px'}}>Đang phục vụ</span>
        case "CANCEL":
            return <span className="color-red fw-medium" style={{fontWeight: '500', width: '120px'}}>Hủy</span>
        case "PENDING":
            return <span className="color-pending fw-medium" style={{fontWeight: '500', width: '120px'}}>Chưa phục vụ</span>
        default:
            return null
    }
}

const DataPage = () => {

    const dataFilter: IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value: dataStore.state,
            placeholder: 'Tất cả',
            listValue: [
                {
                    value: "SUCCESS",
                    label: "Hoàn thành"
                },
                {
                    value: "IN_PROGRESS",
                    label: "Đang phục vụ"
                },
                {
                    value: "PENDING",
                    label: "Chưa phục vụ"
                },
                {
                    value: "CANCEL",
                    label: "Hủy"
                }
            ],
            callback: async (e: any) => {
                dataStore.state = e;
                dataStore.keyword = ''
                await dataStore.getData()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm theo mã đơn , số điện thoại',
            value: dataStore.keyword,
            onChange: (e: any) => {
                dataStore.keyword = e
                dataStore.state = null
            },
            callback: async () => dataStore.getData()
        },
    ]

    useEffect(() => {
        dataStore.getData().then()
    }, [])

    return (
        <>
            <div className='dashboard_container'>
                <div className='tab_filter_header'>
                    <h4 className='title'>Dữ liệu</h4>
                    <FilterComponent data={dataFilter}/>
                </div>
                {dataStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-center">STT</th>
                            <th scope="col" className="text-center">Thời gian</th>
                            <th scope="col" className="text-center" style={{width: '150px'}}>Khách hàng</th>
                            <th scope="col" className="text-center">Số điện thoại</th>
                            <th scope="col" className="text-center" style={{width: '120px'}}>Tình trạng<br/>đơn hàng</th>
                            <th scope="col" className="text-center">Phòng</th>
                            <th scope="col" className="text-center">Bàn</th>
                            <th scope="col" className="text-center" style={{width: '120px'}}>Loại phục vụ</th>
                            <th scope="col" className="text-center">Mã đặt hàng</th>
                            <th scope="col" className="text-center">Thời gian đặt hàng</th>
                            <th scope="col" className="text-center">Tổng tiền (VNĐ)</th>
                            <th scope="col" className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataStore.listData && dataStore.listData.length > 0 ? dataStore.listData.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i+1}</td>
                                    <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                    <td className="text-center">{item?.user?.name}</td>
                                    <td className="text-center">{item?.user?.phone}</td>
                                    <td className="text-center">{convertStatusOrder(item.status)}</td>
                                    <td className="text-center">{item?.room?.name}</td>
                                    <td className="text-center">{item?.table?.name}</td>
                                    <td className="text-center">{convertTypeOrder(item.type)}</td>
                                    <td className="text-start">{item.code}</td>
                                    <td className="text-center">{dateUtils.formatDate(item.checkinTime, 'HH:MM DD/MM/YYYY')}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item.totalPayment)}</td>
                                    <td className="text-center">
                                            <button
                                                type='button'
                                                className="view_detail__btn"
                                                onClick={() => dataStore.getDetail(item.id)}
                                                data-bs-toggle="modal"
                                                data-bs-target='#detail_order'>
                                                    <Tooltip title='Xem chi tiết'>
                                                        Chi tiết
                                                    </Tooltip>
                                            </button>
                                    </td>
                                </tr>
                            )
                        }) : <NoContent colSpan={12}/>}
                        </tbody>
                    </table>
                </div>}
            </div>

            <DetailOrderModal/>
        </>
    );
}

export default observer(DataPage);