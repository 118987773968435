import React from 'react';
import {observer} from "mobx-react";
import './SelectRestaurant.scss'
import {checkOrderStore} from "../../CheckOrderStore";
import {Link} from "react-router-dom";
import $ from "jquery";
const ModalSelectRestaurant = () => {
    const urlObject = new URL(window.location.href);
    const baseURL = `${urlObject.protocol}//${urlObject.host}`;
    return (
        <>
            <div className='modal fade' id='select_restaurant_order' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_service__container'>
                            <h1 className='modal_select_service__title'>
                                Chọn điểm bán
                            </h1>
                            { checkOrderStore.listRestaurant.length > 0 ? <div className='modal_select_service__cardContainer'>
                                {
                                    checkOrderStore.listRestaurant.map((item: any, index: number) => {
                                        return(
                                            <button
                                                key={index}
                                                onClick={async () => {
                                                    checkOrderStore.dataRequest.establishmentId = item?.value
                                                    checkOrderStore.dataRequest.establishmentName = item?.label
                                                    localStorage.setItem('dataOrder' , JSON.stringify(checkOrderStore.dataRequest))
                                                    await checkOrderStore.getListOrder().then()
                                                }}
                                                data-bs-dismiss="modal"  className='modal_select_service__card'>
                                                <div className='modal_select_service__cardTitle'>
                                                    {item?.label}
                                                </div>
                                            </button>
                                        )
                                    })
                                }
                            </div> : <div className='no_esteblistment w-100 custom-scrollbar'>
                                <img src="/assets/ico/no_supplies.svg" alt=""/>
                                <span className='no_esteblistment__title'>Hiện tại bạn chưa có điểm bán!</span>
                                <Link onClick={() => {
                                    $('#select_restaurant_order').trigger('click')
                                }
                                } className='link_create__esteblistment' to={`${baseURL}/thiet-lap/danh-sach-diem-ban`}>Tạo điểm bán mới</Link>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
)
}

export default observer(ModalSelectRestaurant);

