import React from 'react';
import {observer} from "mobx-react";
import './ModalSelectTypeVoucher.scss'
import ModalValueVoucher from "../modalValueVoucher/ModalValueVoucher";
import ModalProductVoucher from "../modalProductVoucher/ModalProductVoucher";
import ModalComboVoucher from "../modalComboVoucher/ModalComboVoucher";
import ModalDiscountVoucher from "../modalDiscountVoucher/ModalDiscountVoucher";
import {voucherStore} from "../../VoucherStore";
import ModalBirthdayVoucher from "../modalBirthdayVoucher/ModalBirthdayVoucher";

const ModalSelectVoucher = () => {
    return (
        <>
        <div className='modal fade' id='add_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                   <div className='modal_select_voucher__container'>
                       <h1 className='modal_select_voucher__title'>
                           Chọn Voucher bạn muốn tạo
                       </h1>
                       <div className='modal_select_voucher__cardContainer'>
                           <button onClick={() => voucherStore.resetFormValue()} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#value_voucher' className='modal_select_voucher__card'>
                               <div className='modal_select_voucher__cardTitle'>
                                   VOUCHER GIÁ TRỊ
                               </div>
                           </button>
                           <button onClick={() => voucherStore.resetFormProduct()} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#product_voucher' className='modal_select_voucher__card'>
                               <div className='modal_select_voucher__cardTitle'>
                                   VOUCHER TẶNG SẢN PHẨM
                               </div>
                           </button>
                           <button  onClick={() => voucherStore.resetFormCombo()} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#combo_voucher' className='modal_select_voucher__card'>
                               <div className='modal_select_voucher__cardTitle'>
                                   COMBO
                               </div>
                           </button>
                           <button  onClick={() => voucherStore.resetFormProducts()}  data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#discount_voucher' className='modal_select_voucher__card'>
                               <div className='modal_select_voucher__cardTitle'>
                                   MUA NHIỀU GIẢM GIÁ
                               </div>
                           </button>
                           <button  onClick={() => voucherStore.resetFormBirthday()}  data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target='#birthday_voucher' className='modal_select_voucher__card'>
                               <div className='modal_select_voucher__cardTitle'>
                                   VOUCHER SINH NHẬT
                               </div>
                           </button>
                       </div>
                   </div>
                </div>
            </div>
        </div>
            <ModalValueVoucher />
            <ModalProductVoucher />
            <ModalComboVoucher />
            <ModalDiscountVoucher />
            <ModalBirthdayVoucher />
        </>

    )

}

export default observer(ModalSelectVoucher);
