import React, {useEffect, useState} from 'react';
import "./SignUpStyle.scss"
import {signUpStore} from "./SignUpStore";
import {observer} from "mobx-react";
import StorageService from "../../../common/service/StorageService";
import {Navigate, useSearchParams} from "react-router-dom";


const SignUpPage = () => {
    const [pathName, setPathName] = useState('')
    let searchParams: URLSearchParams;
    [searchParams] = useSearchParams();
    useEffect(() => {
        setPathName(window.location.pathname)
    }, [])
    if (StorageService.getToken()) {
            return <Navigate to={'/'} replace/>
        }
    else
        return (
                <div className="signUp_main">
                    <div className="row flex-grow">
                        <div style={{width: '45%'}} className="background-signup__container">
                            <img src="/assets/ico/sign_up_banner.svg" className="w-100 vh-100"
                                 style={{objectFit: 'cover'}} alt=""/>
                        </div>
                        <div style={{width: '55%'}}>
                            <div className='content-signup'>
                                <div className='content-signup__container'>
                                    <h1>Chào mừng bạn đến với</h1>
                                    <h1>Không gian Quản trị số</h1>
                                    <p className='py-3 content-signup__subtitle'>Chuyển đổi số cho cuộc sống đẹp tươi trong tương lai!</p>
                                    <div className='content-signup__btn'>
                                        <a className="btn-redirect"
                                           href={`${process.env.REACT_APP_DOMAIN_SSO}/login?redirectTo=${searchParams.get("redirectTo") ?? window.location.href}&service=fnb`}
                                                onClick={async () => {signUpStore.isLogin = true}}>
                                            ISS 365 FnB
                                            <img src="/assets/ico/move-right.svg" alt=""/>
                                        </a>
                                        {/*<a type="button" className="btn-customer"*/}
                                        {/*        onClick={() => {alert('2')}}>*/}
                                        {/*    <span>Khách hàng</span>*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

export default observer(SignUpPage);