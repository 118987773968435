import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {kpiService} from "./KpiService";
import {restaurantStore} from "../../setting/restaurants/RestaurantStore";


class KpiStore {
    id: any = ''
    typeModal: string = ''
    searchYear: any = new Date().getFullYear().toString()
    isLoading: boolean = false
    isLoadingBt: boolean = false
    dataDefault: any = {
        revenue: {
            target: '',
            result: ''
        },
        foodRevenue: {
            target: '',
            result: ''
        },
        beverageRevenue:{
            target: '',
            result: ''
        },
        averageMonthlyRevenue:{
            result: '',
            target: ''
        },
        customer: {
            conversionRate: {
                target: '',
                result: ''
            },
            orderRate: {
                target: '',
                result: ''
            },
            returning: {
                target: '',
                result: ''
            }
        },
        social: {
            approachWebsite: {
                target: '',
                result: ''
            },
            interactFacebook: {
                target: '',
                result: ''
            },
            subscribeYoutube: {
                target: '',
                result: ''
            },
            viewYoutube: {
                target: '',
                result: ''
            }
        },
        marketing: {
            numberEstablishment: {
                target: '',
                result: ''
            },
            promotion: {
                target: '',
                result: ''
            },
            event: {
                target: '',
                result: ''
            }
        },
        partnerRelationship: {
            partner: {
                target: '',
                result: ''
            },
            customer: {
                target: '',
                result: ''
            },
            cover: {
                target: '',
                result: ''
            }
        },
        human: {
            growUp: {
                target: '',
                result: ''
            },
            fixedCost: {
                target: '',
                result: ''
            }
        },
        improved: {
            capacity: {
                target: '',
                result: ''
            },
            applyTechnology: {
                target: '',
                result: ''
            },
            advancement: {
                target: '',
                result: ''
            }
        },
        customerSatisfaction: {
            advancement: {
                target: '',
                result: ''
            },
            satisfactionSalary: {
                target: '',
                result: ''
            },
            workplaceSatisfaction: {
                target: '',
                result: ''
            },
            leadershipVision: {
                target: '',
                result: ''
            },
            leaderWorkStyle: {
                target: '',
                result: ''
            }
        }
    }
    dataKpi: any = {
        revenue: {
            target: '',
            result: ''
        },
        averageMonthlyRevenue:{
            result: '',
            target: ''
        },
        foodRevenue: {
            target: '',
            result: ''
        },
        beverageRevenue:{
            target: '',
            result: ''
        },
    }
    listKpi: any[] = []
    listRestaurant: any = []
    listMarRestaurant: any = []

    constructor() {
        makeAutoObservable(this)
    }


    async getKpi() {
        this.isLoading = true
        const result = await kpiService.getKPI()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = []
            this.listMarRestaurant = restaurantStore.listRestaurant?.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    target: '',
                    result: '',
                };
            }) || [];
            this.listRestaurant = restaurantStore.listRestaurant?.map((item) => {
                const revenueItem = result.body.revenueEstablishment?.find((val: any) => val.id === item.id);
                return {
                    id: item.id,
                    name: item.name,
                    target: revenueItem?.revenue?.target || '',
                    result: revenueItem?.revenue?.result || '',
                };
            }) || [];

            let data = result.body ?? this.dataDefault
            if(!data['human']) data['human'] = this.dataDefault.human
            if(!data['customer']) data['customer'] = this.dataDefault.customer
            if(!data['marketing']) data['marketing'] = this.dataDefault.marketing
            if(!data['social']) data['social'] = this.dataDefault.social
            if(!data['improved']) data['improved'] = this.dataDefault.improved
            if(!data['partnerRelationship']) data['partnerRelationship'] = this.dataDefault.partnerRelationship
            if(!data['customerSatisfaction']) data['customerSatisfaction'] = this.dataDefault.customerSatisfaction
            this.dataDefault = data
            this.dataKpi = data
            this.listKpi = []

            for (const key in data) {
                if(key === "human" || key === "partnerRelationship" || key === "customer" || key === "customerSatisfaction" || key === "marketing" || key === "social" || key === "improved"){
                    if (Object.hasOwnProperty.call(data, key)) {
                        this.listKpi.push({ type: key });
                        for (const subKey in data[key]) {
                            if (Object.hasOwnProperty.call(data[key], subKey)) {
                                this.listKpi.push({
                                    key:`${key}_${subKey}`,
                                    subKey: subKey,
                                    target: data[key][subKey].target,
                                    result: data[key][subKey].result
                                });

                            }
                        }
                    }
                }
            }

        }
    }

    async editKpi() {
        const originalData: any = {};
        let currentType = "";
        let revenueEstablishment: any[] = []
        for (const item of this.listKpi) {
            if (item.type) {
                currentType = item.type;
                originalData[item.type] = {};
            } else {
                originalData[currentType][item.subKey] = {
                    target: item.target,
                    result: item.result
                }
            }
        }

        let {revenue, averageMonthlyRevenue, foodRevenue, beverageRevenue} = this.dataKpi;

        this.listRestaurant && this.listRestaurant.forEach((item: any) => {
            revenueEstablishment.push({
                id: item.id,
                revenue: {
                    target: item.target,
                    result: item.result
                }
            })
        })

        let data = {
            year: this.searchYear,
            revenue: {
                target: revenue.target,
                result: revenue.result
            },
            averageMonthlyRevenue: {
                target: averageMonthlyRevenue.target,
                result: averageMonthlyRevenue.result
            },
            foodRevenue: {
                target: foodRevenue.target,
                result: foodRevenue.result
            },
            beverageRevenue: {
                target: beverageRevenue.target,
                result: beverageRevenue.result
            },
            revenueEstablishment: revenueEstablishment ,
            customer: originalData.customer,
            social: originalData.social,
            marketing: originalData.marketing,
            partnerRelationship: originalData.partnerRelationship,
            human: originalData.human,
            improved: originalData.improved,
            customerSatisfaction: originalData.customerSatisfaction,
        }


        this.isLoadingBt = true
        const result = await kpiService.editKpi(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Cập nhật chỉ số KPI thành công!`)
            this.typeModal = ''
            await kpiStore.getKpi()
        } else toastUtil.error(result.body.message)
    }


    returnKey(key: string){
        switch (key) {
            case "revenue":
                return "Doanh thu tổng"
            case "averageMonthlyRevenue":
                return "Doanh thu trung bình tháng"
            case "foodRevenue":
                return "Doanh thu đồ ăn"
            case "beverageRevenue":
                return "Doanh thu đồ uống"
            case "customer":
                return "Khách hàng (*)"
            case "customer_conversionRate":
                return "Tỉ lệ chuyển đổi khách hàng/ dữ liệu"
            case "customer_orderRate":
                return "Tỉ lệ khách hàng hủy bàn"
            case "customer_returning":
                return "Tỉ lệ khách hàng quay lại"
            case "human":
                return "Con người"
            case "human_growUp":
                return "Khả năng phát triển"
            case "human_fixedCost":
                return "Chi phí cố định (Lương + nhà ở + máy móc)"
            case "partnerRelationship":
                return "Quan hệ đối tác"
            case "partnerRelationship_partner":
                return "Đối tác"
            case "partnerRelationship_customer":
                return "Khách hàng"
            case "partnerRelationship_cover":
                return "Độ phủ"
            case "marketing":
                return "Trade Marketing"
            case "marketing_numberEstablishment":
                return "Số điểm bán"
            case "marketing_promotion":
                return "Chương trình khuyến mãi"
            case "marketing_event":
                return "Sự kiện"
            case "social":
                return "Mạng xã hội"
            case "social_approachWebsite":
                return "Website - Tiếp cận"
            case "social_interactFacebook":
                return "Facebook - Tương tác"
            case "social_subscribeYoutube":
                return "Youtube - Đăng ký"
            case "social_viewYoutube":
                return "Tiktok - Lượt xem"
            case "customerSatisfaction":
                return "Sự hài lòng của nhân viên"
            case "customerSatisfaction_advancement":
                return "Chỉ số cơ hội thăng tiến"
            case "customerSatisfaction_satisfactionSalary":
                return "Hài lòng lương"
            case "customerSatisfaction_workplaceSatisfaction":
                return "Hài lòng môi trường làm việc"
            case "customerSatisfaction_leadershipVision":
                return "Tầm nhìn lãnh đạo"
            case "customerSatisfaction_leaderWorkStyle":
                return "Phong cách quản lý lãnh đạo"
            case "improved":
                return "Học hỏi và phát triển"
            case "improved_capacity":
                return "Tỉ lệ tăng cường năng lực (Tỉ lệ người cần tham gia đào tạo)"
            case "improved_applyTechnology":
                return "Ứng dụng khoa học công nghệ (Mức độ sẵn sàng dùng SP số)"
            case "improved_advancement":
                return "Tỉ lệ phát triển sự nghiệp (Số lượng nhân viên thăng chức, tham gia dự án mới)"
            default :
                return key
        }
    }
}

export const kpiStore = new KpiStore()