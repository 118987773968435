import React from 'react';
import {Input} from "antd";

interface IProps{
    value: any,
    onChange: any
    isError?: any,
    type?: any,
    disabled?: any,
    placeholder?: string
}



const InputForm = (item:IProps) => {


    return (
        <>
            <div className="input-group">
                <Input type={item.type ?? 'text'} value={item.value || ""}
                       onChange={(e: any) => item.onChange(e)} disabled={item.disabled}
                       className={`${(item.isError && !item.value) ? 'border_error' : ''}`} placeholder={item.placeholder ?? ''}/>
            </div>
            {(item.isError && !item.value) && <p className="error">{item.isError}</p>}
        </>
    );
}

export default InputForm;