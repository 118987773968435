import React from 'react';
import {observer} from "mobx-react";
import './ModalDiscountVoucher.scss'
// @ts-ignore
import { DatePicker, Radio,  Select, Input,  Space } from 'antd';
import {voucherStore} from "../../VoucherStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {Moment} from "moment";
import ModalAddProductVoucher from "./modalAddProductVoucher/modalAddProductVoucher";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
dayjs.extend(customParseFormat);
function convertTime(date?: Moment, isEndDate?: boolean): number {
    if (!date) return 0;
    if (isEndDate) {
        return date.set({hour: 23, minute: 59, second: 59}).unix() * 1000;
    }
    return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
}
const handleRangePickerChange = (dates: any) => {
    if (dates && dates.length > 0) {
        voucherStore.dataRequestValueVoucher.startTime = convertTime(dates[0]);
        voucherStore.dataRequestValueVoucher.endTime = convertTime(dates[1]);
    }
}

const ModalDiscountVoucher = () => {
    const {RangePicker} = DatePicker;

    return (
        <div className='modal fade' id='discount_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>MUA NHIỀU GIẢM GIÁ</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='d-flex w-100'>
                            <div className='w-50 d-flex flex-column pe-2'>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Tên chương trình giảm giá<span className='text-danger'>*</span></label>
                                    <Input type='text' value={voucherStore.dataRequestValueVoucher?.name}
                                           status={(voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher?.name) ? "error" : ""}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.name = e.currentTarget.value
                                           }}
                                           placeholder='Nhập tên ...' />
                                    {voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher.name && <small className="text-error">{voucherStore.errors?.name}</small>}
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Mã Voucher<span className='text-danger'>*</span></label>
                                    <Input type='text'  value={voucherStore.dataRequestValueVoucher?.code}
                                           status={(voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher?.code) ? "error" : ""}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.code = e.currentTarget.value
                                           }}
                                           placeholder='HUAS | Nhập vào ...' />
                                    {voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher.code && <small className="text-error">{voucherStore.errors?.code}</small>}

                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Thời gian áp dụng<span className='text-danger'>*</span></label>
                                    <RangePicker
                                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                        onChange={handleRangePickerChange}
                                        format={"DD/MM/YYYY"}
                                        status={(voucherStore.errors?.time && !voucherStore.dataRequestValueVoucher?.startTime &&  !voucherStore.dataRequestValueVoucher?.endTime) ? "error" : ""}
                                        value={[
                                            voucherStore.dataRequestValueVoucher.startTime === "" ? null : dayjs(voucherStore.dataRequestValueVoucher.startTime),
                                            voucherStore.dataRequestValueVoucher.endTime === "" ? null : dayjs( voucherStore.dataRequestValueVoucher.endTime)
                                        ] as any}
                                    />
                                    {voucherStore.errors?.time  && (!voucherStore.dataRequestValueVoucher.startTime ||  !voucherStore.dataRequestValueVoucher.endTime) && <small className="text-error">{voucherStore.errors?.time}</small>}
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Địa điểm áp dụng<span className='text-danger'>*</span></label>
                                    <Select
                                        disabled
                                        onChange={(e: any) => {
                                            alert(e.currentTarget.value)}}
                                        placeholder="Chọn" options={[{value: '11', label: '1'}, {value: '22', label: '2'}]}
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Số voucher phát hành<span className='text-danger'>*</span></label>
                                    <Input type='number'  value={voucherStore.dataRequestValueVoucher?.amount}
                                           status={(voucherStore.errors?.amount && !voucherStore.dataRequestValueVoucher?.amount) ? "error" : ""}
                                           onChange={(e: any) => {
                                               voucherStore.dataRequestValueVoucher.amount = e.currentTarget.value
                                           }}
                                           placeholder='Nhập số ...' />
                                    {voucherStore.errors?.amount  && !voucherStore.dataRequestValueVoucher?.amount && <small className="text-error">{voucherStore.errors?.amount}</small>}
                                </div>
                                <div className='w-100'>
                                    <label className='form-label'>Thiết lập mã giảm giá</label>
                                    <div className='mt-2'>
                                        <label className='form-label me-4'>Loại Voucher<span className='text-danger'>*</span></label>
                                        <Radio.Group  name="radiogroup" onChange={(e) => {
                                            voucherStore.dataRequestValueVoucher.discountType = e.target.value
                                        }} value={voucherStore.dataRequestValueVoucher?.discountType}>
                                            <Radio value="DISCOUNT">Khuyến Mãi</Radio>
                                            <Radio value="REFUND">Hoàn Điểm</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                            <div className='w-50 d-flex flex-column ps-2'>
                                <div className='w-100'>
                                    <div className='w-100 mb-3 d-flex justify-content-between align-items-center'>
                                        <label className='form-label'>Sản phẩm áp dụng</label>
                                        <button className='button_add_product__voucher'  data-bs-toggle="modal" data-bs-target="#add_product_voucher__discount">
                                            <img src="/assets/ico/action/plus_green.svg" alt="button_add_product"/>
                                            Thêm sản phẩm
                                        </button>
                                    </div>
                                    <div className='list_product__voucher'>
                                        {voucherStore.dataRequestValueVoucher?.proviso?.products?.length <= 0 ? <div className='list_product__placeholder'>
                                                Tất cả sản phẩm
                                            </div> :
                                            <>{
                                                voucherStore.dataRequestValueVoucher.proviso.products?.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index} className='product_item__voucher'>
                                                            {item?.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='w-100 form-group'>
                                    <div className='mt-2'>
                                        <label className='form-label me-4'>Loại giảm giá<span className='text-danger'>*</span></label>
                                        <Radio.Group  name="radiogroup" onChange={(e) => {
                                            voucherStore.dataRequestValueVoucher.proviso.type = e.target.value
                                            voucherStore.dataRequestValueVoucher.proviso?.buyMore?.forEach((item: any) => {
                                                    item.discount = ""
                                            })
                                        }} value={voucherStore.dataRequestValueVoucher.proviso.type}>
                                            <Radio value="SPECIFIC_AMOUNT">Theo số tiền</Radio>
                                            <Radio value="PERCENTAGE">Theo %</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div className='w-100 form-group'>
                                    <div className='d-flex justify-content-between'>
                                        <label className='form-label w-40'>Số lượng <span className='text-danger'>*</span></label>
                                        <div className='w-60 d-flex justify-content-between'>
                                            <label className='form-label'>Mức giảm <span className='text-danger'>*</span></label>
                                            <button
                                                onClick={() => {
                                                    voucherStore.dataRequestValueVoucher.proviso?.buyMore?.push({
                                                        from: "",
                                                        to: "",
                                                        discount: "",
                                                    })
                                                }
                                                }
                                                className='button_add_product__voucher'>
                                                <img src="/assets/ico/action/plus_green.svg" alt="button_add_product"/>
                                                Thêm
                                            </button>
                                        </div>
                                    </div>
                                    <div className='level_discount__container'>
                                        {
                                            voucherStore.dataRequestValueVoucher.proviso?.buyMore?.map((item: any , index: number) => {
                                                return(
                                                    <div className='level_discount__item'>
                                                        <div className='d-flex align-items-center justify-content-between level_discount__item--range w-40'>
                                                            <input
                                                                className='level_discount__item--input'
                                                                type="text"
                                                                onKeyDown={(evt: {
                                                                    key: string
                                                                    preventDefault: () => any
                                                                }) =>
                                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (
                                                                        e === '' ||
                                                                        Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                                    ) {
                                                                        item.from =
                                                                            numberUtil.regexNumberFormat(e.currentTarget.value)
                                                                    }
                                                                }}
                                                                value={
                                                                    (item.from ? numberUtil.number_format(
                                                                        item.from
                                                                    ) as string : '') ?? ''
                                                                }
                                                                placeholder='Từ'
                                                                required={true}
                                                            />
                                                            <span className='mx-1'>-</span>
                                                            <input
                                                                className='level_discount__item--input'
                                                                type="text"
                                                                onKeyDown={(evt: {
                                                                    key: string
                                                                    preventDefault: () => any
                                                                }) =>
                                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (
                                                                        e === '' ||
                                                                        Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                                    ) {
                                                                        item.to =
                                                                            numberUtil.regexNumberFormat(e.currentTarget.value)
                                                                    }
                                                                }}
                                                                value={
                                                                    (item.to ? numberUtil.number_format(
                                                                        item.to
                                                                    ) as string : '') ?? ''
                                                                }
                                                                placeholder='Đến'
                                                                required={true}
                                                            />
                                                        </div>
                                                        <div className='w-60 d-flex'>
                                                            <input
                                                                className='level_discount__input--level'
                                                                type="text"
                                                                onKeyDown={(evt: {
                                                                    key: string
                                                                    preventDefault: () => any
                                                                }) =>
                                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (voucherStore.dataRequestValueVoucher.proviso.type === "SPECIFIC_AMOUNT" &&
                                                                        (e === '' ||
                                                                            Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0)
                                                                    ) {
                                                                        item.discount =
                                                                            numberUtil.regexNumberFormat(e.currentTarget.value)
                                                                    }
                                                                    else if (voucherStore.dataRequestValueVoucher.proviso.type === "PERCENTAGE" &&
                                                                        (e === '' ||
                                                                            Number(numberUtil.regexPercentage(e)) >= 0)
                                                                    ) {
                                                                        item.discount =
                                                                            numberUtil.regexPercentage(e)
                                                                    }
                                                                }}
                                                                value={
                                                                    (item.discount ? numberUtil.number_format(
                                                                        item.discount
                                                                    ) as string : '') ?? ''
                                                                }
                                                                placeholder='Nhập vào...'
                                                                required={true}
                                                            />
                                                            {index !== 0 && <button className='btn p-0' onClick={() => {
                                                                voucherStore.dataRequestValueVoucher.proviso?.buyMore?.splice(index, 1)
                                                            }}>
                                                                <img src="/assets/ico/action/delete_icon.svg" className='level_discount__input--delete' alt=""/>
                                                            </button>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className='w-100 mt-4'>
                                    <button type='submit' className='btn btn__voucher'
                                            onClick={async () => {
                                                await voucherStore.addEditPromotion()
                                            }}
                                    >{voucherStore.type === 'add' ?  'Tạo Voucher' : voucherStore.type === 'copy' ?  "Sao chép Voucher" : 'Cập nhật Voucher'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddProductVoucher />
        </div>
    )

}

export default observer(ModalDiscountVoucher);

