import {getRequest, IApiResponse, postRequest} from "../../../common/helpers/RequestHelper";

class ChangePointService {
    public getSettingChangePoint(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/setting/conversion_points`);
    }
    public updateSettingChangePoint(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/setting/conversion_points`, data);
    }
    public getSettingRefundOrder(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/setting/loyalty_orders`);
    }
    public updateSettingRefundOrder(data: any): Promise<IApiResponse> {
        return postRequest(`/iss_365_fnb/setting/loyalty_orders`, data);
    }
}

export const changePointService = new ChangePointService();