import { observer } from 'mobx-react'
import React from 'react'
import './OrderTrackingCard.scss'
import {dateUtils} from "../../../../common/utils/DateUtils";

const ActiveTrackingCard = ({ state, logs }: any) => {
    const convertLogChangeStatus = (status: string) => {
        if(logs && logs.length > 0) return logs.filter((item: any) => item.state === status)?.[0]?.createdAt
        else return null
    }
    return (
        <div className="w-100 tracking_card__order--ship">
            {state === "CANCEL" ?
                <div className="d-flex justify-content-center align-items-center">
                    <img
                        src={'/assets/ico/statusOrder/cancel.svg'}
                        width={56}
                        height={56}
                        className=""
                        alt="cancel_order"
                    />
                </div>
                : <div className="d-flex justify-content-center align-items-center">
                <img
                    src={'/assets/ico/statusOrder/approve_active.svg'}
                    width={56}
                    height={56}
                    className=""
                    alt="cancel_order"
                />
                <img
                    src={state === 'DELIVERY_CONFIRMATION' || state === 'DELIVERED' ? '/assets/ico/statusOrder/line_active.svg' : '/assets/ico/statusOrder/line.svg'}
                    width={100}
                    height={3}
                    className=""
                    alt="line_active"
                />
                <img
                    src={state === 'DELIVERY_CONFIRMATION' || state === 'DELIVERED' ? '/assets/ico/statusOrder/shipping_active.svg' : '/assets/ico/statusOrder/shipping.svg'}
                    width={56}
                    height={56}
                    className=""
                    alt="cancel_order"
                />
                <img
                    src={state === 'DELIVERED' ? '/assets/ico/statusOrder/line_active.svg' : '/assets/ico/statusOrder/line.svg'}
                    width={100}
                    height={3}
                    className=""
                    alt="line_active"
                />
                <img
                    src={state === 'DELIVERED' ? '/assets/ico/statusOrder/shipped_active.svg' : '/assets/ico/statusOrder/shipped.svg'}
                    width={56}
                    height={56}
                    className=""
                    alt="cancel_order"
                />
            </div>
            }
            {state === "CANCEL" ?
                <div className="d-flex justify-content-center">
                    <div
                        style={{width: '157px'}}
                        className={`d-flex flex-column justify-content-center align-items-center mt-2 status_des`}
                    >
                        Đã hủy
                        <span
                            className='status_des__time'>{dateUtils.formatDate(convertLogChangeStatus("CANCEL"), 'hh:mm, DD/MM/YYYY')}</span>
                    </div>
                </div>
                : <div className="d-flex justify-content-center">
                    <div
                        style={{width: '157px'}}
                        className={`d-flex flex-column justify-content-center align-items-center mt-2 status_des`}
                    >
                        Xác nhận
                        <span
                            className='status_des__time'>{dateUtils.formatDate(convertLogChangeStatus("PENDING"), 'hh:mm')}</span>
                    </div>
                    <div
                        style={{width: '157px'}}
                        className={`d-flex flex-column justify-content-center align-items-center mt-2 status_des`}
                    >
                        Đang giao
                        <span
                            className='status_des__time'>{dateUtils.formatDate(convertLogChangeStatus("DELIVERY_CONFIRMATION"), 'hh:mm')}</span>
                    </div>
                    <div
                        style={{width: '157px'}}
                        className={`d-flex flex-column justify-content-center align-items-center mt-2 status_des`}
                    >
                        Đã giao
                        <span
                            className='status_des__time'>{dateUtils.formatDate(convertLogChangeStatus("DELIVERED"), 'hh:mm')}</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(ActiveTrackingCard)
