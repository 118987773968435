import {makeAutoObservable} from "mobx";
import $ from 'jquery'


class CheckStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listCheck: any[] = [1,2,3]
    dataRequest: any = {
        id: '',
    }
    listRequestCheckAfterProcessing: any = [
        {
            name: "",
            code: "",
            unit: "",
            qty: "",
            note: "",
        },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {

    }
}

export const checkStore = new CheckStore()