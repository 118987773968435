import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {roomStore} from "./RoomStore";

class RoomService{

    public getListRoom(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/room?page=${roomStore.page}${roomStore.establishmentId ? `&establishmentId=${roomStore.establishmentId}` : ''}${roomStore.status ? `&status=${roomStore.status}` : ''}&size=${size ?? 20}`)
    }

    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/room/${id}`)
    }

    public addRoom(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/room`, data)
    }

    public editRoom(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/room/${id}`, data)
    }

    public deleteRoom(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/room/${id}`, {})
    }

}

export const roomService = new RoomService()