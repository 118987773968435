import React from 'react';
import {observer} from "mobx-react";
import './DetailDryFoodCheckSheet.scss'
import {checkStore} from "../../CheckStore";

const ModalDetailDryFoodCheckSheet = () => {
    return (
        <div className="modal fade" id="detail_dry_food_check" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content px-5">
                    <div className="modal-header">
                        <button className="bt-action-print"
                                title="In biên bản"
                                onClick={() => {
                                    window.print();
                                }}
                                data-toggle="modal">
                            <img src="/assets/ico/action/printer.svg" alt=""/>
                        </button>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='detail_request_purchase__content my-3'>
                        <span className='content'>PHIẾU KIỂM TRA</span>
                        <span style={{color: '#000', fontSize: '14px'}}>Phiếu kiểm nguyên liệu thực phẩm tươi sống, đông lạnh: thịt cá, rau, củ, quả, ....</span>
                    </div>

                    <div className='modal-order--content'>
                        <div className='modal-order--head'>
                            <div className='modal-order--headFirst'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='modal-order--name d-flex flex-column'>
                                        <span>Tên nhà cung cấp: </span>
                                        <span>Mã kiểm tra: </span>
                                    </div>
                                    <div className='modal-order--value d-flex flex-column'>
                                        <span>TNHH Thành Công</span>
                                        <span>KT1221312</span>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-order--headSecond'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Người đánh giá: </span>
                                            <span>Thời gian đánh giá: </span>

                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column'>
                                            <span>Nguyễn Thành Danh</span>
                                            <span>12:09  12/07/2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-form-responsive d-flex justify-content-between">
                        <table className='table align-middle border gy-7 gs-7'>
                            <thead>
                            <tr>
                                <th rowSpan={2} className="text-center">TT</th>
                                <th rowSpan={2}  className="text-center">Tên thực phẩm</th>
                                <th rowSpan={2} className="text-center">Thời gian nhập (Ngày, giờ)</th>
                                <th rowSpan={2} className="text-center">Khối lượng</th>
                                <th colSpan={3} className="text-center">Nơi cung cấp</th>
                                <th rowSpan={2} className="text-center">Chứng từ, hóa đơn</th>
                                <th rowSpan={2} className="text-center">Giấy ĐKVS thú y</th>
                                <th rowSpan={2} className="text-center">Giấy kiểm dịch</th>
                                <th colSpan={2} className="text-center">Kiểm tra cảm quan</th>
                                <th colSpan={2} className="text-center">Xét nghiệm nhanh</th>
                                <th rowSpan={2} className="text-center">Biện pháp xử lý/ Ghi chú</th>
                            </tr>
                            <tr>
                                <th className="text-center">Tên cơ sở</th>
                                <th className="text-center">Địa chỉ, SĐT</th>
                                <th className="text-center">Tên người giao hàng</th>
                                <th className="text-center">Đạt</th>
                                <th className="text-center">Không đạt</th>
                                <th className="text-center">Đạt</th>
                                <th className="text-center">Không đạt</th>
                            </tr>
                            </thead>
                            <tbody>
                            { checkStore.listRequestCheckAfterProcessing.map((item: any, i: number) => {
                                return (
                                    <tr key={i} className='position-relative'>
                                        <td  style={{width: '32px'}}  className="text-center">{i + 1}</td>
                                        <td style={{minWidth: '200px'}}  className="text-center">
                                        </td>
                                        <td style={{minWidth: '150px'}} className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td  className="text-center">
                                        </td>
                                        <td className="text-center">
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ModalDetailDryFoodCheckSheet);