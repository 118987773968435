import React from 'react';
import {observer} from "mobx-react";
import './ViewHistoryOrder.scss'
import {dateUtils} from "../../../../common/utils/DateUtils";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {checkOrderStore} from "../../CheckOrderStore";
import {convertStatus} from "../detailOrder/detailOrder";
import Loading from "../../../../modules/components/Loading";


const ViewDetailOrderModal = () => {
    const convertDetailVoucher= (type: string, refundOrSale?: string) => {
        switch (type) {
            case "GIVE_PRODUCT":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Sản phẩm tặng ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.[0]?.name})</div>
                </div>
            case "VALUE":
                if(refundOrSale !== "REFUND")
                    return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Giảm ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                </div>
                else
                    return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Tích điểm ({`${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.coinEarned)} điểm`})</div>
                </div>
            case "COMBO":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Giảm ({checkOrderStore?.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                </div>
            case "PRODUCT":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.name})</div>
                </div>
            case "BIRTHDAY":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>
                        Voucher Sinh nhật
                        {checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.state === "USED" && <span className='ms-2 color-green'>(Đã sử dụng)</span>}
                    </div>
                </div>
            default:
                return <div className='d-flex justify-content-start align-items-center'>
                </div>
        }
    }
    const convertDetailPriceVoucher= () => {
        return <div className='d-flex justify-content-start align-items-center'>
            <div className='mx-2'>Đơn tối thiểu :</div>
            <div className={checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.minOrderValue <= checkOrderStore.dataDetail.totalAmount ? 'color-green' : 'color-red'}>({numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.minOrderValue)} đ)</div>
            {checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.type === "PERCENTAGE" &&
                <>
                    <span className='mx-1'>;</span>
                    <div className='mx-2'>Giảm tối đa :</div>
                    <div>{checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount ? `(${numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount)} đ)` : `(Không giới hạn)`}</div>
                </>
            }
            {checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.type === "COMBO" &&
                <>
                    <span className='mx-1'>;</span>
                    <div className='mx-2'>Combo áp dụng :</div>
                    <span>{checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.map((item: any, i: number) => {
                        return <span key={i}>{(i+1 === checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.promotion?.proviso?.products?.length) ?`${item?.name}` : `${item?.name} ,`}</span>
                    })}</span>
                </>
            }
        </div>
    }
    const convertDataTransport = () => {
        return <div className='d-flex justify-content-start align-items-center'>
            <div className='mx-2'>Người giao hàng :</div>
            <div>
                {checkOrderStore.dataDetailTransport?.driver?.name}
            </div>
        </div>
    }
    const convertLogsTransport = () => {
        return <div className='d-flex justify-content-start align-items-center'>
            <div className='mx-2'>Lịch sử giao hàng :</div>
            <div>
                Bắt đầu giao ({dateUtils.formatDate(checkOrderStore.dataDetailTransport?.logs.filter((i: any) => i.preState === "DELIVERY_CONFIRMATION")?.[0]?.createdAt, 'hh:mm, DD/MM/YYYY')})
            </div>
            {checkOrderStore.dataDetailTransport?.logs.filter((i: any) => i.preState === "DELIVERED")?.[0]?.createdAt && <div className='ms-2'>
                <span className='me-2'>-</span> Giao thành công ({dateUtils.formatDate(checkOrderStore.dataDetailTransport?.logs.filter((i: any) => i.preState === "DELIVERED")?.[0]?.createdAt, 'hh:mm, DD/MM/YYYY')})
            </div>}
        </div>
    }

    return (
        <div className="modal fade" id="view_detail_order" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"/>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='modal-order--content'>
                        <div className='modal-order--head'>
                            <div className='modal-order--headFirst'>
                                <div className='modal-order--title'>
                                    Thông tin đơn hàng
                                </div>
                                <div className='d-flex'>
                                    <div className='modal-order--name d-flex flex-column'>
                                        <span>Mã đơn:</span>
                                        <span>Thời gian đặt:</span>
                                        <span>Loại: </span>
                                        <span>Phòng - Bàn: </span>
                                        <span>Check in- out: </span>
                                    </div>
                                    <div className='modal-order--value d-flex flex-column w-50'>
                                        <span>#{checkOrderStore.dataDetail?.code}</span>
                                        <span>{dateUtils.formatDate(checkOrderStore.dataDetail.checkinTime, 'HH:MM DD/MM/YYYY')}</span>
                                        <span>{checkOrderStore.dataDetail.type === "OFFLINE" ? 'Tại chỗ' : 'Online'}</span>
                                        <span>{checkOrderStore.dataDetail.room?.name} - {checkOrderStore.dataDetail.table?.name}</span>
                                        <span>{dateUtils.formatDate(checkOrderStore.dataDetail.checkinTime, 'HH:MM DD/MM/YYYY')} - {dateUtils.formatDate(checkOrderStore.dataDetail.checkoutTime, 'HH:MM DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                            {checkOrderStore.dataDetail?.user?.name && <div className='modal-order--headSecond'>
                                <div className='modal-order--title'>
                                    Thông tin khách hàng
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Họ & Tên: </span>
                                            <span>Số điện thoại: </span>
                                            <span>Giới tính: </span>
                                            <span>Ngày sinh: </span>
                                            <span>Địa chỉ: </span>
                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-60'>
                                            <span>{checkOrderStore.dataDetail?.user?.name ?? '-'}</span>
                                            <span>{checkOrderStore.dataDetail?.user?.phone ?? '-'}</span>
                                            <span>{checkOrderStore.dataDetail?.user?.gender == "MALE" ? 'Nam' : checkOrderStore.dataDetail?.user?.gender == "FEMALE" ? 'Nữ' : ''}</span>
                                            <span>{dateUtils.formatDate(checkOrderStore.dataDetail.user?.birthDay)}</span>
                                            <span>{checkOrderStore.dataDetail.user?.address ?? '-'}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>ID: </span>
                                            <span>Loại khách hàng: </span>
                                            <span>Điểm: </span>
                                        </div>
                                        <div style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-30'>
                                            <span>{checkOrderStore.dataDetail.user?.id ?? '-'}</span>
                                            <span>{checkOrderStore.dataDetail?.user?.member?.[0]?.rank?.rankName} ( giảm {numberUtil.number_format(checkOrderStore.dataDetail?.user?.member?.[0]?.rank?.discountValue)} <span>{checkOrderStore.dataDetail?.user?.member?.[0]?.rank?.discountType === "VALUE" ? 'đ' : '%'}</span> )</span>
                                            <span>{numberUtil.number_format(checkOrderStore.dataDetail.user?.customerPoints?.[0]?.totalPoint)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {checkOrderStore.dataDetail?.type === 'ONLINE' && <div className='modal-order--headSecond'>
                                <div style={{padding: '5px 0'}} className='modal-order--title'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        Thông tin người nhận
                                    </div>
                                </div>
                                {checkOrderStore.isLoading ? <Loading /> : (checkOrderStore.dataDetailTransport ? <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-100'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Họ & Tên: </span>
                                            <span>Số điện thoại: </span>
                                            <span>Địa chỉ: </span>
                                            <span>Ghi chú: </span>
                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-60'>
                                            <span>{checkOrderStore.dataDetailTransport?.receiver?.name ?? '-'}</span>
                                            <span>{checkOrderStore.dataDetailTransport?.receiver?.phone ?? '-'}</span>
                                            <span>{checkOrderStore.dataDetailTransport?.receivingAddress ?
                                                `${checkOrderStore.dataDetailTransport?.receivingAddress?.ward?.name}, ${checkOrderStore.dataDetailTransport?.receivingAddress?.district?.name}, ${checkOrderStore.dataDetailTransport?.receivingAddress?.province?.name}` : '-'}</span>
                                            <span>{checkOrderStore.dataDetailTransport?.note?.customer ?? '-'}</span>
                                        </div>
                                    </div>
                                </div> : <div className='box_no__user'>Chưa có dữ liệu</div>)}
                            </div>}

                        </div>
                        <div className='table-responsive'>
                            <table className='table align-middle border gy-7 gs-7 mt-4'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Thời gian</th>
                                    <th className="text-center">Tình trạng</th>
                                    <th>Tên sản phẩm</th>
                                    <th>Mã sản phẩm</th>
                                    <th className="text-center">Đơn vị</th>
                                    <th className="text-center">Ghi chú</th>
                                    <th className="text-center">Số lượng</th>
                                    <th className="text-center">Đơn giá (VNĐ)</th>
                                    <th className="text-center">Thành tiền (VNĐ)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {checkOrderStore.dataDetail.items && checkOrderStore.dataDetail.items.map((item: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{i+1}</td>
                                            <td className="text-center">{dateUtils.formatDate(item.createdAt, 'HH:MM')}</td>
                                            <td className="text-center">{convertStatus(item.status)}</td>
                                            <td>{item?.product?.name}</td>
                                            <td>{item?.product?.batch}</td>
                                            <td className="text-center">{item?.product?.unit}</td>
                                            <td className="text-center">{item?.note}</td>
                                            <td className="text-center">{item?.quantity}</td>
                                            <td className="text-end fw-500">{numberUtil.number_format(item?.product?.price)}</td>
                                            <td className={`text-end fw-500 ${(item.status === 'SUCCESS' || item.status === 'IN_PROGRESS') ? '' : 'text-decoration-line-through'}`}>
                                                {numberUtil.number_format(item?.quantity * item?.product?.price)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{marginRight: '48px', width: '90%'}}>Tổng tiền dịch vụ:</div>
                                            <div>{numberUtil.number_format(checkOrderStore.dataDetail.totalAmount)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{marginRight: '48px', width: '90%'}}>Giảm giá voucher:</div>
                                            <div>- {numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.discountVoucher + checkOrderStore.dataDetail?.vouchers?.[0]?.discountBirthday)} đ</div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                {checkOrderStore.dataDetail?.vouchers?.[0]?.voucher ? convertDetailVoucher(checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.type,checkOrderStore.dataDetail?.vouchers?.[0]?.voucher?.discountType) :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Ưu đãi thành viên:</div>
                                            </div>
                                            <div>- {numberUtil.number_format(checkOrderStore.dataDetail?.vouchers?.[0]?.discountRank)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                {checkOrderStore.dataDetail?.type === 'ONLINE' && <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center'
                                                 style={{marginRight: '12px', width: '90%'}}>
                                                {checkOrderStore.dataDetail?.vouchers?.[0]?.voucher ? convertDetailPriceVoucher() :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Phí vận chuyển:</div>
                                            </div>
                                            <div>
                                                {numberUtil.number_format(checkOrderStore.dataDetail.totalFeeShip)} đ
                                            </div>
                                        </div>
                                    </td>
                                </tr>}
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                {checkOrderStore.dataDetailTransport && checkOrderStore.dataDetailTransport?.driver?.name ? convertDataTransport() :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Tổng tiền thành toán:</div>
                                            </div>
                                            <div>{numberUtil.number_format(checkOrderStore.dataDetail.totalPayment)} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                {checkOrderStore.dataDetail?.type === 'ONLINE' && <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '70%'}}>
                                                {checkOrderStore.dataDetailTransport && checkOrderStore.dataDetailTransport?.logs.filter((i: any) => i.preState === "DELIVERY_CONFIRMATION")?.[0]?.createdAt ? convertLogsTransport() :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                            </div>
                                            <div className='d-flex justify-content-end align-items-center status_payment'>
                                                {checkOrderStore.dataDetail?.paymentStatus === "PAID" ? <span>Đã thanh toán</span> : <span>Chưa thanh toán</span>}
                                            </div>
                                        </div>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className='d-flex justify-content-end'>
                            <button
                                type='button'
                                className="btn_action__checkout"
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                <img src="/assets/ico/action/printer_green.svg" alt=""/>
                                In tệp
                            </button>
                            {/*<button*/}
                            {/*    type='button'*/}
                            {/*    className="btn_action__checkout"*/}
                            {/*>*/}
                            {/*    <img src="/assets/ico/action/file-output.svg" alt=""/>*/}
                            {/*    Xuất tệp*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ViewDetailOrderModal);