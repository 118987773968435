import React, {useEffect} from 'react';
import './MarketResearchPage.scss'
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import {sttPage} from "../../../common/utils/Utils";
import {Link} from "react-router-dom";
import {marketResearchStore} from "./MarketResearchStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import {registerStore} from "../../../modules/auth/register/RegisterStore";
import {Tooltip} from "antd";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import PopupConfirm from "../../../modules/components/PopUpConfirm";

export const convertStateResearch = (value: string) => {
    switch (value.toUpperCase()) {
        case "APPROVED":
            return <span className="fw-500" style={{color: '#00AB56'}}>Đã duyệt</span>
        case "PROCESSING":
            return <span className="fw-500" style={{color: '#EF7752'}}>Đang xử lý</span>
        case "PENDING":
            return <span className="fw-500" style={{color: '#7B61FF'}}>Chờ duyệt</span>
        default:
            return null
    }
}
const MarketResearchPage = () => {
    const dataFilter : IFilter[] = [

        {
            label: 'Tình trạng',
            type: 'select',
            value: marketResearchStore.searchStatus,
            placeholder: 'Tất cả',
            listValue: [{value: "PENDING", label: "Đang chờ"},{value: "PROCESSING", label: "Đang xử lý"},{value: "APPROVED", label: "Đã duyệt"}],
            callback: async (e: any) => {
                marketResearchStore.searchStatus = e
                await marketResearchStore.getListMarketResearch()
            }
        },

    ]
    useEffect(() => {
        marketResearchStore.getListMarketResearch().then()
    }, [])
    return (
        <>
            <div className='market_research__container'>
                <div className='d-flex justify-content-between'>
                    <TabRedirect tab='Báo cáo nghiên cứu thị trường' />
                    <FilterComponent
                        data={dataFilter}
                        permissionCode
                        buttonAdd={<Link onClick={() => {
                            marketResearchStore.typeForm = 'add'
                            marketResearchStore.clearForm()
                            marketResearchStore.dataRequestMarketResearch.reportUser = registerStore.username
                        }} className="button_add_research" to={"/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong/tao-bao-cao"}>
                            <img src="/assets/ico/action/add_icon.svg" alt=""/>
                            Thêm
                        </Link>}
                    />
                </div>
                {marketResearchStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tên</th>
                            <th className="text-center">Thời gian</th>
                            <th className="text-center">Tình trạng</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {marketResearchStore.listMarketResearch.length < 1 ?
                                <NoContent colSpan={5}/> : marketResearchStore.listMarketResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{sttPage(0, i)}</td>
                                    <td className="text-center">
                                        <div className='d-flex align-items-center'>
                                            <img src="/assets/ico/file-bar-chart-2.svg" alt="" />
                                            <span className='mx-2'>{item?.name}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{dateUtils.formatDate(item?.reportingTime, "HH:mm:ss, DD/MM/YYYY")}</td>
                                    <td className="text-center">{convertStateResearch(item?.status)}</td>
                                    <td className="text-center">
                                            <Link onClick={() => {
                                                marketResearchStore.typeForm = 'edit'
                                                marketResearchStore.id = item.id
                                            }} className='p-0 mx-1' to={`/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong/chi-tiet/${item.id}`}>
                                                <Tooltip title='Chỉnh sửa'>
                                                    <img src="/assets/ico/action/edit_icon.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Chi tiết"/>
                                                </Tooltip>
                                            </Link>
                                            <button className="btn p-0 mx-1"
                                                    data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                    onClick={() => marketResearchStore.id = item.id}>
                                                <Tooltip title='Xóa'>
                                                    <img src="/assets/ico/action/delete_icon.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </Tooltip>
                                            </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <PopupConfirm isLoading={marketResearchStore.isLoadingBt} action={marketResearchStore.deleteMarketResearch} />
        </>
    );
}

export default observer(MarketResearchPage);