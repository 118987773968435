import React, {useEffect} from 'react';
import './StaffPage.scss'
import {observer} from "mobx-react";
import {numberUtil} from "../../common/utils/NumberUtil";
import DetailOrderModal from "../saleMarketing/dashboard/components/detailOrder/detailOrder";
import FilterComponent, {IFilter} from "../../modules/components/filterGlobal/FilterGlobal";
import {sttPage} from "../../common/utils/Utils";
import {staffStore} from "./StaffStore";
import {voucherStore} from "../saleMarketing/voucher/VoucherStore";
import {dateUtils} from "../../common/utils/DateUtils";
import Loading from "../../modules/components/Loading";
import NoContent from "../../modules/components/NoContent";
import {ConfigProvider, DatePicker, Input, Select} from "antd";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {Moment} from "moment/moment";
import {DatePickerProps} from "antd/es/date-picker";
const jobType = (type: string) => {
    switch (type) {
        case "FULL_TIME":
            return <span className="color-green fw-500">Full-time</span>;
        case "PART_TIME":
            return <span className="color-purple fw-500" style={{color: '#38383D'}}>Part-time</span>;
        default:
            return <span>-</span>;
    }
}
const StaffPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm tên , mã nhân viên...',
            value: '',
            onChange: (e: any) => {
                staffStore.keyword  = e
            },
            callback: async () => {
                staffStore.getStaff().then()
            }
        },
    ]
    useEffect(() => {
        staffStore.getStaff().then(() => {
        })
    }, [])
    function convertTime(date: Moment): number | null {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChange = (
        value: DatePickerProps['value'],
    ) => {
        // @ts-ignore
        staffStore.dataRequest.dateJoin = convertTime(value);
    };
    return (
        <>
            <div className='staff_container'>
                <div className='staff_container__header'>
                    <div  className='d-flex w-100 justify-content-end align-items-center'>
                        <FilterComponent
                            data={dataFilter}
                        />
                    </div>
                </div>
                {staffStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" className="text-center">STT</th>
                            <th style={{width: '150px'}} scope="col" className="text-center">Họ và tên</th>
                            <th scope="col"  className="text-center">Mã nhân viên</th>
                            <th scope="col" className="text-center">Số điện thoại</th>
                            <th scope="col"  className="text-center">Phòng ban</th>
                            <th scope="col"  className="text-center">Chức vụ</th>
                            <th scope="col"  className="text-center">Tình trạng</th>
                            <th scope="col"  className="text-center">Ngày chính thức</th>
                            <th scope="col"  className="text-center">Lương (VNĐ)</th>
                            <th scope="col"  className="text-center">Phụ cấp (VNĐ)</th>
                            <th scope="col" className="text-center">Thưởng (VNĐ)</th>
                            <th scope="col" className="text-center">BHXH (VNĐ)</th>
                            <th scope="col" className="text-center">Chi phí công đoàn (VNĐ)</th>
                            <th scope="col" className="text-center">Chi phí khác (VNĐ)</th>
                            <th scope="col"  className="text-center">Tổng (VNĐ)</th>
                            <th scope="col" style={{width: '150px'}} className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {staffStore.listStaff?.length <= 0 ? <NoContent colSpan={16}/> : staffStore.listStaff.map((item, i) => {
                            if(!item.isEdit)
                            return (
                                <tr style={{height: '36px'}} key={i}>
                                    <td className="text-center">{sttPage(voucherStore.page,i)}</td>
                                    <td className="text-center">{item?.name}</td>
                                    <td className="text-center">{item?.code}</td>
                                    <td className="text-center">{item?.phone}</td>
                                    <td className="text-center">
                                        {item?.departments?.map((department: any, index: number) => <div>{index === item?.departments?.length - 1 ? <span key={index}>{`${department.name}`}</span> : <span key={index}>{`${department.name}, `}</span>}</div>)}
                                    </td>
                                    <td className="text-center">
                                        {item?.roles?.map((role: any, index: number) => <div>{index === item?.roles?.length - 1 ? <span key={index}>{`${role.name}`}</span> : <span key={index}>{`${role.name}, `}</span>}</div>)}
                                        </td>
                                    <td className="text-center">{jobType(item?.jobType)}</td>
                                    <td className="text-center">{dateUtils.formatDate(item?.dateJoin)}</td>
                                    <td className="text-end">{numberUtil.number_format(item?.wage)}</td>
                                    <td className="text-end">{numberUtil.number_format(item?.allowance)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.bonus)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.socialInsurance)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.unionDues)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.otherCosts)}</td>
                                    <td className="text-end fw-500">{numberUtil.number_format(item?.wage + item?.allowance + item?.bonus - item?.socialInsurance - item?.unionDues - item?.otherCosts)}</td>
                                    <td className="text-center">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button onClick={async () => {
                                                item.isEdit = true
                                                staffStore.id = item.id
                                                staffStore.listStaff.filter((i: any) => i.id !== item.id).forEach((itemList: any) => {
                                                    itemList.isEdit = false
                                                })
                                                await staffStore.getDetailStaff(item.id)
                                            }} className='btn-edit__row mx-1'>
                                                Sửa
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                            else return (
                                <tr key={i} >
                                    <td className="text-center">{sttPage(voucherStore.page,i)}</td>
                                    <td className="text-center">{item?.name}</td>
                                    <td className="text-center">{item?.code}</td>
                                    <td className="text-center">{item?.phone}</td>
                                    <td className="text-center">
                                        {item?.departments?.map((department: any, index: number) => <div>{index === item?.departments?.length - 1 ? <span key={index}>{`${department.name}`}</span> : <span key={index}>{`${department.name}, `}</span>}</div>)}
                                    </td>
                                    <td className="text-center">
                                        {item?.roles?.map((role: any, index: number) => <div>{index === item?.roles?.length - 1 ? <span key={index}>{`${role.name}`}</span> : <span key={index}>{`${role.name}, `}</span>}</div>)}
                                    </td>
                                    <td style={{width: '150px'}}  className="text-center">
                                        <Select
                                            allowClear
                                            value={ staffStore.dataRequest.jobType}
                                            onChange={(e: any) => {
                                                staffStore.dataRequest.jobType = e
                                            }}
                                            placeholder="Chọn" options={staffStore.listTypeJob}
                                        />
                                    </td>
                                    <td style={{width: '150px'}}  className="text-center">
                                        <ConfigProvider locale={viVI}>
                                            <DatePicker
                                                value={!staffStore.dataRequest.dateJoin ? null : dayjs(staffStore.dataRequest.dateJoin)}
                                                placeholder='Chọn'
                                                format={"DD/MM/YYYY"} onChange={onChange}/>
                                        </ConfigProvider>
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.wage =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.wage ? numberUtil.number_format(
                                                    staffStore.dataRequest.wage
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-end">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.allowance =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.allowance ? numberUtil.number_format(
                                                    staffStore.dataRequest.allowance
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.bonus =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.bonus ? numberUtil.number_format(
                                                    staffStore.dataRequest.bonus
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.socialInsurance =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.socialInsurance ? numberUtil.number_format(
                                                    staffStore.dataRequest.socialInsurance
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.unionDues =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.unionDues ? numberUtil.number_format(
                                                    staffStore.dataRequest.unionDues
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="text"
                                            onKeyDown={(evt: {
                                                key: string
                                                preventDefault: () => any
                                            }) =>
                                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            onChange={(e: any) => {
                                                if (
                                                    e === '' ||
                                                    Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                                ) {
                                                    staffStore.dataRequest.otherCosts =
                                                        numberUtil.regexNumberFormat(e.currentTarget.value)
                                                }
                                            }}
                                            value={
                                                (staffStore.dataRequest.otherCosts ? numberUtil.number_format(
                                                    staffStore.dataRequest.otherCosts
                                                ) as string : '') ?? ''
                                            }
                                            placeholder='Nhập...'
                                            required={true}
                                        />
                                    </td>
                                    <td className="text-end fw-500">{
                                        numberUtil.number_format(Number(staffStore.dataRequest.wage) + Number(staffStore.dataRequest.allowance) + Number(staffStore.dataRequest.bonus) - Number(staffStore.dataRequest.socialInsurance)  - Number(staffStore.dataRequest.unionDues) - Number(staffStore.dataRequest.otherCosts))
                                    }</td>
                                    <td style={{width: '80px'}} className="text-center">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button onClick={() => {
                                                item.isEdit = false
                                            }} className='btn-edit__row--cancel mx-1'>
                                                Hủy
                                            </button>
                                            <button onClick={async () => {
                                                staffStore.id = item.id
                                                await staffStore.updateInfoStaff(item.id, item.name)
                                            }} className='btn-edit__row--save mx-1'>
                                                Lưu
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <DetailOrderModal />
        </>
    );
}

export default observer(StaffPage);