import React from 'react';
import {observer} from "mobx-react";
import './DetailCustomerData.scss'
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {customerDataStore} from "../../CustomerDataStore";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import NoContent from "../../../../../modules/components/NoContent";
import {dataStore} from "../../../../saleMarketing/data/DataStore";
import DetailOrderModal from "../../../../saleMarketing/dashboard/components/detailOrder/detailOrder";
import Loading from "../../../../../modules/components/Loading";
const DetailCustomerDataModal = () => {
    return (
        <div className="modal fade" id="detail_customer_data" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='modal-customerData--content'>
                        <div className='modal-customerData--head'>
                            <div className='modal-customerData--headFirst'>
                                <div className='modal-customerData--title'>
                                    THÔNG TIN KHÁCH HÀNG
                                </div>
                                <div className='d-flex w-100'>
                                    <div className='modal-customerData--name d-flex flex-column'>
                                        <span>Họ và tên: </span>
                                        <span>Số điện thoại: </span>
                                        <span>ID: </span>
                                        <span>Khách hàng: </span>
                                        <span>Điểm: </span>
                                    </div>
                                    <div className='modal-customerData--value d-flex flex-column w-50'>
                                        <span>{customerDataStore.dataDetail?.name}</span>
                                        <span>{customerDataStore.dataDetail?.phone}</span>
                                        <span>{customerDataStore.dataDetail?.id}</span>
                                        <span>Thường</span>
                                        <span>{numberUtil.number_format(customerDataStore.dataDetail?.totalPoint)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {customerDataStore.isLoading ? <Loading /> : <div className='table-responsive'>
                            <table className='table align-middle bcustomerData gy-7 gs-7 mt-4'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Mã đơn</th>
                                    <th className="text-center">Check In</th>
                                    <th className="text-center">Check Out</th>
                                    <th className="text-end">Tổng tiền thanh toán</th>
                                    <th className="text-end">Số điểm sử dụng</th>
                                    <th className="text-end">Tích điểm</th>
                                    <th className="text-center">Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>
                                { customerDataStore.listOrderUser?.length <= 0 ? <NoContent colSpan={8}/> : customerDataStore.listOrderUser.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{i + 1}</td>
                                            <td className="text-center">#{item?.code}</td>
                                            <td className="text-center">{dateUtils.formatDate(item?.checkinTime, 'hh:mm , DD/MM/YYYY')}</td>
                                            <td className="text-center">{dateUtils.formatDate(item?.checkoutTime, 'hh:mm , DD/MM/YYYY')}</td>
                                            <td className="text-end fw-500">{numberUtil.number_format(item?.totalPayment)}</td>
                                            <td className="text-end fw-500">{numberUtil.number_format(0)}</td>
                                            <td className="text-end fw-500">{numberUtil.number_format(item?.vouchers?.[0]?.coinEarned)}</td>
                                            <td className="text-center">
                                                <button
                                                    type='button'
                                                    className="view_detail__btn"
                                                    onClick={() => dataStore.getDetail(item.id)}
                                                    data-bs-toggle="modal"
                                                    title="Xem chi tiết"
                                                    data-bs-target='#detail_order'>
                                                    Chi tiết
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
            <DetailOrderModal/>
        </div>
    );
}

export default observer(DetailCustomerDataModal);