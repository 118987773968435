import React from 'react';
import {observer} from "mobx-react";
import './ModalBirthdayVoucher.scss'
import {Radio, Select, Input, Space, Spin} from 'antd';
import {voucherStore} from "../../VoucherStore";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {numberUtil} from "../../../../../common/utils/NumberUtil";
dayjs.extend(customParseFormat);

const ModalBirthdayVoucher = () => {
    const options = [
        {
            value: 'SPECIFIC_AMOUNT',
            label: 'Theo số tiền',
        },
        {
            value: 'PERCENTAGE',
            label: 'Theo %',
        },
    ];

    return (
        <div className='modal fade' id='birthday_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>VOUCHER TẶNG SINH NHẬT</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <div className='d-flex flex-column'>
                                    <label className='mb-2'>Loại Voucher<span className='text-danger'>*</span></label>
                                    <Radio.Group style={{display: 'flex', flexDirection: 'column'}}  name="radiogroup" onChange={(e) => {
                                        voucherStore.dataRequestValueVoucher.proviso.birthdayDiscount = e.target.value
                                    }} value={voucherStore.dataRequestValueVoucher?.proviso?.birthdayDiscount}>
                                        <Radio className='mb-2' value="FOR_MONTH">Theo tháng</Radio>
                                        <Radio value="FOR_DAY">Theo ngày</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-100 form-group'>
                                <label className='form-label mb-2'>Loại giảm giá | mức giảm <span className='text-danger'>*</span></label>
                                <Space.Compact>
                                    <Select placeholder='Chọn' onChange={(e) => voucherStore.dataRequestValueVoucher.proviso.type = e} value={ voucherStore.dataRequestValueVoucher?.proviso?.type} options={options} />
                                    <Input
                                        type="text"
                                        value={voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT" ? (voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value) : "") :  (voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value ?  numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value) : "")}
                                        onChange={(e: any) => {
                                            if (e === '' || Number(numberUtil.regexNumber(e)) >= 0){
                                                if(voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
                                                    voucherStore.dataRequestValueVoucher.proviso.specificAmount.value = numberUtil.regexNumber(e) ?? ""
                                                }
                                                else {
                                                    voucherStore.dataRequestValueVoucher.proviso.percentage.value = numberUtil.regexPercentage(e) ?? ""
                                                }
                                            }
                                        }}
                                        onKeyDown={(evt: {
                                            key: string
                                            preventDefault: () => any
                                        }) =>
                                            ['e', 'E', '+', '-'].includes(evt.key) &&
                                            evt.preventDefault()
                                        }
                                        status={(voucherStore.errors?.discountValue && !voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value) ? "error" : ""}
                                        placeholder="Nhập số ..." />
                                </Space.Compact>
                                {voucherStore.errors?.discountValue  && !voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value && <small className="text-error">{voucherStore.errors?.discountValue}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-100 d-flex align-items-center mt-3'>
                                <button type='submit' className='btn btn__voucher' onClick={async () => {
                                    await voucherStore.addEditPromotion()
                                }}>{voucherStore.isLoadingBt ? <Spin size="small" /> : voucherStore.type === 'add' ?  'Tạo Voucher' : voucherStore.type === 'copy' ?  "Sao chép Voucher" : 'Cập nhật Voucher'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalBirthdayVoucher);

