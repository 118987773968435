import React, {useEffect} from 'react';
import "./SettingInformationPage.scss"
import {observer} from "mobx-react";
import {Input, Select, Spin} from "antd";
import {settingInformationStore} from "./SettingInformationStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {urlImage} from "../../../common/utils/Utils";
import Loading from "../../../modules/components/Loading";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";



const SettingInformationPage = () => {
    useEffect(() => {
        if(localStorage.getItem('fnbId')) settingInformationStore.getDetailRestaurant(localStorage.getItem('fnbId')).then()
        settingInformationStore.getProvinces().then()
    }, [])
    useEffect(() => {
        if(settingInformationStore.dataRequest.provinceId) {
            settingInformationStore.getDistricts(settingInformationStore.dataRequest.provinceId).then(() => {
                if(settingInformationStore.dataRequest.districtId) {
                    settingInformationStore.getWards(settingInformationStore.dataRequest.districtId).then()
                }
            })
        }
    }, [settingInformationStore.dataRequest.provinceId])
    const uploadImgLogoInformation = (e: any) => {
        settingInformationStore.dataRequest.logo = e
    }
    return (
        <div className="setting_information__container">
            {!settingInformationStore.isLoading ? <div className="register_container">
                <div className="mb-4 setting_infor__title">
                    THÔNG TIN CỬA HÀNG
                </div>
                <div className='w-100 form-group'>
                    <label className='form-label'>Tên thương hiệu<span className='text-danger'>*</span></label>
                    <Input type='text' value={settingInformationStore.dataRequest?.name}
                           onChange={(e: any) => settingInformationStore.dataRequest.name = e.currentTarget.value}
                           status={(settingInformationStore.errors?.name && !settingInformationStore.dataRequest?.name) ? "error" : ""}
                           placeholder='Nhập tên ...' />
                    {settingInformationStore.errors?.name && !settingInformationStore.dataRequest?.name && <small className="mb-2 text-error">{settingInformationStore.errors?.name}</small>}
                </div>
                <div className='w-100 d-flex'>
                    <div className='w-50 form-group pe-2'>
                        <label className='form-label'>Quy mô (người)<span className='text-danger'>*</span></label>
                        <Input type='text' value={settingInformationStore.dataRequest?.businessNumber ? numberUtil.number_format(settingInformationStore.dataRequest?.businessNumber) : ""}
                               onKeyDown={(evt: {
                                   key: string
                                   preventDefault: () => any
                               }) =>
                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                   evt.preventDefault()
                               }
                               onChange={(e: any) => {
                                   if (
                                       e === '' ||
                                       Number(numberUtil.regexNumber(e)) >= 0
                                   ) {
                                       settingInformationStore.dataRequest.businessNumber = numberUtil.regexNumber(e)
                                   }
                               }}
                               status={(settingInformationStore.errors?.businessNumber&& !settingInformationStore.dataRequest?.businessNumber) ? "error" : ""}
                               placeholder='Nhập số ...' />
                        {settingInformationStore.errors?.businessNumber && !settingInformationStore.dataRequest?.businessNumber && <small className="mb-2 text-error">{settingInformationStore.errors?.businessNumber}</small>}
                    </div>
                    <div className='w-50 form-group ps-2'>
                        <label className='form-label'>Số chi nhánh<span className='text-danger'>*</span></label>
                        <Input type='text' value={settingInformationStore.dataRequest?.numBranch ? numberUtil.number_format(settingInformationStore.dataRequest?.numBranch) : ""}
                               onKeyDown={(evt: {
                                   key: string
                                   preventDefault: () => any
                               }) =>
                                   ['e', 'E', '+', '-'].includes(evt.key) &&
                                   evt.preventDefault()
                               }
                               onChange={(e: any) => {
                                   if (
                                       e === '' ||
                                       Number(numberUtil.regexNumber(e)) >= 0
                                   ) {
                                       settingInformationStore.dataRequest.numBranch = numberUtil.regexNumber(e)
                                   }
                               }}
                               status={(settingInformationStore.errors?.numBranch&& !settingInformationStore.dataRequest?.numBranch) ? "error" : ""}

                               placeholder='Nhập số ...'
                        />
                        {settingInformationStore.errors?.numBranch && !settingInformationStore.dataRequest?.numBranch && <small className="mb-2 text-error">{settingInformationStore.errors?.numBranch}</small>}
                    </div>
                </div>
                <div className='w-100 d-flex'>
                    <div className='w-50 form-group pe-2'>
                        <label className='form-label'>Họ và tên<span className='text-danger'>*</span></label>
                        <Input type='text' value={settingInformationStore.dataRequest?.ownerName}
                               onChange={(e: any) => settingInformationStore.dataRequest.ownerName = e.currentTarget.value}
                               status={(settingInformationStore.errors?.ownerName && !settingInformationStore.dataRequest?.ownerName) ? "error" : ""}
                               placeholder='Nhập tên...'
                        />
                        {settingInformationStore.errors?.ownerName && !settingInformationStore.dataRequest?.ownerName && <small className="mb-2 text-error">{settingInformationStore.errors?.ownerName}</small>}
                    </div>
                    <div className='w-50 form-group ps-2'>
                        <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                        <Input type='number' value={settingInformationStore.dataRequest?.ownerPhone}
                               onChange={(e: any) => settingInformationStore.dataRequest.ownerPhone = e.currentTarget.value}
                               status={(settingInformationStore.errors?.ownerPhone && !settingInformationStore.dataRequest?.ownerPhone) ? "error" : ""}
                               placeholder='Nhập số điện thoại ...'
                        />
                        {settingInformationStore.errors?.ownerPhone && !settingInformationStore.dataRequest?.ownerPhone && <small className="mb-2 text-error">{settingInformationStore.errors?.ownerPhone}</small>}
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-between'>
                    <div style={{width: '33%'}} className='form-group pe-2'>
                            <label className='form-label'>Tỉnh/ Thành phố<span className='text-danger'>*</span></label>
                            <Select
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                value={settingInformationStore.dataRequest.provinceId}
                                status={(settingInformationStore.errors?.provinceId && !settingInformationStore.dataRequest?.provinceId) ? "error" : ""}
                                onChange={async (e: any) => {
                                    await settingInformationStore.changeProvince(e)
                                }}
                                placeholder="Chọn" options={settingInformationStore.listProvince}
                            />
                            {settingInformationStore.errors?.provinceId  && !settingInformationStore.dataRequest.provinceId && <small className="text-error">{settingInformationStore.errors?.provinceId}</small>}
                    </div>
                    <div style={{width: '33%'}} className='form-group '>
                            <label className='form-label'>Quận/ Huyện<span className='text-danger'>*</span></label>
                            <Select
                                showSearch
                                status={(settingInformationStore.errors?.districtId && !settingInformationStore.dataRequest?.districtId) ? "error" : ""}
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                value={settingInformationStore.dataRequest.districtId}
                                onChange={async (e: any) => {
                                    await settingInformationStore.changeDistrict(e)
                                }}
                                placeholder="Chọn" options={settingInformationStore.listDistrict}
                            />
                            {settingInformationStore.errors?.districtId  && !settingInformationStore.dataRequest.districtId && <small className="text-error">{settingInformationStore.errors?.districtId}</small>}
                    </div>
                    <div style={{width: '33%'}} className='form-group ps-2'>
                            <label className='form-label'>Xã/ Phường<span className='text-danger'>*</span></label>
                            <Select
                                showSearch
                                status={(settingInformationStore.errors?.wardId && !settingInformationStore.dataRequest?.wardId) ? "error" : ""}

                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                value={settingInformationStore.dataRequest.wardId}
                                onChange={(e: any) => {
                                    settingInformationStore.dataRequest.wardId = e
                                }}
                                placeholder="Chọn" options={settingInformationStore.listWard}
                            />
                            {settingInformationStore.errors?.wardId  && !settingInformationStore.dataRequest.wardId && <small className="text-error">{settingInformationStore.errors?.wardId}</small>}
                    </div>
                </div>
                <div className='w-100 form-group'>
                    <label className='form-label'>Địa chỉ cụ thể<span className='text-danger'>*</span></label>
                    <Input type='text' value={settingInformationStore.dataRequest?.address}
                           onChange={(e: any) => settingInformationStore.dataRequest.address = e.currentTarget.value}
                           status={(settingInformationStore.errors?.address && !settingInformationStore.dataRequest?.address) ? "error" : ""}
                           placeholder='Nhập địa chỉ ...'
                    />
                    {settingInformationStore.errors?.address && !settingInformationStore.dataRequest?.address && <small className="mb-2 text-error">{settingInformationStore.errors?.address}</small>}
                </div>
                <div className="w-100 flex flex-wrap justify-between mb-4">
                    <label className='form-label'>Logo thương hiệu<span className='text-danger'>*</span></label>
                    <div className='list_image'>
                        <div className='list_'>
                            <div
                                className='upload'
                            >
                                <img src="/assets/ico/action/upload.svg" alt=""/>
                                <input
                                    accept="image/jpeg, image/jpg, image/png"
                                    type="file"
                                    onChange={async (e: any) =>
                                        await uploadFileHelper(e, uploadImgLogoInformation)
                                    }
                                />
                            </div>
                            {settingInformationStore.dataRequest.logo && <div className='item'>
                                                <img className='img' src={urlImage(settingInformationStore.dataRequest.logo)} alt=""/>
                                                <div
                                                    className='close_item'
                                                    onClick={() =>
                                                        settingInformationStore.dataRequest.logo = ""
                                                    }
                                                >
                                                    <img src="/assets/ico/action/button_clear_image.svg"
                                                         className="icon-action"
                                                         alt=""
                                                         title="Chỉnh sửa"/>
                                                </div>
                                            </div>}
                        </div>
                    </div>
                    {settingInformationStore.errors?.logo && !settingInformationStore.dataRequest?.logo && <small className="mb-2 text-error">{settingInformationStore.errors?.logo}</small>}

                </div>
                <div className='w-100 mt-4 d-flex justify-content-end'>
                    <button onClick={async () => {await settingInformationStore.updateInfo()}} className="button_save_setting">
                        {
                            settingInformationStore.isLoadingBt ? <Spin className='my-1' size="small" /> : "Lưu"
                        }
                    </button>
                </div>
            </div> : <Loading />}
        </div>
    )}

export default observer(SettingInformationPage);