import React, {useEffect} from 'react';
import './CustomerFeedback.scss'
import {observer} from "mobx-react";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {customerFeedbackStore} from "./CustomerFeedbackStore";
import {dateUtils} from "../../../common/utils/DateUtils";
import AddFeedbackModal from "./components/addFeedbackModal/AddFeedbackModal";
import ChartFeedback from "./components/chartFeedback/ChartFeedback";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";
import DetailFeedbackModal from "./components/detailFeedbackModal/DetailFeedbackModal";
import {orderStore} from "../../order/OrderStore";

const CustomerFeedback = () => {
    const dataNoteChartFirst = [
        {
            title: 'Rất hài lòng',
            color: '#2e62c4'
        },
        {
            title: 'Bình thường',
            color: '#09D'
        },
        {
            title: 'Không hài lòng',
            color: '#F93'
        }
    ]
    const dataNoteChartSecond = [
        {
            title: 'Phù hợp',
            color: '#2e62c4'
        },
        {
            title: 'Cao',
            color: '#09D'
        },
        {
            title: 'Thấp',
            color: '#7e9f9f'
        }
    ]
    const dataNoteChartThird = [
        {
            title: 'Facebook',
            color: '#2e62c4'
        },
        {
            title: 'Bạn bè',
            color: '#09D'
        },
        {
            title: 'Khác',
            color: '#f5cd79'
        }
    ]
    const dataNoteChartFour = [
        {
            title: 'Có',
            color: '#2e62c4'
        },
        {
            title: 'Không',
            color: '#cc3e58'
        },
    ]
    const dataFeedbackFilter : IFilter[] = [
        {
            type: 'select',
            value: customerFeedbackStore.establishmentId,
            placeholder: 'Chọn điểm bán',
            listValue: customerFeedbackStore.listRestaurant,
            callback: async (e: any, value: any) => {
                if(!e) {
                    customerFeedbackStore.establishmentId = customerFeedbackStore.listRestaurant?.[0]?.value

                    orderStore.dataRequest.establishmentId = customerFeedbackStore.listRestaurant?.[0]?.value
                    orderStore.dataRequest.establishmentName = customerFeedbackStore.listRestaurant?.[0]?.label ?? ""
                    localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                }
                else {
                    customerFeedbackStore.establishmentId = e
                    orderStore.dataRequest.establishmentId = e
                    orderStore.dataRequest.establishmentName = value?.label ?? ""
                    localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                }
                await customerFeedbackStore.getReviewsStats()
                await customerFeedbackStore.getCustomerFeedback()
            }
        },
    ]

    useEffect(() => {
        customerFeedbackStore.getListRestaurant().then(() => {
            if(!customerFeedbackStore.establishmentId) {
                customerFeedbackStore.establishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
            }
            customerFeedbackStore.getReviewsStats().then()
            customerFeedbackStore.getCustomerFeedback().then()
        })
    }, [])
    return (
        <>
            <div className='feedback_container'>
                {customerFeedbackStore.isLoading ? <Loading/> :
                   <div className="table-responsive d-flex justify-content-between">
                        <div style={{width: '65%'}} className='gy-7 gs-7'>
                            <div className='feedback_container__header w-100'>
                                <div style={{height: '72px'}} className='d-flex w-100 justify-content-between align-items-center'>
                                    <TabRedirect tab="Phản hồi khách hàng"/>
                                    <FilterComponent
                                        data={dataFeedbackFilter}
                                    />
                                </div>
                                {customerFeedbackStore.dataSumaryFeedback.length > 0 &&
                                    <div className="d-flex flex-column w-100 mt-3 mb-4">
                                        {
                                            customerFeedbackStore.dataSumaryFeedback.map((itemFb: any, indexFb: number) => {
                                                return (
                                                    <ChartFeedback key={indexFb} type={itemFb.type} title={itemFb.title}
                                                                   data={itemFb.data}/>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {customerFeedbackStore.dataSumaryFeedback.length > 0 &&
                                    <div className='d-flex align-items-start' style={{marginLeft: 162}}>
                                        <div className='d-flex flex-column justify-content-center align-items-start'>
                                            {dataNoteChartFirst.map((item: any, index: number) => {
                                                return (
                                                    <div key={index}
                                                         className='d-flex justify-content-center align-items-center me-3 mb-1'>
                                                        <div className='feedback_container--note'
                                                             style={{backgroundColor: item.color}}/>
                                                        <span
                                                            className='feedback_container--note-title'>{item.title}</span>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-start'>
                                            {
                                                dataNoteChartSecond.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index}
                                                             className='d-flex justify-content-center align-items-center me-3 mb-1'>
                                                            <div className='feedback_container--note'
                                                                 style={{backgroundColor: item.color}}/>
                                                            <span
                                                                className='feedback_container--note-title'>{item.title}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-start'>
                                            {
                                                dataNoteChartThird.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index}
                                                             className='d-flex justify-content-center align-items-center me-3 mb-1'>
                                                            <div className='feedback_container--note'
                                                                 style={{backgroundColor: item.color}}/>
                                                            <span
                                                                className='feedback_container--note-title'>{item.title}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-start'>
                                            {
                                                dataNoteChartFour.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index}
                                                             className='d-flex justify-content-center align-items-center me-3 mb-1'>
                                                            <div className='feedback_container--note'
                                                                 style={{backgroundColor: item.color}}/>
                                                            <span
                                                                className='feedback_container--note-title'>{item.title}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{width: '30%'}} className=''>
                            <div style={{height: '72px'}} className='feedback_container__header d-flex align-items-center justify-content-end'>
                                <button onClick={() => customerFeedbackStore.clearForm()}
                                        className='btn_add__feedback' data-bs-toggle="modal"
                                        data-bs-target={"#add_feedback"}>
                                    <img src='/assets/ico/action/add_green_icon.svg' alt=""
                                         className="icon_action"/>
                                    <span>Thêm đánh giá</span>
                                </button>
                            </div>
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">Thời gian</th>
                                    <th className="text-center">SĐT</th>
                                    <th className="text-center">Họ và tên</th>
                                    <th className="text-center">Đánh giá</th>
                                </tr>
                                </thead>
                                <tbody>
                                {customerFeedbackStore.listFeedback?.length <= 0 ?
                                    <NoContent colSpan={4} height={620} /> : customerFeedbackStore.listFeedback.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{dateUtils.formatDate(item?.createdAt, 'hh:mm , DD/MM/YYYY')}</td>
                                                <td className="text-center">{item?.user?.phone}</td>
                                                <td className="text-center">{item?.user?.name}</td>
                                                <td className="text-center">
                                                    <button onClick={async () => await customerFeedbackStore.getDetailFeedback(item?.id)} className='btn' data-bs-toggle="modal"
                                                            data-bs-target={"#detail_feedback"}>
                                                        <img className='tab_header__device'
                                                             src="/assets/ico/action/file-text.svg" alt=""/>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                }
            </div>
            <AddFeedbackModal />
            <DetailFeedbackModal />
        </>
    );
}

export default observer(CustomerFeedback);