import React from 'react';
import {observer} from "mobx-react";
import './detailOrder.scss'
import {numberUtil} from "../../../../common/utils/NumberUtil";
import {checkOrderStore} from "../../CheckOrderStore";
import {dateUtils} from "../../../../common/utils/DateUtils";
import ModalSelectUser from "../addUser/AddUserModal";
import Loading from "../../../../modules/components/Loading";
import {Radio} from "antd";
import ModalAddInfoReceiver from "../addInforReceiverTransport/AddInfoReceiverTransport";

export const convertStatus= (value: string) => {
    switch (value.toUpperCase()) {
        case "SUCCESS":
            return <span className="color-green" style={{fontWeight: '500'}}>Hoàn thành</span>
        case "IN_PROGRESS":
            return <span className="color-purple fw-medium" style={{fontWeight: '500'}}>Đang làm</span>
        case "CANCEL":
            return <span className="color-red fw-medium" style={{fontWeight: '500'}}>Hủy</span>
        case "PENDING":
            return <span className="color-pending fw-medium" style={{fontWeight: '500'}}>Chưa làm</span>
        default:
            return null
    }
}
const DetailCheckoutOrderModal = () => {

    const onKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
            await checkOrderStore.getListUser()
        }
    }
    const onKeyDownVoucher = async (e: any) => {
        if (e.key === 'Enter') {
            await checkOrderStore.checkAddVoucher()
        }
    }
    const ref = React.useRef<any>(null)
    const convertDetailVoucher= (type: string, refundOrSale?: string) => {
        switch (type) {
            case "GIVE_PRODUCT":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Sản phẩm tặng ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products?.[0]?.name})</div>
                </div>
            case "VALUE":
                if(refundOrSale !== "REFUND")
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Giảm ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                </div>
                else
                    return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name})</div>
                    <span className='mx-1'>;</span>
                    <div>Tích điểm ({`${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.coinEarned)} điểm`})</div>
                </div>
            case "COMBO":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name})</div>
                    {checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.type !== "SPECIAL" && <span className='mx-1'>;</span>}
                    {checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.type !== "SPECIAL" &&
                        <div>Giảm
                            ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.type === "SPECIFIC_AMOUNT" ? `${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.specificAmount?.value)} đ` : `${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.percentage?.value)} %`})</div>
                    }
                </div>
            case "PRODUCT":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>Tên chương trình ({checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name})</div>
                </div>
            case "BIRTHDAY":
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Thông tin mã voucher :</div>
                    <div>
                        {checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.name}
                        {checkOrderStore.dataCheckCodeVoucher?.voucher?.state === "USED" && <span className='ms-2 color-green'>(Đã sử dụng)</span>}
                    </div>
                </div>
            default:
                return <div className='d-flex justify-content-start align-items-center'>
                </div>
        }
    }
    const convertDetailPriceVoucher= () => {
                return <div className='d-flex justify-content-start align-items-center'>
                    <div className='mx-2'>Đơn tối thiểu :</div>
                    <div className={checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.minOrderValue <= checkOrderStore.dataDetail.totalAmount ? 'color-green' : 'color-red'}>({numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.minOrderValue)} đ)</div>
                    {checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.type === "PERCENTAGE" &&
                        <>
                            <span className='mx-1'>;</span>
                            <div className='mx-2'>Giảm tối đa :</div>
                            <div>{checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount ? `(${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.percentage?.maxDiscountAmount)} đ)` : `(Không giới hạn)`}</div>
                        </>
                    }
                    {checkOrderStore.dataCheckCodeVoucher?.voucher?.type === "COMBO" &&
                        <>
                            <span className='mx-1'>;</span>
                            <div className='mx-2'>Combo áp dụng :</div>
                            <span>{checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products?.map((item: any, i: number) => {
                                return <span key={i}>{(i+1 === checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products?.length) ?`${item?.name}` : `${item?.name} ,`}</span>
                            })}</span>
                        </>
                    }
                </div>
        }
    const handleFormBlur = (e: any) => {
        if (!ref.current.contains(e.relatedTarget)) {
            checkOrderStore.keyword = ''
        }
    };

    const convertPriceProductCombo = (products:any , id: any) => {
        return products?.filter((i: any) => i.id == id)?.[0]?.price
    }
    return (
        <>
        <div className="modal fade" id="detail_checkout_order" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"/>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='modal-order--content '>
                        <div className='modal-order--head mb-4'>
                            <div className='modal-order_checkout--headFirst'>
                                <div className='modal-order--title'>
                                    Thông tin đơn hàng
                                </div>
                                <div className='d-flex'>
                                    <div className='modal-order--name d-flex flex-column'>
                                        <span>Mã đơn:</span>
                                        <span>Điểm bán: </span>
                                        <span>Thời gian đặt:</span>
                                        <span>Loại: </span>
                                        <span>Phòng - Bàn: </span>
                                        <span>Check in- out: </span>
                                    </div>
                                    <div className='modal-order--value d-flex flex-column w-50'>
                                        <span>#{checkOrderStore.dataDetail?.code}</span>
                                        <span>{checkOrderStore.dataDetail?.establishment?.name}</span>
                                        <span>{dateUtils.formatDate(checkOrderStore.dataDetail.checkinTime, 'HH:MM DD/MM/YYYY')}</span>
                                        <span>{checkOrderStore.dataDetail.type === "OFFLINE" ? 'Tại chỗ' : 'Online'}</span>
                                        <span>{checkOrderStore.dataDetail.room?.name} - {checkOrderStore.dataDetail.table?.name}</span>
                                        <span>{dateUtils.formatDate(checkOrderStore.dataDetail.checkinTime, 'HH:MM')} - {dateUtils.formatDate(checkOrderStore.dataDetail.checkoutTime, 'HH:MM')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-order_checkout--headSecond'>
                                <div className='modal-order--title'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        Thông tin khách hàng
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <form
                                            ref={ref}
                                            onBlur={handleFormBlur}
                                            className="position-relative  mx-auto w-max"
                                        >
                                            <div className="input-search-container">
                                                <img src="/assets/ico/action/icon_search.svg" onClick={async () => await checkOrderStore.getListUser()} alt=""/>
                                                <input onChange={async (e: any) => {
                                                    checkOrderStore.keyword = e.target.value
                                                    if(checkOrderStore.keyword) {
                                                        await checkOrderStore.getListUser()
                                                    }
                                                    else checkOrderStore.listUser = []
                                                }} value={checkOrderStore.keyword} onKeyDown={(e: any) => onKeyDown(e)} type="text" placeholder="Tìm kiếm tên, số điện thoại..." className="search-input" />
                                            </div>
                                            {checkOrderStore.keyword && checkOrderStore.listUser && checkOrderStore.listUser.length > 0 && (
                                                <ul className="position-absolute search_user__container custom-scrollbar">
                                                    {checkOrderStore.listUser.map((suggestion, index) => (
                                                        <li
                                                            key={index}
                                                            className="search_user__item"
                                                            onMouseDown={(e) => e.preventDefault()}
                                                            onClick={async () => {
                                                                await checkOrderStore.getDetailUser(suggestion?.id)
                                                                checkOrderStore.keyword = ''
                                                            }}
                                                        >
                                                            <div>{suggestion.name}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </form>

                                    </div>
                                </div>
                                {checkOrderStore.isLoading ? <Loading /> : (checkOrderStore.detailInfoUser?.id ? <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Họ & Tên: </span>
                                            <span>Số điện thoại: </span>
                                            <span>Giới tính: </span>
                                            <span>Ngày sinh: </span>
                                            <span>Địa chỉ: </span>
                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-60'>
                                            <span>{checkOrderStore.detailInfoUser?.name ?? '-'}</span>
                                            <span>{checkOrderStore.detailInfoUser?.phone ?? '-'}</span>
                                            <span>{checkOrderStore.detailInfoUser?.gender == "MALE" ? 'Nam' : checkOrderStore.detailInfoUser?.gender == "FEMALE" ? 'Nữ' : '-'}</span>
                                            <span>{dateUtils.formatDate(checkOrderStore.detailInfoUser?.birthDay)}</span>
                                            <span>{checkOrderStore.dataDetail.user?.address ?? '-'}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex w-50'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>ID: </span>
                                            <span>Loại khách hàng: </span>
                                            <span>Điểm: </span>
                                        </div>
                                        <div style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-30'>
                                            <span>{checkOrderStore.detailInfoUser?.id}</span>
                                            {checkOrderStore.dataCheckCodeVoucher?.rank?.rankName ? <span>{checkOrderStore.dataCheckCodeVoucher?.rank?.rankName} ( giảm {numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.rank?.discountValue)} <span>{checkOrderStore.dataCheckCodeVoucher?.rank?.discountType === "VALUE" ? 'đ' : '%'}</span> )</span> : <span>-</span>}
                                            <span>{numberUtil.number_format(checkOrderStore.detailInfoUser?.customerPoints?.[0]?.availablePoints)}</span>
                                        </div>
                                    </div>
                                </div> : <div className='box_no__user'>Chưa có dữ liệu</div>)}
                            </div>
                            {checkOrderStore.dataDetail?.type === 'ONLINE' && <div className='modal-order_checkout--headSecond'>
                                <div style={{padding: '5px 0'}} className='modal-order--title'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        Thông tin người nhận
                                    </div>
                                    <button onClick={async () => {
                                        await checkOrderStore.getProvinces()
                                    }
                                    } className='btn p-0' data-bs-toggle="modal"
                                            data-bs-target="#add_info_receiver_transport">
                                        <img src="/assets/ico/action/edit.svg" alt=""/>
                                    </button>
                                </div>
                                {checkOrderStore.isLoading ? <Loading /> : (checkOrderStore.dataReceiverTransport?.nameReceiver ? <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-100'>
                                        <div className='modal-order--name d-flex flex-column'>
                                            <span>Họ & Tên: </span>
                                            <span>Số điện thoại: </span>
                                            <span>Địa chỉ: </span>
                                            <span>Ghi chú: </span>
                                        </div>
                                        <div  style={{marginLeft: '18px'}} className='modal-order--value d-flex flex-column w-60'>
                                            <span>{checkOrderStore.dataReceiverTransport.nameReceiver ?? '-'}</span>
                                            <span>{checkOrderStore.dataReceiverTransport.phoneReceiver ?? '-'}</span>
                                            <span>{checkOrderStore.dataReceiverTransportWardName && checkOrderStore.dataReceiverTransportDistrictName && checkOrderStore.dataReceiverTransportProvinceName ?
                                                `${checkOrderStore.dataReceiverTransportWardName}, ${checkOrderStore.dataReceiverTransportDistrictName}, ${checkOrderStore.dataReceiverTransportProvinceName}` : '-'}</span>
                                            <span>{checkOrderStore.dataReceiverTransport.note ?? '-'}</span>
                                        </div>
                                    </div>
                                </div> : <div className='box_no__user'>Chưa có dữ liệu</div>)}
                            </div>}
                        </div>
                        <div className='table-responsive'>
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th>Tên sản phẩm</th>
                                    <th>Mã sản phẩm</th>
                                    <th className="text-center">Đơn vị</th>
                                    <th className="text-center">Ghi chú</th>
                                    <th className="text-center">Đơn giá (VNĐ)</th>
                                    <th className="text-center">Số lượng</th>
                                    <th className="text-center">Thành tiền (VNĐ)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {checkOrderStore.listItems && checkOrderStore.listItems.map((item: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{i+1}</td>
                                            <td>{item?.product?.name}</td>
                                            <td>{item?.product?.batch}</td>
                                            <td className="text-center">{item?.product?.unit}</td>
                                            <td className="text-center">{item?.note}</td>
                                            {(checkOrderStore.dataCheckCodeVoucher?.voucher?.type === "COMBO" && convertPriceProductCombo(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products, item.productId)) ?
                                                <td className="text-end fw-500">
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-decoration-line-through color-pending'>{numberUtil.number_format(item?.product?.price)}</span>
                                                        {
                                                            numberUtil.number_format(convertPriceProductCombo(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products, item.productId))
                                                        }
                                                    </div>
                                                </td> :  <td className="text-end fw-500">{
                                                    numberUtil.number_format(item?.product?.price)
                                                }</td>
                                            }
                                            <td className="text-center">
                                                {item?.quantity}
                                            </td>
                                            {(checkOrderStore.dataCheckCodeVoucher?.voucher?.type === "COMBO" && convertPriceProductCombo(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products, item.productId)) ?
                                                <td className={`text-end fw-500 ${(item.status === 'SUCCESS' || item.status === 'IN_PROGRESS') ? '' : 'text-decoration-line-through'}`}>
                                                        <div className='d-flex flex-column'>
                                                            <span className='text-decoration-line-through color-pending'>{numberUtil.number_format(item?.quantity * item.product?.price)}</span>
                                                            {
                                                                numberUtil.number_format(convertPriceProductCombo(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.proviso?.products, item.productId) * item.quantity)
                                                            }
                                                        </div>
                                                </td> :  <td className={`text-end fw-500 ${(item.status === 'SUCCESS' || item.status === 'IN_PROGRESS') ? '' : 'text-decoration-line-through'}`}>
                                                    {
                                                        numberUtil.number_format(item?.quantity * item.product?.price)
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{marginRight: '48px', width: '90%'}}>Tổng tiền dịch vụ:</div>
                                            <div>{numberUtil.number_format(checkOrderStore?.listItems?.filter((i: any) => (i.status === 'IN_PROGRESS' || i.status === 'SUCCESS')).reduce((accumulator: any, currentItem: any) => {
                                                return accumulator + currentItem.price * currentItem.quantity;
                                            }, 0))} đ</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <div className='mx-2'>Mã khuyến mãi:</div>
                                                    <div className="input-search-voucher-container">
                                                        <input onChange={(e: any) => {
                                                            checkOrderStore.dataCheckVoucherRequest.voucher = e.target.value
                                                            checkOrderStore.dataCheckOutRequest.voucherCode = e.target.value
                                                        }} value={ checkOrderStore.dataCheckVoucherRequest.voucher} onKeyDown={(e: any) => onKeyDownVoucher(e)} type="text" placeholder="Thêm mã voucher..." className="search-input" />
                                                        <img src="/assets/ico/action/badge-percent.svg" onClick={async () => {
                                                            await checkOrderStore.checkAddVoucher()
                                                        }} alt=""/>
                                                    </div>
                                                </div>
                                                <div>Giảm giá voucher:</div>
                                            </div>
                                            <div>{checkOrderStore.dataCheckCodeVoucher?.discountBirthday || checkOrderStore.dataCheckCodeVoucher?.discountVoucher  ? `- ${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.discountBirthday + checkOrderStore.dataCheckCodeVoucher?.discountVoucher)} đ` : '0 đ'}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                {checkOrderStore.dataCheckCodeVoucher.voucher ? convertDetailVoucher(checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.type, checkOrderStore.dataCheckCodeVoucher?.voucher?.promotion?.discountType) :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Ưu đãi thành viên:</div>
                                            </div>
                                            <div>{checkOrderStore.dataCheckCodeVoucher?.discountRank ? `- ${numberUtil.number_format(checkOrderStore.dataCheckCodeVoucher?.discountRank)} đ` : '0 đ'}</div>
                                        </div>
                                    </td>
                                </tr>
                                {checkOrderStore.dataDetail?.type === 'ONLINE' && <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center'
                                                 style={{marginRight: '12px', width: '90%'}}>
                                                {checkOrderStore.dataCheckCodeVoucher.voucher ? convertDetailPriceVoucher() :
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                }
                                                <div>Phí vận chuyển:</div>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    onKeyDown={(evt: {
                                                        key: string
                                                        preventDefault: () => any
                                                    }) =>
                                                        ['e', 'E', '+', '-'].includes(evt.key) &&
                                                        evt.preventDefault()
                                                    }
                                                    onChange={(e: any) => {
                                                        if (e === '' || Number(numberUtil.regexNumber(e)) >= 0) {
                                                            checkOrderStore.dataReceiverTransport.shippingFee = numberUtil.regexNumber(e)
                                                        }
                                                    }}
                                                    className={checkOrderStore.isErrorShippingFee && !checkOrderStore.dataReceiverTransport.shippingFee ? 'border_error input_fee__shipping' : 'input_fee__shipping'}
                                                    value={checkOrderStore.dataReceiverTransport.shippingFee > 0 ? numberUtil.number_format(checkOrderStore.dataReceiverTransport.shippingFee) : 0}
                                                    required={true}
                                                    placeholder="Nhập số..."
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center' style={{marginRight: '48px', width: '90%'}}>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                    </div>
                                                <div>Tổng tiền thành toán:</div>
                                            </div>
                                            <div>
                                                {checkOrderStore.dataReceiverTransport.shippingFee ? (
                                                    checkOrderStore.dataCheckCodeVoucher?.discountTotal ?
                                                        `${numberUtil.number_format(checkOrderStore.listItems?.filter((i: any) => (i.status === 'IN_PROGRESS' || i.status === 'SUCCESS')).reduce((accumulator: any, currentItem: any) => {
                                                            return accumulator + currentItem.price * currentItem.quantity;
                                                        }, 0) - checkOrderStore.dataCheckCodeVoucher?.discountTotal + checkOrderStore.dataReceiverTransport.shippingFee)} đ`
                                                        : `${numberUtil.number_format(checkOrderStore.listItems?.filter((i: any) => (i.status === 'IN_PROGRESS' || i.status === 'SUCCESS')).reduce((accumulator: any, currentItem: any) => {
                                                            return accumulator + currentItem.price * currentItem.quantity;
                                                        }, 0) + checkOrderStore.dataReceiverTransport.shippingFee)} 
                                            đ`
                                                ) : (
                                                    checkOrderStore.dataCheckCodeVoucher?.discountTotal ?
                                                        `${numberUtil.number_format(checkOrderStore.listItems?.filter((i: any) => (i.status === 'IN_PROGRESS' || i.status === 'SUCCESS')).reduce((accumulator: any, currentItem: any) => {
                                                            return accumulator + currentItem.price * currentItem.quantity;
                                                        }, 0) - checkOrderStore.dataCheckCodeVoucher?.discountTotal)} đ`
                                                        : `${numberUtil.number_format(checkOrderStore.listItems?.filter((i: any) => (i.status === 'IN_PROGRESS' || i.status === 'SUCCESS')).reduce((accumulator: any, currentItem: any) => {
                                                            return accumulator + currentItem.price * currentItem.quantity;
                                                        }, 0))} 
                                            đ`
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {checkOrderStore.dataDetail?.type === 'ONLINE' && <tr>
                                    <td colSpan={12} className='text-end fw-500'>
                                        <div className='d-flex justify-content-end align-items-center'>
                                                <Radio.Group  name="radiogroup" onChange={(e) => {
                                                    checkOrderStore.dataReceiverTransport.paymentStatus = e.target.value
                                                }} value={checkOrderStore.dataReceiverTransport?.paymentStatus}>
                                                    <Radio value="UNPAID">Chưa thanh toán</Radio>
                                                    <Radio value="PAID">Đã thanh toán</Radio>
                                                </Radio.Group>
                                        </div>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className='d-flex w-100 justify-content-end'>
                                {/*<button*/}
                                {/*    type='button'*/}
                                {/*    className="btn_action__checkout"*/}
                                {/*    onClick={() => {*/}
                                {/*        window.print();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <img src="/assets/ico/action/printer_green.svg" alt=""/>*/}
                                {/*    In tệp*/}
                                {/*</button>*/}
                                {/*<button*/}
                                {/*    type='button'*/}
                                {/*    className="btn_action__checkout"*/}
                                {/*>*/}
                                {/*    <img src="/assets/ico/action/li_forward.svg" alt=""/>*/}
                                {/*    Trả hàng*/}
                                {/*</button>*/}
                                {/*<button*/}
                                {/*    type='button'*/}
                                {/*    className="btn_action__checkout"*/}
                                {/*>*/}
                                {/*    <img src="/assets/ico/action/file-output.svg" alt=""/>*/}
                                {/*    Xuất tệp*/}
                                {/*</button>*/}
                                <button
                                    type='button'
                                    className="btn_action__checkout__finish"
                                    onClick={async () => {
                                        await checkOrderStore.checkOutOrder()
                                    }}
                                >
                                    Thanh toán
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <ModalSelectUser />
            <ModalAddInfoReceiver />
        </>
    );
}

export default observer(DetailCheckoutOrderModal);