import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from 'jquery'
import {suppliesStore} from "../../setting/supplies/SuppliesStore";
import {purchaseService} from "./PurchaseService";
import {importExportStore} from "../importExportSupplies/ImportExportStore";


class PurchaseStore {
    id: any = ''
    typeModal: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listUser: any[] = []
    listBuyRequest: any[] = []
    listSummaryPurchase: any[] = []
    materialId: any = null
    use_material: any = null
    isErrorsQtySupplies : boolean = false
    dataDetailBuyRequest: any = {}
    errors: any = {
        code: '',
    }
    dataRequest: any = {
        code: '',
        timeRequest : "",
        purpose: '',
        approvedId: null,
        items: [],
    }
    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
        this.dataRequest = {
            code: '',
            timeRequest : "",
            purpose: '',
            approvedId: null,
            items: [],
        }
        this.errors = {
            code: '',
        }
        this.isErrorsQtySupplies = false
    }
    async getBuyRequest() {
        this.isLoading = true
        const result = await purchaseService.getBuyRequest()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listBuyRequest = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetailBuyRequest(id: number) {
        const result = await purchaseService.getDetailBuyRequest(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataDetailBuyRequest = result.body
            importExportStore.dataRequest.items = result.body.data.map((item: any) => {
                return {
                    id: item.suppliesId,
                    name: item.suppliesName,
                    code: item.suppliesCode,
                    unit: item.unit,
                    qty: item.qtyRequest,
                    price: "",
                    expiryDate: "",
                    note: ""
                }
            })
        }
    }
    async changeUseMaterial(value: any){
        suppliesStore.type = value
        purchaseStore.use_material = value
        await suppliesStore.getSupplies()
    }

    async addSupplies(){
        let value: any = {}
        let isError: boolean = false
        suppliesStore.listSupplies && suppliesStore.listSupplies.map((item: any) => {
            purchaseStore.dataRequest.items && purchaseStore.dataRequest.items.map((val: any) => {
                if (val.id == this.materialId) {
                    isError = true
                }
            })
            if (item.id == this.materialId) {
                value = item
            }
        })
        if (!this.materialId) {
            toastUtil.warning('Vui lòng chọn vật tư!', 2)
            return
        }
        if (isError) {
            toastUtil.warning('Vật tư đã tồn tại!', 3)
            return
        }
        let data = {
            id: value.id,
            name: value.name,
            code: value.code,
            unit: value.unit,
            qty: "",
            expectedDate: "",
            note: ""
        }
        this.errors.materialId = ""
        purchaseStore.dataRequest.items.unshift(data)
        suppliesStore.listSupplies = []
        this.use_material = null
        this.materialId = null
    }
    async getListUser() {
        const result = await purchaseService.getListUser()
        if (result.status === HttpStatusCode.OK) {
            purchaseStore.listUser = result.body.data
        }
    }
    async getListSummaryPurchase() {
        const result = await purchaseService.getListSummaryPurchase()
        if (result.status === HttpStatusCode.OK) {
            purchaseStore.listSummaryPurchase = result.body
        }
    }
    async sendImportExport() {
        let {code, timeRequest, items, purpose, approvedId } = this.dataRequest
        let arrSupplies: any = []

        items && items.map((item: any) => {
            arrSupplies.push({
                suppliesId: item.id,
                qtyRequest: item.qty ? Number(item.qty) : null,
                note: item.note,
                expectedDate: item.expectedDate,
            })
        })

        if (!code) {
            this.errors.code = `Mã yêu cầu không được để trống!`
            return
        }
        if (!timeRequest) {
            this.errors.timeRequest = `Ngày gửi yêu cầu không được để trống!`
            return
        }

        if (arrSupplies.length < 1) {
            this.errors.materialId = 'Vật tư không được để trống!'
            return
        }
        const isIdInArraySupplies = items.some(
            (item: { qty: any; }) => !item.qty
        );
        if(isIdInArraySupplies) {
            this.isErrorsQtySupplies = true
            toastUtil.warning('Vui lòng nhập đầy đủ số lượng vật tư yêu cầu', 4)
            return false
        }


        let data = {
            code: code,
            timeRequest: timeRequest,
            approvedId: approvedId,
            items: arrSupplies,
            purpose: purpose,
        }
        this.isLoadingBt = true
        const result = await purchaseService.sendBuyRequest(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Tạo đề nghị mua hàng thành công!`)
            $('#add_request_purchase').trigger('click')
            this.clearForm()
            await purchaseStore.getBuyRequest()

        } else toastUtil.error(result.body.message)
    }

}

export const purchaseStore = new PurchaseStore()