import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import FilterComponent from "../../modules/components/filterGlobal/FilterGlobal";
import CustomTable from "../../modules/components/tableGlobal/CustomTable";
import {browserStore} from "./BrowserStore";
import {dateUtils} from "../../common/utils/DateUtils";
import PopupImpExpDetail from "../warehouses/importExportSupplies/components/PopupImpExpDetail";
import {importExportStore} from "../warehouses/importExportSupplies/ImportExportStore";
import {purchaseStore} from "../warehouses/purchase/PurchaseStore";
import ModalDetailRequestPurchase from "../warehouses/purchase/components/detailRequestPurchase/DetailRequestPurchase";
import {Tooltip} from "antd";

const BrowserPage = () => {
    const convertStatusImportRequest= (value: string) => {
        switch (value.toUpperCase()) {
            case "APPROVED":
                return <span className="color-green fw-500">Đã duyệt</span>
            case "REJECT":
                return <span className="color-red fw-500">Hủy</span>
            case "PENDING":
                return <span style={{fontWeight: '500', color: '#808089'}}>Đang chờ</span>
            default:
                return null
        }
    }
    const convertTypeRequest= (value: string, typeImEx?: string) => {
        switch (value.toUpperCase()) {
            case "WAREHOUSE":
                if(typeImEx === "IMPORT") return <span className="color-purple fw-500">Yêu cầu nhập kho</span>
                else return <span className="color-green fw-500">Yêu cầu xuất kho</span>
            case "BUY_REQUEST":
                return <span style={{color: '#EF7752'}} className="fw-500">Yêu cầu mua hàng</span>
            default:
                return null
        }
    }

    const dataFilter : any[] = [
        {
            label: 'Loại yêu cầu',
            type: 'select',
            value:  browserStore.searchType,
            placeholder: 'Loại yêu cầu',
            listValue: [{
                value: "WAREHOUSE",
                label : "Xuất nhập kho"
            },{
                value: "BUY_REQUEST",
                label : "Mua hàng"
            }],
            callback: async (e: any) => {
                browserStore.searchType = e
                await browserStore.getImpExpRequest()
            }
        },
        {
            label: 'Tình trạng',
            type: 'select',
            value: browserStore.searchStatus,
            placeholder: 'Tình trạng',
            listValue: [{
                value: "APPROVED",
                label: "Đã duyệt"
            },{
                value: "PENDING",
                label : "Đang chờ"
            }
            ,{
                value: "REJECT",
                label : "Đã hủy"
            }],
            callback: async (e: any) => {
                browserStore.searchStatus = e
                await browserStore.getImpExpRequest()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm theo mã',
            value: browserStore.keyword,
            onChange: (e: any) => browserStore.keyword = e,
            callback: async () => await browserStore.getImpExpRequest()
        },
    ]
    const columnCosts: any = [
        {
            title: "STT",
            align: "center",
            width: 80,
            render: (_: any, __ : any, index: any) => index + 1,
            fixed: "left",
        },
        {
            title: "Tên file",
            dataIndex: "type",
            key: "type",
            align: "left",
            fixed: "left",
            render: (value: any, record: any) => value ? convertTypeRequest(value, record.imEx) : "-"
        },
        {
            title: 'Mã file',
            dataIndex: "code",
            key: "code",
            align: "center",
        },
        {
            title: "Người gửi",
            dataIndex: "senderName",
            key: "senderName",
            align: "center",
        },
        {
            title: "Lãnh đạo/ Phòng ban/ Nhân viên",
            dataIndex: "cost",
            key: "cost",
            width: 250,
            align: "center",
        },
        {
            title: "Ngày nhận",
            dataIndex: "timeRequest",
            key: "timeRequest",
            align: "center",
            render: (value: any) => value ? dateUtils.formatDate(value,'hh:mm , DD/MM/YYYY') : "-"
        },
        {
            title: "Ngày duyệt",
            dataIndex: "timeApproved",
            key: "timeApproved",
            align: "center",
            render: (value: any) => value ? dateUtils.formatDate(value,'hh:mm , DD/MM/YYYY') : "-"
        },
        {
            title: "Tình trạng",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (value: any) => value ? convertStatusImportRequest(value) : "-"
        },
        {
            title: "Thao tác",
            dataIndex: "action",
            align: "center",
            width: 160,
            render: (_: any, record: any, index: number): JSX.Element => (
                <div>
                        <button className="btn p-0"
                                data-bs-toggle="modal" data-bs-target={record.type === "WAREHOUSE" ? '#popup_imp_exp_request' : '#detail_request_purchase'}
                                onClick={() => record.type === "WAREHOUSE" ? importExportStore.getDetailRequest(record.idRequest) :  purchaseStore.getDetailBuyRequest(record.idBuyRequest)}>
                            <Tooltip title='Chi tiết'>
                                <img src="/assets/ico/action/detail_icon.svg"
                                     className="icon-action"
                                     alt=""
                                />
                            </Tooltip>
                        </button>
                    {
                        (record.status !== 'APPROVED' && record.status !== 'REJECT') &&
                        <button className="btn p-0"
                                onClick={async () => {
                                    browserStore.dataRequestChangeStatus = {
                                        ids: [record.id],
                                        status: "APPROVED"
                                    }
                                    await browserStore.changeStatusRequest()
                                }}>
                            <Tooltip title={'Duyệt'}>
                                <img src="/assets/ico/action/check-circle.svg"
                                     className="icon-action"
                                     alt="" data-toggle="tooltip" data-placement="top"
                                     title="Duyệt"/>
                            </Tooltip>
                        </button>
                    }
                    {
                        (record.status !== 'APPROVED' && record.status !== 'REJECT') &&
                        <button className="btn p-0"
                                onClick={async () => {
                                    browserStore.dataRequestChangeStatus = {
                                        ids: [record.id],
                                        status: "REJECT"
                                    }
                                    await browserStore.changeStatusRequest()
                                }}>
                            <Tooltip title='Hủy'>
                                <img src="/assets/ico/action/x-circle.svg"
                                     className="icon-action"
                                     alt="" data-toggle="tooltip" data-placement="top"
                                     title="Hủy"/>
                            </Tooltip>
                        </button>
                    }
                </div>
            ),
        },
    ];
    useEffect(() => {
        browserStore.getImpExpRequest().then()
    }, [])
    const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
        browserStore.listSelectRowRequest = newSelectedRowKeys;
    };
    return (
        <>
            <div className='browser_page'>
                <div className='tab_filter_header d-flex justify-content-end align-items-center'>
                    <FilterComponent
                        data={dataFilter}
                    />
                </div>
                <CustomTable
                    isLoading={browserStore.isLoading}
                    subtractHeight={400}
                    columns={columnCosts}
                    data={browserStore.listImportExportRequest}
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: browserStore.listSelectRowRequest,
                        onChange: onSelectChange,
                    }}
                    scrollX={1000}
                />
            </div>
            <PopupImpExpDetail />
            <ModalDetailRequestPurchase />
        </>
    );
}

export default observer(BrowserPage);