import {getRequest, IApiResponse, deleteRequest, putRequest, postRequest} from "../../../common/helpers/RequestHelper";
import {businessPlanStore} from "./BusinessPlanStore";

class BusinessPlanService{
    public getBusinessPlan(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/business_plans${businessPlanStore.searchTypePlan ? `?state=${businessPlanStore.searchTypePlan}` : ''}`)
    }
    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/business_plans/${id}`)
    }
    public add(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/business_plans`, data)
    }
    public edit(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/business_plans/${id}`, data)
    }
    public delete(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/business_plans/${id}`, {})
    }
    public changeStatusPlan(id: any, state: any, data?: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/business_plans/${id}/change_state?state=${state}`, data)
    }
    //---------------JOB-------------------------------
    public getJobPlan(): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/jobs${localStorage.getItem('idPlan') ? `?businessPlanId=${Number(localStorage.getItem('idPlan'))}`: ''}${businessPlanStore.searchTypeJob ? (localStorage.getItem('idPlan') ? `&state=${businessPlanStore.searchTypeJob}` : `?state=${businessPlanStore.searchTypeJob}`) : ''}`)
    }
    public getDetailJob(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/jobs/${id}`)
    }
    public addJob(data: any): Promise<IApiResponse>{
        return postRequest(`/iss_365_fnb/jobs`, data)
    }
    public editJob(id: number, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/jobs/${id}`, data)
    }
    public deleteJobPlan(id: number): Promise<IApiResponse>{
        return deleteRequest(`/iss_365_fnb/jobs/${id}`, {})
    }
    public updateContentJob(id: any, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/jobs/${id}/work_result`, data)
    }
    public changeStatusJob(id: any, state: any, data?: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/jobs/${id}/change_state?state=${state}`, data)
    }

//    ----------------------REQUIREMENT------------------------
    public addRequirement(id: any, data: any): Promise<IApiResponse>{
        return putRequest(`/iss_365_fnb/jobs/${id}/add_requirement`, data)
    }
}

export const businessPlanService = new BusinessPlanService()