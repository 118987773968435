import React, {useEffect} from 'react';
import './RevenuePage.scss'
import {observer} from "mobx-react";
import FilterComponent from "../../../modules/components/filterGlobal/FilterGlobal";
import CustomTable from "../../../modules/components/tableGlobal/CustomTable";
import {numberUtil} from "../../../common/utils/NumberUtil";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {costStore} from "../costPage/CostStore";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import ModalFilter from "../costPage/components/modalFilter/ModalFilter";
const RevenuePage = () => {
    useEffect(() => {
        costStore.searchYear = new Date().getFullYear().toString()
        costStore.getListRestaurant().then(() => {
            if(!costStore.searchEstablishmentId) {
                costStore.searchEstablishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
            }
            if(!costStore.establishmentName) {
                costStore.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
            }
            costStore.getListRevenue().then()
        })
    }, [])
    const handleSearchYearChange = async (date: any, dateString: any) => {
        costStore.searchYear = dateString || new Date().getFullYear().toString()
        await costStore.getListRevenue()
    };
    const sumRevenue = (record: any) => {
        let result = 0
        for(let i = 0; i <= 11; i++) {
            if(record[`cost_${i + 1}`])
                result += record[`cost_${i + 1}`]
        }
        return result
    }
    const columnRevenues: any = [
        {
            title: "STT",
            align: "center",
            width: 80,
            render: (_: any, __ : any, index: any) => index + 1,
            fixed: "left",
        },
        {
            title: "Điểm bán",
            dataIndex: "address",
            key: "address",
            align: "left",
            fixed: "left",
            width: 200,
        },
        {
            title: "Mã điểm bán",
            dataIndex: "code",
            key: "code",
            align: "center",
            fixed: "left",
            width: 150,
        },
        {
            title: "Tổng tiền (VNĐ)",
            dataIndex: "sumRevenue",
            key: "sumRevenue",
            align: "right",
            fixed: "left",
            width: 150,
            render: (value: any, record: any) =>  <span>{numberUtil.number_format(sumRevenue(record))}</span>
        },
        {
            title: "Nguồn",
            dataIndex: "resource",
            key: "resource",
            align: "center",
            width: 200,
        },
        {
            title: 'Doanh thu tháng (VNĐ)',
            children: [
                {
                    title: "Tháng 1",
                    dataIndex: "cost_1",
                    key: "cost_1",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 2",
                    dataIndex: "cost_2",
                    key: "cost_2",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 3",
                    dataIndex: "cost_3",
                    key: "cost_3",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 4",
                    dataIndex: "cost_4",
                    key: "cost_4",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 5",
                    dataIndex: "cost_5",
                    key: "cost_5",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 6",
                    dataIndex: "cost_6",
                    key: "cost_6",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 7",
                    dataIndex: "cost_7",
                    key: "cost_7",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 8",
                    dataIndex: "cost_8",
                    key: "cost_8",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 9",
                    dataIndex: "cost_9",
                    key: "cost_9",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 10",
                    dataIndex: "cost_10",
                    key: "cost_10",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 11",
                    dataIndex: "cost_11",
                    key: "cost_11",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
                {
                    title: "Tháng 12",
                    dataIndex: "cost_12",
                    key: "cost_12",
                    align: "right",
                    width: 150,
                    render: (value: any) => (value === 0 || value) ?  numberUtil.number_format(value) : '-'
                },
            ],
        },
    ];
    return (
        <>
            <div className='finance_revenue_container'>
                <div className='finance_container__header'>
                    <div className='d-flex w-100 justify-content-between align-items-center'>
                        <TabRedirect tab='Doanh thu' />
                        <FilterComponent
                            isSearchYear
                            searchYear={<DatePicker
                                picker="year"
                                placeholder="Năm"
                                value={costStore.searchYear ? dayjs(costStore.searchYear, 'YYYY') as any : null}
                                onChange={handleSearchYearChange}
                                bordered={false}
                            />}
                        />
                    </div>
                </div>
            </div>
            <CustomTable
                subtractHeight={1000}
                isRowSelection={false}
                columns={columnRevenues}
                isLoading={costStore.isLoading}
                data={costStore.dataColumnRevenue}
                scrollX={500}
            />
            <ModalFilter />
        </>
    );
}

export default observer(RevenuePage);