import React, {useRef} from 'react';
import {observer} from "mobx-react";
import './ModalAddEditDepartment.scss'
import {Input, Spin} from "antd";
import {departmentStore} from "../../DepartmentStore";
const ModalAddEditDepartment = () => {
    const modalRef = useRef(null);
    return (
        <>
            <div className='modal fade' id='add_edit_department' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal-header'>
                            <div className='value_voucher__title'>{departmentStore.typeModal === 'add' ? 'THÊM' : 'CẬP NHẬT'} PHÒNG BAN</div>
                            <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className='w-100 form-group'>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Tên phòng ban <span className='text-danger'>*</span></label>
                                    <Input type='text' value={departmentStore.dataRequest?.name}
                                           status={(departmentStore.errors?.name && !departmentStore.dataRequest.name) ? "error" : ""}
                                           onChange={(e: any) => {
                                               departmentStore.dataRequest.name = e.target.value
                                           }}
                                           onKeyPress={async (e) => {
                                               if (e.key === "Enter") {
                                                       (modalRef.current as unknown as HTMLButtonElement).click();
                                               }
                                           }}
                                           placeholder='Nhập...' />
                                    {(departmentStore.errors?.name && !departmentStore.dataRequest.name) && <small className="text-error">{departmentStore.errors?.name}</small>}
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Mã phòng ban <span className='text-danger'>*</span></label>
                                    <Input type='text' value={departmentStore.dataRequest?.code}
                                           status={(departmentStore.errors?.code && !departmentStore.dataRequest.code) ? "error" : ""}
                                           onChange={(e: any) => {
                                               departmentStore.dataRequest.code = e.target.value
                                           }}
                                           onKeyPress={async (e) => {
                                               if (e.key === "Enter") {
                                                   (modalRef.current as unknown as HTMLButtonElement).click();
                                               }
                                           }}
                                           placeholder='Nhập...' />
                                    {(departmentStore.errors?.code && !departmentStore.dataRequest.code) && <small className="text-error">{departmentStore.errors?.code}</small>}
                                </div>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-end mt-4'>
                                        <button type='submit' className='w-25 btn btn__continue'  ref={modalRef}
                                                onClick={async () => {await departmentStore.addEditDepartment()}}>
                                            {
                                                departmentStore.isLoadingBt ?  <Spin size="small" /> : "Hoàn thành"
                                            }
                                        </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default observer(ModalAddEditDepartment);

