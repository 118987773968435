import React from 'react';
import {observer} from "mobx-react";
import './SelectRestaurant.scss'
import {orderStore} from "../../OrderStore";
import {Link} from "react-router-dom";
import $ from "jquery";
import {kitchenStore} from "../../../kitchenManager/KitchenStore";
import {inventoryReportStore} from "../../../warehouses/inventoryReport/InventoryReportStore";
const ModalSelectRestaurant = () => {
    const urlObject = new URL(window.location.href);
    const baseURL = `${urlObject.protocol}//${urlObject.host}`;
    return (
        <>
            <div className='modal fade' id='select_restaurant_order' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal_select_service__container'>
                            <h1 className='modal_select_service__title'>
                                Chọn điểm bán
                            </h1>
                            {orderStore.listRestaurant.length > 0 ? <div className='modal_select_service__cardContainer'>
                                {
                                    orderStore.listRestaurant.map((item: any, index: number) => {
                                        return(
                                            <button
                                                key={index}
                                                onClick={async () => {
                                                    orderStore.dataRequest.establishmentId = item?.value
                                                    kitchenStore.searchEstablishmentId = item?.value
                                                    inventoryReportStore.searchEstablishmentId = item?.value
                                                    orderStore.dataRequest.establishmentName = item?.label
                                                    kitchenStore.establishmentName = item?.label
                                                    inventoryReportStore.establishmentName = item?.label
                                                    localStorage.setItem('dataOrder' , JSON.stringify(orderStore.dataRequest))
                                                    orderStore.page = 0
                                                    orderStore.listOrders = []
                                                    orderStore.isFetching = false
                                                    orderStore.hasMore = true
                                                    if(window.location.pathname === '/bep'){
                                                        await  kitchenStore.getKitchen().then()
                                                    }
                                                    else if(window.location.pathname === '/inventory_report') {
                                                        await  inventoryReportStore.getInventoryReport().then()
                                                    }
                                                    else await orderStore.getListOrder().then()
                                                }}
                                                data-bs-dismiss="modal"  className='modal_select_service__card'>
                                                <div className='modal_select_service__cardTitle'>
                                                    {item?.label}
                                                </div>
                                            </button>
                                        )
                                    })
                                }
                            </div> : <div className='no_esteblistment w-100 custom-scrollbar'>
                            <img src="/assets/ico/no_supplies.svg" alt=""/>
                            <span className='no_esteblistment__title'>Hiện tại bạn chưa có điểm bán!</span>
                            <Link onClick={() => {
                                $('#select_restaurant_order').trigger('click')
                            }
                            } className='link_create__esteblistment' to={`${baseURL}/thiet-lap/danh-sach-diem-ban`}>Tạo điểm bán mới</Link>
                        </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
)
}

export default observer(ModalSelectRestaurant);

