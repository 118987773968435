
export default class Constants {
    public static TOKEN_EXPIRE_DAYS: number = 16;
    public static TOKEN_NAME: string = "token";
    public static REFRESH_TOKEN: string = "refreshToken";
    public static API_URL: String = (window as any).API_DOMAIN;
}
export const listMenu = [
    {
        icon : '/assets/ico/menu/baocao.svg',
        text: 'Báo cáo',
        module: 'bao_cao',
        link: '/',
        title: 'Báo cáo'
    },
    {
        icon : '/assets/ico/menu/m_sale.svg',
        text: 'M-Sale',
        module: 'mar_sale',
        link: '/tong-quan',
        title: 'Sale - Marketing'
    },
    {
        icon : '/assets/ico/menu/vanchuyen.svg',
        text: 'Vận chuyển',
        module: 'mar_sale',
        link: '/van-chuyen',
        title: 'Vận chuyển'
    },
    {
        icon : '/assets/ico/menu/nhansu.svg',
        text: 'Nhân sự',
        module: 'staff',
        link: '/nhan-su',
        title: 'Nhân sự'
    },
    {
        icon : '/assets/ico/menu/taichinh.svg',
        text: 'Tài chính',
        module: 'tai_chinh',
        link: '/tai-chinh',
        title: 'Tài chính'
    },
    {
        icon : '/assets/ico/menu/cskh.svg',
        text: 'CSKH',
        module: 'cskh',
        link: '/du-lieu-khach-hang',
        title: 'Chăm sóc khách hàng'
    },
    {
        icon : '/assets/ico/menu/kho.svg',
        text: 'Kho',
        module: 'batch',
        link: '/purchase',
        title: 'Quản lý kho'
    },
    {
        icon : '/assets/ico/menu/qlcn.svg',
        text: 'QLCN',
        module: 'mar_sale',
        link: '/quan-ly-ca-nhan',
        title: 'Quản lý cá nhân'
    },
    {
        icon : '/assets/ico/menu/bep.svg',
        text: 'Bếp',
        module: 'bep',
        link: '/bep',
        title: 'Bếp'
    },
    {
        icon : '/assets/ico/menu/chuoi.svg',
        text: 'Chuỗi',
        module: 'mar_sale',
        link: '/quan-ly-chuoi',
        title: 'Quản lý chuỗi'
    },
    {
        icon : '/assets/ico/menu/dulieu.svg',
        text: 'Dữ liệu',
        module: 'data',
        link: '/data',
        title: 'Dữ liệu'
    },
    {
        icon : '/assets/ico/menu/check.svg',
        text: 'Kiểm tra',
        module: 'check',
        link: '/kiem-tra/truoc-che-bien',
        title: 'Kiểm tra'
    },
    {
        icon : '/assets/ico/menu/order.svg',
        text: 'Phục vụ',
        module: 'order',
        link: '/phuc-vu',
        title: 'Phục vụ'
    },
    {
        icon : '/assets/ico/menu/check_bill.svg',
        text: 'Check đơn',
        module: 'check_order',
        link: '/check-out',
        title: 'Check đơn'
    },
    {
        icon : '/assets/ico/menu/browse.svg',
        text: 'Duyệt',
        module: 'browse',
        link: '/duyet',
        title: 'Duyệt'
    },
    {
        icon : '/assets/ico/menu/setting.svg',
        text: 'Thiết lập',
        module: 'setting',
        link: '/setup/account',
        title: 'Thiết lập'
    },
]
