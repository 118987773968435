import React, {useEffect} from 'react';
import './FormRndResearch.scss'
import {observer} from "mobx-react";
import {ConfigProvider, DatePicker, Spin, Tooltip} from "antd";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {Moment} from "moment";
import {DatePickerProps} from "antd/es/date-picker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {getIDParameter} from "../../../../../common/utils/Utils";
import {Link} from "react-router-dom";
import {rndResearchStore} from "../../RndResearchStore";
import {marketResearchStore} from "../../../marketResearch/MarketResearchStore";

const FormRndResearch = () => {
    const {RangePicker} = DatePicker;
    function convertTime(date: Moment): number | null {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChange = (
        value: DatePickerProps['value'],
    ) => {
        // @ts-ignore
        rndResearchStore.dataRequestRndResearch.reportingTime = convertTime(value);
    };
    dayjs.extend(customParseFormat);
    const handleRangePickerChange = (dates: any) => {
        if (dates && dates.length > 0) {
            rndResearchStore.dataRequestRndResearch.researchTimeFrom = convertTime(dates[0]);
            rndResearchStore.dataRequestRndResearch.researchTimeTo = convertTime(dates[1]);
        }
    }
    useEffect(() => {
        if(getIDParameter(4)) {
            rndResearchStore.typeForm = 'edit'
            rndResearchStore.getDetail(getIDParameter(4)).then()
            rndResearchStore.id = getIDParameter(4)
        }
    }, [])
    return (
        <>
            <div className='create_rnd_research__container'>
                <Tooltip title='Quay lại' placement='right'>
                    <Link to='/nghien-cuu-thi-truong/bao-cao-rnd' className='create_market_research__back'><img src="/assets/ico/action/move-left.svg" alt=""/></Link>
                </Tooltip>
                <div>
                    <div className='create_rnd_research__title'>
                        <p>
                            BÁO CÁO NGHIÊN CỨU VÀ PHÁT TRIỂN SẢN PHẨM
                        </p>
                        {rndResearchStore.statusResearch === "PENDING" ? <span className='create_rnd_research__status--pending'>Chờ duyệt</span> : <span className='create_rnd_research__status'>Nháp</span>}
                    </div>
                    <span className='device_star'>
                        *********
                    </span>
                </div>
                <div className='data_header_research'>
                    <div className='data_header_research__wrap'>
                        <span className="data_header_research__wrap--key">Người thực hiện : </span><span className="fw-500" style={{fontSize: '14px'}}>{rndResearchStore.dataRequestRndResearch.reportUser}</span>
                    </div>
                    <div className='data_header_research__wrap'>
                        <span className="data_header_research__wrap--key">Thời gian báo cáo : <span className='text-danger'>*</span> : </span>
                        <div className='w-25'>
                            <ConfigProvider locale={viVI}>
                                <DatePicker
                                    value={!rndResearchStore.dataRequestRndResearch.reportingTime ? null : dayjs( rndResearchStore.dataRequestRndResearch.reportingTime)}
                                    placeholder='Chọn'
                                    status={(!rndResearchStore.dataRequestRndResearch.reportingTime  && rndResearchStore.errors.reportingTime) ? 'error': ''}
                                    format={"DD/MM/YYYY"} onChange={onChange}/>
                            </ConfigProvider>
                        </div>
                    </div>
                    <div className='data_header_research__wrap'>
                        <span className="data_header_research__wrap--key">Thời gian nghiên cứu : <span className='text-danger'>*</span> : </span>
                        <div className='w-50'>
                            <RangePicker
                                placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                onChange={handleRangePickerChange}
                                format={"DD/MM/YYYY"}
                                status={!rndResearchStore.dataRequestRndResearch.researchTimeFrom && !rndResearchStore.dataRequestRndResearch.researchTimeTo &&  rndResearchStore.errors.researchTimeFrom ? 'error': ''}

                                value={[
                                    rndResearchStore.dataRequestRndResearch.researchTimeFrom === "" ? null : dayjs( rndResearchStore.dataRequestRndResearch.researchTimeFrom),
                                    rndResearchStore.dataRequestRndResearch.researchTimeTo === "" ? null : dayjs(rndResearchStore.dataRequestRndResearch.researchTimeTo)
                                ] as any}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='title_table__research'>I. Báo cáo chung</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listCommonRndResearch.push({
                                    title : "",
                                    content: "",
                                    default: false
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive mb-3 d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Đầu mục</th>
                            <th className="text-center">Nội dung</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listCommonRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td className="text-start">
                                        {
                                            item?.default ? <span>{item.title}</span> :
                                                <input type="text" value={item.title} onChange={(e: any) => item.title = e.currentTarget.value} placeholder='Nhập chữ...' className='form-control'/>
                                        }
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.content}  onChange={(e: any) => item.content = e.currentTarget.value} placeholder='Nhập chữ...' className='form-control'/>
                                        {(i !== 0 && !item.default) && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listCommonRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <p className='title_table__research'>II. Kết quả nghiên cứu</p>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='subtitle_table__research'>1. Đánh giá sản phẩm</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listVoteProductRndResearch.push({
                                    criteria : "",
                                    result: "",
                                    vote: "",
                                    default: false
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tiêu chí</th>
                            <th className="text-center">Kết quả</th>
                            <th className="text-center">Chấm điểm</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listVoteProductRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td className="text-start">
                                        {
                                            item?.default ? <span>{item.criteria}</span> :
                                                <input type="text" value={item.criteria}  onChange={(e: any) => item.criteria = e.currentTarget.value} placeholder='Nhập chữ...' className='form-control'/>
                                        }
                                    </td>
                                    <td className="text-start">
                                        <input type="text" value={item.result}  onChange={(e: any) => item.result = e.currentTarget.value} placeholder='Nhập chữ...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.vote}  onChange={(e: any) => item.vote = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                        {(i !== 0 && !item.default) && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            disabled={i === 0}
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listVoteProductRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='subtitle_table__research'>2. Đánh giá thị trường</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listMarketRndResearch.push({
                                    criteria: "",
                                    option1: "",
                                    option2: "",
                                    default: false
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tiêu chí so sánh</th>
                            <th className="text-center">Tùy chọn B</th>
                            <th className="text-center">Tùy chọn C</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listMarketRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td  className="text-start">
                                        {
                                            item?.default ? <span>{item.criteria}</span> :
                                                <input type="text" value={item.criteria}  onChange={(e: any) => item.criteria = e.currentTarget.value} placeholder='Nhập chữ...' className='form-control'/>
                                        }
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.option1}  onChange={(e: any) => item.option1 = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.option2}  onChange={(e: any) => item.option2 = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                        {(i !== 0 && !item.default) && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listMarketRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <p className='title_table__research'>Kết luận</p>
                <textarea value={rndResearchStore.overallFirstConclusionRndResearch} onChange={(e) => {rndResearchStore.overallFirstConclusionRndResearch = e.currentTarget.value}} placeholder="Nhập kết luận ..." className='form-control w-100  mb-3' style={{minHeight: '100px'}}></textarea>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='subtitle_table__research'>3. Đề xuất phát triển sản phẩm</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listSuggestProductRndResearch.push({
                                    stage: "",
                                    action: "",
                                    performer: "",
                                    coordinator: "",
                                    time: ""
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Giai đoạn</th>
                            <th className="text-center">Hành động</th>
                            <th className="text-center">Người thực hiện</th>
                            <th className="text-center">Người phụ trách</th>
                            <th className="text-center">Thời gian</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listSuggestProductRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td  className="text-center">
                                        <input type="text" value={item?.stage}  onChange={(e: any) => item.stage = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" onChange={(e: any) => item.action = e.currentTarget.value} value={item?.action} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" onChange={(e: any) => item.performer = e.currentTarget.value} value={item?.performer} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" onChange={(e: any) => item.coordinator = e.currentTarget.value} value={item?.coordinator} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.time} onChange={(e: any) => item.time = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                        {i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listSuggestProductRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='subtitle_table__research'>4. Chiến lược marketing</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listStragyMarketingRndResearch.push({
                                    marketingActive : "",
                                    mediaChanel : "",
                                    target: ""
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Hoạt động marketing</th>
                            <th className="text-center">Kênh truyền thông</th>
                            <th className="text-center">Mục tiêu</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listStragyMarketingRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td  className="text-center">
                                        <input type="text" value={item?.marketingActive} onChange={(e: any) => item.marketingActive = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td  className="text-center">
                                        <input type="text" value={item?.mediaChanel} onChange={(e: any) => item.mediaChanel = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.target} onChange={(e: any) => item.target = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                        { i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listStragyMarketingRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='subtitle_table__research'>5. Hiệu quả mục tiêu</p>
                </div>
                <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Tiêu chí</th>
                            <th className="text-center">Kết quả</th>
                            <th className="text-center">Chấm điểm</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listTargetEfficiencyRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td  className="text-start">
                                        {item?.criteria}
                                    </td>
                                    <td  className="text-center">
                                        <input type="text" value={item?.result} onChange={(e: any) => item.result = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item?.vote} onChange={(e: any) => item.vote = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <p className='title_table__research'>KẾT LUẬN TỔNG THỂ</p>
                <textarea value={rndResearchStore.overallConclusionRndResearch} onChange={(e) => {rndResearchStore.overallConclusionRndResearch = e.currentTarget.value}} placeholder="Nhập kết luận ..." className='form-control w-100  mb-3' style={{minHeight: '150px'}}></textarea>
                <div style={{maxWidth: '50%'}} className='d-flex justify-content-end align-items-center mb-2'>
                    <button className="button_add_form_table"
                            onClick={() => {
                                rndResearchStore.listDocumentRndResearch.push({
                                    name: ""
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div style={{maxWidth: '50%'}} className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Danh mục tài liệu tham khảo</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rndResearchStore.listDocumentRndResearch.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td className="text-center">
                                        <input type="text" value={item.name} onChange={(e: any) => item.name = (e.currentTarget.value)} placeholder='Nhập...' className='form-control'/>
                                        {i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                rndResearchStore.listDocumentRndResearch.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <button onClick={async () => {
                    await rndResearchStore.addRndResearch()
                }} className="button_save_research">
                    {
                        rndResearchStore.isLoadingBt ?  <Spin size="small" /> : rndResearchStore.typeForm === 'add' ? 'Hoàn thành' : 'Cập nhật'
                    }
                </button>
            </div>
        </>
    );
}

export default observer(FormRndResearch);