import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {dateUtils} from "../../../common/utils/DateUtils";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {Link} from "react-router-dom";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {orderShipStore} from "./OrderShipStore";
import "./OrderStyle.scss"
import {urlImage} from "../../../common/utils/Utils";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";


const OrderShipPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  orderShipStore.searchStatusOrder,
            placeholder: 'Trạng thái',
            listValue: [{
                value: "PENDING",
                label : "Đang chờ"
            },{
                value: "DELIVERY_CONFIRMATION",
                label : "Đang giao"
            },{
                value: "DELIVERED",
                label : "Đã giao"
            },{
                value: "CANCEL",
                label : "Đã hủy"
            }],
            callback: async (e: any) => {
                orderShipStore.searchStatusOrder = e
                await orderShipStore.getOrderShip().then()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm theo mã đơn',
            value: orderShipStore.searchKeywordOrder,
            onChange: (e: any) => {
                orderShipStore.searchKeywordOrder = e
            },
            callback: async () => {
                await orderShipStore.getOrderShip().then()
            }
        },
    ]
    const statusOrder = (value: string) => {
        switch (value) {
            case 'PENDING':
                return <span className="color-pending">Chờ xác nhận</span>
            case 'DELIVERY_CONFIRMATION':
                return <span className="color-purple">Đang giao</span>
            case 'DELIVERED':
                return <span className="color-green">Đã giao</span>
            case 'CANCEL':
                return <span className="color-red">Đã hủy</span>
            default:
                return null
        }
    }

    const statusOrderShop = (value: string) => {
        switch (value) {
            case 'PENDING':
                return 'Đơn hàng đang được chờ xác nhận'
            case 'DELIVERY_CONFIRMATION':
                return 'Đơn hàng đang được vận chuyển'
            case 'DELIVERED':
                return 'Đơn hàng đã được hoàn thành'
            default:
                return 'Đơn hàng đã được hủy'
        }
    }

    useEffect(() => {
        orderShipStore.getOrderShip().then()
    }, [])

    return (
        <div className="order_ship">
            <div  className='w-100'>
                <FilterComponent
                    data={dataFilter}
                />
            </div>
            <div className="w-100 header_top d-flex justify-content-between">
                <div className="item_ w_1_6">
                    Sản phẩm
                </div>
                <div className="item_ w_1_6">
                    Tổng đơn hàng
                </div>
                <div className="item_ w_1_3">
                    Địa chỉ giao hàng
                </div>
                <div className="item_ w_1_6">
                    Trạng thái
                </div>
                <div className="item_ w_1_6">
                    Người vận chuyển
                </div>
                <div className="item_ w_1_6">
                    Thao tác
                </div>
            </div>
            {orderShipStore.isLoading ? <Loading/> : orderShipStore.listOrderShip && orderShipStore.listOrderShip.length > 0 ? orderShipStore.listOrderShip.map((item: any, index: number) => (
                <div key={index} className="w-100 item_order d-flex flex-column">
                    <div className="w-100 info_shop">
                        <div className="d-flex align-items-center">
                            <div className="name">
                                {item.customer?.name}
                            </div>
                            <div className="phone">
                                {item.customer?.phone}
                            </div>
                        </div>
                        <div className="left">
                            <div className="code">Mã đơn : <span className="fw-500 ms-2">{item.code}</span></div> <span className="time">{dateUtils.formatDate(item.createdAt, 'hh:mm, DD/MM/YYYY')}</span>
                        </div>
                    </div>

                    <div className="w-100 item_product d-flex justify-content-between">
                        <div className="item_product__container d-flex justify-content-start align-items-center w_1_6">
                                <img  width={36} height={36}
                                      alt="image_product"
                                      className='object-fit-cover'
                                      src={urlImage(item.order?.items?.[0]?.product?.images?.[0])}
                                />
                                <span className="ms-2">
                                    {item.order?.items?.[0]?.product?.name}
                                    <span className={'fw-500 ms-2'}>x{item.order?.items?.[0]?.quantity}</span>
                                </span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center fw-500 w_1_6">
                            {numberUtil.number_format(item?.order?.totalPayment)}
                            <span className="text-decoration-underline ms-1">đ</span>
                        </div>
                        <div className="address_receiver d-flex flex-column justify-content-center align-items-start w_1_3">
                            {item?.receiver?.phone && <div className="mb-1">
                                {item?.receiver?.name} |{' '}
                                <span className='fw-500'>{item?.receiver?.phone}</span>
                            </div>
                            }
                            <div>
                                {item?.receivingAddress?.detail ? `${item?.receivingAddress?.detail}, ` : ''}
                                {item?.receivingAddress?.ward?.name ? `${item?.receivingAddress?.ward?.name}, ` : ''}
                                {item?.receivingAddress?.district?.name ? `${item?.receivingAddress?.district?.name}, ` : ''}
                                {item?.receivingAddress?.province?.name ? `${item?.receivingAddress?.province?.name}` : ''}
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start w_1_6">
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                <div className="text-state__order">
                                    {statusOrder(item.state)}
                                </div>
                            </div>

                            <div className="text-state__order--sub">
                                {statusOrderShop(item.state)}
                            </div>
                        </div>
                        <div className="address_receiver d-flex justify-content-center align-items-center w_1_6">
                            {item?.driver?.name}
                        </div>
                        <div className="detail_order__btn d-flex justify-content-center align-items-center w_1_6">
                            <Link to={`/van-chuyen/${item?.id}`} onClick={() => orderShipStore.id = item.id} className="d-flex justify-content-center align-items-center">
                                <img src="/assets/ico/ico_detail_order.svg" width={24} height={24} className="me-1" alt="icon-action"/>
                                Xem chi tiết
                            </Link>
                        </div>
                    </div>
                </div>
            )) : <NoContent/>}
        </div>
    );

}

export default observer(OrderShipPage);