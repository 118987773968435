import React, {useEffect} from 'react';
import './FormTradeMarkPlan.scss'
import {observer} from "mobx-react";
import {trademarkStore} from "../../TrademarkStore";
import {Link} from "react-router-dom";
import {ConfigProvider, DatePicker, Spin, Tooltip} from "antd";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {Moment} from "moment/moment";
import {DatePickerProps} from "antd/es/date-picker";
import {getIDParameter} from "../../../../../common/utils/Utils";

const FormTradeMarkPlan= () => {
    function convertTime(date: Moment): number | null {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChange = (
        value: DatePickerProps['value'],
    ) => {
        // @ts-ignore
        trademarkStore.reportingTime = convertTime(value);
    };
    useEffect(() => {
        if(getIDParameter(3)) {
            trademarkStore.typeForm = 'edit'
            trademarkStore.getDetail(getIDParameter(3)).then()
            trademarkStore.id = getIDParameter(3)
        }
    }, [])
    return (
        <>
            <div className='create_trade_research__container'>
                <Tooltip title='Quay lại' placement='right'>
                    <Link to='/thuong-hieu' className='create_market_research__back'><img src="/assets/ico/action/move-left.svg" alt=""/></Link>
                </Tooltip>
                <div>
                    <div className='create_trade_research__title'>
                        <p>
                            KẾ HOẠCH THƯƠNG HIỆU
                        </p>
                        {trademarkStore.statusResearch === "PENDING" ? <span className='create_trade_research__status--pending'>Chờ duyệt</span> : <span className='create_trade_research__status'>Nháp</span>}
                    </div>
                    <span className='device_star'>
                        *********
                    </span>
                    <p className='title_table__research'>I. Tổng quan</p>
                    <div className='form-group'>
                        <label className='form-label'>Thời gian thực hiện:</label>
                        <div className='w-100'>
                            <ConfigProvider locale={viVI}>
                                <DatePicker
                                    value={!trademarkStore.reportingTime ? null : dayjs(trademarkStore.reportingTime)}
                                    placeholder='Chọn'
                                    format={"DD/MM/YYYY"} onChange={onChange}/>
                            </ConfigProvider>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Tóm tắt hiện trạng thương hiệu:</label>
                        <textarea value={trademarkStore.summaryCurrentBrandStatus} onChange={(e) => {trademarkStore.summaryCurrentBrandStatus = e.currentTarget.value}} placeholder="Nhập text ..." className='form-control w-100  mb-3' style={{minHeight: '50px'}}></textarea>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Mục tiêu phát triển thương hiệu:</label>
                        <textarea value={trademarkStore.goalBrandDevelop} onChange={(e) => {trademarkStore.goalBrandDevelop = e.currentTarget.value}} placeholder="Nhập text ..." className='form-control w-100  mb-3' style={{minHeight: '50px'}}></textarea>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Tuyên truyền giá trị thương hiệu:</label>
                        <textarea value={trademarkStore.propagandaBrandValue} onChange={(e) => {trademarkStore.propagandaBrandValue = e.currentTarget.value}} placeholder="Xác định giá trị cốt lõi của thương hiệu và sự khác biệt so với đối thủ, thông điệp, vị trí thương hiệu..." className='form-control w-100  mb-3' style={{minHeight: '50px'}}></textarea>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Chiến lược phát triển thương hiệu:</label>
                        <textarea value={trademarkStore.summaryStrategy} onChange={(e) => {trademarkStore.summaryStrategy = e.currentTarget.value}} placeholder="Nhập text ..." className='form-control w-100  mb-3' style={{minHeight: '50px'}}></textarea>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <p className='title_table__research'>II. Chiến lược phát triển thương hiệu</p>
                    <button className="button_add_form_table"
                            onClick={() => {
                                trademarkStore.listStrategyTrademark.push({
                                    targetDevelopment: "",
                                    action: "",
                                    chanel: "",
                                    target: "",
                                    object: "",
                                    qty: "",
                                    unit: "",
                                    performer: "",
                                    time: "",
                                })
                            }}>
                        <img src="/assets/ico/action/add_green_icon.svg"
                             alt=""/>
                        Thêm
                    </button>
                </div>
                <div className="table-form-responsive mb-3 d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">STT</th>
                            <th className="text-center">Mục tiêu phát triển thương hiệu</th>
                            <th className="text-center">Công việc</th>
                            <th className="text-center">Kênh</th>
                            <th className="text-center">Mục tiêu</th>
                            <th className="text-center">Đối tượng</th>
                            <th className="text-center">Định lượng</th>
                            <th className="text-center">Đơn vị</th>
                            <th className="text-center">Người thực hiện</th>
                            <th className="text-center">Thời gian</th>
                        </tr>
                        </thead>
                        <tbody>
                        { trademarkStore.listStrategyTrademark.map((item: any, i: number) => {
                            return (
                                <tr key={i} className='position-relative'>
                                    <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                    <td style={{width: '400px'}} className="text-center">
                                        <input type="text" value={item.targetDevelopment}  onChange={(e) => {item.targetDevelopment = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.action} onChange={(e) => {item.action = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.chanel} onChange={(e) => {item.chanel = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td style={{width: '250px'}} className="text-center">
                                        <input type="text" value={item.target} onChange={(e) => {item.target = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.object} onChange={(e) => {item.object = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.qty} onChange={(e) => {item.qty = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.unit} onChange={(e) => {item.unit = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.performer} onChange={(e) => {item.performer = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                    <td className="text-center">
                                        <input type="text" value={item.time} onChange={(e) => {item.time = e.currentTarget.value}} placeholder='Nhập...' className='form-control'/>
                                        {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                            data-toggle="modal" data-target="#"
                                                            onClick={() => {
                                                                trademarkStore.listStrategyTrademark.splice(i, 1)
                                                            }}>
                                            <img src="/assets/ico/action/minus-circle.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <button onClick={async () => {
                    await trademarkStore.addEditTradeMark()
                }} className="button_save_research">
                    {
                        trademarkStore.isLoadingBt ?  <Spin size="small" /> : trademarkStore.typeForm === 'add' ? 'Hoàn thành' : 'Cập nhật'
                    }
                </button>
            </div>
        </>
    );
}

export default observer(FormTradeMarkPlan);