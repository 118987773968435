import React from 'react';
import {convertDataSelectAnt} from "../../../common/utils/Utils";
import {Select} from "antd"


interface IProps{
    value?: any,
    onChange: any
    isError?: any,
    options: any[],
    className?: any,
    disabled?: boolean
    placeholder?: string
}


const SelectForm = (item:IProps) => {

    return (
        <>
            <div className="input-group">
                <Select value={item.value} style={{borderRadius: 4}} className={`${(item.isError && !item.value) ? 'border_error' : ''} ${item.className}`}
                        filterOption={(input: string, option: any) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={(e: any, value: any) => item.onChange(e, value)} showSearch placeholder={item.placeholder ?? 'Chọn'}
                        options={convertDataSelectAnt(item.options)}/>
            </div>
            {(item.isError && !item.value) && <p className="error">{item.isError}</p>}
        </>
    );
}

export default SelectForm;