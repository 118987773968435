import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {getRequest} from "../../common/helpers/RequestHelper";

class PersonalManagerStore {
    keyword: any = ''
    state: any = null
    isLoading: boolean = false
    isLoadingBt: boolean = false
    page: number = 0
    totalPage: number = 0
    listData: any[] = []
    dataDetail: any = {
    }
    dataCampaignRequest: any = {
        name: "",
        exp: "",
        mainPerson: null,
        supportPerson: [],
        content: null,
    }
    errorCampaignRequest: any = {
        name: "",
        exp: "",
        mainPerson: "",
        supportPerson: "",
        content: "",
    }
    constructor() {
        makeAutoObservable(this)
    }


    async getData() {
        this.isLoading = true
        const result = await getRequest(`/iss_365_fnb/order/fnb?page=0&size=100${personalManagerStore.keyword ? `&keyword=${this.keyword.trim()}` : ''}${personalManagerStore.state ? `&status=${this.state}` : ''}`)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listData = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: number){
        const result = await getRequest(`/iss_365_fnb/order/${id}`)
        if(result.status === HttpStatusCode.OK){
            this.dataDetail = result.body
        }
    }


}

export const personalManagerStore = new PersonalManagerStore()