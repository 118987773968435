import React, {useEffect} from 'react';
import './FormMarketResearch.scss'
import {Link} from "react-router-dom";
import {marketResearchStore} from "../../MarketResearchStore";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {observer} from "mobx-react";
import {ConfigProvider, DatePicker, Spin, Tooltip} from "antd";
import viVI from "antd/lib/locale/vi_VN";
import dayjs from "dayjs";
import {Moment} from "moment";
import {DatePickerProps} from "antd/es/date-picker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {getIDParameter} from "../../../../../common/utils/Utils";
import Loading from "../../../../../modules/components/Loading";

const FormMarketResearch = () => {
    function convertTime(date: Moment): number | null {
        if(!date) return null
        else return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }

    const onChange = (
        value: DatePickerProps['value'],
    ) => {
        // @ts-ignore
        marketResearchStore.dataRequestMarketResearch.reportingTime = convertTime(value);
    };
    dayjs.extend(customParseFormat);

    const handleRangePickerChange = (dates: any) => {
        if (dates && dates.length > 0) {
            marketResearchStore.dataRequestMarketResearch.researchTimeFrom = convertTime(dates[0]);
            marketResearchStore.dataRequestMarketResearch.researchTimeTo = convertTime(dates[1]);
        }
    }
    const {RangePicker} = DatePicker;
    useEffect(() => {
        if(getIDParameter(4)) {
            marketResearchStore.typeForm = 'edit'
            marketResearchStore.getDetail(getIDParameter(4)).then()
            marketResearchStore.id = getIDParameter(4)
        }
    }, [])
    return (
        <>
            {
                marketResearchStore.isLoading ? <Loading /> :
                    <div className='create_market_research__container'>
                        <Tooltip title='Quay lại' placement='right'>
                            <Link to='/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong' className='create_market_research__back'><img src="/assets/ico/action/move-left.svg" alt=""/></Link>
                        </Tooltip>
                        <div>
                            <div className='create_market_research__title'>
                                <p>
                                    BÁO CÁO HOẠT ĐỘNG NGHIÊN CứU THỊ TRƯỜNG
                                </p>
                                {marketResearchStore.statusResearch === "PENDING" ? <span className='create_market_research__status--pending'>Chờ duyệt</span> : <span className='create_market_research__status'>Nháp</span>}
                            </div>
                            <span className='device_star'>
                        *********
                    </span>
                        </div>
                        <div className='data_header_research'>
                            <div className='data_header_research__wrap'>
                                <span className="data_header_research__wrap--key">Người thực hiện : </span><span className="fw-500" style={{fontSize: '14px'}}>{marketResearchStore.dataRequestMarketResearch.reportUser}</span>
                            </div>
                            <div className='data_header_research__wrap'>
                                <span className="data_header_research__wrap--key">Thời gian báo cáo <span className='text-danger'>*</span> : </span>
                                <div className='w-25'>
                                    <ConfigProvider locale={viVI}>
                                        <DatePicker
                                            value={!marketResearchStore.dataRequestMarketResearch.reportingTime ? null : dayjs( marketResearchStore.dataRequestMarketResearch.reportingTime)}
                                            placeholder='Chọn'
                                            status={(!marketResearchStore.dataRequestMarketResearch.reportingTime  && marketResearchStore.errors.reportingTime) ? 'error': ''}
                                            format={"DD/MM/YYYY"} onChange={onChange}/>
                                    </ConfigProvider>
                                </div>
                            </div>
                            <div className='data_header_research__wrap'>
                                <span className="data_header_research__wrap--key">Thời gian nghiên cứu <span className='text-danger'>*</span> : </span>
                                <div className='w-50'>
                                    <RangePicker
                                        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                        onChange={handleRangePickerChange}
                                        format={"DD/MM/YYYY"}
                                        allowClear
                                        status={!marketResearchStore.dataRequestMarketResearch.researchTimeFrom && ! marketResearchStore.dataRequestMarketResearch.researchTimeTo &&  marketResearchStore.errors.researchTimeFrom ? 'error': ''}
                                        value={[
                                            marketResearchStore.dataRequestMarketResearch.researchTimeFrom === "" ? null : dayjs( marketResearchStore.dataRequestMarketResearch.researchTimeFrom),
                                            marketResearchStore.dataRequestMarketResearch.researchTimeTo === "" ? null : dayjs(marketResearchStore.dataRequestMarketResearch.researchTimeTo)
                                        ] as any}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='title_table__research'>I. Báo cáo chung</p>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listResearchMarketCommon.push({
                                            content: "",
                                            result: ""
                                        })
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div className="table-form-responsive mb-3 d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Thông tin cơ bản</th>
                                    <th className="text-center">Kết quả</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listResearchMarketCommon.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td className="text-center">
                                                <input type="text" value={item.content} onChange={(e: any) => item.content = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" value={item.result} onChange={(e: any) => item.result = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                                {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listResearchMarketCommon.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <p className='title_table__research'>II. Chi tiết nghiên cứu</p>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='subtitle_table__research'>1. Bảng hiển thị kết quả nghiên cứu thị trường  qua khảo sát</p>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listResearchResult.push("")
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Tiêu chí</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listResearchResult.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td className="text-center">
                                                <input type="text" value={item} onChange={(e: any) => {
                                                    marketResearchStore.updateResearchResult(i, e.currentTarget.value)
                                                }} placeholder='Nhập...' className='form-control'/>
                                                {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    disabled={i === 0}
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listResearchResult.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='subtitle_table__research'>2. Bảng nghiên cứu đối thủ cạnh tranh</p>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listCompetitorResearch.push({
                                            criteria : "",
                                            option1: "",
                                            option2: "",
                                        })
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Tiêu chí so sánh</th>
                                    <th className="text-center">Tùy chọn B</th>
                                    <th className="text-center">Tùy chọn C</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listCompetitorResearch.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td  className="text-center">
                                                <input type="text" value={item?.criteria} onChange={(e: any) => item.criteria = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" value={item?.option1} onChange={(e: any) => item.option1 = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" value={item?.option2} onChange={(e: any) => item.option2 = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                                {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listCompetitorResearch.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='subtitle_table__research'>3. Bảng nghiên cứu về giá cả</p>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listPriceResearch.push({
                                            productName : "",
                                            price: "",
                                            vote: "",
                                        })
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Sản phẩm</th>
                                    <th className="text-center">Giá đề xuất</th>
                                    <th className="text-center">Đánh giá về giá (1-5)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listPriceResearch.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td  className="text-center">
                                                <input type="text" value={item?.productName} onChange={(e: any) => item.productName = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" onChange={(e: any) => item.price = numberUtil.regexNumber(e)} value={item?.price ? numberUtil.number_format(item?.price) : ""} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" value={item?.vote} onChange={(e: any) => item.vote = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/>
                                                { i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listPriceResearch.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{maxWidth: '50%'}} className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='subtitle_table__research'>4. Bảng nghiên cứu khách hàng</p>
                        </div>
                        <div style={{maxWidth: '50%'}} className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th style={{width: '200px'}} className="text-center">Tiêu chí</th>
                                    <th className="text-center">Mức độ hài lòng (1-5)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{width: '48px'}} rowSpan={6} className="text-center">1</td>
                                    <td colSpan={2} className="text-center fw-500">Độ tuổi</td>
                                </tr>
                                <tr>
                                    <td className="text-center">Dưới 18</td>
                                    <td className="text-center"><input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByAge?.level1} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByAge.level1 = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">18 - 25</td>
                                    <td className="text-center">
                                        <input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByAge?.level2} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByAge.level2 = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">26 - 35</td>
                                    <td className="text-center"><input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByAge?.level3} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByAge.level3 = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">36 - 45</td>
                                    <td className="text-center"><input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByAge?.level4} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByAge.level4 = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">Trên 45</td>
                                    <td className="text-center"><input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByAge?.level5} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByAge.level5 = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '48px'}} rowSpan={3} className="text-center">2</td>
                                    <td colSpan={2} className="text-center fw-500">Giới tính</td>
                                </tr>
                                <tr>
                                    <td className="text-center">Nam</td>
                                    <td className="text-center"><input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByGender?.male} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByGender.male = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">Nữ</td>
                                    <td className="text-center">
                                        <input type="text" value={marketResearchStore.listCustomerResearch?.satisfactionByGender?.female} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByGender.female = numberUtil.regexVote(e)} placeholder='Nhập...' className='form-control'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: '48px'}} rowSpan={5} className="text-center">3</td>
                                    <td colSpan={2} className="text-center fw-500">Trình độ học vấn</td>
                                </tr>
                                <tr>
                                    <td className="text-center">Tiểu học</td>
                                    <td className="text-center"><input value={marketResearchStore.listCustomerResearch?.satisfactionByEducate?.level1} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByEducate.level1 = numberUtil.regexVote(e)} type="text" placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">THCS - THPT</td>
                                    <td className="text-center"><input value={marketResearchStore.listCustomerResearch?.satisfactionByEducate?.level2} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByEducate.level2 = numberUtil.regexVote(e)} type="text" placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">Cao đẳng & Đại học</td>
                                    <td className="text-center"><input value={marketResearchStore.listCustomerResearch?.satisfactionByEducate?.level3} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByEducate.level3 = numberUtil.regexVote(e)} type="text" placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td className="text-center">Sau đại học</td>
                                    <td className="text-center"><input value={marketResearchStore.listCustomerResearch?.satisfactionByEducate?.level4} onChange={(e: any) => marketResearchStore.listCustomerResearch.satisfactionByEducate.level4 = numberUtil.regexVote(e)} type="text" placeholder='Nhập...' className='form-control'/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '48px'}} rowSpan={marketResearchStore.listCustomerResearch.demand.length + 1} className="text-center">4</td>
                                    <td colSpan={2}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-center fw-500">Nhu cầu khách hàng</span>
                                            <button className="button_add_form_table"
                                                    onClick={() => {
                                                        marketResearchStore.listCustomerResearch.demand.push({
                                                            title : "",
                                                            value: "",
                                                        })
                                                    }}>
                                                <img src="/assets/ico/action/add_green_icon.svg"
                                                     alt=""/>
                                                Thêm
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    marketResearchStore.listCustomerResearch?.demand?.map((item: any, i: number) => {
                                        return (
                                            <tr key={i} className="position-relative">
                                                <td className="text-center">
                                                    <input type="text" value={item?.title} onChange={(e: any) => item.title = e.currentTarget.value}  placeholder='Nhập...' className='form-control'/>
                                                </td>
                                                <td className="text-center">
                                                    <input type="text" value={item?.value} onChange={(e: any) => item.value = numberUtil.regexVote(e)}  placeholder='Nhập...' className='form-control'/>
                                                    {i !== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                        data-toggle="modal" data-target="#"
                                                                        onClick={() => {
                                                                            marketResearchStore.listCustomerResearch?.demand.splice(i, 1)
                                                                        }}>
                                                        <img src="/assets/ico/action/minus-circle.svg"
                                                             className="icon-action"
                                                             alt="" data-toggle="tooltip" data-placement="top"
                                                             title="Xóa"/>
                                                    </button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='subtitle_table__research'>5. Kết quả</p>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listTotalResult.push(        {
                                            content: "",
                                            request: ""
                                        },)
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Nội dung</th>
                                    <th className="text-center">Kiến nghị</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listTotalResult.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td  className="text-center">
                                                <input type="text" value={item?.content} onChange={(e: any) => item.content = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                            </td>
                                            <td className="text-center">
                                                <input type="text" value={item?.request} onChange={(e: any) => item.request = e.currentTarget.value} placeholder='Nhập...' className='form-control'/>
                                                { i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listTotalResult.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <p className='title_table__research'>KẾT LUẬN TỔNG THỂ</p>
                        <textarea value={marketResearchStore.overallConclusion} onChange={(e) => {marketResearchStore.overallConclusion = e.currentTarget.value}} placeholder="Nhập kết luận ..." className='form-control w-100  mb-3' style={{minHeight: '150px'}}></textarea>
                        <div style={{maxWidth: '50%'}} className='d-flex justify-content-end align-items-center mb-2'>
                            <button className="button_add_form_table"
                                    onClick={() => {
                                        marketResearchStore.listDocument.push("")
                                    }}>
                                <img src="/assets/ico/action/add_green_icon.svg"
                                     alt=""/>
                                Thêm
                            </button>
                        </div>
                        <div style={{maxWidth: '50%'}} className="table-form-responsive custom-scrollbar d-flex justify-content-between">
                            <table className='table align-middle border gy-7 gs-7'>
                                <thead>
                                <tr>
                                    <th className="text-center">STT</th>
                                    <th className="text-center">Danh mục tài liệu tham khảo</th>
                                </tr>
                                </thead>
                                <tbody>
                                {marketResearchStore.listDocument.map((item: any, i: number) => {
                                    return (
                                        <tr key={i} className='position-relative'>
                                            <td style={{width: '48px'}} className="text-center">{i + 1}</td>
                                            <td className="text-center">
                                                <input type="text" value={item} onChange={(e: any) => marketResearchStore.updateListDocument(i, e.currentTarget.value)} placeholder='Nhập...' className='form-control'/>
                                                { i!== 0 && <button className="btn p-0 mx-1 btn_delete_form_table"
                                                                    data-toggle="modal" data-target="#"
                                                                    onClick={() => {
                                                                        marketResearchStore.listDocument.splice(i, 1)
                                                                    }}>
                                                    <img src="/assets/ico/action/minus-circle.svg"
                                                         className="icon-action"
                                                         alt="" data-toggle="tooltip" data-placement="top"
                                                         title="Xóa"/>
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <button onClick={async () => {
                            await marketResearchStore.addMarketResearch()
                        }} className="button_save_research">
                            {
                                marketResearchStore.isLoadingBt ?  <Spin size="small" /> : marketResearchStore.typeForm === 'add' ? 'Hoàn thành' : 'Cập nhật'
                            }
                        </button>
                    </div>
            }
        </>
    );
}

export default observer(FormMarketResearch);