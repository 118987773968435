import {makeAutoObservable} from 'mobx'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {marketResearchService} from "./MarketResearchService";
import $ from "jquery";
export interface IDataRequestAddMarketResearch {
    name: string,
    data: any,
    reportUser: any
    reportingTime: any,
    researchTimeFrom: any,
    researchTimeTo: any,
    type: any
}

class MarketResearch {
    id: any = ""
    page: number = 0
    totalPage: number = 0
    isLoadingBt: boolean = false
    isLoading: boolean = false
    typeForm: string = "add"
    searchStatus: any = null
    statusResearch: any = ''
    listMarketResearch: any = []
    //---------------DATA MARKET RESEARCH -------------------------

    updateResearchResult(index: any, value: any){
        marketResearchStore.listResearchResult[index] = value
    }
    updateListDocument(index: any, value: any){
        marketResearchStore.listDocument[index] = value
    }
    listResearchMarketCommon: any = [{
        content: "Mục tiêu nghiên cứu",
        result: ""
    },{
        content: "Phạm vi nghiên cứu",
        result: ""
    },{
        content: "Phương pháp nghiên cứu",
        result: ""
    },{
        content: "Số lượng mẫu khảo sát",
        result: ""
    },{
        content: "Phạm vi xử lý",
        result: ""
    }]
    errors : any = {
        reportingTime: false,
        researchTimeFrom: false,
    }
    listResearchResult: any = [""]
    listCompetitorResearch: any = [{
        criteria : "",
        option1: "",
        option2: "",
    }]
    listPriceResearch: any = [
        {
            productName : "",
            price: "",
            vote: "",
        }
    ]
    listCustomerResearch: any = {
        satisfactionByAge: {
            level1: "",
            level2: "",
            level3: "",
            level4: "",
            level5: "",
        },
        satisfactionByGender: {
            male: "",
            female: "",
        },
        satisfactionByEducate: {
            level1: "",
            level2: "",
            level3: "",
            level4: "",
        },
        demand: [{
            title: "",
            value: ""
        }]
    }
    listTotalResult: any = [
        {
            content: "",
            request: ""
        },
    ]
    overallConclusion : any = ""
    listDocument: any = [""]

    clearForm(){
        this.statusResearch = ''
        this.errors = {
            reportingTime: false,
            researchTimeFrom: false,
        }
        this.dataRequestMarketResearch = {
            name: "BÁO CÁO NGHIÊN CỨU THỊ TRƯỜNG",
            reportUser: "",
            data: {},
            reportingTime: "",
            researchTimeFrom: "",
            researchTimeTo: "",
            type: "RESEARCH"
        }
        this.listResearchMarketCommon = [{
            content: "Mục tiêu nghiên cứu",
            result: ""
        },{
            content: "Phạm vi nghiên cứu",
            result: ""
        },{
            content: "Phương pháp nghiên cứu",
            result: ""
        },{
            content: "Số lượng mẫu khảo sát",
            result: ""
        },{
            content: "Phạm vi xử lý",
            result: ""
        }]
        this.listResearchResult = [""]
        this.listCompetitorResearch = [{
            criteria : "",
            option1: "",
            option2: "",
        }]
        this.listPriceResearch = [
            {
                productName : "",
                price: "",
                vote: "",
            }
        ]
        this.listCustomerResearch = {
            satisfactionByAge: {
                level1: "",
                level2: "",
                level3: "",
                level4: "",
                level5: "",
            },
            satisfactionByGender: {
                male: "",
                female: "",
            },
            satisfactionByEducate: {
                level1: "",
                level2: "",
                level3: "",
                level4: "",
            },
            demand: [{
                title: "",
                value: ""
            }]
        }
        this.listTotalResult = [
            {
                content: "",
                request: ""
            },
        ]
        this.overallConclusion = ""
        this.listDocument = [""]
    }
    //--------------------------DATA REQUEST----------------
    dataRequestMarketResearch: IDataRequestAddMarketResearch = {
        name: "BÁO CÁO NGHIÊN CỨU THỊ TRƯỜNG",
        reportUser: "",
        data: {},
        reportingTime: "",
        researchTimeFrom: "",
        researchTimeTo: "",
        type: "RESEARCH"
    }

    //---------------DATA RND RESEARCH -------------------------

    listCommonRndResearch = [{
        title : "Sản phẩm mục tiêu",
        content: "",
        default: true
    },{
        title : "Khách hàng mục tiêu",
        content: "",
        default: true
    },{
        title : "Thị trường mục tiêu",
        content: "",
        default: true
    },{
        title : "Đối thủ cạnh tranh",
        content: "",
        default: true
    },{
        title : "Phương pháp nghiên cứu",
        content: "",
        default: true
    },{
        title : "Mục tiêu nghiên cứu",
        content: "",
        default: true
    },{
        title : "Phạm vi nghiên cứu",
        content: "",
        default: true
    }]
    listVoteProductRndResearch = [{
        criteria : "Đáp ứng nhu cầu thị trường",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Yêu cầu chất lượng",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tính năng",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Lợi ích",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tính độc đáo và sáng tạo",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Giá",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Phạm vi thị trường",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Đánh giá tính cạnh tranh đối với đối thủ trực tiếp",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Đánh giá tính cạnh tranh đối với đối thủ gián tiếp",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tỷ lệ khách hàng hiện tại mua sản phẩm",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tuân thủ quy định pháp lý",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tổng điểm Trung bình",
        result: "",
        vote: "",
        default: true
    }]
    listMarketRndResearch : any = [
        {
            criteria: "Tỷ lệ người sử dụng (%)",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Giá bán",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Doanh số bán hàng",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Đánh giá chất lượng",
            option1: "",
            option2: "",
            default: true
        }
    ]
    listSuggestProductRndResearch = [{
        stage: "",
        action: "",
        performer: "",
        coordinator: "",
        time: ""
    }]
    listStragyMarketingRndResearch = [
        {
            marketingActive : "",
            mediaChanel : "",
            target: ""
        }
    ]
    listTargetEfficiencyRndResearch = [
        {
            criteria: "Đánh giá chất lượng",
            result: "",
            vote: "",
        },
        {
            criteria: "% Chi phí/Tổng chi phí",
            result: "",
            vote: "",
        },
        {
            criteria: "Doanh số kỳ vọng",
            result: "",
            vote: "",
        },
        {
            criteria: "% Đóng góp doanh số",
            result: "",
            vote: "",
        },
        {
            criteria: "Tỷ suất sinh lời đầu tư ROI",
            result: "",
            vote: "",
        },
        {
            criteria: "Thời gian thu hồi vốn",
            result: "",
            vote: "",
        },
        {
            criteria: "Tổng điểm trung bình",
            result: "",
            vote: "",
        }
    ]
    listDocumentRndResearch: any = [{
        name: ""
    }]
    async getListMarketResearch() {
        this.isLoading = true
        const result = await marketResearchService.getListMarketResearch()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listMarketResearch = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetail(id: any) {
        this.isLoading = true
        const result = await marketResearchService.getDetailMarketResearch(id)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            marketResearchStore.dataRequestMarketResearch.name = result.body?.name
            marketResearchStore.dataRequestMarketResearch.reportUser = result.body?.reportUser?.name
            marketResearchStore.dataRequestMarketResearch.reportingTime = result.body?.reportingTime
            marketResearchStore.dataRequestMarketResearch.researchTimeFrom = result.body?.researchTimeFrom
            marketResearchStore.dataRequestMarketResearch.researchTimeTo = result.body?.researchTimeTo
            marketResearchStore.dataRequestMarketResearch.type = result.body?.type
            marketResearchStore.listResearchMarketCommon = result.body?.data?.listResearchMarketCommon
            marketResearchStore.listResearchResult = result.body?.data?.listResearchResult
            marketResearchStore.listCompetitorResearch = result.body?.data?.listCompetitorResearch
            marketResearchStore.listPriceResearch = result.body?.data?.listPriceResearch
            marketResearchStore.listCustomerResearch = result.body?.data?.listCustomerResearch
            marketResearchStore.overallConclusion = result.body?.data?.overallConclusion
            marketResearchStore.listTotalResult = result.body?.data?.listTotalResult
            marketResearchStore.listDocument = result.body?.data?.listDocument
            marketResearchStore.statusResearch = result.body?.status
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async deleteMarketResearch() {
        marketResearchStore.isLoadingBt = true
        const result = await marketResearchService.deleteMarketResearch(marketResearchStore.id)
        marketResearchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa báo cáo thành công!')
            await  marketResearchStore.getListMarketResearch()
        } else toastUtil.error(result.body.message)
    }
    async addMarketResearch() {
        if(!marketResearchStore.dataRequestMarketResearch.reportingTime){
            toastUtil.warning(`Vui lòng nhập thời gian báo cáo !`, 1)
            this.errors.reportingTime = true
            return
        }
        if(!marketResearchStore.dataRequestMarketResearch.reportingTime || !marketResearchStore.dataRequestMarketResearch.researchTimeTo){
            toastUtil.warning(`Vui lòng nhập thời gian nghiên cứu !`, 1)
            this.errors.researchTimeFrom = true
            return
        }
        const data = {
                name: marketResearchStore.dataRequestMarketResearch.name,
                data: {
                    listResearchMarketCommon: marketResearchStore.listResearchMarketCommon,
                    listResearchResult: marketResearchStore.listResearchResult,
                    listCompetitorResearch: marketResearchStore.listCompetitorResearch,
                    listPriceResearch: marketResearchStore.listPriceResearch,
                    listCustomerResearch: marketResearchStore.listCustomerResearch,
                    overallConclusion : marketResearchStore.overallConclusion,
                    listTotalResult: marketResearchStore.listTotalResult,
                    listDocument: marketResearchStore.listDocument,
                },
                reportingTime:  marketResearchStore.dataRequestMarketResearch.reportingTime,
                researchTimeFrom:  marketResearchStore.dataRequestMarketResearch.researchTimeFrom,
                researchTimeTo:  marketResearchStore.dataRequestMarketResearch.researchTimeTo,
                type: "RESEARCH"
        }
        this.isLoadingBt = true
        const result =  this.typeForm === 'add' ? await marketResearchService.addMarketResearch(data) : await marketResearchService.editMarketResearch(marketResearchStore.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeForm === 'add' ? 'Thêm báo cáo thành công' : 'Cập nhật thông tin báo cáo thành công'}`, 1)
            window.location.href = '/nghien-cuu-thi-truong/bao-cao-nghien-cuu-thi-truong'
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    constructor() {
        makeAutoObservable(this)
    }

}

export const marketResearchStore = new MarketResearch()