import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import TabRedirect from "../../modules/components/tabRedirect/TabRedirect";
import './OrderPage.scss'
import ButtonGlobal from "../../modules/components/buttonGlobal/ButtonGlobal";
import FilterComponent, {IFilter} from "../../modules/components/filterGlobal/FilterGlobal";
import {orderStore} from "./OrderStore";
import ModalSelectRestaurant from "./components/selectRestaurant/SelectRestaurant";
import ModalSelectTypeSerive from "./components/modalSelectTypeService/ModalSelectTypeService";
import Loading from "../../modules/components/Loading";
import NoContent from "../../modules/components/NoContent";
import {dateUtils} from "../../common/utils/DateUtils";
import {Link} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {convertTypeOrder} from "../checkOrder/CheckOrderPage";
import {kitchenStore} from "../kitchenManager/KitchenStore";
import {DatePicker} from "antd";
import {Moment} from "moment/moment";
import dayjs from "dayjs";
import MenuAction from "../../modules/components/menuAction/MenuAction";
import {slug} from "../../common/utils/Utils";
import {checkOrderStore} from "../checkOrder/CheckOrderStore";
import DetailCheckoutOrderModal from "../checkOrder/components/detailOrder/detailOrder";
import ViewDetailOrderModal from "../checkOrder/components/viewHistoryOrder/ViewHistoryOrder";
import ModalSelectRoom from "./components/modalSelectRoom/ModalSelectRoom";

const convertStatusOrder = (value: string) => {
    switch (value.toUpperCase()) {
        case "SUCCESS":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#F3FFF9', color: '#00AB56', borderRadius: '3px'}}>Hoàn
                thành</div>
        case "IN_PROGRESS":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#FAF9FF', color: '#7B61FF', borderRadius: '3px'}}>Đang
                phục vụ</div>
        case "PENDING":
            return <div className="px-1"
                        style={{width: '100px', backgroundColor: '#EBEBF0', color: '#626C70', borderRadius: '3px'}}>Chưa
                phục vụ</div>
        case "CANCEL":
            return <div className="px-1" style={{
                width: '100px',
                backgroundColor: '#FFEDEE',
                color: '#FF424F',
                borderRadius: '3px'
            }}>Hủy</div>
        default:
            return null
    }
}

const OrderPage = () => {
    const modalRef = useRef(null);
    const showRef = useRef(null);
    const handleShowRow = () => {
        document.querySelectorAll('.button-detail__order').forEach((button, i) => {
            const shouldClickButton = (JSON.parse(localStorage.getItem('rowShow') as any) === i);
            if (shouldClickButton && showRef.current) {
                (showRef.current as HTMLButtonElement).click();
                return
            }
        });
    }
    const dataFilter : IFilter[] = [
        {
            label: 'Tình trạng',
            type: 'select',
            value:  orderStore.searchStatusOrder,
            placeholder: 'Tình trạng',
            listValue: [{
                value: "CANCEL",
                label : "Đã hủy"
            },{
                value: "IN_PROGRESS",
                label : "Đang phục vụ"
            },{
                value: "PENDING",
                label : "Chưa phục vụ"
            },{
                value: "SUCCESS",
                label : "Thành công"
            }],
            callback: async (e: any) => {
                orderStore.searchStatusOrder = e
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = !orderStore.searchStatusOrder
                if(!e) await orderStore.getListOrder().then()
                else await orderStore.getListOrder(500).then()
            }
        },
        {
            label: 'Loại',
            type: 'select',
            value:  orderStore.searchTypeOrder,
            placeholder: 'Loại',
            listValue: [{
                value: "OFFLINE",
                label : "Tại chỗ / Mang đi"
            },{
                value: "ONLINE",
                label : "Gọi ship"
            },{
                value: "ORDER",
                label : "Đặt chỗ"
            }],
            callback: async (e: any) => {
                orderStore.searchTypeOrder = e
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = !orderStore.searchTypeOrder
                if(!e) await orderStore.getListOrder().then()
                else await orderStore.getListOrder(500).then()
            }
        },
        {
            label: '',
            type: 'search',
            placeholder: 'Nhập từ khóa tìm kiếm...',
            value: orderStore.searchKeywordOrder,
            onChange: (e: any) => {
                orderStore.searchKeywordOrder = e
            },
            callback: async () => {
                orderStore.page = 0
                orderStore.listOrders = []
                orderStore.hasMore = !orderStore.searchKeywordOrder
                if(!orderStore.searchKeywordOrder) await orderStore.getListOrder().then()
                else await orderStore.getListOrder(500).then()
            }
        },
    ]

    useEffect(() => {
        orderStore.searchKeywordOrder = ''
        orderStore.searchStatusOrder = null
        orderStore.page = 0
        orderStore.listOrders = []
        orderStore.hasMore = true
        if(!orderStore.dataRequest?.establishmentId) {
            orderStore.dataRequest.establishmentId = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId
        }
        if(!orderStore.dataRequest?.establishmentName) {
            orderStore.dataRequest.establishmentName = JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentName
        }
        orderStore.getListOrder().then(() => handleShowRow())
        orderStore.getListRestaurant().then(() => {
            if (modalRef.current && !JSON.parse(localStorage.getItem('dataOrder') as any)?.establishmentId && orderStore.listRestaurant.length > 0) {
                (modalRef.current as HTMLButtonElement).click();
            }
        })
    }, [])
    function checkIsShowAction(arr: any) {
        return arr.some((item: any) => item.status === 'PENDING' || item.status === 'IN_PROGRESS')
    }
    function convertTime(date?: Moment, isEndDate?: boolean): number {
        if (!date) return 0;
        if (isEndDate) {
            return date.set({hour: 23, minute: 59, second: 59}).unix() * 1000;
        }
        return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
    }
    const handleRangePickerChange = async (dates: any) => {
        orderStore.page = 0
        orderStore.listOrders = []
        orderStore.hasMore = !orderStore.searchStatusOrder
        if (dates && dates.length > 0) {
            orderStore.fromTime = convertTime(dates[0]);
            orderStore.toTime = convertTime(dates[1]);
            if(!orderStore.fromTime && !orderStore.toTime) await orderStore.getListOrder().then()
            else await orderStore.getListOrder(500).then()
        }
        else {
            orderStore.fromTime = "";
            orderStore.toTime = "";
            if(!orderStore.fromTime && !orderStore.toTime) await orderStore.getListOrder().then()
            else await orderStore.getListOrder(500).then()
        }
    }
    const {RangePicker} = DatePicker;
    return (
        <>
            <div className='service_container'>
                <div  className='w-100 service_container__header'>
                        <TabRedirect tab={`${orderStore.dataRequest?.establishmentName ? orderStore.dataRequest?.establishmentName : 'Tất cả điểm bán'}`}  />
                        <div style={{width: '40%', marginRight: '24px'}} className='d-flex align-items-center justify-content-end'>
                            <RangePicker
                                style={{width: 'unset'}}
                                placeholder={['Từ ngày', 'Đến ngày']}
                                onChange={handleRangePickerChange}
                                format={'DD/MM/YYYY'}
                                value={[
                                    orderStore.fromTime === "" ? null : dayjs(orderStore.fromTime),
                                    orderStore.toTime === "" ? null : dayjs(orderStore.toTime)
                                ] as any}
                            />
                        </div>
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            buttonAdd={<ButtonGlobal onClick={() => {
                                orderStore.page = 0
                                orderStore.typeModal = 'add'
                            }}
                                                     dataTarget={`${!orderStore.dataRequest.establishmentId ? 'select_restaurant_order' : 'add_service'}`}
                                                     text='Thêm' ico='add_icon' className='btn_add' />}
                        />
                </div>
                <div className='mb-3 d-flex justify-content-between'>
                    <button ref={modalRef}  data-bs-toggle="modal" data-bs-target='#select_restaurant_order' className='btn_select_restaurant'>Chuyển điểm bán</button>
                </div>
                <div className='w-100'>
                    <InfiniteScroll
                        dataLength={orderStore.listOrders.length}
                        next={orderStore.getListOrder}
                        hasMore={orderStore.hasMore}
                        loader={<Loading/>}>
                                    <div className="table-responsive d-flex flex-column mb-4 justify-content-between">
                                        <table className='table align-middle border gy-7 gs-7'>
                                            <thead>
                                            <tr>
                                                <th className="text-center">STT</th>
                                                <th className="text-center">Mã đơn</th>
                                                <th className="text-center">Thời gian</th>
                                                <th className="text-center">Số điện thoại</th>
                                                <th className="text-center">Họ và tên</th>
                                                <th className="text-center">Xếp hạng</th>
                                                <th className="text-center">Loại</th>
                                                <th className="text-center">Phòng - Bàn</th>
                                                <th className="text-center">Tình trạng</th>
                                                <th className="text-center">Thao tác</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(orderStore.listOrders.length <= 0 &&  !orderStore.isLoading) ? <NoContent colSpan={10}/> : orderStore.listOrders.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-center">{i + 1}</td>
                                                            <td className="text-center">
                                                                #{item?.code}
                                                            </td>
                                                            <td className="text-center">{dateUtils.formatDate(item?.checkinTime, 'hh:mm , DD/MM/YYYY')}</td>
                                                            <td className="text-center">{item?.type !== 'ORDER' ? (item?.user?.phone ?? "-") : (item?.bookingInfo?.phone ?? "-")}</td>
                                                            <td className="text-center">{item?.type !== 'ORDER' ? (item?.user?.name ?? "-") : (item?.bookingInfo?.name ?? "-")}</td>
                                                            <td className="text-center">Thường</td>
                                                            <td className="text-center">
                                                                <div className='d-flex w-100 justify-content-center'>
                                                                    {convertTypeOrder(item?.type)}
                                                                </div>
                                                            </td>
                                                            <td className="text-center">{`${item?.room?.name ? `${item?.room?.name}` : ""}${item?.table?.name ? ` - ${item?.table?.name}` : ""}`}</td>
                                                            <td className="text-center">
                                                                <div
                                                                    className='d-flex justify-content-center'>{convertStatusOrder(item?.status)}</div>
                                                            </td>
                                                            <td style={{width: '150px'}} className="text-center fw-500">
                                                                <MenuAction children={<>
                                                                    <Link
                                                                        to={`/phuc-vu/chi-tiet/${slug(item.code)}`}
                                                                        className="d-flex justify-content-start btn"
                                                                        onClick={async () => {
                                                                            localStorage.setItem('orderId', item.id)
                                                                            await orderStore.getDetailOrder(item.id)
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="/assets/ico/menuAction/clipboard-list.svg"
                                                                            className="icon-action"
                                                                            alt="detail"
                                                                        />
                                                                        <span>
                                                                            Chi tiết
                                                                        </span>
                                                                    </Link>
                                                                    <Link to={`/phuc-vu/${item.code}/${item.id}`}
                                                                          className="d-flex justify-content-start btn"
                                                                          onClick={() => {
                                                                              orderStore.idOrder = item?.id
                                                                              orderStore.codeOrder = item?.code
                                                                              orderStore.isBackToDetailPage = false
                                                                          }}>
                                                                        <img src="/assets/ico/action/plus-square-grey.svg"
                                                                             className="icon-action"
                                                                             alt=""/>
                                                                        <span>
                                                                            Thêm món
                                                                        </span>
                                                                    </Link>
                                                                    <button className='d-flex justify-content-start btn'
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target='#select_room_order'
                                                                            onClick={async () => {
                                                                                orderStore.idOrder = item?.id
                                                                                orderStore.typeModal = 'edit'
                                                                                orderStore.codeOrder = item?.code
                                                                                orderStore.page = 0
                                                                                orderStore.dataRequest.establishmentId = item?.establishmentId
                                                                                await orderStore.getListRoom().then()
                                                                                orderStore.dataRequest.roomId = item?.roomId
                                                                                orderStore.dataRequest.tableId = item?.tableId
                                                                            }}>
                                                                        <img
                                                                            src="/assets/ico/action/arrow-left-right.svg"
                                                                            className="icon-action"
                                                                            alt=""/>
                                                                        <span>
                                                                            Đổi bàn
                                                                        </span>
                                                                    </button>
                                                                    <button className='d-flex justify-content-start btn'
                                                                            onClick={async () => {
                                                                                await kitchenStore.cancelKitchen(item.items?.map((item: any) => item.id))
                                                                                orderStore.page = 0
                                                                                orderStore.listOrders = []
                                                                                orderStore.hasMore = true
                                                                                await orderStore.getListOrder().then()
                                                                            }}>
                                                                        <img src="/assets/ico/action/x.svg"
                                                                             className="icon-action"
                                                                             alt=""/>
                                                                        <span>
                                                                            Hủy tất cả món
                                                                        </span>
                                                                    </button>
                                                                    <button className='d-flex justify-content-start btn'
                                                                            onClick={async () => {
                                                                                await kitchenStore.changeStatus({
                                                                                    status: 'IN_PROGRESS',
                                                                                    ids: item.items?.map((item: any) => item.id)
                                                                                })
                                                                                orderStore.page = 0
                                                                                orderStore.listOrders = []
                                                                                orderStore.hasMore = true
                                                                                await orderStore.getListOrder().then()
                                                                            }}>
                                                                        <img src="/assets/ico/action/check-circle-2.svg"
                                                                             className="icon-action"
                                                                             alt=""/>
                                                                        <span>
                                                                            Đã phục vụ tất cả
                                                                        </span>
                                                                    </button>
                                                                    <div className={'divice_menu__action'}></div>
                                                                    <button className='d-flex justify-content-start btn'
                                                                            onClick={() => {
                                                                                if (item?.status !== "SUCCESS") {
                                                                                    checkOrderStore.clearForm()
                                                                                    checkOrderStore.idOrder = Number(localStorage.getItem('orderId'))
                                                                                    checkOrderStore.getDetailOrder(item.id).then()
                                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                                    checkOrderStore.detailInfoUser = {}
                                                                                    checkOrderStore.keyword = ""
                                                                                } else {
                                                                                    checkOrderStore.dataCheckCodeVoucher = {}
                                                                                    checkOrderStore.getDetailOrder(Number(localStorage.getItem('orderId'))).then()
                                                                                }
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target={item?.status !== "SUCCESS" ? '#detail_checkout_order' : '#view_detail_order'}
                                                                    >
                                                                        <img src="/assets/ico/action/banknote.svg"
                                                                             className="icon-action"
                                                                             alt=""/>
                                                                        <span style={{color: '#EF7752'}}>
                                                                            {item?.status !== 'SUCCESS' ? 'Check đơn' : 'Chi tiết'}
                                                                        </span>
                                                                    </button>
                                                                </>}/>
                                                            </td>
                                                        </tr>
                                                    )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                    </InfiniteScroll>
                </div>
            </div>
            <ModalSelectRestaurant />
            <ModalSelectTypeSerive />
            <DetailCheckoutOrderModal/>
            <ViewDetailOrderModal/>
            <ModalSelectRoom/>
        </>
    );
}

export default observer(OrderPage);
