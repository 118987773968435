import React from 'react';
import {observer} from "mobx-react";
import './ModalAddEditFinance.scss'
import { Input } from 'antd';
import { Spin } from 'antd';
import {accountStore} from "../../../../setting/account/AccountStore";
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import {costStore} from "../../CostStore";
const ModalAddEditFinance = () => {
    return (
        <div className='modal fade' id='add_edit_finance' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>
                            CẬP NHẬT CHI PHÍ
                        </div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='px-4 fw-500 my-3'>
                        <span>{costStore?.establishmentName} - Tháng {costStore.dataRequest?.month}/{costStore.dataRequest?.year}</span>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50  d-flex flex-column pe-3'>
                                <div className='w-100 header-form__finance mb-3 d-flex justify-content-between'>
                                    <label className='form-label'>COS</label>
                                    <label className='form-label'>
                                        {numberUtil.number_format(
                                            Number(costStore.dataRequest?.foodCost) +
                                            Number(costStore.dataRequest?.drinkCost) +
                                            Number(costStore.dataRequest?.otherGoodsCost) +
                                            Number(costStore.dataRequest?.destructionCost) +
                                            Number(costStore.dataRequest?.instrumentAllotmentCost)
                                        )}
                                        <span className='text-decoration-underline ms-1'>đ</span>
                                    </label>
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>1. Giá vốn đồ ăn</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.foodCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.foodCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.foodCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>2. Giá vốn đồ uống</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.drinkCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.drinkCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.drinkCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>3. Giá vốn hàng bán khác</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.otherGoodsCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.otherGoodsCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.otherGoodsCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>4. Chi phí hỏng , hủy NVL</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.destructionCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.destructionCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.destructionCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>5. Chi phí DC phân bổ</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.instrumentAllotmentCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.instrumentAllotmentCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.instrumentAllotmentCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                            </div>
                            <div className='w-50 d-flex flex-column ps-3'>
                                <div className='w-100 header-form__finance mb-3 d-flex justify-content-between'>
                                    <label className='form-label'>COL</label>
                                    <label className='form-label'>
                                        {numberUtil.number_format(
                                            Number(costStore.dataRequest?.salaryFee) +
                                            Number(costStore.dataRequest?.socialInsuranceFee) +
                                            Number(costStore.dataRequest?.bscbonusFee) +
                                            Number(costStore.dataRequest?.extraSalaryFee) +
                                            Number(costStore.dataRequest?.staffmealFee)
                                        )}
                                        <span className='text-decoration-underline ms-1'>đ</span>
                                    </label>
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>1. Chi phí lương nhân viên</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.salaryFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.salaryFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.salaryFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>2. Chi phí BHXH, BHYT, BHTN</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.socialInsuranceFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.socialInsuranceFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.socialInsuranceFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>3. Chi tiền thưởng BSC</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.bscbonusFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.bscbonusFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.bscbonusFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>4. Chi tháng lương thứ 13</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.extraSalaryFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.extraSalaryFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.extraSalaryFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>5. Chi phí cơm nhân viên (ăn ca)</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.staffmealFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.staffmealFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.staffmealFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='header-form__finance w-100 d-flex justify-content-between pe-3 my-3'>
                            <label className='form-label'>SEMI</label>
                            <label className='form-label'>
                                <label className='form-label'>
                                    {numberUtil.number_format(
                                        Number(costStore.dataRequest?.rentalCost) +
                                        Number(costStore.dataRequest?.securityCost) +
                                        Number(costStore.dataRequest?.electricityCost) +
                                        Number(costStore.dataRequest?.phoneBill) +
                                        Number(costStore.dataRequest?.internetCost) +
                                        Number(costStore.dataRequest?.gasBill) +
                                        Number(costStore.dataRequest?.coalCost) +
                                        Number(costStore.dataRequest?.waterCost) +
                                        Number(costStore.dataRequest?.ccdcFee) +
                                        Number(costStore.dataRequest?.ccdcPeriodFee) +
                                        Number(costStore.dataRequest?.repairCost) +
                                        Number(costStore.dataRequest?.maintenanceCost) +
                                        Number(costStore.dataRequest?.serviceFee) +
                                        Number(costStore.dataRequest?.marketingCost) +
                                        Number(costStore.dataRequest?.transportationCost) +
                                        Number(costStore.dataRequest?.otherCost)
                                    )}
                                    <span className='text-decoration-underline ms-1'>đ</span>
                                </label>
                            </label>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 d-flex flex-column'>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>1. Chi phí thuê mặt bằng</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.rentalCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.rentalCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.rentalCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>2. Chi phí dịch vụ bảo vệ</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.securityCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.securityCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.securityCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>3. Chi phí tiền điện</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.electricityCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.electricityCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.electricityCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>4. Chi phí điện thoại</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.phoneBill =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.phoneBill ? numberUtil.number_format(
                                                   costStore.dataRequest?.phoneBill
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>5. Chi phí Internet</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.internetCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.internetCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.internetCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>6. Chi phí tiền gas</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.gasBill =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.gasBill ? numberUtil.number_format(
                                                   costStore.dataRequest?.gasBill
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>7. Chi phí tiền than 1.3</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.coalCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.coalCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.coalCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group pe-3'>
                                    <label className='form-label'>8. Chi phí tiền nước máy</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.waterCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.waterCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.waterCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                            </div>
                            <div className='w-50 d-flex flex-column'>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>9. Chi phí CCDC, VL</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.ccdcFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.ccdcFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.ccdcFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>10. Chi phí CCDC, VL dùng ngay trong kỳ</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.ccdcPeriodFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.ccdcPeriodFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.ccdcPeriodFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>11. Chi phí sửa chữa nhà hàng</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.repairCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.repairCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.repairCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>12. Chi phí bảo hành, bảo trì hệ thống</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.maintenanceCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.maintenanceCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.maintenanceCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>13. Phí dịch vụ</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.serviceFee =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.serviceFee ? numberUtil.number_format(
                                                   costStore.dataRequest?.serviceFee
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>14. Chi phí Marketing</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.marketingCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.marketingCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.marketingCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>15. Chi phí vận chuyển</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.transportationCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.transportationCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.transportationCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                                <div className='w-100 form-group ps-3'>
                                    <label className='form-label'>16. Chi phí khác</label>
                                    <Input type='text'
                                           placeholder="Nhập..."
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           onChange={(e: any) => {
                                               if (
                                                   e === '' ||
                                                   Number(numberUtil.regexNumberFormat(e.currentTarget.value)) >= 0
                                               ) {
                                                   costStore.dataRequest.otherCost =
                                                       numberUtil.regexNumberFormat(e.currentTarget.value)
                                               }
                                           }}
                                           value={
                                               (costStore.dataRequest?.otherCost ? numberUtil.number_format(
                                                   costStore.dataRequest?.otherCost
                                               ) as string : '') ?? ''
                                           }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 d-flex align-items-center ps-3 mt-3'>
                                <button type='submit' className='btn btn__voucher'
                                        onClick={async () => {
                                            await costStore.updateCost()}}>
                                    {accountStore.isLoadingBt ? <Spin size="small" /> : accountStore.typeModal === 'add' ?  'Thêm' : 'Cập nhật' }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddEditFinance);

