import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import './RestaurantPage.scss'
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import Loading from "../../../modules/components/Loading";
import NoContent from "../../../modules/components/NoContent";
import {restaurantStore} from "./RestaurantStore";
import {sttPage} from "../../../common/utils/Utils";
import ModalAddEditRestaurant from "./components/addEditRestaurant/AddEditRestaurant";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {numberUtil} from "../../../common/utils/NumberUtil";
export const convertStatusRestaurant = (value: string) => {
    switch (value.toUpperCase()) {
        case "ACTIVE":
            return <span style={{color: '#00AB56'}}>Đang hoạt động</span>
        case "INACTIVE":
            return <span style={{color: '#515158'}}>Chưa hoạt động</span>
        default:
            return null
    }
}
const ListRestaurantPage = () => {
    useEffect(() => {
        restaurantStore.getListRestaurant().then()
        restaurantStore.getProvinces().then()
    }, [])
    const dataFilter : IFilter[] = [

        {
            label: 'Tình trạng',
            type: 'select',
            value: restaurantStore.status,
            placeholder: 'Chọn',
            listValue: [{
                value: "ACTIVE",
                label: "Đang hoạt động"
            },{
                value: "INACTIVE",
                label: "Chưa hoạt động"
            }],
            callback: async (e: any) => {
                restaurantStore.status = e
                await restaurantStore.getListRestaurant().then()
            }
        },
    ]
    return (
        <div className='kpi_page'>
            <div className='d-flex justify-content-between'>
                <TabRedirect tab='Danh sách các điểm bán' />
                <FilterComponent
                    data={dataFilter}
                    permissionCode
                    buttonAdd={<ButtonGlobal onClick={() => {
                        restaurantStore.typeModal = 'add'
                        restaurantStore.clearForm()
                    }} dataTarget='add_edit_restaurant' text='Thêm' ico='add_icon' className='btn_add' />}
                />
            </div>
            {restaurantStore.isLoading ? <Loading/> : <div className="table-responsive custom-scrollbar">
                <table className='table align-middle border gy-7 gs-7'>
                    <thead>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Tên điểm bán</th>
                        <th className="text-center">Số điện thoại</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Địa chỉ</th>
                        <th className="text-center">Số lượng nhân viên</th>
                        <th className="text-center">Mã điểm bán</th>
                        <th className="text-center">Trạng thái</th>
                        <th className="text-center">Thao tác</th>
                    </tr>
                    </thead>
                    <tbody>
                    {restaurantStore.listRestaurant?.length <= 0 ? <NoContent colSpan={9}/> : restaurantStore.listRestaurant.map((item: any, i: number) => {
                        return (
                            <tr key={i}>
                                <td style={{width: '38px'}} className="text-center">{sttPage(0, i)}</td>
                                <td className="text-start fw-500">{item?.name}</td>
                                <td className="text-center">{item?.phone}</td>
                                <td className="text-center">{item?.email}</td>
                                <td className="text-start">{`${item?.address?.detail}, ${item?.address?.ward?.name}, ${item?.address?.district?.name} ,${item?.address?.province?.name}`}</td>
                                <td className="text-center">{numberUtil.number_format(item?.countStaff)}</td>
                                <td className="text-center">{item?.code}</td>
                                <td className="text-center"><span className="color-green" style={{fontWeight: '500'}}>{convertStatusRestaurant(item?.status)}</span>
                                </td>
                                <td className="text-center">
                                    <button className="btn p-0"
                                            data-bs-toggle="modal" data-bs-target={"#add_edit_restaurant"}
                                            onClick={async () => {
                                                restaurantStore.id = item?.id
                                                restaurantStore.typeModal = 'edit'
                                                await restaurantStore.getDetailRestaurant(item.id)}
                                            }>
                                        <img src="/assets/ico/action/edit_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Chỉnh sửa"/>
                                    </button>

                                    <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                            onClick={() => restaurantStore.id = item.id}>
                                        <img src="/assets/ico/action/delete_icon.svg"
                                             className="icon-action"
                                             alt="" data-toggle="tooltip" data-placement="top"
                                             title="Xóa"/>
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>}
            <ModalAddEditRestaurant />
            <PopupConfirm isLoading={restaurantStore.isLoadingBt} action={restaurantStore.deleteRestaurant} />
        </div>
    );
}

export default observer(ListRestaurantPage);