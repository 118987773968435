import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {orderService} from "./OrderService";
import {toastUtil} from "../../common/utils/ToastUtil";
import {validatePhoneNumber} from "../../common/utils/Utils";
import moment from "moment/moment";


export enum ITabTypeProduct {
    ALL = 'ALL',
    APPETIZER = 'APPETIZER',
    MAIN_COURSE = 'MAIN_COURSE',
    DESSERT = 'DESSERT',
    BEVERAGE = 'BEVERAGE'
}
class OrderStore {
    id: any = ''
    typeModal: string = ''
    establishmentName: string = ''
    fromTime: any = ''
    toTime: any = ''
    page: number = 0
    searchStatusOrder: any = null
    searchTypeOrder: any = null
    searchKeywordOrder: any = null
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    isErrorInfoOrder: boolean = true
    listOrders: any[] = []
    listRestaurant: any[] = []
    listRoom: any[] = []
    listTable: any[] = []
    listProduct: any[] = []
    tabActiveProduct: string = 'ALL'
    keyword: string = ''
    idOrder: any = ''
    isBackToDetailPage: boolean = false
    codeOrder: any = ''
    dataDetailOrder: any = {}
    isFetching = false; // Thêm biến kiểm tra
    hasMore: boolean = true
    dataRequest: any = {
        establishmentId: null,
        establishmentName: "",
        items: [],
        type: "",
        roomId: null,
        tableId: null,
        bookingInfo: null,
        checkinTime: null
    }
    bookingInfo: any = {
        name: "",
        numPeople: "",
        phone: ""
    }
    checkinTimeOrder: any = null
    errorBookingInfo: any = {
        name: "",
        numPeople: "",
        phone: "",
        checkinTimeOrder: ""
    }
    clearFormInforOrder() {
        this.bookingInfo = {
            name: "",
            numPeople: "",
            phone: ""
        }
        this.checkinTimeOrder = null
        this.errorBookingInfo = {
            name: "",
            numPeople: "",
            phone: "",
            checkinTimeOrder: ""
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    async isValidateInforOrder() {
        this.isErrorInfoOrder = !(this.bookingInfo.name
            && this.bookingInfo.phone
            && validatePhoneNumber(this.bookingInfo.phone)
            && this.bookingInfo.numPeople
            && this.checkinTimeOrder
        );
    }
    validateInforOrder() {
        this.isLoadingBt = true
        if(!this.bookingInfo.name) {
            this.errorBookingInfo.name = "Tên khách hàng không được để trống"
            this.isLoadingBt = false
            return false
        }
        if(!this.bookingInfo.phone) {
            this.errorBookingInfo.phone = "Số điện thoại khách hàng không được để trống"
            this.isLoadingBt = false
            return false
        }
        if (!validatePhoneNumber(this.bookingInfo.phone)) {
            this.bookingInfo.phone = ''
            this.errorBookingInfo.phone = 'Vui lòng nhập đúng định dạng số điện thoại!'
            this.isLoadingBt = false
            return
        }
        if(!this.bookingInfo.numPeople) {
            this.errorBookingInfo.numPeople = "Vui lòng điền số người!"
            this.isLoadingBt = false
            return false
        }
        if(!this.checkinTimeOrder) {
            this.errorBookingInfo.checkinTimeOrder = "Vui lòng chọn thời gian đặt!"
            this.isLoadingBt = false
            return false
        }
        this.isLoadingBt = false
        this.dataRequest.bookingInfo = this.bookingInfo
        return true
    }

    async getListRoom() {
        this.isLoading = true
        const result = await orderService.getListRoom()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRoom = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                    status: item?.status
                }
            })
        }
    }
    async getListRestaurant() {
        orderStore.page = 0
        const result = await orderService.getListRestaurant()
        if (result.status === HttpStatusCode.OK) {
            this.listRestaurant = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
        }
    }
    async getListTable() {
        orderStore.isLoading = true
        const result = await orderService.getListTable()
        orderStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listTable = result.body.data?.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                    status: item?.status
                }
            })
        }
    }
    async getListProduct() {
        orderStore.isLoading = true
        const result = await orderService.getListProduct()
        if (result.status === HttpStatusCode.OK) {
            this.listProduct = result.body.data
            orderStore.isLoading = false
        }
    }
    async getListOrder(size?: any) {
        if (orderStore.isFetching) {
            return;
        }
        orderStore.isLoading = true
        orderStore.isFetching = true;
        try {
            const result = await orderService.getListOrder(size)
            if (result.status === HttpStatusCode.OK) {
                if (result.body.data.length === 0) {
                    orderStore.hasMore = false;
                } else {
                    orderStore.listOrders = [...orderStore.listOrders, ... result.body.data];
                    if(orderStore.listOrders.length < 15) {
                        orderStore.hasMore = false;
                    }
                    orderStore.page = orderStore.page + 1;
                }
            }
        } catch (error) {
            toastUtil.error(error as any)
        } finally {
            orderStore.isLoading = false;
            orderStore.isFetching = false;
        }
    }
    async addOrder() {
        this.isLoadingBt = true
        let data
        if(orderStore.dataRequest.type !== 'ORDER') {
            data = {
                establishmentId: orderStore.dataRequest.establishmentId,
                roomId: orderStore.dataRequest.roomId,
                tableId: orderStore.dataRequest.tableId,
                type: orderStore.dataRequest.type,
                items : orderStore.dataRequest.items.map((item: any) => {
                    return {
                        productId: item.productId,
                        quantity: item.quantity,
                        note: item.note
                    }
                })
            }
        }
        else {
            data = {
                establishmentId: orderStore.dataRequest.establishmentId,
                roomId: orderStore.dataRequest.roomId,
                tableId: orderStore.dataRequest.tableId,
                type: orderStore.dataRequest.type,
                items : orderStore.dataRequest.items.map((item: any) => {
                    return {
                        productId: item.productId,
                        quantity: item.quantity,
                        note: item.note
                    }
                }),
                bookingInfo: orderStore.dataRequest.bookingInfo,
                checkinTime: moment(orderStore.checkinTimeOrder)
            }
        }

        const result = orderStore.typeModal !== 'edit' ?  await orderService.addOrder(data) : await orderService.editOrder(orderStore.idOrder , data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            orderStore.typeModal !== 'edit' ?  toastUtil.success('Thêm đơn hàng thành công!') : toastUtil.success('Cập nhật thông tin đơn hàng thành công!')
            if(orderStore.typeModal !== 'edit' || window.location.pathname === '/phuc-vu') {
                window.location.href = '/phuc-vu'
            } else window.location.href = `/phuc-vu/chi-tiet/${orderStore.codeOrder}`
        } else toastUtil.error(result.body.message)
    }




    async getDetailOrder(id: number) {
        const result = await orderService.getDetailOrder(id)
        if (result.status === HttpStatusCode.OK) {
            this.dataDetailOrder = result.body
        }else toastUtil.error(result.body.message)
    }
    async addOrderItem(isDetailPage?: boolean) {
        this.isLoadingBt = true
        const data = orderStore.dataRequest.items.map((item: any) => {
                return {
                    productId: item.productId,
                    quantity: item.quantity,
                    note: item.note
                }
            })
        const result = await orderService.addOrderItem(data, orderStore.idOrder)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Thêm sản phẩm vào đơn hàng thành công!')
            if (isDetailPage) {
                window.location.href = `/phuc-vu/chi-tiet/${orderStore.codeOrder}`
            }
            else  window.location.href = `/phuc-vu`
        } else toastUtil.error(result.body.message)
        if(orderStore.dataRequest.type === 'ORDER') {
            localStorage.removeItem('bookingInfo')
            localStorage.removeItem('checkinTime')
        }
    }

}

export const orderStore = new OrderStore()