import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class SettingInformationService {
    public getDetailRestaurant(id: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/restaurant/${id}`);
    }
    public updateInfoRestaurant(data: any, id: any): Promise<IApiResponse> {
        return putRequest(`/iss_365_fnb/restaurant/${id}`, data);
    }
    public getProvinces(): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/provinces`)
    }

    public getDistricts(provinceId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/districts?provinceId=${provinceId}`)
    }

    public getWards(districtId: any): Promise<IApiResponse> {
        return getRequest(`/iss_365_fnb/resource/wards?districtId=${districtId}`)
    }
}

export const settingInformationService = new SettingInformationService();