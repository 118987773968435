import React, {useRef} from 'react';
import {observer} from "mobx-react";
import './AddUsernameModal.scss'
import {Input, Spin} from "antd";
import {accountStore} from "../../AccountStore";
import ModalAddEditAccount from "../addAccountModal/AddAccountModal";
const ModalAddUsername = () => {
    const modalRef = useRef(null);
    return (
        <>
            <div className='modal fade' id='add_username_account' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header'>
                        <div className='value_voucher__title'>THÊM TÀI KHOẢN</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className='w-100 form-group d-flex'>
                            <div className='w-100 form-group'>
                                <Input type='text' value={accountStore.dataRequest?.phone}
                                    status={((accountStore.errors?.username && !accountStore.dataRequest.phone) || (accountStore.errors?.validateUsername && accountStore.dataRequest.phone)) ? "error" : ""}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.phone = e.target.value
                                        accountStore.isValidateUsername = accountStore.validateUsername();
                                        if(accountStore.isValidateUsername) {
                                            accountStore.errors.validateUsername = ""
                                        }
                                    }}
                                       onKeyPress={async (e) => {
                                           if (e.key === "Enter") {
                                               await accountStore.checkUsername();
                                               if (accountStore.isValidateUsername && accountStore.dataRequest.phone) {
                                                   (modalRef.current as unknown as HTMLButtonElement).click();
                                               }
                                           }
                                       }}
                                     placeholder='Nhập số điện thoại ...' />
                                {((accountStore.errors?.username && !accountStore.dataRequest.phone) || (accountStore.errors?.validateUsername && accountStore.dataRequest.phone)) && <small className="text-error">{accountStore.errors?.username || accountStore.errors?.validateUsername}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex align-items-center justify-content-end mt-4'>
                            {
                                (accountStore.isValidateUsername && accountStore.dataRequest.phone) ?
                                    <button type='submit' className='w-25 btn btn__continue'  ref={modalRef}
                                            data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#add_edit_account'
                                    onClick={async () => {await accountStore.checkUsername()}}>
                                        {
                                            accountStore.isLoadingBt ?  <Spin size="small" /> : "Tiếp theo"
                                        }
                                    </button>
                                    :
                                    <button type='submit' className='w-25 btn btn__continue_error'
                                            onClick={async () => {await accountStore.checkUsername()}}>Tiếp theo</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <ModalAddEditAccount />
        </>

    )

}

export default observer(ModalAddUsername);

