import React from 'react';
import {observer} from "mobx-react";
import './ProductCardOrder.scss'
import {numberUtil} from "../../../../../../common/utils/NumberUtil";
interface IProps {
    name: string,
    image: string,
    description: string,
    price: any,
    onClick: any,
    qty?: any
}

const ProductCardOrder = ({name, image, description, price, onClick, qty}: IProps) => {
    return (
        <>
            <div className='product_card__order m-1'>
                <div className='img_product__card_container'>
                    <img className='img_product__card' src={image} alt=""/>
                </div>
                {qty > 0 && <div className='qty_product__order'>{qty}</div>}
                <div className='h-100 w-100 d-flex flex-column justify-content-between'>
                    <div>
                        <h2>{name}</h2>
                        <h5>{description}</h5>
                    </div>
                    <div className='d-flex justify-content-between align-items-end'>
                        <span className="price_product">{numberUtil.number_format(price)}</span>
                        <button onClick={onClick} className='button_add_product__order'>
                            <img src="/assets/ico/action/icon_add_product_order.svg" alt=""/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default observer(ProductCardOrder);