import React from 'react';
import {observer} from "mobx-react";
import './AddAccountModal.scss'
import {Select, Input, Radio} from 'antd';
import {accountStore} from "../../AccountStore";
import { Spin } from 'antd';
import {checkOrderStore} from "../../../../checkOrder/CheckOrderStore";
const ModalAddEditAccount= () => {
    return (
        <div className='modal fade' id='add_edit_account' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>
                            {accountStore.typeModal === 'add' ? 'THÊM TÀI KHOẢN' : 'CẬP NHẬT THÔNG TIN TÀI KHOẢN'}
                        </div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Số điện thoại<span className='text-danger'>*</span></label>
                                <Input type='text' value={accountStore.dataRequest?.phone}
                                       onChange={(e: any) => {
                                           accountStore.dataRequest.phone = e.target.value
                                       }}
                                       className={accountStore.errors?.usernameNotExits ? 'acocunt_not_exits' : ''}
                                       suffix={!accountStore.errors?.usernameNotExits ? <img src="/assets/ico/checked.svg" alt=""/> : null}
                                       disabled />
                                {accountStore.errors?.usernameNotExits && <small className="error_account_not_exits">{accountStore.errors?.usernameNotExits}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Chức vụ<span className='text-danger'>*</span></label>
                                <Select
                                    allowClear
                                    mode="multiple"
                                    value={accountStore.dataRequest.roleId}
                                    status={(accountStore.errors?.roleId && !accountStore.dataRequest?.roleId) ? "error" : ""}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.roleId = e
                                    }}
                                    placeholder="Chọn" options={accountStore.listRole}
                                />
                                {accountStore.errors?.roleId  && !accountStore.dataRequest.roleId && <small className="text-error">{accountStore.errors?.roleId}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Họ và tên<span className='text-danger'>*</span></label>
                                <Input type='text' value={accountStore.dataRequest?.name}
                                       status={(accountStore.errors?.name && !accountStore.dataRequest?.name) ? "error" : ""}
                                       onChange={(e: any) => {
                                           accountStore.dataRequest.name = e.target.value
                                       }}
                                       placeholder='Nhập tên ...' />
                                {accountStore.errors?.name  && !accountStore.dataRequest.name && <small className="text-error">{accountStore.errors?.name}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Cấp bậc<span className='text-danger'>*</span></label>
                                <Select
                                    allowClear
                                    status={(accountStore.errors?.rank && !accountStore.dataRequest?.rank) ? "error" : ""}
                                    value={accountStore.dataRequest.rank}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.rank = e
                                    }}
                                    placeholder="Chọn" options={[{value: 'DIRECTORS', label: 'Lãnh đạo'}, {value: 'MANAGERS', label: 'Quản lý'}, {value: 'EMPLOYEES', label: 'Nhân viên'}]}
                                />
                                {accountStore.errors?.rank  && !accountStore.dataRequest.rank && <small className="text-error">{accountStore.errors?.rank}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Phòng ban</label>
                                <Select
                                    allowClear
                                    mode="multiple"
                                    value={accountStore.dataRequest.departmentId}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.departmentId = e
                                    }}
                                    placeholder="Chọn" options={accountStore.listDepartment}
                                />
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Người quản lý</label>
                                <Select
                                    allowClear
                                    value={accountStore.dataRequest.managerId}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.managerId = e
                                    }}
                                    placeholder="Chọn" options={accountStore.listManager}
                                />
                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Cơ sở</label>
                                <Select
                                    allowClear
                                    value={accountStore.dataRequest.establishmentId}
                                    onChange={(e: any) => {
                                        accountStore.dataRequest.establishmentId = e
                                    }}
                                    placeholder="Chọn" options={accountStore.listRestaurant}
                                />
                            </div>
                            <div className='w-50 d-flex flex-column justify-content-start ps-2 mt-3'>
                                <label className='me-3'>Loại</label>
                                <Radio.Group  name="radiogroup" onChange={(e) => {
                                    accountStore.dataRequest.type = e.target.value
                                }} value={accountStore.dataRequest?.type}>
                                    <Radio value="STAFF">Nhân viên</Radio>
                                    <Radio value="DELIVERY_DRIVER">Giao hàng</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                            <button type='submit' className='btn btn__voucher'
                                    onClick={async () => {
                                        await accountStore.addAccount()}}>
                                {accountStore.isLoadingBt ? <Spin size="small" /> : accountStore.typeModal === 'add' ?  'Thêm' : 'Cập nhật' }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalAddEditAccount);

