import {makeAutoObservable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {staffService} from "./StaffService";
import {toastUtil} from "../../common/utils/ToastUtil";
import {businessPlanStore} from "../saleMarketing/businessPlan/BusinessPlanStore";



class StaffStore {
    id: any = ''
    typeModal: string = ''
    keyword: string = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listStaff: any[] = []
    listTypeJob: any = [
        {
            value: "FULL_TIME",
            label: "Full-time"
        },
        {
            value: "PART_TIME",
            label: "Part-time"
        }
    ]
    dataRequest: any = {
    }

    constructor() {
        makeAutoObservable(this)
    }

    clearForm() {
    }
    async getStaff() {
        this.isLoading = true
        const result = await staffService.getStaff()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            businessPlanStore.listStaff = result.body.data.map((item: any) => {
                return {
                    value: item?.id,
                    label: item?.name
                }
            })
            staffStore.listStaff = result.body.data.map((item: any) => {
                return {
                    ...item,
                    isEdit: false
                }
            })
        }
    }
    async getDetailStaff(id: any) {
        const result = await staffService.getStaffById(id)
        if (result.status === HttpStatusCode.OK) {
            staffStore.dataRequest = result.body
        }
    }
    async updateInfoStaff(id: any, name: any) {
        this.isLoadingBt = true
        const result =  await staffService.updateInfoStaff(id, staffStore.dataRequest)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`Cập nhật thông tin của ${name} thành công!`, 1)
            this.clearForm()
            await staffStore.getStaff()
        } else toastUtil.error(result.body.message, 2)
    }

}

export const staffStore = new StaffStore()