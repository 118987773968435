import React, {useRef} from 'react';
import {observer} from "mobx-react";
import './ModalAddEditPosition.scss'
import {Input, Spin} from "antd";
import {positionStore} from "../../PositionStore";
const ModalAddEditPosition = () => {
    const modalRef = useRef(null);
    return (
        <>
            <div className='modal fade' id='add_edit_position' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content border-0'>
                        <div className='modal-header'>
                            <div className='value_voucher__title'>{positionStore.typeModal === 'add' ? 'THÊM' : 'CẬP NHẬT'} CHỨC VỤ</div>
                            <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className='w-100 form-group'>
                                <div className='w-100 form-group'>
                                    <label className='form-label'>Tên chức vụ <span className='text-danger'>*</span></label>
                                    <Input type='text' value={positionStore.dataRequest?.name}
                                           status={(positionStore.errors?.name && !positionStore.dataRequest.name) ? "error" : ""}
                                           onChange={(e: any) => {
                                               positionStore.dataRequest.name = e.target.value
                                           }}
                                           onKeyPress={async (e) => {
                                               if (e.key === "Enter") {
                                                       (modalRef.current as unknown as HTMLButtonElement).click();
                                               }
                                           }}
                                           placeholder='Nhập...' />
                                    {(positionStore.errors?.name && !positionStore.dataRequest.name) && <small className="text-error">{positionStore.errors?.name}</small>}
                                </div>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-end mt-4'>
                                        <button type='submit' className='w-25 btn btn__continue'  ref={modalRef}
                                                onClick={async () => {await positionStore.addEditPosition()}}>
                                            {
                                                positionStore.isLoadingBt ?  <Spin size="small" /> : "Hoàn thành"
                                            }
                                        </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default observer(ModalAddEditPosition);

