import React from 'react';
import './ButtonGlobal.scss'

interface IProps {
    text?: string,
    className?: string,
    dataTarget?: string,
    onClick?: any,
    ico?: string,
    disabled?: boolean
}


const ButtonGlobal = ({text, dataTarget, className = "button_global", onClick, ico, disabled}: IProps) => {
    if(dataTarget) {
        return (
            <button className={`${className} button_global`} onClick={() => onClick()} disabled={disabled}  data-bs-toggle="modal" data-bs-target={`#${dataTarget}`}>
                {ico && <img src={`/assets/ico/action/${ico}.svg`} alt="" className="icon_action"/>}
                <span className="title-btn">{text}</span>
            </button>
        );
    }
    else
        return (
        <button className={`${className} button_global`} onClick={() => onClick()} disabled={disabled}>
            {ico && <img src={`/assets/ico/action/${ico}.svg`} alt="" className="icon_action"/>}
            <span className="title-btn">{text}</span>
        </button>
    );

}

export default ButtonGlobal;
