import React, {useEffect} from 'react';
import './CustomerBenefit.scss'
import {observer} from "mobx-react";
import TabRedirect from "../../../modules/components/tabRedirect/TabRedirect";
import {numberUtil} from "../../../common/utils/NumberUtil";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import {customerBenefitStore} from "./CustomerBenefitStore";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";
import AddEditBenefit from "./components/AddEditBenefit";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {Tooltip} from "antd";

const CustomerBenefitPage = () => {
    useEffect(() => {
        customerBenefitStore.getCustomerBenefit().then()
    }, [])
    return (
        <>
            <div className='customerData_container'>
                <div className='customerData_container__header'>
                    <TabRedirect tab='Quyền lợi thành viên' />
                    <ButtonGlobal onClick={() => {
                        customerBenefitStore.clearForm()
                    }} dataTarget='add_edit_benefit' text='Thêm' ico='add_icon' className='btn_add' />
                </div>
                <div className="table-responsive d-flex justify-content-center">
                    {customerBenefitStore.isLoading ? <Loading/> : <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th className="text-center">Tên phân loại</th>
                            <th className="text-center">Mức doanh thu khách (VNĐ)</th>
                            <th className="text-center">Mức ưu đãi</th>
                            <th className="text-center">Loại Voucher</th>
                            <th className="text-center">Dịch vụ phục vụ</th>
                            <th className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {customerBenefitStore.listCustomerBenefit && customerBenefitStore.listCustomerBenefit.length > 0 ? customerBenefitStore.listCustomerBenefit.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{item.rankName}</td>
                                    <td className="text-center fw-500">{numberUtil.number_format(item.revenueFrom)}</td>
                                    <td className="text-center">{`${numberUtil.number_format(item?.discountValue)}`}<span className='ms-1 text-decoration-underline'>{item.discountType === "VALUE" ? "đ" : "%"}</span></td>
                                    <td className="text-center">{item.discountType === "VALUE" ? "Theo giá tiền" : "Theo %"}</td>
                                    <td className="text-center">{item?.interest?.note}</td>
                                    <td className="text-center">
                                            <button className="btn p-0"
                                                    data-bs-toggle="modal" data-bs-target={"#add_edit_benefit"}
                                                    onClick={async () => {
                                                        customerBenefitStore.id = item?.id
                                                        await customerBenefitStore.getDetail(item?.id)}
                                                    }>
                                                <Tooltip title='Chỉnh sửa'>
                                                <img src="/assets/ico/action/edit_green.svg"
                                                     className="icon-action"
                                                     alt=""/>
                                                </Tooltip>
                                            </button>

                                            <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                    onClick={() => customerBenefitStore.id = item?.id}>
                                                <Tooltip title='Xóa'>
                                                    <img src="/assets/ico/action/delete_icon.svg"
                                                         className="icon-action"
                                                         alt=""/>
                                                </Tooltip>
                                            </button>
                                    </td>
                                </tr>
                            )
                        }) : <NoContent colSpan={6}/>}
                        </tbody>
                    </table>}
                </div>
                <AddEditBenefit/>
                <PopupConfirm isLoading={customerBenefitStore.isLoadingBt} action={customerBenefitStore.delete} />
            </div>
        </>
    );
}

export default observer(CustomerBenefitPage);