import React from 'react';
import {observer} from "mobx-react";
import './ModalValueVoucher.scss'
import {DatePicker, Radio, Select, Input, Space, Spin} from 'antd';
import {Moment} from "moment";
import {voucherStore} from "../../VoucherStore";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {numberUtil} from "../../../../../common/utils/NumberUtil";
dayjs.extend(customParseFormat);

export function convertTime(date?: Moment, isEndDate?: boolean): number {
    if (!date) return 0;
    if (isEndDate) {
        return date.set({hour: 23, minute: 59, second: 59}).unix() * 1000;
    }
    return date.set({hour: 0, minute: 0, second: 0}).unix()*1000;
}
export const handleRangePickerChange = (dates: any) => {
    if (dates && dates.length > 0) {
        voucherStore.dataRequestValueVoucher.startTime = convertTime(dates[0]);
        voucherStore.dataRequestValueVoucher.endTime = convertTime(dates[1]);
    }
}
const ModalValueVoucher = () => {
    const {RangePicker} = DatePicker;
    const options = [
        {
            value: 'SPECIFIC_AMOUNT',
            label: 'Theo số tiền',
        },
        {
            value: 'PERCENTAGE',
            label: 'Theo %',
        },
    ];

    return (
        <div className='modal fade' id='value_voucher' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='value_voucher__title'>VOUCHER GIÁ TRỊ</div>
                        <button type="button" id="close_kpi" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body p-4">
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Tên Voucher<span className='text-danger'>*</span></label>
                                <Input type='text' value={voucherStore.dataRequestValueVoucher?.name}
                                       onChange={(e: any) => {
                                           voucherStore.dataRequestValueVoucher.name = e.currentTarget.value
                                       }}
                                       status={(voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher?.name) ? "error" : ""}
                                       placeholder='Nhập tên ...' />
                                {voucherStore.errors?.name && !voucherStore.dataRequestValueVoucher?.name && <small className="text-error">{voucherStore.errors?.name}</small>}

                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Giá trị Voucher tối đa
                                    <small className='mx-2'>(Không nhập sẽ không giới hạn giá trị)</small>
                                </label>
                                <Input type='text'
                                       disabled={voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT"}
                                       status={(voucherStore.errors?.proviso.maxDiscountAmount  && ! voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount && voucherStore.dataRequestValueVoucher?.proviso?.type !== "SPECIFIC_AMOUNT") ? "error" : ""}
                                       value={voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT" ? "" : voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount ?  numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount) : ""}
                                       onChange={(e: any) => {
                                           if (
                                               e === '' ||
                                               Number(numberUtil.regexNumber(e)) >= 0
                                           ) {
                                               voucherStore.dataRequestValueVoucher.proviso.percentage.maxDiscountAmount = numberUtil.regexNumber(e)
                                           }
                                       }}
                                       onKeyDown={(evt: {
                                           key: string
                                           preventDefault: () => any
                                       }) =>
                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                           evt.preventDefault()
                                       }
                                       placeholder='Nhập số ...' />
                                {voucherStore.errors?.proviso.maxDiscountAmount  && ! voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount && voucherStore.dataRequestValueVoucher?.proviso?.type !== "SPECIFIC_AMOUNT" && <small className="text-error">{voucherStore.errors?.name}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Mã Voucher<span className='text-danger'>*</span></label>
                                <Input type='text'  value={voucherStore.dataRequestValueVoucher?.code}
                                       onChange={(e: any) => {
                                           voucherStore.dataRequestValueVoucher.code = e.currentTarget.value
                                       }}
                                       status={(voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher?.code) ? "error" : ""}
                                       placeholder='HUAS | Nhập vào ...' />
                                {voucherStore.errors?.code && !voucherStore.dataRequestValueVoucher?.code && <small className="text-error">{voucherStore.errors?.code}</small>}

                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Giá trị đơn hàng tối thiểu<span className='text-danger'>*</span></label>
                                <Input type='text' value={voucherStore.dataRequestValueVoucher?.proviso?.minOrderValue ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.minOrderValue) : ""}
                                       onChange={(e: any) => {
                                           if (
                                               e === '' ||
                                               Number(numberUtil.regexNumber(e)) >= 0
                                           ) {
                                               voucherStore.dataRequestValueVoucher.proviso.minOrderValue = numberUtil.regexNumber(e)
                                           }
                                       }}
                                       onKeyDown={(evt: {
                                           key: string
                                           preventDefault: () => any
                                       }) =>
                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                           evt.preventDefault()
                                       }
                                       status={(voucherStore.errors?.proviso.minOrderValue && !voucherStore.dataRequestValueVoucher?.proviso.minOrderValue) ? "error" : ""}
                                       placeholder='Nhập số ...' />
                                {voucherStore.errors?.proviso?.minOrderValue && !voucherStore.dataRequestValueVoucher?.proviso?.minOrderValue && <small className="text-error">{voucherStore.errors?.proviso?.minOrderValue}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Thời gian sử dụng mã<span className='text-danger'>*</span></label>
                                <RangePicker
                                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                    onChange={handleRangePickerChange}
                                    format={'DD/MM/YYYY'}
                                    status={(voucherStore.errors?.time && !voucherStore.dataRequestValueVoucher?.startTime &&  !voucherStore.dataRequestValueVoucher?.endTime) ? "error" : ""}
                                    value={[
                                        voucherStore.dataRequestValueVoucher.startTime === "" ? null : dayjs(voucherStore.dataRequestValueVoucher.startTime),
                                        voucherStore.dataRequestValueVoucher.endTime === "" ? null : dayjs( voucherStore.dataRequestValueVoucher.endTime)
                                    ] as any}
                                />
                                {voucherStore.errors?.time  && !voucherStore.dataRequestValueVoucher.startTime &&  !voucherStore.dataRequestValueVoucher.endTime && <small className="text-error">{voucherStore.errors?.time}</small>}
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Địa điểm áp dụng<span className='text-danger'>*</span></label>
                                <Select
                                    disabled
                                        onChange={(e: any) => {
                                            alert(e.currentTarget.value)}}
                                        placeholder="Chọn" options={[{value: '11', label: '1'}, {value: '22', label: '2'}]}
                                />
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Thiết lập mã giảm giá</label>
                                <div className='d-flex align-items-center'>
                                    <label className='me-3'>Loại Voucher<span className='text-danger'>*</span></label>
                                    <Radio.Group  name="radiogroup" onChange={(e) => {
                                        voucherStore.dataRequestValueVoucher.discountType = e.target.value
                                    }} value={voucherStore.dataRequestValueVoucher?.discountType}>
                                        <Radio value="DISCOUNT">Khuyến Mãi</Radio>
                                        <Radio value="REFUND">Hoàn Điểm</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className='w-50 form-group ps-2'>
                                <label className='form-label'>Số voucher phát hành<span className='text-danger'>*</span></label>
                                <Input type='text'  value={voucherStore.dataRequestValueVoucher?.amount ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.amount) : ""}
                                       onChange={(e: any) => {
                                           if (
                                               e === '' ||
                                               Number(numberUtil.regexNumber(e)) >= 0
                                           ) {
                                               voucherStore.dataRequestValueVoucher.amount = numberUtil.regexNumber(e)
                                           }
                                       }}
                                       onKeyDown={(evt: {
                                           key: string
                                           preventDefault: () => any
                                       }) =>
                                           ['e', 'E', '+', '-'].includes(evt.key) &&
                                           evt.preventDefault()
                                       }
                                       status={(voucherStore.errors?.amount && !voucherStore.dataRequestValueVoucher?.amount) ? "error" : ""}
                                       placeholder='Nhập số ...' />
                                {voucherStore.errors?.amount  && !voucherStore.dataRequestValueVoucher?.amount && <small className="text-error">{voucherStore.errors?.amount}</small>}
                            </div>
                        </div>
                        <div className='w-100 d-flex'>
                            <div className='w-50 form-group pe-2'>
                                <label className='form-label'>Loại giảm giá | mức giảm <span className='text-danger'>*</span></label>
                                <Space.Compact>
                                    <Select placeholder='Chọn' onChange={(e) => voucherStore.dataRequestValueVoucher.proviso.type = e} value={ voucherStore.dataRequestValueVoucher?.proviso?.type} options={options} />
                                    <Input
                                        type="text"
                                        value={voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT" ? (voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value ? numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value) : "") :  (voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value ?  numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value) : "")}
                                           onChange={(e: any) => {
                                               if (e === '' || Number(numberUtil.regexNumber(e)) >= 0){
                                                   if(voucherStore.dataRequestValueVoucher?.proviso?.type === "SPECIFIC_AMOUNT") {
                                                       voucherStore.dataRequestValueVoucher.proviso.specificAmount.value = numberUtil.regexNumber(e) ?? ""
                                                   }
                                                   else {
                                                       voucherStore.dataRequestValueVoucher.proviso.percentage.value = numberUtil.regexPercentage(e) ?? ""
                                                   }
                                               }
                                           }}
                                           onKeyDown={(evt: {
                                               key: string
                                               preventDefault: () => any
                                           }) =>
                                               ['e', 'E', '+', '-'].includes(evt.key) &&
                                               evt.preventDefault()
                                           }
                                           status={(voucherStore.errors?.discountValue && !voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value) ? "error" : ""}
                                           placeholder="Nhập số ..." />
                                </Space.Compact>
                                {voucherStore.errors?.discountValue  && !voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value && !voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value && <small className="text-error">{voucherStore.errors?.discountValue}</small>}
                            </div>
                            <div className='w-50 d-flex align-items-center ps-2 mt-3'>
                                <button type='submit' className='btn btn__voucher' onClick={async () => {
                                    await voucherStore.addEditPromotion()
                                }}>{voucherStore.isLoadingBt ? <Spin size="small" /> : voucherStore.type === 'add' ?  'Tạo Voucher' : voucherStore.type === 'copy' ?  "Sao chép Voucher" : 'Cập nhật Voucher'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalValueVoucher);

