import {getRequest, IApiResponse} from "../../../common/helpers/RequestHelper";
import {customerDataStore} from "./CustomerDataStore";

class CustomerDataService{
    public getListUser(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user?page=${customerDataStore.page}${customerDataStore.keyword ? `&keyword=${customerDataStore.keyword}` : ''}&size=${size ?? 15}`)
    }
    public getDetail(id: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/user/${id}`)
    }
    public getListOrderUser(size?: number): Promise<IApiResponse>{
        return getRequest(`/iss_365_fnb/order/fnb?page=0${customerDataStore.userId ? `&userId=${customerDataStore.userId}` : ''}&size=${size ?? 100}`)
    }
}

export const customerDataService = new CustomerDataService()