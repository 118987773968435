import {makeAutoObservable} from "mobx";
import {getRequest, putRequest} from "../../common/helpers/RequestHelper";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import {orderStore} from "../order/OrderStore";

class KitchenStore {
    isLoading: boolean = false
    isLoadingBt: boolean = false
    isLoadingDt: boolean = false
    totalPage: number = 0
    listKitchen: any[] = []
    dataDetail: any[] = []
    searchEstablishmentId: any = ''
    establishmentName: any = ''

    constructor() {
        makeAutoObservable(this)
    }

    async changeStatus(item: any){
        let status: string = ''

        if(item.status == "PENDING"){
            status = "IN_PROGRESS"
        }
        if(item.status == "IN_PROGRESS"){
            status = "SUCCESS"
        }

        let data = {
            ids: item.ids,
            status: status
        }
        this.isLoadingBt = true
        const result = await putRequest(`/iss_365_fnb/order/item/status`, data)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success(`Cập nhật trạng thái món thành công!`, 2)
            this.listKitchen.map((val) => {
                if(val.ids == item.ids){
                    val.status = status
                }
            })
        }else toastUtil.error(result.body.message, 2)

    }

    async cancelKitchen(ids: any){
        let data = {
            ids: ids,
            status: "CANCEL"
        }
        this.isLoadingBt = true
        const result = await putRequest(`/iss_365_fnb/order/item/status`, data)
        this.isLoadingBt = false
        if(result.status === HttpStatusCode.OK){
            toastUtil.success('Hủy món thành công!', 1)
            this.listKitchen.map((val) => {
                if(val.ids == ids){
                    val.status = "CANCEL"
                }
            })
        }else toastUtil.error(result.body.message, 2)
    }

    async getKitchen(){
        this.isLoading = true
        const result = await getRequest(`/iss_365_fnb/order/kitchen?page=0${kitchenStore.searchEstablishmentId ? `&establishmentId=${kitchenStore.searchEstablishmentId}` : ''}&size=100`)
        this.isLoading = false
        if(result.status === HttpStatusCode.OK){
            this.listKitchen = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(ids: any){
        this.isLoadingDt = true
        const result = await getRequest(`/iss_365_fnb/order/item?ids=${ids.join(',')}`)
        this.isLoadingDt = false
        if(result.status === HttpStatusCode.OK){
            this.dataDetail = result.body
        }
    }



}

export const kitchenStore = new KitchenStore()