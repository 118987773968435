import React, {useEffect} from 'react';
import './PositionPage.scss'
import {observer} from "mobx-react";
import NoContent from "../../../modules/components/NoContent";
import Loading from "../../../modules/components/Loading";
import FilterComponent, {IFilter} from "../../../modules/components/filterGlobal/FilterGlobal";
import ButtonGlobal from "../../../modules/components/buttonGlobal/ButtonGlobal";
import PopupConfirm from "../../../modules/components/PopUpConfirm";
import {positionStore} from "./PositionStore";
import ModalAddEditPosition from "./components/modalAddEditPosition/ModalAddEditPosition";

const PositionPage = () => {
    const dataFilter : IFilter[] = [
        {
            label: '',
            type: 'search',
            placeholder: 'Tìm kiếm tên , mã nhân viên...',
            value: '',
            onChange: (e: any) => {
                positionStore.keyword  = e
            },
            callback: async () => {
                positionStore.getPosition().then()
            }
        },
    ]
    useEffect(() => {
        positionStore.getPosition().then()
    }, [])
    return (
        <>
            <div className='position_container'>
                <div className='position_container__header'>
                    <div  className='d-flex w-100 justify-content-end align-items-center'>
                        <FilterComponent
                            data={dataFilter}
                            permissionCode
                            buttonAdd={<ButtonGlobal onClick={() => {
                                positionStore.typeModal = 'add'
                                positionStore.clearForm()
                            }} dataTarget='add_edit_position' text='Thêm' ico='add_icon' className='btn_add' />}
                        />
                    </div>
                </div>
                {positionStore.isLoading ? <Loading /> : <div className="table-responsive custom-scrollbar d-flex justify-content-between">
                    <table className='table align-middle border gy-7 gs-7'>
                        <thead>
                        <tr>
                            <th scope="col" style={{width : '80px'}} className="text-center">STT</th>
                            <th scope="col" className="text-center">Chức vụ</th>
                            <th scope="col" style={{width : '150px'}} className="text-center">Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {positionStore.listPosition?.length <= 0 ? <NoContent colSpan={3}/> : positionStore.listPosition.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td className="text-start">{item?.name ?? "-"}</td>
                                    <td className="text-center">
                                        <button className="btn p-0"
                                                data-bs-toggle="modal" data-bs-target={"#add_edit_position"}
                                                onClick={async () => {
                                                    positionStore.typeModal = 'edit'
                                                    positionStore.id = item?.id
                                                    await positionStore.getDetailPosition(item?.id)
                                                }}>
                                            <img src="/assets/ico/action/edit_green.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Chỉnh sửa"/>
                                        </button>

                                        <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#popup_confirm"
                                                onClick={() => positionStore.id = item.id}>
                                            <img src="/assets/ico/action/delete_icon.svg"
                                                 className="icon-action"
                                                 alt="" data-toggle="tooltip" data-placement="top"
                                                 title="Xóa"/>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <ModalAddEditPosition />
            <PopupConfirm isLoading={positionStore.isLoadingBt} action={positionStore.deletePosition} />
        </>
    );
}

export default observer(PositionPage);