import React from 'react';
import {observer} from "mobx-react";
import './ModalDetailVoucher.scss'
import {Input} from "antd";
import {voucherStore} from "../../VoucherStore";
import {dateUtils} from "../../../../../common/utils/DateUtils";
import {convertTypePromotion} from "../../VoucherPage";
import {numberUtil} from "../../../../../common/utils/NumberUtil";


const DetailVoucher = () => {
    const onKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
            await voucherStore.getVoucherByPromotionId(voucherStore.id)
        }
    }
    return (
        <div className="modal fade" id="detail_voucher" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='modal-voucher--content'>
                        <div className='modal-voucher--head'>
                            <div className='modal-voucher--headFirst'>
                                <div className='d-flex'>
                                    <div className='modal-voucher--name d-flex flex-column pe-4'>
                                        <span>Voucher: </span>
                                        <span>Điểm bán: </span>
                                        <span>Thời gian: </span>
                                        <span>Loại: </span>
                                        {(voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' || voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIFIC_AMOUNT')
                                            && <span>Mức giảm: </span>
                                        }
                                    </div>
                                    <div className='modal-voucher--value d-flex flex-column'>
                                        <span>{voucherStore.dataRequestValueVoucher?.name}</span>
                                        <span>-</span>
                                        <span>{`${dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.startTime)} - ${dateUtils.formatDate(voucherStore.dataRequestValueVoucher?.endTime)}`}</span>
                                        <span>{convertTypePromotion(voucherStore.dataRequestValueVoucher?.type)}</span>
                                        {(voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' || voucherStore.dataRequestValueVoucher.proviso.type === 'SPECIFIC_AMOUNT')
                                            &&
                                            <span>{voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' ? `${numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.value)} %` : `${numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.specificAmount?.value)} đ`}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='modal-voucher--headSecond'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex w-100'>
                                        <div className='modal-voucher--name d-flex flex-column pe-4'>
                                            <span>Giá trị đơn tối thiểu:</span>
                                            {voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' && <span>Giá trị Voucher tối đa:</span>}
                                            <span>Số phát hành: </span>
                                            <span>Địa điểm áp dụng: </span>
                                        </div>
                                        <div className='modal-voucher--value d-flex flex-column align-items-start'>
                                            <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.minOrderValue)} đ</span>
                                            {voucherStore.dataRequestValueVoucher.proviso.type === 'PERCENTAGE' && <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.proviso?.percentage?.maxDiscountAmount)} đ</span>}
                                            <span>{numberUtil.number_format(voucherStore.dataRequestValueVoucher?.amount)} mã</span>
                                            <span>Tất cả cửa hàng </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <Input size="large"
                                   className='input_search_codeVoucher'
                                   placeholder="Tìm kiếm mã"
                                   prefix={<img className='cursor_pointer' src='/assets/ico/action/magnifyingglass.svg' onClick={() => voucherStore.getVoucherByPromotionId(voucherStore.id) } alt='#' />}
                                   onChange={(e) => voucherStore.searchNameCode = e.currentTarget.value}
                                   onKeyDown={(e: any) => onKeyDown(e)}
                            />
                            <div className='list_search_codeVoucher custom-scrollbar' >
                                {
                                    voucherStore.listVoucherByPromotionId?.length > 0 && voucherStore.listVoucherByPromotionId?.map((item : any) => {
                                        return(
                                            <div className='codeVoucher_item'>
                                                {item.code}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    </div>

                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(DetailVoucher);