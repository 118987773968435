import {makeAutoObservable} from 'mobx'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import $ from "jquery";
import {rndResearchService} from "./RndResearchService";
export interface IDataRequestAddRndResearch {
    name: string,
    data: any,
    reportUser: any
    reportingTime: any,
    researchTimeFrom: any,
    researchTimeTo: any,
    type: any
}

class RndResearch {
    id: any = ""
    page: number = 0
    totalPage: number = 0
    isLoadingBt: boolean = false
    isLoading: boolean = false
    typeForm: string = "add"
    searchStatus: any = null
    statusResearch: any = ''

    listRnDResearch: any = []
    //---------------DATA MARKET RESEARCH -------------------------

    // updateResearchResult(index: any, value: any){
    //     rndResearchStore.listResearchResult[index] = value
    // }
    // updateListDocument(index: any, value: any){
    //     rndResearchStore.listDocument[index] = value
    // }
    errors : any = {
        reportingTime: false,
        researchTimeFrom: false,
    }
    listCommonRndResearch = [{
        title : "Sản phẩm mục tiêu",
        content: "",
        default: true
    },{
        title : "Khách hàng mục tiêu",
        content: "",
        default: true
    },{
        title : "Thị trường mục tiêu",
        content: "",
        default: true
    },{
        title : "Đối thủ cạnh tranh",
        content: "",
        default: true
    },{
        title : "Phương pháp nghiên cứu",
        content: "",
        default: true
    },{
        title : "Mục tiêu nghiên cứu",
        content: "",
        default: true
    },{
        title : "Phạm vi nghiên cứu",
        content: "",
        default: true
    }]
    listVoteProductRndResearch = [{
        criteria : "Đáp ứng nhu cầu thị trường",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Yêu cầu chất lượng",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tính năng",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Lợi ích",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tính độc đáo và sáng tạo",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Giá",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Phạm vi thị trường",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Đánh giá tính cạnh tranh đối với đối thủ trực tiếp",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Đánh giá tính cạnh tranh đối với đối thủ gián tiếp",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tỷ lệ khách hàng hiện tại mua sản phẩm",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tuân thủ quy định pháp lý",
        result: "",
        vote: "",
        default: true
    },{
        criteria : "Tổng điểm Trung bình",
        result: "",
        vote: "",
        default: true
    }]
    listMarketRndResearch : any = [
        {
            criteria: "Tỷ lệ người sử dụng (%)",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Giá bán",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Doanh số bán hàng",
            option1: "",
            option2: "",
            default: true
        },
        {
            criteria: "Đánh giá chất lượng",
            option1: "",
            option2: "",
            default: true
        }
    ]
    listSuggestProductRndResearch = [{
        stage: "",
        action: "",
        performer: "",
        coordinator: "",
        time: ""
    }]
    listStragyMarketingRndResearch = [
        {
            marketingActive : "",
            mediaChanel : "",
            target: ""
        }
    ]
    listTargetEfficiencyRndResearch = [
        {
            criteria: "Đánh giá chất lượng",
            result: "",
            vote: "",
        },
        {
            criteria: "% Chi phí/Tổng chi phí",
            result: "",
            vote: "",
        },
        {
            criteria: "Doanh số kỳ vọng",
            result: "",
            vote: "",
        },
        {
            criteria: "% Đóng góp doanh số",
            result: "",
            vote: "",
        },
        {
            criteria: "Tỷ suất sinh lời đầu tư ROI",
            result: "",
            vote: "",
        },
        {
            criteria: "Thời gian thu hồi vốn",
            result: "",
            vote: "",
        },
        {
            criteria: "Tổng điểm trung bình",
            result: "",
            vote: "",
        }
    ]
    overallFirstConclusionRndResearch : any = ""
    overallConclusionRndResearch : any = ""
    listDocumentRndResearch: any = [{
        name: ""
    }]

    clearForm(){
        this.dataRequestRndResearch = {
            name: "BÁO CÁO NGHIÊN CỨU VÀ PHÁT TRIỂN SẢN PHẨM",
            reportUser: "",
            data: {},
            reportingTime: "",
            researchTimeFrom: "",
            researchTimeTo: "",
            type: "R_A_D"
        }
        this.listCommonRndResearch = [{
            title : "Sản phẩm mục tiêu",
            content: "",
            default: true
        },{
            title : "Khách hàng mục tiêu",
            content: "",
            default: true
        },{
            title : "Thị trường mục tiêu",
            content: "",
            default: true
        },{
            title : "Đối thủ cạnh tranh",
            content: "",
            default: true
        },{
            title : "Phương pháp nghiên cứu",
            content: "",
            default: true
        },{
            title : "Mục tiêu nghiên cứu",
            content: "",
            default: true
        },{
            title : "Phạm vi nghiên cứu",
            content: "",
            default: true
        }]
        this.listVoteProductRndResearch = [{
            criteria : "Đáp ứng nhu cầu thị trường",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Yêu cầu chất lượng",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Tính năng",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Lợi ích",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Tính độc đáo và sáng tạo",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Giá",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Phạm vi thị trường",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Đánh giá tính cạnh tranh đối với đối thủ trực tiếp",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Đánh giá tính cạnh tranh đối với đối thủ gián tiếp",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Tỷ lệ khách hàng hiện tại mua sản phẩm",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Tuân thủ quy định pháp lý",
            result: "",
            vote: "",
            default: true
        },{
            criteria : "Tổng điểm Trung bình",
            result: "",
            vote: "",
            default: true
        }]
        this.listMarketRndResearch = [
            {
                criteria: "Tỷ lệ người sử dụng (%)",
                option1: "",
                option2: "",
                default: true
            },
            {
                criteria: "Giá bán",
                option1: "",
                option2: "",
                default: true
            },
            {
                criteria: "Doanh số bán hàng",
                option1: "",
                option2: "",
                default: true
            },
            {
                criteria: "Đánh giá chất lượng",
                option1: "",
                option2: "",
                default: true
            }
        ]
        this.listSuggestProductRndResearch = [{
            stage: "",
            action: "",
            performer: "",
            coordinator: "",
            time: ""
        }]
        this.listStragyMarketingRndResearch = [
            {
                marketingActive : "",
                mediaChanel : "",
                target: ""
            }
        ]
        this.listTargetEfficiencyRndResearch = [
            {
                criteria: "Đánh giá chất lượng",
                result: "",
                vote: "",
            },
            {
                criteria: "% Chi phí/Tổng chi phí",
                result: "",
                vote: "",
            },
            {
                criteria: "Doanh số kỳ vọng",
                result: "",
                vote: "",
            },
            {
                criteria: "% Đóng góp doanh số",
                result: "",
                vote: "",
            },
            {
                criteria: "Tỷ suất sinh lời đầu tư ROI",
                result: "",
                vote: "",
            },
            {
                criteria: "Thời gian thu hồi vốn",
                result: "",
                vote: "",
            },
            {
                criteria: "Tổng điểm trung bình",
                result: "",
                vote: "",
            }
        ]
        this.overallFirstConclusionRndResearch = ""
        this.overallConclusionRndResearch = ""
        this.listDocumentRndResearch = [{
            name: ""
        }]
    }
    //--------------------------DATA REQUEST----------------
    dataRequestRndResearch: IDataRequestAddRndResearch = {
        name: "BÁO CÁO NGHIÊN CỨU VÀ PHÁT TRIỂN SẢN PHẨM",
        reportUser: "",
        data: {},
        reportingTime: "",
        researchTimeFrom: "",
        researchTimeTo: "",
        type: "R_A_D"
    }

    //---------------DATA RND RESEARCH -------------------------


    async getListRndResearch() {
        this.isLoading = true
        const result = await rndResearchService.getListRnDResearch()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listRnDResearch = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }
    async getDetail(id: any) {
        this.isLoading = true
        const result = await rndResearchService.getDetailMarketResearch(id)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            rndResearchStore.dataRequestRndResearch.name = result.body?.name
            rndResearchStore.dataRequestRndResearch.reportUser = result.body?.reportUser?.name
            rndResearchStore.dataRequestRndResearch.reportingTime = result.body?.reportingTime
            rndResearchStore.dataRequestRndResearch.researchTimeFrom = result.body?.researchTimeFrom
            rndResearchStore.dataRequestRndResearch.researchTimeTo = result.body?.researchTimeTo
            rndResearchStore.dataRequestRndResearch.type = result.body?.type
            rndResearchStore.listCommonRndResearch = result.body?.data?.listCommonRndResearch
            rndResearchStore.listVoteProductRndResearch = result.body?.data?.listVoteProductRndResearch
            rndResearchStore.listMarketRndResearch = result.body?.data?.listMarketRndResearch
            rndResearchStore.listSuggestProductRndResearch = result.body?.data?.listSuggestProductRndResearch
            rndResearchStore.listStragyMarketingRndResearch = result.body?.data?.listStragyMarketingRndResearch
            rndResearchStore.listTargetEfficiencyRndResearch = result.body?.data?.listTargetEfficiencyRndResearch
            rndResearchStore.overallFirstConclusionRndResearch = result.body?.data?.overallFirstConclusionRndResearch
            rndResearchStore.overallConclusionRndResearch = result.body?.data?.overallConclusionRndResearch
            rndResearchStore.listDocumentRndResearch = result.body?.data?.listDocumentRndResearch
            rndResearchStore.statusResearch = result.body?.status

        } else {
            toastUtil.error(result.body.message, 4)
        }
    }
    async deleteMRndResearch() {
        rndResearchStore.isLoadingBt = true
        const result = await rndResearchService.deleteMarketResearch(rndResearchStore.id)
        rndResearchStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $('#popup_confirm').trigger('click')
            toastUtil.success('Xóa báo cáo thành công!')
            await  rndResearchStore.getListRndResearch()
        } else toastUtil.error(result.body.message)
    }
    async addRndResearch() {
        if(!rndResearchStore.dataRequestRndResearch.reportingTime){
            toastUtil.warning(`Vui lòng nhập thời gian báo cáo !`, 1)
            this.errors.reportingTime = true
            return
        }
        if(!rndResearchStore.dataRequestRndResearch.reportingTime || !rndResearchStore.dataRequestRndResearch.researchTimeTo){
            toastUtil.warning(`Vui lòng nhập thời gian nghiên cứu !`, 1)
            this.errors.researchTimeFrom = true
            return
        }
        const data = {
            name: rndResearchStore.dataRequestRndResearch.name,
            data: {
                listCommonRndResearch: rndResearchStore.listCommonRndResearch,
                listVoteProductRndResearch: rndResearchStore.listVoteProductRndResearch,
                listMarketRndResearch: rndResearchStore.listMarketRndResearch,
                listSuggestProductRndResearch: rndResearchStore.listSuggestProductRndResearch,
                listStragyMarketingRndResearch: rndResearchStore.listStragyMarketingRndResearch,
                listTargetEfficiencyRndResearch: rndResearchStore.listTargetEfficiencyRndResearch,
                overallFirstConclusionRndResearch : rndResearchStore.overallFirstConclusionRndResearch,
                overallConclusionRndResearch : rndResearchStore.overallConclusionRndResearch,
                listDocumentRndResearch: rndResearchStore.listDocumentRndResearch,
            },
            reportingTime:  rndResearchStore.dataRequestRndResearch.reportingTime,
            researchTimeFrom:  rndResearchStore.dataRequestRndResearch.researchTimeFrom,
            researchTimeTo:  rndResearchStore.dataRequestRndResearch.researchTimeTo,
            type: "R_A_D"
        }
        this.isLoadingBt = true
        const result =  this.typeForm === 'add' ? await rndResearchService.addMarketResearch(data) : await rndResearchService.editMarketResearch(rndResearchStore.id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${this.typeForm === 'add' ? 'Thêm báo cáo thành công' : 'Cập nhật thông tin báo cáo thành công'}`, 1)
            window.location.href = '/nghien-cuu-thi-truong/bao-cao-rnd'
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    constructor() {
        makeAutoObservable(this)
    }

}

export const rndResearchStore = new RndResearch()